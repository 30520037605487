import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import general_messages from "../../containers/messages";
import * as colors from "../../styles/constants";
import { CandidateReportStyles as styles } from "../../styles";
import SectionTitle from "./SectionTitle";
import Container from "../Container";
import Text from "../Text";
import { observer } from "mobx-react";

class ReportCADisclosure extends Component {
  static propTypes = {
    model: PropTypes.object
  };

  render() {
    if (
      this.props.model.stateAbbr !== "CA" &&
      this.props.model.driversLicenseState !== "CA"
    )
      return null;
    return (
      <div
        style={{
          ...styles.section,
          borderBottom: `2px solid ${colors.primary}`
        }}
      >
        <SectionTitle title="State Disclosure" />
        <Container background={colors.white} slim padding="0">
          <div style={styles.disclosureContainer}>
            <Text
              size="20px"
              align="center"
              margin="10px 0 8px 0"
              display="block"
              weight="bold"
            >
              IMPORTANT NOTICE
            </Text>
            <Text
              size="18px"
              align="justify"
              weight="bold"
              margin="0px 0px 10px"
            >
              <FormattedMessage {...general_messages.ca_disclosure} />
            </Text>
            <Text size="18px" align="justify" weight="bold">
              <FormattedMessage {...general_messages.ca_disclosure2} />
            </Text>
          </div>
          <Text
            size="20px"
            align="center"
            margin="10px 0 8px 0"
            display="block"
            weight="bold"
          >
            NOTIFICACIÓN IMPORTANTE
          </Text>
          <Text size="18px" align="justify" weight="bold" margin="0px 0px 10px">
            <FormattedMessage {...general_messages.ca_disclosure_spanish} />{" "}
          </Text>
          <Text size="18px" align="justify" weight="bold">
            <FormattedMessage {...general_messages.ca_disclosure_spanish2} />
          </Text>
        </Container>
      </div>
    );
  }
}

export default observer(ReportCADisclosure);
