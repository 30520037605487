import { defineMessages } from "react-intl";

export default defineMessages({
  ca_disclosure: {
    id: "app.containers.general.ca_disclosure",
    defaultMessage:
      "Turn Technologies does not guarantee the accuracy or truthfulness of the information in this report as to the subject of the investigation, but only that it is accurately copied from public records.  Information generated as a result of identity theft, including evidence of criminal activity, may be inaccurately associated with the consumer who is the subject of this report."
  },
  ca_disclosure2: {
    id: "app.containers.general.ca_disclosure2",
    defaultMessage:
      "An investigative consumer reporting agency shall provide a consumer seeking to obtain a copy of a report or making a request to review a file, a written notice in simple, plain English and Spanish setting forth the terms and conditions of his or her right to receive all disclosures, as provided in Section 1786.26."
  },
  ca_disclosure_spanish: {
    id: "app.containers.general.ca_disclosure_spanish",
    defaultMessage:
      "Turn Technologies no garantiza la exactitud o veracidad de la información en este reporte en cuanto al individuo investigado, sino que la información mostrada ha sido copiada exactamente de los archivos públicos. La información generada como resultado de robo de identidad, incluyendo evidencia de actividad criminal, puede ser asociada de manera errónea con el consumidor quien es sujeto de este reporte."
  },
  ca_disclosure_spanish2: {
    id: "app.containers.general.ficial and valid identificatio",
    defaultMessage:
      "Una agencia de investigación de información del consumidor debe proporcionar al consumidor que busca obtener una copia de un reporte o solicita revisar un documento, una notificación escrita en inglés y español simples y llanos, estableciendo los términos y condiciones de su derecho a recibir todas las divulgaciones, según lo previsto en la Sección 1786.26."
  },
  ca_email: {
    id: "app.containers.general.ca_email",
    defaultMessage: "compliance@turn.ai"
  }
});
