import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import Title from "../../../components/Title";
import messages from "../messages";
import PanelCollapsible from "../../../components/PanelCollapsible";

const WorkerManual = ({ worker }) =>
  worker.manual ? (
    <PanelCollapsible
      padding="10px 0"
      collapsible={false}
      titleComponent={
        <>
          <Title align="left" size="16px" width="100%" margin="0px 0px 20px">
            <FormattedMessage {...messages.pdfURL} />
          </Title>
          <a
            href={worker.pdfURL}
            target="blank"
            style={{ fontSize: "14px", fontWeight: "normal" }}
          >
            See their report
          </a>
        </>
      }
    />
  ) : null;
WorkerManual.propTypes = { worker: PropTypes.object.isRequired };

export default observer(WorkerManual);
