import React from "react";
import Container from "../../components/Container";
import AppContainer from "../../components/AppContainer";
import ErrorDialog from "../../components/ErrorDialog";
import SideBar from "../SideBar";
import member from "../../models/team-member";
import PropTypes from "prop-types";
import { PageType, PageEvent } from "../../services/analytics/gtm";
import { ga } from "../../services";

class ReportPage extends React.PureComponent {
  static propTypes = {
    auth: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      errorOpen: false
    };
  }

  componentDidMount() {
    ga.send(PageType.ReportPage, PageEvent.pageLoaded);
  }

  render() {
    const { errorOpen } = this.state;
    return (
      <AppContainer>
        <SideBar auth={this.props.auth} member={member} />
        <ErrorDialog open={errorOpen} onPress={this.closeError} />
        <Container
          large
          hasBoxShadow
          background="white"
          padding="20px"
          style={{ height: "93vh" }}
        />
      </AppContainer>
    );
  }
}

export default ReportPage;
