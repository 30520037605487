import React, { Component } from "react";
import { observer } from "mobx-react";
import { PropTypes } from "prop-types";
import _ from "lodash";
import ListItemCollapsible from "../ListItemCollapsible/new";
import Container from "../Container";
import Padder from "../SSNTrace/padder";
import { white, noticeError, aliceBlue } from "../../styles/constants";
import {
  WorkerStyles as styles,
  CandidateReportStyles as reportStyles
} from "../../styles";
import SSNModel from "../../models/ssn";
import Info from "../Info";
import Highlighted from "../Highlighted";
import { FormattedMessage } from "react-intl";
import messages from "../SSNTrace/messages";
import Row from "../Row";
import { List } from "material-ui/List";
import ToolTip from "../ToolTip";
import Footer from "./footer";
import withContext from "../../contextAPI/context-HOC";
import PanelCollapsible from "../PanelCollapsible";
import Text from "../Text";
import { SearchHighlighted } from "../SearchHighlighted";

export const SSNTrace = observer(
  class SSNTrace extends Component {
    constructor(props) {
      super(props);
      this.state = {
        expand: false
      };
      this.model = new SSNModel();
    }

    UNSAFE_componentWillMount() {
      if (!this.props.worker) return;
      this.model.setData(
        {
          ...this.props.worker.ssnChecks,
          is_deceased: this.props.worker.is_deceased
        },
        this.props.worker.manual,
        this.props.worker.is_random_ssn
      );
    }
    setExpand = expand => {
      this.setState({ expand });
    };
    toggleExpand = () => {
      this.setState(prevProps => ({ expand: !prevProps.expand }));
    };
    renderLegend(name) {
      if (this.model.isMatched(name)) return null;
      const key = `${name}Mismatch`;
      const message = messages[key];
      return (
        <Info
          message={message}
          styles={styles.ssn.row.legend}
          color={noticeError}
        />
      );
    }
    renderTooltipContent(message) {
      return (
        <div style={{ maxWidth: "400px" }}>
          <FormattedMessage {...message} />
        </div>
      );
    }
    renderTooltipHelp(id, message) {
      return (
        <span style={{ marginLeft: 10 }}>
          <ToolTip
            type="help"
            tooltipID={id}
            tooltipPlacement="bottom"
            tooltipContent={this.renderTooltipContent(message)}
          />
        </span>
      );
    }
    renderTooltipInfo(id, message) {
      return (
        <span style={styles.ssn.row.icon}>
          <ToolTip
            type="info"
            tooltipID={id}
            tooltipPlacement="bottom"
            tooltipContent={this.renderTooltipContent(message)}
          />
        </span>
      );
    }
    renderSSNIssueDate = () => {
      return (
        <div data="test-issue-date" style={styles.ssn.header}>
          <Text style={reportStyles.label}>Issued Date:</Text>
          <Text style={reportStyles.value}>
            <SearchHighlighted
              textToHighlight={
                this.props.worker.ssnIssueDate || "Not Available"
              }
            />
          </Text>
        </div>
      );
    };
    renderSSNIssueState = () => {
      return (
        <div data="test-issue-state" style={styles.ssn.header}>
          <Text style={reportStyles.label}>Issued State:</Text>
          <Text style={reportStyles.value}>
            <SearchHighlighted
              textToHighlight={
                this.props.worker.ssnIssueState || "Not Available"
              }
            />
          </Text>
        </div>
      );
    };
    renderSSNStatus = () => {
      if (!this.props.context.features.REACT_APP_SHOW_SSN_STATUS) return null;
      if (!this.props.worker.ssnStatus) return null;
      return (
        <div data="test-ssn-status" style={styles.ssn.header}>
          <ToolTip
            type="info"
            tooltipID="ssn.status"
            tooltipPlacement="top"
            error={this.props.worker.ssnStatus === "not_valid"}
            tooltipContent={
              <div>
                <Highlighted>Valid</Highlighted> - This is a valid SSN
                <br />
                <Highlighted>Invalid</Highlighted> - This SSN has not be issued
                to any person and is invalid
                <br />
                <Highlighted>Randomized</Highlighted> - This SSN is part of the
                SSA randomization program
                (https://www.ssa.gov/employer/randomization.html)
              </div>
            }
          />
          <Text style={reportStyles.label}>SSN Status:</Text>
          <Text style={reportStyles.value}>
            <SearchHighlighted
              textToHighlight={_.startCase(this.props.worker.ssnStatus)}
            />
          </Text>
        </div>
      );
    };
    renderSSN = () => {
      return (
        <Padder
          padding="30px 0%"
          style={{ borderBottom: `1px solid ${aliceBlue}` }}
        >
          <Row>
            {this.renderSSNIssueDate()}
            {this.renderSSNIssueState()}
            {this.renderSSNStatus()}
          </Row>
        </Padder>
      );
    };
    render() {
      if (!this.props.worker) return null;
      const { checks = {} } = this.props.context;
      return (
        <PanelCollapsible
          color={this.props.color}
          title={messages.title}
          titleStyle={reportStyles.title}
          icon={this.props.icon}
          iconStyle={reportStyles.checkedIcon}
          isCollapsbile
          expandAll
          expandAllProps={{
            toggled: this.state.expand,
            onClick: this.toggleExpand
          }}
          setExpand={this.setExpand}
          expandAllChecks={checks.expandAllChecks}
          panelProps={{
            style: {
              filter:
                "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
            }
          }}
        >
          {this.renderSSN()}
          <Container
            background={white}
            padding="10px 0"
            slim
            allowPagebreak
            margin="0"
          >
            <List style={styles.list}>
              {this.model.mortality.status ? (
                <ListItemCollapsible
                  title="Mortality"
                  data={this.model.mortality}
                  isMatched="false"
                  tooltip={this.renderTooltipInfo(
                    "SSNTrace.mortality.title",
                    messages.mortalityInfo
                  )}
                  expand={this.state.expand}
                />
              ) : null}
              <ListItemCollapsible
                title="Name"
                data={this.model.name}
                isMatched={this.model.isMatched("name")}
                legend={this.renderLegend("name")}
                tooltip={this.renderTooltipInfo(
                  "SSNTrace.name.title",
                  messages.nameInfo
                )}
                expand={this.state.expand}
              />
              {!this.model.manualMode ? (
                <ListItemCollapsible
                  title="Public Name"
                  data={this.model.public_records_name}
                  isMatched={this.model.isMatched("public_records_name")}
                  legend={this.renderLegend("public_records_name")}
                  tooltip={this.renderTooltipInfo(
                    "SSNTrace.public_records_name.title",
                    messages.public_records_nameInfo
                  )}
                  expand={this.state.expand}
                />
              ) : null}
              <ListItemCollapsible
                title="DOB"
                data={this.model.dob}
                isMatched={this.model.isMatched("dob")}
                legend={this.renderLegend("dob")}
                tooltip={this.renderTooltipInfo(
                  "SSNTrace.dob.title",
                  messages.dobInfo
                )}
                expand={this.state.expand}
              />
            </List>
          </Container>
          <Padder style={styles.ssn.footer} data="test-footer">
            <Footer
              lastSeen={this.props.worker.ssnLastSeen}
              firstSeen={this.props.worker.ssnFirstSeen}
            />
          </Padder>
        </PanelCollapsible>
      );
    }
  }
);

SSNTrace.propTypes = {
  worker: PropTypes.object
};

export default withContext(SSNTrace);
