import _ from "lodash";

const removeDuplicates = records => {
  const newArray = [];
  const lookupObject = {};

  for (var i in records) {
    lookupObject[records[i]["offense_description_1"]] = records[i];
  }
  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

const getValueWithLatestDate = sameCase => {
  const dates = sameCase.map(val => val.disposition_date);
  const latestDate = new Date(
    Math.max.apply(
      null,
      dates.map(function(e) {
        return new Date(e);
      })
    )
  );

  return sameCase.filter(val =>
    val.disposition_date === null
      ? true
      : new Date(val.disposition_date).toString() === latestDate.toString()
  );
};

const getRecordsWithSameDescription = (
  records,
  currentCase,
  currentDescription
) => {
  const sameCase = records.filter(
    val =>
      val.case_number === currentCase &&
      val.offense_description_1 === currentDescription
  );
  const notSame = records.filter(
    val =>
      val.case_number === currentCase &&
      val.offense_description_1 !== currentDescription
  );
  const filteredNotSame = removeDuplicates(notSame);
  const filteredSame = removeDuplicates(sameCase);
  const latestRecordWithSameCase = getValueWithLatestDate(filteredSame);
  return filteredNotSame.concat(latestRecordWithSameCase);
};

export const getFilteredRecords = records => {
  if (!records) return null;

  const reviewedNumbers = [];
  const filteredGroupWithSameCase = [];

  const filteredValue = records.filter(val => {
    const currentCase = val.case_number;
    const currentDescription = val.offense_description_1;
    if (!reviewedNumbers.includes(currentCase))
      reviewedNumbers.push(currentCase);
    else return false;

    const recordsWithSameDescription = getRecordsWithSameDescription(
      records,
      currentCase,
      currentDescription
    );
    recordsWithSameDescription.map(val => filteredGroupWithSameCase.push(val));
  });
  return filteredValue.concat(filteredGroupWithSameCase);
};

export function getUniqueByFields(array, callback) {
  const uniqueItems = _.uniqWith(array, callback);
  return _.isEmpty(uniqueItems) ? array : uniqueItems;
}

export function nationalCallback(item1, item2) {
  return (
    item1.case_number === item2.case_number &&
    item1.offense_description_1 === item2.offense_description_1
  );
}

export function soCallback(item1, item2) {
  return (
    item1.original_id === item2.original_id &&
    item1.offense_description1 === item2.offense_description1
  );
}

export function watchlistCallback(item1, item2) {
  return (
    item1.original_id === item2.original_id && item1.offense === item2.offense
  );
}
