import styled from "styled-components";
import { BREAKPOINT_XL, SIDEBAR_WIDTH } from "../../constants";
import withContext from "../../contextAPI/context-HOC";
import { primary } from "../../../src/styles/constants";

const AppContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${({ padding = `0 0 0 ${SIDEBAR_WIDTH}` }) => padding};
  background-color: ${primary};
  height: 100%;
  width: 100%;
  overflow-y: ${props =>
    props.context
      ? props.context.settings.openSettings
        ? "hidden"
        : "scroll"
      : "scroll"};
  overflow-x: hidden;
  @media (max-width: ${BREAKPOINT_XL}px) {
    padding: 0;
  }
  @media print {
    padding: 0;
    height: auto;
    width: auto;
    position: static;
  }
`;

export default withContext(AppContainer);
