import React from "react";
import PropTypes from "prop-types";

// Choosing different defaults for my condition to work out of the box
const Tab = ({ selected = "", value = null, children }) => {
  if (selected !== value) return null;
  return <div>{children}</div>;
};
Tab.propTypes = {
  selected: PropTypes.bool,
  value: PropTypes.string,
  children: PropTypes.node
};

export default Tab;
