import TurnLogoSVG from "../../icons/svgr/TurnLogoSVG";
import styled, { keyframes } from "styled-components";

const spinner = keyframes`
  0% { transform: rotateY(0deg); }
  50% { transform: rotateY(-360deg); }
  100% { transform: rotateY(-360deg); }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  justify-content: center;
  alignitems: center;
`;

export const ImageContainer = styled.div`
  margin-right: 0;
  height: 48px;
  width: 48px;
`;

export const Logo = styled(TurnLogoSVG)`
  width: 100%
  height: 100%;

  animation: ${spinner} 4s ease-in-out infinite;
`;
