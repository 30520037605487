import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { isArray, isEmpty, isPlainObject, startCase } from "lodash";
import { renderDetailValue, valueExists } from "turn-shared";

import DetailItemInline from "../DetailItem/detail-item-inline";

const renderMultipleDetails = (value, deep) => {
  if (isPlainObject(value)) return <DetailInline data={value} />; // deep
  return renderDetailValue(value, deep);
};

const Item = ({ property, value, deep, key, isImage }) => {
  const label = startCase(property);
  const renderedValue = renderMultipleDetails(value, deep);
  const detail = { label, value: renderedValue };
  return (
    <DetailItemInline
      property={property}
      detail={detail}
      key={key}
      width="100%"
      isImage={isImage}
    />
  );
};
Item.propTypes = {
  property: PropTypes.string,
  deep: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  key: PropTypes.number,
  isImage: PropTypes.bool
};

class DetailItem extends PureComponent {
  static propTypes = {
    property: PropTypes.string,
    data: PropTypes.object,
    deep: PropTypes.bool,
    exclude: PropTypes.array,
    isImage: PropTypes.bool
  };
  isExcluded = property => this.props.exclude.includes(property);
  render() {
    const { property, data, isImage } = this.props;
    const { isExcluded } = this;
    const value = data[property];

    if (!valueExists(value)) return null;
    if (isExcluded(property)) return null;
    if (isArray(value))
      return value.map((v, key) => (
        <Item value={v} isImage={isImage} key={key} {...this.props} />
      ));
    return <Item value={value} isImage={isImage} {...this.props} />;
  }
}

class DetailInline extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    deep: PropTypes.bool,
    exclude: PropTypes.array
  };
  static defaultProps = {
    exclude: []
  };
  render() {
    const { data } = this.props;
    if (!data) return null;

    const keys = Object.keys(data);
    if (isEmpty(keys)) return null;

    return keys.map((property, key) => {
      return data[property] &&
        typeof data[property] === "string" &&
        data[property].indexOf(".jpg") > 0 ? (
        <DetailItem property={property} key={key} isImage {...this.props} />
      ) : (
        <DetailItem property={property} key={key} {...this.props} />
      );
    });
  }
}
export default DetailInline;
