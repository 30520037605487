import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import { Icon, Button, MenuItem, withStyles } from "@material-ui/core";
import { getStatus } from "turn-shared";
import { capitalize } from "lodash";
import { Observer } from "mobx-react/custom";
import moment from "moment";

import { white, smokeWhite, primary } from "../../styles/constants";

import { styles as textFieldStyles } from "../../components/WorkerFilter/WorkerFilterTextField";
import { WorkerFilterSearchField } from "../../components/WorkerFilter/WorkerFilterSearchField";
import { WorkerFilterDateField } from "../../components/WorkerFilter/WorkerFilterDateField";
import { WorkerFilterDropdown } from "../../components/WorkerFilter/WorkerFilterDropdown";

const ScreeningSearchBackground = styled.div`
  border-radius: 7px;
  padding: 20px;
  background-color: ${primary};
  margin: 0 20px;
  color: ${white};

  // This is aproximately the min width the Search Bar
  // needs to render in a single line
  @media (min-width: 1158px) {
    position: sticky;
    top: 20px;
    z-index: 99;
  }
`;

const ScreeningSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const ScreeningSearchSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  max-width: 100%;

  @media (max-width: 1191px) {
    margin-bottom: 10px;
  }

  &:last-child {
    @media (min-width: 1208px) {
      margin-right: 0;
    }
    @media (max-width: 500px) {
      padding-right: 20px;
    }

    margin-bottom: 0;
  }
`;

const ScreeningSearchLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${white};
  margin-right: 10px;
`;

const styles = {
  button: {
    color: white
  },
  menuItem: {
    color: primary,
    fontSize: "14px",
    fontWeight: "bold"
  },
  textField: {
    ...textFieldStyles.root,
    borderRadius: 5
  },
  dateTextFieldLeft: {
    ...textFieldStyles.root,
    padding: "10px 17px",
    borderRadius: "5px 0 0 5px",
    borderRight: "none",
    "& input": {
      minWidth: 110
    },
    "& input::-webkit-datetime-edit": {
      minWidth: 86
    },
    "& input::-webkit-calendar-picker-indicator": {
      marginLeft: 0
    }
  },
  dateTextFieldRight: {
    ...textFieldStyles.root,
    padding: "10px 17px",
    borderRadius: "0 5px 5px 0",
    borderLeft: "none",
    "& input": {
      minWidth: 110
    },
    "& input::-webkit-datetime-edit": {
      minWidth: 86
    },
    "& input::-webkit-calendar-picker-indicator": {
      marginLeft: 0
    }
  },
  input: {
    ...textFieldStyles.input
  }
};

export const ScreeningSearchBarComponent = ({ model, classes, ...props }) => {
  const onReset = () => {
    model.reset();
  };

  return (
    <Observer>
      {() => (
        <ScreeningSearchBackground {...props}>
          <ScreeningSearchWrapper>
            <ScreeningSearchSection style={{ flex: 2 }}>
              <ScreeningSearchLabel>Search:</ScreeningSearchLabel>
              <WorkerFilterSearchField
                fullWidth
                name="search"
                placeholder="Name, keyword, email, Turn ID"
                onChangeText={text => model.setSearch(text)}
                value={model.search}
                InputProps={{
                  autocomplete: "new-password",
                  classes: { root: classes.textField, input: classes.input }
                }}
              />
            </ScreeningSearchSection>
            <ScreeningSearchSection style={{ flex: 1 }}>
              <ScreeningSearchLabel>Status:</ScreeningSearchLabel>
              <WorkerFilterDropdown
                style={{ flex: 1, background: smokeWhite }}
                value={model.status}
                onChange={({ target }) => model.setStatus(target.value)}
              >
                {model.statuses.map(
                  option =>
                    option !== "adverse" &&
                    option !== "created" && (
                      <MenuItem
                        key={option}
                        value={option}
                        style={styles.menuItem}
                      >
                        {capitalize(getStatus(option))}
                      </MenuItem>
                    )
                )}
              </WorkerFilterDropdown>
            </ScreeningSearchSection>
            <ScreeningSearchSection style={{ flex: 1 }}>
              <ScreeningSearchLabel>Action:</ScreeningSearchLabel>
              <WorkerFilterDropdown
                style={{ flex: 1, background: smokeWhite }}
                value={model.action}
                onChange={({ target }) => model.setAction(target.value)}
              >
                <MenuItem value="all" style={styles.menuItem}>
                  All
                </MenuItem>
                <MenuItem value="consent date" style={styles.menuItem}>
                  Consent
                </MenuItem>
                <MenuItem value="first notice" style={styles.menuItem}>
                  First Notice
                </MenuItem>
                <MenuItem value="bgc started date" style={styles.menuItem}>
                  BGC Started
                </MenuItem>
              </WorkerFilterDropdown>
            </ScreeningSearchSection>
            <ScreeningSearchSection style={{ flex: 2 }}>
              <ScreeningSearchLabel>Date Range:</ScreeningSearchLabel>
              <WorkerFilterDateField
                style={{ flex: 1 }}
                onChangeText={text => model.setStartDate(text)}
                value={model.startDate || ""}
                InputProps={{
                  classes: {
                    root: classes.dateTextFieldLeft,
                    input: classes.input
                  }
                }}
                inputProps={{
                  max: moment(model.endDate)
                    .add(-1, "day")
                    .format("YYYY-MM-DD")
                }}
              />
              <WorkerFilterDateField
                style={{ flex: 1 }}
                onChangeText={text => model.setEndDate(text)}
                value={model.endDate || ""}
                InputProps={{
                  classes: {
                    root: classes.dateTextFieldRight,
                    input: classes.input
                  }
                }}
                inputProps={{
                  max: moment().format("YYYY-MM-DD"),
                  min:
                    model.endDate &&
                    moment(model.startDate)
                      .add(1, "day")
                      .format("YYYY-MM-DD")
                }}
              />
            </ScreeningSearchSection>
          </ScreeningSearchWrapper>
          <div style={{ margin: "0 0 -20px 0", textAlign: "right" }}>
            <Button size="small" className={classes.button} onClick={onReset}>
              <Icon
                className="fas fa-redo"
                style={{ fontSize: 12, marginRight: 4 }}
              />
              Reset Filters
            </Button>
          </div>
        </ScreeningSearchBackground>
      )}
    </Observer>
  );
};
ScreeningSearchBarComponent.propTypes = {
  model: propTypes.any
};

export const ScreeningSearchBar = withStyles(styles)(
  ScreeningSearchBarComponent
);
ScreeningSearchBar.propTypes = {
  ...ScreeningSearchBarComponent.propTypes
};
