import { primary, secondary, tartOrange } from "../../styles/constants";

const styles = {
  inputLabel: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: "rgba(42, 42, 42, 0.6)",
    marginLeft: "1rem",
    width: "100%"
  },
  modal: {
    title: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 20,
      color: "#393E59",
      margin: 0
    },
    downloadText: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 13,
      margin: "8px 0 16px 0"
    },
    downloadLink: {
      textDecorationLine: "underline",
      color: "#01D8CE",
      cursor: "pointer"
    },
    cancelButton: {
      width: "96px",
      height: "27px",
      backgroundColor: "#EBEBED"
    },
    nextButton: {
      backgroundColor: "#393E59",
      width: "96px",
      height: "27px",
      color: "white"
    },
    editButton: {
      width: "96px",
      height: "25px",
      color: primary,
      marginLeft: "16px"
    }
  },
  textField: {
    background: "#F9F9FB",
    border: "0.693356px solid #EBEBED",
    marginTop: "7px",
    fontWeight: "normal"
  },
  select: {
    background: "#F9F9FB",
    border: "0.693356px solid #EBEBED",
    width: "100%",
    marginTop: "7px"
  },
  progressContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    textAlign: "center",
    padding: 20,
    opacity: 0.5,
    top: 0,
    left: 0,
    zIndex: 2,
    background: "white"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  },
  dropzone: {
    container: {
      border: "0.706883px dashed #EBEBED",
      boxSizing: "border-box",
      borderRadius: 4.2413,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    containerActive: {
      border: "0.706883px dashed #EBEBED",
      boxSizing: "border-box",
      borderRadius: 4.2413,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      opacity: 0.5
    },
    icon: {
      margin: 16
    },
    title: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
      color: "#2A2A2B",
      margin: 0
    },
    subtitle: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      color: "#2A2A2B",
      margin: 0
    },
    text: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      color: "#2A2A2B"
    },
    files: {
      container: {
        background: "#F9FAFF",
        border: "0.707143px solid #EBEBED",
        boxSizing: "border-box",
        borderRadius: 4.24286,
        padding: 20
      },
      header: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-between"
      },
      titleSuccess: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 14,
        color: "#0FCF67",
        margin: 0
      },
      titleFailed: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 14,
        color: "#FF1D25",
        margin: 0
      },
      check: {
        background: "#CAEDCD",
        width: 20,
        borderRadius: "50%"
      },
      close: {
        background: "#FFE4E8",
        width: 20,
        borderRadius: "50%"
      },
      file: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 12,
        color: "#2A2A2B"
      }
    }
  },
  imgContainer: {
    width: "100%",
    height: "240px",
    margin: "0 auto"
  },
  imgDimensionsContainer: {
    maxWidth: "240px",
    maxHeight: "240px",
    margin: "0 auto"
  },
  headerImage: {
    width: "100%",
    height: "auto"
  },
  messages: {
    height: "134px",

    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  gralMessageContainer: {
    width: "288px",
    fontSize: "24px",
    fontWeight: "bold",

    flex: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center"
  },
  specificMessageContainer: {
    width: "288px",
    overflowY: "auto",

    fontSize: "16px",
    fontWeight: "normal",

    flex: 7,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    bottom: 0
  },
  closeButtonContainer: {
    height: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  closeWindowButton: {
    width: "204px",
    height: "25px",
    color: tartOrange
  },
  blackButtonContainer: {
    height: "83px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  blackButtonEmailSent: {
    backgroundColor: secondary,
    width: "230px",
    height: "40px",
    color: "white"
  }
};

export default styles;
