import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import withContext from "../../contextAPI/context-HOC";
import member from "../../models/team-member";
import WorkerSettings from "../../components/WorkerSettings";

import Settings from "../../models/settings";
import memberModel from "../../models/team-member";

import { TableStyles as styles } from "../../styles";
import Page from "../../components/Page";

import { PageType, PageEvent } from "../../services/analytics/gtm";
import { ga } from "../../services";

class SettingsPage extends React.PureComponent {
  static propTypes = {
    params: PropTypes.object,
    router: PropTypes.object,
    location: PropTypes.object,
    auth: PropTypes.object,
    context: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.settings = new Settings(props.context.settings);
  }

  async UNSAFE_componentWillMount() {
    this.auth = this.props.auth;
    if (!memberModel.id) memberModel.retrieveMember();
  }

  componentDidMount() {
    ga.send(PageType.SettingsPage, PageEvent.pageLoaded);
  }

  render() {
    return (
      <Page
        sideBarProps={{
          auth: this.auth,
          member: member
        }}
        backgroundProps={{
          style: styles.background
        }}
        appHeaderProps={{
          auth: this.auth,
          model: this.settings,
          history: this.props.history,
          fullwidth: true
        }}
      >
        <WorkerSettings model={this.settings} />
      </Page>
    );
  }
}

export default withContext(observer(SettingsPage));
