const PageType = {
  BackgroundReportPage: "BackgroundReportPage",
  BackgroundReportPDF: "BackgroundReportPDF",
  CreateJobPost: "CreateJobPostPage",
  PartnerSpecificBGC: "PartnerSpecifcBGC",
  Permission: "Permission",
  WorkerPage: "WorkerPage",
  WorkersPage: "WorkersPage",
  BackgroundReportSucess: "BackgroundReportSuccess",
  Disclosure: "Disclosure",
  JobApplicantsPage: "JobApplicantsPage",
  JobListing: "JobListing",
  JobNewItemPage: "JobNewItemPage",
  LocationListing: "LocationListing",
  ReportPage: "ReportPage",
  ScreeningDashboard: "ScreeningDashboard",
  SettingsPage: "SettingsPage"
};

const PageEvent = {
  pageLoaded: "page_loaded",
  pageView: "pageview"
};

const GTMPageEvent = (() => {
  let id = null,
    gtmManager = null;

  return {
    init: function(initManager, gtmId) {
      gtmManager = gtmManager === null ? initManager : gtmManager;
      id = id === null ? gtmId : id;
      return this;
    },
    setID: gtmId => {
      id = gtmId;
    },
    run: function() {
      if (id && gtmManager) {
        const tagManagerArgs = {
          gtmId: id
        };
        gtmManager.initialize(tagManagerArgs);
      }
      return this;
    },
    send: (page, event) => {
      if (window && window.dataLayer) {
        window.dataLayer.push({
          PageType: page,
          event: event
        });
      }
    }
  };
})();

export { GTMPageEvent, PageEvent, PageType };
