import React from "react";
import FlatButton from "material-ui/FlatButton";

import { secondary } from "../../styles/constants";

const styles = {
  color: {
    color: secondary
  },
  labelStyle: {
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "bold"
  }
};

export const WorkerFilterReset = props => {
  return (
    <FlatButton
      labelStyle={{ ...styles.color, ...styles.labelStyle }}
      icon={<i className="fas fa-redo-alt" style={styles.color} />}
      {...props}
    />
  );
};
