import { observable, computed, decorate } from "mobx";

/**
 * Base class to easily create Models depending on remote data.
 *
 * IMPORTANT: MULTIPLE ENDPOINTS IN A SINGLE MODEL WILL NOT WORK. This is not meant
 * to be used that way. It's actually an anti-pattern.
 *
 * @example
 *  class MyModel extends RemoteModel {
 *     async doIt() {
 *        const result = await this.awaitRequest(fetch('/api/my/endpoint'));
 *     }
 *  }
 *  decoate(MyModel, { loading: computed });
 */
export class RemoteModel {
  latestPromise = undefined;
  latestResponse = undefined;
  error = undefined;

  /**
   * @returns {boolean} Are we currently awaiting a Promise?
   */
  get loading() {
    return !!this.latestPromise;
  }

  /**
   * Awaits a Promise and only returns the most recent Promise resolved, and updates
   * the model to let any subscriber know we're loading..
   *
   * This is useful for when you want to only fetch data once, but the user might
   * request new data before it arrives.
   *
   * Implementors should check the result is actually different from the current state
   * they have to prvent screen flashing/blinking.
   *
   * @example
   *  const model = new RemoteModel();
   *  const result = await Promise.all([
   *    model.awaitPromise(new Promise(resolve => setTimeout(() => resolve(1), 300))),
   *    model.awaitPromise(new Promise(resolve => setTimeout(() => resolve(2), 200))),
   *    model.awaitPromise(new Promise(resolve => setTimeout(() => resolve(3), 100))),
   *  ]);
   *
   *  // `result` will be [3, 3, 3] - magic!
   *
   * @param {Promise<T>} promise Usually, an `axios` request promise.
   * @returns T
   */
  async awaitPromise(promise) {
    let result = undefined;
    this.latestPromise = promise;
    try {
      result = await promise;
      this.error = null;
    } catch (e) {
      this.error = e;
    }

    // This is a lock to prevent unsetting the latestPromise if a newer one exists
    if (this.latestPromise === promise) {
      this.latestPromise = null;
      this.latestResponse = result;
    }

    return this.latestResponse;
  }

  /**
   * Await a Request Promise and return the result.
   *
   * @param {Promise<any>} requestPromise Usually, an `axios` request promise.
   * @returns any The result of the request.
   */
  async awaitRequest(requestPromise) {
    const response = await this.awaitPromise(requestPromise);
    if (response && response.status == 200) {
      return response;
    } else {
      this.error = response;
      return null;
    }
  }
}

decorate(RemoteModel, {
  loading: computed,
  error: observable,
  latestPromise: observable,
  latestResponse: observable
});
