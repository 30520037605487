import styled from "styled-components";

const CollapsibleIconContainer = styled.div`
  display: ${({ collapsed }) => (collapsed ? "flex" : "inline")};
  width: ${({ collapsed }) => collapsed && "100%"};
  justify-content: ${({ collapsed }) => collapsed && "center"};
  margin-top: ${({ collapsed }) => collapsed && "20px"};
`;

export default CollapsibleIconContainer;
