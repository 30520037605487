import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./styles";
import WorkerActions from "../actions";
import WorkerFinder from "../finder";
import withContext from "../../../contextAPI/context-HOC";
import ExpandAll from "../../../components/ExpandAll";
import ExpandAllContainer from "./expandAllContainer";

export class WorkerHeader extends Component {
  static propTypes = {
    worker: PropTypes.object
  };

  state = {
    action: "none"
  };

  setAction = action => {
    this.setState({ action });
  };

  componentWillUnmount() {
    const { checks = {} } = this.props.context;

    if (checks.setExpandAllChecks) {
      checks.setExpandAllChecks(false);
    }
  }

  handleClick = () => {
    const { checks = {} } = this.props.context;

    if (checks.setExpandAllChecks) {
      checks.setExpandAllChecks(!checks.expandAllChecks);
    }
  };

  render() {
    const { checks = {} } = this.props.context;
    return (
      <div style={styles.container}>
        <div style={{ width: "54%" }}>
          <WorkerActions {...this.props} />
        </div>
        <div style={styles.rightActions}>
          <WorkerFinder />
          <ExpandAllContainer>
            <ExpandAll
              toggled={checks.expandAllChecks}
              onClick={this.handleClick}
              style={styles.expandAll}
            />
          </ExpandAllContainer>
        </div>
      </div>
    );
  }
}

export default withContext(WorkerHeader);
