import React, { useCallback, useEffect } from "react";
import { ReactComponent as BackIcon } from "../../icons/back.svg";
import { ReactComponent as FrontIcon } from "../../icons/front.svg";
import { ReactComponent as FaceIcon } from "../../icons/face.svg";
import { ReactComponent as WarningIcon } from "../../icons/red-warning.svg";
import { ReactComponent as CheckIcon } from "../../icons/green-check.svg";
import Resizer from "react-image-file-resizer";

import styles from "./styles.js";
import Column from "../Column";
import Row from "../Row";
import Title from "../Title";
import UploadZone from "./uploadzone";
import { turnAPI } from "../../services";
import { useDropzone } from "react-dropzone";
import env from "@mars/heroku-js-runtime-env";

import { TurnLoader } from "../Loader";

function UploadIdZone({ title, upload, doc_type, details, checkId }) {
  const [files, setFiles] = React.useState([]);
  const uploadCallBack = upload;
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const maxLength = 8;
  const BYTE_DIVISOR = 1000000;
  // Configs are [width, height, compression quality, format]

  const BASE64_IMG_CONFIGS = env().REACT_APP_BASE64_IMG_CONFIGS
    ? JSON.parse(env().REACT_APP_BASE64_IMG_CONFIGS)
    : [1280, 720, 100, "JPEG"];

  const Img = {
    Front: FrontIcon,
    Back: BackIcon,
    Selfie: FaceIcon
  };

  const sizeValidator = file => {
    // convert bytes to megabytes
    const fileSize = file.size / BYTE_DIVISOR;
    setError("");
    if (fileSize > maxLength) {
      return {
        code: "file-too-large",
        message: `File is larger than ${maxLength} MB`
      };
    }

    return null;
  };

  const uploadBase64Id = async (file, mimeType) => {
    const formData = new FormData();
    formData.append("image", new Blob(file, { type: mimeType }));
    formData.append("id", details.turn_id || checkId);
    formData.append("doc_type", doc_type || "document_image");
    turnAPI.setLoading(true, "uploadBase64Id");

    setLoading(false);

    return await turnAPI.uploadBase64Id(formData);
  };

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        BASE64_IMG_CONFIGS[0], // max width
        BASE64_IMG_CONFIGS[1], // max height
        BASE64_IMG_CONFIGS[3], // format
        BASE64_IMG_CONFIGS[2], // compression quality for JPEG
        0,
        async uri => {
          resolve(uri);
        },
        "file"
      );
    });

  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length < 1) {
      setError(`File is larger than ${maxLength} MB`);
      return;
    }

    let resizedImages = [];
    // resize images larger than 2mb
    if (acceptedFiles[0].size / BYTE_DIVISOR > 2) {
      try {
        const resizedImage = await resizeFile(acceptedFiles[0]);
        resizedImages = [resizedImage];
      } catch {
        setError("Please upload a smaller image (< 2mb)");
      }
    }
    const filesToUpload =
      resizedImages.length > 0 ? resizedImages : acceptedFiles;
    setError("");
    setFiles(() => [...filesToUpload]);

    const isRealId = fields =>
      fields.isRealId !== undefined && fields.isRealId.value == "Yes";
    const isDriverLicense = fields =>
      fields.documentType !== undefined &&
      fields.documentType.value == "Driver license";
    const isValidDriverLicense = fields =>
      fields.licenseNumber !== undefined &&
      fields.licenseNumber.isValid == true;

    const response = await uploadBase64Id(filesToUpload, filesToUpload[0].type);
    const { data, status } = response;
    const { fields } = data || {};

    setLoading(true);

    if (status == 201) {
      uploadCallBack(response, title);
      return;
    }

    if (status == 200) {
      if (details.doMVR && !isDriverLicense(fields)) {
        setError("Please upload a valid driver's license.");
        return;
      }

      if (
        isRealId(fields) ||
        (isDriverLicense(fields) && isValidDriverLicense)
      ) {
        uploadCallBack(response, title);
        return;
      }

      setError("Invalid ID.");
      return;
    }

    //enforcing validation of nested attributes
    if (data && data.msg) {
      setError(data.msg);
    } else {
      setError("An unexpected error trying to upload, please try again.");
    }
  }, []);

  const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
    onDrop,
    accept: [
      "image/jpeg",
      "image/gif",
      "image/png",
      "image/bmp",
      "image/heic",
      "image/heif",
      "image/webp",
      "image/tiff"
    ],
    validator: sizeValidator,
    noClick: true
  });

  useEffect(() => {
    setFiles(files);
  }, [files]);

  const imgURI = images => {
    return images.map(file => URL.createObjectURL(file));
  };

  const UploadActive = ({ title }) => {
    return (
      <>
        <Row>
          <Column width="100%" padding="10px 10px 0 10px ">
            <CheckIcon
              style={{
                position: "absolute",
                right: "10px",
                top: "10px"
              }}
            />
            <b>{title}</b>
          </Column>
        </Row>
        <Row>
          <Column width="100%" padding="15px 14px 0 14px">
            <img
              id={`${title}ImageUploaded`}
              style={styles.img2Upload}
              src={imgURI(files)}
            />
            <br />
            <span
              id="changePhotoButton"
              onClick={open}
              style={styles.labelChangePhoto}
            >
              Change Photo
            </span>
          </Column>
        </Row>
      </>
    );
  };

  const UploadError = ({ title }) => {
    return (
      <>
        <Row>
          <Column width="100%" padding="10px 10px 0 10px ">
            <WarningIcon
              style={{
                position: "absolute",
                right: "10px",
                top: "10px"
              }}
            />
            <b>{title}</b>
          </Column>
        </Row>
        <Row>
          <Column width="100%" padding="15px 14px 0 14px">
            <Title
              id={`${title}UploadErrorMessage`}
              style={{
                ...styles.labelError
              }}
            >
              Upload Error
            </Title>
            <p style={{ fontSize: "12px" }}>Below is a quick summary:</p>
            <p
              style={{
                fontWeight: "700",
                fontSize: "12px"
              }}
            >
              {error}
            </p>
            <span
              id={`${title}UploadOtherFileButton`}
              onClick={open}
              style={styles.labelChangePhoto}
            >
              Upload other file
            </span>
          </Column>
        </Row>
      </>
    );
  };

  const UploadIcon = Img[title];

  return (
    <>
      <UploadZone>
        <div {...getRootProps()}>
          <input id={`input${title}`} {...getInputProps()} />
          {fileRejections.length < 1 && error == "" && files.length < 1 && (
            <>
              <Row>
                <Column width="100%" padding="10px 10px 0 10px ">
                  <UploadIcon
                    style={{
                      cursor: "pointer"
                    }}
                    onClick={open}
                  />
                  <br />
                  <b>{title}</b>
                </Column>
              </Row>
              <Row>
                <Column width="100%" padding="15px 14px 0 14px">
                  <p style={{ ...styles.dragDropText }}>
                    Drag and Drop or{" "}
                    <span onClick={open} style={styles.labelChangePhoto}>
                      browse
                    </span>{" "}
                    to upload a JPG, PNG, GIF file.
                  </p>
                </Column>
              </Row>
            </>
          )}
          {error !== "" && <UploadError title={title} />}
          {fileRejections.length < 1 && error == "" && files.length > 0 && (
            <UploadActive title={title} />
          )}
        </div>
      </UploadZone>
      <TurnLoader hidden={loading}> Uploading Document</TurnLoader>
    </>
  );
}

export default UploadIdZone;
