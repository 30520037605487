import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import { CandidateReportStyles as styles } from "../../styles";
import { formatPhone } from "turn-shared";
import messages from "./messages";
import SectionTitle from "./SectionTitle";
import Clearfix from "./Clearfix";
import DataItem from "./DataItem";
import CheckCircleIcon from "material-ui/svg-icons/action/check-circle";
import ClearIcon from "material-ui/svg-icons/content/clear";
import Text from "../Text";

import { faUser } from "@fortawesome/pro-solid-svg-icons";

class ApplicantInfo extends PureComponent {
  componentDidMount() {}
  getConfirmationIcon = confirmation =>
    confirmation ? (
      <CheckCircleIcon style={styles.labelCheckIcon} />
    ) : (
      <ClearIcon style={styles.labelClearIcon} />
    );
  getConfirmationText = confirmation => (confirmation ? "Yes" : "No");

  get smallWidth() {
    if (this.props.isPDFReport) return "25%";
    return "100%";
  }
  render() {
    return (
      <div style={styles.section}>
        <div style={styles.checks.infoContainer}>
          <SectionTitle
            title={<FormattedMessage {...messages.applicantInformation} />}
            icon={faUser}
          />
          {this.props.printPage ? (
            <Text
              id="downloadReport"
              cursor="pointer"
              onClick={this.props.printPage}
              margin="0px 10px"
            >
              Download Report
            </Text>
          ) : null}
        </div>
        <div style={styles.sectionContent}>
          <DataItem
            width="25%"
            widthMD="50%"
            printWidth="33%"
            widthSM={this.smallWidth}
            label={messages.fullLegal}
            text={this.props.model.fullName}
          />
          <DataItem
            width="25%"
            widthMD="50%"
            printWidth="33%"
            widthSM={this.smallWidth}
            label={messages.dob}
            text={this.props.model.candidate_provided_dob}
          />
          <DataItem
            width="25%"
            widthMD="50%"
            printWidth="33%"
            widthSM={this.smallWidth}
            label={messages.turnId}
            text={this.props.model.turnID}
          />
          {this.props.model.driversLicense &&
            this.props.model.driversLicense !== "Not Provided" && (
              <DataItem
                width="25%"
                widthMD="50%"
                printWidth="33%"
                widthSM={this.smallWidth}
                label={messages.driverLicence}
                text={this.props.model.driversLicense}
              />
            )}
          {this.props.model.hasCarInsurance !== undefined &&
            this.props.model.hasCarInsurance !== null &&
            this.props.model.hasCarInsurance !== "" && (
              <DataItem
                inlineLabel
                width="25%"
                widthMD="50%"
                printWidth="33%"
                widthSM={this.smallWidth}
                label={messages.hasCarInsurance}
                text={this.getConfirmationText(
                  this.props.model.hasCarInsurance
                )}
                icon={this.getConfirmationIcon(
                  this.props.model.hasCarInsurance
                )}
              />
            )}
          {this.props.model.doSSNTrace ? (
            <DataItem
              width="25%"
              widthMD="50%"
              printWidth="33%"
              widthSM={this.smallWidth}
              label={messages.ssn}
              text={this.props.model.ssn.number}
              noMargin
            />
          ) : null}
          {this.props.model.doSSNTrace ? (
            <DataItem
              width="25%"
              widthMD="50%"
              printWidth="33%"
              widthSM={this.smallWidth}
              label={messages.location}
              text={this.props.model.currentAddress}
              noMargin
            />
          ) : null}
          <DataItem
            width="25%"
            widthMD="50%"
            printWidth="33%"
            widthSM={this.smallWidth}
            label={messages.phoneNumber}
            text={formatPhone(this.props.model.phoneNumber)}
            noMargin
          />
          <DataItem
            width="25%"
            widthMD="50%"
            printWidth="33%"
            widthSM={this.smallWidth}
            label={messages.email}
            text={this.props.model.email}
            noMargin
          />
          <Clearfix />
        </div>
      </div>
    );
  }
}

ApplicantInfo.propTypes = {
  model: PropTypes.object.isRequired
};

export default ApplicantInfo;
