import { periwinkle } from "../../styles/constants";

const styles = {
  listItemContainer: {
    height: "60px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    borderBottom: `1px solid ${periwinkle}`
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",

    flex: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "12px"
  },
  value: {
    fontSize: "14px",
    fontWeight: "normal",

    flex: 7,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
};

export default styles;
