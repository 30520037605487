import styled from "styled-components";

const DetailDescription = styled.dd`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 5px 0 0 5px;
  font-size: ${props => props.fontSize || "inherit"}
  font-weight: 300;
  font-size: 14px;
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  font-weight: normal;
`;

export default DetailDescription;
