import { primary } from "../../../styles/constants";

export const titleStyles = {
  display: "inline-block",
  margin: "0 0 0 30px"
};
export const expandStyles = {
  display: "inline-block",
  margin: "0 0 0 30px",
  textDecoration: "none",
  fontWeight: 300,
  fontSize: 18,
  cursor: "pointer"
};
export const clearStyles = {
  color: primary,
  fontWeight: "bold",
  display: "block",
  textTransform: "uppercase",
  padding: 0,
  margin: 0
};
export const errorStyles = {
  ...clearStyles,
  fontWeight: "normal",
  color: primary,
  fontSize: "14px"
};

export const autoapproveStyles = {
  fontSize: "0.7em",
  lineHeight: "31px",
  width: "100%",
  textAlign: "left"
};
