import styled from "styled-components";
import { BREAKPOINT_XL } from "../../constants";

const ShowLarge = styled.div`
  @media (min-width: ${BREAKPOINT_XL + 1}px) {
    display: ${({ show }) => (show ? "block" : "none")};
  }
`;

export default ShowLarge;
