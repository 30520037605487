import { primary } from "../constants";

const style = {
  wrapper: {
    display: "inline-flex",
    marginRight: "20px"
  },
  icon: {
    height: "14px",
    width: "14px",
    fontSize: "14px"
  },
  flatButton: {
    color: primary
  },
  flatButtonLabel: {
    textTransform: "capitalize !important",
    fontWeight: "bold",
    fontSize: "14px"
  }
};
export default style;
