import styled from "styled-components";

import { slateGray } from "../../styles/constants";

export const WorkerFilterLabel = styled("div")`
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: ${slateGray};
`;
