import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toTitleCase } from "turn-shared";
import _ from "lodash";

import { CandidateReportStyles as styles } from "../../styles";
import { red, green } from "../../styles/constants";
import { HIT, CLEAR, ON_DEMAND_CRIM } from "../../constants";
import {
  faCheckCircle,
  faExclamationTriangle
} from "@fortawesome/pro-solid-svg-icons";
import Row from "../Row";
import Divider from "../Divider";
import Text from "../Text";
import Title from "../Title";

import DataItem from "./DataItem";
import messages from "./messages";
import Check from "./Check";
import Column from "../Column";
import Link from "../Link";

class CriminalRecord extends PureComponent {
  static propTypes = {
    criminal: PropTypes.array.isRequired,
    reportDate: PropTypes.string,
    pendingMVRReview: PropTypes.bool.isRequired,
    pendingAgeReview: PropTypes.bool.isRequired
  };
  constructor(props) {
    super(props);
    this.criminal =
      props.criminal && props.criminal.length > 0 ? props.criminal : false;
    this.reportDate = props.reportDate;
    this.renderCriminalRecord = props.criminal;
  }
  UNSAFE_componentWillMount() {}
  getState = record => {
    if (record.state) return record.state;
    return record.source_state || record.suspect_state;
  };

  formatCounty = county => {
    if (!_.isString(county)) return "";
    const split = county.split("-");
    // Are all counties 2 length
    const _county = split.filter(string => string.length === 2)[0];
    const _state = _.upperFirst(
      _.lowerCase(split.filter(string => string.length !== 2)[0])
    );
    return `${_county}-${_state}`;
  };

  renderOneOffChecks = (key, record) => {
    return (
      <div key={key}>
        <Row>
          <div
            style={{
              ...styles.summary.container,
              ...styles.checks.titleContainer
            }}
          >
            <Title color={"black"} style={styles.summary.subtitle} size="14px">
              <FontAwesomeIcon
                icon={
                  record.value && record.value.length > 0
                    ? faExclamationTriangle
                    : faCheckCircle
                }
                style={styles.summary.icon}
                color={record.value && record.value.length > 0 ? red : green}
              />
              {this.formatCounty(record.county)}
            </Title>
            <Text
              size="14px"
              weight="bold"
              style={styles.summary.status}
              color={record.resolution.toLowerCase() == CLEAR ? green : red}
            >
              {toTitleCase(record.resolution)}
            </Text>
            <Column width="20%" widthMD="100%">
              <Text>
                {!!record.report_url && (
                  <Text>
                    <Link
                      style={styles.summary.status}
                      link="View Report"
                      href={record.report_url}
                    />
                  </Text>
                )}
              </Text>
            </Column>
          </div>
        </Row>
      </div>
    );
  };

  render() {
    const {
      pendingMVRReview,
      pendingAgeReview,
      isPDFReport,
      ...rest
    } = this.props;

    const defaultCheckNameId = "detailCheckName";
    const defaultCheckStatusId = "detailCheckStatus";
    const defaultCheckSectionId = "detailCheckSection";

    if (!this.renderCriminalRecord) return null;
    if (pendingMVRReview && this.renderCriminalRecord) {
      return (
        <div style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.criminalRecords} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <div style={styles.sectionContent}>
            <p style={styles.pendingMVR}>
              <FormattedMessage {...messages.pendingMVR} />
            </p>
          </div>
          <Divider height="1px" />
        </div>
      );
    }
    if (pendingAgeReview && this.renderCriminalRecord) {
      return (
        <div id={defaultCheckSectionId} style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.criminalRecords} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <div style={styles.sectionContent}>
            <p id="pendingAgeMessage" style={styles.pendingMVR}>
              <FormattedMessage {...messages.pendingAge} />
            </p>
          </div>
          <Divider height="1px" />
        </div>
      );
    }
    if (!this.criminal) {
      return (
        <div id={defaultCheckSectionId} style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.criminalRecords} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <div style={styles.sectionContent}>
            <Divider height="1px" />
          </div>
        </div>
      );
    }
    // When hits are not grouped by county render old way
    if (!this.criminal.some(({ value }) => value)) {
      return (
        <div id={defaultCheckSectionId} style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.criminalRecords} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />{" "}
          <div style={styles.sectionContent}>
            {this.criminal.map((record, key) => {
              return (
                <>
                  {record.name === ON_DEMAND_CRIM ? (
                    this.renderOneOffChecks(key, record)
                  ) : (
                    <Row key={key} style={styles.checks.criminalContainer}>
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.offenseDescription}
                        text={record.offense_description_1}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.county}
                        text={toTitleCase(record.crime_county || record.county)}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.plea}
                        text={
                          record.plea
                            ? toTitleCase(record.plea)
                            : "No plea entered"
                        }
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.crimeType}
                        text={toTitleCase(
                          record.crime_type ? record.crime_type : ""
                        )}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.source}
                        text={toTitleCase(record.source)}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.offenseDay}
                        text={record.offense_date}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.state}
                        text={this.getState(record)}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.disposition}
                        text={record.disposition}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.disposition_date}
                        text={record.disposition_date}
                      />
                      <DataItem
                        width="50%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.charges_filed_date}
                        text={record.charges_filed_date}
                      />
                      <DataItem
                        width="50%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.sentence}
                        text={record.sentence}
                      />
                      <Divider checked={`Checked on ${this.reportDate}`} />
                    </Row>
                  )}
                </>
              );
            })}
          </div>
        </div>
      );
    }
    this.criminal.sort(({ value }) => (value && value.length > 0 ? 1 : -1));

    return (
      <div id={defaultCheckSectionId} style={styles.section}>
        <Check
          check={<FormattedMessage {...messages.criminalRecords} />}
          noDivider
          containerStyle={styles.checks.titleContainer}
          nameId={defaultCheckNameId}
          statusId={defaultCheckStatusId}
          {...rest}
        />{" "}
        <div style={styles.sectionContent}>
          {this.criminal.map((record, key) => {
            const recordCounty = county => {
              if (county === "Other Counties") return county;
              const lowerCaseCounty = county.split("-");
              if (lowerCaseCounty.length === 1) return county;

              lowerCaseCounty[1] = lowerCaseCounty[1].toLowerCase();
              lowerCaseCounty[1] =
                lowerCaseCounty[1].charAt(0).toUpperCase() +
                lowerCaseCounty[1].slice(1);
              return lowerCaseCounty.join("-");
            };
            if (record.name === ON_DEMAND_CRIM) {
              return this.renderOneOffChecks(key, record);
            }
            if (!record.value) {
              return (
                <Row key={key} style={styles.checks.criminalContainer}>
                  <DataItem
                    width="25%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.offenseDescription}
                    text={record.offense_description_1}
                  />
                  <DataItem
                    width="25%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.county}
                    text={toTitleCase(record.crime_county || record.county)}
                  />
                  <DataItem
                    width="25%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.plea}
                    text={
                      record.plea ? toTitleCase(record.plea) : "No plea entered"
                    }
                  />
                  <DataItem
                    width="25%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.crimeType}
                    text={toTitleCase(
                      record.crime_type ? record.crime_type : ""
                    )}
                  />
                  <DataItem
                    width="25%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.source}
                    text={toTitleCase(record.source)}
                  />
                  <DataItem
                    width="25%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.offenseDay}
                    text={record.offense_date}
                  />
                  <DataItem
                    width="25%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.state}
                    text={this.getState(record)}
                  />
                  <DataItem
                    width="25%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.disposition}
                    text={record.disposition}
                  />
                  <DataItem
                    width="25%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.disposition_date}
                    text={record.disposition_date}
                  />
                  <DataItem
                    width="50%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.charges_filed_date}
                    text={record.charges_filed_date}
                  />
                  <DataItem
                    width="50%"
                    widthMD="50%"
                    widthSM={isPDFReport ? "25%" : "100%"}
                    label={messages.sentence}
                    text={record.sentence}
                  />
                  <Divider checked={`Checked on ${this.reportDate}`} />
                </Row>
              );
            }
            return (
              <Row key={key}>
                <div
                  style={{
                    ...styles.summary.container,
                    ...styles.checks.titleContainer
                  }}
                >
                  <Title
                    color={"black"}
                    style={styles.summary.subtitle}
                    size="14px"
                  >
                    <FontAwesomeIcon
                      icon={
                        record.value && record.value.length > 0
                          ? faExclamationTriangle
                          : faCheckCircle
                      }
                      style={styles.summary.icon}
                      color={
                        record.value && record.value.length > 0 ? red : green
                      }
                    />
                    {recordCounty(record.county)}
                  </Title>
                  <Text
                    size="14px"
                    weight="bold"
                    style={styles.summary.status}
                    color={
                      record.value && record.value.length > 0 ? red : green
                    }
                  >
                    {record.value && record.value.length > 0
                      ? _.upperFirst(HIT)
                      : _.upperFirst(CLEAR)}
                  </Text>
                  {record.value &&
                    record.value.map((crime, key) => (
                      <>
                        <Row key={key} style={styles.checks.hitInfoContainer}>
                          <DataItem
                            width="25%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.offenseDescription}
                            text={crime.offense_description_1}
                          />

                          <DataItem
                            width="25%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.plea}
                            text={
                              crime.plea
                                ? toTitleCase(crime.plea)
                                : "No plea entered"
                            }
                          />
                          <DataItem
                            width="25%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.crimeType}
                            text={toTitleCase(
                              crime.crime_type ? crime.crime_type : ""
                            )}
                          />
                          <DataItem
                            width="25%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.source}
                            text={toTitleCase(crime.source)}
                          />
                          <DataItem
                            width="25%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.offenseDay}
                            text={crime.offense_date}
                          />
                          <DataItem
                            width="25%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.state}
                            text={this.getState(crime)}
                          />
                          <DataItem
                            width="25%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.disposition}
                            text={crime.disposition}
                          />
                          <DataItem
                            width="25%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.disposition_date}
                            text={crime.disposition_date}
                          />
                          <DataItem
                            width="50%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.charges_filed_date}
                            text={crime.charges_filed_date}
                          />
                          <DataItem
                            width="50%"
                            widthMD="50%"
                            widthSM={isPDFReport ? "25%" : "100%"}
                            label={messages.sentence}
                            text={crime.sentence}
                          />
                        </Row>
                        <Divider checked={`Checked on ${this.reportDate}`} />
                      </>
                    ))}
                </div>
              </Row>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CriminalRecord;
