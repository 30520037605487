import styled from "styled-components";
import { white } from "../../styles/constants";

const Notice = styled.h1`
  position: absolute;
  width: 100%;
  top: 50%;
  margin: 0;
  text-align: center;
  transform: translateY(-50%);
  color: ${white};
`;

export default Notice;
