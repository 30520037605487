import styled from "styled-components";
import { white, fadedBlue } from "../../../styles/constants";
import { BREAKPOINT_SM } from "../../../constants";

export const Container = styled.div`
  background: ${white};
  position: relative;
  box-shadow: 0px 0px 20px 0px ${fadedBlue};
  border-radius: 20px;
  width: 100%;
  margin: 2em auto;
  padding: 0em 1em;
  text-align: ${({ align }) => align || ""};
  width: 600px;

  @media (max-width: ${BREAKPOINT_SM}px) {
    width: 400px;
  }
`;
