import React from "react";
import { observer } from "mobx-react";
import _ from "lodash";

import { CriminalRecordsComponent } from ".";
import { CanadaCriminalRecordItem } from "./CanadaCriminalRecordItem";
import { HIT, REVIEW, PROCESSING } from "../../../constants";
import withContext from "../../../contextAPI/context-HOC";
import { green, periwinkle, red } from "../../../styles/constants";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner
} from "@fortawesome/pro-solid-svg-icons";
export class CanadaCriminalRecordsComponent extends CriminalRecordsComponent {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      width: 0,
      items: _.get(props.worker, "canadianReport.report_data.criminal_check")
    };
  }

  get color() {
    if (this.props.status === PROCESSING) return periwinkle;
    if (this.props.status === HIT || this.props.status === REVIEW) return red;
    return green;
  }

  get icon() {
    if (this.props.status === PROCESSING) return faSpinner;
    if (this.props.status === HIT || this.props.status === REVIEW)
      return faExclamationTriangle;
    return faCheckCircle;
  }

  renderListItem(item, index, expand) {
    if (item.parent_category == "CRIMINAL") {
      return (
        <CanadaCriminalRecordItem
          expanded={expand}
          title={item.category}
          date={item.date}
          description={item.description}
          stage={item.stage}
          type={item.type}
        />
      );
    }
  }

  isCollapsible() {
    return this.props.status === HIT || this.props.status === REVIEW
      ? true
      : false;
  }

  get shouldRender() {
    return true;
  }

  render() {
    return super.render();
  }
}

export const CanadaCriminalRecords = observer(
  withContext(CanadaCriminalRecordsComponent)
);
