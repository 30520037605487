import React, { useMemo, useContext, useState } from "react";
import _ from "lodash";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

import { CandidateReportStyles as reportStyles } from "../../../styles";
import PanelCollapsible from "../../../components/PanelCollapsible";
import { green } from "../../../styles/constants";
import {
  AutoTable,
  AutoTableCell
} from "../../../components/AutoTable/AutoTable";
import { CopyUrlButton } from "../../../components/CopyUrlButton";
import PropTypes from "prop-types";
import { AppContext } from "../../../contextAPI/context-provider";

import messages from "../messages";

import { SiteIcon } from "./SiteIcon";

const CanadaUrlCell = ({ item, field, children, ...props }) => {
  if (field === "icon") {
    return (
      <AutoTableCell {...props}>
        <SiteIcon site={item.site_name} />
      </AutoTableCell>
    );
  } else if (field === "site_url") {
    return (
      <AutoTableCell {...props}>
        <CopyUrlButton url={item.site_url} />
      </AutoTableCell>
    );
  } else {
    return <AutoTableCell {...props}>{children}</AutoTableCell>;
  }
};

export const CanadaUrls = ({ worker }) => {
  const context = useContext(AppContext);
  const { checks = {} } = context;
  const [isProcessing] = useState(worker.status === "processing");
  const urls = useMemo(() => {
    const _urls = _.get(worker, "canadianReport.information_search.urls");
    return _.map(_urls, item => ({
      ...item,
      icon: <SiteIcon site={item.site_name} />,
      site_category: _.startCase(item.site_category)
    }));
  }, [worker]);

  if (_.isEmpty(urls)) {
    return null;
  }

  return (
    <PanelCollapsible
      title={messages.urlsTitle}
      titleStyle={reportStyles.title}
      iconStyle={reportStyles.checkedIcon}
      color={green}
      icon={faCheckCircle}
      isCollapsbile
      collapsible={!isProcessing}
      expandAllChecks={!isProcessing && checks.expandAllChecks}
    >
      <AutoTable
        data={urls}
        headerRowComponent={() => <></>}
        cellComponent={CanadaUrlCell}
        headers={{
          icon: "Icon",
          site_name: "Site",
          site_category: "Category",
          site_url: "URL"
        }}
      />
    </PanelCollapsible>
  );
};

CanadaUrls.propTypes = {
  worker: PropTypes.object
};
