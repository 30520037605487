import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { ConsentModalStyles as styles } from "../../styles";
import messages from "./messages";
import Img from "../../components/Img";
import { CopyToClipboard } from "react-copy-to-clipboard";

const propTypes = {
  consent: PropTypes.bool,
  teamMember: PropTypes.object,
  worker: PropTypes.object,
  router: PropTypes.object,
  clearWorkerInConsent: PropTypes.func,
  downloadActionFromPreview: PropTypes.bool,
  viewDetailsActionFromPreview: PropTypes.bool,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

const defaultProps = {
  open: true
};

class ConsentModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { copied: false };
  }

  printPage = () => {
    this.props.router.history.push(
      `/workers/${this.props.worker.turnId}?print=true`
    );
  };

  handleClose = () => {
    this.props.clearWorkerInConsent();
    this.setState({ copied: false });
  };

  handleAgree = () => {
    if (this.props.downloadActionFromPreview) {
      this.printPage();
    } else {
      this.props.router.history.push(`/workers/${this.props.worker.id}`);
    }
  };

  handleCopyURL = () => {
    this.setState({ copied: true });
  };

  render() {
    if (!this.props.consent) return null;

    return (
      <Dialog
        fullWidth
        open={this.props.open}
        classes={{
          paper: "consent-modal-paper"
        }}
        onRequestClose={this.props.handleClose}
      >
        <DialogContent id="consentModal" style={styles.dialogContent}>
          <div style={styles.imgContainer}>
            <div style={styles.imgDimensionsContainer}>
              <Img type="ticketCreated" style={styles.headerImage} />
            </div>
          </div>
          <div style={styles.messages}>
            <div id="consentModalTitle" style={styles.gralMessageContainer}>
              <FormattedMessage {...messages.gralMessage} />
            </div>
            <div style={styles.copyConsentURLContainer}>
              {this.state.copied ? (
                <Button
                  id="copiedButton"
                  disabled
                  style={styles.copiedCandidateConsentUrlButton}
                >
                  <FormattedMessage {...messages.copied} />{" "}
                  <i className="fas fa-check" style={{ marginLeft: "7px" }} />
                </Button>
              ) : (
                <CopyToClipboard
                  text={this.props.worker.candidateAssentURL}
                  onCopy={this.handleCopyURL}
                >
                  <Button
                    id="copyCandidateConsentUrlButton"
                    style={styles.copyCandidateConsentUrlButton}
                  >
                    <i className="fas fa-copy" style={{ marginRight: "7px" }} />{" "}
                    <FormattedMessage {...messages.copyCandidateConsentUrl} />
                  </Button>
                </CopyToClipboard>
              )}
            </div>
            <div style={styles.specificMessageContainer}>
              <FormattedMessage {...messages.consentModalBody} />
            </div>
          </div>

          <div style={styles.buttonsContainerFlexEnd}>
            <Button
              onClick={this.handleClose}
              id="cancelButton"
              key="1"
              style={styles.closeWindowButton}
            >
              <div style={{ marginRight: "8px" }}>
                <i className="fas fa-times-circle" />
              </div>
              <FormattedMessage {...messages.buttonCancel} />
            </Button>
            <Button
              onClick={this.handleAgree}
              id="agreeButton"
              key="2"
              style={styles.agreeButton}
            >
              <FormattedMessage {...messages.buttonAgree} />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

ConsentModal.propTypes = propTypes;
ConsentModal.defaultProps = defaultProps;

export default ConsentModal;
