import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.component.error.title",
    defaultMessage: "Oops! Something went wrong"
  },
  error: {
    id: "app.component.error.description",
    defaultMessage:
      "If you continue to see this message, please contact support@turn.ai"
  },
  ok: {
    id: "app.component.error.ok",
    defaultMessage: "OK"
  }
});
