import styled from "styled-components";
import { green } from "../../../styles/constants";
import { orange } from "../../../styles/constants";

export const OnDemandCrimWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-items: center;
  align-items: center;
  width: 100%;
`;

export const OnDemandCrimStatus = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${props =>
    props.status === "CLEAR"
      ? green
      : props.status === "CONSIDER"
      ? orange
      : "gray"};
`;
