import {
  white,
  noticeWarning,
  aliceBlue,
  noticeError,
  primary,
  secondary,
  smokeWhite
} from "../constants";
import opacityColor from "../../utils/opacity-color";

const checkNumber = {
  display: "inline-block",
  verticalAlign: "middle",
  marginRight: 20,
  color: noticeWarning,
  fontWeight: "bolder",
  fontSize: 18
};

const styles = {
  checkNumber,
  getCheckNumber: color => {
    if (!color) return checkNumber;
    return Object.assign({}, checkNumber, { color });
  },
  paddedButton: {
    margin: "5px 0",
    color: white,
    borderRadius: 5
  },
  buttonContent: {
    fontWeight: "bold",
    color: "white"
  },
  hiddenDetails: {
    display: "block",
    width: "100%",
    padding: "10px",
    textDecoration: "none"
  },
  progress: {
    zIndex: "10",
    opacity: "1",
    position: "absolute",
    width: "100%",
    left: "0",
    top: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#f7f9fc87"
  },
  confirmApproval: {
    paddingTop: 24
  },
  eye: {
    marginRight: 10,
    verticalAlign: "middle"
  },
  inlineContainer: {
    overflow: "hidden"
  },
  inlineTitle: {
    float: "left"
  },
  switchInline: {
    float: "right",
    paddingTop: 30,
    width: 115
  },
  switchLabel: {
    fontSize: "12px",

    color: secondary,
    fontWeight: "bold"
  },
  listItem: {
    padding: "0 5%",
    fontWeight: "300"
  },
  subtitle: {
    fontSize: 17
  },
  mapContainer: {
    width: "100%",
    height: 300
  },
  map: {
    height: "100%"
  },
  toggleMap: {
    float: "right",
    marginTop: -7
  },
  address: {
    paddingRight: 7
  },
  ssn: {
    issued: { fontSize: "1.2rem" },
    drawer: {
      padding: "18px 0%"
    },
    row: {
      icon: {
        marginRight: "25px"
      },
      title: {
        paddingLeft: "3px",
        fontWeight: "bold",
        display: "inline-block",
        fontSize: "14px"
      },
      legend: {
        container: {
          padding: "0 15px"
        },
        text: {
          color: noticeError,
          fontSize: "12px",
          fontWeight: "normal",

          display: "inline-block"
        },
        icon: {
          height: "14px",
          width: "14px",
          paddingRight: "3px"
        }
      }
    },
    footer: {
      fontSize: "14px",
      fontWeight: 300,
      color: aliceBlue
    },
    header: {
      display: "inline-block",
      marginRight: "20px"
    },
    detail: {
      content: {
        padding: "20px 0"
      },
      container: {
        display: "inline-block",
        width: "50%",
        padding: 0
      },
      description: {
        fontSize: "14px",
        margin: "5px 0px 0px 0px"
      }
    }
  },
  checkbox: {
    fill: primary
  },
  floatingAlert: {
    position: "absolute",
    top: "-80px",
    left: 0,
    width: "100%",
    padding: "10px",
    background: noticeWarning,
    color: white,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "capitalize"
  },
  checkout: {
    marginTop: "20px"
  },
  checkoutTitle: {
    display: "block",
    fontWeight: "bold",
    marginBottom: "1em"
  },
  content: {
    padding: "30px 64px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  contentContainer: {
    padding: "0 0 20px",
    display: "flex",
    justifyContent: "space-between",
    columnGap: "15px",
    flexWrap: "wrap"
  },
  detailLabel: {
    fontSize: "14px",
    width: "70%"
  },
  detailDescription: {
    fontSize: "14px",
    margin: "0px"
  },
  detailContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "10px 0"
  },
  divider: {
    borderBottom: `1px solid ${aliceBlue}`
  },
  detailHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerContainer: {
    padding: "24px 0 21px"
  },
  checks: {
    resumeContainer: {
      marginBottom: "25px"
    },
    loader: {
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
      backgroundColor: opacityColor(smokeWhite, 0.5)
    },
    rechecksTabs: {
      container: {
        display: "flex",
        borderBottom: "1px solid #E2E2EA",
        overflowX: "clip"
      },
      tab: {
        badge: {
          display: "block",
          width: "100%"
        },
        container: {
          minWidth: 120,
          width: 120,
          padding: "6px 0",
          backgroundColor: "#E9E8E8",
          borderRadius: "6px 6px 0 0",
          border: "1px solid #E2E2EA",
          marginRight: "5px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          filter:
            "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
        },
        selected: {
          container: {
            minWidth: 120,
            width: 120,
            padding: "6px 0",
            borderRadius: "6px 6px 0 0",
            border: "1px solid #E2E2EA",
            marginRight: "5px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: white,
            filter:
              "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
          }
        }
      }
    }
  }
};

export default styles;
export { checkNumber };
