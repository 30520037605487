import {
  smokeWhite,
  white,
  aliceBlue,
  primary,
  cadetBlue
} from "../../styles/constants";

import {
  TABLE_DATA_STATUS,
  TABLE_DATA_NAME,
  TABLE_DATA_CITY,
  TABLE_DATA_INVITE,
  TABLE_DATA_PACKAGE,
  TABLE_DATA_ACTION,
  TABLE_WIDTH,
  TABLE_MAX_WIDTH,
  TABLE_MIN_WIDTH
} from "../../constants";

const styles = {
  responsive: {
    // maxWidth: 863,
    display: "flex",
    // width: "75%",
    width: TABLE_WIDTH
  },
  head: {
    borderBottom: "none"
  },
  headerColumn: {
    cursor: "pointer"
  },
  sortableTableHeader: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#5f7d95"
  },
  progressContainer: {
    margin: "200px auto",
    width: "80px"
  },
  tableBody: {},
  headerRow: {
    borderBottom: "none"
  },
  headerRowContainer: {
    // cursor: "pointer",
    maxHeight: "60px"
    // backgroundColor: white,
    // borderRadius: "10px",
    // boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.12)",
    // marginBottom: "12px"
  },
  row: {
    cursor: "pointer",
    minHeight: "60px",
    borderRadius: "10px",
    marginBottom: "12px",
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  divider: {
    width: "100%",
    position: "absolute",
    bottom: 0
  },
  col: {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  tableContainer: {
    marginTop: 50
  },
  footer: {
    backgroundColor: smokeWhite
  },
  footerColumn: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  footerRow: {
    border: "none"
  },
  footerPageButton: {
    marginLeft: 10
  },
  footerPageButton2: {
    marginLeft: 10,
    width: "20px"
  },
  footerInput: {
    width: 36,
    height: 36,
    fontSize: "14px",
    fontWeight: "bold"
  },
  toggle: {
    marginTop: 20
  },
  checkoutTitle: {
    display: "block",
    fontWeight: "normal",
    marginBottom: "1em",
    marginTop: "2em"
  },
  icon: {
    fontSize: "14px"
  },
  //new style columns
  headerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "14px"
  },
  headerContent: {
    display: "flex",
    width: "100%",
    alignItems: "center"
  },
  statusContent: {
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px"
  },
  nameContainer: {
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px"
  },
  checkStatus: {
    // Same as contentStatus
    paddingLeft: "20px"
  },
  contentSatus: {
    height: "60px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    fontSize: "14px",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    // Same as checkStatus
    paddingLeft: "20px"
  },
  candidateStatusUICol: {
    overflow: "visible",
    padding: 0,
    height: undefined,
    fontSize: "16px",
    verticalAlign: "top",
    width: TABLE_DATA_STATUS,
    minWidth: "150px"
  },
  colName: {
    padding: 0,
    height: undefined,
    width: TABLE_DATA_NAME
  },
  colCity: {
    padding: 0,
    height: undefined,
    width: TABLE_DATA_CITY
  },
  colInvite: {
    padding: 0,
    height: undefined,
    width: TABLE_DATA_INVITE
  },
  colAction: {
    padding: 0,
    height: undefined,
    width: TABLE_DATA_ACTION
  },
  colPackage: {
    padding: 0,
    height: undefined,
    width: TABLE_DATA_PACKAGE,
    overflow: "hidden"
  },
  paginationBtnEnabled: {
    minWidth: "36px",
    height: "36px",
    borderRadius: "4px",
    backgroundColor: primary,
    boxShadow: "none",
    color: smokeWhite,
    fontSize: "14px",
    fontWeight: "bold",
    padding: 0,
    marginRight: 11
  },
  paginationBtnDisabled: {
    minWidth: "36px",
    height: "36px",
    borderRadius: "4px",
    backgroundColor: aliceBlue,
    color: primary,
    boxShadow: "none",
    fontSize: "14px",
    fontWeight: "bold",
    padding: 0,
    marginRight: 11
  },
  actionsSelect: {
    width: "216px",
    backgroundColor: cadetBlue
  },
  new: {
    container: {
      display: "flex",
      minWidth: TABLE_MIN_WIDTH,
      maxWidth: TABLE_MAX_WIDTH
    },
    smokeWhite: {
      tableBody: {
        backgroundColor: smokeWhite,
        position: "relative"
      },
      headerRow: {
        backgroundColor: smokeWhite,
        borderBottom: "0px",
        minHeight: "60px",
        display: "flex",
        alignItems: "center"
      },
      row: {
        cursor: "pointer",
        minHeight: "60px",
        borderRadius: "10px",
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
        backgroundColor: white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.12)"
      }
    }
  },
  pending: {
    row: {
      cursor: "pointer",
      minHeight: "60px",
      backgroundColor: white,
      borderRadius: "10px",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.12)",
      marginBottom: "12px",
      display: "flex"
    },
    headerContainerStyle: {
      paddingTop: "16px"
    },
    colName: {
      width: "40%",
      padding: 0
    },
    colCity: {
      width: "34%",
      padding: 0
    },
    colInvite: {
      width: "20%",
      padding: 0
    }
  },
  data: {
    container: {
      height: "60px",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      fontSize: "14px"
    },
    content: {
      display: "inline-block",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  address: {
    colAddress1: {
      width: "30%"
    },
    colCity: {
      width: "30%"
    },
    colZip: {
      width: "20%"
    },
    colState: {
      width: "20%"
    },
    headerContainerStyle: {
      display: "flex"
    }
  }
};
export default styles;
