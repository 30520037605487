import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import messages from "../../../components/BackgroundReport/messages";
import { ReactComponent as ExclamationTriangleLightIcon } from "../../../icons/exclamation-triangle-light.svg";
import "./confirm.css";

const getBtnConfirmStyle = checked => {
  return checked
    ? "btn-confirm btn-confirm-enabled"
    : "btn-confirm btn-confirm-disabled";
};

const Confirm = ({
  handleOpen,
  handleDispute,
  partnerName,
  handleCheckCertify,
  checked
}) => (
  <div className="container">
    <div />
    <div className="exclamation-triangle-icon-container">
      <div className="exclamation-circle">
        <ExclamationTriangleLightIcon className="exclamation-triangle-icon" />
      </div>
    </div>
    <div className="certify-container">
      <div className="certify-title-container">
        <div className="certify-title">
          <FormattedMessage id="certifyTitle" {...messages.certifyTitle} />
        </div>
      </div>
      <div className="certify-terms">
        <div className="certify-terms-checkbox-container">
          <div className="certify-terms-checkbox">
            <input
              type="checkbox"
              id="checkbox"
              checked={checked}
              onChange={handleCheckCertify}
            />
            <label htmlFor="checkbox" />
          </div>
        </div>
        <div className="certify-terms-descriptions-container">
          <div className="certify-terms-descriptions">
            <p className="para" onClick={handleCheckCertify}>
              <FormattedMessage
                id="certifyFirstPart"
                {...messages.certifyFirstPart}
              />
              {partnerName}.
              <FormattedMessage
                id="certifySecondPart"
                {...messages.certifySecondPart}
              />
              {partnerName}
              <FormattedMessage {...messages.certifyThirdPart} />
            </p>
            <p style={{ marginTop: 6 }}>
              <span onClick={handleCheckCertify}>
                <FormattedMessage {...messages.disputeSecondOption} />
              </span>
              <a
                href="https://turn.ai/dispute_process/"
                onClick={handleDispute}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "inline-block", color: "white" }}
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="certify-actions">
      <div className="btn-confirm-container">
        <button
          id="confirmButton"
          onClick={handleOpen}
          className={getBtnConfirmStyle(checked)}
          disabled={!checked}
        >
          Confirm
        </button>
      </div>
      <div className="dispute-link-container">
        <a
          id="disputeHereLink"
          href="https://turn.ai/dispute_process/"
          onClick={handleDispute}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "inline-block" }}
        >
          or Dispute here
        </a>
      </div>
    </div>
    <div />
  </div>
);

Confirm.propTypes = {
  handleOpen: PropTypes.func,
  handleDispute: PropTypes.func,
  handleCheckCertify: PropTypes.func,
  partnerName: PropTypes.string,
  checked: PropTypes.bool
};

export default Confirm;
