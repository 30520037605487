import { white, primary } from "../../styles/constants";

export const styles = {
  container: {
    position: "relative",
    cursor: "pointer"
  },
  modals: {
    display: "none"
  },
  icon: {
    position: "absolute",
    right: "16px",
    alignSelf: "center",
    color: white
  },
  node: {
    position: "absolute",
    bottom: "-30px"
  },
  menuItem: isDisabled => ({
    flexWrap: "wrap",
    padding: "12px 10px",
    overflow: "visible",
    cursor: isDisabled && "default",
    position: "relative"
  }),
  item: {
    fontSize: "14px",
    fontWeight: "bold",
    color: primary,
    paddingLeft: "10px"
  },
  itemIcon: {
    fontSize: "20px",
    width: "40px"
  },
  divider: {
    width: "100%",
    height: "1px",
    margin: "10px auto",
    backgroundColor: "rgb(0,0,0,0.1)"
  },
  selectTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // To make up for icon > right
    paddingRight: "16px",
    fontSize: "14px",
    fontWeight: "bold"
  },
  button: {
    display: "inline-flex",
    maxWidth: "216px",
    fontSize: "16px",
    height: "48px"
  },
  buttonTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 24px"
  }
};
