import React from "react";
import env from "@mars/heroku-js-runtime-env";
import PropTypes from "prop-types";
import { envVariables } from "../constants";

export const AppContext = React.createContext();

class ContextProvider extends React.Component {
  state = {
    openSettings: false,
    expandAllChecks: false,
    sidebarCollapsed: false,
    mdBreak: false,
    smBreak: false
  };
  setOpenSettings = openSettings => {
    if (typeof openSettings !== "boolean") return;
    this.setState({ openSettings });
  };
  setExpandAllChecks = expandAllChecks => {
    if (typeof expandAllChecks !== "boolean") return;
    this.setState({ expandAllChecks });
  };
  setSidebarCollapsed = sidebarCollapsed => {
    if (typeof sidebarCollapsed !== "boolean") return;
    this.setState({ sidebarCollapsed });
  };
  setMdBreak = mdBreak => {
    if (typeof mdBreak !== "boolean") return;
    this.setState({ mdBreak });
  };
  setSmBreak = smBreak => {
    if (typeof smBreak !== "boolean") return;
    this.setState({ smBreak });
  };
  getFeatureFlags = envVars => {
    let features = {};
    for (let envVar of envVars) {
      const value = env()[envVar];
      const status = value === "true";
      features[envVar] = status;
    }
    return features;
  };

  get settings() {
    return {
      openSettings: this.state.openSettings,
      setOpenSettings: this.setOpenSettings
    };
  }

  get checks() {
    const { expandAllChecks } = this.state;
    const { setExpandAllChecks } = this;
    return {
      expandAllChecks,
      setExpandAllChecks
    };
  }

  get general() {
    const {
      state: { sidebarCollapsed, mdBreak, smBreak },
      setSidebarCollapsed,
      setMdBreak,
      setSmBreak
    } = this;
    return {
      sidebarCollapsed,
      setSidebarCollapsed,
      mdBreak,
      setMdBreak,
      smBreak,
      setSmBreak
    };
  }

  render() {
    const features = this.getFeatureFlags(envVariables);

    return (
      <AppContext.Provider
        value={{
          features,
          settings: this.settings,
          checks: this.checks,
          ...this.general
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

const propTypes = {
  children: PropTypes.node
};

ContextProvider.propTypes = propTypes;

export default ContextProvider;
