import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";

import messages from ".././messages";
import Title from "../../../components/Title";
import Panel from "../../../components/Panel";
import DotsLoader from "../../../components/DotsLoader";
import { ResumeChecksStyles as styles } from "./styles";
import ResumeModal from "../resume-worker";
import { slateGray, smokeWhite } from "../../../styles/constants";
import { Button } from "../../../components/Button";
import teamMember from "../../../models/team-member";

export const Loader = ({ show, style = {} }) =>
  show ? (
    <div
      style={
        _.isObject(style) && !Array.isArray(style)
          ? { ...styles.modal, ...style }
          : styles.modal
      }
    >
      <DotsLoader />
    </div>
  ) : null;
Loader.propTypes = {
  show: PropTypes.bool,
  style: PropTypes.object
};

const ResumeChecks = observer(
  class ResumeChecks extends Component {
    static propTypes = {
      worker: PropTypes.object,
      openErrorDialog: PropTypes.func
    };

    static defaultProps = {
      worker: {}
    };

    constructor(props) {
      super(props);
      this.state = {
        dialogOpen: false
      };
    }
    handleOpenDialog = () => {
      this.setState({ dialogOpen: true });
    };
    handleCloseDialog = () => {
      this.setState({ dialogOpen: false });
    };
    render() {
      const { worker } = this.props;
      const { dialogOpen } = this.state;
      if (worker.isProcessingMVR) return null;
      if (
        !worker.isPendingReview &&
        !worker.isPendingMVRReview &&
        !worker.isPendingAgeReview
      )
        return null;
      if (worker.isPendingReview) {
        return (
          <>
            <ResumeModal
              worker={worker}
              open={dialogOpen}
              handleClose={this.handleCloseDialog}
            />
            <Panel
              padding="20px 5%"
              background={slateGray}
              style={styles.container}
            >
              <div style={styles.textContainer}>
                <FontAwesomeIcon icon={faInfoCircle} style={styles.icon} />
                <div style={styles.textContent}>
                  <Title
                    align="left"
                    margin="0px 0px 10px"
                    size="14px"
                    color={smokeWhite}
                  >
                    Information provided by the candidate did not match public
                    records
                  </Title>
                  <span style={styles.message}>
                    <FormattedMessage {...messages.resumeChecks} />
                  </span>
                </div>
              </div>
              <div style={styles.buttonContainer}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleOpenDialog}
                  disabled={
                    worker.readOnly || !teamMember.canTriggerResumeChecks
                  }
                  style={styles.button}
                >
                  Resume Checks
                </Button>
              </div>
            </Panel>
          </>
        );
      }
      if (worker.isPendingAgeReview) {
        return (
          <>
            <ResumeModal
              worker={worker}
              open={dialogOpen}
              handleClose={this.handleCloseDialog}
            />
            <Panel
              padding="20px 5%"
              background={slateGray}
              style={styles.container}
            >
              <div style={styles.textContainer}>
                <FontAwesomeIcon icon={faInfoCircle} style={styles.icon} />
                <div style={styles.textContent}>
                  <Title
                    align="left"
                    margin="0px 0px 10px"
                    size="14px"
                    color={smokeWhite}
                  >
                    The information provided by the applicant does not meet the
                    age requirement.
                  </Title>
                  <span style={styles.message}>
                    <FormattedMessage {...messages.resumeAgeChecks} />
                  </span>
                </div>
              </div>
              <div style={styles.buttonContainer}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleOpenDialog}
                  disabled={
                    worker.readOnly || !teamMember.canTriggerResumeChecks
                  }
                  style={styles.button}
                >
                  Resume Checks
                </Button>
              </div>
            </Panel>
          </>
        );
      }
      return (
        <>
          <ResumeModal
            worker={worker}
            open={dialogOpen}
            handleClose={this.handleCloseDialog}
          />
          <Panel
            padding="20px 5%"
            background={slateGray}
            style={styles.container}
          >
            <div style={styles.textContainer}>
              <FontAwesomeIcon icon={faInfoCircle} style={styles.icon} />
              <div style={styles.textContent}>
                <Title
                  align="left"
                  margin="0px 0px 10px"
                  size="14px"
                  color={smokeWhite}
                >
                  Motor Vehicle Record Contains Potentially Adverse Information
                </Title>
                <span style={styles.message}>
                  <FormattedMessage {...messages.resumeMVRChecks} />
                </span>
              </div>
            </div>
            <div style={styles.buttonContainer}>
              <Button
                color="secondary"
                variant="contained"
                onClick={this.handleOpenDialog}
                disabled={worker.readOnly || !teamMember.canTriggerResumeChecks}
                style={styles.button}
              >
                Resume Checks
              </Button>
            </div>
          </Panel>
        </>
      );
    }
  }
);

export default ResumeChecks;
