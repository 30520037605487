import React from "react";
import PropTypes from "prop-types";
import { ListItemConfirmStyles as styles } from "../../styles";

import messages from "./messages";

const propTypes = {
  model: PropTypes.object
};

const Confirm = ({ model }) =>
  model ? (
    <div id="confirmInfomationModal">
      <div style={styles.listItemContainer}>
        <div style={styles.label}>{messages.confirmName.defaultMessage}</div>
        <div id="confirmInformationFirstName" style={styles.value}>
          {model.firstName}
        </div>
      </div>

      <div style={styles.listItemContainer}>
        <div style={styles.label}>
          {messages.confirmLastName.defaultMessage}
        </div>
        <div id="confirmInformationLastName" style={styles.value}>
          {model.lastName}
        </div>
      </div>

      <div style={styles.listItemContainer}>
        <div style={styles.label}>{messages.confirmEmail.defaultMessage}</div>
        <div id="confirmInformationEmail" style={styles.value}>
          {model.email}
        </div>
      </div>

      <div style={styles.listItemContainer}>
        <div style={styles.label}>{messages.confirmPhone.defaultMessage}</div>
        <div id="confirmInformationPhone" style={styles.value}>
          {model.phoneNumber}
        </div>
      </div>

      <div style={styles.listItemContainer}>
        <div style={styles.label}>{messages.confirmPackage.defaultMessage}</div>
        <div id="confirmInformationPackageName" style={styles.value}>
          {model.selected_partner_package.name}
        </div>
      </div>
    </div>
  ) : null;

Confirm.propTypes = propTypes;

export default Confirm;
