import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import { turnAPI } from "../../services";
import Highlighted from "../../components/Highlighted";
import Modal from "../../components/Modal/modal";
import messages from "./messages";

class ApproveModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  approveWorker = async () => {
    this.setState({ loading: true });
    const response = await turnAPI.approveWorker(this.props.worker.id, {
      note: ""
    });
    if (!response) return;
    switch (response.status) {
      case 200:
        window.location.reload();
        break;
      case 307:
        this.props.auth.permission();
        break;
      case 401:
        this.props.auth.logout();
        break;
      default:
        break;
    }
    this.setState({ loading: false });
  };
  render() {
    if (!this.props.worker) return null;
    return (
      <Modal
        open={this.props.open}
        title={
          <span>
            <FormattedMessage {...messages.buttonApprove} />
            {` ${this.props.worker.firstName}`}
          </span>
        }
        primaryButtonProps={{
          title: "Approve",
          onClick: this.approveWorker
        }}
        secondaryButtonProps={{
          title: "Cancel",
          onClick: this.props.handleClose
        }}
      >
        <FormattedMessage {...messages.confirmApproval} />
        <Highlighted>{this.props.worker.firstName}:</Highlighted>
      </Modal>
    );
  }
}

ApproveModal.propTypes = {
  worker: PropTypes.object,
  auth: PropTypes.object,
  router: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default ApproveModal;
