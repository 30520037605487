import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider as ThemeProvider } from "@material-ui/core/styles";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { SkeletonTheme } from "react-loading-skeleton";
import history from "./history";
import { IntlProvider } from "react-intl";
import { materialThemeV0, materialThemeV1 } from "./themes/turn";
import WorkersPage from "./containers/WorkersPage";
import Login from "./containers/Login";
import Callback from "./containers/Callback";
import WorkerDetailPage from "./containers/WorkerPage";
import PartnerSpecificBGC from "./containers/PartnerSpecificBGC";
import BackgroundReportSuccess from "./containers/BackgroundReportSuccess";
import BackgroundReportSuccessMVRHits from "./containers/BackgroundReportSuccessMVRHits";
import BackgroundReportPage from "./containers/BackgroundReportPage";
import BackgroundReportPDF from "./containers/BackgroundReportPDF";
import ReportPage from "./containers/ReportPage";
import Permissions from "./containers/Permission";
import Disclosure from "./containers/Disclosure";
import { ScreeningDashboardPage } from "./containers/ScreeningDashboard";
import member from "../src/models/team-member";
import auth from "./authentication/service";
import ProtectedRoute from "./authentication/ProtectedRoute";
import ContextProvider from "./contextAPI/context-provider";
import "./App.css";
import { PropTypes } from "prop-types";
import partnerEvents from "./models/partnerEvents";
import OauthCallbackSettings from "./containers/OauthCallbackSettings";
import MigratedRoute from "./containers/MigratedRoute";
import { rdLocalstorageItemName } from "./constants";
import { ga } from "./services";

import { ToastContainer } from "react-toastify";

import SettingsPage from "./containers/SettingsPage";

import "react-loading-skeleton/dist/skeleton.css";
import ErrorPage from "./containers/ErrorPage";

class App extends Component {
  static contextTypes = {
    flags: PropTypes.object
  };

  state = {
    partnerName: ""
  };
  viewPage = (props, Child) => {
    return (
      <Child
        auth={auth}
        params={props.match.params}
        router={props.history}
        location={props.location}
      />
    );
  };

  constructor(props) {
    super(props);
    ga.run();
  }

  async componentDidMount() {
    localStorage.removeItem(rdLocalstorageItemName);
    await auth.init();
    partnerEvents.init();
    this.setState({ partnerName: member.partner.name });
  }

  render() {
    return (
      <IntlProvider locale="en">
        <ThemeProvider theme={materialThemeV1}>
          <MuiThemeProvider muiTheme={materialThemeV0}>
            <SkeletonTheme
              baseColor={"#f2f5f7"}
              highlightColor={"#f9fbfb"}
              height={16}
            >
              <ContextProvider>
                <Router history={history}>
                  <div className="router-frame">
                    <Switch>
                      <ProtectedRoute
                        history={history}
                        path="/"
                        exact
                        component={ScreeningDashboardPage}
                      />
                      <ProtectedRoute
                        history={history}
                        path="/workers"
                        exact
                        component={WorkersPage}
                      />
                      <ProtectedRoute
                        history={history}
                        path="/workers/:id"
                        exact
                        component={WorkerDetailPage}
                      />
                      <ProtectedRoute
                        history={history}
                        path="/report"
                        exact
                        component={ReportPage}
                      />
                      <Route
                        path="/apply/:partner_name/:partner_id"
                        render={props =>
                          this.viewPage(props, PartnerSpecificBGC)
                        }
                      />
                      <Route
                        path="/canada/apply/:partner_name/:package_id"
                        render={props =>
                          this.viewPage(props, PartnerSpecificBGC)
                        }
                      />
                      <Route
                        path="/candidate/success"
                        exact
                        render={props =>
                          this.viewPage(props, BackgroundReportSuccess)
                        }
                      />
                      <Route
                        path="/candidate/successMVR"
                        exact
                        render={props =>
                          this.viewPage(props, BackgroundReportSuccessMVRHits)
                        }
                      />
                      <Route
                        path="/consent/:email_id"
                        exact
                        render={props =>
                          this.viewPage(props, PartnerSpecificBGC)
                        }
                      />
                      <Route
                        path="/consent/:email_id/report"
                        exact
                        render={props =>
                          this.viewPage(props, BackgroundReportPage)
                        }
                      />
                      <Route
                        path="/consent/:email_id/report/pdf"
                        exact
                        render={props =>
                          this.viewPage(props, BackgroundReportPDF)
                        }
                      />
                      <Route
                        path="/login"
                        render={() => <Login auth={auth} />}
                      />
                      <Route path="/callback" render={() => <Callback />} />
                      <ProtectedRoute
                        history={history}
                        path="/permission"
                        exact
                        component={Permissions}
                      />
                      <Route
                        path="/disclosure"
                        render={props => this.viewPage(props, Disclosure)}
                      />
                      <ProtectedRoute
                        history={history}
                        path="/settings"
                        exact
                        component={SettingsPage}
                      />
                      <ProtectedRoute
                        history={history}
                        path="/recruiter/settings"
                        exact
                        component={props => (
                          <MigratedRoute container={SettingsPage} {...props} />
                        )}
                      />
                      <ProtectedRoute
                        history={history}
                        path={[
                          "/settings/oauth/:service",
                          "/v1/ats/:service/oauth" // Dave: This is a mistake by me. Update with Lever so we can delete this
                        ]}
                        exact
                        component={OauthCallbackSettings}
                      />
                      <Route
                        path="*"
                        render={() => (
                          <ErrorPage message="404: This page could not be found." />
                        )}
                      />
                    </Switch>
                  </div>
                </Router>
                <ToastContainer />
              </ContextProvider>
            </SkeletonTheme>
          </MuiThemeProvider>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default App;
