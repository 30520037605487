import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import _ from "lodash";
import Watchlists from "../watchlists";
import WorkerSSNTrace from "../ssn";
import WorkerAddresses, { CanadaWorkerAddresses } from "../address";
import CriminalRecords from "../criminalRecords";
import MVR from "../mvr";
import ChecksList from "../checksList";
import OneOffChecks from "../oneOffChecks";
import FederalCheck from "../federalCheck";
import DrugTestingResults from "../DrugTestingResults";
import Properties from "../properties";
import WorkerVehicles from "../vehicles";
import ResumeChecks from "../ResumeChecks";
import RechecksTabs from "../rechecksTabs";
import { WorkerStyles as styles } from "../../../styles";

import {
  PROCESSING,
  HIT,
  CLEAR,
  REVIEW,
  NATIONAL_WATCHLIST,
  ONE_OFF_CHECKS,
  WITHDRAWN,
  PENDING
} from "../../../constants";
import CheckStatus from "../../../models/checks-status";

import { CanadaReportTitle } from "../CanadianReport/components/CanadaReportTitle";
import { CanadaEmployers } from "../employers";
import { CanadaUrls } from "../urls/CanadaUrls";
import { CanadaEducation } from "../education/CanadaEducation";
import { CanadaEnhancedIdentity } from "../enhancedIdentity/CanadaEnhancedIdentity";
import { CanadaCriminalRecords } from "../criminalRecords/CanadaCriminalRecords";
import HidePrint from "../../../components/Responsive/hide-print";
import { CanadianCheckList } from "../checksList/CanadianCheckList";
import { COMPLETED_ON_DEMAND_CRIM_RESOLUTION } from "../../../models/constants";

class WorkerContainerChecks extends Component {
  static propTypes = {
    worker: PropTypes.object
  };

  static defaultProps = {
    worker: {}
  };

  constructor(props) {
    super(props);

    this.model = new CheckStatus();
    this.model.setWorker(props.worker);
    this.checks = [
      {
        id: "watchlist",
        component: this.renderWatchlist,
        clear: this.watchlistStatus === CLEAR,
        review: this.watchlistStatus === REVIEW,
        hit: this.watchlistStatus === HIT
      },
      {
        id: "ssnTrace",
        component: this.renderSsn,
        clear: this.model.ssnStatus === CLEAR,
        hit: this.model.ssnStatus === HIT,
        review: false
      },
      {
        id: "enhancedIdentity",
        component: this.renderEnhancedIdentity,
        clear: true,
        review: false
      },
      {
        id: "employers",
        component: this.renderEmployers,
        clear: true,
        review: false
      },
      {
        id: "education",
        component: this.renderEducation,
        clear: true,
        review: false
      },
      {
        id: "urls",
        component: this.renderUrls,
        clear: true,
        review: false
      },
      {
        id: "addresses",
        component: this.renderAddresses,
        clear: true,
        review: false
      },
      {
        id: "checksLit",
        component: this.renderChecksList,
        clear: this.checksListStatus === CLEAR,
        review: this.checksListStatus === REVIEW,
        hit: this.checksListStatus === HIT
      },
      {
        id: "criminal",
        component: this.renderCriminal,
        clear: this.criminalStatus === CLEAR,
        review: this.criminalStatus === REVIEW,
        hit: this.criminalStatus === HIT || this.criminalStatus === PROCESSING
      },
      {
        id: "mvr",
        component: this.renderMVR,
        clear: this.mvrStatus === CLEAR,
        review: this.mvrStatus === REVIEW,
        hit: this.mvrStatus === HIT
      },
      {
        id: "oneOffChecks",
        component: this.renderOneOffChecks,
        clear: this.oneOffChecksStatus === CLEAR,
        review: this.oneOffChecksStatus === REVIEW,
        hit: this.oneOffChecksStatus === HIT
      },
      {
        id: "federalCheck",
        component: this.renderFederalCheck,
        clear: this.federalStatus === CLEAR,
        review: this.federalStatus === REVIEW,
        hit: this.federalStatus === HIT || this.federalStatus === PROCESSING
      },
      {
        id: "drugTestingResults",
        component: this.renderDrugTestings,
        clear: this.drugTestingStatus === CLEAR,
        review: this.drugTestingStatus === PENDING,
        hit:
          this.drugTestingStatus === WITHDRAWN ||
          this.drugTestingStatus === PROCESSING
      }
    ];
  }
  componentDidUpdate() {
    this.checks = [
      {
        id: "watchlist",
        component: this.renderWatchlist,
        clear: this.watchlistStatus === CLEAR,
        review: this.watchlistStatus === REVIEW,
        hit: this.watchlistStatus === HIT
      },
      {
        id: "ssnTrace",
        component: this.renderSsn,
        clear: this.model.ssnStatus === CLEAR,
        hit: this.model.ssnStatus === HIT,
        review: false
      },
      {
        id: "enhancedIdentity",
        component: this.renderEnhancedIdentity,
        clear: true,
        review: false
      },
      {
        id: "employers",
        component: this.renderEmployers,
        clear: true,
        review: false
      },
      {
        id: "education",
        component: this.renderEducation,
        clear: true,
        review: false
      },
      {
        id: "urls",
        component: this.renderUrls,
        clear: true,
        review: false
      },
      {
        id: "addresses",
        component: this.renderAddresses,
        clear: true,
        review: false
      },
      {
        id: "checksLit",
        component: this.renderChecksList,
        clear: this.checksListStatus === CLEAR,
        review: this.checksListStatus === REVIEW,
        hit: this.checksListStatus === HIT
      },
      {
        id: "criminal",
        component: this.renderCriminal,
        clear: this.criminalStatus === CLEAR,
        review: this.criminalStatus === REVIEW,
        hit: this.criminalStatus === HIT || this.criminalStatus === PROCESSING
      },
      {
        id: "mvr",
        component: this.renderMVR,
        clear: this.mvrStatus === CLEAR,
        review: this.mvrStatus === REVIEW,
        hit: this.mvrStatus === HIT
      },
      {
        id: "oneOffChecks",
        component: this.renderOneOffChecks,
        clear: this.oneOffChecksStatus === CLEAR,
        review: this.oneOffChecksStatus === REVIEW,
        hit: this.oneOffChecksStatus === HIT
      },
      {
        id: "federalCheck",
        component: this.renderFederalCheck,
        clear: this.federalStatus === CLEAR,
        review: this.federalStatus === REVIEW,
        hit: this.federalStatus === HIT || this.federalStatus === PROCESSING
      },
      {
        id: "drugTestingResults",
        component: this.renderDrugTestings,
        clear: this.drugTestingStatus === CLEAR,
        review: this.drugTestingStatus === PENDING,
        hit:
          this.drugTestingStatus === WITHDRAWN ||
          this.drugTestingStatus === PROCESSING ||
          this.drugTestingStatus === PENDING
      }
    ];
  }

  renderCanadianPackageTitle = () => {
    if (this.props.worker.canadianReport) {
      return (
        <CanadaReportTitle>{this.props.worker.packageName}</CanadaReportTitle>
      );
    }
  };

  renderWatchlist = (key, props = {}) => {
    if (!this.props.worker) return null;
    if (!Array.isArray(this.props.worker.watchlistData)) return null;
    if (
      this.props.worker.isPendingReview &&
      !this.props.worker.shouldShowCheck(NATIONAL_WATCHLIST)
    )
      return null;
    if (
      this.props.worker.isPendingMVRReview &&
      !this.props.worker.shouldShowCheck(NATIONAL_WATCHLIST)
    )
      return null;
    if (
      this.props.worker.isProcessingMVR &&
      !this.props.worker.shouldShowCheck(NATIONAL_WATCHLIST)
    )
      return null;
    return (
      <Watchlists
        key={key}
        worker={this.props.worker}
        status={this.watchlistStatus}
        {...props}
      />
    );
  };

  renderSsn = (key, props = {}) => (
    <WorkerSSNTrace
      key={key}
      worker={this.props.worker}
      color={this.model.ssnColor}
      icon={this.model.ssnIcon}
      {...props}
    />
  );

  renderChecksList = (key, props = {}) =>
    this.props.worker.canadianReport ? (
      <CanadianCheckList
        worker={this.props.worker}
        status={this.checksListStatus}
        key={key}
        {...props}
      />
    ) : (
      <ChecksList
        worker={this.props.worker}
        status={this.checksListStatus}
        key={key}
        {...props}
      />
    );

  renderEnhancedIdentity = (key, props = {}) =>
    this.props.worker.canadianReport && (
      <CanadaEnhancedIdentity key={key} worker={this.props.worker} {...props} />
    );

  renderAddresses = (key, props = {}) =>
    this.props.worker.canadianReport ? (
      <CanadaWorkerAddresses key={key} worker={this.props.worker} {...props} />
    ) : (
      <WorkerAddresses
        {...this.props.worker}
        key={key}
        setShowAllAddresses={this.props.worker.setShowAllAddresses}
        {...props}
      />
    );

  renderEmployers = (key, props = {}) =>
    this.props.worker.canadianReport && (
      <CanadaEmployers key={key} worker={this.props.worker} {...props} />
    );

  renderEducation = (key, props = {}) =>
    this.props.worker.canadianReport && (
      <CanadaEducation key={key} worker={this.props.worker} {...props} />
    );

  renderUrls = (key, props = {}) =>
    this.props.worker.canadianReport && (
      <CanadaUrls key={key} worker={this.props.worker} {...props} />
    );

  renderCriminal = (key, props = {}) =>
    this.props.worker.canadianReport ? (
      <CanadaCriminalRecords
        worker={this.props.worker}
        key={key}
        expandAll={
          _.get(this.props.worker, "canadianReport.report_data.criminal_check")
            .length > 0 || this.criminalStatus == REVIEW
        }
        status={this.criminalStatus}
        {...props}
      />
    ) : (
      <CriminalRecords
        worker={this.props.worker}
        key={key}
        status={this.criminalStatus}
        expandAll={this.model.criminalHasHits}
        {...props}
      />
    );

  renderMVR = (key, props = {}) => (
    <MVR
      data={this.props.worker.mvr}
      worker={this.props.worker}
      key={key}
      status={this.mvrStatus}
      {...props}
    />
  );

  renderOneOffChecks = (key, props = {}) => (
    <OneOffChecks
      items={this.props.worker.oneOffChecks}
      worker={this.props.worker}
      status={this.oneOffChecksStatus}
      key={key}
      {...props}
    />
  );

  renderFederalCheck = (key, props = {}) => (
    <FederalCheck
      worker={this.props.worker}
      key={key}
      status={this.federalStatus}
      expandAll={this.model.federalHasHits}
      {...props}
    />
  );

  renderDrugTestings = (key, props = {}) =>
    this.props.worker.doDrugTesting && (
      <DrugTestingResults
        worker={this.props.worker}
        key={key}
        status={this.drugTestingStatus}
        {...props}
      />
    );

  renderCheck = ({ component, clear, hit, review }, key) => {
    return component(key, { clear, hit, review });
  };

  renderSortedChecks = () => {
    return this.sortedChecks.map((check, i) => {
      return this.renderCheck(check, i);
    });
  };

  get sortedChecks() {
    return this.checks
      .sort(({ hit }) => (hit ? 1 : -1))
      .sort(({ review }) => (review ? 1 : -1))
      .sort(({ clear }) => (clear ? 1 : -1));
  }

  // Computed status properties should replaced for ChecksStatus model
  get mvrStatus() {
    const { worker } = this.props;
    const data = worker.mvr || {};
    if (_.isString(data)) return REVIEW;
    if (!worker.isMVRClear) return HIT;
    if (worker.isProcessingMVR) return REVIEW;
    if (worker.isPendingReview) return REVIEW;
    if (worker.isPendingAgeReview) return REVIEW;

    return CLEAR;
  }

  get federalStatus() {
    const { worker } = this.props;

    // Generic statuses
    if (worker.workerIsPendingReview) return REVIEW;

    const data = worker.federalCriminalData || [];
    if (
      worker &&
      Array.isArray(data) &&
      data.some(val => val["status"] === "processing")
    )
      return PROCESSING;
    if (
      data.some(val => val["status"] === "ready") &&
      data.some(val => val["values"].length > 0)
    )
      return HIT;
    return CLEAR;
  }

  get drugTestingStatus() {
    const { worker } = this.props;
    const { drugTestingResults } = worker || {};
    const { resolution } = drugTestingResults || {};

    if (resolution === CLEAR) return CLEAR;
    if (resolution === PENDING) return PENDING;
    if (resolution === WITHDRAWN) return WITHDRAWN;
    return PROCESSING;
  }

  get criminalStatus() {
    const { worker } = this.props;

    if (worker.canadianReport) {
      // Canada Specific
      const items = _.get(worker.canadianReport, "report_data.criminal_check");
      if (!_.isEmpty(items)) {
        return HIT;
      }
      if (worker.onBoardingStatus === "processing") return PROCESSING;

      const status = _.get(
        worker.canadianReport,
        "report_metadata.report_status"
      );
      if (status == "consider") return REVIEW;
    } else {
      // Generic statuses
      if (worker.workerIsPendingReview) return REVIEW;

      // US Specific
      const items = worker.countyCriminalData || [];

      const isProcessing =
        items.some(val => val["status"] === "processing") ||
        (items.some(val => !!val["resolution"]) &&
          !items.some(val =>
            COMPLETED_ON_DEMAND_CRIM_RESOLUTION.includes(val["resolution"])
          ));

      const hasHits =
        (items.some(val => val["status"] === "ready") &&
          items.some(val => !!val["value"])) ||
        items.some(val => val["has_hits"] && val["has_hits"].length > 0);

      if (isProcessing) return PROCESSING;
      if (hasHits) return HIT;
    }

    return CLEAR;
  }

  get checksListStatus() {
    if (this.props.worker.canadianReport) {
      const reportData = _.get(
        this.props.worker.canadianReport,
        "report_data",
        {}
      );

      const reportItems = _.omit(reportData, "criminal_check");

      const items = _.values(reportItems);

      if (
        _.find(items, item => {
          if (typeof item == "string") {
            return item != "CLEARED";
          } else {
            return !_.isEmpty(item);
          }
        })
      ) {
        return HIT;
      } else {
        return CLEAR;
      }
    } else {
      const allItems = this.props.worker.checks || [];
      const items = allItems.filter(
        ({ label }) => label === "Sex Offender Check"
      );
      const item = items[0] || {};
      const value = item.value || [];
      const clear = value.length === 0;

      if (!clear) return HIT;
      return CLEAR;
    }
  }

  get watchlistStatus() {
    const { worker } = this.props;
    const data = worker.watchlistData || [];
    const clear = data.length === 0;

    if (
      worker.workerIsPendingReview &&
      worker.shouldShowCheck(NATIONAL_WATCHLIST)
    )
      return REVIEW;
    if (!clear) return HIT;
    return CLEAR;
  }

  get oneOffChecksStatus() {
    const { worker } = this.props;
    if (
      worker &&
      worker.workerIsPendingReview &&
      worker.shouldShowCheck(ONE_OFF_CHECKS)
    )
      return REVIEW;

    if (
      worker &&
      Array.isArray(worker.oneOffChecks) &&
      (worker.oneOffChecks.some(val => val["state"] === "consider") ||
        worker.oneOffChecks.some(val => val["state"] === "attention") ||
        worker.oneOffChecks.some(val => val["resolution"] === "consider") ||
        worker.oneOffChecks.some(val => val["resolution"] === "CONSIDER"))
    )
      return HIT;
    if (
      worker &&
      Array.isArray(worker.oneOffChecks) &&
      (worker.oneOffChecks.some(val => val["state"] === "processing") ||
        worker.oneOffChecks.some(val => val["state"] === "initiated"))
    )
      return PROCESSING;
    return CLEAR;
  }

  render() {
    if (this.props.worker.onBoardingStatus === "initiated") return null;
    return (
      <div>
        {this.renderCanadianPackageTitle()}
        <HidePrint>
          <RechecksTabs worker={this.props.worker} />
        </HidePrint>
        {!this.props.worker.canadianReport && (
          <div style={styles.checks.resumeContainer}>
            <ResumeChecks
              worker={this.props.worker}
              openErrorDialog={this.props.openErrorDialog}
            />
          </div>
        )}
        {this.renderSortedChecks()}
        <WorkerVehicles vehicleData={this.props.worker.vehicleData} />
        <Properties worker={this.props.worker} />
      </div>
    );
  }
}

export default observer(WorkerContainerChecks);
