import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { ProcessingModalStyles as styles } from "../../styles";
import Img from "../../components/Img";

const propTypes = {
  processing: PropTypes.bool,
  teamMember: PropTypes.object,
  worker: PropTypes.object,
  router: PropTypes.object,
  clearWorkerProcessing: PropTypes.func,
  downloadActionFromPreview: PropTypes.bool,
  viewDetailsActionFromPreview: PropTypes.bool
};

class ProcessingModal extends PureComponent {
  handleClose = () => {
    this.props.clearWorkerProcessing();
  };

  printPage = () => {
    this.props.router.history.push(
      `/workers/${this.props.worker.turnId}?print=true`
    );
  };

  handleAgree = () => {
    if (this.props.downloadActionFromPreview) {
      this.printPage();
    } else {
      this.props.router.history.push(`/workers/${this.props.worker.id}`);
    }
  };
  render() {
    if (!this.props.processing) return null;
    return (
      <Dialog
        fullWidth
        open
        classes={{
          paper: "processing-modal-paper"
        }}
      >
        <DialogContent style={styles.dialogContent}>
          <div style={styles.imgContainer}>
            <div style={styles.imgDimensionsContainer}>
              <Img type="ticketCreated" style={styles.headerImage} />
            </div>
          </div>
          <div style={styles.messages}>
            <div style={styles.gralMessageContainer}>
              <FormattedMessage
                {...messages.processingModalTitle}
                values={{ displayName: this.props.worker.displayName }}
              />
            </div>
            <div style={styles.specificMessageContainer}>
              <FormattedMessage {...messages.consentModalBody} />
            </div>
          </div>

          <div style={styles.buttonsContainerFlexEnd}>
            <Button
              onClick={this.handleClose}
              id="close_button"
              key="1"
              style={styles.closeWindowButton}
            >
              <div style={{ marginRight: "8px" }}>
                <i className="fas fa-times-circle" />
              </div>
              <FormattedMessage {...messages.buttonCancel} />
            </Button>
            <Button
              onClick={this.handleAgree}
              id="agree_button"
              key="2"
              style={styles.agreeButton}
            >
              <FormattedMessage {...messages.buttonAgree} />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

ProcessingModal.propTypes = propTypes;

export default ProcessingModal;
