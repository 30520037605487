import React from "react";
import PropTypes from "prop-types";
import { DatesSeenStyles as styles } from "../../styles";

const DatesSeen = ({ first, last }) => {
  if (first && last) {
    return (
      <span style={styles.container}>
        {`Last Seen On ${last}`}
        <span style={styles.separator}>|</span>
        {`First Seen On ${first}`}
      </span>
    );
  }
  if (first) return <span style={styles.container}>First Seen On {first}</span>;
  if (last) return <span style={styles.container}>Last Seen On {last}</span>;
  return null;
};

DatesSeen.propTypes = {
  first: PropTypes.string,
  last: PropTypes.string
};

export default DatesSeen;
