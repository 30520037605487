import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import Help from "material-ui/svg-icons/action/help-outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { primary, noticeError } from "../../styles/constants";

class ToolTip extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    error: PropTypes.bool,
    tooltipID: PropTypes.string,
    tooltipContent: PropTypes.node,
    tooltipPlacement: PropTypes.string,
    tooltipDescription: PropTypes.string,
    tooltipContainerStyle: PropTypes.object
  };
  renderIcon = type => {
    switch (type) {
      case "info":
        return (
          <FontAwesomeIcon
            icon={faInfoCircle}
            color={this.props.error ? noticeError : primary}
            style={{ fontSize: "15px" }}
          />
        );
      case "description":
        return this.props.tooltipDescription;
      case "help":
      default:
        return <Help color={this.props.error ? noticeError : primary} />;
    }
  };
  render() {
    if (!this.props.tooltipID) return null;
    return (
      <span style={this.props.tooltipContainerStyle}>
        <span data-tip data-for={this.props.tooltipID}>
          {this.renderIcon(this.props.type)}
        </span>
        <ReactTooltip
          id={this.props.tooltipID}
          place={this.props.tooltipPlacement}
          className="turn_tooltip"
        >
          {this.props.tooltipContent}
        </ReactTooltip>
      </span>
    );
  }
}

export default ToolTip;
