import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Highlighted from "../Highlighted";
import { Tooltip } from "@material-ui/core";

class ListSubtitle extends PureComponent {
  static propTypes = {
    subtitle: PropTypes.string
  };
  render() {
    const { subtitle } = this.props;
    if (!subtitle) {
      return null;
    }

    // 18 = 16px "W" that fit in 216px
    if (subtitle.length > 18) {
      return (
        <Tooltip title={subtitle} placement="top">
          <Highlighted light size="16px">
            {subtitle}
          </Highlighted>
        </Tooltip>
      );
    } else {
      return (
        <Highlighted light size="16px">
          {subtitle}
        </Highlighted>
      );
    }
  }
}
export default ListSubtitle;
