import styled from "styled-components";

const ContentContainer = styled.div`
  margin: 5% 17%;
  padding: ${props => (props.withImage ? "0 100px 0 200px" : "0 0 50px 0")};
  text-align: ${props => (props.withImage ? "left" : "center")};
  font-weight: 300;
  position: relative;
  @media (max-width: 600px) {
    margin: 5%;
    padding: ${props => (props.withImage ? "0" : "0")};
  }
`;

export default ContentContainer;
