import { secondary } from "../../styles/constants";

export const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Styles mimicking material <IconButton />
    minHeight: "48px",
    minWidth: "48px"
  },
  icon: {
    fontSize: "13px",
    color: secondary
  }
};
