import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import SSNTrace from "../../../components/SSNTrace";

const WorkerSSNTrace = ({ worker, ...rest }) =>
  worker.showSSNTrace ? <SSNTrace worker={worker} {...rest} /> : null;
WorkerSSNTrace.propTypes = { worker: PropTypes.object.isRequired };

export default observer(WorkerSSNTrace);
