const styles = {
  container: {
    borderBottom: "1px solid #CCC",
    paddingTop: 50,
    marginTop: 0,
    textAlign: "center",
    paddingBottom: 50
  }
};
export default styles;
