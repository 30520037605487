import React, { useEffect, useReducer } from "react";
import _ from "lodash";
import history from "../../history";
import { getStatus } from "turn-shared";
import PropTypes from "prop-types";
import { autorun } from "mobx";
import { Observer } from "mobx-react/custom";
import CandidateTile from "../CandidateTile";
import { CandidateTilesContainer } from "./CandidateTilesContainer";
import withContext from "../../contextAPI/context-HOC";
import Pagination from "./Pagination";
import DoubleBounceLoader from "../DoubleBounceLoader";
import ConsentModal from "../../containers/WorkersContainer/consent-modal";
import ProcessingModal from "../../containers/WorkersContainer/processing-modal";
import DownloadCSVModal from "../../containers/WorkersContainer/download-csv-modal";
import teamMember from "../../models/team-member";
import preventClick from "../../utils/prevent-click";
import { reducer } from "./reducer";
import { CandidateTilesHeader } from "./CandidateTilesHeader";
import FloatingButton from "../FloatingButton";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function CandidateTiles({ workers, fetch, model, dispatch, state }) {
  const { candidates, loading, selectedCandidates } = state;

  const { page, limit: pageSize, pageLimit, setLimit } = model;

  useEffect(() => {
    setLimit(10);
    fetch();
  }, []);

  useEffect(
    () =>
      autorun(() => {
        dispatch({
          type: "UPDATE_LOADING",
          payload: {
            loading: true
          }
        });
        if (workers.ready) {
          dispatch({
            type: "UPDATE_LOADING",
            payload: {
              loading: false
            }
          });
          dispatch({
            type: "UPDATE_CANDIDATES",
            payload: {
              candidates: workers.totalWorkers
            }
          });
        }
      }),
    [workers.ready]
  );

  if (loading)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <DoubleBounceLoader />
      </div>
    );

  const onPageSize = pageSize => {
    model.setLimit(pageSize);
    model.setOffset(0);
    fetch();
  };

  const onPageNumber = pageNumber => {
    --pageNumber;
    const offset = model.limit * pageNumber;
    model.setOffset(offset);
    fetch();
  };

  const handleTileClick = candidate => {
    if (candidate.hasResultAwaitingConsent) {
      dispatch({
        type: "SET_WORKER_IN_CONSENT",
        payload: { workerInConsent: candidate }
      });
    } else if (candidate.hasResultProcessing) {
      dispatch({
        type: "SET_WORKER_PROCESSING",
        payload: { workerProcessing: candidate }
      });
    } else {
      if (preventClick(history, candidate, teamMember.email)) return;
      history.push(`/workers/${candidate.turnId}`);
    }
  };

  const isSelected = candidate => {
    if (getStatus(candidate.status) !== "consider") return false;

    return _.includes(selectedCandidates, candidate);
  };

  return (
    <Observer>
      {() => (
        <div>
          <CandidateTilesContainer>
            {candidates.map((candidate, key) => (
              <CandidateTile
                key={key}
                candidate={candidate}
                history={history}
                fetch={fetch}
                handleTileClick={handleTileClick}
                selected={isSelected(candidate)}
                dispatch={dispatch}
                workers={model}
              />
            ))}
          </CandidateTilesContainer>
          <Pagination
            {...{
              page,
              pageSize,
              pageLimit,
              onPageSize,
              onPageNumber,
              setLimit
            }}
          />
        </div>
      )}
    </Observer>
  );
}

CandidateTiles.propTypes = {
  auth: PropTypes.object,
  workers: PropTypes.object,
  fetch: PropTypes.func.isRequired,
  model: PropTypes.object,
  dispatch: PropTypes.func,
  state: PropTypes.object,
  setErrorOpen: PropTypes.func
};

const CandidateTilesWithHeader = ({
  workers,
  fetch,
  auth,
  model,
  history,
  setErrorOpen
}) => {
  const [
    {
      candidates,
      loading,
      workerInConsent,
      workerProcessing,
      downloadActionFromPreview,
      selectedCandidates,
      isSelectingAll,
      isDownloading,
      isCreating,
      showCSVModal
    },
    dispatch
  ] = useReducer(reducer, {
    candidates: [],
    loading: true,
    workerInConsent: null,
    workerProcessing: null,
    downloadActionFromPreview: null,
    selectedCandidates: [],
    isSelectingAll: false,
    isDownloading: false,
    isCreating: false,
    showCSVModal: false
  });

  // FIXME: This is a fucking hack, already spent hours trying to figure it out.
  // I'll get to it after board meeting. Don't judge me.
  window.turn_add_candidate_dispatch = dispatch;

  const clearWorker = () => {
    dispatch({ type: "CLEAR_WORKERS" });
  };

  const handleSelectAll = () => {
    if (isSelectingAll) {
      dispatch({ type: "CLEAR_SELECTED" });
    } else {
      dispatch({ type: "SELECT_ALL" });
    }
  };

  const setAlert = () => {
    toastr.options = {
      closeButton: true,
      positionClass: "toast-top-full-width",
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "10000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      preventDuplicates: true,
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut"
    };

    if (workers.workerToastr.success) {
      toastr.success(workers.workerToastr.message || "Success!");
      setTimeout(
        () =>
          workers.setWorkerToastr({ success: null, error: null, message: "" }),
        10000
      );
    }

    if (workers.workerToastr.error) {
      toastr.error(
        workers.workerToastr.message ||
          "There was an error. Please try again. If the issue persists, contact us at support@turn.ai."
      );
      setTimeout(
        () =>
          workers.setWorkerToastr({ success: null, error: null, message: "" }),
        5000
      );
    }
  };

  return (
    <>
      {CandidateTilesHeader(
        isSelectingAll,
        handleSelectAll,
        selectedCandidates,
        dispatch,
        isCreating,
        auth,
        model,
        fetch,
        isDownloading,
        workers,
        setErrorOpen
      )}
      <ConsentModal
        consent={!!workerInConsent}
        teamMember={teamMember}
        router={{ history }}
        worker={workerInConsent}
        clearWorkerInConsent={clearWorker}
        downloadActionFromPreview={downloadActionFromPreview}
      />
      <ProcessingModal
        processing={!!workerProcessing}
        teamMember={teamMember}
        router={{ history }}
        worker={workerProcessing}
        clearWorkerProcessing={clearWorker}
        downloadActionFromPreview={downloadActionFromPreview}
      />
      <DownloadCSVModal
        showCSVModal={showCSVModal}
        dispatch={dispatch}
        model={model}
      />
      <CandidateTiles
        workers={workers}
        fetch={fetch}
        model={model}
        dispatch={dispatch}
        state={{
          candidates,
          loading,
          workerInConsent,
          workerProcessing,
          downloadActionFromPreview,
          selectedCandidates,
          isSelectingAll,
          isDownloading,
          isCreating
        }}
      />
      {teamMember.canAddCandidates && (
        <FloatingButton
          onClick={() =>
            dispatch({
              type: "CREATING",
              payload: {
                creating: true
              }
            })
          }
          style={{ zIndex: 999 }}
        >
          + Add Candidate(s)
        </FloatingButton>
      )}
      {setAlert()}
    </>
  );
};

export default withContext(CandidateTilesWithHeader);
