import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Column from "../Column";
import Highlighted from "../Highlighted";
import { LandingPageStyles as styles } from "../../styles";

const FieldSet = ({
  shouldRender,
  children,
  message,
  htmlFor,
  width,
  padding,
  disabled,
  error,
  style = {}
}) =>
  shouldRender ? (
    <Column
      width={width || "50%"}
      widthSM="100%"
      padding={padding || "10px"}
      verticalAlign="top"
      style={style}
    >
      {message ? (
        <label htmlFor={htmlFor} style={styles.label}>
          <Highlighted disabled={disabled} error={error}>
            <span>
              <FormattedMessage {...message} />
              {disabled ? "" : "*"}
            </span>
          </Highlighted>
        </label>
      ) : null}
      {children}
    </Column>
  ) : null;

FieldSet.propTypes = {
  shouldRender: PropTypes.bool,
  children: PropTypes.node,
  error: PropTypes.bool,
  htmlFor: PropTypes.string,
  message: PropTypes.object,
  width: PropTypes.string,
  padding: PropTypes.string,
  disabled: PropTypes.bool,
  string: PropTypes.object
};

export default FieldSet;
