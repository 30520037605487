import { defineMessages } from "react-intl";

const _fountain = {
  positiveResponse: {
    id: "app.components.WorkerSettings.fountainCredentialsPositiveResponse",
    defaultMessage:
      "The provided API Key is valid and has been saved correctly."
  },
  authenticationRequired: {
    id:
      "app.components.WorkerSettings.fountainCredentialsAuthenticationRequired",
    defaultMessage:
      "The provided API key is invalid, please verify it and try again. No changes were performed. Contact support@turn.ai if you need assistance."
  },
  errorResponse: {
    id: "app.components.WorkerSettings.fountainCredentialsErrorResponse",
    defaultMessage:
      "Something went wrong setting the credentials. Please check again."
  },
  noNewValuesProvidedResponse: {
    id: "app.components.WorkerSettings.fountainCredentialsErrorResponse",
    defaultMessage: "No new values provided, no changes were performed."
  },
  badRequestResponse: {
    id: "app.components.WorkerSettings.badRequestResponse",
    defaultMessage: "No new values provided, no changes were performed."
  }
};

export const fountain = defineMessages(_fountain);
export const webhook = defineMessages({
  ..._fountain,
  positiveResponse: {
    id: "app.components.WorkerSettings.fountainCredentialsPositiveResponse",
    defaultMessage: "The provided Webhook URL has been saved correctly."
  },
  invalidWebhook: {
    id: "app.components.WorkerSettings.fountainCredentialsInvalidWebhook",
    defaultMessage: "The provided Webhook URL is not a valid URL."
  }
});

export const lever = defineMessages({
  positiveResponse: {
    id: "app.components.WorkerSettings.leverCredentialsPositiveResponse",
    defaultMessage: "The provided details have been saved correctly."
  }
});

export const password = defineMessages({
  positiveResponse: {
    id: "app.components.WorkerSettings.passwordUpdatePositiveResponse",
    defaultMessage: "Password updated successfuly"
  },
  authenticationRequired: {
    id: "app.components.WorkerSettings.authenticationRequired",
    defaultMessage:
      "Your session is invalid. Please refresh the page and try again."
  },
  errorResponse: {
    id: "app.components.WorkerSettings.errorResponse",
    defaultMessage:
      "Something went wrong updating your password. Please try again."
  },
  noNewValuesProvidedResponse: {
    id: "app.components.WorkerSettings.errorResponse",
    defaultMessage:
      "Something went wrong updating your password. Please try again."
  },
  badRequestResponse: {
    id: "app.components.WorkerSettings.badRequestResponse",
    defaultMessage: "Passwords do not match. Please try again."
  }
});

export const defaults = defineMessages({
  positiveResponse: {
    id: "app.components.WorkerSettings.passwordUpdatePositiveResponse",
    defaultMessage: "Default days updated successfully"
  },
  authenticationRequired: {
    id: "app.components.WorkerSettings.authenticationRequired",
    defaultMessage:
      "Your session is invalid. Please refresh the page and try again."
  },
  errorResponse: {
    id: "app.components.WorkerSettings.errorResponse",
    defaultMessage:
      "Something went wrong updating your defaults. Please try again."
  },
  noNewValuesProvidedResponse: {
    id: "app.components.WorkerSettings.errorResponse",
    defaultMessage:
      "Something went wrong updating your defaults. Please try again."
  },
  badRequestResponse: {
    id: "app.components.WorkerSettings.badRequestResponse",
    defaultMessage: "Please try again."
  }
});
