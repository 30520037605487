import React, { useState, useEffect } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Icon,
  Typography,
  withStyles
} from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames";

import {
  CandidateReportStyles as reportStyles,
  WorkerStyles as workerStyles
} from "../../../styles";
import Row from "../../../components/Row";
import Text from "../../../components/Text";
import { orange } from "../../../styles/constants";
import { SearchHighlighted } from "../../../components/SearchHighlighted";

const styles = theme => ({
  root: {
    boxShadow: "none"
  },
  expandIcon: {
    width: "auto",
    height: "auto",
    overflow: "visible",
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(18),
    verticalAlign: "middle"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "bold",
    color: theme.palette.text.primary,
    flex: 1
  },
  headingIcon: {
    width: "auto",
    height: "auto",
    overflow: "visible",
    color: orange,
    fontSize: theme.typography.pxToRem(21),
    marginRight: theme.spacing.unit * 4,
    verticalAlign: "middle"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "normal",
    color: theme.palette.text.primary
  }
});

const RecordItemSummary = styled(ExpansionPanelSummary)``;

const RecordItemDetails = styled(ExpansionPanelDetails)`
  padding-left: 120px !important;
  padding-right: 120px !important;
  flex-direction: column;
`;

export const CanadaCriminalRecordItemComponent = ({
  classes,
  title,
  stage,
  source,
  type,
  description,
  date,
  expanded
}) => {
  const [_expanded, _setExpanded] = useState(false);
  // Keep incoming and internal expand state in sync
  useEffect(() => {
    if (_expanded != expanded) {
      _setExpanded(expanded);
    }
  }, [expanded]);

  return (
    <ExpansionPanel
      className={classes.root}
      expanded={_expanded}
      onChange={() => _setExpanded(!_expanded)}
    >
      <RecordItemSummary
        expandIcon={
          _expanded ? (
            <Icon
              className={classNames(classes.expandIcon, "fas fa-minus-circle")}
            />
          ) : (
            <Icon
              className={classNames(classes.expandIcon, "fas fa-plus-circle")}
            />
          )
        }
      >
        <Typography className={classes.heading}>
          <Icon
            className={classNames(
              classes.headingIcon,
              "fas fa-exclamation-triangle"
            )}
          />
          <SearchHighlighted textToHighlight={title} />
        </Typography>
        <Typography className={classes.secondaryHeading}>
          <SearchHighlighted textToHighlight={date} />
        </Typography>
      </RecordItemSummary>

      <RecordItemDetails>
        <Row style={{ marginLeft: -5 }}>
          <div style={workerStyles.ssn.header}>
            <Text style={reportStyles.label}>Stage:&nbsp;</Text>
            <Text style={reportStyles.value}>
              <SearchHighlighted textToHighlight={stage} />
            </Text>
          </div>
          <div style={workerStyles.ssn.header}>
            <Text style={reportStyles.label}>Type:&nbsp;</Text>
            <Text style={reportStyles.value}>
              <SearchHighlighted textToHighlight={type} />
            </Text>
          </div>
          {source && (
            <div style={workerStyles.ssn.header}>
              <Text style={reportStyles.label}>Source:&nbsp;</Text>
              <Text style={reportStyles.value}>
                <a href={source}>{source}</a>
              </Text>
            </div>
          )}
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Typography>
            {source && (
              <Text style={reportStyles.label}>Description:&nbsp;</Text>
            )}
            <SearchHighlighted textToHighlight={description} />
          </Typography>
        </Row>
      </RecordItemDetails>
    </ExpansionPanel>
  );
};

CanadaCriminalRecordItemComponent.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  stage: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  expanded: PropTypes.bool,
  source: PropTypes.string
};

export const CanadaCriminalRecordItem = withStyles(styles)(
  CanadaCriminalRecordItemComponent
);
