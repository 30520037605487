import styled from "styled-components";

export const ListWrapper = styled.div`
  min-height: 4rem;
  display: grid;
  align-items: center;
  white-space: nowrap;
  grid-template-columns: repeat(3, 1fr);
`;

export const DrugTestType = styled.p`
  font-weight: 500;
`;

export const CheckStatusContainer = styled.div`
  max-width: 180px;
  text-wrap: wrap;
`;

export const CheckStatus = styled.p`
  margin: 0;
  font-size: 15px;
  text-transform: uppercase;
`;

export const DTStatus = styled.p`
  margin: 0;
  font-size: 12px;
`;
