import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import _ from "lodash";
import { availableStatuses } from "turn-shared";
import WorkerEmailToolbar from "../WorkerEmailToolbar";
import Badge from "../Badge";

const { EMAILED } = availableStatuses;

const CandidateStatusUI = observer(
  class CandidateStatusUI extends Component {
    static propTypes = {
      worker: PropTypes.object.isRequired,
      enableEmailStatus: PropTypes.bool.isRequired,
      status: PropTypes.string.isRequired,
      adverseAction: PropTypes.bool.isRequired,
      fullWidth: PropTypes.bool
    };

    constructor(props) {
      super(props);
    }

    render() {
      const {
        worker,
        adverseAction,
        enableEmailStatus,
        fullWidth,
        status
      } = this.props;
      if (_.isEmpty(worker)) return null;

      if (worker.status === EMAILED && enableEmailStatus) {
        return (
          <>
            <Badge
              status={status}
              adverseAction={adverseAction}
              toolbar={<WorkerEmailToolbar worker={worker} size="small" />}
              fullWidth={fullWidth}
            />
          </>
        );
      }

      return (
        <Badge
          status={status}
          adverseAction={adverseAction}
          size="large"
          fullWidth={fullWidth}
        />
      );
    }
  }
);

export default CandidateStatusUI;
