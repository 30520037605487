import _ from "lodash";
import { isObservableArray } from "mobx";

export const getCriminalData = criminalRecords => {
  if (!criminalRecords || !Array.isArray(criminalRecords))
    return criminalRecords;
  if (criminalRecords.length === 0) return [];
  return criminalRecords
    .map(record =>
      _.isObject(record)
        ? {
            source: record.source,
            source_state: record.source_state,
            case_number: record.case_number,
            arresting_agency: record.arresting_agency,
            county: record.county,
            crime_county: record.crime_county,
            status: record.status,
            status_date: record.status_date,
            crime_type: record.crime_type,
            classification: record.classification,
            offense_code: record.offense_code,
            degree_of_offense: record.degree_of_offense,
            offense_description_1: record.offense_description_1,
            counts: record.counts,
            case_type: record.case_type,
            violation: record.violation,
            court: record.court,
            fines: record.fines,
            plea: record.plea,
            conviction_place: record.conviction_place,
            sentence: record.sentence,
            probation: record.probation,
            disposition: record.disposition,
            charges_filed_date: record.charges_filed_date,
            offense_date: record.offense_date,
            violation_date: record.violation_date,
            arrest_date: record.arrest_date,
            conviction_date: record.conviction_date,
            disposition_date: record.disposition_date,
            registration_date: record.registration_date,
            registration_end_date: record.registration_end_date,
            predator: record.predator,
            victim: record.victim,
            victim_gender: record.victim_gender,
            victim_age: record.victim_age,
            victim_is_minor: record.victim_is_minor,
            category: record.category,
            offense_description_2: record.offense_description_2,
            first_name_match: record.first_name_match,
            middle_name_match: record.middle_name_match,
            last_name_match: record.last_name_match,
            date_of_birth_match: record.date_of_birth_match,
            address_match: record.address_match,
            worker_info: getCriminalSuspect(record)
          }
        : null
    )
    .filter(record => !!record);
};

export const getCriminalSuspect = record =>
  record
    ? {
        current_age: record.current_age,
        a_k_as: record.a_k_as,
        other_dates_of_birth: record.other_dates_of_birth,
        other_addresses: record.other_addresses,
        birth_address: record.birth_address,
        birth_place: record.birth_place,
        citizenship: record.citizenship,
        complexion: record.complexion,
        build: record.build,
        hair: record.hair,
        hair_length: record.hair_length,
        gender: record.gender,
        scars_marks: record.scars_marks,
        skin_tone: record.skin_tone,
        height: record.height,
        weight: record.weight,
        ethnicity: record.ethnicity,
        eyes: record.eyes,
        is_sex_offender: record.is_sex_offender,
        military_service: record.military_service,
        marital_status: record.marital_status,
        employer: record.employer,
        employer_address: record.employer_address,
        occupation: record.occupation,
        drivers_license_number: record.drivers_license_number,
        drivers_license_issuing_state: record.drivers_license_issuing_state,
        name: record.name,
        first_name: record.suspect_first_name,
        middle_name: record.suspect_middle_name,
        last_name: record.suspect_last_name,
        date_of_birth: record.date_of_birth,
        ssn: record.ssn,
        ssn_record: record.ssn_record,
        address_line_1: record.suspect_address_line_1,
        address_line_2: record.suspect_address_line_2,
        address_line_3: record.suspect_address_line_3,
        city: record.suspect_city,
        state: record.suspect_state,
        zip: record.suspect_zip,
        county: record.suspect_county,
        latitude: record.suspect_latitude,
        longitude: record.suspect_longitude
      }
    : null;

export const getWatchlistData = watchLists => {
  if (!watchLists || !Array.isArray(watchLists)) return watchLists;
  if (watchLists.length === 0) return [];
  return watchLists
    .map(record =>
      _.isObject(record)
        ? {
            score: record.score,
            page_url: record.page_url,
            source: record.source,
            type: record.type,
            offense: record.offense,
            wanted_by: record.wanted_by,
            program: record.program,
            legal_basis: record.legal_basis,
            listing_date: record.listing_date,
            remarks: record.remarks,
            worker_info: getWatchlistSuspect(record)
          }
        : null
    )
    .filter(record => !!record);
};

export const getWatchlistSuspect = record =>
  record
    ? {
        name: record.name,
        name_first_seen: record.name_first_seen,
        name_last_seen: record.name_last_seen,
        name_source: record.name_source,
        name_score: record.name_score,
        name_observations: record.name_observations,
        full_name: record.full_name,
        title: record.title,
        eyes: record.eyes,
        hair: record.hair,
        weight: record.weight,
        height: record.height,
        marks: record.marks,
        sex: record.sex,
        languages: record.languages,
        TIN: record.TIN,
        documents: record.documents,
        place_of_birth: record.place_of_birth,
        date_of_birth: record.date_of_birth,
        citizenship: record.citizenship,
        phone: record.phone,
        fax: record.fax,
        image_url: record.image_url,
        address_remarks: record.address_remarks,
        alias_type: record.alias_type,
        alias_name: record.alias_name,
        country: record.country,
        address_line_1: record.suspect_address_line_1,
        address_line_2: record.suspect_address_line_2,
        address_line_3: record.suspect_address_line_3,
        city: record.suspect_city,
        state: record.suspect_state,
        zip: record.suspect_zip,
        county: record.suspect_county,
        latitude: record.suspect_latitude,
        longitude: record.suspect_longitude
      }
    : null;

export const getPropertyData = properties => {
  if (!properties || !Array.isArray(properties)) return properties;
  if (properties.length === 0) return [];
  return properties
    .map(record =>
      _.isObject(record)
        ? {
            address: record.address,
            latitude: record.latitude,
            longitude: record.longitude,
            recording_day: record.recording_date,
            sale_day: record.sale_date,
            mortgage: getPropertyMortgage(record)
          }
        : null
    )
    .filter(record => !!record);
};

export const getPropertyMortgage = record => ({
  mortgage_day: record.mortgage_date,
  document_type_code: record.deed_document_type,
  transaction_type: record.deed_transaction_type,
  document_number: record.deed_document_number,
  lender_company_code: record.lender_company_code,
  title_company_name: record.title_company,
  title_company_code: record.title_company_code,
  amount: record.mortgage_amount,
  interest_rate: record.mortgage_interest_rate,
  loan_type_code: record.mortgage_loan_type,
  deed_type_code: record.mortgage_deed_type,
  term_code: record.mortgage_term_code,
  term: record.mortgage_term,
  universal_land_use: record.universal_land_use,
  property_indicator: record.property_indicator,
  seller_name: record.seller_name,
  lender_name: record.lender_name,
  owners: record.owners,
  sellers: record.sellers
});

export const getVehicleData = vehicles => {
  if (!vehicles || !Array.isArray(vehicles)) return vehicles;
  if (vehicles.length === 0) return [];
  return vehicles
    .map(record =>
      _.isObject(record)
        ? {
            VIN: record.VIN,
            vehicle_type: record.vehicle_type,
            make: record.make,
            model: record.model,
            series: record.series,
            year: record.year,
            primary_color: record.primary_color,
            secondary_color: record.secondary_color,
            weight: record.weight,
            length: record.length,
            body_style: record.body_style,
            restraint_type: record.restraint_type,
            drive_type: record.drive_type,
            fuel_type: record.fuel_type,
            title_holders: getTitleHolders(record.registrants),
            license_plates: getLicensePlates(record.registrants)
          }
        : null
    )
    .filter(record => !!record);
};

const isMobXArray = array => Array.isArray(array) || isObservableArray(array);

export const getTitleHolders = registrants => {
  if (!isMobXArray(registrants)) return null;
  const title_holders = registrants
    .filter(registrant => !!registrant)
    .map(registrant =>
      isMobXArray(registrant.titles)
        ? registrant.titles.map(title =>
            isMobXArray(title.title_holders)
              ? title.title_holders.map(title_holder =>
                  getTitleHolderData(title_holder)
                )
              : null
          )
        : null
    );
  return _.flattenDeep(title_holders).filter(
    holder => !!holder && !_.isEmpty(holder)
  );
};

const getTitleHolderData = title_holder => {
  if (!title_holder || !_.isObject(title_holder)) return null;
  return {
    name: title_holder.name || "Not Provided",
    current_age: title_holder.current_age || "Not Provided",
    ssn: title_holder.ssn || "Not Provided",
    date_of_birth: title_holder.date_of_birth || "Not Provided"
  };
};

export const getLicensePlates = registrants => {
  if (!isMobXArray(registrants)) return null;
  const license_plates = registrants
    .filter(registrant => !!registrant)
    .map(registrant =>
      isMobXArray(registrant.tags)
        ? registrant.tags
            .filter(value => _.isObject(value))
            .map(
              value =>
                `Plate: ${value.license_plate ||
                  "Not Provided"}, State: ${value.license_plate_state ||
                  "Not Provided"}`
            )
        : null
    );
  return _.flatten(license_plates).filter(plate => !!plate);
};
