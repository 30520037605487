import React from "react";
import PropTypes from "prop-types";
import { abbreviatedMonths } from "../../data/dates";
import style from "./feature-card-styles";

const propTypes = {
  feature: PropTypes.object,
  dTime: PropTypes.object.isRequired,
  style: PropTypes.object
};
const defaultProps = {
  feature: {},
  dTime: new Date()
};

const FeatureCard = ({ feature, dTime }) => {
  return (
    <div>
      <div style={style.featureWrapper}>
        <article style={style.featureBox}>
          <div style={style.featureDateContainer}>
            <div style={style.featureDayText}>
              {("0" + dTime.getDate()).slice(-2)}
            </div>
            <div style={style.featureMonthText}>
              {abbreviatedMonths[dTime.getMonth() + 1]}
            </div>
          </div>
          <div>
            <div style={style.featureTitleContainer}>
              <div style={style.featureTitleText}>{feature.title}</div>
            </div>
            <div style={style.featureContainer}>
              <div style={style.featureText}>
                {feature.description}

                {feature.image ? (
                  <img style={style.featureMultimedia} src={feature.image} />
                ) : null}

                {feature.video ? (
                  feature.video.includes("youtube") ? (
                    <iframe
                      style={style.featureMultimedia}
                      src={feature.video}
                      frameBorder="0"
                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen="true"
                    />
                  ) : (
                    <video style={style.featureMultimedia} controls>
                      <source src={feature.video} type="video/mp4" />
                      <source src={feature.video} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </article>
      </div>
      <hr style={style.featureDivider} />
    </div>
  );
};

FeatureCard.propTypes = propTypes;
FeatureCard.defaultProps = defaultProps;
export default FeatureCard;
