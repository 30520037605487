import React from "react";
import PropTypes from "prop-types";
import { getStatus } from "turn-shared";
import StatusBadgeContainer from "./StatusBadgeContainer";
import { ReactComponent as ApprovedIcon } from "../../icons/approved-icon.svg";
import { ReactComponent as ConsentIcon } from "../../icons/consent-icon.svg";
import { ReactComponent as ConsiderIcon } from "../../icons/consider-icon.svg";
import { ReactComponent as ProcessingIcon } from "../../icons/processing-icon.svg";
import { ReactComponent as InitiatedIcon } from "../../icons/initiated-icon.svg";
import { ReactComponent as RejectedIcon } from "../../icons/rejected-icon.svg";
import { ReactComponent as ReviewingIcon } from "../../icons/reviewing-icon.svg";
import { ReactComponent as WithdrawIcon } from "../../icons/withdraw-icon.svg";
import Emailed from "./Emailed";
import StatusBadgeLayout from "./StatusBadgeLayout";
import workers from "../../models/workers";
import Checkbox from "material-ui/Checkbox";

const statusIcon = {
  approved: ApprovedIcon,
  consent: ConsentIcon,
  consider: ConsiderIcon,
  first_notice: ConsiderIcon,
  second_notice: ConsiderIcon,
  processing: ProcessingIcon,
  initiated: InitiatedIcon,
  emailed: InitiatedIcon,
  rejected: RejectedIcon,
  reviewing: ReviewingIcon,
  withdrawn: WithdrawIcon,
  verifying: ReviewingIcon,
  "Compliance Review": ReviewingIcon
};

function StatusBadge({ candidate, fetch, selected, dispatch }) {
  const status = getStatus(candidate.status);

  if (status === "emailed") {
    return <Emailed candidate={candidate} />;
  }
  const Icon = statusIcon[status];

  const handleClick = event => {
    event.stopPropagation();
    workers.setStatus(candidate.status);
    fetch();
  };

  const handleCheck = event => {
    event.stopPropagation();
    if (selected) {
      dispatch({ type: "REMOVE_SELECTED", payload: { id: candidate.id } });
    } else {
      dispatch({ type: "ADD_SELECTED", payload: { candidate } });
    }
  };

  return (
    <>
      <StatusBadgeLayout>
        {status === "consider" ? (
          <Checkbox
            style={{ width: 28 }}
            checked={selected}
            onClick={handleCheck}
          />
        ) : null}

        <StatusBadgeContainer
          id="statusBadgeContainer"
          onClick={handleClick}
          status={status}
        >
          {Icon ? <Icon width="28px" /> : null}
          {status.replace("_", " ")}
        </StatusBadgeContainer>
      </StatusBadgeLayout>
    </>
  );
}

StatusBadge.propTypes = {
  candidate: PropTypes.object,
  fetch: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  dispatch: PropTypes.func
};

export default StatusBadge;
