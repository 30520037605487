// import { observable, action, computed, reaction, decorate } from "mobx";
import { observable, computed, decorate, action } from "mobx";
import { Setter } from "turn-shared";
import { database } from "../../src/index";
import member from "../models/team-member";
import _ from "lodash";

/**
 * @class
 * @desc NewFeatures model with MobX enhancements
 */
class NewFeatures extends Setter {
  // Observable Properties
  features = [];
  areThereNewFeatures = false;
  featuresSelected = false;
  disableButton = false;
  filters = {};
  timeSpanFeaturesInMonths = 3;

  get featuresOfLastNMonths() {
    const d = new Date();
    const timeSpan = d.setMonth(d.getMonth() - this.timeSpanFeaturesInMonths);
    return timeSpan;
  }

  setDisableButton = this.setBoolFactory("disableButton");

  get disableButtonStatus() {
    return this.disableButton;
  }

  setFeaturesSelected = this.setBoolFactory("featuresSelected");

  get featuresSelectedComputed() {
    return this.featuresSelected;
  }

  setAreThereNewFeatures = this.setBoolFactory("areThereNewFeatures");

  setFeatures = features => {
    if (!_.isObject(features)) return;
    this.features = features;
  };

  getUserFeatures = async () => {
    database
      .ref("users2")
      .once("value")
      .then(snapshot => {
        this.handleUserFeatures(snapshot);
      });
  };

  handleUserFeatures = async snapshot => {
    let userId = member.member_id;
    if (typeof member.member_id !== "undefined") {
      userId = member.member_id.toString();
    }
    if (!userId) return;
    const filteredFeatures = await this.getFilteredFeatures();
    if (snapshot.hasChild(userId)) {
      const resp = await this.getUserForFeatures(userId);
      const { penultimateVisitToFeatures, lastVisitToFeatures } = resp;

      if (penultimateVisitToFeatures) {
        const checkFilteredFeatures = await this.checkFilteredFeatures(
          penultimateVisitToFeatures || false,
          filteredFeatures
        );
        this.setAreThereNewFeatures(!!checkFilteredFeatures);
      }

      this.setFeatures(filteredFeatures);

      this.setVisitDates(userId, lastVisitToFeatures, new Date().getTime());
    } else {
      this.createUserForFeatures(userId);
      this.setFeatures(filteredFeatures);
      this.setAreThereNewFeatures(true);
    }
  };

  applyFilters = async features => {
    const arrayFeatures = _.toArray(features);
    _.remove(
      arrayFeatures,
      _.conforms({
        availableForPartners: function(arrayOfPartners) {
          const notAvailable = !arrayOfPartners.includes(member.partner_id);
          return notAvailable;
        }
      })
    );

    return arrayFeatures;
  };

  getUserForFeatures = async userId => {
    return database
      .ref(`users2/${userId}`)
      .once("value")
      .then(snapshot => {
        return snapshot.val();
      });
  };

  checkFeatures = async dateTime => {
    return database
      .ref(`features/`)
      .orderByChild("dateTime")
      .startAt(dateTime)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()) {
          return true;
        }
        return false;
      });
  };

  checkFilteredFeatures = async (
    penultimateVisitDateTime,
    filteredFeatures
  ) => {
    const arrayFeatures = _.toArray(filteredFeatures);

    const res = _.filter(
      arrayFeatures,
      _.conforms({
        dateTime: function(dT) {
          return dT > penultimateVisitDateTime;
        }
      })
    );

    if (_.isEmpty(res)) {
      return false;
    }
    return true;
  };

  getFeatures = async () => {
    return database
      .ref(`features/`)
      .orderByChild("dateTime")
      .startAt(this.featuresOfLastNMonths)
      .once("value")
      .then(snapshot => {
        return snapshot.val();
      });
  };

  createUserForFeatures = async userId => {
    database.ref(`/users2/${userId}`).set({
      penultimateVisitToFeatures: false,
      lastVisitToFeatures: new Date().getTime()
    });
  };

  setVisitDates = (userId, penultimateVisitToFeatures, lastVisitToFeatures) => {
    database.ref(`users2/${userId}`).set({
      penultimateVisitToFeatures,
      lastVisitToFeatures
    });
  };

  get featuresArrayReversed() {
    const featuresArray = Object.values(this.features || {});
    return featuresArray.reverse();
  }

  getFilteredFeatures = async () => {
    const features = await this.getFeatures();
    return this.applyFilters(features);
  };
}

decorate(NewFeatures, {
  // OBSERVABLE
  features: observable,
  featuresSelected: observable,
  areThereNewFeatures: observable,
  disableButton: observable,
  // COMPUTED
  featuresArrayReversed: computed,
  featuresSelectedComputed: computed,
  disableButtonStatus: computed,
  featuresOfLastNMonths: computed,
  // ACTION
  setDisableButton: action,
  setFeaturesSelected: action,
  setAreThereNewFeatures: action
});

const newFeatures = new NewFeatures();
export default newFeatures;
