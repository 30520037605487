import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Icon as IconBase,
  withStyles
} from "@material-ui/core";

import { primary, white, slateGray, red } from "../../../styles/constants";
import { freshdeskAPI } from "../../../services";
import teamMember from "../../../models/team-member";

import { Button } from "../../Button";
import TextArea from "../../TextArea";

import SnackbarToast from "../../SnackbarToast";

import {
  SubscriptionEditPicker,
  SubscriptionEditPickerItem
} from "./SubscriptionEditPicker";
import styled from "styled-components";

const styles = {
  cancelIcon: {
    fontSize: 14,
    marginRight: 7,
    overflow: "visible"
  }
};

export const SubscriptionEditDialog = withStyles({
  paper: {
    backgroundColor: primary,
    borderRadius: 14
  }
})(Dialog);

export const SubscriptionEditDialogContent = withStyles({
  root: {
    backgroundColor: white,
    borderRadius: 14,
    margin: 18,
    width: 420,
    padding: 24
  }
})(DialogContent);

export const SubscriptionEditDialogActions = withStyles({
  root: {
    margin: 0,
    justifyContent: "center",
    marginTop: 21
  }
})(DialogActions);

const Title = styled.h3`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin: 0 32px;
  color: ${primary};
`;

const Subtitle = styled.h4`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin: 28px;
  color: ${slateGray};
`;

const Icon = withStyles({
  root: {
    width: "auto",
    height: "auto",
    overflow: "visible",
    verticalAlign: "middle",
    fontSize: 28,
    marginRight: 18
  }
})(IconBase);

const NoteLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
  margin: 21px;
  color: ${primary};
`;

const NoteInput = styled(TextArea)`
  width: calc(100% - 42px);
  margin: 0 21px;
  height: 120px;
`;

const SubmitButton = withStyles({
  root: {
    textTransform: "none",
    padding: "0 42px"
  }
})(Button);

export const SubscriptionEdit = ({ onClose, ...props }) => {
  const [selectedEdit, setSelectedEdit] = useState(undefined);
  const [note, setNote] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const canSubmit = !!selectedEdit;

  const _onClose = () => {
    if (isSubmitting) {
      return;
    }

    onClose && onClose();
  };

  const _onSubmit = () => {
    if (isSubmitting) {
      return;
    } else {
      setIsSubmitting(true);
    }

    let subject = "Subscription: ";
    switch (selectedEdit) {
      case "edit":
        subject += "Edit Subscription";
        break;
      case "cancel":
        subject += "Cancel Subscription";
        break;
    }

    let type = "";
    switch (selectedEdit) {
      case "edit":
        type = "Edit Sourcing Subscription";
        break;
      case "cancel":
        type = "Cancel Sourcing Subscription";
        break;
    }

    freshdeskAPI.createTicket({
      email: teamMember.email,
      subject,
      type,
      description: note || "No notes added",
      status: 2, // open
      priority: 3, // high
      custom_fields: {
        cf_partner_name: teamMember.partner.name
      }
    });

    setSubmitSuccess(true);
    setIsSubmitting(false);
    onClose && onClose();
  };

  return (
    <>
      <SubscriptionEditDialog onClose={_onClose} {...props}>
        <SubscriptionEditDialogContent>
          <Title>How can we help you?</Title>
          <Subtitle>
            Select an option below and a Turn Representative will be in touch
            shortly.
          </Subtitle>

          <SubscriptionEditPicker>
            <SubscriptionEditPickerItem
              name="subs_edit"
              value="edit"
              checked={selectedEdit == "edit"}
              onClick={() => setSelectedEdit("edit")}
            >
              <Icon className="fas fa-edit" />
              Edit my subscription
            </SubscriptionEditPickerItem>
            <SubscriptionEditPickerItem
              name="subs_edit"
              value="cancel"
              checked={selectedEdit == "cancel"}
              onClick={() => setSelectedEdit("cancel")}
            >
              <Icon className="fas fa-power-off" />
              Cancel my subscription
            </SubscriptionEditPickerItem>
          </SubscriptionEditPicker>

          <NoteLabel>Do you want to add a note?</NoteLabel>
          <NoteInput
            value={note}
            onChange={({ target }) => setNote(target.value)}
          />

          <SubscriptionEditDialogActions>
            <Button color="primary" onClick={_onClose} style={{ color: red }}>
              <IconBase
                className={"fas fa-times-circle"}
                style={styles.cancelIcon}
              />
              Cancel
            </Button>
            <SubmitButton
              color="secondary"
              variant="contained"
              disabled={!canSubmit}
              onClick={_onSubmit}
            >
              Send
            </SubmitButton>
          </SubscriptionEditDialogActions>
        </SubscriptionEditDialogContent>
      </SubscriptionEditDialog>
      <SnackbarToast
        open={submitSuccess}
        message="We've received your inquiry! Our support team will contact you as soon as possible."
        type="success"
        onRequestClose={() => setSubmitSuccess(false)}
      />
    </>
  );
};
