import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import Highlighted from "../Highlighted";
import messages from "./messages";

const propTypes = {
  message: PropTypes.string,
  inherit: PropTypes.bool,
  error: PropTypes.bool,
  style: PropTypes.object
};

const FormattedHeader = ({ message, inherit, error, style }) => (
  <Highlighted inherit={inherit} error={error} style={style}>
    {message in messages ? (
      <FormattedMessage {...messages[message]} />
    ) : (
      _.startCase(message)
    )}
  </Highlighted>
);

FormattedHeader.propTypes = propTypes;

export default FormattedHeader;
