import React from "react";
import { Button, Icon, withStyles } from "@material-ui/core";
import styled from "styled-components";
import classNames from "classnames";
import { PropTypes } from "prop-types";

const styles = {
  icon: {
    width: "auto",
    height: "auto",
    overflow: "visible",
    verticalAlign: "middle",
    fontSize: 16,
    marginRight: 7
  }
};

const ImageBoxWrapper = styled.div`
  flex: 1;
  textalign: center;
  padding: 5px;
`;

const ImageBoxLabel = styled.div`
  text-align: left,
  font-size: 14px
  font-weight: bold;
  margin: 7px 0;
`;

export const ImageBoxComponent = ({
  classes,
  label,
  src,
  onClick,
  ...props
}) => {
  return (
    <ImageBoxWrapper {...props}>
      <ImageBoxLabel>{label}</ImageBoxLabel>

      <img src={src} alt={`${label}`} style={{ width: "100%" }} />

      <div style={{ textAlign: "center", marginTop: 10 }}>
        <Button variant="text" color="secondary" size="small" onClick={onClick}>
          <Icon className={classNames(classes.icon, "fas fa-eye")} />
          View
        </Button>
      </div>
    </ImageBoxWrapper>
  );
};
ImageBoxComponent.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export const ImageBox = withStyles(styles)(ImageBoxComponent);
