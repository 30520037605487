import React, { useMemo, useContext, useState } from "react";
import _ from "lodash";
import { CandidateReportStyles as reportStyles } from "../../../styles";

import messages from "../messages";
import PanelCollapsible from "../../../components/PanelCollapsible";
import { green } from "../../../styles/constants";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";

import { AppContext } from "../../../contextAPI/context-provider";
import { AutoTable } from "../../../components/AutoTable/AutoTable";

export const CanadaWorkerAddresses = ({ worker }) => {
  const context = useContext(AppContext);
  const { checks = {} } = context;
  const [isProcessing] = useState(worker.status === "processing");
  const addresses = useMemo(() => {
    const _addressesObj = _.get(
      worker,
      "canadianReport.information_search.addresses"
    );
    const _addressesArr = _.values(_addressesObj);

    return _.filter(_addressesArr, address => {
      // Make sure to keep this array in sync with below `AutoTable` component `header` prop
      for (const key of ["address", "city", "province_state", "postal_code"]) {
        const val = _.get(address, key);
        if (_.isEmpty(val)) {
          return false;
        }
      }

      return true;
    });
  }, [worker]);

  if (_.isEmpty(addresses)) {
    return null;
  }

  return (
    <PanelCollapsible
      title={messages.addressTitle}
      titleStyle={reportStyles.title}
      iconStyle={reportStyles.checkedIcon}
      color={green}
      icon={faCheckCircle}
      isCollapsbile
      collapsible={!isProcessing}
      expandAllChecks={!isProcessing && checks.expandAllChecks}
      panelProps={{
        style: {
          filter:
            "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
        }
      }}
    >
      <AutoTable
        data={addresses}
        headers={{
          address: "Address",
          city: "City",
          province_state: "Province/State",
          postal_code: "Postal Code"
        }}
      />
    </PanelCollapsible>
  );
};

CanadaWorkerAddresses.propTypes = {
  worker: PropTypes.object
};
