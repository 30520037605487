import amplitude from "amplitude-js";
import { Setter } from "turn-shared";
import { observable, action, decorate } from "mobx";
import env from "@mars/heroku-js-runtime-env";
import _ from "lodash";

class PartnerEvents extends Setter {
  deviceID = "";
  _instance = {};

  init = () => {
    amplitude
      .getInstance("partner_events")
      .init(
        env().REACT_APP_AMPLITUDE_PARTNER_EVENTS_KEY,
        null,
        null,
        ({ options: { deviceId } }) => this.setDeviceID(deviceId)
      );
    this._instance = amplitude.getInstance("partner_events");
  };

  logEvent = (eventType, eventProperties, callback) => {
    if (env().REACT_APP_AMPLITUDE_PARTNER_EVENTS_DISABLE === "true") return;
    if (this._instance && this._instance.logEvent) {
      return this._instance.logEvent(
        eventType,
        eventProperties,
        (status, message) => {
          if (_.isFunction(callback)) callback(status, message);
        }
      );
    }
    return null;
  };

  debounceLogEvent = _.debounce((eventType, eventPropeties, callback) => {
    this.logEvent(eventType, eventPropeties, callback);
  }, 500);

  setDeviceID = this.setStringFactory("deviceID", "");

  identify = (data = {}) => {
    const { partnerName, partnerId } = data;
    const identify = new amplitude.Identify();

    if (env().REACT_APP_AMPLITUDE_PARTNER_EVENTS_DISABLE === "true") return;
    if (!_.isNil(partnerName)) identify.set("partner_name", partnerName);
    if (!_.isNil(partnerId)) identify.set("partner_id", partnerId);
    this._instance.identify(identify);
  };

  setUserId = userId => {
    if (!_.isString(userId) || _.isEmpty(userId)) return;
    this._instance.setUserId(userId);
  };
}

decorate(PartnerEvents, {
  deviceID: observable,
  _instance: observable,

  setDeviceID: action,
  init: action,
  logEvent: action,
  debounceLogEvent: action,
  setUserId: action
});

const partnerEvents = new PartnerEvents();
export default partnerEvents;
