import React from "react";
import { Icon } from "@material-ui/core";
import Title from "../../Title";
import { LandingPageStyles as styles } from "../../../styles";
import { FormattedMessage } from "react-intl";
import messages from "../messages";

export default function WelcomeMessage({ isCanadaCheck, details }) {
  const editText = (
    <FormattedMessage
      {...messages.editText}
      values={{
        PartnerName: <strong>{details.partnerName}</strong>
      }}
    />
  );

  const piiRetryEditText = (
    <FormattedMessage
      {...messages.piiRetryEditText}
      values={{
        PartnerName: <strong>{details.partnerName}</strong>,
        SupportLink: <a href="mailto:support@turn.ai">support@turn.ai</a>,
        br: <br />
      }}
    />
  );

  return (
    <>
      <Title id="informationStepTitle" align="left" big>
        Welcome&nbsp;
        {isCanadaCheck && (
          <Icon
            className={"fab fa-canadian-maple-leaf"}
            style={{
              color: "red",
              fontSize: 42,
              verticalAlign: "middle"
            }}
          />
        )}
      </Title>
      <p id="informationLegalDisclosure" style={styles.text}>
        {details.piiRetryPage ? piiRetryEditText : editText}
      </p>

      {!details.piiRetryPage && !isCanadaCheck && (
        <p
          style={{
            ...styles.text
          }}
        ></p>
      )}
    </>
  );
}
