import React from "react";
import PropTypes from "prop-types";
import Drawer from "material-ui/Drawer";
import { List } from "material-ui/List";
import Subheader from "material-ui/Subheader";
import { FormattedMessage } from "react-intl";
import messages from "../../containers/WorkersPage/messages";
import style from "./feature-drawer-styles";
import FeatureCard from "../../components/FeatureCard";
import NoFeaturesYet from "../../components/NoFeaturesYet";
import newFeaturesModel from "../../models/new-features";
import _ from "lodash";

const propTypes = {
  style: PropTypes.object,
  newFeatures: PropTypes.object
};

class FeatureDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      featureCards: [],
      open: true
    };
  }

  componentDidMount() {
    this.renderFeatures(newFeaturesModel.featuresArrayReversed);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.onFeaturesToggle, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onFeaturesToggle, false);
  }

  renderFeatures = newFeatures => {
    if (!_.isEmpty(newFeatures) && _.isObject(newFeatures)) {
      {
        newFeatures.map(feature => {
          let dTime = new Date(feature.dateTime || null);
          this.setState(prevState => {
            return {
              featureCards: [
                ...prevState.featureCards,
                <FeatureCard
                  key={feature.dateTime}
                  feature={feature}
                  dTime={dTime}
                />
              ]
            };
          });
        });
      }
    } else if (_.isEmpty(newFeatures)) {
      this.setState({
        featureCards: <NoFeaturesYet />
      });
    }
  };

  onFeaturesToggle = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    newFeaturesModel.setFeaturesSelected(
      !newFeaturesModel.featuresSelectedComputed
    );
    newFeaturesModel.setAreThereNewFeatures(false);
    newFeaturesModel.setDisableButton(false);
  };

  render() {
    return (
      <div ref={node => (this.node = node)}>
        <Drawer openSecondary={true} containerStyle={style.drawerContainer}>
          <List>
            <Subheader style={style.whatsNewTitle}>
              <FormattedMessage {...messages.whatsNew} />
            </Subheader>
            {this.state.featureCards}
          </List>
        </Drawer>
      </div>
    );
  }
}

FeatureDrawer.propTypes = propTypes;
export default FeatureDrawer;
