import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import { CandidateReportStyles as styles } from "../../styles";

import DataItem from "./DataItem";
import messages from "./messages_watchlist";
import generalMessages from "./messages";
import Clearfix from "./Clearfix";
import Divider from "./Divider";
import Check from "./Check";

const propTypes = {
  watchlist: PropTypes.array.isRequired,
  reportDate: PropTypes.string.isRequired,
  pendingMVRReview: PropTypes.bool.isRequired,
  pendingAgeReview: PropTypes.bool.isRequired
};

const nullPlaceholder = "";

class WatchlistModel {
  constructor(r) {
    this.alias = r.alias_name;
    this.citizenship = r.citizenship;
    this.country = r.country;
    this.dob = r.date_of_birth;
    this.eyes = r.eyes;
    this.fullName = r.full_name;
    this.hair = r.hair;
    this.height = r.height;
    this.listingDate = r.listing_date;
    this.marks = r.marks;
    this.name = r.name;
    this.offense = r.offense;
    this.placeBirth = r.place_of_birth;
    this.remarks = r.remarks;
    this.score = r.score;
    this.sex = r.sex;
    this.source = r.source;
    this.address = r.suspect_address_line_1;
    this.city = r.suspect_city;
    this.county = r.suspect_county;
    this.state = r.suspect_state;
    this.zip = r.suspect_zip;
    this.url = r.page_url;
    this.wantedBy = r.wanted_by;
    this.weight = r.weight;

    // remove any keys that are empty, will not be painted to screen
    Object.entries(this).forEach(tuple => {
      const [key, value] = tuple;
      if (!value) delete this[key];
    });
  }
}

class Watchlist extends PureComponent {
  constructor(props) {
    super(props);
    this.watchlist = false;
    if (this.props.watchlist && this.props.watchlist.length > 0) {
      this.watchlist = this.props.watchlist.map(record => {
        const flattened = record;
        delete flattened.name;
        delete flattened.address;
        return new WatchlistModel(record);
      });
    }
    this.renderWatchlist = !!this.props.watchlist;
  }
  componentDidMount() {}
  render() {
    const defaultCheckNameId = "detailCheckName";
    const defaultCheckStatusId = "detailCheckStatus";
    const defaultCheckSectionId = "detailCheckSection";

    if (!this.renderWatchlist) return null;
    if (this.props.pendingMVRReview && this.renderWatchlist) {
      return (
        <div id={defaultCheckSectionId} style={styles.section}>
          <Check
            check="Watchlist"
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...this.props}
          />
          <p style={styles.pendingMVR}>
            <FormattedMessage {...generalMessages.pendingMVR} />
          </p>
          <Divider height="1px" />
        </div>
      );
    }
    if (this.props.pendingAgeReview && this.renderWatchlist) {
      return (
        <div id={defaultCheckSectionId} style={styles.section}>
          <Check
            check="Watchlist"
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...this.props}
          />
          <p id="pendingAgeMessage" style={styles.pendingMVR}>
            <FormattedMessage {...generalMessages.pendingAge} />
          </p>
          <Divider height="1px" />
        </div>
      );
    }
    if (!this.watchlist) {
      return (
        <div id={defaultCheckSectionId} style={styles.section}>
          <Check
            check="Watchlist"
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...this.props}
          />
          <Divider height="1px" />
        </div>
      );
    }
    return (
      <div id={defaultCheckSectionId} style={styles.section}>
        <Check
          check="Watchlist"
          noDivider
          containerStyle={styles.checks.titleContainer}
          nameId={defaultCheckNameId}
          statusId={defaultCheckStatusId}
          {...this.props}
        />
        <Divider height="1px" />
        {this.watchlist.map((record, key) => (
          <div style={styles.sectionContent} key={key}>
            {Object.entries(record).map(tuple => {
              const [key, value] = tuple;
              return (
                <DataItem
                  width="25%"
                  widthMD="50%"
                  widthSM={this.props.isPDFReport ? "25%" : "100%"}
                  label={messages[key]}
                  text={value}
                  key={key}
                />
              );
            })}
            <Divider checked={`Checked on ${this.props.reportDate}`} />
            <Clearfix />
          </div>
        ))}
      </div>
    );
  }
}

Watchlist.propTypes = propTypes;

export { Watchlist, WatchlistModel, nullPlaceholder };
