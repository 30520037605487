import React from "react";
import { Button as MaterialButton, withStyles } from "@material-ui/core";

import * as colors from "../../styles/constants";

const styles = ({ color, justifyContent }) => ({
  root: {
    fontWeight: "bold",
    boxShadow: "none",
    borderRadius: 25
  },
  containedSecondary: {
    color
  },
  sizeLarge: {
    padding: "13px 24px",
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",

    "& > span:first-child": {
      justifyContent
    }
  },
  disabled: {
    backgroundColor: `${colors.disabledButtonBackground} !important`,
    color: `${colors.disabledButtonText} !important`
  }
});

export const ButtonComponent = ({ classes, ...props }) => {
  return <MaterialButton classes={classes} {...props} />;
};

export const Button = withStyles(
  styles({ color: "white", justifyContent: "flex-start" })
)(ButtonComponent);

export const ButtonPhotosRequest = withStyles(
  styles({ color: "black", justifyContent: "center" })
)(ButtonComponent);
