import env from "@mars/heroku-js-runtime-env";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

// router constants
export const singleParamRegex = /:[a-z_]*/;
export const consent__email_id__report = "/consent/:email_id/report";

// on demand crim checks
export const ON_DEMAND_CRIM = "ON_DEMAND_CRIM";

// on demand mvr checks
export const ON_DEMAND_MVR = "ON_DEMAND_MVR";

// worker profile constants
export const APPROVED = "approved";
export const ATTENTION = "attention";
export const REJECTED = "rejected";
export const CONSENT = "consent";
export const PROCESSING = "processing";
export const WITHDRAWN = "withdrawn";
export const PENDING = "consider";
export const FIRST_NOTICE = "first_notice";
export const SECOND_NOTICE = "second_notice";
export const REVIEW = "review";
export const CLEAR = "clear";
export const READY = "ready";
export const HIT = "hit";
export const ERROR = "error";
export const REVIEWING = "reviewing";
export const COMPLIANCEREVIEW = "Compliance Review";
export const MVR_MONITORING_ONE_OFF_CHECK = "MVR Monitoring";
export const FULFILLED = "fulfilled";
export const INITIATED = "initiated";

export const CAN_WITHDRAW_STATUS = [
  "consent",
  "review",
  "review__identity",
  "review__qa",
  "review__mvr",
  "review__so",
  "review__cm",
  "pending",
  "pending__first__notice",
  "pending__first_notice",
  "pending__second__notice",
  "pending__second_notice",
  "processing",
  "pending__dispute",
  "pending__resolved"
];

// job constants
export const OPEN = "open";
export const DRAFT = "draft";
export const PAST = "past";
export const CLOSED = "closed";
export const HELD = "held";

// viewport breakpoints
const BREAKPOINTS = createBreakpoints({}); // Material-UI default breakpoint object
export const BREAKPOINT_SM = BREAKPOINTS.values.sm;
export const BREAKPOINT_LG = BREAKPOINTS.values.lg;
export const BREAKPOINT_MD = BREAKPOINTS.values.md;
export const BREAKPOINT_XL = BREAKPOINTS.values.md;

export const SIDEBAR_WIDTH = "220px";
export const SIDEBAR_COLLAPSED_WIDTH = "80px";

// Worker table widths
export const TABLE_DATA_STATUS = "20%";
export const TABLE_DATA_NAME = "20%";
export const TABLE_DATA_CITY = "15%";
export const TABLE_DATA_INVITE = "15%";
export const TABLE_DATA_PACKAGE = "22%";
export const TABLE_DATA_ACTION = "5%";
export const TABLE_WIDTH = "900px";
export const TABLE_MAX_WIDTH = "1200px";
export const TABLE_MIN_WIDTH = "700px";

// Worker checks icon
export const ICON_MIN_WIDTH = "40px";

export const envVariables = [
  "REACT_APP_ENABLE_EMAIL_STATUS",
  "REACT_APP_COHORT",
  "REACT_APP_CREATE_JOB",
  "REACT_APP_SHOW_SSN_STATUS",
  "REACT_APP_ENABLE_CHECK_STATUS",
  "REACT_APP_COHORT_SEND_INVITES",
  "REACT_APP_ENABLE_REPORT_TAB",
  "REACT_APP_ENABLE_WHATS_NEW",
  "REACT_APP_ENABLE_PAYMENT_SETTINGS",
  "REACT_APP_ENABLE_DOWNLOAD_PARTNER_DASHBOARD_REPORT"
];

// navigation constants
export const readyRoutes = ["/recruiter", "/ready"];
export const screenRoutes = ["/workers", "/"];
// READY Constants
export const READY_LOCATIONS = "READY_LOCATIONS";
export const READY_OPENINGS = "READY_OPENINGS";
export const READY_OPENING_NEW = "READY_OPENING_NEW";
export const READY_POSITIONS = "READY_POSITIONS";
export const READY_APPLICANTS = "READY_APPLICANTS";
export const READY_LOCATION_NEW = "READY_LOCATION_NEW";
export const READY_POSITION_NEW = "READY_POSITION_NEW";
export const READY_JOB_APPLICANTS = "READY_JOB_APPLICANTS";

export const reportRoute = "/report";
export const HOME_PAGE = "HOME";
export const WORKER_PAGE = "WORKER";
export const WORKERS_PAGE = "WORKERS";
export const REPORT_PAGE = "REPORT";
export const PLACEMENT_SUBTAB = "PLACEMENT"; //remove
export const WORKERS_SUBTAB = "WORKERS";
export const SETTINGS_PAGE = "SETTINGS";
export const SETTINGS_SUBTAB = "SETTINGS";
export const WORKER = "WORKER";
export const SOURCING_PAGE = "SOURCING"; // remove
export const READY_PAGE = "READY";
//Report Types Constants
export const WORKER_SOURCING_REPORT = "WORKER_SOURCING";
export const DASHBOARD_REPORT = "DASHBOARD_REPORT";

// check names
export const ONE_OFF_CHECKS = "one_off_checks";
export const MVR = "mvr";
export const COUNTY_CHECKS = [
  "last_county",
  "seven_years_county",
  "ten_years_county",
  "automatic_county"
];
export const FEDERAL = "federal";
export const NATIONAL_WATCHLIST = "national_sexoffender_watchlist";

export const COHORT_TEMPLATE_PLACEHOLDER = "{{APPLY_URL}}";

export const LANDING_LOGO_START =
  '<table id="partner_edit_landing_logo_start"></table>';
export const LANDING_LOGO_END =
  '<table id="partner_edit_landing_logo_end"></table>';
export const LANDING_HEADING_START =
  '<table id="partner_edit_landing_heading_start"></table>';
export const LANDING_HEADING_END =
  '<table id="partner_edit_landing_heading_end"></table>';
export const LANDING_DESCRIPTION_START =
  '<table id="partner_edit_landing_description_start"></table>';
export const LANDING_DESCRIPTION_END =
  '<table id="partner_edit_landing_description_end"></table>';
export const LANDING_REDIRECT_BUTTON_START =
  '<table id="partner_edit_landing_redirect_button_start"></table>';
export const LANDING_REDIRECT_BUTTON_END =
  '<table id="partner_edit_landing_redirect_button_end"></table>';
export const LANDING_LOGO_ID = "logo";
export const LANDING_HEADING_ID = "jobDescription";
export const LANDING_DESCRIPTION_ID = "shortDescription";
export const LANDING_BUTTON_TITLE = "buttonTitle";
export const LANDING_BUTTON_URL = "buttonUrl";
export const LANDING_BUTTON_RENDER = "buttonRender";

export const JOB_POST_LOGO_START =
  '<table id="job_post_logo_start" style="display: none;"></table>';
export const JOB_POST_LOGO_END =
  '<table id="job_post_logo_end" style="display: none;"></table>';
export const JOB_POST_TITLE_START =
  '<table id="job_post_title_start" style="display: none;"></table>';
export const JOB_POST_TITLE_END =
  '<table id="job_post_title_end" style="display: none;"></table>';
export const JOB_POST_PARTNER_START =
  '<table id="job_post_partner_start" style="display: none;"></table>';
export const JOB_POST_PARTNER_END =
  '<table id="job_post_partner_end" style="display: none;"></table>';
export const JOB_POST_LOCATION_START =
  '<table id="job_post_location_start" style="display: none;"></table>';
export const JOB_POST_LOCATION_END =
  '<table id="job_post_location_end" style="display: none;"></table>';
export const JOB_POST_MINIMUM_AGE_START =
  '<table id="job_post_minimum_age_start" style="display: none;"></table>';
export const JOB_POST_MINIMUM_AGE_END =
  '<table id="job_post_minimum_age_end" style="display: none;"></table>';
export const JOB_POST_INTERESTS_START =
  '<table id="job_post_interests_start" style="display: none;"></table>';
export const JOB_POST_INTERESTS_END =
  '<table id="job_post_interests_end" style="display: none;"></table>';
export const JOB_POST_TRANSPORTS_START =
  '<table id="job_post_transports_start" style="display: none;"></table>';
export const JOB_POST_TRANSPORTS_END =
  '<table id="job_post_transports_end" style="display: none;"></table>';
export const JOB_POST_PAY_RANGE_START =
  '<table id="job_post_pay_range_start" style="display: none;"></table>';
export const JOB_POST_PAY_RANGE_END =
  '<table id="job_post_pay_range_end" style="display: none;"></table>';
export const JOB_POST_COMPANY_DESCRIPTION_START =
  '<table id="job_post_company_description_start" style="display: none;"></table>';
export const JOB_POST_COMPANY_DESCRIPTION_END =
  '<table id="job_post_company_description_end" style="display: none;"></table>';
export const JOB_POST_DESCRIPTION_START =
  '<table id="job_post_description_start" style="display: none;"></table>';
export const JOB_POST_DESCRIPTION_END =
  '<table id="job_post_description_end" style="display: none;"></table>';
export const JOB_POST_REQUIREMENTS_START =
  '<table id="job_post_requirements_start" style="display: none;"></table>';
export const JOB_POST_REQUIREMENTS_END =
  '<table id="job_post_requirements_end" style="display: none;"></table>';
export const JOB_POST_ONBOARDING_START =
  '<table id="job_post_onboarding_start" style="display: none;"></table>';
export const JOB_POST_ONBOARDING_END =
  '<table id="job_post_onboarding_end" style="display: none;"></table>';
export const JOB_POST_PREFERENCES_CONTAINER_START =
  '<table id="job_post_preferences_container_start" style="display: none;"></table>';
export const JOB_POST_PREFERENCES_CONTAINER_END =
  '<table id="job_post_preferences_container_end" style="display: none;"></table>';
export const JOB_POST_PREFERENCES_LEFT_CONTAINER_START =
  '<table id="job_post_preferences_left_container_start" style="display: none;"></table>';
export const JOB_POST_PREFERENCES_LEFT_CONTAINER_END =
  '<table id="job_post_preferences_left_container_end" style="display: none;"></table>';
export const JOB_POST_PREFERENCES_RIGHT_CONTAINER_START =
  '<table id="job_post_preferences_right_container_start" style="display: none;"></table>';
export const JOB_POST_PREFERENCES_RIGHT_CONTAINER_END =
  '<table id="job_post_preferences_right_container_end" style="display: none;"></table>';
export const JOB_POST_PAY_RANGE_CONTAINER_START =
  '<table id="job_post_pay_range_container_start" style="display: none;"></table>';
export const JOB_POST_PAY_RANGE_CONTAINER_END =
  '<table id="job_post_pay_range_container_end" style="display: none;"></table>';
export const JOB_POST_REQUIREMENTS_CONTAINER_START =
  '<table id="job_post_job_requirements_container_start" style="display: none;"></table>';
export const JOB_POST_REQUIREMENTS_CONTAINER_END =
  '<table id="job_post_job_requirements_container_end" style="display: none;"></table>';
export const JOB_POST_SHIFT_DETAILS_START =
  '<table id="job_post_shift_details_start" style="display: none;"></table>';
export const JOB_POST_SHIFT_DETAILS_END =
  '<table id="job_post_shift_details_end" style="display: none;"></table>';

export const JOB_POST_HERO_URL_ID = "JOB_POST_HERO_URL";
export const JOB_POST_LOGO_ID = "JOB_POST_LOGO";
export const JOB_POST_TITLE_ID = "JOB_POST_TITLE";
export const JOB_POST_OPENING_TITLE_ID = "JOB_POST_OPENING_TITLE";
export const JOB_POST_PARTNER_ID = "JOB_POST_PARTNER";
export const JOB_POST_LOCATION_ID = "JOB_POST_LOCATION";
export const JOB_POST_SELECT_LOCATION_ID = "JOB_POST_LOCATION_ID";
export const JOB_POST_SELECT_POSITION_ID = "JOB_POST_POSITION_ID";
export const JOB_POST_MIMIMUM_AGE_ID = "JOB_POST_MIMIMUM_AGE";
export const JOB_POST_INTERESTS_ID = "JOB_POST_INTERESTS";
export const JOB_POST_TRANSPORTS_ID = "JOB_POST_TRANSPORTS";
export const JOB_POST_PAY_RANGE_ID = "JOB_POST_PAY_RANGE";
export const JOB_POST_SIGN_UP_BONUS_ID = "JOB_POST_SIGN_UP_BONUS";
export const JOB_POST_HIRE_COUNT_ID = "JOB_POST_HIRE_COUNT";
export const JOB_POST_COMPANY_DESCRIPTION_ID = "JOB_POST_COMPANY_DESCRIPTION";
export const JOB_POST_DESCRIPTION_ID = "JOB_POST_DESCRIPTION";
export const JOB_POST_REQUIREMENTS_ID = "JOB_POST_REQUIREMENTS";
export const JOB_POST_ONBOARDING_ID = "JOB_POST_ONBOARDING";
export const JOB_POST_ONBOARDING_URL_ID = "JOB_POST_ONBOARDING_URL";
export const JOB_POST_WORKING_HOUR_ID = "JOB_POST_WORKING_HOUR_ID";
export const JOB_POST_PACKAGE_ID = "JOB_POST_PACKAGE";
export const JOB_POST_QUESTIOINS_ID = "JOB_POST_QUESTIONS";
export const JOB_POST_NEW_ITEM_ID = "new_item";
export const JOB_POST_BORDER_RADIUS = "8px";
export const JOB_POST_CANDIDATE_NOTIFICATION_EMAIL = "email";
export const JOB_POST_CANDIDATE_NOTIFICATION_DASHBOARD = "dashboard";
export const QUESTIONS_TYPES_W_OPTIONS = [
  "select",
  "single_choice",
  "multiple_choice"
];

// Settings constants
export const TEAM_MEMBERS = env().REACT_APP_TEAM_MEMBERS;
export const PRIMARY_CONTACT = env().REACT_APP_PRIMARY_CONTACT;
export const WEBHOOK_URL = env().REACT_APP_WEBHOOK_URL;
export const PACKAGES = env().REACT_APP_PACKAGES;
export const FOUNTAIN_INTEGRATION = env().REACT_APP_FOUNTAIN_INTEGRATION;
export const LEVER_INTEGRATION = env().REACT_APP_LEVER_INTEGRATION;
export const PAYMENT_METHOD = env().REACT_APP_PAYMENT_METHOD;
export const DEFAULTS_SECTION = env().REACT_APP_DEFAULTS;
export const CURRENT_INVOICE = env().REACT_APP_CURRENT_INVOICE;

export const TEAMS_SECTION = [TEAM_MEMBERS, PRIMARY_CONTACT];
export const INTEGRATIONS_SECTION = [
  WEBHOOK_URL,
  FOUNTAIN_INTEGRATION,
  LEVER_INTEGRATION
];
export const PACKAGES_AND_PAYMENTS = [PACKAGES, PAYMENT_METHOD];

export const SOURCING_PAYMENT_BANK = "bank";
export const SOURCING_PAYMENT_CARD = "card";

// Sourcing Sign Up
export const SUBSCRIPTIONS_SECTION = "subscriptionSection";
export const PAYMENT_SECTION = "paymentSection";
export const COMPLETION_SECTION = "completionSection";

// url params saved to localStorage if user redirected to auth0 - this is the prefix for localstorage keys
export const PRE_OAUTH_PARAM_PREFIX = "PRE_OAUTH_PARAM_";

// candidate details constants
export const GENDERS = {
  Male: "male",
  Female: "female",
  "Non-binary": "non-binary"
};

// constants related to the nginx partner dashboard integration
export const pdLocalstorageItemName = "pd_tried_redirect";
export const rdLocalstorageItemName = "rd_tried_redirect";

// ecom partners
export const BILLING_TYPE_ECOMMERCE = "ecommerce";
export const ECOMMERCE_TYPE_JOB = "job";
export const ECOMMERCE_TYPE_READY = "ready";
export const ECOMMERCE_TYPE_SCREEN = "screening";
export const ECOMMERCE_TYPE_SCREEN_READY = "screening-ready";

// SSN Trace
export const SSN_TRACE_MULTIPLE_ERROR =
  "Oops! Our system was having some issues confirming your identity. Not to worry. After you complete this form, we will ask you to send some documents to our support team. If you agree, please click Next to continue.";

export const SSN_MESSAGE_ERROR =
  "Unable to match the submitted SSN with information from public records";

// Candidate available profile status
export const PROFILE_STATUS = {
  approved: "approved",
  consent: "consent",
  emailed: "emailed",
  initiated: "initiated",
  pending: "pending",
  pendingDispute: "pending__dispute",
  pendingFirstNotice: "pending__first_notice",
  pendingResolved: "pending__resolved",
  pendingSecondNotice: "pending__second_notice",
  processing: "processing",
  rejected: "rejected",
  review: "review",
  reviewCM: "review__cm",
  reviewIdentity: "review__identity",
  reviewMVR: "review__mvr",
  reviewQA: "review__qa",
  reviewSO: "review__so",
  withdrawn: "withdrawn"
};

// Drug test status from I3Screen
export const DRUG_TEST_STATUS = {
  affidavitReceived: "Affidavit Received",
  closed: "Closed",
  refusalToTest: "Refusal to Test",
  collectionCancelled: "Collection Cancelled",
  collectionCompleted: "Collection Completed",
  collectionStarted: "Collection Started",
  donorArrivedCollectionSite: "Donor Arrived at Collection Site",
  expiredNotScheduled: "Expired, Not Scheduled",
  expiredNoShow: "Expired, No Show",
  incompleteResult: "Incomplete Result",
  initiated: "Initiated",
  inRecovery: "In Recovery",
  inTestingLab: "In Testing at Lab",
  labTestingComplete: "Lab Testing Complete",
  onsiteTestingStarted: "Onsite Testing Started",
  onsiteTestingSuspended: "Onsite Testing - Suspended",
  orderedNotScheduled: "Ordered, Not Scheduled",
  poctNotCollected: "POCT, Not Collected",
  receivedAtLab: "Received at Lab",
  registrationExpired: "Registration Expired",
  scheduled: "Scheduled",
  schedulingError: "Scheduling Error",
  shyBladderInProcess: "Shy Bladder in Process",
  specimenSentToLab: "Specimen Sent to Lab"
};

export const DRUG_TEST_PENDING_SCHEDULING_STATUS = [
  DRUG_TEST_STATUS.initiated,
  DRUG_TEST_STATUS.orderedNotScheduled
];
