import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.containers.Disclosure.title",
    defaultMessage: "BACKGROUND CHECK DISCLOSURE AND AUTHORIZATION"
  },
  disclosure_title: {
    id: "app.containers.Disclosure.disclosure_title",
    defaultMessage: "DISCLOSURE REGARDING BACKGROUND INVESTIGATION"
  },
  disclosure_first_paragraph: {
    id: "app.containers.Disclosure.disclosure_first_paragraph",
    defaultMessage:
      "To maintain the security of our partners, consumers and others, Turn Technologies, Inc. (“Turn,” “our,” “we,” or “us”) may collect your background information and prepare a consumer report or investigative consumer report (commonly known as “background reports”). We may prepare the background report any time after we receive your authorization or while you are registered with Turn, as allowed by law.  The report may contain details about your character, reputation, personality, living situation, and credit.  We may collect data, including but not limited to, to check: social security numbers; address history; credit history; criminal history; driving history; accident history; cellular carrier data; social media history; workers’ compensation claims; bankruptcy filings; educational history; employment; personal and professional references; professional licensing and certification; drug or alcohol history in violation of law or company policy; and other information.  We may get this information from private and public record sources, including but not limited to, as appropriate: government agencies and courthouses; educational institutions; former employers; and, for investigative consumer reports, personal interviews with such sources as neighbors, friends, former employers, and associates; and other information sources.  You have the right to request that we disclose the nature and scope of any investigative consumer report."
  },
  disclosure_second_paragraph: {
    id: "app.containers.Disclosure.disclosure_second_paragraph",
    defaultMessage:
      "For any matters relating to you background report, you may visit us at our offices are located at 20 West Kinzie Street, 17th Floor, Chicago, IL 60654, or you can reach us by telephone at 1.888.499.TURN or online at "
  },
  authorization_title: {
    id: "app.containers.Disclosure.authorization_title",
    defaultMessage: "AUTHORIZATION OF BACKGROUND INVESTIGATION"
  },
  authorization_first_paragraph: {
    id: "app.containers.Disclosure.authorization_first_paragraph",
    defaultMessage:
      "I have carefully read and understand this Disclosure and Authorization and the SUMMARY OF RIGHTS UNDER THE FAIR CREDIT REPORTING ACT and state-specific notices. I CONSENT to Turn preparing background reports on me to make a determination as to my membership eligibility in Turn or for other lawful purposes. I understand that, while I am a Turn member, my consent will apply, and Turn may, as allowed by law, obtain additional background reports on me, without asking for another authorization."
  },
  authorization_second_paragraph: {
    id: "app.containers.Disclosure.authorization_second_paragraph",
    defaultMessage:
      "I understand that information contained in my registration or that I otherwise disclose before or during my Turn membership may be used for the purpose of obtaining and evaluating background reports on me. I also understand that I should not construe anything herein as an offer to contract for my services."
  },
  authorization_third_paragraph: {
    id: "app.containers.Disclosure.authorization_third_paragraph",
    defaultMessage:
      "I hereby AUTHORIZE all of the following persons or entities, without limitation, to disclose information about me to Turn and its agents: law enforcement and all other federal, state, and local agencies, educational institutions (including public and private schools, colleges, and universities), testing agencies, information service bureaus, credit bureaus, record or data repositories, courts (federal, state, and local), motor vehicle records agencies, my past or present employers, the military, and all other individuals and sources with any information about or concerning me. The information that the entities can disclose to Turn and its agents includes, for example, information concerning my work and earnings history, education, credit history, motor vehicle history, criminal history, military service, professional credentials, and licenses."
  }
});
