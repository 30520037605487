import moment from "moment";
import _ from "lodash";

/**
 * Attempt to parse a string input into a `moment` object.
 *
 * @param {moment.MomentInput} input Input date to parse
 * @param {Array} formats (Optional) Array of string formats to try to parse the input into
 * @returns {moment|null}
 */
export const momentParse = (input, formats) => {
  formats = _.isArray(formats) ? formats : ["MM-DD-YYYY", "YYYY-MM-DD"];

  for (const format of formats) {
    const formatted = moment(input, format, true);

    if (formatted.isValid()) {
      return formatted;
    }
  }

  return null;
};

/**
 * Attempt to parse a string input and re-format it into provided format.
 *
 * @param {moment.MomentInput} input Input date to parse
 * @param {string} outputFormat (Optional) Format to output the input, defaults to "YYYY-MM-DD"
 * @param {Array} formats (Optional) Array of string formats to try to parse the input into
 * @return {string|null}
 */
export const reformatDate = (input, outputFormat, inputFormats) => {
  outputFormat = outputFormat || "YYYY-MM-DD";

  const formatted = momentParse(input, inputFormats);

  if (formatted) {
    return formatted.format(outputFormat);
  } else {
    return null;
  }
};

export const momentParseUTC = (input, formats) => {
  formats = _.isArray(formats) ? formats : ["MM-DD-YYYY", "YYYY-MM-DD"];

  for (const format of formats) {
    const formatted = moment.utc(input, format, true);

    if (formatted.isValid()) {
      return formatted;
    }
  }

  return null;
};

export const reformatDateUTC = (input, outputFormat, inputFormats) => {
  outputFormat = outputFormat || "YYYY-MM-DD";

  const formatted = momentParseUTC(input, inputFormats);

  if (formatted) {
    return formatted.format(outputFormat);
  } else {
    return null;
  }
};
