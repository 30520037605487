const handleNotFound = (router, route) => {
  if (!router) return;
  if (!route) {
    router.goBack();
    return;
  }

  window.location.href = route;
};
export default handleNotFound;
