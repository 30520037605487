import { observable, action, computed, decorate, extendObservable } from "mobx";

import _ from "lodash";
import { formatPhone, legalReplace, toBoolean, validators } from "turn-shared";
import { turnAPI } from "../services";
import CustomField from "./custom-field";
import env from "@mars/heroku-js-runtime-env";

const {
  containsAtSymbol,
  containsNumber,
  validateAddress,
  validateCanadianZipcode,
  validateCustomInput,
  validateEmail,
  validateName,
  validatePhone
} = validators;

class LandinPageCanadaModel {
  firstName = "";
  lastName = "";
  middleName = "";
  email = "";
  hasMiddleName = true;
  hasMiddleNameTimestamp = undefined;
  phone = "";
  gender = null;
  birthCountry = "CA";
  birthProvinceState = "";
  birthCity = "";
  zipcode = "00000";
  addressCountry = "Canada";
  addressProvince = "";
  addressCity = "";
  addressStreet = "";
  addressZipcode = "";
  referenceId = "";
  doChecks = true;
  name = "";

  certify = false;
  certifyTimestamp = undefined;
  certifyTos = false;
  certifyTosTimestamp = undefined;
  certifySms = false;
  certifySmsTimestamp = undefined;
  releaseCertify = false;
  releaseCertifyTimestamp = undefined;

  dayOfBirth = "";
  monthOfBirth = "";
  yearOfBirth = "";
  requiredFields = [];
  error = "";
  errorState = {};
  warningState = {};
  convictionErrors = {};
  step = "0";
  disclosure = "";
  rights = "";
  terms = "";
  authorization = "";
  autofocus = "firstName";

  charges = {};
  convicted = false;
  convictions = [];

  oneOffChecks = [];

  nicknames = [];
  agreeDisclosure = false;
  agreeDisclosureTimestamp = undefined;
  agreeRights = false;
  agreeRightsTimestamp = undefined;
  agreeTerms = false;
  agreeTermsTimestamp = undefined;
  agreeAuthorization = false;
  agreeAuthorizationTimestamp = undefined;
  agreeSigning = false;
  signed = false;
  signedTimestamp = undefined;
  loading = false;
  displayIFrame = "";
  partnerName = "";
  bypassDupe = null;

  touchedFirstName = false;
  touchedLastName = false;
  touchedMiddleName = false;
  touchedEmail = false;
  touchedPhone = false;
  touchedBirthCountry = false;
  touchedBirthProvinceState = false;
  touchedBirthCity = false;
  touchedCanadianAddresses = false;
  touchedAddressCountry = false;
  touchedAddressProvince = false;
  touchedAddressCity = false;
  touchedAddressStreet = false;
  touchedAddressZipcode = false;

  freezedEmail = false;
  freezedAddressCountry = true;

  showManualForm = false;
  showAutomaticFill = false;
  piiRetryPage = 0;

  captchaToken = "";
  captchaReset = "";

  constructor(firebaseDB) {
    this.database = firebaseDB;
  }

  get full_return_data() {
    // Pre-process `convictions` into backend expected shape
    const charges = _.flatMap(this.charges);
    const convictions = _.map(
      this.convicted ? this.convictions : [],
      conviction => {
        // "Expand" the `<DateInput />` value into a valid date string
        const { year, month, day } = conviction.date_of_sentence;
        const date_of_sentence = `${year}-${month}-${day}`;

        // Fill `description` from the selected `code`
        const charge = _.find(
          charges,
          item => item.code === conviction.offense
        );
        const description = _.get(charge, "description");

        return {
          ...conviction,
          date_of_sentence,
          description
        };
      }
    );

    const one_off_checks = {};

    this.oneOffChecks.forEach(({ name, custom_fields }) => {
      const key = _.camelCase(name);
      one_off_checks[name] = {};
      custom_fields.forEach(({ input_name }) => {
        const inputKey = _.camelCase(input_name);
        one_off_checks[name][input_name] = this[key][inputKey].value;
      });
    });

    return {
      first_name: this.firstName,
      last_name: this.lastName,
      middle_name: this.middleName,
      gender: this.gender ? this.gender.substring(0, 1).toUpperCase() : "",
      email: this.email,
      has_middle_name: this.hasMiddleName,
      phone: this.phone.replace(/\D/g, ""),
      date_of_birth: this.dateOfBirth,
      zipcode: this.zipcode,
      birth_country: this.birthCountry,
      birth_city: this.birthCity,
      birth_province_state: this.birthProvinceState,
      canadian_addresses: [
        {
          address: this.addressStreet,
          city: this.addressCity,
          province_state: this.addressProvince,
          country: this.addressCountry,
          zipcode: this.addressZipcode
        }
      ],
      callback_url: "http://www.google.com",
      consent_document: this.signature_image,
      convictions,
      custom_checks: one_off_checks
    };
  }

  get fieldAssertions() {
    return {
      first_name: this.validFirstName,
      last_name: this.validLastName,
      middle_name: this.validMiddleName,
      email: validateEmail(this.email),
      has_middle_name: true,
      phone_number: this.validPhone,
      date_of_birth: this.dayOfBirth && this.monthOfBirth && this.yearOfBirth,
      gender: !!this.gender,
      birth_country: this.validBirthCountry,
      birth_province_state: this.validBirthProvinceState,
      birth_city: this.validBirthCity,
      address_country: this.validAddressCountry,
      address_province: this.validAddressProvince,
      address_city: this.validAddressCity,
      address_street: this.validAddressStreet,
      address_zipcode: this.validAddressZipcode
    };
  }

  get customFieldsAssertions() {
    const fields = {};
    this.oneOffChecks.forEach(({ name, custom_fields }) => {
      const key = _.camelCase(name);
      fields[name] = {};
      custom_fields.forEach(({ input_name }) => {
        const inputKey = _.camelCase(input_name);
        fields[name][input_name] = validateCustomInput(
          this[key][inputKey].value
        );
      });
    });
    return fields;
  }

  get informationReady() {
    if (!this.certify) return false;

    const errors = _(this.requiredFields)
      .filter(field => field != "convictions")
      .filter(field => !this.fieldAssertions[field])
      .value();

    const oneOffChecksErrors = [];
    this.oneOffChecks.forEach(({ name, custom_fields }) => {
      custom_fields.forEach(({ input_name }) => {
        if (!this.customFieldsAssertions[name][input_name]) {
          oneOffChecksErrors.push(input_name);
        }
      });
    });

    return errors.length === 0 && oneOffChecksErrors.length === 0;
  }

  get validFirstName() {
    return validateName(this.firstName);
  }

  get invalidFirstName() {
    return this.touchedFirstName && !this.validFirstName;
  }

  get validLastName() {
    return validateName(this.lastName);
  }

  get invalidLastName() {
    return this.touchedLastName && !this.validLastName;
  }

  get validMiddleName() {
    if (this.hasMiddleName) return true;
    return validateName(this.middleName);
  }

  get invalidMiddleName() {
    return !this.touchedMiddleName
      ? false
      : this.hasMiddleName
      ? !this.validMiddleName
      : false;
  }

  get validPhone() {
    return validatePhone(this.phone);
  }

  get invalidPhone() {
    return this.touchedPhone && !this.validPhone;
  }

  get validBirthCountry() {
    return validateName(this.birthCountry);
  }

  get invalidBirthCountry() {
    return this.touchedBirthCountry && !this.validBirthCountry;
  }

  get validBirthProvinceState() {
    return validateName(this.birthProvinceState);
  }

  get invalidBirthProvinceState() {
    return this.touchedBirthProvinceState && !this.validBirthProvinceState;
  }

  get validBirthCity() {
    return validateName(this.birthCity);
  }

  get invalidBirthCity() {
    return this.touchedBirthCity && !this.validBirthCity;
  }

  get validAddressCountry() {
    return validateAddress(this.addressCountry);
  }

  get invalidAddressCountry() {
    return this.touchedAddressCountry && !this.validAddressCountry;
  }

  get validAddressProvince() {
    return validateAddress(this.addressProvince);
  }

  get invalidAddressProvince() {
    return this.touchedAddressProvince && !this.validAddressProvince;
  }

  get validAddressCity() {
    return validateAddress(this.addressCity);
  }

  get invalidAddressCity() {
    return this.touchedAddressCity && !this.validAddressCity;
  }

  get validAddressStreet() {
    return validateAddress(this.addressStreet);
  }

  get invalidAddressStreet() {
    return this.touchedAddressStreet && !this.validAddressStreet;
  }

  get validAddressZipcode() {
    return validateCanadianZipcode(this.addressZipcode);
  }

  get invalidAddressZipcode() {
    return this.touchedAddressZipcode && !this.validAddressZipcode;
  }

  get showConfirmationScreen() {
    return false;
  }

  get dateOfBirth() {
    if (!this.monthOfBirth && !this.dayOfBirth && !this.yearOfBirth) return "";
    return `${this.monthOfBirth}/${this.dayOfBirth}/${this.yearOfBirth}`;
  }

  get ready() {
    if (!this.informationReady) return false;
    if (!this.agreeRights) return false;
    if (this.showConfirmationScreen && !this.agreeAuthorization) return false;
    if (!this.agreeTerms) return false;
    if (!this.agreeDisclosure) return false;
    if (this.isPAMVR && !this.informationReleaseReady) return false;
    if (!this.agreeSigning) return false;
    return true;
  }

  get data() {
    const data = {};
    if (this.bypassDupe) data.bypass_dupe = this.bypassDupe;
    this.requiredFields.push("callback_url");
    this.requiredFields.push("consent_document");
    this.requiredFields.push("date_of_birth");
    this.requiredFields.push("phone");
    this.requiredFields.push("zipcode");
    this.requiredFields.push("canadian_addresses");
    this.requiredFields.push("custom_checks");
    this.requiredFields.forEach(
      field => (data[field] = this.full_return_data[field])
    );
    data.partner = this.partnerName;
    if (env().REACT_APP_FCRA_PARTNER_COMPLIANCE_ENFORCE_METADATA) {
      data.timestamps = {
        welcome: [this.certifyTimestamp],
        summary_of_rights: [this.agreeRightsTimestamp],
        disclosure: [this.agreeDisclosureTimestamp],
        authorization_of_background_investigation: [
          this.agreeAuthorizationTimestamp
        ],
        signature: [this.signedTimestamp]
      };
    }
    return data;
  }

  get formData() {
    const data = {};
    if (this.bypassDupe) data.bypass_dupe = this.bypassDupe;
    const fieldToData = [...this.requiredFields];
    fieldToData.push("callback_url");
    fieldToData.push("consent_document");
    fieldToData.push("date_of_birth");
    fieldToData.push("phone");
    fieldToData.push("zipcode");
    fieldToData.push("canadian_addresses");
    fieldToData.push("custom_checks");
    fieldToData.forEach(field => (data[field] = this.full_return_data[field]));
    data.partner = this.partnerName;
    if (env().REACT_APP_FCRA_PARTNER_COMPLIANCE_ENFORCE_METADATA) {
      data.timestamps = {
        welcome: [this.certifyTimestamp],
        summary_of_rights: [this.agreeRightsTimestamp],
        disclosure: [this.agreeDisclosureTimestamp],
        authorization_of_background_investigation: [
          this.agreeAuthorizationTimestamp
        ],
        signature: [this.signedTimestamp]
      };
    }
    return data;
  }

  validatePhone = () => {
    const error = this.invalidPhone
      ? "must be 10 digits or 11 (1 prefixed)"
      : undefined;
    this.setErrorState("phone", error);
  };

  touchFirstName = () => {
    if (!this.touchedFirstName) {
      this.touchedFirstName = true;
      this.validateFirstName();
    }
  };
  touchLastName = () => {
    if (!this.touchedLastName) {
      this.touchedLastName = true;
      this.validateLastName();
    }
  };
  touchMiddleName = () => {
    if (!this.touchedMiddleName) {
      this.touchedMiddleName = true;
      this.validateMiddleName();
    }
  };
  touchEmail = () => {
    if (!this.touchedEmail) {
      this.touchedEmail = true;
      this.validateEmail();
    }
  };
  touchPhone = () => {
    if (!this.touchedPhone) {
      this.touchedPhone = true;
      this.validatePhone();
    }
  };
  touchBirthCountry = () => {
    if (!this.touchedBirthCountry) {
      this.touchedBirthCountry = true;
      this.validateBirthCountry();
    }
  };
  touchBirthProvinceState = () => {
    if (!this.touchedBirthProvinceState) {
      this.touchedBirthProvinceState = true;
      this.validateBirthProvinceState();
    }
  };
  touchBirthCity = () => {
    if (!this.touchedBirthCity) {
      this.touchedBirthCity = true;
      this.validateBirthCity();
    }
  };

  touchAddressCountry = () => {
    if (!this.touchedAddressCountry) {
      this.touchedAddressCountry = true;
      this.validateAddressCountry();
    }
  };
  touchAddressProvince = () => {
    if (!this.touchedAddressProvince) {
      this.touchedAddressProvince = true;
      this.validateAddressProvince();
    }
  };
  touchAddressCity = () => {
    if (!this.touchedAddressCity) {
      this.touchedAddressCity = true;
      this.validateAddressCity();
    }
  };
  touchAddressStreet = () => {
    if (!this.touchedAddressStreet) {
      this.touchedAddressStreet = true;
      this.validateAddressStreet();
    }
  };
  touchAddressZipcode = () => {
    if (!this.touchedAddressZipcode) {
      this.touchedAddressZipcode = true;
      this.validateAddressZipcode();
    }
  };

  freezeEmail = () => {
    if (!this.freezedEmail) {
      this.freezedEmail = true;
    }
  };
  unfreezeEmail = () => {
    if (this.freezedEmail) {
      this.freezedEmail = false;
    }
  };

  get captchaError() {
    return this.error;
  }

  setCaptchaToken = token => {
    if (typeof token !== "string") return;
    this.captchaToken = token;
  };

  setCaptchaReset = resetString => {
    if (typeof resetString !== "string") return;
    this.captchaReset = resetString;
  };

  setRequiredFields = requiredFields => {
    if (!Array.isArray(requiredFields)) return;
    this.requiredFields = [...requiredFields];
  };

  declareOneOffChecks = () => {
    if (_.isEmpty(this.oneOffChecks)) return;
    this.oneOffChecks.forEach(({ name, custom_fields }) => {
      if (!custom_fields) return;
      if (_.isEmpty(custom_fields)) return;
      const key = _.camelCase(name);
      this.declareOneOffCheck(key);
      this.declareCustomFields(key, custom_fields);
    });
  };

  declareOneOffCheck = key => {
    if (this.hasOwnProperty(_.camelCase(key))) return;
    extendObservable(this, { [key]: {} }, { [key]: observable });
  };

  declareCustomFields = (key, customFields) => {
    if (!this.hasOwnProperty(key)) return;
    if (!_.isArray(customFields)) return;
    if (_.isEmpty(customFields)) return;
    customFields.forEach(customField =>
      this.declareCustomField(key, customField)
    );
  };

  declareCustomField = (key, customField) => {
    if (!_.isObject(this[key])) return;
    const model = new CustomField(customField, this.setErrorState);
    const inputKey = _.camelCase(customField.input_name);
    extendObservable(
      this[key],
      {
        [inputKey]: model
      },
      {
        [inputKey]: observable
      }
    );
  };

  setOneOffChecks = oneOffChecks => {
    if (!Array.isArray(oneOffChecks)) return;
    this.oneOffChecks = oneOffChecks;
    this.declareOneOffChecks();
  };

  setMonthOfBirth = date => {
    if (typeof date !== "number" && typeof date !== "string") return;
    const month = typeof date === "number" ? date.toString() : date;
    this.monthOfBirth = month.replace(/\D/g, "").substring(0, 2);
  };

  setDayOfBirth = date => {
    if (typeof date !== "number" && typeof date !== "string") return;
    const day = typeof date === "number" ? date.toString() : date;
    this.dayOfBirth = day.replace(/\D/g, "").substring(0, 2);
  };

  setYearOfBirth = date => {
    if (typeof date !== "number" && typeof date !== "string") return;
    const year = typeof date === "number" ? date.toString() : date;
    this.yearOfBirth = year.replace(/\D/g, "").substring(0, 4);
  };

  setDateOfBirth = dateOfBirth => {
    if (typeof dateOfBirth !== "string") return;
    const date = dateOfBirth.split("/");
    this.setMonthOfBirth(date[0]);
    this.setDayOfBirth(date[1]);
    this.setYearOfBirth(date[2]);
  };

  setFirstName = firstName => {
    if (typeof firstName !== "string") return;
    this.firstName = firstName;
  };

  setLastName = lastName => {
    if (typeof lastName !== "string") return;
    this.lastName = lastName;
  };

  setMiddleName = middleName => {
    if (typeof middleName !== "string") return;
    this.middleName = middleName;
  };

  setEmail = email => {
    if (typeof email !== "string") return;
    this.email = email;
  };

  setAutofocus = input => {
    if (typeof input !== "string") return;
    this.autofocus = input;
  };

  setPartnerName = name => {
    if (typeof name !== "string") return;
    this.partnerName = name;
  };

  setBypass = bool => {
    if (typeof bool !== "boolean") return;
    this.bypassDupe = bool;
  };

  setHasMiddleName = hasMiddleName => {
    if (typeof hasMiddleName !== "boolean") return;
    this.hasMiddleName = hasMiddleName;
  };

  setPhone = phone => {
    if (typeof phone !== "string") return;
    this.phone = formatPhone(phone);
  };

  setGender = gender => {
    if (typeof gender !== "string") return;
    this.gender = gender.toLowerCase();
  };

  setName = name => {
    if (typeof name !== "string") return;
    this.name = name;
  };

  setCertify = certify => {
    if (typeof certify !== "boolean") return;
    this.certify = certify;
    this.certifyTimestamp = new Date().toISOString();
  };

  setCertifyTos = certify => {
    if (typeof certify !== "boolean") return;
    this.certifyTos = certify;
    this.certifyTosTimestamp = new Date().toISOString();
  };

  setCertifySms = certify => {
    if (typeof certify !== "boolean") return;
    this.certifySms = certify;
    this.certifySmsTimestamp = new Date().toISOString();
  };

  setReleaseCertify = releaseCertify => {
    if (typeof releaseCertify !== "boolean") return;
    this.releaseCertify = releaseCertify;
    this.releaseCertifyTimestamp = new Date().toISOString();
  };

  setZipcode = zipcode => {
    if (typeof zipcode !== "number" && typeof zipcode !== "string") return;
    this.zipcode = zipcode;
  };

  setAgreeDisclosure = agreeDisclosure => {
    if (typeof agreeDisclosure !== "boolean") return;
    this.agreeDisclosure = agreeDisclosure;
    this.agreeDisclosureTimestamp = new Date().toISOString();
  };
  setAgreeAuthorization = agreeAuthorization => {
    if (typeof agreeAuthorization !== "boolean") return;
    this.agreeAuthorization = agreeAuthorization;
    this.agreeAuthorizationTimestamp = new Date().toISOString();
  };

  setAgreeRights = agreeRights => {
    if (typeof agreeRights !== "boolean") return;
    this.agreeRights = agreeRights;
    this.agreeRightsTimestamp = new Date().toISOString();
  };

  setAgreeTerms = agreeTerms => {
    if (typeof agreeTerms !== "boolean") return;
    this.agreeTerms = agreeTerms;
  };

  setAgreeSigning = agreeSigning => {
    if (typeof agreeSigning !== "boolean") return;
    this.agreeSigning = agreeSigning;
  };

  setSigned = signed => {
    if (typeof signed !== "boolean") return;
    this.signed = signed;
    this.signedTimestamp = new Date().toISOString();
  };

  setLoading = loading => {
    if (typeof loading !== "boolean") return;
    this.loading = loading;
  };

  setDisplayIFrame = display => {
    if (typeof display !== "string") return;
    this.displayIFrame = display;
  };

  setReferenceId = referenceId => {
    if (typeof referenceId !== "string") return;
    this.referenceId = referenceId;
  };

  setDoChecks = doChecks => {
    if (typeof doChecks !== "boolean") return;
    this.doChecks = doChecks;
  };

  setBirthCountry = birthCountry => {
    if (typeof birthCountry !== "string") return;
    this.birthCountry = birthCountry;
  };

  setBirthProvinceState = birthProvinceState => {
    if (typeof birthProvinceState !== "string") return;
    this.birthProvinceState = birthProvinceState;
  };

  setBirthCity = birthCity => {
    if (typeof birthCity !== "string") return;
    this.birthCity = birthCity;
  };

  setAddressCountry = addressCountry => {
    if (typeof addressCountry !== "string") return;
    this.addressCountry = addressCountry;
  };

  setAddressProvince = addressProvince => {
    if (typeof addressProvince !== "string") return;
    this.addressProvince = addressProvince;
  };

  setAddressCity = addressCity => {
    if (typeof addressCity !== "string") return;
    this.addressCity = addressCity;
  };

  setAddressStreet = addressStreet => {
    if (typeof addressStreet !== "string") return;
    this.addressStreet = addressStreet;
  };

  setAddressZipcode = addressZipcode => {
    if (typeof addressZipcode !== "string") return;
    this.addressZipcode = addressZipcode;
  };

  setError = error => {
    if (typeof error !== "string") return;
    this.error = error;
  };
  setErrorState = (fieldName, error) => {
    this.errorState[fieldName] = error;
  };
  setWarningState = (fieldName, warning) => {
    this.warningState[fieldName] = warning;
  };

  clearError = () => {
    this.error = null;
  };

  setConvictionsErrors = (index, error) => {
    if (!this.convictions || !this.convictions[index]) {
      return;
    }

    this.convictionErrors[index] = error;
  };
  clearConvictionError = index => {
    if (!this.convictions || !this.convictions[index]) {
      return;
    }

    // We remove the item this way because MobX is weird
    this.convictionErrors = _.pickBy(
      this.convictionErrors,
      (_, k) => k !== index
    );
  };
  clearConvictionsErrors = () => {
    this.convictionErrors = {};
  };
  setPIIRetryPage = value => {
    if (typeof value !== "number") return;
    if (value > 0) {
      this.piiRetryPage = value;
      this.setShowAutomaticFill(true);
      this.setShowManualForm(false);
    }
  };

  setStep = step => {
    if (typeof step !== "string") return;
    let skip;
    switch (step) {
      case "0":
        break;
      case "1":
        if (this.agreeRights) {
          skip = true;
          this.setStep(parseInt(this.step, 10) > 1 ? "0" : "2");
        }
        break;
      case "2":
        if (this.agreeDisclosure) {
          skip = true;
          this.setStep(parseInt(this.step, 10) > 2 ? "1" : "3");
        }
        break;
      case "3":
        if (!this.stateRights || this.agreeStateRights) {
          skip = true;
          this.setStep(parseInt(this.step, 10) > 2 ? "2" : "4");
        }
        break;
      case "4":
        if (this.showConfirmationScreen && this.agreeAuthorization) {
          skip = true;
          this.setStep(parseInt(this.step, 10) > 2 ? "3" : "5");
        }
        break;
      case "5":
        break;
      default:
        break;
    }
    if (step !== "0") this.clearError();
    if (window) window.scrollTo(0, 0);
    if (!skip) this.step = step;
  };

  storeImage = dataUrl => {
    this.signature_image = dataUrl;
  };

  setFormData = (query = {}, do_mvr = false, requiredFields = []) => {
    const has_middle_name =
      (!_.isEmpty(query.middle_name) && !toBoolean(query.no_middle_name)) ||
      !_.isEmpty(query.middle_name);
    let doChecks = true;
    this.setPhone(query.phone_number);
    this.setFirstName(query.first_name);
    this.setLastName(query.last_name);
    if (has_middle_name) {
      this.setHasMiddleName(!has_middle_name);
    } else {
      this.setHasMiddleName(has_middle_name);
    }
    //this.setHasCarInsurance(query.has_car_insurance);
    this.setDateOfBirth(query.date_of_birth);
    this.setGender(query.gender);
    this.setZipcode(query.zipcode);
    this.setReferenceId(query.reference_id);
    this.setEmail(query.email);
    this.setBirthCountry(query.birth_country);
    this.setBirthProvinceState(query.birth_province_state);
    this.setBirthCity(query.birth_city);
    this.setAddressProvince(query.address_province);
    this.setAddressCity(query.address_city);
    this.setAddressStreet(query.address_street);
    this.setAddressZipcode(query.address_zipcode);
    //this.setFountainId(query.fountain_id);
    this.setDoChecks(doChecks);
    if (has_middle_name) this.setMiddleName(query.middle_name);
    if (do_mvr) this.setDriversLicenseState(query.drivers_license_state);
    if (do_mvr) this.setDriversLicenseNumber(query.drivers_license_number);
    let func;
    requiredFields.forEach(field => {
      func = this[
        _.camelCase(`touch_${field === "phone_number" ? "phone" : field}`)
      ];
      if (typeof func === "function" && !!query[field]) func();
    });
  };

  //VALIDATION
  //Names
  validateName(key) {
    const str = this[key].trim();

    let error = "";

    if (key === "middleName" && this.hasMiddleName) {
      error = containsNumber(str)
        ? "should not contain numbers"
        : containsAtSymbol(str)
        ? "should not contain @ symbol"
        : undefined;
    } else {
      error =
        str.length === 0
          ? "required"
          : containsNumber(str)
          ? "should not contain numbers"
          : containsAtSymbol(str)
          ? "should not contain @ symbol"
          : undefined;
    }

    const warning = this.nicknames.some(val =>
      _.isString(val) && _.isString(str)
        ? val.toUpperCase() === str.toUpperCase()
        : false
    )
      ? "This looks like a nickname. Please use your legal name."
      : undefined;
    this.setWarningState(key, warning);
    this.setErrorState(key, error);
    return error;
  }
  validateFirstName = () => this.validateName("firstName");
  validateLastName = () => this.validateName("lastName");
  validateMiddleName = () => this.validateName("middleName");
  // Email
  get validEmail() {
    return validateEmail(this.email);
  }
  get invalidEmail() {
    return this.touchedEmail && !this.validEmail;
  }
  validateEmail = () => {
    const error = this.invalidEmail ? "must be correctly formatted" : undefined;
    this.setErrorState("email", error);
  };
  get disableEmail() {
    return !!this.email && !this.touchedEmail;
  }
  validatePhone = () => {
    const error = this.invalidPhone
      ? "must be 10 digits or 11 (1 prefixed)"
      : undefined;
    this.setErrorState("phone", error);
  };
  validateBirthCountry = () => this.validateName("birthCountry");
  validateBirthProvinceState = () => this.validateName("birthProvinceState");
  validateBirthCity = () => this.validateName("birthCity");
  validateAddressCountry = () => this.validateName("addressCountry");
  validateAddressProvince = () => this.validateName("addressProvince");
  validateAddressCity = () => this.validateName("addressCity");
  validateAddressStreet = () => {
    const error = !validateAddress(this.addressStreet)
      ? "must be a valid street name"
      : undefined;
    this.setErrorState("addressStreet", error);
    return typeof error === "undefined";
  };
  validateAddressZipcode = () => {
    const error = !validateCanadianZipcode(this.addressZipcode)
      ? "must be a valid Zipcode, no special characters allowed"
      : undefined;
    this.setErrorState("addressZipcode", error);
    return typeof error === "undefined";
  };
  validateConvictions = () => {
    // If not convicted, clear and skip validation
    if (!this.convicted) {
      this.clearConvictionsErrors();
      return true;
    }

    this.clearConvictionsErrors();
    _.forEach(this.convictions, (conviction, i) => {
      const thisErrors = {};

      // Check fields are not empty
      _.forEach(["offense", "date_of_sentence", "court_location"], key => {
        if (_.isEmpty(conviction[key])) {
          thisErrors[key] = "required";
        }
      });

      // Check date has no empty fields
      if (!thisErrors.date_of_sentence) {
        _.forEach(["year", "month", "day"], key => {
          const value = conviction["date_of_sentence"][key];
          if (!value) {
            thisErrors["date_of_sentence"] = "required";
          }
        });
      }

      // Only set error if we found any
      if (!_.isEmpty(thisErrors)) {
        this.setConvictionsErrors(i, thisErrors);
      }
    });

    return _.isEmpty(this.convictionErrors);
  };

  getLegalData() {
    return Promise.all([this.setAuthorization(), this.loadConvictionCharges()]);
  }

  setLegalData(disclosures) {
    this.rights = disclosures[1].copy_html;
    this.disclosure = disclosures[2].copy_html;
    this.terms = disclosures[3].copy_html;
    this.authorization = disclosures[3].copy_html;
  }

  async setReportAuth() {
    this.database
      .ref("report_auth")
      .once("value")
      .then(snap => {
        this.disclosure = legalReplace(snap.val(), this.partnerName);
      });
  }

  async setSummaryOfRights() {
    this.database
      .ref("summary_of_rights/default")
      .once("value")
      .then(snap => {
        this.rights = legalReplace(snap.val(), this.partnerName);
      });
  }

  async setSummaryOfStateRights() {
    this.database
      .ref(`summary_of_rights/${this.stateAbbr}`)
      .once("value")
      .then(snap => {
        this.stateRights = legalReplace(snap.val(), this.partnerName) || "";
      });
  }

  async setTerms() {
    this.database
      .ref("terms")
      .once("value")
      .then(snap => {
        this.terms = snap.val();
      });
  }

  async setAuthorization() {
    this.database
      .ref("authorization_canada")
      .once("value")
      .then(snap => {
        this.authorization = legalReplace(snap.val(), this.partnerName);
      });
  }

  async loadConvictionCharges() {
    const response = await turnAPI.getConvictionCharges();
    switch (response.status) {
      case 200:
      case 201:
        this.charges = response.data;
        break;
    }
  }

  setConvicted(convicted) {
    this.convicted = !!convicted;
  }

  setConvictions(convictions) {
    if (_.isArray(convictions)) {
      this.convictions = convictions;
    }
  }

  setShowManualForm = bool => {
    this.showManualForm = bool;
  };

  setShowAutomaticFill = bool => {
    this.showAutomaticFill = bool;
  };

  getNicknames = async () => {
    this.database
      .ref("nicknames_validation")
      .once("value")
      .then(snap => {
        const value = snap.val();
        if (_.isArray(value.nicknames)) this.nicknames = value.nicknames;
      });
  };
}

decorate(LandinPageCanadaModel, {
  firstName: observable,
  lastName: observable,
  middleName: observable,
  email: observable,
  hasMiddleName: observable,
  phone: observable,
  gender: observable,
  birthCountry: observable,
  birthProvinceState: observable,
  birthCity: observable,
  zipcode: observable,
  addressCountry: observable,
  addressProvince: observable,
  addressCity: observable,
  addressStreet: observable,
  addressZipcode: observable,
  name: observable,
  signed: observable,

  charges: observable,
  convictions: observable,
  convicted: observable,

  dayOfBirth: observable,
  monthOfBirth: observable,
  yearOfBirth: observable,
  error: observable,
  errorState: observable,
  warningState: observable,
  convictionErrors: observable,
  referenceId: observable,
  doChecks: observable,
  certify: observable,
  certifyTos: observable,
  certifySms: observable,
  releaseCertify: observable,
  loading: observable,
  displayIFrame: observable,
  step: observable,
  agreeDisclosure: observable,
  agreeRights: observable,
  agreeStateRights: observable,
  agreeTerms: observable,
  agreeAuthorization: observable,
  agreeSigning: observable,
  disclosure: observable,
  rights: observable,
  stateRights: observable,
  terms: observable,
  authorization: observable,
  partnerName: observable,

  touchedFirstName: observable,
  touchedLastName: observable,
  touchedMiddleName: observable,
  touchedEmail: observable,
  touchedPhone: observable,
  touchedBirthCountry: observable,
  touchedBirthProvinceState: observable,
  touchedBirthCity: observable,
  touchedAddressCountry: observable,
  touchedAddressProvince: observable,
  touchedAddressCity: observable,
  touchedAddressStreet: observable,
  touchedAddressZipcode: observable,
  requiredFields: observable,
  freezedEmail: observable,
  freezedAddressCountry: observable,
  piiRetryPage: observable,
  captchaToken: observable,
  captchaReset: observable,

  //computed
  fieldAssertions: computed,
  validFirstName: computed,
  invalidFirstName: computed,
  validLastName: computed,
  invalidLastName: computed,
  validMiddleName: computed,
  invalidMiddleName: computed,
  validPhone: computed,
  invalidPhone: computed,
  validBirthCountry: computed,
  invalidBirthCountry: computed,
  validBirthProvinceState: computed,
  invalidBirthProvinceState: computed,
  validBirthCity: computed,
  invalidBirthCity: computed,
  validAddressCountry: computed,
  invalidAddressCountry: computed,
  validAddressProvince: computed,
  invalidAddressProvince: computed,
  validAddressCity: computed,
  invalidAddressCity: computed,
  validAddressStreet: computed,
  invalidAddressStreet: computed,
  validAddressZipcode: computed,
  invalidAddressZipcode: computed,
  informationReady: computed,
  showConfirmationScreen: computed,
  full_return_data: computed,
  dateOfBirth: computed,
  captchaError: computed,

  //action
  setFirstName: action,
  setLastName: action,
  setMiddleName: action,
  setPhone: action,
  setBirthCountry: action,
  setBirthProvinceState: action,
  setBirthCity: action,
  setAddressCountry: action,
  setAddressProvince: action,
  setAddressCity: action,
  setAddressStreet: action,
  setAddressZipcode: action,
  setMonthOfBirth: action,
  setDayOfBirth: action,
  setYearOfBirth: action,
  setFormData: action,
  setReferenceId: action,
  setDoChecks: action,
  setZipcode: action,
  setGender: action,
  setName: action,
  setOneOffChecks: action,
  declareOneOffChecks: action,
  declareOneOffCheck: action,
  setCertify: action,
  setCertifyTos: action,
  setCertifySms: action,
  setReleaseCertify: action,
  setLoading: action,
  setDisplayIFrame: action,
  setError: action,
  setErrorState: action,
  setConvictionsErrors: action,
  getNicknames: action,
  setWarningState: action,
  clearError: action,
  clearConvictionError: action,
  clearConvictionsErrors: action,
  setStep: action,
  setAgreeDisclosure: action,
  setAgreeAuthorization: action,
  setAgreeRights: action,
  setAgreeStateRights: action,
  setAgreeTerms: action,
  setAgreeSigning: action,
  setSigned: action,
  setReportAuth: action,
  setSummaryOfRights: action,
  setSummaryOfStateRights: action,
  setTerms: action,
  setAuthorization: action,
  setPartnerName: action,
  setBypass: action,
  storeImage: action,
  setCaptchaToken: action,
  setCaptchaReset: action,

  loadConvictionCharges: action,
  setConvicted: action,
  setConvictions: action,

  touchFirstName: action,
  touchLastName: action,
  touchMiddleName: action,
  touchEmail: action,
  touchPhone: action,
  touchBirthCountry: action,
  touchBirthProvinceState: action,
  touchBirthCity: action,
  touchAddressCountry: action,
  touchAddressProvince: action,
  touchAddressCity: action,
  touchAddressStreet: action,
  touchAddressZipcode: action,
  freezeEmail: action,
  unfreezeEmail: action,
  setPIIRetryPage: action
});

export default LandinPageCanadaModel;
