import React, { Component } from "react";

import Column from "../Column";
import { FormCheckLine } from "./components/FormCheckLine";
import { FormWizardBackForward } from "./components/FormWizardBackNext";
import { FormWizardStep } from "./components/FormWizardStep";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import Title from "../Title";
import TurnScroll from "../TurnScroll";
import messages from "./messages";
import { observer } from "mobx-react";
import { LandingPageStyles as styles } from "../../styles";
import { FormSignature } from "./components/FormSignature";
import syslog from "../../services/syslog";

const propTypes = {
  ...FormWizardStep.propTypes,
  isCanadaCheck: PropTypes.bool,
  details: PropTypes.object.isRequired,
  authorization: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

class TabAuthorization extends Component {
  handleNextSignature() {
    if (this.props.isCanadaCheck) {
      window.scrollTo(0, 0);
      this.props.details.setAgreeAuthorization(
        !this.props.details.agreeAuthorization
      );
    }
    this.props.handleSubmit();
    this.props.nextStep();
    const detailsData = { ...this.props.details.formData };
    detailsData["has_middle_name"] = !detailsData["has_middle_name"];
    detailsData["tab"] = "step_6_authorization";
    syslog([detailsData]);
  }

  handleNext() {
    this.props.nextStep();
    const detailsData = { ...this.props.details.data };
    detailsData["has_middle_name"] = !detailsData["has_middle_name"];
    detailsData["Location"] = "Tab Authorization";
    syslog([detailsData]);
  }

  setSignaturePad = pad => {
    this.signaturePad = pad;
  };
  storeImage = () => {
    const dataURL = this.signaturePad.toDataURL();
    this.props.details.storeImage(dataURL);
  };
  renderAuthorizationCheckbox = () => (
    <div>
      <div style={styles.agreeContainer}>
        <FormCheckLine
          id="authorizationTabCheckbox"
          label={<FormattedMessage {...messages.authorization} />}
          checked={this.props.details.agreeAuthorization}
          onChange={() =>
            this.props.details.setAgreeAuthorization(
              !this.props.details.agreeAuthorization
            )
          }
        />
      </div>
      <Column
        textAlign="right"
        textAlignSM="center"
        width="100%"
        margin="20px 0 0 0"
      >
        <FormWizardBackForward
          id="authorizationTabBackForwardDiv"
          nextDisabled={!this.props.details.agreeAuthorization}
          onBack={() => this.props.previousStep()}
          onNext={() => this.handleNext()}
        />
      </Column>
    </div>
  );
  renderAction = () => {
    if (!this.props.details.showConfirmationScreen)
      return this.renderSignatureAndSubmit();
    return this.renderAuthorizationCheckbox();
  };
  renderSignatureAndSubmit = () => (
    <div>
      <div style={styles.signatureForm}>
        <p style={{ ...styles.textBig, marginBottom: 28, marginTop: 36 }}>
          Electronic Signature
        </p>
        <FormSignature details={this.props.details} />
        <FormCheckLine
          id="authorizationTabCheckbox"
          label={<FormattedMessage {...messages.signing} />}
          checked={this.props.details.agreeSigning}
          onChange={() =>
            this.props.details.setAgreeSigning(!this.props.details.agreeSigning)
          }
        />
      </div>
      {this.props.details.error ? (
        <p style={styles.error}>{this.props.details.error}</p>
      ) : null}
      <Column
        textAlign="right"
        textAlignSM="center"
        width="100%"
        margin="20px 0 0 0"
      >
        <FormWizardBackForward
          id="authorizationTabBackForwardDiv"
          nextLabel={"Submit"}
          nextDisabled={
            !this.props.details.agreeSigning ||
            !this.props.details.signed ||
            !this.props.details.name
          }
          onBack={() => this.props.previousStep()}
          onNext={() =>
            this.props.isCanadaCheck
              ? this.handleNextSignature()
              : this.handleNext()
          }
        />
      </Column>
    </div>
  );
  render() {
    if (
      !this.props.details ||
      !this.props.authorization ||
      !this.props.handleSubmit
    ) {
      return null;
    }

    return (
      <div style={styles.tabContent}>
        <Title id="authorizationStepTitle" align="left" big>
          Authorization of Background Investigation
        </Title>
        <TurnScroll
          id="authorizationLegalDisclosure"
          dangerouslySetInnerHTML={{ __html: this.props.authorization }}
        />
        {this.renderAction()}
      </div>
    );
  }
}

TabAuthorization.propTypes = propTypes;
export default observer(TabAuthorization);
