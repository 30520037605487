import React from "react";
import PropTypes from "prop-types";
import ReactSignature from "react-signature-canvas";
import { observer } from "mobx-react";

const propTypes = {
  clearCanvas: PropTypes.func,
  setRef: PropTypes.func
};

const CircularDisplay = observer(props => (
  <div id="signature-pad" className="m-signature-pad">
    <div className="m-signature-pad--body">
      <ReactSignature ref={props.setRef} {...props} />
    </div>
  </div>
));

CircularDisplay.propTypes = propTypes;

export default CircularDisplay;
