import { Builder } from "../record-helper";

export const parseAccident = data => {
  const titles = ["accident_injury"];
  const sentences = [
    "accident_details",
    "property_damage",
    "property_damage_desc"
  ];
  const builder = new Builder(data, { titles, sentences });

  builder.assign("incident_date");
  builder.assignNested(
    "locations",
    [
      ["loc", { out: "location" }],
      ["zip", { out: "zip_code" }],
      ["docket"],
      ["court"],
      ["court_type"],
      ["state"]
    ],
    { titles: ["loc", "court", "court_type"] }
  );
  builder.assignList([
    "state_code",
    "state_points",
    "commercial",
    "acd_code",
    "fault",
    "num_vehicles"
  ]);
  builder.assign("accident_details", { out: "details" });
  builder.assign("accident_injury", { out: "injury" });
  builder.assign("accident_injury_num", { out: "injury_number" });
  builder.assignList([
    "property_damage",
    "property_damage_desc",
    "plate_number"
  ]);
  builder.assignNested(
    "descriptions",
    [["short_description"], ["state_description"], ["long_description"]],
    {
      sentences: ["short_description", "state_description", "long_description"]
    }
  );
  return builder.dump();
};
