const styles = {
  container: {
    display: "inline-flex",
    width: "400px",
    borderRadius: "10px",
    position: "relative",
    backgroundColor: "#fff"
  },
  textField: {
    border: "3px solid"
  },
  input: {
    border: "1px solid rgb(229 231 235)",
    borderRadius: "10px"
  },
  focused: {
    border: "1px solid #7364DB"
  },
  iconContainer: {
    position: "absolute",
    alignItems: "center",
    right: "20px",
    display: "flex",
    height: "100%"
  },
  icon: {
    fontSize: "16px",
    fontWeight: "lighter",
    color: "#4b5563cc"
  }
};

export default styles;
