import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import _ from "lodash";
import { styles } from "./styles";
import { turnAPI } from "../../services";
import { primary } from "../../styles/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faCheck } from "@fortawesome/pro-solid-svg-icons";

const ReSendEmail = observer(
  class ReSendEmail extends Component {
    static propTypes = {
      worker: PropTypes.object.isRequired
    };

    constructor(props) {
      super(props);
      this.state = {
        ready: true
      };
    }

    setReady = ready => {
      if (!_.isBoolean(ready)) return;
      this.setState({ ready });
    };

    reSendEmail = async () => {
      this.setReady(false);
      await turnAPI.resendEmail(this.props.worker.turnId);
    };

    render() {
      const { ready } = this.state;
      return (
        <div style={styles.container}>
          {ready ? (
            <FontAwesomeIcon
              id="resendEmailButton"
              onClick={this.reSendEmail}
              icon={faRedo}
              style={styles.icon}
              color={primary}
            />
          ) : (
            <FontAwesomeIcon
              id="emailResentIcon"
              onClick={this.reSendEmail}
              icon={faCheck}
              style={styles.icon}
              color={primary}
            />
          )}
        </div>
      );
    }
  }
);

export default ReSendEmail;
