import styled from "styled-components";

const DisclosureContainer = styled.div`
  padding: ${props =>
    props.question ? "1% 15%" : props.full ? "0 0 0 34px" : "0 20%"};
  margin-bottom: ${props =>
    props.question
      ? "0"
      : props.full
      ? "16px"
      : props.marginBottom
      ? props.marginBottom
      : "5%"};
  text-align: left;
  position: relative;
  @media (max-width: 600px) {
    padding: ${props =>
      props.question ? "1% 1%" : props.full ? "0 0 0 40px" : "0 10% 0 10%"};
  }
`;

export default DisclosureContainer;
