import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/pro-solid-svg-icons";
import EnrollmentBadge from "../Badge";

export const getLabelIcon = enrollment => {
  const { status } = enrollment || {};

  if (status === "not_enrolled")
    return (
      <FontAwesomeIcon
        style={{ color: "#70707C", fontSize: "16px", marginRight: "12px" }}
        icon={faBan}
      />
    );

  return <EnrollmentBadge enrollment={enrollment} />;
};
