import React from "react";
import PropTypes from "prop-types";
import ButtonStyles from "./ButtonStyles";

function StatusButton({ text, callback, disabled, Icon, id = null }) {
  return (
    <ButtonStyles disabled={disabled} onClick={callback} id={id}>
      {text}
      {Icon ? <Icon width="16px" /> : null}
    </ButtonStyles>
  );
}

StatusButton.propTypes = {
  text: PropTypes.string,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  Icon: PropTypes.node,
  id: PropTypes.string
};

export default StatusButton;
