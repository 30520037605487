import { PROCESSING, PENDING, CLEAR, WITHDRAWN } from "../../../constants";
import {
  orange,
  green,
  red,
  slateGray,
  periwinkle
} from "../../../styles/constants";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner
} from "@fortawesome/pro-solid-svg-icons";

export const CHECK_STATUS_MAPPING = {
  [CLEAR]: {
    bgColor: green,
    color: green,
    icon: faCheckCircle,
    testStatus: "clear"
  },
  [PENDING]: {
    bgColor: red,
    color: orange,
    icon: faExclamationTriangle,
    testStatus: "consider"
  },
  [PROCESSING]: {
    bgColor: periwinkle,
    color: slateGray,
    icon: faSpinner,
    testStatus: "processing..."
  },
  [WITHDRAWN]: {
    bgColor: red,
    color: red,
    icon: faExclamationTriangle,
    testStatus: "withdrawn"
  }
};

export const getCheckStatus = status => CHECK_STATUS_MAPPING[status];

export const hideReportUrl = status => [PROCESSING, WITHDRAWN].includes(status);
