import React from "react";
import { FormattedMessage } from "react-intl";
import Highlighted from "../Highlighted";
import { emails } from "../../data/company";
import messages from "./messages";

const style = {
  paddingTop: 24
};

const TrialExpired = ({ ecomm }) => (
  <div style={style}>
    <FormattedMessage
      {...(ecomm ? messages.trialExpiredEcomm : messages.trialExpired)}
      values={{
        email: <a href={`mailto:${emails.sales}`}>{emails.sales}</a>,
        reason: (
          <Highlighted>
            <FormattedMessage {...messages.trialExpiredChecks} />
          </Highlighted>
        )
      }}
    />
  </div>
);

export default TrialExpired;
