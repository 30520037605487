import { observable, action, computed, reaction, decorate } from "mobx";
import moment from "moment";
import _ from "lodash";
import { getStatus } from "turn-shared";
import { formatDate, Setter } from "turn-shared";
import Worker from "./worker";
import env from "@mars/heroku-js-runtime-env";

/**
 * @class
 * @desc Workers model with MobX enhancements
 */
class Workers extends Setter {
  // Not observable
  allowed_orders = {
    fullName: "display_name",
    city: "city",
    email: "email",
    status: "profile_status",
    signUpDate: "create_timestamp"
  };
  order_to_property = {
    display_name: "fullName",
    city: "city",
    email: "email",
    profile_status: "status",
    create_timestamp: "signUpDate",
    package_name: "package"
  };
  get config() {
    return env().REACT_APP_ENABLE_CHECK_STATUS === "true"
      ? [
          { value: "status", label: "Status" },
          { value: "fullName", label: "Full Name" },
          { value: "city", label: "City" },
          { value: "signUpDate", label: "Consent Date" },
          { value: "package", label: "Package" },
          { value: "empty", label: "Empty for preview candidate eye" }
        ]
      : [
          { value: "fullName", label: "Full Name" },
          { value: "email", label: "Email" },
          { value: "status", label: "On Boarding Status" },
          { value: "signUpDate", label: "Consent Date" },
          { value: "package", label: "Package" }
        ];
  }

  // Observable Properties
  ready = false;
  sortBy = "signUpDate";
  ascending = false;
  filter = "";
  daysFilter = 0;
  startDate = undefined;
  endDate = undefined;
  action = "all";
  status = "all";
  search = "";
  limit = 20;
  offset = 0;
  orderAttribute = "create_timestamp";
  onlyIdentified = false;
  prevOffset = 0;

  saveHandler = null;
  searchHandler = null;
  statusHandler = null;
  daysHandler = null;
  workerToastr = { success: null, error: null, message: "" };
  csvSelectedDate = "";

  constructor() {
    super();
    this.saveHandler = reaction(
      () => this.params,
      () => {
        this.storeParams();
      }
    );
    this.searchHandler = reaction(
      () => this.search,
      () => {
        if (this.search) {
          if (this.prevOffset === 0) this.prevOffset = this.offset;
          this.setOffset(0);
        } else {
          this.setOffset(this.prevOffset);
          this.prevOffset = 0;
        }
      }
    );
    this.statusHandler = reaction(
      () => this.status,
      () => {
        if (this.status) {
          if (this.prevOffset === 0) this.prevOffset = this.offset;
          this.setOffset(0);
        } else {
          this.setOffset(this.prevOffset);
          this.prevOffset = 0;
        }
      }
    );
    this.daysHandler = reaction(
      () => [this.daysFilter, this.startDate, this.endDate],
      () => {
        if (this.daysFilter || this.startDate || this.endDate) {
          if (this.prevOffset === 0) this.prevOffset = this.offset;
          this.setOffset(0);
        } else {
          this.setOffset(this.prevOffset);
          this.prevOffset = 0;
        }
      }
    );

    this.default = {};
    for (var key in this) {
      if (this.hasOwnProperty(key)) {
        this.default[key] = this[key];
      }
    }
  }

  // Computed Properties
  get totalWorkers() {
    if (!this.payload) return [];
    const { items: workers = [], adverse_action } = this.payload;
    return workers.map(worker => new Worker(worker, adverse_action));
  }
  get shouldSelectallBeOn() {
    return !!this.totalWorkers.find(
      worker => getStatus(worker.status) === "consider"
    );
  }
  get adverseAction() {
    if (!this.payload) return [];
    const { adverse_action } = this.payload;
    return adverse_action;
  }
  get pageLimit() {
    if (!this.payload) return 1;
    const { count, limit } = this.payload;
    return Math.ceil(count / limit);
  }
  get orderSign() {
    return this.ascending ? "" : "-";
  }
  get orderBy() {
    return [`${this.orderSign}${this.orderAttribute}`];
  }
  get params() {
    const filters = {
      global: this.search
    };

    if (this.status !== "all") filters["profile_status"] = this.status;
    if (this.daysFilter) {
      filters["create_timestamp__gt"] = moment()
        .add(-this.daysFilter, "days")
        .toDate();
    } else if (this.startDate && this.endDate) {
      try {
        filters["create_timestamp__gt"] = moment(this.startDate).format(
          "YYYY-MM-DD"
        );
        filters["create_timestamp__lt"] = moment(this.endDate).format(
          "YYYY-MM-DD"
        );
      } catch {
        // Do nothing
      }
    }

    if (this.action !== "all") {
      filters["action"] = this.action;
    }

    const params = {
      filters,
      limit: this.limit,
      offset: this.offset,
      orderBy: [...this.orderBy],
      only_identified: false
    };

    return params;
  }
  storeParams = () => {
    if (!window.localStorage) return;
    window.localStorage.setItem(
      "candidate_params",
      JSON.stringify({
        search: this.search,
        daysFilter: this.daysFilter,
        limit: this.limit,
        offset: this.offset,
        ascending: this.ascending,
        orderAttribute: this.orderAttribute,
        onlyIdentified: this.onlyIdentified
      })
    );
  };
  retrieveParams = () => {
    if (!window.localStorage) return false;
    const data = window.localStorage.getItem("candidate_params");
    if (!data) return false;
    const params = JSON.parse(data);
    this.setSearch(params.search);
    this.setDaysFilter(params.daysFilter);
    this.setLimit(params.limit);
    this.setOffset(params.offset);
    this.ascending = params.ascending;
    this.orderAttribute = params.orderAttribute;
    this.onlyIdentified = params.onlyIdentified;
    return true;
  };
  get orderProperty() {
    return this.order_to_property[this.orderAttribute];
  }
  get order() {
    return this.ascending ? "asc" : "desc";
  }
  get list() {
    return _.orderBy(this.searchList, [this.sortBy], [this.order]);
  }
  get searchList() {
    if (this.filter.length === 0) return [...this.totalWorkers];
    let value;
    let match;
    return this.totalWorkers.filter(worker => {
      match = false;
      for (let i = 0; i < this.config.length; i++) {
        // eslint-disable-line
        if (this.config[i].value === "signUpDate") {
          value = formatDate(worker[this.config[i].value])
            .toString()
            .toLowerCase();
        } else {
          value = worker[this.config[i].value].toString().toLowerCase();
        }
        if (value.indexOf(this.filter.toLowerCase()) > -1) match = true;
      }
      return match;
    });
  }
  get count() {
    return this.list.length;
  }
  get page() {
    return this.offset > 1 ? (this.offset + this.limit) / this.limit : 1;
  }
  get statuses() {
    const base = ["all"];
    if (_.isEmpty(this.payload)) return base;
    return [...base, ...Object.keys(this.payload.counts)];
  }

  setPayload = payload => {
    if (_.isEmpty(payload)) return;
    const items = payload.items ? payload.items : [];
    const newPayload = Object.assign({}, payload);
    newPayload.items = items;
    this.payload = newPayload;
    this.setReady(true);
  };
  sortWorkers = property => {
    if (!property) return;
    this.toggleAscending(property);
    this.setSortBy(property);
  };
  toggleAscending = property => {
    this.ascending = property === this.sortBy ? !this.ascending : true;
  };
  addWorker = worker => {
    if (!worker) return;
    this.payload.items.push(worker);
  };
  /**
   * @func
   * @memberof! Workers#
   * @param {string} filter
   */
  setFilter = this.setStringFactory("filter", "");
  setDaysFilter(days) {
    if (days) {
      this.daysFilter = days;
      this.startDate = null;
      this.endDate = null;
    }
  }
  setStartDate(date) {
    if (date) {
      this.startDate = date;
      this.daysFilter = null;
    }
  }
  setEndDate(date) {
    if (date) {
      this.endDate = date;
      this.daysFilter = null;
    }
  }
  setAction(action) {
    this.action = action;
  }

  setWorkerToastr(payload) {
    this.workerToastr = payload;
  }

  setCSVSelectedFromDate(date) {
    this.csvSelectedFromDate = date;
  }

  setCSVSelectedToDate(date) {
    this.csvSelectedToDate = date;
  }

  /**
   * @func
   * @memberof! Workers#
   * @param {string} search
   * @desc trigger a new candidate search using the provided value
   */
  setSearch = this.setStringFactory("search", "");
  /**
   * @func
   * @memberof! Workers#
   * @param {string} status
   * @desc trigger a new candidate search filtering by a valid candidate status
   */
  setStatus = this.setOptionFactory("status", "statuses");
  setSortBy = this.setTruthyFactory("sortBy");
  setLimit = this.setNumberFactory("limit");
  setOffset = this.setNumberFactory("offset");
  /**
   * @func
   * @memberof! Workers#
   * @param {boolean} ready
   * @desc trigger UI lock/unlocking
   */
  setReady = this.setBoolFactory("ready");
  /**
   * @func
   * @memberof! Workers#
   * @desc toggles the filtering of candidate results by an onlyIdentified flag
   */
  toggleIdentified = this.toggleBoolFactory("onlyIdentified");
  setOrderBy = order => {
    const orderAttribute = this.allowed_orders[order];
    if (!orderAttribute) return;
    this.ascending =
      orderAttribute === this.orderAttribute ? !this.ascending : false;
    this.orderAttribute = orderAttribute;
  };
  isOrderable = prop => Object.keys(this.allowed_orders).includes(prop);

  reset() {
    for (var key in this.default) {
      if (this.hasOwnProperty(key)) {
        this[key] = this.default[key];
      }
    }
  }
}

decorate(Workers, {
  // OBSERVABLE
  payload: observable,
  ready: observable,
  sortBy: observable,
  ascending: observable,
  filter: observable,
  daysFilter: observable,
  startDate: observable,
  endDate: observable,
  action: observable,
  status: observable,
  search: observable,
  limit: observable,
  offset: observable,
  orderAttribute: observable,
  onlyIdentified: observable,
  prevOffset: observable,
  workerToastr: observable,
  csvSelectedFromDate: observable,
  csvSelectedToDate: observable,
  // COMPUTED
  orderSign: computed,
  orderBy: computed,
  params: computed,
  orderProperty: computed,
  order: computed,
  list: computed,
  searchList: computed,
  count: computed,
  page: computed,
  statuses: computed,
  totalWorkers: computed,
  shouldSelectallBeOn: computed,
  pageLimit: computed,
  adverseAction: computed,
  // ACTION
  setPayload: action,
  setSearch: action,
  storeParams: action,
  retrieveParams: action,
  sortWorkers: action,
  toggleAscending: action,
  setSortBy: action,
  setFilter: action,
  setDaysFilter: action,
  setStartDate: action,
  setEndDate: action,
  setAction: action,
  addWorker: action,
  setLimit: action,
  setOffset: action,
  setOrderBy: action,
  toggleIdentified: action,
  setPageLimit: action,
  setReady: action,
  setStatus: action,
  reset: action,
  setWorkerToastr: action,
  setCSVSelectedFromDate: action,
  setCSVSelectedToDate: action
});

const workers = new Workers();
export default workers;
