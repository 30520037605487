import React, { useMemo, useState, useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner
} from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import _ from "lodash";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PropTypes from "prop-types";

import {
  CandidateReportStyles as reportStyles,
  WorkerStyles as styles
} from "../../../styles";
import PanelCollapsible from "../../../components/PanelCollapsible";
import Padder from "../../../components/SSNTrace/padder";
import Row from "../../../components/Row";
import Text from "../../../components/Text";
import { green, red, aliceBlue, periwinkle } from "../../../styles/constants";
import { PROCESSING } from "../../../constants";

import messages from "../messages";

import { ImageBox } from "./ImageBox";
import { Dialog, DialogContent, Button } from "@material-ui/core";
import { SearchHighlighted } from "../../../components/SearchHighlighted";
import { AppContext } from "../../../contextAPI/context-provider";
const CarouselCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  background: #fff;

  & img {
    max-height: 100% !important;
    max-width: 100% !important;
    width: auto !important;
  }
`;

const CarouselImage = styled.img``;

export const CanadaEnhancedIdentity = ({ worker }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isProcessing] = useState(worker.onBoardingStatus === PROCESSING);
  const context = useContext(AppContext);

  const enhancedIdentityImagesKeys = {
    back: "Back",
    front: "Front",
    face: "Face"
  };

  const previewFor = index => {
    setShowPreview(true);
    setCurrentSlide(index);
  };

  const enhancedIdentityData = useMemo(() => {
    return _.get(worker, "canadianReport.enhanced_identity_data");
  }, [worker]);

  const enhancedIdentityImages = useMemo(() => {
    return _.get(worker, "canadianReport.enhanced_identity_images");
  }, [worker]);

  const noImages = useMemo(() => {
    if (!enhancedIdentityImages) {
      return true;
    }

    for (const key of _.keys(enhancedIdentityImagesKeys)) {
      const value = _.get(enhancedIdentityImages, key);
      if (!_.isEmpty(value)) {
        return false;
      }
    }

    return true;
  }, [enhancedIdentityImages]);

  const hit = useMemo(() => {
    const status_label = _.get(enhancedIdentityData, "status_label");
    if (_.isEmpty(status_label) || status_label.toLowerCase() != "accepted") {
      return true;
    } else {
      return false;
    }
  }, [enhancedIdentityData]);

  if (!_.get(worker, "report_parameters.do_canadian_enhanced_id_check")) {
    return null;
  }

  const icon = isProcessing
    ? faSpinner
    : hit
    ? faExclamationTriangle
    : faCheckCircle;
  const color = isProcessing ? periwinkle : hit ? red : green;

  const { checks = {} } = context;

  return (
    <PanelCollapsible
      title={messages.enhancedIdentity}
      titleStyle={reportStyles.title}
      iconStyle={reportStyles.checkedIcon}
      color={color}
      icon={icon}
      isCollapsbile
      collapsible={!isProcessing}
      expandAllChecks={!isProcessing && checks.expandAllChecks}
    >
      <Padder
        padding="30px 0%"
        style={{ borderBottom: `1px solid ${aliceBlue}` }}
      >
        <Row>
          <div style={styles.ssn.header}>
            <Text style={reportStyles.label}>Result:</Text>
            <Text style={reportStyles.value}>
              <SearchHighlighted
                textToHighlight={
                  _.isEmpty(enhancedIdentityData.result_label)
                    ? "Not Available"
                    : enhancedIdentityData.result_label
                }
              />
            </Text>
          </div>
          <div style={styles.ssn.header}>
            <Text style={reportStyles.label}>Status:</Text>
            <Text style={reportStyles.value}>
              <SearchHighlighted
                textToHighlight={
                  _.isEmpty(enhancedIdentityData.status_label)
                    ? "Not Available"
                    : enhancedIdentityData.status_label
                }
              />
            </Text>
          </div>
        </Row>

        {noImages ? (
          <Row style={{ display: "flex", marginTop: 32 }}>
            <Text style={reportStyles.value}>No image data</Text>
          </Row>
        ) : (
          <Row style={{ display: "flex", marginTop: 32 }}>
            {_.keys(enhancedIdentityImagesKeys).map((k, i) => {
              if (!_.isEmpty(enhancedIdentityImages[k])) {
                return (
                  <ImageBox
                    key={i}
                    label={enhancedIdentityImagesKeys[k]}
                    src={enhancedIdentityImages[k]}
                    onClick={() => previewFor(i)}
                  />
                );
              }
            })}

            <Dialog
              open={showPreview}
              onClose={() => setShowPreview(false)}
              onBackdropClick={() => setShowPreview(false)}
            >
              <DialogContent>
                <Carousel
                  selectedItem={currentSlide}
                  onChange={setCurrentSlide}
                  renderThumbs={() =>
                    _.keys(enhancedIdentityImagesKeys).map((k, i) => (
                      <CarouselImage key={i} src={enhancedIdentityImages[k]} />
                    ))
                  }
                  renderIndicator={null}
                >
                  {_.keys(enhancedIdentityImagesKeys).map((k, i) => (
                    <CarouselCard key={i}>
                      <CarouselImage src={enhancedIdentityImages[k]} />
                    </CarouselCard>
                  ))}
                </Carousel>
                <div style={{ textAlign: "center" }}>
                  <Button
                    color="secondary"
                    onClick={() => setShowPreview(false)}
                  >
                    Close
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </Row>
        )}
      </Padder>
    </PanelCollapsible>
  );
};

CanadaEnhancedIdentity.propTypes = {
  worker: PropTypes.object
};
