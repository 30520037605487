import { action, observable, decorate } from "mobx"; // , computed
import { toTitleCase, isObject } from "turn-shared";
import { isNull } from "lodash";

class SSNModel {
  name = { status: null, details: [] };
  public_records_name = { status: null, details: [] };
  dob = { status: null, details: [] };
  mortality = { status: null, details: [] };
  manual = false;

  // Is worker orphaned?
  isOrphan = false;

  setData(data, manual = false) {
    if (!isObject(data)) return;
    const isOrphan = data.is_orphan || this.isOrphan;
    this.manual = manual;
    this.name = {
      status: isOrphan ? "" : data.name_status,
      details: [
        {
          label: "Candidate Entered",
          value: toTitleCase(data.candidate_provided_name || null)
        }
      ]
    };
    this.dob = {
      status: this.manual ? "" : data.dob_status,
      details: [
        {
          label: "Candidate Entered",
          value: data.candidate_provided_date_of_birth || null
        }
      ]
    };
    if (!isOrphan || !this.manual) {
      this.name.details.push({
        label: "Partner Entered",
        value: toTitleCase(data.partner_provided_name || null)
      });
    }
    if (!this.manual) {
      this.public_records_name = {
        status: data.public_name_status,
        details: [
          {
            label: "Candidate Entered",
            value: toTitleCase(data.candidate_provided_name || null)
          },
          {
            label: "Public Records",
            value: toTitleCase(data.public_provided_name || null)
          }
        ]
      };
      this.dob.details.push({
        label: "Public Records",
        value: data.public_record_date_of_birth || null
      });
    }

    if (data.deceased_date || data.is_deceased) {
      this.mortality = {
        status: "Deceased",
        details: data.deceased_date
          ? [{ label: "Date", value: data.deceased_date || "Not Provided" }]
          : [
              {
                label: "Detail",
                value: "SSN is listed in the SSA Death Master File"
              }
            ]
      };
    }
    if (isNull(data.deceased_date)) {
      this.mortality = {
        status: " ",
        details: data.deceased_date
          ? [{ label: "Date", value: data.deceased_date || "Not Provided" }]
          : [
              {
                label: "Detail",
                value: "SSN is NOT listed in the SSA Death Master File"
              }
            ]
      };
    }
    this.isOrphan = isOrphan;
  }

  validateNoCaseSensitiveName() {
    const [candidateProvidedName, partnerProvidedName] = this.name.details.map(
      details => details.value
    );
    const isNameMatched =
      candidateProvidedName.toLowerCase() === partnerProvidedName.toLowerCase();

    if (isNameMatched) this.name.status = "matched";

    return isNameMatched;
  }

  isMatched(key) {
    if (!(key in this)) return null;
    if (key !== "dob" && this.isOrphan) return true;

    //! Temporary fix!! remove it when the BE does a no case sensitive match validation
    //! for candidateProvidedName and partnerProvidedName
    if (
      (key === "name" &&
        this.name.status === "not_matched" &&
        !this.isOrphan) ||
      (key === "name" && this.name.status === "not_matched" && !this.manual)
    )
      return this.validateNoCaseSensitiveName();

    if (this.manual) return true;
    return this[key].status === "matched" || this[key].status === "none";
  }
}

decorate(SSNModel, {
  name: observable,
  public_records_name: observable,
  dob: observable,
  mortality: observable,
  manual: observable,
  isOrphan: observable,
  setdetails: action
});

export default SSNModel;
