/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/href-no-hash */
import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { CandidateReportStyles as styles } from "../../styles";
import Row from "../Row";
import Column from "../Column";

import Check from "./Check";
import { green, red } from "../../styles/constants";
import Divider from "../Divider";
import Text from "../Text";
import { toTitleCase } from "turn-shared";
import Link from "../Link";
import { CLEAR } from "../../constants";

const propTypes = {
  oneOffChecks: PropTypes.object,
  isPDFReport: PropTypes.bool,
  reportDate: PropTypes.string,
  pendingMVRReview: PropTypes.bool,
  pendingAgeReview: PropTypes.bool,
  icon: PropTypes.string,
  color: PropTypes.string,
  status: PropTypes.string
};

const OneOffChecks = ({ oneOffChecks, color, icon }) => {
  if (!oneOffChecks) return null;

  return (
    <div style={styles.section}>
      <Check
        check="Other Checks"
        icon={icon}
        color={color}
        noDivider
        containerStyle={styles.checks.titleContainer}
        nameId="detailCheckName"
        statusId="detailCheckStatus"
      />
      <div>
        <Row style={styles.sectionContent}>
          <Column
            width="100%"
            widthMD={"100%"}
            widthSM={"100%"}
            style={styles.ssnStatus}
          >
            <Row>
              <Column width="25%" widthMD="100%">
                <Text weight="bold">Name</Text>
              </Column>
              <Column width="26.25%" widthMD="100%">
                <Text weight="bold">Status</Text>
              </Column>
              <Column width="34%" widthMD="100%">
                <Text weight="bold">Report</Text>
              </Column>
            </Row>
            {oneOffChecks.map((check, key) => (
              <div key={key} className="address">
                <Row>
                  <Column width="25%" widthMD="100%">
                    <Text>{toTitleCase(check.name)}</Text>
                  </Column>
                  <Column width="26.25%" widthMD="100%">
                    <Text
                      style={{
                        color:
                          check.resolution.toLowerCase() == CLEAR ? green : red
                      }}
                    >
                      {toTitleCase(check.resolution)}
                    </Text>
                  </Column>
                  <Column width="34%" widthMD="100%">
                    <Text>
                      <Link link="View Report" href={check.report_url} />
                    </Text>
                  </Column>
                </Row>
              </div>
            ))}
          </Column>
        </Row>
        <Divider height="1px" />
      </div>
    </div>
  );
};

OneOffChecks.propTypes = propTypes;

export default observer(OneOffChecks);
