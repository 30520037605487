import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ConsentProvided from "../../components/LandingPage/consent-provided";

const propTypes = { details: PropTypes.object };

class BackgroundReportSuccessMVRHits extends PureComponent {
  render() {
    return <ConsentProvided details={this.props.details || {}} />;
  }
}

BackgroundReportSuccessMVRHits.propTypes = propTypes;

export default BackgroundReportSuccessMVRHits;
