import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { CandidateReportStyles as styles } from "../../styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Text from "../Text";
import { primary } from "../../styles/constants";

class SectionTitle extends PureComponent {
  componentDidMount() {}

  renderIcon = () => {
    const { icon } = this.props;
    if (!icon) return null;

    return <FontAwesomeIcon icon={icon} style={styles.sectionTitleIcon} />;
  };

  render() {
    return (
      <div style={styles.sectionTitle}>
        <h3 style={styles.sectionTitleTxt}>
          {this.renderIcon()}
          <Text id="sectionTitle" weight="bold" size="inherit" color={primary}>
            {this.props.title}
          </Text>
        </h3>
      </div>
    );
  }
}

SectionTitle.propTypes = {
  title: PropTypes.object.isRequired,
  icon: PropTypes.object
};

export default SectionTitle;
