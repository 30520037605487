import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import RaisedButton from "material-ui/RaisedButton";
import { ModalStyles as styles, GeneralStyles } from "../../styles";

import messages from "./messages";

const ModalTrigger = ({ handleOpen }) => (
  <RaisedButton
    style={GeneralStyles.button}
    buttonStyle={GeneralStyles.button}
    overlayStyle={GeneralStyles.buttonOverlay}
    onClick={handleOpen}
    id="check_worker"
  >
    <div style={styles.buttonContent}>
      <FormattedMessage {...messages.triggerLabel} />
    </div>
  </RaisedButton>
);

ModalTrigger.propTypes = {
  handleOpen: PropTypes.func
};

export default ModalTrigger;
