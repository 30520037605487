import {
  CarInsuranceField,
  DateOfBirthField,
  EmailField,
  FirstNameField,
  GenderField,
  LastNameField,
  LicenseNumberField,
  LicenseStateField,
  MiddleNameField,
  PhoneField,
  SSNField,
  ZipcodeField,
  getProps
} from "./candidate-fields";
import { Grid, Paper, Typography, InputLabel } from "@material-ui/core";
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import Column from "../Column";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import Title from "../Title";
import _ from "lodash";
import messages from "./messages";
import { observer } from "mobx-react";
import { LandingPageStyles as styles } from "../../styles";
import { valueExists } from "turn-shared";
import { FormCheckLine } from "./components/FormCheckLine";
import { FormWizardBackForward } from "./components/FormWizardBackNext";
import { FormSignature } from "./components/FormSignature";
import syslog from "../../services/syslog";
import FormLockContainer from "./components/FormLockContainer";

const propTypes = {
  details: PropTypes.object,
  setStep: PropTypes.func,
  currentStep: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  getButtonFlatStyle: PropTypes.object,
  getButtonLabelStyle: PropTypes.object,
  getButtonStyle: PropTypes.object
};

class TabConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      fieldToEdit: "",
      error: false,
      showEditForm: false
    };
  }

  setSignaturePad = pad => {
    this.signaturePad = pad;
  };
  storeImage = () => {
    const dataURL = this.signaturePad.toDataURL();
    this.props.details.storeImage(dataURL);
  };
  logDataReview = (message, callback) => {
    const detailsData = { ...this.props.details.formData };
    detailsData["has_middle_name"] = !detailsData["has_middle_name"];
    delete detailsData["signature_image"];
    detailsData["tab"] = "step_8_confirmation";
    syslog([detailsData]);

    if (callback) callback({ skipValidateSSN: true });
  };
  showInput = field => {
    const { details } = this.props;
    const fieldName = this.camelize(this.state.fieldToEdit);
    const { error } = getProps(details, fieldName);

    if (!error.error) {
      this.setState({
        showEdit: true,
        fieldToEdit: field
      });
    }
  };
  camelize = str => {
    return str.replace(/_([a-z])/g, function(g) {
      return g[1].toUpperCase();
    });
  };
  hideInput = field => {
    const { details } = this.props;
    const fieldName = this.camelize(field);
    const { error } = getProps(details, fieldName);

    if (error.error) {
      this.setState({
        error: true
      });
    }

    if (!error.error) {
      this.setState({
        showEdit: false,
        fieldToEdit: "",
        error: false
      });
    }
  };
  showEditForm = () => {
    this.setState({
      showEditForm: true
    });
  };
  hideEditForm = () => {
    if (this.props.details.informationReady) {
      this.setState({
        showEditForm: false
      });
    }
  };
  renderField = field => {
    const { details: d } = this.props;
    const showSsn = d.requiredFields.includes("ssn");
    switch (field) {
      case "first_name":
        return <FirstNameField model={d} width="100%" />;
      case "last_name":
        return <LastNameField model={d} width="100%" />;
      case "middle_name":
        return <MiddleNameField model={d} width="100%" />;
      case "email":
        return <EmailField model={d} width="100%" />;
      case "phone_number":
        return <PhoneField model={d} width="100%" />;
      case "date_of_birth":
        return <DateOfBirthField model={d} width="100%" />;
      case "has_car_insurance":
        return <CarInsuranceField model={d} width="100%" />;
      case "ssn":
        return <SSNField model={d} width="100%" shouldRender={showSsn} />;
      case "gender":
        return <GenderField model={d} width="100%" />;
      case "zipcode":
        return <ZipcodeField model={d} width="100%" />;
      case "drivers_license_state":
        return <LicenseStateField model={d} width="100%" />;
      case "drivers_license_number":
        return <LicenseNumberField model={d} width="100%" />;
      default:
        return;
    }
  };

  renderLabel = key => {
    switch (key) {
      case "date_of_birth":
        return "Date of Birth";
      case "ssn":
        return "SSN";
      case "zipcode":
        return "Zip Code";
      default:
        return _.startCase(key);
    }
  };

  renderVariantAListItem = (value, key) => {
    if (!valueExists(value) && value !== "") {
      return null;
    }

    return (
      <Grid item xs={12} sm={6}>
        {this.state.showEdit && this.state.fieldToEdit === key ? (
          <div
            style={{
              position: "relative",
              opacity: 1,
              transition: "opacity 200ms"
            }}
          >
            {this.renderField(key)}
          </div>
        ) : (
          <div id={`${key}-grid`} style={{ padding: 10 }}>
            <div style={{ display: "flex" }}>
              <InputLabel
                htmlFor="name-simple"
                style={{ display: "flex", flex: 1, alignItems: "center" }}
              >
                <span>{this.renderLabel(key)}</span>
              </InputLabel>
            </div>
            <div>
              {this.state.showEdit && this.state.fieldToEdit === key ? (
                this.renderField(key)
              ) : (
                <Typography>{_.isEmpty(value) ? "-" : value}</Typography>
              )}
            </div>
          </div>
        )}
      </Grid>
    );
  };

  render() {
    if (!this.props.details) {
      return null;
    }
    return (
      <div style={styles.tabContent}>
        <Title id="confirmationStepTitle" align="left" big>
          Confirmation
        </Title>
        <p
          style={{
            ...styles.textBig,
            marginBottom: 28,
            marginTop: 36,
            color: "#7E7E8F"
          }}
        >
          Please take some time to carefully check your personal information and
          confirm its accuracy.
        </p>

        {this.props.details.error && (
          <p style={{ ...styles.error, marginBottom: 10 }}>
            {this.props.details.error}
          </p>
        )}

        <Paper style={{ borderRadius: 10, padding: 20 }}>
          <FormLockContainer formLock={true}>
            <Grid container spacing={12}>
              {_.map(
                this.props.details.confirmationData,
                this.renderVariantAListItem
              )}
            </Grid>
          </FormLockContainer>
        </Paper>

        <p style={{ ...styles.textBig, marginBottom: 28, marginTop: 36 }}>
          Electronic Signature
        </p>
        <FormSignature details={this.props.details} />
        <FormCheckLine
          id="confirmationTabCertifyCheckbox"
          label={
            this.props.details.isPAMVR ? (
              <FormattedMessage
                {...messages.certifyRelease}
                defaultMessage={`${messages.certifyRelease.defaultMessage} ${this.props.details.partnerName}`}
              />
            ) : (
              <FormattedMessage {...messages.certify} />
            )
          }
          checked={this.props.details.certify}
          onChange={() =>
            this.props.details.setCertify(!this.props.details.certify)
          }
        />

        <FormCheckLine
          id="confirmationTabSigningCheckbox"
          label={<FormattedMessage {...messages.signing} />}
          checked={this.props.details.agreeSigning}
          onChange={() =>
            this.props.details.setAgreeSigning(!this.props.details.agreeSigning)
          }
          style={{ color: "#7E7E8F !important" }}
        />

        <Column
          textAlign="right"
          textAlignSM="center"
          width="100%"
          margin="20px 0 0 0"
        >
          <FormWizardBackForward
            id="confirmationTabBackForwardDiv"
            nextLabel="Submit"
            nextDisabled={!this.props.details.allowConfirmationPageSubmit}
            onBack={() =>
              this.logDataReview(
                "Worker - Candidate - Data Reviewed - Backtracked",
                this.props.previousStep
              )
            }
            onNext={() =>
              this.logDataReview(
                "Worker - Candidate - Data Reviewed - Data Submitted",
                this.props.handleSubmit
              )
            }
          />
        </Column>
      </div>
    );
  }
}

TabConfirmation.propTypes = propTypes;
export default observer(TabConfirmation);
