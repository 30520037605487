import { primary } from "../../../styles/constants";

const text = {
  display: "block",
  fontWeight: "normal",
  color: primary
};

export const styles = {
  container: {
    minHeight: "60px",
    alignItems: "center",
    display: "flex",
    filter:
      "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
  },
  primary: {
    flex: 1,
    textAlign: "center"
  },
  names: {
    flex: 5,
    lineHeight: 1.5
  },
  actions: {
    flex: 2
  },
  actionButton: {
    textAlign: "center"
  },
  name: {
    ...text,
    fontSize: "14px"
  },
  email: {
    ...text,
    fontSize: "12px"
  },
  button: {
    padding: "8px 16px",
    width: "100%",
    textAlign: "center",
    minWidth: 140,
    cursor: "pointer"
  }
};
