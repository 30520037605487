import styled from "styled-components";

const IconContainer = styled.div`
  display: block;
  position: absolute;
  right: ${props => props.right || "56px"};
  top: ${props => props.top || "12px"};
`;

export default IconContainer;
