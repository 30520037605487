import { get } from "lodash";
import PropTypes from "prop-types";

/**
 *
 * @param {object} props
 * @param {string} props.titleRoute Used to navigate the user upon clicking inside variant
 * @param {[array, string]} props.variants Used to render subtitle
 * @returns
 */
const getNavigationProps = ({ titleRoute, title, variants } = {}) => {
  const variant = Array.isArray(variants)
    ? get(
        variants.filter(({ inVariant }) => inVariant),
        "[0]"
      )
    : variants;
  if (!variant || !variant.inVariant) return { title };

  return {
    title,
    titleRoute,
    subtitle: variant.title
  };
};

getNavigationProps.propTypes = {
  titleRoute: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variants: PropTypes.oneOfType([
    PropTypes.shape({
      title: PropTypes.object,
      inVariant: PropTypes.bool
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.object,
        inVariant: PropTypes.bool
      })
    )
  ])
};

export default getNavigationProps;
