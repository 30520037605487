import { slateGray, primary, secondary } from "../../../src/styles/constants";

const style = {
  featureWrapper: {
    margin: "30px 30px 28px 20px"
  },
  featureContainer: {
    margin: "8px 20px 0px 20px"
  },
  featureBox: {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  featureDateContainer: {
    borderBottom: `solid 1px ${secondary}`,
    paddingBottom: "10px"
  },
  featureDayText: {
    fontSize: "16px",
    fontWeight: "bold",
    color: slateGray,
    opacity: "0.9",
    textAlign: "center"
  },
  featureMonthText: {
    fontSize: "12px",
    fontWeight: "bold",
    opacity: "0.9",
    color: slateGray,
    textTransform: "capitalize"
  },
  featureTitleContainer: {
    margin: "0px 20px"
  },
  featureTitleText: {
    fontSize: "12px",
    fontWeight: "bold",
    color: primary
  },
  featureText: {
    fontSize: "12px",
    lineHeight: "1.5em",
    color: slateGray
  },
  featureDivider: {
    height: "0.5px",
    border: "none",
    backgroundColor: "rgba(155, 177, 200, 0.5)",
    width: "311px",
    margin: "0 auto",
    padding: "0"
  },
  featureMultimedia: {
    width: "100%",
    height: "auto",
    marginTop: "14px"
  }
};
export default style;
