import styled from "styled-components";
import { white } from "../../styles/constants";

const NotFoundLink = styled("a")`
  color: ${white};
  font-size: 18px
  text-decoration: underline;
  &:hover {
    text-decoration-line: none
  }
`;

export default NotFoundLink;
