import React, { useState } from "react";
import PropTypes from "prop-types";
import Head from "./head";
import Subhead from "./subhead";
import Body from "./body";

const Tabs = ({ children, headerConfig = [] }) => {
  const initialState = headerConfig[0] ? headerConfig[0].value : "";
  const [selected, setSelected] = useState(initialState);
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { selected });
    }
    return child;
  });

  return (
    <div>
      <Head>
        {headerConfig.map(config =>
          config.show ? (
            <Subhead
              key={config.value}
              data-test="header"
              onClick={() => {
                setSelected(config.value);
                const callback = config.onClick || undefined;
                if (callback) callback(config.value);
              }}
              selected={selected === config.value}
            >
              {config.title}
            </Subhead>
          ) : null
        )}
      </Head>
      <Body>{childrenWithProps}</Body>
    </div>
  );
};
Tabs.propTypes = {
  children: PropTypes.node,
  headerConfig: PropTypes.array
};

export default Tabs;
