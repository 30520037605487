/* eslint-disable no-case-declarations */
import { getStatus } from "turn-shared";

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_CANDIDATES":
      return Object.assign({}, state, {
        candidates: payload.candidates
      });
    case "UPDATE_LOADING":
      return Object.assign({}, state, { loading: payload.loading });
    case "SET_WORKER_IN_CONSENT":
      const newState = Object.assign({}, state, {
        workerInConsent: payload.workerInConsent
      });
      return newState;
    case "SET_WORKER_PROCESSING":
      if (state.workerProcessing !== payload.workerProcessing) {
        return Object.assign({}, state, {
          workerProcessing: payload.workerProcessing
        });
      }
      break;
    case "CLEAR_WORKERS":
      return Object.assign({}, state, {
        workerInConsent: null,
        workerProcessing: null
      });
    case "SELECT_ALL":
      const candidatesInConsider = state.candidates.filter(
        candidate => getStatus(candidate.status) === "consider"
      );
      return Object.assign({}, state, {
        selectedCandidates: candidatesInConsider,
        isSelectingAll: true
      });
    case "CLEAR_SELECTED":
      return Object.assign({}, state, {
        selectedCandidates: [],
        isSelectingAll: false
      });
    case "ADD_SELECTED":
      return Object.assign({}, state, {
        selectedCandidates: [
          ...state.selectedCandidates,
          ...[payload.candidate]
        ]
      });
    case "REMOVE_SELECTED":
      return Object.assign({}, state, {
        selectedCandidates: state.selectedCandidates.filter(
          candidate => candidate.id !== payload.id
        )
      });
    case "DOWNLOADING":
      return Object.assign({}, state, { isDownloading: payload.downloading });
    case "CREATING":
      return Object.assign({}, state, { isCreating: payload.creating });
    case "SHOW_CSV_MODAL":
      return Object.assign({}, state, { showCSVModal: payload.showCSVModal });
    default:
      return state;
  }
};
