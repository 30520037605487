import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faCogs,
  faShoppingCart,
  faUserPlus
} from "@fortawesome/pro-solid-svg-icons";

import { WorkerSettingsStyles as styles } from "../../styles";
import { WorkerSettingsSection } from "./section";
import TeamMembers from "./TeamMembers";
import { SubscriptionSettings } from "./Subscription";
import Title from "../Title";
import ActionsSelect from "../../components/ActionsSelect";
import withContext from "../../contextAPI/context-HOC";
import LogoLoader from "../LogoLoader";
import { primary } from "../../styles/constants";

export class WorkerSettings extends Component {
  static propTypes = {
    open: PropTypes.bool,
    model: PropTypes.object
  };

  renderContent = (content, key) => {
    if (content.title === "Team Members") {
      return <TeamMembers {...content} key={key} model={this.props.model} />;
    }
    if (content.type === "subscription") {
      return (
        <SubscriptionSettings {...content} key={key} model={this.props.model} />
      );
    }
    return (
      <WorkerSettingsSection {...content} key={key} model={this.props.model} />
    );
  };

  renderLoader = () => {
    return !this.props.model.ready ? (
      <div style={styles.loader}>
        <LogoLoader />
      </div>
    ) : null;
  };

  renderTeamSection = () => {
    const { teamSection } = this.props.model;
    if (_.isEmpty(teamSection)) return null;
    return (
      <div id="teamMemberContainer" style={styles.teamSectionContainer}>
        <ActionsSelect
          type="button"
          onClick={this.props.model.toggleOpenAddTeam}
          containerStyle={styles.teamAction}
          title={
            <>
              <FontAwesomeIcon
                icon={faUserPlus}
                style={{ marginRight: "7px" }}
              />
              Add Member
            </>
          }
        />
        <div style={styles.titleContainer}>
          <FontAwesomeIcon style={styles.icon} icon={faUsers} />
          <Title size="16px" margin="0" color={primary}>
            Team Members
          </Title>
        </div>
        {teamSection.map((content, key) => this.renderContent(content, key))}
      </div>
    );
  };

  renderIntegrationsSection = () => {
    const { integrationsSection } = this.props.model;
    if (_.isEmpty(integrationsSection)) return null;
    return (
      <div style={styles.sectionContainer}>
        <div style={styles.titleContainer}>
          <FontAwesomeIcon style={styles.icon} icon={faCogs} />
          <Title size="16px" margin="0" color={primary}>
            Integrations
          </Title>
        </div>
        {integrationsSection.map((content, key) =>
          this.renderContent(content, key)
        )}
      </div>
    );
  };

  renderPackagesAndPaymentsSection = () => {
    const { packagesAndPaymentsSection } = this.props.model;
    const {
      context: {
        features: { REACT_APP_ENABLE_PAYMENT_SETTINGS = false } = {}
      } = {}
    } = this.props;
    if (_.isEmpty(packagesAndPaymentsSection)) return null;
    return (
      <div style={styles.sectionContainer}>
        <div style={styles.titleContainer}>
          <FontAwesomeIcon style={styles.icon} icon={faShoppingCart} />
          <Title size="16px" margin="0" color={primary}>
            {REACT_APP_ENABLE_PAYMENT_SETTINGS
              ? "Packages & Payments"
              : "Packages"}
          </Title>
        </div>
        {packagesAndPaymentsSection.map((content, key) =>
          this.renderContent(content, key)
        )}
        {this.renderCurrentInvoiceSection()}
      </div>
    );
  };

  renderCurrentInvoiceSection = () => {
    let { invoiceSection } = this.props.model;
    if (_.isEmpty(invoiceSection)) return null;
    return (
      <>
        {invoiceSection.map((content, key) => this.renderContent(content, key))}
      </>
    );
  };

  renderDefaultsSection = () => {
    const { defaultsSection } = this.props.model;
    if (_.isEmpty(defaultsSection)) return null;
    return (
      <div style={styles.sectionContainer}>
        <div style={styles.titleContainer}>
          <FontAwesomeIcon style={styles.icon} icon={faCogs} />
          <Title size="16px" margin="0" color={primary}>
            Defaults
          </Title>
        </div>
        {defaultsSection.map((content, key) =>
          this.renderContent(content, key)
        )}
      </div>
    );
  };

  setAlert() {
    toastr.options = {
      closeButton: true,
      positionClass: "toast-bottom-right",
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "10000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut"
    };

    if (this.props.model.exception) {
      toastr.error(this.props.model.exception);
      setTimeout(() => this.props.model.setException(null), 5000);
    }

    if (this.props.model.successMessage) {
      toastr.success(this.props.model.successMessage);
      setTimeout(() => this.props.model.setSuccessMessage(null), 5000);
    }
  }

  render() {
    return (
      <div style={styles.container}>
        {this.renderLoader()}
        {this.renderTeamSection()}
        {this.renderIntegrationsSection()}
        {this.renderPackagesAndPaymentsSection()}
        {this.renderDefaultsSection()}
        {this.setAlert()}
      </div>
    );
  }
}

export default withContext(observer(WorkerSettings));
