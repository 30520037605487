import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";

import { DateInput } from "./DateInput";

export * from "./DateInput";

const DateSelect = observer(
  class DateSelect extends Component {
    static propTypes = {
      model: PropTypes.object,
      looksDisabled: PropTypes.bool
    };

    onChange = ({ year, month, day }) => {
      const { model } = this.props;

      model.setYearOfBirth(year);
      model.setMonthOfBirth(month);
      model.setDayOfBirth(day);
    };

    render() {
      const { model, looksDisabled } = this.props;
      if (!model) {
        return null;
      }

      const { yearOfBirth: year, monthOfBirth: month, dayOfBirth: day } = model;
      return (
        <DateInput
          value={{ year, month, day }}
          onChange={this.onChange}
          looksDisabled={looksDisabled}
        />
      );
    }
  }
);

export default DateSelect;
