import { observable, action, decorate } from "mobx";
import history from "../history";
import { turnAPI } from "../services";

export default class PermissionModel {
  loading = true;
  GLBA = [];
  DPPA = [];
  selectedGLBA = 0;
  selectedDPPA = 0;
  agree = false;

  selectGLBA = i => {
    this.selectedGLBA = this.GLBA.filter(g => g.id === i)[0].id;
  };

  selectDPPA = i => {
    this.selectedDPPA = this.DPPA.filter(g => g.id === i)[0].id;
  };

  checkAgreement = () => {
    this.agree = !this.agree;
  };

  getTerms = async auth => {
    const response = await turnAPI.setToken(auth.getToken()).getTerms();
    this.loading = false;
    switch (response.status) {
      case 200: {
        const { data } = response;
        const DPPA = [];
        const GLBA = [];
        data
          .sort((termA, termB) => termA.position > termB.position)
          .map(term => {
            if (term.category === "DPPA") {
              DPPA.push(term);
            }
            if (term.category === "GLBA") {
              GLBA.push(term);
            }
            return term;
          });
        this.DPPA = [...DPPA];
        this.GLBA = [...GLBA];
        break;
      }
      case 401:
        auth.logout();
        break;
      default:
        break;
    }
  };

  send = async auth => {
    const response = await turnAPI.setToken(auth.getToken()).consent([
      {
        id: this.selectedDPPA
      },
      {
        id: this.selectedGLBA
      }
    ]);
    this.loading = false;
    switch (response.status) {
      case 200:
        history.replace("/");
        break;
      case 401:
        auth.logout();
        break;
      default:
        break;
    }
  };
}

decorate(PermissionModel, {
  loading: observable,
  GLBA: observable,
  DPPA: observable,
  selectedGLBA: observable,
  selectedDPPA: observable,
  agree: observable,
  selectGLBA: action,
  selectDPPA: action,
  checkAgreement: action
});
