import React, { Component } from "react";
import PropTypes from "prop-types";
import { ModalStyles } from "../../styles";
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import Modal from "../../components/Modal/modal";
import { turnAPI } from "../../services";
import DotsLoader from "../../components/DotsLoader";
import { WorkerStyles as styles } from "../../styles";
import UnidentifiedUserIcon from "../../icons/svgr/UnidentifiedUserIcon";
import { ContactLink, Description, TextContainer } from "./styled";

class RecheckModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    authorizedAction: PropTypes.bool,
    auth: PropTypes.object,
    worker: PropTypes.object,
    handleClose: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      ready: true
    };
  }

  setReady = ready => {
    if (typeof ready !== "boolean") return;
    this.setState({ ready });
  };

  handleResponse = (response = {}) => {
    this.props.handleClose();
    switch (response.status) {
      case 200:
        window.location.reload();
        break;
      case 307:
        this.props.auth.permission();
        break;
      case 401:
        this.props.auth.logout();
        break;
      default:
        break;
    }
    this.setReady(true);
  };

  handleConfirm = async () => {
    this.setReady(false);
    const response = await turnAPI.recheckWorker(this.props.worker.id, {
      note: ""
    });

    if (!response) return;
    this.handleResponse(response);
  };

  renderLoader = () => {
    return !this.state.ready ? (
      <div style={ModalStyles.resumeLoader}>
        <DotsLoader />
      </div>
    ) : null;
  };

  renderConfirmationModal = () => (
    <Modal
      Modal
      open={this.props.open}
      title={<FormattedMessage {...messages.recheckTitle} />}
      primaryButtonProps={{
        title: "Ok",
        onClick: this.handleConfirm
      }}
      secondaryButtonProps={{
        title: "Cancel",
        onClick: this.props.handleClose
      }}
    >
      {this.renderLoader()}
      <div style={styles.confirmApproval}>
        <FormattedMessage {...messages.recheckConfirmation} />
      </div>
    </Modal>
  );

  renderUnauthorizedModal = () => (
    <Modal
      Modal
      img={<UnidentifiedUserIcon stroke="#7364DB" />}
      open={this.props.open}
      title={<FormattedMessage {...messages.unauthorizedActionTitle} />}
      primaryButtonProps={{
        title: "Close",
        onClick: this.props.handleClose
      }}
    >
      <div style={styles.unauthorizedContainer}>
        <TextContainer>
          <Description false when worker is in any other status>
            {messages.unauthorizedAction.description}
          </Description>
        </TextContainer>
        <TextContainer style={{ width: "368px" }}>
          <p>
            {messages.unauthorizedAction.footer}
            <ContactLink href="mailto:support@turn.ai">
              support@turn.ai
            </ContactLink>
          </p>
        </TextContainer>
      </div>
    </Modal>
  );

  render() {
    return this.renderConfirmationModal();
  }
}

export default RecheckModal;
