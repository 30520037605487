import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import CircularProgress from "material-ui/CircularProgress";
import { CallbackStyles as styles } from "../../styles";
import Title from "../../components/Title";
import auth from "../../authentication/service";

export class Callback extends React.PureComponent {
  state = {
    loading: true
  };

  async componentDidMount() {
    await auth.handleAuthentication();
    setTimeout(() => {
      this.setState({ loading: false });
    }, 10000);
  }

  renderContent() {
    if (this.state.loading) {
      return (
        <div>
          <CircularProgress size={80} thickness={5} color="#7364DB" />
        </div>
      );
    }
    return (
      <div>
        <Title>Access denied, please contact the administrator.</Title>
        <Link to="/">
          <Button style={styles.button} variant="contained" color="primary">
            Login
          </Button>
        </Link>
      </div>
    );
  }

  render() {
    return <div style={styles.container}>{this.renderContent()}</div>;
  }
}

export default Callback;
