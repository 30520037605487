import styled from "styled-components";

const HidePrint = styled.div`
  display: ${props => props.display || "block"};
  @media print {
    display: none;
  }
`;

export default HidePrint;
