const lock = require("./images/lock.png");
const logo = require("./images/logo.png");
const logoWhite = require("./images/logo-white.png");
const partnerPlaceholder = require("./images/partner-logo-placeholder.png");
const profilePlaceholder = require("./images/profile-placeholder.jpg");
const background = require("./images/background.png");
const clearSignature = require("./images/clear-signature.png");
const heart = require("./images/heart-primary.png");
const marker = require("./images/marker.png");
const appStoreBadge = require("./images/app-store-badge.png");
const playStoreBadge = require("./images/play-store-badge.png");
const error = require("./images/error.png");
const errorNotFound = require("./images/error-not-found.png");
const ticketCreated = require("./images/ticket-created.png");
const success = require("./images/success.png");
const noInternet = require("./images/no-internet.png");
const noChecks = require("./images/no-checks.png");
const discoverySuccess = require("./images/discovery-success.png");
const discoveryNoAvailable = require("./images/discovery-no-available.png");
const processing = require("./images/processing.png");
const canadaPhotosRequest = require("./images/canada-photos-request.png");
const canadaPhotosSuccess = require("./images/canada-photos-success.png");
const canadaPhotosError = require("./images/canada-photos-error.png");
const exampleBase64Front = require("./images/example-dl-front.png");
const exampleBase64Back = require("./images/example-dl-back.png");

module.exports = {
  lock,
  logo,
  logoWhite,
  partnerPlaceholder,
  profilePlaceholder,
  background,
  clearSignature,
  heart,
  marker,
  appStoreBadge,
  playStoreBadge,
  error,
  ticketCreated,
  success,
  noInternet,
  noChecks,
  discoverySuccess,
  discoveryNoAvailable,
  processing,
  errorNotFound,
  canadaPhotosRequest,
  canadaPhotosSuccess,
  canadaPhotosError,
  exampleBase64Front,
  exampleBase64Back
};
