import React from "react";
import { PropTypes } from "prop-types";
import Divider from "material-ui/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DetailContainer from "../../../components/ListDetailView/detail-container";
import Title from "../../../components/Title";
import { rejectedCandidateStyles as styles } from "./styles";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Header = ({ title, expand, toggleExpand }) => (
  <>
    <DetailContainer style={styles.headerStyles} onClick={toggleExpand}>
      <Title align="left" margin="0px" size="16px">
        {title}
      </Title>
      <FontAwesomeIcon
        icon={faChevronDown}
        style={{
          fontSize: "14px",
          transform: expand && "rotate(-180deg)",
          transition: "all 300ms ease-in-out"
        }}
      />
    </DetailContainer>
    {expand && <Divider style={styles.dividerStyles} />}
  </>
);
Header.propTypes = {
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  expand: PropTypes.bool,
  toggleExpand: PropTypes.func
};

export default Header;
