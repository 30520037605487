import React from "react";
import PropTypes from "prop-types";
import Dot from "./Dot";
import { DotsLoaderStyles as styles } from "../../styles";

const DotsLoader = ({ dotsStyle }) => (
  <div style={styles.container}>
    <Dot style={dotsStyle} />
    <Dot style={dotsStyle} />
    <Dot style={dotsStyle} />
  </div>
);

DotsLoader.propTypes = {
  dotsStyle: PropTypes.object
};

export default DotsLoader;
