import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CheckIcon } from "../../icons/check-icon.svg";
import { ReactComponent as NeutralIcon } from "../../icons/neutral-icon.svg";
import { ReactComponent as WrongIcon } from "../../icons/wrong-icon.svg";

const statusIcon = {
  clear: CheckIcon,
  review: NeutralIcon,
  hit: WrongIcon
};

function CandidateCheck({ name, status }) {
  const Icon = statusIcon[status];

  if (!Icon) {
    return null;
  }

  return (
    <li>
      <Icon width="24px" />
      {name}
    </li>
  );
}

CandidateCheck.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string
};

export default CandidateCheck;
