import React, { useState, useEffect } from "react";
import { WorkerSettingsStyles as styles } from "../../../styles";
import Highlighted from "../../../components/Highlighted";
import InputLabel from "@material-ui/core/InputLabel";
import Column from "../../../components/Column";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { PropTypes } from "prop-types";
import Title from "../../Title";
import DotsLoader from "../../DotsLoader";

const Form = ({ handleChange, member, isNew }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setFirstName(member.first_name);
    setLastName(member.last_name);
    setEmail(member.email);
  }, [member]);

  const handleFirstNameChange = value => {
    setFirstName(value);
    handleChange("first_name", value);
  };

  const handleLastNameChange = value => {
    setLastName(value);
    handleChange("last_name", value);
  };

  const handleEmailChange = value => {
    setEmail(value);
    handleChange("email", value);
  };

  const handlePasswordChange = value => {
    setPassword(value);
    handleChange("password", value);
  };

  return (
    <div id="memberModal">
      <div style={styles.row}>
        <Column width="50%">
          <InputLabel htmlFor="name">
            <Highlighted
              style={{ fontSize: "14px", marginBottom: "5px", fontWeight: 500 }}
            >
              First Name
            </Highlighted>
          </InputLabel>
          <TextField
            id="memberFirstName"
            name="name"
            fullWidth
            onChange={e => handleFirstNameChange(e.target.value)}
            value={firstName}
            placeholder="First Name"
            disabled={false}
            style={styles.input}
          />
        </Column>
        <Column width="50%">
          <InputLabel htmlFor="name">
            <Highlighted
              style={{ fontSize: "14px", marginBottom: "5px", fontWeight: 500 }}
            >
              Last Name
            </Highlighted>
          </InputLabel>
          <TextField
            id="memberLastName"
            name="name"
            fullWidth
            onChange={e => handleLastNameChange(e.target.value)}
            value={lastName}
            placeholder="Last Name"
            disabled={false}
            style={styles.input}
          />
        </Column>
      </div>
      <div style={styles.row}>
        {isNew && (
          <Column width="50%">
            <InputLabel htmlFor="email">
              <Highlighted
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  fontWeight: 500
                }}
              >
                Email
              </Highlighted>
            </InputLabel>
            <TextField
              id="memberEmail"
              name="email"
              fullWidth
              onChange={e => handleEmailChange(e.target.value)}
              value={email}
              placeholder="Email"
              disabled={false}
              style={styles.input}
            />
          </Column>
        )}
        {isNew && (
          <Column width="50%">
            <InputLabel htmlFor="password">
              <Highlighted
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  fontWeight: 500
                }}
              >
                Password
              </Highlighted>
            </InputLabel>
            <TextField
              id="memberPassword"
              name="password"
              fullWidth
              onChange={e => handlePasswordChange(e.target.value)}
              value={password}
              placeholder="Password"
              type="password"
              disabled={false}
              style={styles.input}
              inputProps={{ style: { height: "auto" } }}
            />
          </Column>
        )}
      </div>
    </div>
  );
};

Form.propTypes = {
  handleChange: PropTypes.func,
  member: PropTypes.object,
  isNew: PropTypes.bool
};

const MemberModal = ({
  open,
  handleSubmit,
  handleClose,
  member,
  handleChange,
  isNew,
  ready
}) => {
  const actions = [
    <Button
      onMouseDown={handleClose}
      id="close_modal"
      key="0"
      style={styles.cancelButton}
    >
      Cancel
    </Button>,
    <Button
      onMouseDown={() => handleSubmit(isNew)}
      id="submit"
      key="1"
      style={styles.nextButton}
    >
      {!isNew ? "Save" : "Submit"}
    </Button>
  ];

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      autoScrollBodyContent
      actions={actions}
      classes={{
        paper: "turn-modal-paper-add-candidate"
      }}
    >
      {!ready ? (
        <div style={{ ...styles.loader, height: "100%" }}>
          <DotsLoader />
        </div>
      ) : null}
      <DialogTitle>
        <Title align="left" style={{ marginBottom: "3px" }}>
          {!isNew ? "Edit Team Member" : "New Team Member"}
        </Title>
      </DialogTitle>
      <div
        style={{
          height: "1px",
          borderBottom: "1px solid #E5E7EB",
          margin: "0 24px 40px"
        }}
      ></div>
      <DialogContent style={styles.form}>
        <Form handleChange={handleChange} member={member} isNew={isNew} />
      </DialogContent>
      <DialogActions style={styles.footer}>{actions}</DialogActions>
    </Dialog>
  );
};

MemberModal.propTypes = {
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  member: PropTypes.object,
  handleChange: PropTypes.func,
  isNew: PropTypes.bool,
  ready: PropTypes.bool
};

export default MemberModal;
