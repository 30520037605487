/* eslint-disable no-undef */
import { observable, computed, action, decorate } from "mobx";
import {
  BILLING_TYPE_ECOMMERCE,
  ECOMMERCE_TYPE_JOB,
  ECOMMERCE_TYPE_READY,
  ECOMMERCE_TYPE_SCREEN,
  ECOMMERCE_TYPE_SCREEN_READY
} from "../constants";
import moment from "moment";

class TeamMember {
  id;
  partner = {};
  is_fcra;
  is_fcra_e;
  is_fcra_n;
  is_trial;
  is_staff;
  is_beta;
  partner_type = "";
  email = "";
  user_name = "";
  partner_id = "";
  unique_code = "";
  member_id = "";
  billing_type = "";
  ecommerce_type = "";
  account_source = "";
  first_bgc_time = "";
  profile_picture = "";
  role_permissions = {};

  get partnerName() {
    return this.partner.name;
  }

  get getPartnerId() {
    return this.partner_id;
  }

  saveMember() {
    localStorage.setItem(
      "member",
      JSON.stringify({
        id: this.id,
        is_beta: this.is_beta,
        is_fcra: this.is_fcra,
        is_fcra_e: this.is_fcra_e,
        is_fcra_n: this.is_fcra_n,
        is_trial: this.is_trial,
        is_staff: this.is_staff,
        name: this.partner.name,
        email: this.email,
        user_name: this.user_name,
        partner_type: this.partner_type,
        partner_id: this.partner_id,
        unique_code: this.unique_code,
        member_id: this.member_id,
        billing_type: this.billing_type,
        ecommerce_type: this.ecommerce_type,
        account_source: this.account_source,
        first_bgc_time: this.first_bgc_time,
        profile_picture: this.profile_picture,
        role_permissions: this.role_permissions ? this.role_permissions : {}
      })
    );
  }

  removeMember() {
    localStorage.removeItem("member");
  }

  retrieveMember() {
    this.setMember(JSON.parse(localStorage.getItem("member")));
  }

  setMember = member => {
    if (!member) return;
    this.id = member.id;
    this.is_beta = member.is_beta;
    this.is_fcra = member.is_fcra;
    this.is_fcra_e = member.is_fcra_e;
    this.is_fcra_n = member.is_fcra_n;
    this.is_trial = member.is_trial;
    this.is_staff = member.is_staff;
    this.partner_type = member.partner_type;
    this.partner.name = member.name;
    this.email = member.email;
    this.user_name = member.user_name;
    this.partner_id = member.partner_id;
    this.unique_code = member.unique_code;
    this.member_id = member.member_id;
    this.billing_type = member.billing_type;
    this.ecommerce_type = member.ecommerce_type;
    this.account_source = member.account_source;
    this.first_bgc_time = member.first_bgc_time;
    this.profile_picture = member.profile_picture;
    this.role_permissions = member.role_permissions;
  };

  get isEcom() {
    return this.billing_type === BILLING_TYPE_ECOMMERCE;
  }

  get isReadyEcom() {
    return [ECOMMERCE_TYPE_READY, ECOMMERCE_TYPE_JOB].includes(
      this.ecommerce_type
    );
  }

  get isScreenEcom() {
    return this.ecommerce_type === ECOMMERCE_TYPE_SCREEN;
  }

  get isScreenReadyEcom() {
    return this.ecommerce_type === ECOMMERCE_TYPE_SCREEN_READY;
  }

  get isAuthorizedForReady() {
    if (this.isEcom && (this.isReadyEcom || this.isScreenReadyEcom))
      return true;

    return true;
  }

  get isAuthorizedForScreen() {
    if (this.isEcom && this.isReadyEcom) return false;

    return true;
  }

  get canAddCandidates() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_add_candidate") &&
      this.role_permissions.can_add_candidates === true
    );
  }

  get showWorkerList() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_worker_list") &&
      this.role_permissions.show_worker_list === true
    );
  }

  get showHome() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_home") &&
      this.role_permissions.show_home === true
    );
  }

  get showReport() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_report") &&
      this.role_permissions.show_report === true
    );
  }

  get showNotes() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_notes") &&
      this.role_permissions.show_notes === true
    );
  }

  get showContinuousMonitoring() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_continuous_monitoring") &&
      this.role_permissions.show_continuous_monitoring === true
    );
  }

  get canTriggerResumeChecks() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_resume_checks") &&
      this.role_permissions.can_trigger_resume_checks === true
    );
  }

  get canTriggerEnrollment() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_enrollment") &&
      this.role_permissions.can_trigger_enrollment === true
    );
  }

  get canTriggerSupport() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_support") &&
      this.role_permissions.can_trigger_support === true
    );
  }

  get canAddNote() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_add_note") &&
      this.role_permissions.can_add_note === true
    );
  }

  get canTriggerApprove() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_approve") &&
      this.role_permissions.can_trigger_approve === true
    );
  }

  get canTriggerPreadverse() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_preadverse") &&
      this.role_permissions.can_trigger_preadverse === true
    );
  }

  get canTriggerAdverse() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_adverse") &&
      this.role_permissions.can_trigger_adverse === true
    );
  }

  get canTriggerWithdraw() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_withdraw") &&
      this.role_permissions.can_trigger_withdraw === true
    );
  }

  get canTriggerRecheck() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_recheck") &&
      this.role_permissions.can_trigger_recheck === true
    );
  }

  get canTriggerCmMvr() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_cm_mvr") &&
      this.role_permissions.can_trigger_cm_mvr === true
    );
  }

  get canTriggerCmCrim() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_cm_crim") &&
      this.role_permissions.can_trigger_cm_crim === true
    );
  }

  get canTriggerPrint() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("can_trigger_print") &&
      this.role_permissions.can_trigger_print === true
    );
  }

  get canShowProfile() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_my_profile") &&
      this.role_permissions.show_my_profile === true
    );
  }

  get canShowCompanySettings() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_company_settings") &&
      this.role_permissions.show_company_settings === true
    );
  }

  get canShowUserManagement() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_user_management") &&
      this.role_permissions.show_user_management === true
    );
  }

  get canShowIntegrations() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_integrations") &&
      this.role_permissions.show_integrations === true
    );
  }

  get canShowSettingsPayments() {
    if (!this.role_permissions) return true;
    return (
      this.role_permissions.hasOwnProperty("show_settings_payments") &&
      this.role_permissions.show_settings_payments === true
    );
  }

  get hasBeenPartnerFor() {
    // 0 will show only the current month in the csv modal
    const member = JSON.parse(localStorage.getItem("member")) || null;
    if (member) {
      if (!member.first_bgc_time) {
        return 0;
      } else {
        let months = moment()
          .startOf("month")
          .diff(
            moment(member.first_bgc_time, "YYYY-MM-DD").startOf("month"),
            "months"
          );
        if (months <= 0) return 0;
        return months >= 11 ? 11 : months;
      }
    } else {
      return 0;
    }
  }
}

decorate(TeamMember, {
  id: observable,
  partner: observable,
  is_beta: observable,
  is_fcra: observable,
  is_fcra_e: observable,
  is_fcra_n: observable,
  is_trial: observable,
  is_staff: observable,
  partner_type: observable,
  email: observable,
  user_name: observable,
  partner_id: observable,
  unique_code: observable,
  member_id: observable,
  billing_type: observable,
  ecommerce_type: observable,
  account_source: observable,
  first_bgc_time: observable,
  partnerName: computed,
  saveMember: action,
  removeMember: action,
  retrieveMember: action,
  setMember: action,
  isEcom: computed,
  isReadyEcom: computed,
  isScreenEcom: computed,
  isScreenReadyEcom: computed,
  isAuthorizedForReady: computed,
  isAuthorizedForScreen: computed,
  hasBeenPartnerFor: computed,
  profile_picture: observable,
  role_permissions: observable,
  canAddCandidates: computed,
  showWorkerList: computed,
  showHome: computed,
  showReport: computed,
  showNotes: computed,
  showContinuousMonitoring: computed,
  canTriggerResumeChecks: computed,
  canTriggerEnrollment: computed,
  canTriggerSupport: computed,
  canAddNote: computed,
  canTriggerApprove: computed,
  canTriggerPreadverse: computed,
  canTriggerAdverse: computed,
  canTriggerWithdraw: computed,
  canTriggerRecheck: computed,
  canTriggerCmMvr: computed,
  canTriggerCmCrim: computed,
  canTriggerPrint: computed,
  canShowProfile: computed,
  canShowCompanySettings: computed,
  canShowUserManagement: computed,
  canShowIntegrations: computed,
  canShowSettingsPayments: computed
});

const teamMember = new TeamMember();
export default teamMember;
