import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import env from "@mars/heroku-js-runtime-env";
import Avatar from "material-ui/Avatar";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { Hidden } from "@material-ui/core";

import LogoutButton from "../../components/LogoutButton";

export class MenuUI extends PureComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      teamMember: { user_name: "" }
    };
    this.profile = this.props.auth ? this.props.auth.getProfile() : {};
  }

  componentDidMount() {
    const teamMember = JSON.parse(window.localStorage.getItem("member"));
    this.setState(() => ({ teamMember }));
  }

  toggleOpen = () => this.setState(prev => ({ open: !prev.open }));

  goToSettings = () => this.props.history.push("/recruiter/settings");

  render() {
    const { auth } = this.props;

    if (env().REACT_APP_DISABLE_SETTINGS === "true" && _.isObject(auth))
      return <LogoutButton logout={auth.logout} />;

    return _.isObject(auth) && this.profile ? (
      <a
        style={{
          display: "inline-flex",
          cursor: "pointer",
          textDecoration: "none",
          color: "#000"
        }}
        href="/recruiter/settings"
        data-menu="menuAnchor"
      >
        <Avatar
          src={
            this.state.teamMember !== null
              ? this.state.teamMember.profile_picture
              : this.profile
              ? this.profile.picture
              : ""
          }
          size={32}
        />
        <Hidden xsDown>
          <div style={{ display: "inline-flex", verticalAlign: "middle" }}>
            <div
              style={{
                margin: "auto",
                marginRight: 0,
                marginLeft: 10,
                fontSize: 14
              }}
            >
              Hello,{" "}
              <b style={{ textTransform: "capitalize" }}>
                {this.state.teamMember
                  ? this.state.teamMember.user_name.split(" ")[0]
                  : this.profile.given_name}
              </b>
            </div>
          </div>
        </Hidden>
      </a>
    ) : null;
  }
}
export default withRouter(MenuUI);
