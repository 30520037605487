import * as React from "react";

const TurnLogoSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 400 400"
  >
    <path
      d="M368.1 315.7V105.6h-84V315.8c-105.1 0-147.1 0-147.1-42V147.7h21v-42h-21V42.5H52.9v63.2h-42v42h42v126.1c0 84.1 42 84.1 210.2 84.1h126v-42l-21-.2z"
      style={{ fill: "#7364db" }}
    />
  </svg>
);

export default TurnLogoSVG;
