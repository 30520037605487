import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { List, ListItem } from "material-ui/List";
import { CandidateReportStyles as reportStyles } from "../../../styles";
import ListContainer from "../../../components/ListDetailView/list-container";
import messages from "../messages";
import Check from "./Check";
import _ from "lodash";
import PendingReview from "../../../components/PendingReview";
import PendingMVRReview from "../../../components/PendingMVRReview";
import PendingAgeReview from "../../../components/PendingAgeReview";
import { ONE_OFF_CHECKS, REVIEW, PROCESSING, HIT } from "../../../constants";
import ProcessingMVR from "../../../components/ProcessingMVR";
import PanelCollapsible from "../../../components/PanelCollapsible";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner,
  faPauseCircle
} from "@fortawesome/pro-solid-svg-icons";
import { red, periwinkle, green, orange } from "../../../styles/constants";
import withContext from "../../../contextAPI/context-HOC";

export const OneOffChecks = observer(
  class OneOffChecks extends Component {
    static propTypes = {
      items: PropTypes.array,
      worker: PropTypes.object,
      status: PropTypes.string,
      context: PropTypes.object
    };

    renderListItem = (item, index) => (
      <ListItem disabled style={{ padding: "6px 5px" }} key={index}>
        <Check key={index} item={item} />
      </ListItem>
    );

    get color() {
      const { status } = this.props;
      if (status === REVIEW) return orange;
      if (status === PROCESSING) return periwinkle;
      if (status === HIT) return red;
      return green;
    }

    get icon() {
      const { status } = this.props;
      if (status === REVIEW) return faPauseCircle;
      if (status === PROCESSING) return faSpinner;
      if (status === HIT) return faExclamationTriangle;
      return faCheckCircle;
    }

    renderContent = () => {
      const { worker } = this.props;
      if (
        worker &&
        worker.isProcessingMVR &&
        worker.shouldShowCheck(ONE_OFF_CHECKS)
      )
        return <ProcessingMVR message={messages.oneOffChecks} />;

      if (
        worker &&
        worker.isPendingReview &&
        worker.shouldShowCheck(ONE_OFF_CHECKS)
      )
        return <PendingReview message={messages.oneOffChecks} />;

      if (
        worker &&
        worker.isPendingMVRReview &&
        worker.shouldShowCheck(ONE_OFF_CHECKS)
      )
        return <PendingMVRReview message={messages.oneOffChecks} />;
      if (
        worker &&
        worker.isPendingAgeReview &&
        worker.shouldShowCheck(ONE_OFF_CHECKS)
      )
        return <PendingAgeReview message={messages.oneOffChecks} />;
      return (
        <ListContainer>
          <List>
            {this.props.items.map((item, index) =>
              this.renderListItem(item, index)
            )}
          </List>
        </ListContainer>
      );
    };

    render() {
      const { items, context } = this.props;
      const { checks = {} } = context;
      if (_.isEmpty(items) && this.props.status !== REVIEW) return null;
      return (
        <PanelCollapsible
          padding="10px 0"
          title={messages.oneOffChecks}
          titleStyle={reportStyles.title}
          iconStyle={reportStyles.checkedIcon}
          color={this.color}
          icon={this.icon}
          expandAllChecks={checks.expandAllChecks}
          panelProps={{
            style: {
              filter:
                "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
            }
          }}
        >
          {this.renderContent()}
        </PanelCollapsible>
      );
    }
  }
);

export default withContext(OneOffChecks);
