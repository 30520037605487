import { smokeWhite, secondary, primary, white } from "../../styles/constants";

const styles = {
  select: {
    backgroundColor: secondary,
    color: smokeWhite,
    width: "216px",
    paddingLeft: "18px",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "26px",
    cursor: "pointer"
  },
  actionsText: {
    color: smokeWhite,
    fontSize: "16px",
    fontWeight: "bold"
  },
  listItemActionIcon: {
    paddingLeft: "8px",
    color: primary,
    fontSize: "20px",
    width: "40px",
    maxWidth: "40px",
    height: "41px",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  listItemActionText: {
    fontSize: "14px",
    fontWeight: "bold",
    color: primary
  },
  listContainer: {
    backgroundColor: white,
    paddingTop: "12px",
    position: "absolute",
    width: "250px",
    height: "174px",
    borderRadius: "10px",
    boxShadow: "0 13px 20px 0 rgba(0, 0, 0, 0.1)",
    zIndex: 1
  },
  listItemAction: {
    height: "48px",
    cursor: "pointer"
  }
};

export default styles;
