import { defineMessages } from "react-intl";

export default defineMessages({
  headerCompanyName: {
    id: "app.components.candidate.report.headerCompanyName",
    defaultMessage: "Turn Technologies Inc."
  },
  headerCompanyAddress: {
    id: "app.components.candidate.report.headerCompanyAddress",
    defaultMessage: "333 N Green St., Convene, 8th Floor"
  },
  headerCompanyEmail: {
    id: "app.components.candidate.report.headerCompanyEmail",
    defaultMessage: "support@turn.ai"
  },
  headerCompanyNumber: {
    id: "app.components.candidate.report.headerCompanyNumber",
    defaultMessage: "1.888.499 TURN"
  },
  headerCompanyState: {
    id: "app.components.candidate.report.headerCompanyState",
    defaultMessage: "Chicago, IL 60607"
  },
  bgReport: {
    id: "app.components.candidate.report.bgReport",
    defaultMessage: "Background Check Report"
  },
  reportDate: {
    id: "app.components.candidate.report.reportDate",
    defaultMessage: "Report Date"
  },
  orderDate: {
    id: "app.components.candidate.report.orderDate",
    defaultMessage: "Order Date"
  },
  fileNumber: {
    id: "app.components.candidate.report.fileNumber",
    defaultMessage: "File Number"
  },
  applicantInformation: {
    id: "app.components.candidate.report.applicantInformation",
    defaultMessage: "Candidate Information"
  },
  reportSummary: {
    id: "app.components.candidate.report.reportSummary",
    defaultMessage: "Report Summary"
  },
  reportDetail: {
    id: "app.components.candidate.report.reportDetail",
    defaultMessage: "Report Detail"
  },
  fullLegal: {
    id: "app.components.candidate.report.fullLegal",
    defaultMessage: "Full Legal Name"
  },
  dob: {
    id: "app.components.candidate.report.dob",
    defaultMessage: "DOB"
  },
  turnId: {
    id: "app.components.candidate.report.turnId",
    defaultMessage: "Turn ID"
  },
  driverLicence: {
    id: "app.components.candidate.report.driverLicence",
    defaultMessage: "Driver Licence Number"
  },
  ssn: {
    id: "app.components.candidate.report.ssn",
    defaultMessage: "SSN"
  },
  location: {
    id: "app.components.candidate.report.location",
    defaultMessage: "Location"
  },
  phoneNumber: {
    id: "app.components.candidate.report.phoneNumber",
    defaultMessage: "Phone Number"
  },
  email: {
    id: "app.components.candidate.report.email",
    defaultMessage: "Email"
  },
  ssnTrace: {
    id: "app.components.candidate.report.ssnTrace",
    defaultMessage: "SSN Trace"
  },
  addressesFullActiveList: {
    id: "app.components.candidate.report.addressesFullActiveList",
    defaultMessage: "Addresses"
  },
  subheaderAddressesStreet: {
    id: "app.components.candidate.report.subheaderAddressesStreet",
    defaultMessage: "Street"
  },
  subheaderAddressesCity: {
    id: "app.components.candidate.report.subheaderAddressesCity",
    defaultMessage: "City"
  },
  subheaderAddressesZipcode: {
    id: "app.components.candidate.report.subheaderAddressesZipcode",
    defaultMessage: "Zipcode"
  },
  subheaderAddressesState: {
    id: "app.components.candidate.report.subheaderAddressesState",
    defaultMessage: "State"
  },
  subheaderAddresses: {
    id: "app.component.canidadte.report.subheaderAddresses",
    defaultMessage: "Current Address"
  },
  sexOffender: {
    id: "app.components.candidate.report.sexOffender",
    defaultMessage: "Sex Offender Check"
  },
  registeredSex: {
    id: "app.components.candidate.report.registeredSex",
    defaultMessage: "Registered Sex Offender in the Following State(s):"
  },
  criminalRecords: {
    id: "app.components.candidate.report.criminalRecords",
    defaultMessage: "Criminal Records"
  },
  federalRecords: {
    id: "app.components.candidate.report.federalRecords",
    defaultMessage: "Federal Check"
  },
  drugTestingResults: {
    id: "app.components.candidate.report.drugTestingResults",
    defaultMessage: "Drug Test Results"
  },
  caseNumber: {
    id: "app.components.candidate.report.caseNumber",
    defaultMessage: "Case Number"
  },
  chargesFiledDate: {
    id: "app.components.candidate.report.chargesFiledDate",
    defaultMessage: "Charges Filed Date"
  },
  convictionDate: {
    id: "app.components.candidate.report.convictionDate",
    defaultMessage: "Conviction Date"
  },
  districtFederalRecords: {
    id: "app.components.candidate.report.districtFederalRecords",
    defaultMessage: "District"
  },
  offenseDescription: {
    id: "app.components.candidate.report.offenseDescription",
    defaultMessage: "Offense Description"
  },
  county: {
    id: "app.components.candidate.report.county",
    defaultMessage: "County"
  },
  sentence: {
    id: "app.components.candidate.report.sentence",
    defaultMessage: "Sentence"
  },
  plea: {
    id: "app.components.candidate.report.plea",
    defaultMessage: "Plea"
  },
  crimeType: {
    id: "app.components.candidate.report.crimeType",
    defaultMessage: "Crime Type"
  },
  source: {
    id: "app.components.candidate.report.source",
    defaultMessage: "Source"
  },
  offenseDay: {
    id: "app.components.candidate.report.offenseDay",
    defaultMessage: "Offense Day"
  },
  state: {
    id: "app.components.candidate.report.state",
    defaultMessage: "State"
  },
  motorVehicle: {
    id: "app.components.candidate.report.motorVehicle",
    defaultMessage: "Motor Vehicle"
  },
  signature: {
    id: "app.components.candidate.report.watchlist.signature",
    defaultMessage: "Enter Your Signature"
  },
  name: {
    id: "app.components.candidate.report.watchlist.name",
    defaultMessage: "Please type your name to confirm"
  },
  certifyTitle: {
    id: "app.components.candidate.report.certifyTitle",
    defaultMessage:
      "Please take a moment to review possible ADVERSE INFORMATION contained in your background check report."
  },
  certifyFirstPart: {
    id: "app.components.candidate.report.certifyFirstPart",
    defaultMessage:
      "I authorize Turn Technologies, Inc., a consumer reporting agency, to share criminal or other public record information that may be adverse about me to the Hiring Manager at "
  },
  certifySecondPart: {
    id: "app.components.candidate.report.certifySecondPart",
    defaultMessage: " This notice is not an indication of "
  },
  certifyThirdPart: {
    id: "app.components.candidate.report.certifyThirdPart",
    defaultMessage: "'s decision regarding your application status."
  },
  certify: {
    id: "app.components.candidate.report.certify",
    defaultMessage:
      "I certify that this is me, and that the information contained in the present consumer report is up to date, true, valid, accurate, and complete."
  },
  dispute: {
    id: "app.components.candidate.report.dispute",
    defaultMessage:
      "You may dispute any information contained in this report by following the steps "
  },
  disputeSecondOption: {
    id: "app.components.candidate.report.disputeSecondOption",
    defaultMessage: "You may dispute any information contained in this report "
  },
  disposition: {
    id: "app.components.candidate.report.disposition",
    defaultMessage: "Disposition"
  },
  disposition_date: {
    id: "app.components.candidate.report.disposition_date",
    defaultMessage: "Disposition Date"
  },
  charges_filed_date: {
    id: "app.components.candidate.report.charges_filed_date",
    defaultMessage: "Charges Filed Date"
  },
  reviewModalTitle: {
    id: "app.components.candidate.report.reviewModalTitle",
    defaultMessage: "Background Check Report"
  },
  reviewModalContentPart1: {
    id: "app.components.candidate.report.reviewModalContent",
    defaultMessage:
      "Please take a moment to review possible <b>ADVERSE INFORMATION</b> contained in your background check report. Your report will not be complete and shared with "
  },
  reviewModalContentPart2: {
    id: "app.components.candidate.report.reviewModalContent",
    defaultMessage: " until you complete this step."
  },
  clear: {
    id: "app.components.candidate.report.clear",
    defaultMessage: "CLEAR"
  },
  pendingMVR: {
    id: "app.components.candidate.report.mvr",
    defaultMessage: "This check wasn't started due to a non-clear MVR report."
  },
  pendingAge: {
    id: "app.components.candidate.report.mvr",
    defaultMessage:
      "This check wasn't started because the worker does not meet the age requirement."
  },
  track: {
    id: "app.components.candidate.report.track",
    defaultMessage: "Track your progress in our new Worker App"
  },
  close: {
    id: "app.components.candidate.report.close",
    defaultMessage: "You may now close this page."
  },
  processingTitle: {
    id: "app.components.candidate.report.title",
    defaultMessage: "Thank you, your background check is now processing"
  },
  processingTitleSecond: {
    id: "app.components.candidate.report.titleSecond",
    defaultMessage: "Your background check is now processing."
  },
  processingSubTitle: {
    id: "app.components.candidate.report.subtitle",
    defaultMessage:
      "You will receive an email notification once all your checks are complete, so you may review and download a copy of your records."
  },
  processingTitleError: {
    id: "app.components.candidate.report.processingTitleError",
    defaultMessage: "Hold up!"
  },
  processingSubTitleError: {
    id: "app.components.candidate.report.processingSubTitleError",
    defaultMessage:
      "We are unable to process your background check. To resolve this:"
  },
  processingSubTitleErrorStepOne: {
    id: "app.components.candidate.report.processingSubTitleErrorStepOne",
    defaultMessage:
      "Click on the button below to securely verify your identity."
  },
  processingSubTitleErrorStepTwo: {
    id: "app.components.candidate.report.processingSubTitleErrorStepTwo",
    defaultMessage: "Provide your information and submit."
  },
  processingSubTitleErrorButton: {
    id: "app.components.candidate.report.processingSubTitleErrorButton",
    defaultMessage: "Verify your identity"
  },
  processingSubTitleErrorFooterOne: {
    id: "app.components.candidate.report.processingSubTitleErrorFooterOne",
    defaultMessage:
      "If you want to do it at a later time, we just sent you this link to your email. Make sure to check your inbox / spam folder."
  },
  processingSubTitleErrorFooterTwo: {
    id: "app.components.candidate.report.processingSubTitleErrorFooterTwo",
    defaultMessage: "Or contact {SupportLink} for immediate assistance."
  },
  reviewSubTitle: {
    id: "app.components.candidate.report.review",
    defaultMessage:
      "We are unable to process your background check until you confirm your identity. Please check your inbox for an email with the subject line “Identity Verification Required” for further instructions, or contact support@turn.ai for immediate assistance."
  },
  mvr: {
    id: "app.components.candidate.report.mvr",
    defaultMessage: "Motor Vehicle Record"
  },
  redirect: {
    id: "app.components.LandingPage.redirect",
    defaultMessage: "You will be redirected in a few seconds."
  },
  hasCarInsurance: {
    id: "app.components.LandingPage.hasCarInsurance",
    defaultMessage: "Has Insurance?"
  }
});
