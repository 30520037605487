import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import WorkerTable from "../../components/WorkerTable";
import Container, { FixedContainer } from "../../components/Container";
import FilterAndTableContainer from "../../components/FilterAndTableContainer";
import teamMember from "../../models/team-member";
import preventClick from "../../utils/prevent-click";
import ProcessingModal from "./processing-modal";
import CandidatePreviewModal from "./candidate-preview-modal";
import { smokeWhite } from "../../../src/styles/constants";
import { getPageFromRoute } from "../../utils/get-page-from-route";
import DownloadReportUIListItem from "../../components/DownloadReportUIListItem";

import { WorkerFilter } from "../../components/WorkerFilter";
import ActionsSelect from "../../components/ActionsSelect";
import {
  faUserPlus,
  faDownload
  // faShareSquare
} from "@fortawesome/pro-solid-svg-icons";
import Modal from "../../containers/WorkerCheckModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "./styles";
import { DASHBOARD_REPORT } from "../../constants";

import ConsentModal from "./consent-modal";

const ADD_CANDIDATE = "Add Candidate";
const DOWNLOAD = "Export";
// const SHARE = "Share";
const NONE = "none";

const WorkersContainer = observer(
  class WorkersContainer extends Component {
    static propTypes = {
      workers: PropTypes.object,
      fetch: PropTypes.func.isRequired,
      setLimit: PropTypes.func,
      history: PropTypes.object,
      model: PropTypes.object,
      auth: PropTypes.object
    };
    static contextTypes = {
      router: PropTypes.object
    };
    static defaultProps = {
      history: {},
      context: {}
    };

    constructor(props) {
      super(props);
      this.state = {
        workerInConsent: null,
        workerProcessing: null,
        workerPreview: null,
        downloadActionFromPreview: null,
        actions: [ADD_CANDIDATE, DOWNLOAD, NONE], // SHARE
        action: NONE
      };
      this.route = props.history.location
        ? props.history.location.pathname || ""
        : window.location
        ? window.location.pathname || ""
        : "";
    }

    onPageSize = pageSize => {
      this.props.workers.setLimit(pageSize);
      this.props.workers.setOffset(0);
      this.props.fetch();
    };

    onPageNumber = pageNumber => {
      --pageNumber;
      const offset = this.props.workers.limit * pageNumber;
      this.props.workers.setOffset(offset);
      this.props.fetch();
    };

    handleRowClick = worker => {
      if (teamMember.showReport) {
        return;
      }
      if (worker.hasResultAwaitingConsent) {
        this.setState({ workerInConsent: worker });
      } else if (worker.hasResultProcessing) {
        this.setState({ workerProcessing: worker });
      } else {
        const router = this.context.router;
        if (preventClick(router, worker, teamMember.email)) return;
        this.context.router.history.push(`/workers/${worker.turnId}`);
      }
    };

    handleClickCandidatePreview = worker => {
      this.setState({ workerPreview: worker });
    };

    handlePreviewClick = (worker, action) => {
      if (worker.hasResultAwaitingConsent && action === "download") {
        this.setState({
          workerInConsent: worker,
          downloadActionFromPreview: true
        });
      } else if (worker.hasResultAwaitingConsent && action === "viewDetails") {
        this.setState({ workerInConsent: worker });
      } else if (worker.hasResultProcessing && action === "download") {
        this.setState({
          workerProcessing: worker,
          downloadActionFromPreview: true
        });
      } else if (worker.hasResultProcessing && action === "viewDetails") {
        this.setState({ workerProcessing: worker });
      } else {
        const router = this.context.router;
        if (preventClick(router, worker, teamMember.email)) return;
        this.context.router.history.push(`/workers/${worker.turnId}`);
      }
    };

    clearWorkerInConsent = () => {
      this.setState({ workerInConsent: null });
    };

    clearWorkerProcessing = () => {
      this.setState({ workerProcessing: null });
    };

    clearWorkerPreview = () => {
      this.setState({ workerPreview: null });
    };

    orderWorkers = order => {
      this.props.workers.setOrderBy(order);
      this.props.fetch();
    };

    renderTable() {
      const { workers, setLimit } = this.props;

      return (
        <WorkerTable
          model={workers}
          onRowClick={this.handleRowClick}
          onPreviewClick={this.handleClickCandidatePreview}
          onPageSize={this.onPageSize}
          onPageNumber={this.onPageNumber}
          orderWorkers={this.orderWorkers}
          setLimit={setLimit}
          showTable={workers.ready}
          adverseAction={workers.adverseAction}
        />
      );
    }

    get page() {
      return getPageFromRoute(this.route);
    }

    setAction = action => {
      if (!this.state.actions.includes(action)) return;
      this.setState({ action });
    };

    isSelected = action => {
      if (!this.state.actions.includes(action)) return false;
      return action === this.state.action;
    };

    handleClose = () => {
      this.setAction(NONE);
    };

    render() {
      const { state, context } = this;
      const { workers, fetch } = this.props;

      return (
        <>
          <ConsentModal
            consent={!!state.workerInConsent}
            teamMember={teamMember}
            router={context.router}
            worker={state.workerInConsent}
            clearWorkerInConsent={this.clearWorkerInConsent}
            downloadActionFromPreview={state.downloadActionFromPreview}
          />
          <ProcessingModal
            processing={!!state.workerProcessing}
            teamMember={teamMember}
            router={context.router}
            worker={state.workerProcessing}
            clearWorkerProcessing={this.clearWorkerProcessing}
            downloadActionFromPreview={state.downloadActionFromPreview}
          />
          {!!state.workerPreview && (
            <CandidatePreviewModal
              show
              worker={state.workerPreview}
              clearWorkerPreview={this.clearWorkerPreview}
              onPreviewClick={this.handlePreviewClick}
            />
          )}
          <FilterAndTableContainer>
            <FixedContainer>
              <ActionsSelect
                title="Actions"
                actions={this.state.actions}
                action={this.state.action}
                setAction={this.setAction}
                isSelected={this.isSelected}
                ref={this.node}
                menuItems={[
                  {
                    value: ADD_CANDIDATE,
                    item: (
                      <>
                        <FontAwesomeIcon
                          icon={faUserPlus}
                          style={styles.itemIcon}
                        />
                        <span style={styles.item}>{ADD_CANDIDATE}</span>
                      </>
                    )
                  },
                  {
                    value: DOWNLOAD,
                    item: (
                      <>
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={styles.itemIcon}
                        />
                        <span style={styles.item}>{DOWNLOAD}</span>
                      </>
                    )
                  }
                  // ,
                  // {
                  //   value: SHARE,
                  //   item: (
                  //     <>
                  //       <FontAwesomeIcon
                  //         icon={faShareSquare}
                  //         style={styles.itemIcon}
                  //       />
                  //       <span style={styles.item}>{SHARE}</span>
                  //     </>
                  //   )
                  // }
                ]}
                modals={
                  <>
                    {this.isSelected(ADD_CANDIDATE) && (
                      <Modal
                        auth={this.props.auth}
                        workers={this.props.model}
                        fetch={this.props.fetch}
                        autoOpen={true}
                        handleClose={this.handleClose}
                      />
                    )}
                    {this.isSelected(DOWNLOAD) && (
                      <DownloadReportUIListItem
                        reportType={DASHBOARD_REPORT}
                        handleClose={this.handleClose}
                      />
                    )}
                  </>
                }
              />
              <WorkerFilter workers={workers} fetch={fetch} />
            </FixedContainer>
            <Container large background={smokeWhite} margin="0">
              {this.renderTable()}
            </Container>
          </FilterAndTableContainer>
        </>
      );
    }
  }
);

export default WorkersContainer;
