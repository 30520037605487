import { observable, action, computed, decorate, extendObservable } from "mobx";
import { persist } from "mobx-persist";
import env from "@mars/heroku-js-runtime-env";
import _ from "lodash";
import {
  formatPhone,
  formatSSN,
  formatZipcode,
  legalReplace,
  toBoolean,
  validators,
  validateDriversLicense,
  validate_ssn
} from "turn-shared";
import states from "../data/us-states";
import { turnAPI } from "../services/";
import CustomField from "./custom-field";
import { GENDERS } from "../constants";

const FIRST_AND_LAST_NAME_MIN_LENGTH =
  process.env.REACT_APP_FIRST_LAST_NAME_MIN_LENGTH || 2;
const MIDDLE_NAME_MIN_LENGTH =
  process.env.REACT_APP_MIDDLE_NAME_MIN_LENGTH || 1;

const {
  validateEmail,
  validatePhone,
  validateZipcode,
  containsAtSymbol,
  containsNumber,
  validateName,
  validateCustomInput,
  validateAddress
} = validators;

class LandingPageModel {
  candidateConsentEmailId = "";
  firstName = "";
  lastName = "";
  middleName = "";
  hasMiddleName = true;
  hasMiddleNameTimestamp = undefined;
  hasCarInsurance = undefined;
  phone = "";
  ssn = "";
  zipcode = "";
  monthOfBirth = "";
  driversLicenseState = null;
  driversLicenseNumber = null;
  gender = null;
  dayOfBirth = "";
  yearOfBirth = "";
  email = "";
  autofocus = "firstName";
  source = "";
  userId = "";
  referenceId = "";
  fountainId = "";
  doChecks = true;
  candidateId = "";
  candidateUUID = "";
  partnerName = "";
  bypassDupe = null;
  error = "";
  errorState = {};
  warningState = {};
  name = "";
  certify = false;
  certifyTimestamp = undefined;
  certifyTos = false;
  certifyTosTimestamp = undefined;
  certifySms = false;
  certifySmsTimestamp = undefined;
  agreeDisclosure = false;
  agreeDisclosureTimestamp = undefined;
  agreeRights = false;
  agreeRightsTimestamp = undefined;
  agreeStateRights = false;
  agreeStateRightsTimestamp = undefined;
  agreeTerms = false;
  agreeTermsTimestamp = undefined;
  agreeAuthorization = false;
  agreeAuthorizationTimestamp = undefined;
  agreeSigning = false;
  agreeSigningTimestamp = undefined;
  signed = false;
  signedTimestamp = undefined;
  loading = false;
  edit = false;
  consent = false;
  doMVR = false;
  submited = false;
  step = "0";
  finished = false;
  geocodeData = null;
  disclosure = "";
  rights = "";
  stateRights = "";
  terms = "";
  authorization = "";
  getBGCCopy = false;
  requiredFields = [];
  releaseRequiredFields = [
    "last_name",
    "first_name",
    "address",
    "city",
    "zipcode",
    "ssn"
  ];
  oneOffChecks = [];
  nicknames = [];
  touchedFirstName = false;
  touchedLastName = false;
  touchedMiddleName = false;
  touchedSsn = false;
  touchedZipcode = false;
  touchedDriversLicenseState = false;
  touchedDriversLicenseNumber = false;
  touchedSSN = false;
  touchedEmail = false;
  touchedPhone = false;
  freezedEmail = false;
  address = "";
  city = "";
  releaseCertify = false;
  releaseCertifyTimestamp = undefined;
  isInvestorDemoEnv = false;
  showManualForm = false;
  showAutomaticFill = false;
  captchaToken = "";
  captchaReset = "";
  turn_id = "";
  scrollCurrentTabTo = "";
  formLock = false;
  skipValidation = false;

  // Drug Testing
  doDrugTesting = false;
  shouldScheduleDrugTest = false;
  drugTesting = null;

  get full_return_data() {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      middle_name: this.middleName,
      gender: this.gender ? this.gender.substring(0, 1).toUpperCase() : "",
      email: this.email,
      drivers_license_state: this.driversLicenseState,
      drivers_license_number: this.driversLicenseNumber,
      has_car_insurance: this.hasCarInsurance,
      has_middle_name: this.hasMiddleName,
      phone_number: this.phone.replace(/\D/g, ""),
      ssn: this.numberOnlySSN,
      date_of_birth: this.dateOfBirth,
      zipcode: this.zipcode
    };
  }

  get numberOnlySSN() {
    return this.ssn.replace(/\D/g, "");
  }

  get full_confirmation_data() {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      middle_name: this.middleName,
      gender: this.gender || "",
      email: this.email,
      drivers_license_state: this.driversLicenseState,
      drivers_license_number: this.driversLicenseNumber,
      has_car_insurance: this.hasCarInsurance,
      phone_number: this.phone,
      ssn: this.ssn,
      date_of_birth: this.dateOfBirth,
      zipcode: this.zipcode
    };
  }

  get full_release_data() {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      inital: this.initial,
      address: this.address,
      city: this.city,
      state: "PA",
      phone:
        this.full_return_data.phone_number.length === 10
          ? this.full_return_data.phone_number
          : this.full_return_data.phone_number.substring(1, 11),
      zipcode: this.zipcode,
      ssn: this.ssn
    };
  }

  constructor(firebaseDB) {
    this.database = firebaseDB;
    this.setAutofocus("");
  }

  get allowConfirmationPageSubmit() {
    return !!(
      this.agreeSigning &&
      this.signed &&
      this.certify &&
      this.name &&
      this.isValidForm
    );
  }

  get dateOfBirth() {
    if (!this.monthOfBirth && !this.dayOfBirth && !this.yearOfBirth) return "";
    return `${this.monthOfBirth}/${this.dayOfBirth}/${this.yearOfBirth}`;
  }

  get lastFourSSN() {
    return this.numberOnlySSN.slice(-4);
  }

  get requireEmail() {
    if (this.source === "partner_specific_bgc") return true;
    return !!this.email;
  }

  get data() {
    const data = {};
    this.requiredFields.forEach(
      field => (data[field] = this.full_return_data[field])
    );
    const one_off_checks = {};
    this.oneOffChecks.forEach(({ name, custom_fields }) => {
      const key = _.camelCase(name);
      one_off_checks[name] = {};
      custom_fields.forEach(({ input_name }) => {
        const inputKey = _.camelCase(input_name);
        one_off_checks[name][input_name] = this[key][inputKey].value;
      });
    });
    data.one_off_checks = one_off_checks;
    data.signature = this.signature;
    data.signature_image = this.signature_image;
    data.do_checks = this.doChecks;
    data.sms_opt_in = this.certifySms;
    if (this.bypassDupe) data.bypass_dupe = this.bypassDupe;
    if (this.referenceId) data.reference_id = this.referenceId;
    if (this.fountainId) data.fountain_id = this.fountainId;
    if (this.shouldReceiveBGCCopy) data.receive_bgc_copy = this.getBGCCopy;

    if (this.doMVR && this.releaseCertify) {
      if (this.isPAMVR) {
        data.ssn = this.numberOnlySSN;
      }

      data.address = this.address;
      data.metadata = {
        // These fields get overriden by server-visible data
        // so their value is irrelevant
        ip_address: "192.168.0.1",
        user_agent: "Turn Technologies",

        mvr_address: this.address,
        mvr_city: this.city,
        mvr_state: this.driversLicenseState,
        mvr_ssn: this.ssn
      };
    }

    if (env().REACT_APP_FCRA_PARTNER_COMPLIANCE_ENFORCE_METADATA) {
      data.timestamps = {
        welcome: [this.certifyTimestamp],
        summary_of_rights: [this.agreeRightsTimestamp],
        disclosure: [this.agreeDisclosureTimestamp],
        authorization_of_background_investigation: [
          this.agreeAuthorizationTimestamp
        ],
        signature: [this.signedTimestamp]
      };

      if (this.stateRights) {
        data.timestamps["summary_of_state_rights"] = [
          this.agreeStateRightsTimestamp
        ];
      }

      if (this.releaseCertify) {
        data.timestamps["mvr_release"] = [this.releaseCertifyTimestamp];
      }
    }
    return data;
  }

  get formData() {
    return this.data;
  }

  get releaseData() {
    const data = {};
    this.releaseRequiredFields.forEach(
      field => (data[field] = this.full_release_data[field])
    );
    data.signature = this.signature;
    data.signature_image = this.signature_image;
    data.email = this.email;
    data.phone = this.full_release_data.phone;
    data.month_of_birth = this.monthOfBirth;
    data.year_of_birth = this.yearOfBirth;
    data.day_of_birth = this.dayOfBirth;
    data.initial = this.initial;
    data.state = "PA";
    data.driver_license = this.driversLicenseNumber;
    data.ssn = this.ssn;
    return data;
  }

  get confirmationData() {
    const data = {};
    this.requiredFields.forEach(field =>
      field !== "has_middle_name"
        ? (data[field] = this.full_confirmation_data[field])
        : null
    );
    if (this.isPAMVR) {
      this.releaseRequiredFields.forEach(
        field => (data[field] = this.full_release_data[field])
      );
    }
    // this.oneOffChecks.forEach(({ name, custom_fields }) => {
    //   const key = _.camelCase(name);
    //   data[name] = {};
    //   custom_fields.forEach(({input_name}) => {
    //     const inputKey = _.camelCase(input_name);
    //     data[input_name] = this[key][inputKey].value
    //   })
    // })
    return data;
  }

  get fieldAssertions() {
    return {
      first_name: this.validFirstName,
      last_name: this.validLastName,
      middle_name: this.validMiddleName,
      drivers_license_state: this.validLicense,
      drivers_license_number: this.validLicense,
      has_car_insurance: this.hasCarInsurance,
      email: validateEmail(this.email),
      ssn: this.validSsn,
      date_of_birth: this.dayOfBirth && this.monthOfBirth && this.yearOfBirth,
      gender: !!this.gender,
      zipcode: this.validZipcode,
      phone_number: this.validPhone,
      has_middle_name: true
    };
  }

  get fieldReleaseAssertions() {
    return {
      last_name: this.validLastName,
      first_name: this.validFirstName,
      address: this.validAddress,
      city: this.validCity,
      zipcode: this.validZipcode,
      ssn: this.validSsn
    };
  }

  get customFieldsAssertions() {
    const fields = {};
    this.oneOffChecks.forEach(({ name, custom_fields }) => {
      const key = _.camelCase(name);
      fields[name] = {};
      custom_fields.forEach(({ input_name }) => {
        const inputKey = _.camelCase(input_name);
        fields[name][input_name] = validateCustomInput(
          this[key][inputKey].value
        );
      });
    });
    return fields;
  }

  get informationReady() {
    if (!this.certify) return false;
    const errors = this.requiredFields.filter(assertion => {
      return !this.fieldAssertions[assertion];
    });
    const oneOffChecksErrors = [];
    this.oneOffChecks.forEach(({ name, custom_fields }) => {
      custom_fields.forEach(({ input_name }) => {
        if (!this.customFieldsAssertions[name][input_name]) {
          oneOffChecksErrors.push(input_name);
        }
      });
    });
    return errors.length === 0 && oneOffChecksErrors.length === 0;
  }

  get informationReleaseReady() {
    if (!this.releaseCertify) return false;
    const errors = this.releaseRequiredFields.filter(assertion => {
      return !this.fieldReleaseAssertions[assertion];
    });
    return errors.length === 0;
  }

  get showConfirmationScreen() {
    return env().REACT_APP_ENABLE_CONFIRMATION_SCREEN === "true";
  }

  get ready() {
    if (!this.informationReady) return false;
    if (!this.agreeRights) return false;
    if (this.stateRights && !this.agreeStateRights) return false;
    if (this.showConfirmationScreen && !this.agreeAuthorization) return false;
    if (!this.agreeTerms) return false;
    if (!this.agreeDisclosure) return false;
    if (this.isPAMVR && !this.informationReleaseReady) return false;
    if (!this.agreeSigning) return false;
    return true;
  }

  get signature() {
    return this.name;
  }

  get driversLicenseStateName() {
    if (!this.driversLicenseState) return "";
    const us_state = states.find(
      state => state.abbreviation === this.driversLicenseState
    );
    if (!us_state) return "";
    return us_state.name;
  }

  get stateAbbr() {
    if (!this.geocodeData) return null;
    return this.geocodeData.state;
  }

  get shouldReceiveBGCCopy() {
    return this.stateAbbr === "CA";
  }

  get fullName() {
    if (!this.firstName || !this.lastName) return "";
    return this.middleName
      ? `${this.firstName} ${this.middleName} ${this.lastName}`
      : `${this.firstName} ${this.lastName}`;
  }

  get captchaError() {
    return this.error;
  }

  setGeocodeData = async () => {
    if (this.zipcode.length !== 5) {
      this.geocodeData = null;
      return;
    }
    const resp = await turnAPI.getZipCode(this.zipcode);
    if (!resp.ok || resp.status !== 200) return;
    this.geocodeData = resp.data;
  };

  async getLegalData() {
    await this.setReportAuth();
    await this.setSummaryOfRights();
    await this.setSummaryOfStateRights();
    await this.setTerms();
    await this.setAuthorization();
  }

  async setReportAuth() {
    this.database
      .ref("report_auth")
      .once("value")
      .then(snap => {
        this.disclosure = legalReplace(snap.val(), this.partnerName);
      });
  }

  async setSummaryOfRights() {
    this.database
      .ref("summary_of_rights/default")
      .once("value")
      .then(snap => {
        this.rights = legalReplace(snap.val(), this.partnerName);
      });
  }

  async setSummaryOfStateRights() {
    this.database
      .ref(`summary_of_rights/${this.stateAbbr}`)
      .once("value")
      .then(snap => {
        this.stateRights = legalReplace(snap.val(), this.partnerName) || "";
      });
  }

  async setTerms() {
    this.database
      .ref("terms")
      .once("value")
      .then(snap => {
        this.terms = snap.val();
      });
  }

  async setAuthorization() {
    this.database
      .ref("authorization")
      .once("value")
      .then(snap => {
        this.authorization = legalReplace(snap.val(), this.partnerName);
      });
  }

  getNicknames = async () => {
    this.database
      .ref("nicknames_validation")
      .once("value")
      .then(snap => {
        const value = snap.val();
        if (_.isArray(value.nicknames)) this.nicknames = value.nicknames;
      });
  };
  finish = () => {
    window.scrollTo(0, 0);
    this.finished = true;
  };

  setError = error => {
    if (typeof error !== "string") return;
    this.error = error;
  };
  setErrorState = (fieldName, error) => {
    this.errorState[fieldName] = error;
  };
  setWarningState = (fieldName, warning) => {
    this.warningState[fieldName] = warning;
  };

  clearError = () => {
    this.error = null;
  };

  setStep = step => {
    if (typeof step !== "string") return;
    let skip;
    switch (step) {
      case "0":
        break;
      case "1":
        if (this.agreeRights) {
          skip = true;
          this.setStep(parseInt(this.step, 10) > 1 ? "0" : "2");
        }
        break;
      case "2":
        if (this.agreeDisclosure) {
          skip = true;
          this.setStep(parseInt(this.step, 10) > 2 ? "1" : "3");
        }
        break;
      case "3":
        if (!this.stateRights || this.agreeStateRights) {
          skip = true;
          this.setStep(parseInt(this.step, 10) > 2 ? "2" : "4");
        }
        break;
      case "4":
        if (this.showConfirmationScreen && this.agreeAuthorization) {
          skip = true;
          this.setStep(parseInt(this.step, 10) > 2 ? "3" : "5");
        }
        break;
      case "5":
        break;
      default:
        break;
    }
    if (step !== "0") this.clearError();
    if (window) window.scrollTo(0, 0);
    if (!skip) this.step = step;
  };

  setInvestorDemoEnv = value => {
    if (typeof value !== "boolean") return;
    this.isInvestorDemoEnv = true;
    this.setCertify(true);
  };

  setRequiredFields = requiredFields => {
    if (!Array.isArray(requiredFields)) return;
    this.requiredFields = [...requiredFields];
  };
  setOneOffChecks = oneOffChecks => {
    if (!Array.isArray(oneOffChecks)) return;
    this.oneOffChecks = oneOffChecks;
    this.declareOneOffChecks();
  };

  setConsent = consent => {
    if (typeof consent !== "boolean") return;
    this.consent = consent;
  };

  setGetBGCCopy = getBGCCopy => {
    if (typeof getBGCCopy !== "boolean") return;
    this.getBGCCopy = getBGCCopy;
  };

  setEmail = email => {
    if (typeof email !== "string") return;
    this.email = email;
  };

  setAutofocus = input => {
    if (typeof input !== "string") return;
    this.autofocus = input;
  };

  setPartnerName = name => {
    if (typeof name !== "string") return;
    this.partnerName = name;
  };

  setBypass = bool => {
    if (typeof bool !== "boolean") return;
    this.bypassDupe = bool;
  };

  setSource = source => {
    if (typeof source !== "string") return;
    this.source = source;
  };

  setDriversLicenseState = driversLicenseState => {
    if (typeof driversLicenseState !== "string") return;
    if (
      this.isPAMVR &&
      this.driversLicenseState === "PA" &&
      driversLicenseState !== "PA" &&
      this.step === "6"
    ) {
      this.setStep("5");
    }
    this.driversLicenseState = driversLicenseState;
  };

  setDriversLicenseNumber = driversLicenseNumber => {
    if (typeof driversLicenseNumber !== "string") return;
    this.driversLicenseNumber = driversLicenseNumber;
  };

  setUserId = id => {
    if (!id) return;
    this.userId = id;
  };

  setCandidateUUID = uuid => {
    if (typeof uuid !== "string") return;
    this.candidateUUID = uuid;
  };

  setReferenceId = referenceId => {
    if (typeof referenceId !== "string") return;
    this.referenceId = referenceId;
  };

  setFountainId = fountainId => {
    if (typeof fountainId !== "string") return;
    this.fountainId = fountainId;
  };

  setDoChecks = doChecks => {
    if (typeof doChecks !== "boolean") return;
    this.doChecks = doChecks;
  };

  setCandidateId = candidateId => {
    if (typeof candidateId !== "string") return;
    this.candidateId = candidateId;
  };

  setMonthOfBirth = date => {
    if (typeof date !== "number" && typeof date !== "string") return;
    const month = typeof date === "number" ? date.toString() : date;
    this.monthOfBirth = month.replace(/\D/g, "").substring(0, 2);
  };

  setDayOfBirth = date => {
    if (typeof date !== "number" && typeof date !== "string") return;
    const day = typeof date === "number" ? date.toString() : date;
    this.dayOfBirth = day.replace(/\D/g, "").substring(0, 2);
  };

  setYearOfBirth = date => {
    if (typeof date !== "number" && typeof date !== "string") return;
    const year = typeof date === "number" ? date.toString() : date;
    this.yearOfBirth = year.replace(/\D/g, "").substring(0, 4);
  };

  setDateOfBirth = dateOfBirth => {
    if (typeof dateOfBirth !== "string") return;
    const date = dateOfBirth.split("/");
    this.setMonthOfBirth(date[0]);
    this.setDayOfBirth(date[1]);
    this.setYearOfBirth(date[2]);
  };

  setFirstName = firstName => {
    if (typeof firstName !== "string") return;
    this.firstName = firstName;
  };

  setLastName = lastName => {
    if (typeof lastName !== "string") return;
    this.lastName = lastName;
  };

  setMiddleName = middleName => {
    if (typeof middleName !== "string") return;
    this.middleName = middleName;
  };

  setSubmited = submited => {
    if (typeof submited !== "boolean") return;
    this.submited = submited;
  };

  setHasMiddleName = hasMiddleName => {
    if (typeof hasMiddleName !== "boolean") return;
    this.hasMiddleName = hasMiddleName;
  };

  setPhone = phone => {
    if (typeof phone !== "string") return;
    this.phone = formatPhone(phone);
  };

  setSsn = ssn => {
    if (typeof ssn !== "string") return;
    this.ssn = formatSSN(ssn);
  };

  setZipcode = async zipcode => {
    if (typeof zipcode !== "string") return;
    this.zipcode = formatZipcode(zipcode);
    await this.setGeocodeData();
    if (this.database) await this.setSummaryOfStateRights();
  };

  setGender = gender => {
    if (typeof gender !== "string") return;

    gender = gender.toLowerCase();
    gender = Object.values(GENDERS).includes(gender)
      ? gender
      : GENDERS["Non-binary"];
    this.gender = gender;
  };

  setName = name => {
    if (typeof name !== "string") return;
    this.name = name;
  };

  setCertify = certify => {
    if (typeof certify !== "boolean") return;
    this.certify = certify;
    this.certifyTimestamp = new Date().toISOString();
  };

  setCertifyTos = certify => {
    if (typeof certify !== "boolean") return;
    this.certifyTos = certify;
    this.certifyTosTimestamp = new Date().toISOString();
  };

  setCertifySms = certify => {
    if (typeof certify !== "boolean") return;
    this.certifySms = certify;
    this.certifySmsTimestamp = new Date().toISOString();
  };

  setReleaseCertify = releaseCertify => {
    if (typeof releaseCertify !== "boolean") return;
    this.releaseCertify = releaseCertify;
    this.releaseCertifyTimestamp = new Date().toISOString();
  };

  setAgreeDisclosure = agreeDisclosure => {
    if (typeof agreeDisclosure !== "boolean") return;
    this.agreeDisclosure = agreeDisclosure;
    this.agreeDisclosureTimestamp = new Date().toISOString();
  };

  setAgreeAuthorization = agreeAuthorization => {
    if (typeof agreeAuthorization !== "boolean") return;
    this.agreeAuthorization = agreeAuthorization;
    this.agreeAuthorizationTimestamp = new Date().toISOString();
  };

  setAgreeRights = agreeRights => {
    if (typeof agreeRights !== "boolean") return;
    this.agreeRights = agreeRights;
    this.agreeRightsTimestamp = new Date().toISOString();
  };
  // todo test
  setAgreeStateRights = agreeStateRights => {
    if (typeof agreeStateRights !== "boolean") return;
    this.agreeStateRights = agreeStateRights;
    this.agreeStateRightsTimestamp = new Date().toISOString();
  };

  setAgreeTerms = agreeTerms => {
    if (typeof agreeTerms !== "boolean") return;
    this.agreeTerms = agreeTerms;
  };

  setAgreeSigning = agreeSigning => {
    if (typeof agreeSigning !== "boolean") return;
    this.agreeSigning = agreeSigning;
  };

  setSigned = signed => {
    if (typeof signed !== "boolean") return;
    this.signed = signed;
    this.signedTimestamp = new Date().toISOString();
  };

  setLoading = loading => {
    if (typeof loading !== "boolean") return;
    this.loading = loading;
  };

  setDoMVR = doMVR => {
    if (typeof doMVR !== "boolean") return;
    this.doMVR = doMVR;
  };

  setEdit = edit => {
    if (typeof edit !== "boolean") return;
    this.edit = edit;
  };

  setAddress = address => {
    if (typeof address !== "string") return;
    this.address = address;
  };

  setCity = city => {
    if (typeof city !== "string") return;
    this.city = city;
  };

  setHasCarInsurance = bool => {
    if (bool === "true" || bool === "false") this.hasCarInsurance = bool;
  };

  setShowManualForm = bool => {
    this.showManualForm = bool;
  };

  setShowAutomaticFill = bool => {
    this.showAutomaticFill = bool;
  };

  storeImage = dataUrl => {
    this.signature_image = dataUrl;
  };

  setFormData = (query = {}, do_mvr = false, requiredFields = []) => {
    const has_middle_name =
      (!_.isEmpty(query.middle_name) && !toBoolean(query.no_middle_name)) ||
      !_.isEmpty(query.middle_name);
    let doChecks = true;
    if (typeof query.do_checks !== "undefined") {
      doChecks = toBoolean(query.do_checks);
    }
    this.setPhone(query.phone_number);
    this.setSsn(query.ssn);
    this.setFirstName(query.first_name);
    this.setLastName(query.last_name);
    if (has_middle_name) {
      this.setHasMiddleName(!has_middle_name);
    } else {
      this.setHasMiddleName(has_middle_name);
    }

    this.setHasCarInsurance(query.has_car_insurance);
    this.setDateOfBirth(query.date_of_birth);
    this.setGender(query.gender);
    this.setZipcode(query.zipcode);
    this.setReferenceId(query.reference_id);
    this.setFountainId(query.fountain_id);
    this.setDoChecks(doChecks);
    if (has_middle_name) this.setMiddleName(query.middle_name);
    if (do_mvr) this.setDriversLicenseState(query.drivers_license_state);
    if (do_mvr) this.setDriversLicenseNumber(query.drivers_license_number);
    let func;
    requiredFields.forEach(field => {
      func = this[
        _.camelCase(`touch_${field === "phone_number" ? "phone" : field}`)
      ];
      if (typeof func === "function" && !!query[field]) func();
    });
  };

  setData = (data = {}) => {
    this.setDateOfBirth(data.date_of_birth);
    this.setEmail(data.email);
    this.setUserId(data.user_id);
    this.setSource(data.source);
    this.setPartnerName(data.partner_name);
    this.setDoMVR(data.do_mvr);
    this.setFormData(data, data.do_mvr);
    this.setHasCarInsurance(data.has_car_insurance);
  };

  setTurnId = id => {
    if (typeof id !== "string") return;
    this.turn_id = id;
  };

  touchFirstName = () => {
    if (!this.touchedFirstName) {
      this.touchedFirstName = true;
      this.validateFirstName();
    }
  };
  touchLastName = () => {
    if (!this.touchedLastName) {
      this.touchedLastName = true;
      this.validateLastName();
    }
  };
  touchMiddleName = () => {
    if (!this.touchedMiddleName) {
      this.touchedMiddleName = true;
      this.validateMiddleName();
    }
  };
  touchSsn = () => {
    if (!this.touchedSsn) {
      this.touchedSsn = true;
      this.validateSsn();
    }
  };
  touchZipcode = () => {
    if (!this.touchedZipcode) {
      this.touchedZipcode = true;
      this.validateZipcode();
    }
  };
  touchPhone = () => {
    if (!this.touchedPhone) {
      this.touchedPhone = true;
      this.validatePhone();
    }
  };
  touchEmail = () => {
    if (!this.touchedEmail) {
      this.touchedEmail = true;
      this.validateEmail();
    }
  };
  touchDriversLicenseState = () => (this.touchedDriversLicenseState = true);
  touchDriversLicenseNumber = () => {
    if (!this.touchedDriversLicenseNumber) {
      this.touchedDriversLicenseNumber = true;
      this.validateLicense();
    }
  };

  freezeEmail = () => {
    if (!this.freezedEmail) {
      this.freezedEmail = true;
    }
  };
  unfreezeEmail = () => {
    if (this.freezedEmail) {
      this.freezedEmail = false;
    }
  };

  // Sanitize
  setValidNames = () => {
    this.firstName = this.replaceInvalidCharachters(this.firstName);
    this.middleName = this.replaceInvalidCharachters(this.middleName);
    this.lastName = this.replaceInvalidCharachters(this.lastName);
  };

  setCaptchaToken = token => {
    if (typeof token !== "string") return;
    this.captchaToken = token;
  };

  setCaptchaReset = resetString => {
    if (typeof resetString !== "string") return;
    this.captchaReset = resetString;
  };

  setScrollCurrentTabTo = value => {
    if (typeof value !== "string") return;
    this.scrollCurrentTabTo = value;
  };

  setFormLock = bool => {
    if (typeof bool !== "boolean") return;
    this.formLock = bool;
  };

  setSkipValidation = bool => {
    if (typeof bool !== "boolean") return;
    this.skipValidation = bool;
  };

  replaceInvalidCharachters = value => {
    var newValue = this.replaceApostrophes(value);
    newValue = this.replaceAccents(newValue);
    newValue = this.replaceSlash(newValue);

    return newValue;
  };

  replaceApostrophes = value => value.replace(/`|’/g, "'");

  replaceAccents = value =>
    value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  replaceSlash = value => value.replace(/\//g, " ");

  // VALIDATION
  // Names
  validateName(key) {
    const str = this[key].trim();

    let error = "";

    if (key === "middleName" && this.hasMiddleName) {
      error = containsNumber(str)
        ? "should not contain numbers"
        : containsAtSymbol(str)
        ? "should not contain @ symbol"
        : undefined;
    } else if (key === "middleName") {
      error =
        str.length < MIDDLE_NAME_MIN_LENGTH
          ? `Should not contain less than ${MIDDLE_NAME_MIN_LENGTH} character`
          : undefined;
    } else {
      error =
        str.length === 0
          ? "required"
          : containsNumber(str)
          ? "should not contain numbers"
          : containsAtSymbol(str)
          ? "should not contain @ symbol"
          : str.length < FIRST_AND_LAST_NAME_MIN_LENGTH
          ? `Should not contain less than ${FIRST_AND_LAST_NAME_MIN_LENGTH} characters`
          : undefined;
    }

    const warning = this.nicknames.some(val =>
      _.isString(val) && _.isString(str)
        ? val.toUpperCase() === str.toUpperCase()
        : false
    )
      ? "This looks like a nickname. Please use your legal name."
      : undefined;
    this.setWarningState(key, warning);
    this.setErrorState(key, error);
    return error;
  }
  validateFirstName = () => this.validateName("firstName");
  validateLastName = () => this.validateName("lastName");
  validateMiddleName = () => this.validateName("middleName");

  get validFirstName() {
    return validateName(this.firstName);
  }
  get invalidFirstName() {
    return this.touchedFirstName && !this.validFirstName;
  }

  get validLastName() {
    return validateName(this.lastName);
  }
  get invalidLastName() {
    return this.touchedLastName && !this.validLastName;
  }

  get validMiddleName() {
    if (this.hasMiddleName) return true;
    return validateName(this.middleName);
  }
  get invalidMiddleName() {
    return !this.touchedMiddleName
      ? false
      : this.hasMiddleName
      ? !this.validMiddleName
      : false;
  }

  get initial() {
    if (!this.hasMiddleName) return "";
    return this.middleName.charAt(0);
  }

  //Address Release Form
  validateUserAddress(key) {
    const str = this[key].trim();
    const error =
      str.length === 0
        ? "required"
        : containsAtSymbol(str)
        ? "should not contain @ symbol"
        : undefined;
    this.setErrorState(key, error);
    return error;
  }
  validateAddress = () => this.validateUserAddress("address");

  get validAddress() {
    return validateAddress(this.address);
  }
  get invalidAddress() {
    return this.touchedAddress && !this.validAddress;
  }

  //City Release Form
  get validCity() {
    return validateName(this.city);
  }
  get invalidCity() {
    return this.touchedCity && !this.validCity;
  }

  validateCity = () => this.validateName("city");

  // Zipcode
  get validZipcode() {
    return validateZipcode(this.zipcode);
  }
  get invalidZipcode() {
    return this.touchedZipcode && !this.validZipcode;
  }
  validateZipcode = () => {
    const error = this.invalidZipcode ? "must be 5 digits" : undefined;
    this.setErrorState("zipcode", error);
  };

  // Email
  get validEmail() {
    return validateEmail(this.email);
  }
  get invalidEmail() {
    return this.touchedEmail && !this.validEmail;
  }
  validateEmail = () => {
    const error = this.invalidEmail ? "must be correctly formatted" : undefined;
    this.setErrorState("email", error);
  };
  get disableEmail() {
    return !!this.email && !this.touchedEmail;
  }

  // SSN
  get validSsn() {
    return validate_ssn(this.ssn);
  }
  get invalidSsn() {
    return this.touchedSsn && !this.validSsn;
  }
  validateSsn = () => {
    const error = this.invalidSsn ? "Invalid SSN" : undefined;
    this.setErrorState("ssn", error);
  };

  // Phone
  get validPhone() {
    return validatePhone(this.phone);
  }
  get invalidPhone() {
    return this.touchedPhone && !this.validPhone;
  }
  validatePhone = () => {
    const error = this.invalidPhone
      ? "must be 10 digits or 11 (1 prefixed)"
      : undefined;
    this.setErrorState("phone", error);
  };

  // License
  get hasLicenseStateAndNumber() {
    return !!this.driversLicenseState && !!this.driversLicenseNumber;
  }
  get invalidLicenseNumber() {
    if (!this.touchedDriversLicenseNumber) return false;
    const result = validateDriversLicense(
      this.driversLicenseNumber,
      this.driversLicenseState
    );
    return !(result || {}).valid;
  }
  get validatedDriversLicense() {
    if (!this.hasLicenseStateAndNumber) return false;
    const result = validateDriversLicense(
      this.driversLicenseNumber,
      this.driversLicenseState
    );
    return (result || {}).valid;
  }
  get validLicense() {
    if (!this.doMVR) return true;
    return this.validatedDriversLicense;
  }

  get isValidForm() {
    return (
      !this.errorState.firstName &&
      !this.errorState.lastName &&
      !this.errorState.email &&
      !this.errorState.phone &&
      !this.errorState.ssn &&
      !this.errorState.zipcode &&
      !this.errorState.driversLicenseNumber
    );
  }

  get isPAMVR() {
    return this.driversLicenseState === "PA" && this.doMVR;
  }

  validateLicense = () => {
    const fieldName = "driversLicenseNumber";
    if (!this.touchedDriversLicenseNumber) return;
    if (!this.hasLicenseStateAndNumber) {
      this.setErrorState(fieldName, "required");
    } else if (!this.validatedDriversLicense) {
      this.setErrorState(
        fieldName,
        `incorrectly formatted for the state of ${this.driversLicenseStateName}.
        Please try again.`
      );
    } else {
      this.setErrorState(fieldName);
    }
  };
  declareOneOffChecks = () => {
    if (_.isEmpty(this.oneOffChecks)) return;
    this.oneOffChecks.forEach(({ name, custom_fields }) => {
      if (!custom_fields) return;
      if (_.isEmpty(custom_fields)) return;
      const key = _.camelCase(name);
      this.declareOneOffCheck(key);
      this.declareCustomFields(key, custom_fields);
    });
  };
  declareOneOffCheck = key => {
    if (this.hasOwnProperty(_.camelCase(key))) return;
    extendObservable(this, { [key]: {} }, { [key]: observable });
  };
  declareCustomFields = (key, customFields) => {
    if (!this.hasOwnProperty(key)) return;
    if (!_.isArray(customFields)) return;
    if (_.isEmpty(customFields)) return;
    customFields.forEach(customField =>
      this.declareCustomField(key, customField)
    );
  };
  declareCustomField = (key, customField) => {
    if (!_.isObject(this[key])) return;
    const model = new CustomField(customField, this.setErrorState);
    const inputKey = _.camelCase(customField.input_name);
    extendObservable(
      this[key],
      {
        [inputKey]: model
      },
      {
        [inputKey]: observable
      }
    );
  };
}

persist({
  candidateConsentEmailId: true,
  firstName: true,
  lastName: true,
  middleName: true,
  hasMiddleName: true,
  phone: true,
  ssn: true,
  zipcode: true,
  monthOfBirth: true,
  driversLicenseState: true,
  driversLicenseNumber: true,
  gender: true,
  dayOfBirth: true,
  yearOfBirth: true,
  email: true,
  name: true,
  address: true,
  city: true,
  step: true,
  agreeDisclosure: true,
  agreeDisclosureTimestamp: true,
  agreeRights: true,
  agreeRightsTimestamp: true,
  agreeStateRights: true,
  agreeStateRightsTimestamp: true,
  agreeTerms: true,
  agreeTermsTimestamp: true,
  agreeAuthorization: true,
  agreeAuthorizationTimestamp: true,
  certify: true,
  getBGCCopy: true,
  stateRights: true,
  showConfirmationScreen: true,
  showManualForm: true,
  showAutomaticFill: true
})(LandingPageModel);

decorate(LandingPageModel, {
  // observable
  candidateConsentEmailId: observable,
  firstName: observable,
  lastName: observable,
  middleName: observable,
  hasMiddleName: observable,
  phone: observable,
  ssn: observable,
  zipcode: observable,
  monthOfBirth: observable,
  driversLicenseState: observable,
  driversLicenseNumber: observable,
  gender: observable,
  dayOfBirth: observable,
  yearOfBirth: observable,
  email: observable,
  autofocus: observable,
  source: observable,
  userId: observable,
  referenceId: observable,
  fountainId: observable,
  doChecks: observable,
  candidateId: observable,
  partnerName: observable,
  bypassDupe: observable,
  error: observable,
  errorState: observable,
  warningState: observable,
  name: observable,
  certify: observable,
  certifyTos: observable,
  certifySms: observable,
  agreeDisclosure: observable,
  agreeRights: observable,
  agreeStateRights: observable,
  agreeTerms: observable,
  agreeAuthorization: observable,
  agreeSigning: observable,
  signed: observable,
  displayReleaseForm: observable,
  loading: observable,
  edit: observable,
  consent: observable,
  doMVR: observable,
  submited: observable,
  step: observable,
  finished: observable,
  geocodeData: observable,
  disclosure: observable,
  rights: observable,
  stateRights: observable,
  terms: observable,
  authorization: observable,
  getBGCCopy: observable,
  nicknames: observable,
  oneOffChecks: observable,
  requiredFields: observable,
  address: observable,
  city: observable,
  touchedFirstName: observable,
  touchedLastName: observable,
  touchedMiddleName: observable,
  touchedZipcode: observable,
  touchedDriversLicenseNumber: observable,
  touchedSsn: observable,
  touchedEmail: observable,
  touchedPhone: observable,
  touchedAddress: observable,
  touchedCity: observable,
  hasCarInsurance: observable,
  freezedEmail: observable,
  releaseCertify: observable,
  isInvestorDemoEnv: observable,
  showManualForm: observable,
  showAutomaticFill: observable,
  captchaToken: observable,
  captchaReset: observable,
  turn_id: observable,
  scrollCurrentTabTo: observable,
  formLock: observable,
  skipValidation: observable,
  doDrugTesting: observable,
  shouldScheduleDrugTest: observable,
  drugTesting: observable,

  // computed
  allowConfirmationPageSubmit: computed,
  dateOfBirth: computed,
  lastFourSSN: computed,
  numberOnlySSN: computed,
  requireEmail: computed,
  full_return_data: computed,
  data: computed,
  full_confirmation_data: computed,
  confirmationData: computed,
  informationReady: computed,
  informationReleaseReady: computed,
  showConfirmationScreen: computed,
  ready: computed,
  signature: computed,
  driversLicenseStateName: computed,
  stateAbbr: computed,
  shouldReceiveBGCCopy: computed,
  fullName: computed,
  fieldAssertions: computed,

  validFirstName: computed,
  invalidFirstName: computed,
  validLastName: computed,
  invalidLastName: computed,
  validMiddleName: computed,
  invalidMiddleName: computed,
  validSsn: computed,
  invalidSsn: computed,
  validZipcode: computed,
  invalidZipcode: computed,
  validPhone: computed,
  invalidPhone: computed,
  invalidLicenseNumber: computed,
  hasLicenseStateAndNumber: computed,
  validatedDriversLicense: computed,
  validLicense: computed,
  isPAMVR: computed,
  releaseData: computed,
  initial: computed,
  captchaError: computed,

  // action
  setGeocodeData: action,
  setReportAuth: action,
  setSummaryOfRights: action,
  setSummaryOfStateRights: action,
  setTerms: action,
  setAuthorization: action,
  finish: action,
  setError: action,
  setErrorState: action,
  getNicknames: action,
  setWarningState: action,
  clearError: action,
  setStep: action,
  setConsent: action,
  setGetBGCCopy: action,
  setEmail: action,
  setPartnerName: action,
  setBypass: action,
  setSource: action,
  setDriversLicenseState: action,
  setDriversLicenseNumber: action,
  setUserId: action,
  setReferenceId: action,
  setFountainId: action,
  setDoChecks: action,
  setCandidateId: action,
  setMonthOfBirth: action,
  setDayOfBirth: action,
  setYearOfBirth: action,
  setDateOfBirth: action,
  setFirstName: action,
  setLastName: action,
  setMiddleName: action,
  setSubmited: action,
  setHasMiddleName: action,
  setPhone: action,
  setSsn: action,
  setZipcode: action,
  setGender: action,
  setName: action,
  setCertify: action,
  setCertifyTos: action,
  setCertifySms: action,
  setAgreeDisclosure: action,
  setAgreeAuthorization: action,
  setAgreeRights: action,
  setAgreeStateRights: action,
  setAgreeTerms: action,
  setAgreeSigning: action,
  setSigned: action,
  setLoading: action,
  setDoMVR: action,
  setEdit: action,
  setAddress: action,
  setCity: action,
  toggleHasCarInsurance: action,
  storeImage: action,
  setFormData: action,
  setData: action,
  touchFirstName: action,
  touchLastName: action,
  touchMiddleName: action,
  touchSsn: action,
  touchZipcode: action,
  touchDriversLicenseState: action,
  touchDriversLicenseNumber: action,
  freezeEmail: action,
  unfreezeEmail: action,
  setOneOffChecks: action,
  declareOneOffChecks: action,
  declareOneOffCheck: action,
  setReleaseCertify: action,
  setInvestorDemoEnv: action,
  setShowManualForm: action,
  setShowAutomaticFill: action,
  setCaptchaToken: action,
  setCaptchaReset: action,
  setTurnId: action,
  setScrollCurrentTabTo: action,
  setFormLock: action,
  setSkipValidation: action
});

export default LandingPageModel;
