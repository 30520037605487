import { defineMessages } from "react-intl";

export default defineMessages({
  type: {
    id: "app.components.CriminalRecordsTable.type",
    defaultMessage: "Type"
  },
  amount: {
    id: "app.components.CriminalRecordsTable.amount",
    defaultMessage: "Amount"
  },
  plaintiff: {
    id: "app.components.CriminalRecordsTable.plaintiff",
    defaultMessage: "Plaintiff"
  },
  date: {
    id: "app.components.CriminalRecordsTable.date",
    defaultMessage: "Date"
  },
  chapter: {
    id: "app.components.CriminalRecordsTable.chapter",
    defaultMessage: "Chapter"
  },
  noticeType: {
    id: "app.components.CriminalRecordsTable.date",
    defaultMessage: "Notice Type"
  },
  state: {
    id: "app.components.CriminalRecordsTable.state",
    defaultMessage: "State"
  },
  description: {
    id: "app.components.CriminalRecordsTable.description",
    defaultMessage: "Description"
  },
  plea: {
    id: "app.components.CriminalRecordsTable.plea",
    defaultMessage: "Plea"
  },
  disposition: {
    id: "app.components.CriminalRecordsTable.disposition",
    defaultMessage: "Disposition"
  },
  offense_date: {
    id: "app.components.CriminalRecordsTable.offense_date",
    defaultMessage: "Offense Date"
  },
  county: {
    id: "app.components.CriminalRecordsTable.county",
    defaultMessage: "County"
  },
  source: {
    id: "app.components.CriminalRecordsTable.source",
    defaultMessage: "Source"
  },
  crime_type: {
    id: "app.components.CriminalRecordsTable.source",
    defaultMessage: "Source"
  }
});
