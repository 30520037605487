import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Button, Icon, withStyles } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useDebounce } from "../../utils/useDebounce";

const styles = {
  icon: {
    width: "auto",
    height: "auto",
    overflow: "visible",
    verticalAlign: "middle",
    fontSize: 14,
    marginRight: 7
  }
};

export const CopyUrlButtonComponent = ({ url, classes, ...props }) => {
  const [showCopied, setShowCopied] = useState(false);
  const debouncedShowCopied = useDebounce(showCopied, 1000);

  // Revert to previous state after debounced state is updated
  useEffect(() => {
    setShowCopied(false);
  }, [debouncedShowCopied]);

  return (
    <CopyToClipboard text={encodeURI(url)} onCopy={() => setShowCopied(true)}>
      {showCopied ? (
        <Button color="primary" {...props}>
          <Icon className={classNames(classes.icon, "fas fa-check")} />
          Copied!
        </Button>
      ) : (
        <Button color="secondary" {...props}>
          <Icon className={classNames(classes.icon, "fas fa-copy")} />
          Copy URL
        </Button>
      )}
    </CopyToClipboard>
  );
};

export const CopyUrlButton = withStyles(styles)(CopyUrlButtonComponent);
