import { primary, secondary, red } from "../../styles/constants";

export const styles = {
  item: {
    fontSize: "14px",
    fontWeight: "bold",
    color: primary
  },
  itemIcon: {
    fontSize: "20px",
    width: "40px"
  },

  modal: {
    title: {
      fontStyle: "normal",
      fontSize: 18,
      color: "#393E59",
      margin: 0
    },
    cancelButton: {
      width: "96px",
      height: "27px",
      border: `1px solid ${secondary}`,
      color: secondary,
      marginRight: "-5px"
    },
    nextButton: {
      backgroundColor: secondary,
      width: "96px",
      height: "27px",
      color: "white"
    },
    actionContainer: {
      display: "flex",
      justifyContent: "end",
      marginBottom: "20px",
      marginRight: "10px"
    },
    messageContainer: {
      marginTop: "40px",
      marginLeft: "10px",
      marginRight: "10px",
      marginBottom: "0px",
      lineHeight: "20px"
    },
    bodyContainer: {
      display: "flex",
      flexDirection: "column",
      color: "#333",
      fontSize: 14,
      fontWeight: 600
    },
    datePickerContainer: {
      width: "50%",
      marginTop: "20px",
      position: "relative"
    },
    checkIconContainer: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(255, 255, 255, 0.85)"
    },
    checkIcon: { width: "30%" },
    csvModalError: {
      color: red,
      display: "flex",
      alignItems: "center",
      fontSize: "10px",
      position: "absolute",
      bottom: -20
    },
    progress: {
      marginTop: "30px"
    }
  }
};
