import {
  tartOrange,
  primary,
  white,
  secondary,
  smokeWhite
} from "../constants";

const buttonContainer = {
  display: "flex",
  marginTop: "14px",
  marginBottom: "30px",
  flexDirection: "row",
  alignItems: "center"
};

const styles = {
  buttonContent: {
    color: white,
    fontSize: 14,
    fontWeight: "bold"
  },
  altButtonContent: {
    color: white,
    fontSize: 18
  },
  title: {
    color: primary,
    fontSize: "32px",
    fontweight: "bold",
    display: "inline-block"
  },
  subtitle: {
    textAlign: "center",
    fontWeight: "500",
    paddingTop: 20,
    paddingBottom: 20
  },
  message: {
    display: "block",
    fontSize: "20px",
    marginTop: "20px",
    textAlign: "center"
  },
  primary: {
    color: primary
  },
  modal: {
    maxWidth: 752,
    borderRadius: 20
  },
  progressContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    textAlign: "center",
    padding: 20,
    opacity: 0.5,
    top: 0,
    left: 0,
    zIndex: 2,
    background: "white"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  },
  ssnTitle: {
    marginTop: 20,
    marginBottom: -15,
    display: "block",
    color: "red"
  },
  altBodyStyle: {
    paddingTop: "15px",
    padding: "0"
  },
  altMessage: {
    fontSize: "20px",
    textAlign: "center"
  },
  confirmBtnContainer: {
    textAlign: "center",
    marginBottom: "15px"
  },
  blackButton: {
    backgroundColor: "black",
    width: "168px",
    height: "40px",
    color: "white"
  },
  nextButton: {
    backgroundColor: secondary,
    width: "168px",
    height: "40px",
    color: "white"
  },
  cancelButton: {
    width: "96px",
    height: "25px",
    color: tartOrange,
    borderRadius: "8px",
    padding: "10px"
  },
  editButton: {
    width: "96px",
    height: "25px",
    color: primary,
    marginLeft: "16px"
  },
  turquoiseButton: {
    width: "131px",
    height: "36px",
    backgroundColor: secondary,
    color: white
  },
  closeWindowButton: {
    width: "204px",
    height: "25px",
    color: tartOrange
  },
  buttonsContainerSpaceBetween: {
    margin: 0,
    padding: "8px 24px 43px 24px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonsContainerFlexEnd: {
    margin: 0,
    padding: "8px 24px 24px 24px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },

  //email sent styles
  dialogContent: {
    padding: "36px 72px 60px 72px"
  },
  imgContainer: {
    width: "100%",
    height: "240px",
    margin: "0 auto"
  },
  imgDimensionsContainer: {
    maxWidth: "240px",
    maxHeight: "240px",
    margin: "0 auto"
  },
  headerImage: {
    width: "100%",
    height: "auto"
  },
  messages: {
    height: "134px",

    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  gralMessageContainer: {
    width: "288px",
    fontSize: "24px",
    fontWeight: "bold",

    flex: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center"
  },
  specificMessageContainer: {
    width: "288px",
    overflowY: "auto",

    fontSize: "16px",
    fontWeight: "normal",

    flex: 7,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    bottom: 0
  },
  onlyGralErrorMessageContainer: {
    fontSize: "24px",
    fontWeight: "bold",

    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  closeButtonContainer: {
    height: "60px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  blackButtonContainer: {
    height: "83px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  blackButtonEmailSent: {
    backgroundColor: secondary,
    width: "230px",
    height: "40px",
    color: "white"
  },
  resumeLoader: {
    position: "absolute",
    zIndex: "5",
    width: "100%",
    left: "0",
    top: "0",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#ffffffb3"
  },
  // modal.js styles
  new: {
    container: {
      backgroundColor: smokeWhite,
      padding: "30px",
      borderRadius: "30px",
      display: "grid",
      gridGap: "25px",
      gridTemplateRows: "60px auto 50px",
      gridTemplateColumns: "100%",
      maxWidth: "500px"
    },
    textContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center"
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      width: "100%",
      textAlign: "center",
      color: primary
    },
    contentContainer: {
      fontSize: "14px",
      fontWeight: "normal",
      width: "100%",
      textAlign: "center",
      color: primary,
      paddingTop: "0px"
    },
    buttonContainerCentered: {
      ...buttonContainer,
      justifyContent: "center"
    },
    buttonContainerEnd: {
      ...buttonContainer,
      justifyContent: "flex-end"
    },
    imageContainer: {
      width: "100%",
      margin: "0 auto",
      marginTop: "30px",
      marginBottom: "24px"
    },
    closeContainer: {
      position: "absolute",
      top: 40,
      right: 30,
      width: 16,
      cursor: "pointer"
    },
    image: {
      maxWidth: "240px",
      width: "fit-content",
      margin: "0 auto"
    }
  }
};
export default styles;
