const styles = {
  wrapper: {
    margin: "35px 30px 35px 30px"
  },
  box: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  iconContainer: {
    marginBottom: "20px"
  },
  icon: {
    width: "49px",
    height: "51px",
    fill: "#31B4B5"
  },
  mainTextContainer: {
    marginBottom: "10px"
  },
  mainText: {
    fontSize: "14px",
    fontWeight: "bold"
  },
  secondaryTextContainer: {
    width: "150px"
  },
  secondaryText: {
    fontSize: "11px",
    fontWeight: "lighter",
    lineHeight: "initial",
    margin: "0",
    textAlign: "center"
  }
};

export default styles;
