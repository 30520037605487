import PropTypes from "prop-types";
import styled from "styled-components";

export const StatusLabel = styled.span`
  display: inline-block;
  position: relative;
  padding: 4px 14px;
  margin-left: 7px;
  color: ${props => props.color};
  font-size: 14px;

  &:before {
    content: "";
    position: absolute;
    border-radius: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-color: ${props => props.color};
  }
`;
StatusLabel.propTypes = {
  color: PropTypes.string.isRequired
};
