import React, { useEffect, useState } from "react";
import { InputAdornment, Icon } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import moment from "moment";

import { useDebounce } from "../../utils/useDebounce";

import { WorkerFilterTextField } from "./WorkerFilterTextField";

const SearchIcon = ({ className, ...props }) => {
  return (
    <Icon
      className={classNames(className, "fas fa-search")}
      style={{ top: "calc(50% - 4px)", fontSize: "12px", right: "20px" }}
      {...props}
    />
  );
};

export const WorkerFilterDateField = ({ value, onChangeText, ...props }) => {
  const [innerValue, setInnerValue] = useState(value || "");
  const debouncedInnerValue = useDebounce(innerValue, 200);

  useEffect(() => {
    // Keep internal controlled value in sync
    if (moment(value).toDate() != innerValue) {
      setInnerValue(value);
    }
  }, [value]);

  useEffect(() => {
    // Call onChangeText with new value (if callback exists)
    onChangeText && onChangeText(debouncedInnerValue);
  }, [debouncedInnerValue]);

  return (
    <WorkerFilterTextField
      {...props}
      type="date"
      value={innerValue}
      onChange={({ target }) => setInnerValue(target.value)}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

WorkerFilterDateField.propTypes = {
  value: PropTypes.string,
  onChangeText: PropTypes.func
};
