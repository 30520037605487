import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.components.SSNTrace.title",
    defaultMessage: "SSN Trace"
  },
  titleHelp: {
    id: "app.components.SSNTrace.titleHelp",
    defaultMessage:
      "A check the confirms the validity of an SSN number, the date of birth and mortality status of the holder, and all addresses associated with the SSN"
  },
  issuedDate: {
    id: "app.components.SSNTrace.issuedDate",
    defaultMessage: "Issued Date"
  },
  issuedState: {
    id: "app.components.SSNTrace.issuedState",
    defaultMessage: "Issued State"
  },
  numberInfo: {
    id: "app.components.SSNTrace.numberInfo",
    defaultMessage:
      "Confirms whether the SSN number provided by the Turn partner matches the number entered by the candidate."
  },
  numberMismatch: {
    id: "app.components.SSNTrace.numberMismatch",
    defaultMessage: "The SSN you entered does not match the public records."
  },
  nameInfo: {
    id: "app.components.SSNTrace.numberInfo",
    defaultMessage:
      "Does the name provided by the partner match the name entered by the candidate? A mismatch is not necessarily an indication of fraud."
  },
  public_records_nameInfo: {
    id: "app.components.SSNTrace.numberInfo",
    defaultMessage:
      "Does the name provided by the partner match the name retrieved by the public records? A mismatch is not necessarily an indication of fraud."
  },
  nameMismatch: {
    id: "app.components.SSNTrace.nameMismatch",
    defaultMessage:
      "The name entered does not match the name provided by the candidate."
  },
  public_records_nameMismatch: {
    id: "app.components.SSNTrace.nameMismatch",
    defaultMessage:
      "The name entered does not match the name provided by the public records."
  },
  dobInfo: {
    id: "app.components.SSNTrace.dobInfo",
    defaultMessage:
      "The DOB entered does not match the DOB provided by the candidate."
  },
  dobMismatch: {
    id: "app.components.SSNTrace.dobMismatch",
    defaultMessage:
      "The date of birth entered does not match the public records."
  },
  mortalityInfo: {
    id: "app.components.SSNTrace.mortalityInfo",
    defaultMessage: "Is the person deceased according to public record data."
  },
  SSNLastSeen: {
    id: "app.components.SSNTrace.SSNLastSeen",
    defaultMessage: "Last Seen On"
  },
  SSNFirstSeen: {
    id: "app.components.SSNTrace.SSNFirstSeen",
    defaultMessage: "First Seen On"
  }
});
