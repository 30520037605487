const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    position: "relative"
  },
  rightActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  badge: {
    padding: "0px 45px",
    display: "inline-flex",
    width: "25%",
    alignItems: "center",
    borderRadius: "20px",
    marginRight: "50px"
  },
  expandAll: {
    top: 0,
    margin: 0,
    right: "",
    position: "relative"
  }
};

export default styles;
