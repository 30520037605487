import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.containers.ReportAuthorization.title",
    defaultMessage: "Your report is ready"
  },
  subtitle: {
    id: "app.containers.ReportAuthorization.subtitle",
    defaultMessage: "Please verify it's you"
  },
  dontRemember: {
    id: "app.containers.ReportAuthorization.dontRemember",
    defaultMessage: "Don't remember your SSN?"
  },
  changeCredentials: {
    id: "app.containers.ReportAuthorization.changeCredentials",
    defaultMessage: "Try other credentials"
  },
  ssn: {
    id: "app.components.ReportAuthorization.CardForm.snn",
    defaultMessage: "SSN"
  },
  ssn_hint: {
    id: "app.components.ReportAuthorization.CardForm.snn_hint",
    defaultMessage: "XXXX - XXX"
  },
  drivers_license_number: {
    id: "app.components.ReportAuthorization.CardForm.drivers_license",
    defaultMessage: "Driver’s License"
  },
  drivers_license_number_hint: {
    id: "app.components.ReportAuthorization.CardForm.drivers_license_hint",
    defaultMessage: "XXXX - XXXX -"
  },
  phone_number: {
    id: "app.components.ReportAuthorization.CardForm.phone_number",
    defaultMessage: "Phone Number"
  },
  phone_number_hint: {
    id: "app.components.ReportAuthorization.CardForm.phone_number_hint",
    defaultMessage: "XXX.XXX"
  }
});
