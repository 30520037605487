import styled from "styled-components";

const AppHeaderContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 600px) {
    width: ${props => (props.fullwidth ? "95%" : "auto")};
  }
`;

export default AppHeaderContent;
