import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-solid-svg-icons";

import { white } from "../../../styles/constants";
import CollapsibleIconContainer from "./container";

const styles = {
  container: {
    cursor: "pointer"
  }
};

class CollapsibleIcon extends React.Component {
  static propTypes = {
    appContainerProps: PropTypes.object,
    sideBarProps: PropTypes.object,
    backgroundProps: PropTypes.object,
    appHeaderProps: PropTypes.object,
    sideBarCollapsible: PropTypes.bool,

    modal: PropTypes.node,

    children: PropTypes.node
  };

  static defaultProps = {
    appContainerProps: {},
    sideBarProps: {},
    backgroundProps: {},
    appHeaderProps: {},

    modal: null
  };

  render() {
    return (
      <CollapsibleIconContainer {...this.props} style={styles.container}>
        <FontAwesomeIcon icon={faBars} color={white} />
      </CollapsibleIconContainer>
    );
  }
}

export default CollapsibleIcon;
