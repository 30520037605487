import { defineMessages } from "react-intl";

export default defineMessages({
  subtitle: {
    id: "app.components.LandingPage.subtitle",
    defaultMessage:
      "Please take some time to carefully check your personal information and read the Disclosure & Authorization before confirming your submission"
  },
  title: {
    id: "app.components.LandingPage.title",
    defaultMessage: "Confirm your information"
  },
  consentTitle: {
    id: "app.components.LandingPage.consentTitle",
    defaultMessage: "Background Report Submitted"
  },
  consentSubTitle: {
    id: "app.components.LandingPage.consentSubTitle",
    defaultMessage:
      "You have successfully reviewed and submitted your background report."
  },
  firstNameLabel: {
    id: "app.components.LandingPage.firstNameLabel",
    defaultMessage: "First Name"
  },
  lastNameLabel: {
    id: "app.components.LandingPage.lastNameLabel",
    defaultMessage: "Last Name"
  },
  middleNameLabel: {
    id: "app.components.LandingPage.middleNameLabel",
    defaultMessage: "Middle Name"
  },
  dateOfBirthLabel: {
    id: "app.components.LandingPage.dateOfBirthLabel",
    defaultMessage: "Date of Birth"
  },
  ssnLabel: {
    id: "app.components.LandingPage.ssnLabel",
    defaultMessage: "SSN"
  },
  zipcodeLabel: {
    id: "app.components.LandingPage.zipcode",
    defaultMessage: "Zip Code"
  },
  genderLabel: {
    id: "app.components.LandingPage.gender",
    defaultMessage: "Gender"
  },
  driversLicenseNumberLabel: {
    id: "app.components.LandingPage.driversLicenseNumber",
    defaultMessage: "Drivers License Number"
  },
  driversLicenseStateLabel: {
    id: "app.components.LandingPage.driversLicenseState",
    defaultMessage: "Drivers License State"
  },
  phoneLabel: {
    id: "app.components.LandingPage.phoneLabel",
    defaultMessage: "Phone Number"
  },
  emailLabel: {
    id: "app.components.LandingPage.email",
    defaultMessage: "Email"
  },
  birthCityLabel: {
    id: "app.components.LandingPage.birthCity",
    defaultMessage: "Birth City"
  },
  canadianAddressesLabel: {
    id: "app.components.LandingPage.canadianAddresses",
    defaultMessage: "Canadian Address"
  },
  addressCountryLabel: {
    id: "app.components.LandingPage.addressCountry",
    defaultMessage: "Address Country"
  },
  addressProvinceLabel: {
    id: "app.components.LandingPage.addressProvince",
    defaultMessage: "Address Province"
  },
  addressCityLabel: {
    id: "app.components.LandingPage.addressCity",
    defaultMessage: "Address City"
  },
  addressStreetLabel: {
    id: "app.components.LandingPage.addressStreet",
    defaultMessage: "Address Street"
  },
  addressZipcodeLabel: {
    id: "app.components.LandingPage.addressZipcode",
    defaultMessage: "Address Zipcode"
  },

  birthProvinceStateLabel: {
    id: "app.components.LandingPage.birthProvinceState",
    defaultMessage: "Birth Province State"
  },
  birthCountryLabel: {
    id: "app.components.LandingPage.birthCountry",
    defaultMessage: "Birth Country"
  },
  certify: {
    id: "app.components.LandingPage.certify",
    defaultMessage:
      "The information I provided is true, accurate, and complete."
  },
  certifySms: {
    id: "app.components.LandingPage.certifySms",
    defaultMessage:
      "I  want to receive background check status, new job postings, and other updates from Turn by text at the phone number provided. Message frequency may vary. Message and data rates may apply. Reply HELP for help or STOP to cancel."
  },
  certifyRelease: {
    id: "app.components.LandingPage.certifyRelease",
    defaultMessage:
      "I certify that the information provided herein is true and correct. I authorize and consent to Turn Technologies Inc. and its affiliates, to use my personal information and signature to request my Driver Record Information from the Pennsylvania Department of Transportation for employment purposes and to furnish the record to"
  },
  disclosure: {
    id: "app.components.LandingPage.disclosure",
    defaultMessage:
      "I have read and understood the Disclosure Regarding Background Investigation"
  },
  disclosureLink: {
    id: "app.components.LandingPage.disclosureLink",
    defaultMessage: "Disclosure & Authorization"
  },
  rights: {
    id: "app.components.LandingPage.rights",
    defaultMessage: "I have read and understood the Summary of my Rights"
  },
  terms: {
    id: "app.components.LandingPage.terms",
    defaultMessage: "I have read and understood the Terms of Use"
  },
  signing: {
    id: "app.components.LandingPage.signing",
    defaultMessage:
      "By signing my name, I indicate my agreement to all of the above."
  },
  confidential: {
    id: "app.components.LandingPage.confidential",
    defaultMessage:
      "All information provided to Turn Technologies will be kept confidential to protect your privacy."
  },
  notProvided: {
    id: "app.components.LandingPage.notProvided",
    defaultMessage: "Please Provide"
  },
  editText: {
    id: "app.components.LandingPage.editText",
    defaultMessage:
      '{PartnerName} (the "Company") has engaged Turn Technologies, Inc. to obtain a consumer report and/or investigative consumer report as a condition of your engagement with the Company. Turn Technologies Inc. will conduct a background check in compliance with applicable law. These reports may contain information regarding your criminal history, social security verification, motor vehicle records (“driving records”), verification of your education or employment history, or other background checks, which will be used for employment purposes only.'
  },
  confirmText: {
    id: "app.components.LandingPage.confirmText",
    defaultMessage:
      "Please take your time to confirm all your information is true and correct, and update any errors before continuing. Incorrect data will affect the results of your background check. If you already uploaded a picture of your photo id, you don’t need to upload a new one."
  },
  thankYou: {
    id: "app.components.LandingPage.thankYou",
    defaultMessage: "Thank you!"
  },
  moreAboutText: {
    id: "app.components.LandingPage.moreAboutText",
    defaultMessage: "We want to know more about you."
  },
  chooseHowToText: {
    id: "app.components.LandingPage.chooseHowToText",
    defaultMessage: "Choose how to do it."
  },
  chooseHowToTextMVR: {
    id: "app.components.LandingPage.chooseHowToTextMVR",
    defaultMessage: "Continue with a driver's license upload."
  },
  chooseHowToTextCanada: {
    id: "app.components.LandingPage.chooseHowToTextCanada",
    defaultMessage: "Continue with an official identification."
  },
  helpText: {
    id: "app.components.LandingPage.helpText",
    defaultMessage:
      "Please enter your full legal name and other personal details below:"
  },
  close: {
    id: "app.components.LandingPage.close",
    defaultMessage: "You may now close this page."
  },
  name: {
    id: "app.components.LandingPage.name",
    defaultMessage: "Please type your name to confirm"
  },
  draw: {
    id: "app.components.LandingPage.draw",
    defaultMessage: "Enter Your Signature"
  },
  redirect: {
    id: "app.components.LandingPage.redirect",
    defaultMessage: "You will be redirected in a few seconds."
  },
  authorization: {
    id: "app.components.LandingPage.authorization",
    defaultMessage:
      "By clicking this checkbox, I indicate my agreement to all of the above."
  },
  bgccopy: {
    id: "app.components.LandingPage.bgccopy",
    defaultMessage: "I want to receive a copy of my Background Report."
  },
  paReleaseFormHeader: {
    id: "app.components.LandingPage.paReleaseFormHeader",
    defaultMessage:
      " requires that you fill out a Pennsylvania Department of Transportation release form in order to continue with your application. Please fill the highlighted zones."
  },
  driverInformation: {
    id: "app.components.LandingPage.driverInformation",
    defaultMessage: "Driver Information"
  },
  driverRelease: {
    id: "app.components.LandingPage.driverRelease",
    defaultMessage: "Driver Release"
  },
  firstNameReleaseLabel: {
    id: "app.components.LandingPage.firstNameReleaseLabel",
    defaultMessage: "First Name"
  },
  lastNameReleaseLabel: {
    id: "app.components.LandingPage.lastNameReleaseLabel",
    defaultMessage: "Last Name"
  },
  initialLabel: {
    id: "app.components.LandingPage.initialLabel",
    defaultMessage: "Initial"
  },
  addressLabel: {
    id: "app.components.LandingPage.addressLabel",
    defaultMessage: "Address"
  },
  cityLabel: {
    id: "app.components.LandingPage.cityLabel",
    defaultMessage: "City"
  },
  stateLabel: {
    id: "app.components.LandingPage.stateLabel",
    defaultMessage: "State"
  },
  zipcodeReleaseLabel: {
    id: "app.components.LandingPage.zipcodeReleaseLabel",
    defaultMessage: "Zipcode"
  },
  viewFullDocument: {
    id: "app.components.LandingPage.viewFullDocument",
    defaultMessage: "View Full Document"
  },
  canadianAddress: {
    id: "app.components.LandingPage.canadianAddress",
    defaultMessage: "Current or past address"
  },
  automaticFilling: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage: "Automatic Filling"
  },
  uploadIdFront: {
    id: "app.components.LandingPage.uploadIdCard",
    defaultMessage:
      "Drag and Drop or click to browse your device to upload a JPG, PNG, GIF file."
  },
  maxUpload: {
    id: "app.components.LandingPage.uploadIdCard",
    defaultMessage: "The maximum file upload limit is 8MB"
  },
  beforeUpload: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage: "Before uploading the photo"
  },
  checkImage: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage: "Check that the image is clear and the data is legible."
  },
  followingSteps: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage:
      "Please take the following steps to avoid processing errors."
  },
  pictureMobile: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage: "When taking photos of your ID:"
  },
  canadaPhotosSuccess: {
    title: "All good!",
    paragraphText: "Your background check is now processing.",
    buttonText: "OK"
  },
  canadaPhotosError: {
    title: "Thank you",
    paragraphText: `A manual verification of the pictures you provided is required and our team is working on it.
    Once it's done, we will let you know the next steps via email.`,
    buttonText: "OK"
  },
  cardAutomaticFilling: {
    title: "Identification upload (recommended)",
    paragraphText:
      "Use a clear picture of your photo ID so we can verify your information"
  },
  cardCanadaFilling: {
    title: "Identification upload",
    paragraphText:
      "Use a clear picture of your photo ID so we can verify your information"
  },
  cardMVRFilling: {
    title: "Automatic filling",
    paragraphText:
      "Upload a valid US driver's license and make sure it's not expired; temporary permits and personal IDs are not allowed."
  },
  cardManualFilling: {
    title: "Continue without uploading identification",
    paragraphText: `If needed, we will contact you for a picture of your photo ID \
      to verify your information and proceed with the background check. \
      Not having your photo ID may affect the completion of your background check.`
  },
  piiRetryEditText: {
    id: "app.components.LandingPage.piiRetryEditText",
    defaultMessage: `Please upload pictures of your valid government-issued photo id, front and back, so we can verify your identity and resume processing your background check report.{br}
    {br}
    Have in mind that, if we need further information, we might contact you via email, text message, and / or phone call. Remember this is part of your application process with {PartnerName}.{br}
    {br}
    If you need immediate assistance or if you consider this to be an error, please contact {SupportLink}.
    `
  },
  piiRetryConfirmationSubtitle: {
    id: "app.components.LandingPage.piiRetryConfirmationSubtitle",
    defaultMessage: `
    Please take some time to carefully check your personal information and if anything is incorrect, please contact {SupportLink}.{br} {br}

    Thank you!
    `
  },
  piiRetryThankYouTitle: {
    id: "app.components.LandingPage.piiRetryThankYouTitle",
    defaultMessage: "Thank you!"
  },
  piiRetryThankYouSubtitle: {
    id: "app.components.LandingPage.piiRetryThankYouSubtitle",
    defaultMessage: `
    We will start processing your background check in a moment.{br}{br}
    In case anything else is needed from you, our Support team will get in touch with you via email, text message, and/or phone call.{br}{br}
    If you need immediate assistance please contact {SupportLink}
    `
  },
  piiRetryTrack: {
    id: "app.components.LandingPage.piiRetryTrack",
    defaultMessage: "Track your progress in our Turn Worker App"
  },
  tipsDarkBG: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage: "Place your ID over a dark background in a well-lit area"
  },
  tipsFlash: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage: "Don’t use flash to avoid shiny pictures"
  },
  tipsHorizontal: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage: "Take the picture in horizontal orientation"
  },
  tipsTilt: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage: "Avoid tilting the camera"
  },
  tipsExample: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage: "See an example image"
  },
  reviewName: {
    id: "app.components.LandingPage.tabAutomaticFill",
    defaultMessage:
      "Before submitting your information, please be sure to review all fields and correct any errors."
  }
});
