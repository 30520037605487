import { noticeWarning, primary, white } from "../../../styles/constants";

export const styles = {
  checkbox: {
    fill: primary
  },
  floatingAlert: {
    position: "absolute",
    top: "-80px",
    left: 0,
    width: "100%",
    padding: "10px",
    background: noticeWarning,
    color: white,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "capitalize"
  },
  recordsLabel: {
    fontSize: "14px",
    fontWeight: "600",
    marginBottom: "5px",
    display: "block"
  }
};
