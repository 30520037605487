import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faCheck,
  faFileExclamation,
  faUserMinus,
  faDownload,
  faRedo
} from "@fortawesome/pro-solid-svg-icons";
import { styles } from "./styles";
import { getRejectMessage } from "turn-shared";
import env from "@mars/heroku-js-runtime-env";

import ApproveModal from "../approve-worker";
import RejectModal from "../reject-worker";
import WithdrawModal from "../withdraw-worker";
import ResumeModal from "../resume-worker";
import ActionsSelect from "../../../components/ActionsSelect";
import member from "../../../models/team-member";
import RecheckModal from "../recheck-worker";
import {
  APPROVE,
  CRIM_MONITORING,
  ENROLLMENT_LABEL_MAPPING,
  MVR_MONITORING,
  NONE,
  PRINT,
  RECHECK,
  RESUME,
  UNAUTHORIZED_STATES,
  WITHDRAW
} from "./constants";
import CMIcon from "../../../icons/svgr/CMIcon";
import EnrollmentModal from "../Information/EnrollmentInformation/Modal";
import { normalizeEnrollments } from "../Information/EnrollmentInformation/adapters";
import teamMember from "../../../models/team-member";

class WorkerActions extends Component {
  static propTypes = {
    worker: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    cannotApprove: PropTypes.bool.isRequired,
    cannotReject: PropTypes.bool.isRequired,
    canWithdraw: PropTypes.bool.isRequired,
    cannotResume: PropTypes.bool.isRequired,
    cannotPrint: PropTypes.bool,
    canRecheck: PropTypes.bool.isRequired,
    printPage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    openErrorDialog: PropTypes.func
  };

  constructor(props) {
    super(props);
    const REJECT =
      getRejectMessage(member.partner_type) || "Pre-Adverse Action";
    this.state = {
      action: NONE,
      actions: [
        NONE,
        APPROVE,
        WITHDRAW,
        REJECT,
        RESUME,
        PRINT,
        RECHECK,
        MVR_MONITORING,
        CRIM_MONITORING
      ],
      enrollments: [],
      open: false,
      isEnrollmentEnabled: {}
    };
    this.node = null;
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.worker)) {
      this.setState({ enrollments: normalizeEnrollments(this.props.worker) });
      this.setState({
        isEnrollmentEnabled: {
          mvr: this.props.worker.drivingMonitoring.isEnabled,
          crim: this.props.worker.criminalMonitoring.isEnabled
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.action !== PRINT && this.state.action === PRINT) {
      this.props.printPage();
      this.setState({ action: NONE });
    }
  }

  setOpen = open => {
    if (!_.isBoolean(open)) return;
    this.setState({ open });
  };

  toggleOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  setAction = action => {
    if (!this.state.actions.includes(action)) return;
    this.setState({ action });
  };

  isSelected = action => {
    if (!this.state.actions.includes(action)) return false;
    return action === this.state.action;
  };

  handleClose = () => {
    this.setAction(NONE);
  };

  isCanadianPackage = () => {
    const { do_canadian_criminal } = this.props.worker.report_parameters || {};
    return do_canadian_criminal;
  };

  isRecheckActionAllowedPartner = () => {
    const allowedPartners =
      env().REACT_APP_RECHECK_ACTION_ALLOWED_PARTNERS || [];
    return allowedPartners.includes(member.id);
  };

  isAuthorizedToRecheck = () => {
    const { state } = this.props.worker.geocodeData || {};
    return !UNAUTHORIZED_STATES.includes(state);
  };

  renderEnrollment = key => {
    const label = ENROLLMENT_LABEL_MAPPING[key];

    return (
      <>
        <CMIcon
          width={40}
          height={20}
          id={key}
          primaryColor={"black"}
          secondaryColor={"black"}
        />
        <span style={{ ...styles.item, color: "black" }}>{label}</span>
      </>
    );
  };

  render() {
    const REJECT =
      getRejectMessage(member.partner_type) || "Pre-Adverse Action";
    const {
      cannotApprove,
      cannotReject,
      canWithdraw,
      worker,
      auth,
      history,
      cannotResume,
      cannotPrint,
      canRecheck
    } = this.props;

    return (
      <ActionsSelect
        title="Actions"
        actions={this.state.actions}
        action={this.state.action}
        setAction={this.setAction}
        isSelected={this.isSelected}
        ref={this.node}
        open={this.state.open}
        setOpen={this.setOpen}
        menuItems={[
          {
            value: RESUME,
            disabled: cannotResume || !teamMember.canTriggerResumeChecks,
            hide: !teamMember.canTriggerResumeChecks,
            displayTooltip: !teamMember.canTriggerResumeChecks,
            item: (
              <>
                <FontAwesomeIcon icon={faPlayCircle} style={styles.itemIcon} />
                <span style={styles.item}>{RESUME}</span>
              </>
            )
          },
          {
            value: APPROVE,
            disabled: cannotApprove || !teamMember.canTriggerApprove,
            hide: !teamMember.canTriggerApprove,
            displayTooltip: !teamMember.canTriggerApprove,
            item: (
              <>
                <FontAwesomeIcon icon={faCheck} style={styles.itemIcon} />
                <span style={styles.item}>{APPROVE}</span>
              </>
            )
          },
          {
            value: REJECT,
            disabled: cannotReject || !teamMember.canTriggerPreadverse,
            hide: !teamMember.canTriggerApprove,
            displayTooltip: !teamMember.canTriggerPreadverse,
            item: (
              <>
                <FontAwesomeIcon
                  icon={faFileExclamation}
                  style={styles.itemIcon}
                />
                <span style={styles.item}>{REJECT}</span>
              </>
            )
          },
          {
            value: WITHDRAW,
            disabled: !canWithdraw || !teamMember.canTriggerWithdraw,
            displayTooltip: !teamMember.canTriggerWithdraw,
            divider: env().REACT_APP_ENABLE_RECHECK_ACTION === "false",
            hide: !teamMember.canTriggerWithdraw,
            item: (
              <>
                <FontAwesomeIcon icon={faUserMinus} style={styles.itemIcon} />
                <span style={styles.item}>{WITHDRAW}</span>
              </>
            )
          },
          {
            value: RECHECK,
            disabled: !canRecheck || !teamMember.canTriggerRecheck,
            displayTooltip: !teamMember.canTriggerRecheck,
            hide:
              this.isCanadianPackage() ||
              env().REACT_APP_ENABLE_RECHECK_ACTION === "false" ||
              !teamMember.canTriggerRecheck,
            item: (
              <>
                <FontAwesomeIcon icon={faRedo} style={styles.itemIcon} />
                <span style={styles.item}>{RECHECK}</span>
              </>
            )
          },
          {
            value: MVR_MONITORING,
            customDisabled:
              !this.state.isEnrollmentEnabled.mvr &&
              !teamMember.canTriggerCmMvr,
            item: this.renderEnrollment("mvr"),
            customTooltipContent:
              !teamMember.canTriggerEnrollment || !teamMember.canTriggerCmMvr
                ? "Action not allowed for this role."
                : "Package not allowed for Continuous Monitoring.",
            disabled:
              !teamMember.canTriggerEnrollment ||
              !teamMember.canTriggerCmMvr ||
              !this.state.isEnrollmentEnabled["mvr"],
            hide:
              !teamMember.canTriggerEnrollment || !teamMember.canTriggerCmMvr,
            displayTooltip:
              !teamMember.canTriggerEnrollment ||
              !teamMember.canTriggerCmMvr ||
              !this.state.isEnrollmentEnabled["mvr"]
          },
          {
            value: CRIM_MONITORING,
            divider: true,
            disabled:
              !this.state.isEnrollmentEnabled["crim"] ||
              !teamMember.canTriggerEnrollment ||
              !teamMember.canTriggerCmCrim,
            customDisabled:
              !this.state.isEnrollmentEnabled.crim &&
              !teamMember.canTriggerCmCrim,
            item: this.renderEnrollment("crim"),
            customTooltipContent:
              !teamMember.canTriggerEnrollment || !teamMember.canTriggerCmCrim
                ? "Action not allowed for this role."
                : "Package not allowed for Criminal Monitoring.",
            hide:
              !teamMember.canTriggerEnrollment || !teamMember.canTriggerCmCrim,
            displayTooltip:
              !teamMember.canTriggerEnrollment ||
              !teamMember.canTriggerCmCrim ||
              !this.state.isEnrollmentEnabled["crim"]
          },
          {
            value: PRINT,
            disabled: cannotPrint || !teamMember.canTriggerPrint,
            item: (
              <>
                <FontAwesomeIcon icon={faDownload} style={styles.itemIcon} />
                <span style={styles.item}>{PRINT}</span>
              </>
            )
          }
        ]}
        modals={
          <>
            <ApproveModal
              worker={worker}
              auth={auth}
              router={history}
              open={this.isSelected(APPROVE)}
              handleClose={this.handleClose}
            />
            <RejectModal
              worker={worker}
              auth={auth}
              router={history}
              open={this.isSelected(REJECT)}
              handleClose={this.handleClose}
            />
            <WithdrawModal
              worker={worker}
              auth={auth}
              router={history}
              open={this.isSelected(WITHDRAW)}
              handleClose={this.handleClose}
            />
            <ResumeModal
              worker={worker}
              open={this.isSelected(RESUME)}
              handleClose={this.handleClose}
              openErrorDialog={this.props.openErrorDialog}
            />
            <RecheckModal
              worker={worker}
              auth={auth}
              authorizedAction={this.isAuthorizedToRecheck()}
              open={this.isSelected(RECHECK)}
              handleClose={this.handleClose}
            />
            {this.state.isEnrollmentEnabled.mvr && (
              <EnrollmentModal
                enrollment={this.state.enrollments[0]}
                isOpen={this.isSelected(MVR_MONITORING)}
                onCloseModal={this.handleClose}
                disableEnrollmentButton={
                  !teamMember.canTriggerEnrollment ||
                  !teamMember.canTriggerCmMvr
                }
                worker={worker}
              />
            )}
            {this.state.isEnrollmentEnabled.crim && (
              <EnrollmentModal
                enrollment={this.state.enrollments[1]}
                isOpen={this.isSelected(CRIM_MONITORING)}
                onCloseModal={this.handleClose}
                disableEnrollmentButton={
                  !teamMember.canTriggerEnrollment ||
                  !teamMember.canTriggerCmCrim
                }
                worker={worker}
              />
            )}
          </>
        }
      />
    );
  }
}

export default WorkerActions;
