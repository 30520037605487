import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import { CandidateReportStyles as styles } from "../../styles";
import Column from "../Column";

class DataItem extends PureComponent {
  componentDidMount() {}

  render() {
    return (
      <Column
        width={this.props.width}
        widthMD={this.props.widthMD}
        widthSM={this.props.widthSM}
        printWidth={this.props.printWidth}
        verticalAlign="top"
      >
        <div style={this.props.noMargin ? {} : styles.reportData}>
          <p style={styles.reportDateLabel}>
            {this.props.label ? (
              <FormattedMessage {...this.props.label} />
            ) : (
              this.props.title
            )}
          </p>
          {this.props.icon ? this.props.icon : null}
          <p
            id={
              this.props.label
                ? this.props.label.defaultMessage
                : this.props.title
            }
            style={
              this.props.inlineLabel
                ? styles.inlineReportDateTxt
                : styles.reportDateTxt
            }
            data-label={
              this.props.label
                ? this.props.label.defaultMessage
                : this.props.title
            }
          >
            {this.props.text ? this.props.text : "Not provided"}
          </p>
        </div>
      </Column>
    );
  }
}

DataItem.propTypes = {
  width: PropTypes.string.isRequired,
  widthMD: PropTypes.string.isRequired,
  widthSM: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  printWidth: PropTypes.string,
  label: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  inlineLabel: PropTypes.string,
  id: PropTypes.object
};

export default DataItem;
