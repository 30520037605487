import React from "react";
import { PropTypes } from "prop-types";
import { CandidateReportStyles as styles } from "../../styles";

const Divider = ({ checked }) => (
  <div style={styles.divider}>
    <p style={styles.dividerTxt}>{checked}</p>
  </div>
);

Divider.propTypes = {
  checked: PropTypes.string.isRequired
};

export default Divider;
