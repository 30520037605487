import React, { Component } from "react";
import {
  CarInsuranceField,
  DateOfBirthField,
  EmailField,
  FirstNameField,
  GenderField,
  LastNameField,
  LicenseNumberField,
  LicenseStateField,
  MiddleNameField,
  PhoneField,
  SSNField,
  ZipcodeField
} from "./candidate-fields";
import { Grid, Paper, Typography, InputLabel } from "@material-ui/core";
import _ from "lodash";
import Title from "../Title";
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import { LandingPageStyles as styles } from "../../styles";
import { PropTypes } from "prop-types";
import Column from "../Column";
import { valueExists } from "turn-shared";
import { FormWizardBackForward } from "./components/FormWizardBackNext";
import syslog from "../../services/syslog";

const propTypes = {
  details: PropTypes.object,
  setStep: PropTypes.func,
  currentStep: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  getButtonFlatStyle: PropTypes.object,
  getButtonLabelStyle: PropTypes.object,
  getButtonStyle: PropTypes.object
};

export default class TabPIIRetryConfirmation extends Component {
  logDataReview = (message, callback) => {
    const detailsData = { ...this.props.details.formData };
    detailsData["has_middle_name"] = !detailsData["has_middle_name"];
    detailsData["tab"] = "step_2_piiRetryConfirmation";
    syslog([detailsData]);

    if (callback) callback();
  };

  renderField = field => {
    const { details: d } = this.props;
    const showSsn = d.requiredFields.includes("ssn");
    switch (field) {
      case "first_name":
        return <FirstNameField model={d} width="100%" />;
      case "last_name":
        return <LastNameField model={d} width="100%" />;
      case "middle_name":
        return <MiddleNameField model={d} width="100%" />;
      case "email":
        return <EmailField model={d} width="100%" />;
      case "phone_number":
        return <PhoneField model={d} width="100%" />;
      case "date_of_birth":
        return <DateOfBirthField model={d} width="100%" />;
      case "has_car_insurance":
        return <CarInsuranceField model={d} width="100%" />;
      case "ssn":
        return <SSNField model={d} width="100%" shouldRender={showSsn} />;
      case "gender":
        return <GenderField model={d} width="100%" />;
      case "zipcode":
        return <ZipcodeField model={d} width="100%" />;
      case "drivers_license_state":
        return <LicenseStateField model={d} width="100%" />;
      case "drivers_license_number":
        return <LicenseNumberField model={d} width="100%" />;
      default:
        return;
    }
  };

  renderLabel = key => {
    switch (key) {
      case "date_of_birth":
        return "Date of Birth";
      case "ssn":
        return "SSN";
      case "zipcode":
        return "Zip Code";
      default:
        return _.startCase(key);
    }
  };

  renderVariantAListItem = (value, key) => {
    if (!valueExists(value) && value !== "") {
      return null;
    }

    return (
      <Grid item xs={12} sm={6}>
        <div id={`${key}-grid`} style={{ padding: 10 }}>
          <div style={{ display: "flex" }}>
            <InputLabel
              htmlFor="name-simple"
              style={{ display: "flex", flex: 1, alignItems: "center" }}
            >
              <span>{this.renderLabel(key)}</span>
            </InputLabel>
          </div>
          <div>
            {!this.props.details ? (
              this.renderField(key)
            ) : (
              <Typography>{_.isEmpty(value) ? "-" : value}</Typography>
            )}
          </div>
        </div>
      </Grid>
    );
  };

  render() {
    if (!this.props.details) {
      return null;
    }
    return (
      <div style={styles.tabContent}>
        <Title id="confirmationStepTitle" align="left" big>
          Confirmation
        </Title>
        <p style={{ ...styles.textBig, marginBottom: 28, marginTop: 36 }}>
          <FormattedMessage
            {...messages.piiRetryConfirmationSubtitle}
            values={{
              SupportLink: <a href="mailto:support@turn.ai">support@turn.ai</a>,
              br: <br />
            }}
          />
        </p>

        <Paper style={{ borderRadius: 10, padding: 20 }}>
          <Grid container spacing={12}>
            {_.map(
              this.props.details.confirmationData,
              this.renderVariantAListItem
            )}
          </Grid>
        </Paper>

        <Column
          textAlign="right"
          textAlignSM="center"
          width="100%"
          margin="40px 0 0 0"
        >
          <FormWizardBackForward
            nextLabel="Submit"
            onNext={() =>
              this.logDataReview(
                "Worker - Candidate - Data Reviewed - Data Submitted",
                this.props.handleRetrySubmit
              )
            }
          />
        </Column>
      </div>
    );
  }
}

TabPIIRetryConfirmation.propTypes = propTypes;
