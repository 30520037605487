import React from "react";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import _ from "lodash";
import Anchor from "../../../components/Anchor";
import messages from "../messages";
import { errorStyles } from "./styles";

// const { mvr } = messages;
// const { defaultMessage } = messages.clearError;

const MVRErrorMessage = ({ data }) => (
  <p style={errorStyles}>
    {data === "clear" ? (
      <>
        <FormattedMessage {...messages.clearError} />
        <Anchor
          color="inherit"
          textDecoration="none"
          hoverColor="inherit"
          hoverTextDecoration="underline"
          href="mailto:support@turn.ai"
        >
          support@turn.ai
        </Anchor>
      </>
    ) : _.isString(data) ? (
      data
    ) : null}
  </p>
);

MVRErrorMessage.propTypes = {
  data: PropTypes.object.isRequired
};

export default MVRErrorMessage;
