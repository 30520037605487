import React from "react";
import Column from "../Column";
import Row from "../Row";
import FlexRow from "../Row/flex-row";
import CardFillingContainer from "./container";
import PropTypes from "prop-types";
import styles from "./styles";
import { ReactComponent as ManualFill } from "../../icons/manual_fill.svg";
import { ReactComponent as AutomaticFill } from "../../icons/automatic_fill.svg";
import { white } from "../../styles/constants";

const CardFilling = ({ title, paragraphText, image, link, doMVR }) => {
  const imgElements = {
    automatic: AutomaticFill,
    manual: ManualFill
  };

  const Img = imgElements[image];

  return (
    <CardFillingContainer
      id="cardAutomaticContainer"
      onClick={link}
      style={{
        ...styles.containerCard.body,
        paddingBottom: `${doMVR ? "16px" : "20px"}`
      }}
    >
      <Row>
        <Column textAlign="left" textAlignSM="center" width="100%">
          <h1 style={{ ...styles.containerCard.title, color: white }}>
            {title}
          </h1>
        </Column>
      </Row>
      <FlexRow itemsCenter>
        <Column textAlign="left">
          <p
            style={{
              ...styles.containerCard.paragraphText,
              color: white
            }}
          >
            {paragraphText}
          </p>
        </Column>
        <Column>
          <Img />
        </Column>
      </FlexRow>
    </CardFillingContainer>
  );
};

CardFilling.propTypes = {
  title: PropTypes.string,
  paragraphText: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.func,
  doMVR: PropTypes.bool
};

export default CardFilling;
