import styled from "styled-components";

const ListContainer = styled.div`
  background-color: ${props => {
    if (props.indent) return "white";
    return props.simple ? "white" : "inherit";
  }};
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: ${props => {
    if (props.indent) return "0 7%";
    return props.simple ? "0" : "0 5%";
  }};
`;

export default ListContainer;
