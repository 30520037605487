import React, { PureComponent } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { renderDetailValue, valueExists } from "turn-shared";

import { CandidateReportStyles as styles } from "../../styles";
import FormattedHeader from "../FormattedHeader";

import Row from "../Row";
import DataItem from "./DataItem";
import Check from "./Check";
import Text from "../Text";

import messages from "./messages";
import Divider from "../Divider";

const propTypes = {
  mvr: PropTypes.object.isRequired,
  reportDate: PropTypes.string
};

class MVR extends PureComponent {
  get smallWidth() {
    if (this.props.isPDFReport) return "25%";
    return "100%";
  }
  renderLicenses = () =>
    this.props.mvr.licenses.length > 0 ? (
      <div style={styles.sectionContent} data-test="licenses">
        {this.props.mvr.licenses.map((license, key) => (
          <Row key={key} data-test="license">
            <FormattedHeader inherit message={license.type} />
            <Row
              margin="20px 40px 20px 20px"
              padding="0 0 0 20px"
              borderLeft="1px solid lightgray"
              data-test="license-details"
            >
              {_.map(license, (record, key) => {
                if (key === "statuses") {
                  return valueExists(record) ? (
                    <DataItem
                      key={key}
                      width="25%"
                      widthMD="50%"
                      widthSM={this.smallWidth}
                      title={<FormattedHeader inherit message={key} />}
                      text={record[0].code}
                    />
                  ) : null;
                } else {
                  return valueExists(record) ? (
                    <DataItem
                      key={key}
                      width="25%"
                      widthMD="50%"
                      widthSM={this.smallWidth}
                      title={<FormattedHeader inherit message={key} />}
                      text={renderDetailValue(record)}
                    />
                  ) : null;
                }
              })}
            </Row>
          </Row>
        ))}
      </div>
    ) : null;
  renderViolations = () =>
    this.props.mvr.violations.length > 0 ? (
      <div style={styles.sectionContent} data-test="violations">
        <FormattedHeader inherit message="Violations" />
        {this.props.mvr.violations.map((violation, key) => (
          <Row
            key={key}
            margin="20px 40px 0 20px"
            padding="0 0 0 20px"
            borderLeft="1px solid lightgray"
          >
            {_.map(
              { description: violation.description, ...violation },
              (record, key) =>
                valueExists(record) ? (
                  <DataItem
                    key={key}
                    width="25%"
                    widthMD="50%"
                    widthSM={this.smallWidth}
                    title={<FormattedHeader inherit message={key} />}
                    text={renderDetailValue(record)}
                  />
                ) : null
            )}
          </Row>
        ))}
      </div>
    ) : (
      <div style={styles.sectionContent} data-test="violations">
        <FormattedHeader inherit message="Violations" />
        <Row padding="20px 40px 0 20px">
          <Text style={styles.clear}>
            <FormattedMessage {...messages.clear} />
          </Text>
        </Row>
      </div>
    );
  renderAccidents = () =>
    this.props.mvr.accidents.length > 0 ? (
      <div style={styles.sectionContent} data-test="accidents">
        <FormattedHeader inherit message="Accidents" />
        {this.props.mvr.accidents.map((accident, key) => (
          <Row
            key={key}
            margin="20px 40px 0 20px"
            padding="0 0 0 20px"
            borderLeft="1px solid lightgray"
          >
            {_.map(
              { description: accident.description, ...accident },
              (record, key) =>
                valueExists(record) ? (
                  <DataItem
                    key={key}
                    width="25%"
                    widthMD="50%"
                    widthSM={this.smallWidth}
                    title={<FormattedHeader inherit message={key} />}
                    text={renderDetailValue(record)}
                  />
                ) : null
            )}
          </Row>
        ))}
      </div>
    ) : (
      <div style={styles.sectionContent} data-test="accidents">
        <FormattedHeader inherit message="Accidents" />
        <Row padding="20px 40px 0 20px">
          <Text style={styles.clear}>
            <FormattedMessage {...messages.clear} />
          </Text>
        </Row>
      </div>
    );
  renderActions = () =>
    this.props.mvr.actions.length > 0 ? (
      <div style={styles.sectionContent} data-test="actions">
        <FormattedHeader inherit message="Actions" />
        {this.props.mvr.actions.map((action, key) => (
          <Row
            key={key}
            margin="20px 40px 0 20px"
            padding="0 0 0 20px"
            borderLeft="1px solid lightgray"
          >
            {_.map(
              { description: action.description, ...action },
              (record, key) =>
                valueExists(record) ? (
                  <DataItem
                    key={key}
                    width="25%"
                    widthMD="50%"
                    widthSM={this.smallWidth}
                    title={<FormattedHeader inherit message={key} />}
                    text={renderDetailValue(record)}
                  />
                ) : null
            )}
          </Row>
        ))}
      </div>
    ) : (
      <div style={styles.sectionContent} data-test="actions">
        <FormattedHeader inherit message="Actions" />
        <Row padding="20px 40px 0 20px">
          <Text style={styles.clear}>
            <FormattedMessage {...messages.clear} />
          </Text>
        </Row>
      </div>
    );
  render() {
    const { mvr, ...rest } = this.props;
    const defaultCheckNameId = "detailCheckName";
    const defaultCheckStatusId = "detailCheckStatus";

    if (!mvr) return null;
    if (_.isEmpty(mvr) || typeof mvr === "string") {
      return (
        <div style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.mvr} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <Divider height="1px" />
        </div>
      );
    }
    return (
      <div style={styles.section}>
        <Check
          check={<FormattedMessage {...messages.mvr} />}
          noDivider
          containerStyle={styles.checks.titleContainer}
          nameId={defaultCheckNameId}
          statusId={defaultCheckStatusId}
          {...rest}
        />
        {this.renderLicenses()}
        {this.renderViolations()}
        {this.renderAccidents()}
        {this.renderActions()}
        <Divider height="1px" />
      </div>
    );
  }
}

MVR.propTypes = propTypes;

export default MVR;
