import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { ListDetailStyles as styles } from "../../styles";
import FormattedHeader from "../FormattedHeader";
import DetailItem from "../DetailItem";
import { renderDetailValue, valueExists } from "turn-shared";

class Detail extends PureComponent {
  static defaultProps = {
    width: ""
  };
  isExcluded = property => this.props.exclude.indexOf(property) > -1;
  renderDetailItem = (key, value) => {
    if (!valueExists(value)) return null;
    if (Array.isArray(this.props.exclude) && this.isExcluded(key)) return null;
    return (
      <DetailItem
        key={key}
        detail={{
          label: <FormattedHeader message={key} />,
          value: renderDetailValue(value, this.props.deep)
        }}
        width={this.props.width}
      />
    );
  };
  render() {
    if (!this.props.data) return null;
    const keys = Object.keys(this.props.data);
    return keys.length > 0 ? (
      <dl style={styles.detailContainer}>
        {keys.map(key => this.renderDetailItem(key, this.props.data[key]))}
      </dl>
    ) : null;
  }
}

Detail.propTypes = {
  data: PropTypes.object,
  deep: PropTypes.bool,
  exclude: PropTypes.array,
  width: PropTypes.string
};

export default Detail;
