import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import _ from "lodash";

import { pickColor } from "turn-shared";
import { oneOffChecksStyle as styles } from "./style";
import Highlighted from "../../../components/Highlighted";
import Link from "../../../components/Link";

const Check = observer(
  class Check extends Component {
    static propTypes = {
      item: PropTypes.object
    };

    renderName = name => (
      <Highlighted black light size="16px" style={styles.one_third}>
        {_.startCase(name)}
      </Highlighted>
    );

    renderState = state => (
      <Highlighted style={{ color: pickColor(state), ...styles.one_third }}>
        {`${_.upperCase(state)}${state === "processing" ? "..." : ""}`}
      </Highlighted>
    );

    renderLink = document => (
      <Link link="View Report" href={document} style={styles.link} />
    );

    render() {
      const { item } = this.props;
      if (!item) return null;

      const { name, state, resolution, report_url } = item;
      const _state = state === "fulfilled" ? resolution : state;
      return (
        <>
          {this.renderName(name)}
          {this.renderState(_state)}
          {this.renderLink(report_url)}
        </>
      );
    }
  }
);

export default Check;
