import { defineMessages } from "react-intl";

export default defineMessages({
  changeCredentials: {
    id: "app.containers.ValidationPage.changeCredentials",
    defaultMessage: "Try other credentials"
  },
  didntRequest: {
    id: "app.containers.ValidationPage.didntRequest",
    defaultMessage: "Didn't request a Background Report?"
  },
  letUsKnow: {
    id: "app.containers.ValidationPage.letUsKnow",
    defaultMessage: "Let us know at "
  },
  email: {
    id: "app.containers.ValidationPage.email",
    defaultMessage: "support@turn.ai"
  },
  choose: {
    id: "app.containers.ValidationPage.choose",
    defaultMessage: "Choose a credential"
  },
  chooseHint: {
    id: "app.containers.ValidationPage.chooseHint",
    defaultMessage: "Last four digits"
  },
  ssn: {
    id: "app.containers.ValidationPage.snn.label",
    defaultMessage: "SSN"
  },
  drivers_license_number: {
    id: "app.containers.ValidationPage.drivers_license_number",
    defaultMessage: "Driver’s License"
  },
  phone_number: {
    id: "app.containers.ValidationPage.phone_number",
    defaultMessage: "Phone Number"
  }
});
