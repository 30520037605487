import styled from "styled-components";

const SubTitle = styled.p`
  color: ${props => props.color};
  font-size: ${props => props.size || "14px"};
  font-weight: ${props => props.weight || 300};
  margin: ${props => props.margin || "0 0 14px 0"};
  display: ${props => props.display || "block"};
  text-align: ${props => props.textAlign || "inherit"};
  max-width: ${props => props.maxWidth || undefined};
  font-family: ${props => props.family || undefined};

  @media (max-width: 600px) {
    margin: ${props => props.marginSM || "0 0 14px 0"};
    display: ${props => props.displaySM || "block"};
    text-align: ${props => props.textAlignSM || "inherit"};
  }
`;

export default SubTitle;
