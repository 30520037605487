import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import { ModalLoaderStyles } from "../../styles";
import { observer } from "mobx-react/custom";
import ChaseDotsLoader from "../ChaseDotsLoader";
import { withStyles } from "@material-ui/styles";

const styles = {
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  }
};

class ModalLoader extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  render() {
    return (
      <Dialog
        modal
        open
        style={ModalLoaderStyles.modalLoaderBody}
        PaperProps={{
          classes: {
            root: this.props.classes.paper
          }
        }}
      >
        <ChaseDotsLoader />
      </Dialog>
    );
  }
}

const ModalLoaderWrapped = withStyles(styles)(ModalLoader);

class Loader extends React.Component {
  render() {
    return <ModalLoaderWrapped />;
  }
}

export default observer(Loader);
