import React, { useState } from "react";
import styled from "styled-components";
import { Icon as IconBase, withStyles } from "@material-ui/core";
import _ from "lodash";

import { primary, secondary, periwinkle } from "../../../styles/constants";

export const SubscriptionEditPicker = styled.ul`
  list-style-type: none;
  margin: 0 21px;
  padding: 0;

  li {
    margin-bottom: 28px;
  }
`;

const SubscriptionEditPickerItemWrapper = styled.li`
  position: relative;
  margin: 0;
  width: 100%;
  height: 52px;

  label {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    align-items: center;
    border-radius: 7px;
    padding: 12px;
    color: ${primary};
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);

    & > .content {
      flex: 1;
      font-weight: bold;
      font-size: 14px;
    }
  }

  input[type="radio"] {
    display: none;

    & + label > .check {
      color: ${periwinkle};
    }
  }

  input[type="radio"]:checked + label {
    & > .check {
      color: ${secondary};
    }
  }
`;

const Icon = withStyles({
  root: {
    width: "auto",
    height: "auto",
    overflow: "visible",
    verticalAlign: "middle",
    fontSize: 21,
    marginRight: 7
  }
})(IconBase);

export const SubscriptionEditPickerItem = ({ children, ...props }) => {
  const [inputId] = useState(_.uniqueId("sed_"));

  return (
    <SubscriptionEditPickerItemWrapper>
      <input {...props} type="radio" id={inputId} />
      <label htmlFor={inputId}>
        <span className="content">{children}</span>
        <Icon className="fas fa-check-circle check" />
      </label>
    </SubscriptionEditPickerItemWrapper>
  );
};
