import React, { useState } from "react";
import PropTypes from "prop-types";
import EmailedContainer from "./EmailedContainer";
import { ReactComponent as OpenEmailIcon } from "../../../icons/mail-icon.svg";
import { ReactComponent as HandIcon } from "../../../icons/hand-icon.svg";
import StatusButton from "../StatusButton";
import { turnAPI } from "../../../services";

function Emailed({ candidate }) {
  const [sent, setSent] = useState(false);

  const reSendEmail = async event => {
    event.stopPropagation();
    await turnAPI.resendEmail(candidate.turnId);
    setSent(true);
  };

  return (
    <>
      <EmailedContainer>
        <p>
          Emailed
          <span>
            <OpenEmailIcon width="20px" />
            {candidate.numberOfTimesOpened}
            <HandIcon width="20px" />
            {candidate.numberOfTimesClicked}
          </span>
        </p>
        <StatusButton
          id="statusBadgeContainer"
          disabled={sent}
          text={sent ? "Sent" : "Email Again"}
          callback={reSendEmail}
        />
      </EmailedContainer>
    </>
  );
}

Emailed.propTypes = {
  candidate: PropTypes.object
};

export default Emailed;
