import styled from "styled-components";
import { slateGray } from "../../styles/constants";

const HeaderStyle = styled.p`
  text-align: right;
  font-size: 12px;
  color: ${slateGray};
  line-height: 1.4;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

export default HeaderStyle;
