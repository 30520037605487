import React, { Component } from "react";
import { PropTypes } from "prop-types";

import ListDetailView from "../../components/ListDetailView";
import { orange, green, red } from "../../styles/constants";
import { CandidateReportStyles as reportStyles } from "../../styles";
import messages from "./messages";
import PendingReview from "../../components/PendingReview";
import PendingMVRReview from "../../components/PendingMVRReview";
import PendingAgeReview from "../../components/PendingAgeReview";
import { NATIONAL_WATCHLIST, CLEAR, HIT, REVIEW } from "../../constants";
import ProcessingMVR from "../../components/ProcessingMVR";
import {
  faCheckCircle,
  faExclamationTriangle,
  faPauseCircle
} from "@fortawesome/pro-solid-svg-icons";
import PanelCollapsible from "../../components/PanelCollapsible";
import withContext from "../../contextAPI/context-HOC";

export class Watchlists extends Component {
  static propTypes = {
    worker: PropTypes.object,
    clear: PropTypes.bool,
    review: PropTypes.bool,
    hit: PropTypes.bool,
    context: PropTypes.shape({
      checks: PropTypes.object
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      expand: false
    };
  }

  toggleExpand = () => {
    this.setState(prevProps => ({ expand: !prevProps.expand }));
  };

  setExpand = expand => {
    this.setState({ expand });
  };

  renderContent = () => {
    const { worker } = this.props;
    if (worker.isProcessingMVR && worker.shouldShowCheck(NATIONAL_WATCHLIST))
      return <ProcessingMVR message={messages.watchlists} />;
    if (worker.isPendingReview && worker.shouldShowCheck(NATIONAL_WATCHLIST))
      return <PendingReview message={messages.watchlists} />;
    if (worker.isPendingMVRReview && worker.shouldShowCheck(NATIONAL_WATCHLIST))
      return <PendingMVRReview message={messages.watchlists} />;
    if (worker.isPendingAgeReview && worker.shouldShowCheck(NATIONAL_WATCHLIST))
      return <PendingAgeReview message={messages.watchlists} />;
    return (
      <ListDetailView
        data={[...worker.watchlistData]}
        simple
        masterKey="source"
        exclude={[
          "original_id",
          "original_record",
          "worker_info",
          "remarks",
          "score"
        ]}
        secondary="worker_info"
        highlight="remarks"
        expand={this.state.expand}
      />
    );
  };

  get shouldCollapse() {
    const { worker } = this.props;
    if (worker.workerIsPendingReview || !worker.watchlistData) return false;
    return worker.watchlistData.length === 0;
  }

  get color() {
    // eslint-disable-next-line react/prop-types
    return this.props.status == CLEAR
      ? green
      : this.props.status == REVIEW
      ? orange
      : this.props.status == HIT
      ? red
      : green;
  }

  get icon() {
    return this.props.status == CLEAR
      ? faCheckCircle
      : this.props.status == REVIEW
      ? faPauseCircle
      : this.props.status == HIT
      ? faExclamationTriangle
      : faCheckCircle;
  }

  render() {
    const { checks = {} } = this.props.context;
    const { worker } = this.props;

    const shouldExpand =
      !worker.isProcessingMVR &&
      !worker.isPendingReview &&
      !worker.isPendingAgeReview &&
      !worker.isPendingMVRReview;
    return (
      <div>
        <PanelCollapsible
          color={this.color}
          title={messages.watchlists}
          titleStyle={reportStyles.title}
          icon={this.icon}
          iconStyle={reportStyles.checkedIcon}
          collapsible={!this.shouldCollapse}
          expandAll={shouldExpand}
          expandAllProps={{
            onClick: this.toggleExpand,
            toggled: this.state.expand
          }}
          setExpand={this.setExpand}
          expandAllChecks={checks.expandAllChecks}
          panelProps={{
            style: {
              filter:
                "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
            }
          }}
        >
          {this.renderContent()}
        </PanelCollapsible>
      </div>
    );
  }
}

export default withContext(Watchlists);
