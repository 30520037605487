import React, { useState } from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import {
  IconButton,
  Icon,
  ClickAwayListener,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { primary, slateGray, periwinkle } from "../../styles/constants";

export const infoColor = "#278CF4";

export const ScreeningWidgetWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: ${slateGray};
  min-width: 120px;
`;
export const ScreeningWidgetTitle = styled.div`
  display: inline;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${primary};
  margin: 0 -10px 10px -10px
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TooltipInnerWrapper = styled.div`
  display: flex;
`;
const TooltipIconWrapper = styled.div`
  display: flex;
  flex: 0;
  padding: 12px;
  align-items: center;
  border-bottom: 2px solid ${infoColor};
`;
const TooltipTextWrapper = styled.div`
  flex: 1;
  padding: 8px;
  font-size: 11px;
  text-align: left;
  width: max-content;
  border-bottom: 2px solid ${periwinkle};
`;
const TooltipCloseIconWrapper = styled(TooltipIconWrapper)`
  align-items: flex-start;
  padding: 8px 2px;
  border-bottom: 2px solid ${periwinkle};
`;

const ScreeningWidgetTooltipCloseIcon = ({ ...props }) => {
  return (
    <TooltipCloseIconWrapper>
      <IconButton size="small" style={{ width: 12, height: 12 }} {...props}>
        <Icon
          className="fas fa-times"
          style={{ fontSize: 10, color: slateGray, overflow: "visible" }}
        />
      </IconButton>
    </TooltipCloseIconWrapper>
  );
};

const tooltipStyles = theme => ({
  tooltip: {
    background: theme.palette.common.white,
    color: slateGray,
    padding: 0
  }
});
const ScreeningWidgetTooltip = withStyles(tooltipStyles)(
  ({ children, classes, ...props }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
      <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
        <Tooltip
          PopperProps={{ disablePortal: true }}
          placement="bottom-end"
          onClose={() => setShowTooltip(false)}
          classes={{ tooltip: classes.tooltip }}
          open={showTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <TooltipInnerWrapper>
              <TooltipIconWrapper>
                <div>
                  <Icon
                    className="fas fa-info-circle"
                    style={{
                      fontSize: 24,
                      color: infoColor,
                      overflow: "visible"
                    }}
                  />
                </div>
              </TooltipIconWrapper>
              <TooltipTextWrapper>{children}</TooltipTextWrapper>
              <ScreeningWidgetTooltipCloseIcon
                onClick={() => setShowTooltip(false)}
              />
            </TooltipInnerWrapper>
          }
          {...props}
        >
          <IconButton
            size="small"
            style={{
              width: 21,
              height: 21,
              position: "absolute",
              right: 8,
              top: 8
            }}
            onClick={() => setShowTooltip(true)}
          >
            <Icon
              className="fas fa-info-circle"
              style={{ fontSize: 12, color: infoColor, overflow: "visible" }}
            />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    );
  }
);

export const ScreeningWidget = ({
  title,
  tooltip,
  WrapperComponent,
  children,
  ...props
}) => {
  WrapperComponent = WrapperComponent || ScreeningWidgetWrapper;

  return (
    <WrapperComponent {...props}>
      {tooltip && <ScreeningWidgetTooltip>{tooltip}</ScreeningWidgetTooltip>}
      {title &&
        (React.isValidElement(title) ? (
          title
        ) : (
          <ScreeningWidgetTitle>{title}</ScreeningWidgetTitle>
        ))}
      {children}
    </WrapperComponent>
  );
};
ScreeningWidget.propTypes = {
  title: propTypes.string,
  tooltip: propTypes.string,
  WrapperComponent: propTypes.any,
  children: propTypes.node
};

export const ScreeningWidgetLoader = ({ isLoading, children, ...props }) => {
  if (isLoading) {
    return <CircularProgress style={{ margin: "auto" }} size={18} {...props} />;
  } else if (children === undefined || children === null) {
    return "-";
  } else {
    return children;
  }
};
ScreeningWidgetLoader.propTypes = {
  isLoading: propTypes.bool,
  children: propTypes.node,
  ...CircularProgress.propTypes
};
