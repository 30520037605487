import { useState } from "react";
import { turnAPI } from "../../../../../services";
import toastr from "toastr";
import {
  DEFAULT_ERROR_MESSAGE,
  OPPOSITE_STATUS_MAPPING,
  TOASTER_OPTIONS
} from "./constants";

const usecontroller = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const setAlert = () => {
    toastr.options = TOASTER_OPTIONS;

    if (isSuccess) {
      toastr.success(alertMessage);
      setTimeout(() => {
        setIsSuccess(false);
        setIsLoading(false);
        window.location.reload();
      }, 1000);
    }

    if (isError) {
      toastr.error(alertMessage || DEFAULT_ERROR_MESSAGE);
      setTimeout(() => setIsError(false), 3000);
    }
  };

  const enrollmentActionMapping = {
    mvr: {
      enrolled: async turn_id => await turnAPI.unenrollCandidateMVR(turn_id),
      unenrolled: async turn_id => await turnAPI.enrollCandidateMVR(turn_id)
    },
    crim: {
      enrolled: () => setIsLoading(false), //! update when endpoint exists
      unenrolled: () => setIsLoading(false) //! update when endpoint exists
    }
  };

  const handleErrorStates = error => {
    setAlertMessage(error.data.message);
    setIsError(true);
    setIsLoading(false);
  };

  const triggerEnrollmentAction = async (
    enrollmentType,
    currentStatus,
    turn_id
  ) => {
    setIsLoading(true);
    const response = await enrollmentActionMapping[enrollmentType][
      currentStatus
    ](turn_id);
    if (!response) return;
    switch (response.status) {
      case 200:
        setAlertMessage(
          `Worker has been successfully ${OPPOSITE_STATUS_MAPPING[currentStatus]}.`
        );
        setIsSuccess(true);
        break;
      case 201:
        handleErrorStates(response);
        break;
      default:
        handleErrorStates(response);
        break;
    }
  };

  return {
    isLoading,
    setAlert,
    triggerEnrollmentAction
  };
};

export default usecontroller;
