/* eslint-disable no-undef */
/* eslint-disable no-fallthrough */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import CircularProgress from "material-ui/CircularProgress";
import { observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FlatButton from "material-ui/FlatButton";
import { ModalStyles as styles } from "../../styles";
import FormModel from "../../models/form";
import { turnAPI } from "../../services";
import ModalTrigger from "../../components/WorkerCheckModal/trigger";
import Form from "../WorkerCheckModal/form";
import Confirm from "../../components/WorkerCheckModal/confirm";
// import EmailSent from "../../components/WorkerCheckModal/email-sent";
import TrialExpired from "../../components/WorkerCheckModal/trial-expired";
import ErrorDialog from "../../components/ErrorDialog";
import Button from "@material-ui/core/Button";
import Img from "../../components/Img";

import messages from "./messages";

const propTypes = {
  auth: PropTypes.object,
  workers: PropTypes.object,
  autoOpen: PropTypes.bool,
  handleClose: PropTypes.func
};

const Modal = observer(
  class Modal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        open: false,
        form: { errors: "" },
        status: "not-ready",
        errorOpen: false
      };
      this.actions = {
        "not-ready": [
          <Button
            onMouseDown={this.handleClose}
            id="close_modal"
            key="0"
            style={styles.cancelButton}
          >
            <div style={{ marginRight: "8px" }}>
              <i className="fas fa-times-circle" />
            </div>
            <FormattedMessage {...messages.buttonCancel} />
          </Button>,
          <Button
            onClick={this.confirmData}
            id="submit_modal"
            key="1"
            style={styles.nextButton}
          >
            <FormattedMessage {...messages.buttonNext} />
          </Button>
        ],
        ready: [
          <div key="0">
            <Button
              onMouseDown={this.handleClose}
              id="close_modal"
              key="3"
              style={styles.cancelButton}
            >
              <div style={{ marginRight: "8px" }}>
                <i className="fas fa-times-circle" />
              </div>
              <FormattedMessage {...messages.buttonCancel} />
            </Button>
            <Button
              onClick={this.handleBack}
              id="back_modal"
              key="4"
              style={styles.editButton}
            >
              <div style={{ marginRight: "8px" }}>
                <i className="fas fa-edit" />
              </div>
              <FormattedMessage {...messages.buttonEdit} />
            </Button>
          </div>,

          <Button
            onClick={this.checkWorker}
            id="submit_modal"
            key="5"
            style={styles.nextButton}
          >
            <FormattedMessage {...messages.buttonSubmit} />
          </Button>
        ],
        "trial-expired": [
          <FlatButton
            primary
            label={<FormattedMessage {...messages.buttonClose} />}
            onClick={this.handleClose}
            id="close_modal"
            key="6"
          />
        ]
      };
    }

    componentDidMount() {
      if (this.props.autoOpen) {
        this.handleOpen();
      }
    }

    reset = () => {
      this.setState({
        open: true,
        form: new FormModel({
          packages: this.state.form.partner_packages,
          default_package: this.state.form.package_id
        }),
        status: "not-ready"
      });
    };

    handleBack = () => {
      this.setState({ status: "not-ready" });
    };

    handleOpen = async () => {
      await this.reset();
      this.state.form.setLoading(true);
      const response = await turnAPI.newCandidate();
      switch (response.status) {
        case 200:
          this.state.form.setNewCandidateEndpointData(response.data);
          this.setState({ status: "not-ready" });
          break;
        case 401:
          this.props.auth.logout();
          break;
        case 402:
          this.setState({ status: "trial-expired" });
          break;
        default:
          this.setState({
            errorOpen: true
          });
          break;
      }
      this.state.form.setLoading(false);
    };

    handleClose = () => {
      this.setState({ open: false });
      if (_.isFunction(this.props.handleClose)) this.props.handleClose();
    };

    confirmData = async () => {
      this.state.form.touch("submit");
      this.state.form.touch("firstName");
      this.state.form.touch("lastName");
      this.state.form.touch("email");
      if (this.state.form.errors === "") await this.validate();
    };

    validate = async () => {
      this.state.form.setLoading(true);
      await this.validateEmail();
      this.state.form.setLoading(false);
      if (this.state.form.errors === "") this.setState({ status: "ready" });
    };

    checkWorker = async () => {
      this.state.form.setLoading(true);
      const response = await turnAPI.checkWorker(this.state.form.params);
      switch (response.status) {
        case 200:
          this.handleCheckResponse(response.data);
          break;
        case 401:
          this.props.auth.logout();
          break;
        case 402:
          this.setState({ status: "trial-expired" });
          break;
        case 422:
          if (response.data.phone_number) {
            this.state.form.setPhoneNumberInvalidError(
              response.data.phone_number
            );
            this.setState({ status: "not-ready" });
            break;
          }
        default:
          this.setState({
            errorOpen: true
          });
          break;
      }
      this.state.form.setLoading(false);
    };

    validateEmail = async () => {
      const response = await turnAPI.validateEmail(this.state.form.email);
      switch (response.status) {
        case 200:
          this.state.form.setEmailServiceError(
            response.data.is_verified ? "verified" : "refuted"
          );
          break;
        case 500:
          this.setState({
            errorOpen: true
          });
          break;
        default:
          this.state.form.setEmailServiceError("unknown");
          break;
      }
    };

    getWorkerEmail = data => {
      if (!data.email && data.worker) {
        return data.worker;
      }
      if (!data.worker) {
        return data;
      }
      data.worker["email"] = data.email;

      return data;
    };

    handleCheckResponse = data => {
      if (data.is_fcra) {
        this.handleEmailResponse(data);
      } else {
        this.handleWorkerResponse(data);
      }
    };

    handleEmailResponse = data => {
      let worker = this.getWorkerEmail(data);

      this.props.workers.addWorker(worker);
      this.props.fetch();
      this.setState({ status: "email-sent" });
    };

    handleWorkerResponse = data => {
      const ssnRequired = data.need.includes("ssn");
      if (ssnRequired && this.state.form.requireSSN) {
        this.state.form.setNameServiceError("invalid");
        this.setState({ status: "not-ready" });
      } else if (ssnRequired) {
        this.state.form.setRequireSSN(true);
        this.state.form.touch("ssn");
        this.state.form.setSSNServiceError();
        this.setState({ status: "not-ready" });
      } else {
        this.props.workers.addWorker(data);
        this.handleClose();
      }
    };

    renderModalContent = () => {
      switch (this.state.status) {
        // case "email-sent":
        //   return <EmailSent />;
        case "ready":
          return <Confirm model={this.state.form} />;
        case "trial-expired":
          return <TrialExpired />;
        case "not-ready":
        default:
          return <Form model={this.state.form} />;
      }
    };

    renderTitle = () => {
      switch (this.state.status) {
        case "email-sent":
          return "The Candidate has been notified";
        case "ready":
          return "Confirm Information";
        case "trial-expired":
          return "Your trial has expired";
        case "not-ready":
        default:
          return <span>Add Candidate</span>;
      }
    };

    renderLoading = () =>
      this.state.form.loading ? (
        <div style={styles.progressContainer}>
          <CircularProgress style={styles.progress} size={80} thickness={5} />
        </div>
      ) : null;

    render() {
      const { status, open, errorOpen } = this.state;
      const paperClass =
        status === "not-ready"
          ? "turn-modal-paper-add-candidate"
          : "turn-modal-paper-add-candidate-thin";

      return (
        <>
          {!this.props.autoOpen ? (
            <ModalTrigger handleOpen={this.handleOpen} />
          ) : null}

          {status === "email-sent" ? (
            // <EmailSentDialog />
            <Dialog
              fullWidth
              open={open}
              onClose={this.handleClose}
              autoScrollBodyContent
              classes={{
                paper: paperClass
              }}
            >
              <DialogContent style={{ padding: "47px" }}>
                {this.renderLoading()}

                <div style={styles.imgContainer}>
                  <div style={styles.imgDimensionsContainer}>
                    <Img type="discoverySuccess" style={styles.headerImage} />
                  </div>
                </div>
                <div style={styles.messages}>
                  <div style={styles.gralMessageContainer}>
                    {this.renderTitle()}
                  </div>
                  <div style={styles.specificMessageContainer}>
                    <FormattedMessage {...messages.emailSent} />
                  </div>
                </div>
                <div style={styles.blackButtonContainer}>
                  <Button
                    onClick={this.reset}
                    id="ok_button"
                    key="1"
                    style={styles.blackButtonEmailSent}
                  >
                    <FormattedMessage {...messages.buttonAgainAddCandidate} />
                  </Button>
                  <div style={styles.closeButtonContainer}>
                    <Button
                      onClick={this.handleClose}
                      id="close_button"
                      key="1"
                      style={styles.closeWindowButton}
                    >
                      <div style={{ marginRight: "8px" }}>
                        <i className="fas fa-times-circle" />
                      </div>
                      <FormattedMessage {...messages.buttonClose} />
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          ) : (
            <Dialog
              fullWidth
              open={open}
              onClose={this.handleClose}
              autoScrollBodyContent
              classes={{
                paper: paperClass
              }}
            >
              <DialogTitle style={{ padding: "6px 36px 40px 36px" }}>
                <h2 style={{ ...styles.title }}>{this.renderTitle()}</h2>
              </DialogTitle>
              <DialogContent style={{ padding: "0px 36px 10px 36px" }}>
                {this.renderLoading()}
                {this.renderModalContent()}
              </DialogContent>
              {status === "ready" ? (
                <DialogActions style={styles.buttonsContainerSpaceBetween}>
                  {this.actions[status]}
                </DialogActions>
              ) : (
                <DialogActions style={styles.buttonsContainerFlexEnd}>
                  {this.actions[status]}
                </DialogActions>
              )}
            </Dialog>
          )}

          <ErrorDialog
            open={errorOpen}
            onPress={() => {
              this.setState({
                errorOpen: false
              });
            }}
          />
        </>
      );
    }
  }
);

Modal.propTypes = propTypes;

export default Modal;
