import React from "react";
import PropTypes from "prop-types";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import { ReportAuthorizationStyles as styles } from "../../../styles";
import { primary } from "../../../styles/constants";

export class Header extends React.PureComponent {
  static propTypes = {
    title: PropTypes.object,
    subtitle: PropTypes.object
  };

  render() {
    return (
      <div style={styles.header}>
        <Title big margin="0" color={primary}>
          {this.props.title}
        </Title>
        <Text margin="5px 0 0 0">{this.props.subtitle}</Text>
      </div>
    );
  }
}
