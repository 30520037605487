export const NONE = "none";
export const RESUME = "Resume checks";
export const APPROVE = "Approve";
export const WITHDRAW = "Withdraw";
export const PRINT = "Print";
export const RECHECK = "Recheck";
export const MVR_MONITORING = "Driving Monitoring";
export const CRIM_MONITORING = "Criminal Monitoring";
export const UNAUTHORIZED_STATES = ["CA"];

export const ENROLLMENT_LABEL_MAPPING = {
  mvr: MVR_MONITORING,
  crim: CRIM_MONITORING
};

const tooltipPositionAfterCommonProps = `
  content: "";
  position: absolute;
  border: 6px solid;
`;

const topPositionAfterCommonStyles = `
  ${tooltipPositionAfterCommonProps}
  top: 100%;
  border-color: black transparent transparent transparent;
`;

const bottomPositionAfterCommonStyles = `
  ${tooltipPositionAfterCommonProps}
  bottom: 100%;
  border-color: transparent transparent black transparent;
`;

export const TOOLTIP_POSITION = {
  top_left: `
    bottom: 120%;
    left: 9px;
    
    &::after {
      left: 12px;
      margin-left: -8px;
      ${topPositionAfterCommonStyles}
    }
  `,
  top_right: `
    bottom: 120%;
    right: 9px;

    &::after {
      right: 12px;
      margin-right: -8px;
      ${topPositionAfterCommonStyles}
    }
  `,
  bottom_left: `
    top: 100%;
    left: 12px;

    &::after {
      left: 12px;
      margin-left: -8px;
      ${bottomPositionAfterCommonStyles}
    }
  `,
  bottom_right: `
    top: 100%;
    right: 12px;

    &::after {
      right: 12px;
      margin-right: -8px;
      ${bottomPositionAfterCommonStyles}
    }
  `
};
