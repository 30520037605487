import { backgroundGray } from "../../../styles/constants";

export const rejectedCandidateStyles = {
  buttonStyles: {
    display: "inline-block",
    margin: "0 0 0 30px",
    textDecoration: "none",
    fontWeight: 300,
    cursor: "pointer",
    userSelect: "none"
  },
  dividerStyles: {
    position: "absolute",
    height: "2px",
    width: "100%",
    left: "0px"
  },
  reasonsStyles: {
    title: {
      margin: "5px 0px 10px",
      fontSize: "14px",
      color: "#808080",
      display: "block"
    },
    container: {
      padding: "20px 0px"
    },
    reasons: {
      background: backgroundGray,
      margin: 0,
      padding: "10px 15px"
    }
  },
  headerStyles: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: 0
  }
};
