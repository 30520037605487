import { primary } from "../../../src/styles/constants";

const style = {
  wrapper: {
    display: "inline-flex",
    verticalAlign: "middle"
  },
  badgeStyle: {
    backgroundColor: "#ff6c69",
    top: "5px",
    right: "28px",
    width: "8px",
    height: "8px"
  },
  withoutBadgeStyle: {
    display: "none"
  },
  rootBadgeStyle: {
    padding: "0px"
  },
  icon: {
    height: "24px",
    width: "26px",
    fontSize: "24px",
    color: primary
  },
  flatButton: {
    minWidth: "0",
    marginLeft: "14px",
    marginRight: "14px",
    backgroundColor: "transparent"
  }
};
export default style;
