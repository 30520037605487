import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Tooltip, Button, Popover, Divider } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faCrown } from "@fortawesome/pro-solid-svg-icons";

import SortableTableHeader from "../../DataTable/sortable-table-header";
import Panel from "../../Panel";
import { styles } from "./styles";
import { orange } from "../../../styles/constants";

const TableHeader = ({ config }) => {
  return config.map((option, index) => (
    <SortableTableHeader
      key={index}
      ascending={true}
      // sortTable={}
      sortBy="name"
      isSortable={true}
      value={option.value}
      styles={option.value === " " ? { width: "20px" } : {}}
      black
      size="18px  "
    >
      {option.value}
    </SortableTableHeader>
  ));
};
TableHeader.propTypes = {
  config: PropTypes.array
};

const Actions = ({
  memberId,
  handleEdit,
  handleDelete,
  makePrimary,
  isPrimaryContact
}) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);
  const id = open ? "simple-popover" : undefined;

  const triggerEdit = () => {
    setAnchorElement(null);
    handleEdit(memberId);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <div style={styles.actionButton}>
      <FontAwesomeIcon
        id="actionButton"
        aria-describedby={id}
        icon={faEllipsisV}
        onClick={event => setAnchorElement(event.currentTarget)}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Button
          id="editButton"
          aria-describedby={id}
          variant="text"
          style={{
            width: "100%",
            textAlign: "left",
            minWidth: 140,
            borderRadius: 0
          }}
          onClick={() => {
            triggerEdit();
            handleClose();
          }}
        >
          Edit
        </Button>
        <Divider style={{ border: "0.5px solid #E5E7EB" }} />
        {!isPrimaryContact && (
          <Button
            id="makePrimaryButton"
            aria-describedby={id}
            variant="text"
            style={{ width: "100%", minWidth: 140, borderRadius: 0 }}
            onClick={() => {
              makePrimary(memberId);
              handleClose();
            }}
          >
            Make Primary
          </Button>
        )}
        {!isPrimaryContact && (
          <>
            <Divider style={{ border: "0.5px solid #E5E7EB" }} />
            <Button
              id="deleteMemberButton"
              aria-describedby={id}
              variant="text"
              style={{ width: "100%", minWidth: 140, borderRadius: 0 }}
              onClick={() => {
                handleDelete(memberId);
                handleClose();
              }}
            >
              Delete
            </Button>
          </>
        )}
      </Popover>
    </div>
  );
};
Actions.propTypes = {
  memberId: PropTypes.string,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  makePrimary: PropTypes.func,
  isPrimaryContact: PropTypes.bool
};

const MembersTable = props => {
  if (!props || !props.members) return null;

  return props.members.map((member, index) => {
    return (
      <Panel
        id="existingMemberContainer"
        margin="0px 0px 24px 0px"
        padding="0px"
        overflow="hidden"
        style={styles.container}
        key={index}
      >
        <div style={styles.primary}>
          {member.is_primary_contact && (
            <Tooltip title="Primary Contact">
              <FontAwesomeIcon
                id="primaryContactIcon"
                icon={faCrown}
                color={orange}
              />
            </Tooltip>
          )}
        </div>
        <div style={styles.names}>
          <span id="existingMemberName" style={styles.name}>
            {member.name}
          </span>
          <span id="existingMemberEmail" style={styles.email}>
            {member.email}
          </span>
        </div>
        <div style={styles.actions}>
          <Actions
            handleEdit={props.actions.handleEdit}
            {...props.actions}
            memberId={`${index}`}
            isPrimaryContact={member.is_primary_contact}
          />
        </div>
      </Panel>
    );
  });
};

export default MembersTable;
