import React from "react";
import PropTypes from "prop-types";
import { SearchHighlighted } from "../SearchHighlighted";
import withContext from "../../contextAPI/context-HOC";

function CandidateOptionalField({ type, value, id = null }) {
  if (!value) {
    return null;
  }

  return (
    <li>
      {`${type}: `}{" "}
      <span id={id}>
        <SearchHighlighted textToHighlight={value} />
      </span>
    </li>
  );
}

CandidateOptionalField.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string
};

export default withContext(CandidateOptionalField);
