import { aliceBlue, cadetBlue } from "../../styles/constants";

import styled from "styled-components";

const ScrollContainer = styled.div`
  padding: 0px 28px 5px 24px;
  max-height: 400px;
  overflow: auto;

  @media (max-width: 800px) {
    max-height: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${aliceBlue};
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${cadetBlue};
    border-radius: 25px;
  }
`;

export default ScrollContainer;
