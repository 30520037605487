import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react/custom";

const Link = observer(
  class Link extends Component {
    static propTypes = {
      href: PropTypes.string,
      style: PropTypes.string,
      link: PropTypes.string
    };

    render() {
      const { link, style, href } = this.props;

      return !link || !href ? null : (
        <a href={href} style={style} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      );
    }
  }
);

export default Link;
