import styled from "styled-components";
import { white } from "../../styles/constants";

const NotFoundBackground = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: #7364db; /* Old browsers */
  color: ${white};
`;

export default NotFoundBackground;
