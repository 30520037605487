import React from "react";
import { Typography, Icon, withStyles } from "@material-ui/core";
import classNames from "classnames";

// Note: Don't know if theme is getting used by withStyles somehow
const styles = () => ({
  // eslint-disable-line no-unused-vars
  root: {
    marginLeft: 24
  },
  icon: {
    width: "auto",
    height: "auto",
    overflow: "visible",
    color: "#ff0500",
    verticalAlign: "middle",
    marginRight: 20
  }
});

const CanadaReportTitleComponent = ({ children, classes, ...props }) => (
  <div {...props}>
    <Typography variant="headline" classes={classes}>
      <Icon
        className={classNames(classes.icon, "fab fa-canadian-maple-leaf")}
      />
      {children}
    </Typography>
  </div>
);

export const CanadaReportTitle = withStyles(styles)(CanadaReportTitleComponent);
