import styled, { css } from "styled-components";

const isOpenStyles = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getIsOpenStyles = props => {
  if (props.isOpen) {
    return isOpenStyles;
  }
};

export const Modal = styled.div`
  display: none;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(45, 48, 71, 0.61);

  z-index: 100;

  ${getIsOpenStyles}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 500px;
  height: fit-content;
  padding: 30px;

  border-radius: 10px;

  background-color: #fff;
`;

export const ImageContainer = styled.div`
  width: 40%;
  maxheight: 140px;
`;

export const Title = styled.h3``;

export const Message = styled.p`
  text-align: center !important;
`;

export const Button = styled.div`
  border-radius: 10px;
  padding: 12px 50px;
  margin-top: 10px;

  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  background-color: #7364db;

  cursor: pointer;

  transition: all 300ms ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;
