import React, { Component } from "react";

import { FormWizard } from "./components/FormWizard";
import { FormWizardStep } from "./components/FormWizardStep";
import Img from "../Img";
import { PropTypes } from "prop-types";
import RaisedButton from "material-ui/RaisedButton";
import TabAuthorization from "./tab-authorization";
import TabConfirmation from "./tab-confirmation";
import TabDisclosure from "./tab-disclosure";
import TabInformation from "./tab-information";
import TabPAForm from "./tab-pa-form";
import TabRights from "./tab-rights";
import TabStateRights from "./tab-state-rights";
import TabCanadaPhotosFlow from "./tab-canada-photos-flow";
import messages from "./messages";
import Title from "../Title";
import { observer, Provider, inject } from "mobx-react";
import { LandingPageStyles as styles } from "../../styles";

import { materialThemeV1 } from "../../themes/turn-consent-flow";
import { MuiThemeProvider } from "@material-ui/core";
import TabConvictions from "./tab-convictions";
import _ from "lodash";
import TabPIIRetryConfirmation from "./tab-pii-retry-confirmation";

const EditCandidateDetails = observer(
  class EditCandidateDetails extends Component {
    static propTypes = {
      details: PropTypes.object,
      disclosure: PropTypes.string,
      handleRetrySubmit: PropTypes.func,
      handleSubmit: PropTypes.func,
      rights: PropTypes.string,
      authorization: PropTypes.string,
      validateForm: PropTypes.func,
      emailId: PropTypes.string,
      isCanadaCheck: PropTypes.bool,
      certnResponse: PropTypes.object,
      turn_id: PropTypes.string,
      store: PropTypes.object,
      checkId: PropTypes.string,
      recheck_id: PropTypes.string
    };

    constructor(props) {
      super(props);
      this.state = { convictionsContinue: false, informationContinue: false };
    }
    informationHasClickedContinue = () => {
      this.setState({ informationContinue: true });
    };
    onStepChange = ({ activeStep }) => {
      const modelValue = localStorage.getItem("modelState") || undefined;
      let model_state = modelValue ? JSON.parse(modelValue) : undefined;
      if (model_state) {
        model_state.step = activeStep;
        localStorage.setItem("modelState", JSON.stringify(model_state));
      }
    };
    renderInformation() {
      let disabled = false;
      if (this.props.isCanadaCheck) {
        disabled =
          this.props.details.error ||
          !this.props.details.informationReady ||
          !_.isEmpty(this.props.details.convictionErrors);
      }

      return (
        <FormWizardStep label={"Information"} disabled={disabled}>
          <TabInformation
            isCanadaCheck={this.props.isCanadaCheck}
            details={this.props.details}
            validateForm={this.props.validateForm}
            hasClickedContinue={this.informationHasClickedContinue}
            turn_id={this.props.turn_id}
            handleSubmit={this.props.handleSubmit}
            checkId={this.props.checkId}
          />
        </FormWizardStep>
      );
    }
    convictionsHasClickedContinue = () => {
      this.setState({ convictionsContinue: true });
    };
    renderCanadaConvictions() {
      if (
        !this.props.isCanadaCheck ||
        !_.includes(this.props.details.requiredFields, "convictions")
      ) {
        return null;
      }

      return (
        <FormWizardStep
          label={"Convictions"}
          disabled={
            this.props.details.error ||
            !this.props.details.informationReady ||
            !this.state.informationContinue
          }
        >
          <TabConvictions
            isCanadaCheck={this.props.isCanadaCheck}
            details={this.props.details}
            hasClickedContinue={this.convictionsHasClickedContinue}
          />
        </FormWizardStep>
      );
    }
    renderPAReleaseForm() {
      if (!this.props.details.isPAMVR) {
        return null;
      }

      return (
        <FormWizardStep
          label={"PA Release Form"}
          disabled={
            this.props.details.error ||
            !this.props.details.informationReady ||
            !this.props.details.agreeRights ||
            !this.props.details.agreeDisclosure
          }
        >
          <TabPAForm
            details={this.props.details}
            handleSubmit={this.props.handleSubmit}
            emailId={this.props.emailId}
          />
        </FormWizardStep>
      );
    }
    renderConfirmation() {
      if (!this.props.details.showConfirmationScreen) {
        return null;
      }

      return (
        <FormWizardStep
          label={"Confirmation"}
          disabled={
            this.props.details.error ||
            !this.props.details.informationReady ||
            !this.props.details.agreeRights ||
            !this.props.details.agreeDisclosure ||
            !(this.props.details.showConfirmationScreen
              ? this.props.details.agreeAuthorization
              : this.props.details.agreeSigning) ||
            (this.props.details.isPAMVR && !this.props.details.releaseCertify)
          }
        >
          <TabConfirmation
            isCanadaCheck={this.props.isCanadaCheck}
            details={this.props.details}
            handleSubmit={this.props.handleSubmit}
          />
        </FormWizardStep>
      );
    }
    renderRights() {
      if (this.props.isCanadaCheck) {
        return null;
      }

      return (
        <FormWizardStep
          label={"Rights"}
          disabled={
            this.props.details.error || !this.props.details.informationReady
          }
        >
          <TabRights
            isCanadaCheck={this.props.isCanadaCheck}
            details={this.props.details}
            rights={this.props.details.rights}
          />
        </FormWizardStep>
      );
    }
    renderDisclosure() {
      if (this.props.isCanadaCheck) {
        return null;
      }

      return (
        <FormWizardStep
          label={"Disclosure"}
          disabled={
            this.props.details.error ||
            !this.props.details.informationReady ||
            !this.props.details.agreeRights
          }
        >
          <TabDisclosure
            isCanadaCheck={this.props.isCanadaCheck}
            details={this.props.details}
            disclosure={this.props.details.disclosure}
          />
        </FormWizardStep>
      );
    }
    renderStateRights() {
      if (!this.props.details.stateRights) {
        return null;
      }

      return (
        <FormWizardStep
          label={"State Rights"}
          disabled={
            this.props.details.error ||
            !this.props.details.informationReady ||
            !this.props.details.agreeRights ||
            !this.props.details.agreeDisclosure
          }
        >
          <TabStateRights
            details={this.props.details}
            rights={this.props.details.stateRights}
          />
        </FormWizardStep>
      );
    }
    renderAuthorization() {
      let disabled = false;
      if (this.props.isCanadaCheck) {
        disabled =
          this.props.details.error ||
          !this.props.details.informationReady ||
          !_.isEmpty(this.props.details.convictionErrors) ||
          this.props.details.agreeAuthorization ||
          !this.state.convictionsContinue;
      } else {
        disabled =
          this.props.details.error ||
          !this.props.details.informationReady ||
          !this.props.details.agreeRights ||
          !this.props.details.agreeDisclosure;
      }

      return (
        <FormWizardStep label={"Authorization"} disabled={disabled}>
          <TabAuthorization
            isCanadaCheck={this.props.isCanadaCheck}
            details={this.props.details}
            authorization={this.props.details.authorization}
            handleSubmit={this.props.handleSubmit}
          />
        </FormWizardStep>
      );
    }
    renderCanadaResult() {
      if (!this.props.isCanadaCheck) {
        return null;
      }

      return (
        <FormWizardStep label={"Next Steps"} disabled={true}>
          {this.props.certnResponse && this.props.certnResponse.error ? (
            <TabCanadaPhotosFlow
              imageType={"canadaPhotosError"}
              title={messages.canadaPhotosError.title}
              paragraphText={messages.canadaPhotosError.paragraphText}
              isLastStep={true}
            />
          ) : (
            <TabCanadaPhotosFlow
              imageType={"canadaPhotosSuccess"}
              title={messages.canadaPhotosSuccess.title}
              paragraphText={messages.canadaPhotosSuccess.paragraphText}
              isLastStep={true}
            />
          )}
        </FormWizardStep>
      );
    }

    renderPIIRetryConfirmation() {
      if (!this.props.details.piiRetryPage) return null;

      return (
        <FormWizardStep
          label={"Upload Document"}
          stepName={"retryConfirmation"}
        >
          <TabPIIRetryConfirmation
            details={this.props.details}
            handleRetrySubmit={this.props.handleRetrySubmit}
          />
        </FormWizardStep>
      );
    }

    render() {
      if (!this.props.details) {
        return null;
      }

      if (this.props.details.finished) {
        return (
          <div>
            <Title align="center" weight="bold" huge>
              <Img style={styles.heart} type="heart" alt="Thank you" />
              Thank you
            </Title>
            <p
              style={{
                ...styles.text,
                ...{
                  fontSize: 28,
                  textAlign: "center",
                  lineHeight: "34px"
                }
              }}
            >
              For submiting your background check details. <br />
              The checks are now in progress.
            </p>
            <p style={styles.textNotification}>
              You will receive an email notification once all checks are
              complete so you may review and submit your background check
              results back to the requestor.
            </p>
            {false && (
              <RaisedButton
                buttonStyle={styles.button}
                labelStyle={styles.buttonLabel}
                overlayStyle={styles.buttonOverlay}
                label="Finish"
                primary
                onClick={window.close}
              />
            )}
          </div>
        );
      }

      return (
        <MuiThemeProvider theme={materialThemeV1}>
          <Provider store={this.props.store}>
            <FormWizard
              initialStep={this.props.store.step}
              onStepChange={this.onStepChange}
            >
              {this.renderInformation()}
              {this.renderCanadaConvictions()}
              {this.renderRights()}
              {this.renderDisclosure()}
              {this.renderStateRights()}
              {this.renderAuthorization()}
              {this.renderPAReleaseForm()}
              {this.renderConfirmation()}
              {this.renderCanadaResult()}
            </FormWizard>
          </Provider>
        </MuiThemeProvider>
      );
    }
  }
);

export default inject("store")(EditCandidateDetails);
