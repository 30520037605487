import { defineMessages } from "react-intl";

export default defineMessages({
  watchlists: {
    id: "app.components.candidate.report.watchlist.watchlists",
    defaultMessage: "Watchlists"
  },
  name: {
    id: "app.components.candidate.report.watchlist.name",
    defaultMessage: "Name"
  },
  alias: {
    id: "app.components.candidate.report.watchlist.alias",
    defaultMessage: "Alias"
  },
  citizenship: {
    id: "app.components.candidate.report.watchlist.citizenship",
    defaultMessage: "Citizenship"
  },
  country: {
    id: "app.component.candidate.report.watchlist.country",
    defaultMessage: "Country"
  },
  dob: {
    id: "app.component.candidate.report.watchlist.dob",
    defaultMessage: "Date of Birth"
  },
  eyes: {
    id: "app.component.candidate.report.watchlist.eyes",
    defaultMessage: "Eyes"
  },
  fullName: {
    id: "app.component.candidate.report.watchlist.fullName",
    defaultMessage: "Full Name"
  },
  hair: {
    id: "app.component.candidate.report.watchlist.hair",
    defaultMessage: "Hair"
  },
  height: {
    id: "app.component.candidate.report.watchlist.height",
    defaultMessage: "Height"
  },
  listingDate: {
    id: "app.component.candidate.report.watchlist.listing_date",
    defaultMessage: "Listing Date"
  },
  marks: {
    id: "app.component.candidate.report.watchlist.marks",
    defaultMessage: "Marks"
  },
  offense: {
    id: "app.component.candidate.report.watchlist.offense",
    defaultMessage: "offense"
  },
  placeBirth: {
    id: "app.component.candidate.report.watchlist.placeBirth",
    defaultMessage: "Place of Birth"
  },
  remarks: {
    id: "app.component.candidate.report.watchlist.remarks",
    defaultMessage: "Remarks"
  },
  score: {
    id: "app.component.candidate.report.watchlist.score",
    defaultMessage: "Score"
  },
  sex: {
    id: "app.component.candidate.report.watchlist.sex",
    defaultMessage: "Sex"
  },
  source: {
    id: "app.component.candidate.report.watchlist.source",
    defaultMessage: "Source"
  },
  address: {
    id: "app.component.candidate.report.watchlist.address",
    defaultMessage: "Address"
  },
  city: {
    id: "app.component.candidate.report.watchlist.city",
    defaultMessage: "City"
  },
  county: {
    id: "app.component.candidate.report.watchlist.county",
    defaultMessage: "County"
  },
  state: {
    id: "app.component.candidate.report.watchlist.state",
    defaultMessage: "State"
  },
  zip: {
    id: "app.component.candidate.report.watchlist.zip",
    defaultMessage: "Zip"
  },
  url: {
    id: "app.component.candidate.report.watchlist.url",
    defaultMessage: "URL"
  },
  wantedBy: {
    id: "app.component.candidate.report.watchlist.wantedBy",
    defaultMessage: "Wanted by"
  },
  weight: {
    id: "app.component.candidate.report.watchlist.weight",
    defaultMessage: "Weight"
  }
});
