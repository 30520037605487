/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import Highlighted from "../../../components/Highlighted";
import FooterContent from "./footer-content";
import styles from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const propTypes = {
  model: PropTypes.object
};

const ReadOnly = ({ model }) =>
  model ? (
    <FooterContent left>
      <div style={styles.footerContainer}>
        <div style={styles.textContainer}>
          <Highlighted black size="18px">
            <FormattedMessage {...messages.submitTo} />
            {model.partner}
            <FormattedMessage {...messages.review} />
          </Highlighted>
          <p>
            <span>
              <FormattedMessage {...messages.convenience} />
            </span>
            <br />
            <span>
              <FormattedMessage {...messages.mistake} />{" "}
              <a href="mailto:support@turn.ai">support@turn.ai</a>
              <FormattedMessage {...messages.inTouch} />
            </span>
          </p>
        </div>
      </div>
    </FooterContent>
  ) : null;

ReadOnly.propTypes = propTypes;

export default ReadOnly;
