/* eslint-disable no-fallthrough */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import RaisedButton from "material-ui/RaisedButton";
import { turnAPI, ga } from "../../services";
import history from "../../history";
import WorkerContainer from "../WorkerContainer";
import { WorkerStyles as styles } from "../../styles";
import member from "../../models/team-member";
import handleNotFound from "../../utils/handle-not-found";
import { CurrentWorker } from "../../models/current-worker";
import messages from "./messages";
import Modal from "../../components/Modal/modal";
import Page from "../../components/Page";
import { PageType, PageEvent } from "../../services/analytics/gtm";
import LogoLoader from "../../components/LogoLoader";
import ErrorModal from "../../components/ErrorModal";
import UnidentifiedUserIcon from "../../icons/svgr/UnidentifiedUserIcon";
import WarningConceptIcon from "../../icons/svgr/WarningConceptIcon";
import {
  ContactLink,
  Description,
  MessageContainer,
  TextContainer
} from "./styled";
import teamMember from "../../models/team-member";

const propTypes = {
  auth: PropTypes.object,
  params: PropTypes.object,
  router: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object
};

const WorkerDetailPage = observer(
  class WorkerDetailPage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        workerFound: true,
        errorOpen: false,
        errorMessage: undefined,
        workerStored: false,
        roleAccess: true
      };
      this.actions = [
        <RaisedButton
          primary
          label="OK"
          onClick={() => handleNotFound(history, "/")}
          key="1"
        />
      ];
    }
    openErrorDialog = errorMessage => {
      this.setState({ errorOpen: true, errorMessage });
    };
    closeErrorDialog = () => {
      this.setState({ errorOpen: false, errorMessage: undefined });
    };
    UNSAFE_componentWillMount() {
      this.currentWorker = new CurrentWorker();
      this.auth = this.props.auth;
      if (!member.id) member.retrieveMember();
      const id = this.props.match.params.id;
      this.getWorker(this.auth.getToken(), id);
    }

    getWorker = async (token, id) => {
      this.currentWorker.setLoading(true);
      if (!teamMember.showReport) this.setState({ roleAccess: false });
      const response = await turnAPI.setToken(token).getWorker(id);

      switch (response.status) {
        case 200:
          if (!response.data) this.setState({ workerFound: false });
          await this.currentWorker.set(response.data);
          await this.currentWorker.setGeocodeData();
          this.currentWorker.setId(response.data.uuid);
          this.currentWorker.showCounty = true;
          this.setState({ worker_consent: response.data.worker_consent });
          break;
        case 204:
          this.setState({ workerFound: true, workerStored: true });
          break;
        case 202:
          this.setState({
            workerFound: true,
            workerStored: true,
            worker_consent: response.data.consented
          });
          break;
        case 404:
          this.setState({ workerFound: false });
          break;
        case 307:
          this.auth.permission();
          break;
        case 401:
          this.auth.logout();
          break;
        default:
          this.setState({
            errorOpen: true
          });
          break;
      }
      this.currentWorker.setLoading(false);
    };
    renderWorkerContainer(loading) {
      // here's the loader
      if (loading || !this.state.workerFound) {
        return (
          <div style={styles.progress}>
            <LogoLoader />
          </div>
        );
      }
      if (this.state.roleAccess)
        return (
          <WorkerContainer
            worker={this.currentWorker}
            auth={this.auth}
            location={this.props.location}
            history={this.props.history}
            openErrorDialog={this.openErrorDialog}
            member={member}
          />
        );
    }
    handleInitiatedClose = () => {
      window.location.href = "/screen";
    };

    componentDidMount() {
      ga.send(PageType.WorkerPage, PageEvent.pageLoaded);
    }

    render() {
      return (
        <Page
          sideBarProps={{
            auth: this.auth,
            model: this.currentWorker,
            hideContent: this.currentWorker.loading
          }}
          appHeaderProps={{
            auth: this.auth,
            fullWidth: false,
            model: this.currentWorker,
            history: this.props.history
          }}
        >
          <ErrorModal
            buttonLabel={"Ok"}
            isOpen={this.state.errorOpen}
            message={messages.error.description.defaultMessage}
            title={messages.error.title.defaultMessage}
            onClick={this.closeErrorDialog}
          />

          <Modal
            img={
              this.state.worker_consent != undefined &&
              this.state.worker_consent == true ? (
                <WarningConceptIcon stroke="#7364DB" />
              ) : (
                <UnidentifiedUserIcon stroke="#7364DB" />
              )
            }
            centerButtons
            open={
              this.state.workerStored === true ||
              this.currentWorker.onBoardingStatus === "initiated"
            }
            title={
              this.state.worker_consent != undefined &&
              this.state.worker_consent == true ? (
                <FormattedMessage {...messages.workerInitated.title} />
              ) : (
                <FormattedMessage {...messages.workerUnidentified} />
              )
            }
            primaryButtonProps={{
              title:
                this.state.worker_consent != undefined &&
                this.state.worker_consent == true
                  ? "OK"
                  : "Close",
              onClick: this.handleInitiatedClose
            }}
          >
            {!this.state.worker_consent != undefined &&
            this.state.worker_consent == true ? (
              <MessageContainer>
                {messages.workerInitated.description}
              </MessageContainer>
            ) : (
              <MessageContainer style={{ width: "343px" }}>
                <p>{messages.workerNotFound.description.line1}</p>
                <p>
                  {messages.workerNotFound.description.line2}
                  <ContactLink href="mailto:support@turn.ai">
                    support@turn.ai
                  </ContactLink>
                </p>
              </MessageContainer>
            )}
          </Modal>
          <Modal
            img={<UnidentifiedUserIcon stroke="#7364DB" />}
            open={!this.state.workerFound}
            title={<FormattedMessage {...messages.workerUnidentified} />}
            actions={this.actions}
            primaryButtonProps={{
              title: "Close",
              onClick: this.handleInitiatedClose
            }}
            auth={this.auth}
            router={this.props.history}
          >
            <MessageContainer style={{ width: "343px" }}>
              <p>{messages.workerNotFound.description.line1}</p>
              <p>
                {messages.workerNotFound.description.line2}
                <ContactLink href="mailto:support@turn.ai">
                  support@turn.ai
                </ContactLink>
              </p>
            </MessageContainer>
          </Modal>

          <Modal
            Modal
            img={<UnidentifiedUserIcon stroke="#7364DB" />}
            open={!this.state.roleAccess}
            title={<FormattedMessage {...messages.roleAccessDeniedTitle} />}
          >
            <div style={styles.unauthorizedContainer}>
              <TextContainer>
                <Description>
                  Your current role does not have the necessary permissions to
                  complete this action. Please contact your administrator if you
                  require additional access.
                </Description>
              </TextContainer>
              <TextContainer style={{ width: "368px" }}>
                <p>
                  Please contact our Support team if you need assistance at{" "}
                  <ContactLink href="mailto:support@turn.ai">
                    support@turn.ai
                  </ContactLink>
                </p>
              </TextContainer>
            </div>
          </Modal>

          {this.renderWorkerContainer(this.currentWorker.loading)}
        </Page>
      );
    }
  }
);

WorkerDetailPage.propTypes = propTypes;

export default WorkerDetailPage;
