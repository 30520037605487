import PropTypes from "prop-types";
import styled from "styled-components";
import { aliceBlue } from "../../styles/constants";

const Divider = styled.div`
  height: ${({ height }) => height || "2px"};
  width: 100%;
  background-color: ${aliceBlue};
`;

Divider.propTypes = {
  style: PropTypes.object
};

export default Divider;
