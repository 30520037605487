export const days = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31
];

export const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
};

export const abbreviatedMonths = {
  1: "jan",
  2: "feb",
  3: "mar",
  4: "apr",
  5: "may",
  6: "jun",
  7: "jul",
  8: "aug",
  9: "sept",
  10: "oct",
  11: "nov",
  12: "dec"
};

const currentYear = new Date().getFullYear();
const lastYearInArray = currentYear - 10;
const years = [];

for (let i = lastYearInArray; i >= 1900; i--) {
  // eslint-disable-line no-plusplus
  years.push(i);
}

export { years };
