import { defineMessages } from "react-intl";

export default defineMessages({
  buttonNext: {
    id: "app.containers.WorkerCheckModal.buttonNext",
    defaultMessage: "Next"
  },
  buttonUpload: {
    id: "app.containers.WorkerCheckModal.buttonUpload",
    defaultMessage: "Upload"
  },
  buttonBack: {
    id: "app.containers.WorkerCheckModal.buttonBack",
    defaultMessage: "Back"
  },
  buttonEdit: {
    id: "app.containers.WorkerCheckModal.buttonEdit",
    defaultMessage: "Edit"
  },
  buttonAgain: {
    id: "app.containers.WorkerCheckModal.buttonAgain",
    defaultMessage: "Check Another Worker"
  },
  buttonAgainAddCandidate: {
    id: "app.containers.WorkerCheckModal.buttonAgainAddCandidate",
    defaultMessage: "Add more candidates"
  },
  buttonCancel: {
    id: "app.containers.WorkerCheckModal.buttonCancel",
    defaultMessage: "Cancel"
  },
  buttonCloseThisWindow: {
    id: "app.containers.WorkerCheckModal.buttonCloseThisWindow",
    defaultMessage: "Close this window"
  },
  buttonClose: {
    id: "app.containers.WorkerCheckModal.buttonClose",
    defaultMessage: "Close"
  },
  buttonSubmit: {
    id: "app.containers.WorkerCheckModal.buttonSubmit",
    defaultMessage: "Submit"
  },
  error: {
    id: "app.containers.WorkerCheckModal.error",
    defaultMessage: "{error}"
  },
  labelName: {
    id: "app.containers.WorkerCheckModal.labelName",
    defaultMessage: "Full Legal Name"
  },
  firstName: {
    id: "app.containers.WorkerCheckModal.firstName",
    defaultMessage: "First Name*"
  },
  lastName: {
    id: "app.containers.WorkerCheckModal.lastName",
    defaultMessage: "Last Name*"
  },
  labelEmail: {
    id: "app.containers.WorkerCheckModal.labelEmail",
    defaultMessage: "Email*"
  },
  labelPhone: {
    id: "app.containers.WorkerCheckModal.labelPhone",
    defaultMessage: "Mobile Phone"
  },
  labelSSN: {
    id: "app.containers.WorkerCheckModal.labelSSN",
    defaultMessage: "SSN"
  },
  labelPackages: {
    id: "app.containers.WorkerCheckModal.labelPackages",
    defaultMessage: "Partner Package"
  },
  emailSent: {
    id: "app.containers.WorkerCheckModal.emailSent",
    defaultMessage:
      "We will perform the background checks as soon as they provide consent."
  }
});
