import { string } from "prop-types";
import React from "react";

const WarningConceptIcon = ({ height, stroke, width }) => {
  return (
    <svg
      width={width || "134"}
      height={height || "134"}
      viewBox="0 0 134 134"
      fill="none"
      stroke={stroke || "#000"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)">
        <path
          d="M116.789 104.697H17.211c-4.732 0-9.066-2.575-11.37-6.72a13.08 13.08 0 0 1 .315-13.207l44.827-71.667A18.87 18.87 0 0 1 67 4.186c6.533 0 12.584 3.37 16.017 8.917l44.827 71.688c2.492 4.02 2.639 9.084.335 13.208a13.059 13.059 0 0 1-11.39 6.698Z"
          fill="#E8E9F7"
        />
        <mask
          id="b"
          style={{
            maskType: "luminance"
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={134}
          height={134}
        >
          <path d="M134 .042H0V134h134V.042Z" fill="#fff" />
        </mask>
        <g mask="url(#b)">
          <path d="M77.469 104.697H56.53V134H77.47v-29.303Z" fill="#E8E9F7" />
          <path
            d="M131.383 82.573 86.576 10.884A23.01 23.01 0 0 0 67 0a23.057 23.057 0 0 0-19.577 10.884L2.617 82.573a17.215 17.215 0 0 0-.419 17.435 17.238 17.238 0 0 0 15.013 8.875h35.133v20.931A4.172 4.172 0 0 0 56.53 134a4.172 4.172 0 0 0 4.188-4.186v-20.931H73.28v20.931A4.172 4.172 0 0 0 77.47 134a4.172 4.172 0 0 0 4.187-4.186v-20.931h35.133a17.158 17.158 0 0 0 15.033-8.854 17.207 17.207 0 0 0-.439-17.456Zm-14.656 17.937H17.211a8.857 8.857 0 0 1-7.726-4.542 8.854 8.854 0 0 1 .23-8.958L54.542 15.32a14.663 14.663 0 0 1 12.5-7.032 14.65 14.65 0 0 1 12.52 7.032l44.723 71.689a8.853 8.853 0 0 1 .23 8.958 8.856 8.856 0 0 1-7.726 4.542h-.062Z"
            fill="#7364DB"
          />
        </g>
        <path
          d="M67 73.3a4.172 4.172 0 0 1-4.188-4.186v-33.49A4.172 4.172 0 0 1 67 31.438a4.172 4.172 0 0 1 4.188 4.186v33.49A4.172 4.172 0 0 1 67 73.3Zm0 16.745a4.172 4.172 0 0 1-4.188-4.186A4.172 4.172 0 0 1 67 81.673a4.172 4.172 0 0 1 4.188 4.186A4.172 4.172 0 0 1 67 90.045Z"
          fill="#7364DB"
        />
      </g>
    </svg>
  );
};

WarningConceptIcon.propTypes = {
  height: string,
  stroke: string,
  width: string
};

export default WarningConceptIcon;
