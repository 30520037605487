import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { saveAs } from "file-saver";
import { turnAPI } from "../../services";
import _ from "lodash";
import ErrorDialog from "../../components/ErrorDialog";
import ModalLoader from "../../components/ModalLoader";
import { DASHBOARD_REPORT, WORKER_SOURCING_REPORT } from "../../constants";

const DownloadReportUI = observer(
  class DownloadReportUI extends Component {
    static propTypes = {
      handleClose: PropTypes.func,
      reportType: PropTypes.string
    };
    constructor(props) {
      super(props);
      this.state = {
        errorOpen: false,
        loading: false,
        reportType: props.reportType
      };
    }

    componentDidMount = () => {
      this.getReport();
    };

    setLoading = loading => {
      if (typeof loading !== "boolean") return;
      this.setState({ loading });
    };

    getReport = async () => {
      this.setLoading(true);
      let response;

      if (this.state.reportType == WORKER_SOURCING_REPORT) {
        response = await turnAPI.getWorkerSourcingReport();
      } else if (this.state.reportType == DASHBOARD_REPORT) {
        response = await turnAPI.getReport();
      }

      switch (response.status) {
        case 200:
          if (_.isNil(response.data.report_hyperlink)) {
            this.setState({ errorOpen: true });
            break;
          } else {
            try {
              await fetch(response.data.report_hyperlink)
                .then(response => response.blob())
                .then(blob => {
                  saveAs(blob, `${response.data.title}.csv`);
                });
            } catch (e) {
              this.setState({ errorOpen: true });
            }
            this.setLoading(false);
          }
          break;
        case 500:
        case 520:
        default:
          this.setState({ errorOpen: true });
          break;
      }
      this.setLoading(false);
      if (_.isFunction(this.props.handleClose)) this.props.handleClose();
    };

    closeError = () => this.setState({ errorOpen: false });

    render() {
      const { loading, errorOpen } = this.state;
      return (
        <div>
          <ErrorDialog open={errorOpen} onPress={this.closeError} />
          {loading ? <ModalLoader /> : null}
        </div>
      );
    }
  }
);

export default DownloadReportUI;
