import * as colors from "../constants";
import opacityColor from "../../utils/opacity-color";

const contentPadding = "0px 15px";
const checkVerticalPadding = "10px 0 10px";
const checkHorizontalPadding = "0px 30px";

const reportDateTxt = {
  margin: 0,
  paddingTop: 6,
  overflowWrap: "break-word",
  wordWrap: "break-word",
  wordBreak: "break-word",
  hyphens: "auto",
  minHeight: "24px",
  fontSize: "14px",
  color: colors.primary,
  fontWeight: "normal"
};

const styles = {
  wrapper: {
    backgroundColor: "#FFF"
  },
  disclosureContainer: {
    paddingBottom: "25px"
  },
  print: {
    cursor: "pointer",
    verticalAlign: "middle",
    fontSize: "0.5em",
    display: "inline-block",
    marginLeft: 20,
    color: colors.primary
  },
  headerContainer: {
    display: "inline-flex",
    alignItems: "center",
    padding: contentPadding,
    background: opacityColor(colors.aliceBlue, 0.3)
  },
  headerText: {
    marginRight: "5px"
  },
  headerImage: {
    width: 80
  },
  reportContent: {
    padding: contentPadding
  },
  content: {},
  reportDateContainer: {
    padding: "40px 0"
  },
  reportDateContent: {
    marginBottom: 20
  },
  reportDateTitle: {
    margin: "0 0 20px"
  },
  reportDateLabel: {
    margin: 0,
    fontSize: "14px",
    color: colors.slateGray,
    display: "block",
    fontWeight: "normal"
  },
  reportDateTxt,
  inlineReportDateTxt: {
    display: "inline"
  },
  section: {
    marginBottom: 40
  },
  sectionTitleTxt: {
    margin: 0,
    padding: "10px 0",
    fontSize: "20px"
  },
  sectionTitleIcon: {
    marginRight: "10px"
  },
  sectionTitleSmallTxt: {
    fontSize: 10,
    color: "#DDD",
    margin: "2px 0 0",
    textAlign: "right"
  },
  sectionContent: {
    padding: checkVerticalPadding
  },
  reportData: {
    marginBottom: 25
  },
  ssnContent: {
    paddingBottom: "10px"
  },
  ssnStatus: {
    verticalAlign: "top"
  },
  tableStatusTxt: {
    paddingLeft: 10,
    color: "#39c8c9"
  },
  bold: {
    fontWeight: 700
  },
  divider: {
    borderTop: "1px solid #DDD",
    clear: "both"
  },
  dividerTxt: {
    fontSize: 10,
    color: "#DDD",
    margin: "2px 0 0",
    textAlign: "right"
  },
  checkedIcon: {
    fontSize: "28px",
    marginRight: "15px"
  },
  clockIcon: {
    marginTop: "-5px",
    marginLeft: "1em",
    color: colors.periwinkle
  },
  warningIcon: {
    marginTop: "-5px",
    marginLeft: "1em",
    color: colors.noticeWarning
  },
  checkIcon: {
    marginTop: "-5px",
    marginLeft: "1em",
    color: colors.primary
  },
  progress: {
    margin: "100px auto",
    width: "400px",
    textAlign: "center",
    fontSize: 18
  },
  progressDivider: {
    backgroundColor: colors.primary
  },
  progressContent: {
    marginTop: 5,
    textAlign: "center"
  },
  buttonContent: {
    color: colors.white,
    fontSize: 18
  },
  clear: {
    color: colors.primary,
    fontWeight: "bolder",
    padding: 0,
    margin: 0
  },
  pendingMVR: {
    size: "18px",
    color: "#FFCA00",
    align: "left",
    weight: "500",
    margin: "0"
  },
  flatButton: {
    fontSize: 21,
    position: "absolute",
    right: 0,
    bottom: 0,
    minWidth: "auto",
    height: 40
  },
  flatButtonContent: {
    fontSize: 18
  },
  checkbox: {
    fill: colors.primary
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  input: {
    textTransform: "none"
  },
  labelCheckIcon: {
    color: colors.white,
    fill: colors.primary
  },
  labelClearIcon: {
    height: "20px",
    width: "20px",
    color: colors.white,
    background: colors.noticeError,
    borderRadius: "50%"
  },
  title: {
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    margin: "0px"
  },
  action: {
    fontSize: "14px"
  },
  label: {
    fontSize: "14px",
    color: colors.primary,
    fontWeight: "bold",
    marginRight: "5px"
  },
  value: {
    fontSize: "14px",
    color: colors.primary,
    fontWeight: "normal"
  },
  showAll: {
    position: "absolute",
    top: 0,
    right: 0,
    color: colors.black,
    fontWeight: "bold",
    fontSize: "12px"
  },
  container: {
    marginBottom: "40px"
  },
  summary: {
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      padding: checkHorizontalPadding
    },
    check: {
      display: "inline-flex",
      flex: 5
    },
    subtitle: {
      marginRight: "20px",
      marginLeft: "40px"
    },
    icon: {
      marginRight: "10px"
    },
    // date: {
    //   display: "inline-flex",
    //   flex: 2
    // },
    status: {
      display: "inline-flex",
      flex: 1
    }
  },
  checks: {
    container: {
      padding: checkHorizontalPadding,
      marginBottom: "13rem"
    },
    titleContainer: {
      padding: 0
    },
    criminalContainer: {
      marginBottom: 20
    },
    hitInfoContainer: {
      marginBottom: 20,
      marginLeft: 80
    },
    infoContainer: {
      display: "flex",
      alignItems: "center"
    }
  }
};

export default styles;
