import styled from "styled-components";
import { pickColor } from "turn-shared";
import { altGreen } from "../../styles/constants";

const StatusLabelContainer = styled.p`
  color: ${props =>
    props.status === "clear" ? altGreen : pickColor(props.status)};
  font-size: ${props => props.size || "14px"};
  font-weight: ${props => props.weight || 700};
  display: ${props => props.display || "block"};
  text-align: ${props => props.textAlign || "left"};
  margin: ${({ margin }) => (margin ? margin : "5px 0px 0px")};
`;

export default StatusLabelContainer;
