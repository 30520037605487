import styled from "styled-components";
import { secondary } from "../../styles/constants";

const CardFillingContainer = styled.div`
  width: 400px;
  min-height: 88px;
  display: block;
  background: ${secondary};
  box-shadow: 0px 4px 4px rgba(0, 0, 89, 0.08);
  border-radius: 12px;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
`;

export default CardFillingContainer;
