import React, { useMemo, useState } from "react";
import { PropTypes } from "prop-types";
import PanelCollapsible from "../../../../components/PanelCollapsible";
import Title from "../../../../components/Title";
import { FormattedMessage } from "react-intl";
import { primary } from "../../../../styles/constants";
import messages from "../../messages";
import Column from "../../../../components/Column";
import { WorkerStyles as styles } from "../../../../styles";
import { SearchHighlighted } from "../../../../components/SearchHighlighted";
import EnrollmentDetails from "./Details";
import EnrollmentModal from "./Modal";
import { normalizeEnrollments } from "./adapters";

const EnrollmentInformation = ({ worker }) => {
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [isModalopen, setIsModalOpen] = useState(false);

  const normalizedEnrollments = useMemo(() => normalizeEnrollments(worker), [
    worker
  ]);

  return (
    <>
      <EnrollmentModal
        enrollment={selectedEnrollment}
        isOpen={isModalopen}
        onCloseModal={() => setIsModalOpen(false)}
        worker={worker}
      />
      <PanelCollapsible
        panelProps={{
          style: {
            filter:
              "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
          }
        }}
        padding="0"
        margin="0px 0px 25px 0px"
        collapsedInvert={true}
        headerDivider
        contentContentStyle={{ paddingTop: 0, paddingBottom: 0 }}
        titleComponent={
          <Title size="14px" align="left" margin="0" color={primary}>
            <FormattedMessage {...messages.enrollmentInformation} />
          </Title>
        }
      >
        <Column width="100%" style={styles.detailHeader}></Column>
        <Column width="100%">
          <Title
            size="16px"
            align="left"
            weight="600"
            margin="0px 0px 10px 0px"
            color={primary}
          >
            <SearchHighlighted textToHighlight="Continuous Monitoring" />
          </Title>

          <EnrollmentDetails
            details={normalizedEnrollments}
            onValueAction={enrollment => {
              setSelectedEnrollment(enrollment);
              setIsModalOpen(true);
            }}
          />
        </Column>
      </PanelCollapsible>
    </>
  );
};

EnrollmentInformation.propTypes = {
  worker: PropTypes.object
};

export default EnrollmentInformation;
