import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { faClipboardListCheck } from "@fortawesome/pro-solid-svg-icons";

import { CandidateReportStyles as styles } from "../../styles";
import SectionTitle from "./SectionTitle";
import Check from "./Check";
import messages from "./messages";

class ReportSummary extends Component {
  static propTypes = {
    model: PropTypes.object
  };

  static defaultProps = {
    model: {}
  };

  render() {
    const { checksSummaryData } = this.props.model;
    if (!Array.isArray(checksSummaryData)) return null;
    return (
      <div id="reportSummaryContainer" style={styles.container}>
        <SectionTitle
          title={<FormattedMessage {...messages.reportSummary} />}
          icon={faClipboardListCheck}
        />
        {checksSummaryData.map((check = {}, key) => (
          <Check
            nameId="summaryCheckName"
            statusId="summaryStatusName"
            {...check}
            key={key}
          />
        ))}
      </div>
    );
  }
}

export default observer(ReportSummary);
