import { ENROLLMENTS_DATA_MAPPING } from "./constants";
import { getEnrollmentStatus } from "./utils";

export const normalizeEnrollments = worker => {
  const { criminalMonitoring, drivingMonitoring } = worker || {};

  const mvrStatus = getEnrollmentStatus(
    drivingMonitoring.isEnabled,
    drivingMonitoring.status,
    drivingMonitoring.updatedAt
  );

  const crimStatus = getEnrollmentStatus(
    criminalMonitoring.isEnabled,
    criminalMonitoring.status,
    criminalMonitoring.updatedAt
  );

  return [
    {
      label: "driving",
      status: mvrStatus,
      type: "mvr",
      value: ENROLLMENTS_DATA_MAPPING[mvrStatus].value,
      valueStyle: ENROLLMENTS_DATA_MAPPING[mvrStatus].valueStyle
    },
    {
      label: "criminal",
      status: crimStatus,
      type: "crim",
      value: ENROLLMENTS_DATA_MAPPING[crimStatus].value,
      valueStyle: ENROLLMENTS_DATA_MAPPING[crimStatus].valueStyle
    }
  ];
};
