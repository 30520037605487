import { Grid, Paper, Icon } from "@material-ui/core";
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import Highlighted from "../../Highlighted";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import ReactSignature from "../../Signature";
import TextField from "@material-ui/core/TextField";
import messages from "../messages";
import { LandingPageStyles as styles } from "../../../styles";
import { Observer } from "mobx-react/custom";
import { slateGray } from "../../../styles/constants";

/**
 * Render a Signature input, requiring the full-name and drawn signature. Props are passed
 * down to wrapping `div`.
 *
 * @param {object} details The `LandingPageModel` that's controlling the current form
 */
export const FormSignature = ({ details, ...props }) => {
  const signaturePadRef = useRef(null);

  const onNameChanged = ({ target }) => {
    details.setName(target.value);
  };

  const onEnd = () => {
    const dataURL = signaturePadRef.current.toDataURL();
    details.storeImage(dataURL);
    details.setSigned(true);
  };

  const onReset = () => {
    details.setSigned(false);
    signaturePadRef.current.clear();
  };

  return (
    <Observer>
      {() => (
        <div {...props}>
          <Grid container spacing={24} style={{ marginBottom: 20 }}>
            <Grid item xs={12} md={6}>
              <label htmlFor="name">
                <Highlighted color={slateGray}>
                  <FormattedMessage {...messages.name} />
                </Highlighted>
              </label>
              <TextField
                id="name"
                fullWidth
                autoComplete="nope"
                style={styles.input}
                value={details.name}
                onChange={onNameChanged}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label htmlFor="signature-pad">
                <Highlighted color={slateGray}>
                  <FormattedMessage {...messages.draw} />
                </Highlighted>
              </label>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Paper
                  style={{
                    borderRadius: 10,
                    padding: 20,
                    position: "relative",
                    flex: 1
                  }}
                >
                  <ReactSignature
                    setRef={signaturePadRef}
                    onEnd={onEnd}
                    clearOnResize={false}
                  />
                </Paper>
                <IconButton
                  color="secondary"
                  size="small"
                  style={{ width: 32, height: 32 }}
                  onClick={onReset}
                >
                  <Icon className="fas fa-redo" style={{ fontSize: 16 }} />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Observer>
  );
};
FormSignature.propTypes = {
  details: PropTypes.object.isRequired
};
