import { isArray, isString, isEmpty, isPlainObject, flatten } from "lodash";
import { toSentenceCase, toTitleCase } from "turn-shared";

export class Builder {
  constructor(data, options = {}) {
    this.data = data;
    this.titles = options.titles || [];
    this.sentences = options.sentences || [];
    this.output = {};
  }

  isTitle = (key, titles = this.titles) => titles.includes(key);
  toTitle = value => toTitleCase(value);

  isSentence = (key, sentences = this.sentences) => sentences.includes(key);
  toSentence = value => toSentenceCase(value);

  getValue = (
    data,
    key,
    config = { titles: this.titles, sentences: this.sentences }
  ) => {
    const { isTitle, toTitle, isSentence, toSentence } = this;
    const { titles, sentences } = config;
    const value = data[key];

    if (key === "total_state_points") return `${value}`;
    if (isEmpty(value)) return null;
    if (isTitle(key, titles)) return toTitle(value);
    if (isSentence(key, sentences)) return toSentence(value);

    return value;
  };
  assign = (key, options = {}) => {
    const { getValue, output } = this;
    const { data = this.data, out = key, titles = this.titles } = options;
    if (!isPlainObject(data)) return;
    const value = getValue(data, key, { titles });
    if (!value) return;
    output[out] = value;
  };
  assignList = keys => {
    if (!isArray(keys)) return;
    keys.forEach(key => this.assign(key));
  };
  assignNested = (list, key, options = { out: key }) => {
    const { getNestedData, mapListData, output } = this;
    const listData = getNestedData(list);
    const mapped = mapListData(listData, key, options);
    if (isEmpty(mapped)) return;

    const [first, second] = list.split(".");
    if (!second) {
      output[first] = mapped;
    } else {
      if (isArray(output[first])) {
        const obj = {};
        obj[second] = mapped;
        output[first].push(obj);
      } else {
        if (!output[first]) output[first] = {};
        output[first][second] = mapped;
      }
    }
  };
  mapListData = (listData, key, options = {}) => {
    if (!isArray(listData)) return null;
    const { getValue } = this;
    const { titles, sentences, out = key } = options;

    return listData.map(data => {
      const output = {};
      if (isString(key)) {
        const value = getValue(data, key, { titles, sentences });
        if (value) output[out] = value;
      }
      if (isArray(key)) {
        key.forEach(([k, opt = { out: k }]) => {
          const value = getValue(data, k, { titles, sentences });
          if (value) output[opt.out] = value;
        });
      }
      return output;
    });
  };
  getNestedData = (list = "") => {
    if (list === "" || !isString(list)) return null;
    const { data } = this;
    const [first, second] = list.split(".");
    const nested = data[first];

    if (isEmpty(nested)) return null;
    if (!second) return nested;

    const secondNested = flatten(
      nested.map(n => n[second]).filter(v => !isEmpty(v))
    );

    if (isEmpty(secondNested)) return null;
    return secondNested;
  };
  dump = () => this.output;
}
