import React from "react";
import styled from "styled-components";
import Column from "../Column";
import Row from "../Row";
import messages from "../LandingPage/messages";
import styles from "./styles";
import PropTypes from "prop-types";

const CardManualContainer = styled.div`
  width: 400px;
  min-height: 88px;
  display: block;
  background: transparent;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  cursor: pointer;
`;

const CardManualFilling = ({ link }) => {
  return (
    <CardManualContainer
      id="cardManualContainer"
      onClick={link}
      style={{
        ...styles.containerCard.body
      }}
    >
      <Row>
        <Column width="100%">
          <a
            style={{
              ...styles.containerCard.title,
              color: "#7E7E8F"
            }}
          >
            {messages.cardManualFilling.title}
          </a>
        </Column>
      </Row>
      <Row>
        <Column
          style={{
            paddingTop: "5px"
          }}
          width="100%"
        >
          <p
            style={{
              ...styles.containerCard.paragraphText,
              color: "#7E7E8F"
            }}
          >
            {messages.cardManualFilling.paragraphText}{" "}
          </p>
        </Column>
      </Row>
    </CardManualContainer>
  );
};

CardManualFilling.propTypes = {
  link: PropTypes.func
};

export default CardManualFilling;
