import React from "react";
import PropTypes from "prop-types";
import {
  Switch,
  FormControlLabel,
  withStyles,
  Typography
} from "@material-ui/core";
import styled from "styled-components";
import _ from "lodash";

import Column from "../Column";
import Title from "../Title";

import { LandingPageStyles as styles } from "../../styles";
import LandingPageCanadaModel from "../../models/landing-page-canada";

import { FormWizardBackForward } from "./components/FormWizardBackNext";
import {
  ConvictionPaper,
  ConvictionsForm
} from "./components/ConvictionsInput";
import { Observer } from "mobx-react/custom";
import syslog from "../../services/syslog";

/**
 * @augments FormControlLabel
 */
const ConvictedSwitchFormControlLabel = withStyles({
  label: {
    fontFamily: "Inter",
    fontSize: "1rem",
    fontWeight: "bold"
  }
})(FormControlLabel);
ConvictedSwitchFormControlLabel.propTypes = FormControlLabel.propTypes;

/**
 * @param {boolean} show Show or hide the content with an opacity + height animation
 */
const ConvictionsWrapper = styled.div`
  margin: 20px 0;
  transition: max-height 0.3s, opacity 0.2s;
  max-height: ${props => (props.show ? "9999px" : "0")};
  opacity: ${props => (props.show ? 1 : 0)};
`;
ConvictionsWrapper.propTypes = {
  show: PropTypes.bool
};

/**
 * Render the Canada Convictions Disclosure step
 *
 * @param {LandingPageCanadaModel} model MobX Canada Landing Page Model
 * @param {func} previousStep
 * @param {func} nextStep
 */
export const TabConvictions = ({
  details: model,
  previousStep,
  nextStep,
  hasClickedContinue
}) => {
  const handleNext = () => {
    window.scrollTo(0, 0);
    hasClickedContinue();
    nextStep();
    const detailsData = { ...model.formData };
    detailsData["has_middle_name"] = !detailsData["has_middle_name"];
    detailsData["tab"] = "step_2_convictions";
    syslog([detailsData]);
  };

  const handleBack = () => {
    previousStep();
    model.setCaptchaReset("reset");
  };

  return (
    <Observer>
      {() => (
        <div style={styles.tabContent}>
          <Title align="left" big>
            Disclose your Convictions
          </Title>

          <ConvictionPaper elevation={1}>
            <div style={{ padding: "0 20px" }}>
              <ConvictedSwitchFormControlLabel
                value="other"
                control={
                  <Switch
                    onChange={({ target }) => {
                      model.setConvicted(target.checked);
                      model.validateConvictions();
                    }}
                    checked={model.convicted}
                  />
                }
                label="Have you been convicted of a crime?"
                labelPlacement="start"
              />
            </div>
            <div style={{ padding: "0 7px 10px 7px" }}>
              <Typography style={{ fontWeight: "bold" }}>
                You don&apos;t have to declare the following:
              </Typography>
              <ul>
                <li>
                  <Typography>
                    A conviction for which you have received a Record Suspension
                    (formerly pardon) in accordance with the Criminal Records
                    Act
                  </Typography>
                </li>
                <li>
                  <Typography>
                    A conviction where you were a &apos;young person&apos; under
                    the Youth Criminal Justice Act
                  </Typography>
                </li>
                <li>
                  <Typography>
                    An Absolute or Conditional Discharge, pursuant to section
                    730 of the Criminal Code
                  </Typography>
                </li>
                <li>
                  <Typography>
                    An offense for which you were not convicted
                  </Typography>
                </li>
                <li>
                  <Typography>Any provincial or municipal offense</Typography>
                </li>
                <li>
                  <Typography>
                    Any charges dealt with outside of Canada
                  </Typography>
                </li>
              </ul>
              <Typography>
                Note that a Certified Criminal Record can only be issued based
                on the submission of fingerprints to the RCMP National
                Repository of Criminal Records.
              </Typography>
            </div>
          </ConvictionPaper>

          <ConvictionsWrapper show={model.convicted}>
            <Typography variant="subheading" style={{ marginLeft: 20 }}>
              Please tell us what happened
            </Typography>
            <ConvictionsForm style={{ marginTop: 20 }} model={model} />
          </ConvictionsWrapper>

          <Column
            textAlign="right"
            textAlignSM="center"
            width="100%"
            margin="20px 0 0 0"
          >
            <FormWizardBackForward
              nextDisabled={!_.isEmpty(model.convictionErrors)}
              onBack={handleBack}
              onNext={() => model.validateConvictions() && handleNext()}
            />
          </Column>
        </div>
      )}
    </Observer>
  );
};
TabConvictions.propTypes = {
  details: PropTypes.instanceOf(LandingPageCanadaModel),
  previousStep: PropTypes.func,
  nextStep: PropTypes.func,
  hasClickedContinue: PropTypes.func
};

export default TabConvictions;
