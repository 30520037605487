import { string } from "prop-types";
import React from "react";

const SearchZoomInIcon = ({ height, stroke, width }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke || "#000"}
    >
      <path
        d="M8.43335 10.725H13.0167"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.725 13.0167V8.43333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5417 19.25C15.3512 19.25 19.25 15.3511 19.25 10.5417C19.25 5.73218 15.3512 1.83333 10.5417 1.83333C5.73223 1.83333 1.83337 5.73218 1.83337 10.5417C1.83337 15.3511 5.73223 19.25 10.5417 19.25Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1667 20.1667L18.3334 18.3333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

SearchZoomInIcon.propTypes = {
  height: string,
  stroke: string,
  width: string
};

export default SearchZoomInIcon;
