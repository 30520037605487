import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { autorun, reaction } from "mobx";
import PropTypes from "prop-types";
import { Observer } from "mobx-react/custom";
import withContext from "../../contextAPI/context-HOC";

import { materialThemeV1 } from "../../themes/turn-screening-dashboard";

import { turnAPI, ga } from "../../services";

import ErrorDialog from "../../components/ErrorDialog";
import Page from "../../components/Page";
import CandidateTiles from "../../components/CandidateTiles";
import { PartnerConsentModal } from "../../components/PartnerConsentModal";

import { ScreeningWidgetsHeader } from "./ScreeningWidgetsHeader";
import { ScreeningSearchBar } from "./ScreeningSearchBar";

import member from "../../models/team-member";
import workers from "../../models/workers";
import { PartnerMetricsModel } from "../../models/screening/PartnerMetricsModel";
import multiplyDemoWorkers from "../../data/demo/mulitplyDemoWorkers";

import { PageType, PageEvent } from "../../services/analytics/gtm";
import teamMember from "../../models/team-member";

/**
 * Screening Dashboard Page. Refactored from WorkersPage component.
 */
export const ScreeningDashboardPageComponent = ({ auth, history }) => {
  const [widgetsModel] = useState(new PartnerMetricsModel());
  const [errorOpen, setErrorOpen] = useState(false);

  const getWorkers = async isDemo => {
    // No need to call .setReady(true) because .setPayload() will do it for us.
    workers.setReady(false);
    await workers.retrieveParams();
    const response = await turnAPI.getWorkers(workers.params);
    const getData = response =>
      isDemo ? multiplyDemoWorkers(response.data) : response.data;
    switch (response.status) {
      case 200:
        // Since demo accounts only have a couple of real partnerWorkers. Here we multiply them so that it looks like there are more
        // If it's not a demo account, just set the response as normal
        workers.setPayload(getData(response));

        break;
      case 307:
        auth.permission();
        break;
      case 401:
        auth.logout();
        break;
      case null:
        if (response.problem === "CANCEL_ERROR") {
          break;
        } else {
          setErrorOpen(true);
          break;
        }
      default:
        setErrorOpen(true);
        break;
    }
  };

  useEffect(() => {
    // TODO: Let's refactor TeamMember, it should not be necessary to call this
    if (!member.id) {
      member.retrieveMember();
    }

    widgetsModel.checkIfDemoPartner(member);

    return autorun(() => {
      if (workers.params) {
        getWorkers(widgetsModel.isDemoPartner);
        widgetsModel.applyFilters(true);
      }
    });
  }, []);

  // Sync widgetsModel to workers
  useEffect(() => {
    return reaction(
      () => widgetsModel.filterDays,
      () => {
        if (widgetsModel.filterStartDate != workers.startDate) {
          workers.setStartDate(widgetsModel.filterStartDate);
        }
        if (widgetsModel.filterEndDate != workers.endDate) {
          workers.setEndDate(widgetsModel.filterEndDate);
        }
      }
    );
  }, []);
  // Sync workers to widgetsModel
  useEffect(() => {
    return reaction(
      () => [workers.startDate, workers.endDate],
      ([startDate, endDate]) => {
        if (startDate != widgetsModel.startDate) {
          widgetsModel.setStartDate(startDate);
        }
        if (endDate != widgetsModel.endDate) {
          widgetsModel.setEndDate(endDate);
        }
      }
    );
  }, []);
  // Initial sync
  useEffect(() => {
    workers.setStartDate(widgetsModel.filterStartDate);
    workers.setEndDate(widgetsModel.filterEndDate);
  }, []);

  useEffect(
    () => ga.send(PageType.ScreeningDashboard, PageEvent.pageLoaded),
    []
  );

  return (
    <Observer>
      {() => (
        <Page
          sideBarProps={{
            auth: auth,
            model: workers,
            member: member,
            fetch: getWorkers,
            history: history
          }}
          appHeaderProps={{
            auth: auth,
            model: workers,
            fetch: getWorkers,
            history: history
          }}
          modal={
            <ErrorDialog open={errorOpen} onPress={() => setErrorOpen(false)} />
          }
        >
          <MuiThemeProvider theme={materialThemeV1}>
            <ScreeningWidgetsHeader
              workersModel={workers}
              widgetsModel={widgetsModel}
            />
            {teamMember.showWorkerList && (
              <div>
                <ScreeningSearchBar model={workers} />
                <CandidateTiles
                  auth={auth}
                  workers={workers}
                  fetch={getWorkers}
                  model={workers}
                  history={history}
                  setErrorOpen={setErrorOpen}
                />
              </div>
            )}
          </MuiThemeProvider>
          <PartnerConsentModal />
        </Page>
      )}
    </Observer>
  );
};
ScreeningDashboardPageComponent.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object
};

export const ScreeningDashboardPage = withContext(
  ScreeningDashboardPageComponent
);
