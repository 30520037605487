import { defineMessages } from "react-intl";

export default defineMessages({
  line_1: {
    id: "app.components.AddressTable.line_1",
    defaultMessage: "Street"
  },
  address1: {
    id: "app.components.AddressTable.address1",
    defaultMessage: "Street"
  },
  city: {
    id: "app.components.AddressTable.city",
    defaultMessage: "City"
  },
  state: {
    id: "app.components.AddressTable.state",
    defaultMessage: "State"
  },
  zipcode: {
    id: "app.components.AddressTable.zipcode",
    defaultMessage: "Zip Code"
  },
  zip4: {
    id: "app.components.AddressTable.zip4",
    defaultMessage: "Zip4"
  },
  latitude_longitude: {
    id: "app.components.AddressTable.latitude_longitude",
    defaultMessage: "Latitude/Longitude"
  }
});
