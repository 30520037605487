import React from "react";
import { PropTypes } from "prop-types";

const CMIcon = ({
  height,
  width,
  id,
  isDefault = true,
  primaryColor = "#5C8FFF",
  secondaryColor = "#C14BFF"
}) => {
  const defaultHeight = isDefault ? "62" : "67";

  return (
    <svg
      width={width || "72"}
      height={height || defaultHeight}
      viewBox={`0 0 72 ${defaultHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
    >
      <g clipPath={`url(#a_${id})`}>
        <path
          fill={`url(#b_${id})`}
          d="M36.022 0h33.016c1.975 0 2.94.958 2.94 2.939 0 2.385.022 4.79 0 7.176-.02 3.599-1.566 6.43-4.55 8.496-.451.32-.602.64-.602 1.15.022 3.833.022 7.645 0 11.478-.107 14.373-11.162 27.086-25.438 29.535-15.52 2.64-30.247-6.516-34.82-20.911-.9-2.832-1.394-5.707-1.416-8.688-.021-3.79-.021-7.602 0-11.393 0-.51-.129-.83-.58-1.17-2.983-2.045-4.53-4.877-4.55-8.497C0 7.666 0 5.238 0 2.79 0 .98 1.009 0 2.855 0h33.167Zm-25.74 20.421c0 3.237-.042 6.41.022 9.562.043 1.746.172 3.513.45 5.217 2.405 14.203 17.024 23.636 31.214 20.187 11.656-2.833 19.642-12.905 19.75-24.872.02-3.152 0-6.325 0-9.476 0-.213-.044-.405-.044-.618H10.283Zm-5.13-15.29c0 1.385-.021 2.727 0 4.047.022.852.043 1.725.258 2.513.644 2.3 2.662 3.62 5.323 3.62H61.89c2.276-.128 4.38-1.619 4.723-3.748.322-2.087.236-4.259.343-6.41-20.694-.021-41.195-.021-61.803-.021Z"
        />
        <path
          fill={`url(#c_${id})`}
          d="M36.064 25.51c7.149.022 12.795 5.729 12.795 12.884C48.837 45.4 43.063 51.02 35.849 51c-7.019-.021-12.73-5.77-12.708-12.82.022-7.048 5.775-12.69 12.923-12.67Zm7.643 12.735c0-4.153-3.5-7.624-7.664-7.624-4.272 0-7.771 3.471-7.75 7.709.022 4.131 3.542 7.602 7.707 7.602 4.229-.043 7.707-3.492 7.707-7.687Z"
        />
        <path
          fill={`url(#d_${id})`}
          d="M54.032 25.51c1.417.022 2.533 1.13 2.533 2.556 0 1.448-1.137 2.555-2.597 2.555a2.55 2.55 0 0 1-2.533-2.619c.021-1.427 1.159-2.513 2.597-2.491Z"
        />
        <path
          fill={`url(#e_${id})`}
          d="M38.554 38.223c.022 1.448-1.116 2.577-2.576 2.577-1.416 0-2.554-1.107-2.554-2.513-.021-1.448 1.116-2.576 2.576-2.576 1.417 0 2.554 1.107 2.554 2.512Z"
        />
      </g>
      {!isDefault && (
        <>
          <circle cx={56.5} cy={55.5} r={11} fill="#5EEA8D" stroke="#fff" />
          <path fill="#fff" d="m60 55.5-5.25 3.897v-7.794L60 55.5Z" />
        </>
      )}
      <defs>
        <linearGradient
          id={`b_${id}`}
          x1={71.988}
          x2={28.687}
          y1={0}
          y2={60.325}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primaryColor} />
          <stop offset={1} stopColor={secondaryColor} />
        </linearGradient>
        <linearGradient
          id={`c_${id}`}
          x1={48.859}
          x2={30.131}
          y1={25.511}
          y2={47.899}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primaryColor} />
          <stop offset={1} stopColor={secondaryColor} />
        </linearGradient>
        <linearGradient
          id={`d_${id}`}
          x1={56.565}
          x2={52.807}
          y1={25.51}
          y2={29.981}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primaryColor} />
          <stop offset={1} stopColor={secondaryColor} />
        </linearGradient>
        <linearGradient
          id={`e_${id}`}
          x1={38.555}
          x2={34.815}
          y1={35.711}
          y2={40.178}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primaryColor} />
          <stop offset={1} stopColor={secondaryColor} />
        </linearGradient>
        <clipPath id={`a_${id}`}>
          <path fill="#fff" d="M0 0h72v61.2H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

CMIcon.propTypes = {
  height: PropTypes.string,
  id: PropTypes.string,
  isDefault: PropTypes.bool,
  width: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string
};

export default CMIcon;
