import styled from "styled-components";
import { pickColor } from "turn-shared";

const BadgeContainer = styled.div`
  color: ${props => {
    return pickColor(props.status);
  }};
  height: ${({ size, height }) =>
    height ? height : size === "large" ? "30px" : "21px"};
  text-transform: capitalize;
  background-color: transparent;
  display: ${props => (props.display ? props.display : "inline-block")};
  justify-content: ${props => (props.justify ? props.justify : "")};
  align-items: ${props => (props.alignItems ? props.alignItems : "")};
  margin: ${props => (props.margin ? props.margin : 0)};
  width: ${props => (props.fullWidth ? "100%" : "50%")};
`;

export default BadgeContainer;
