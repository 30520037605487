import styled from "styled-components";
import { BREAKPOINT_XL } from "../../constants";

const HideLarge = styled.div`
  @media (max-width: ${BREAKPOINT_XL}px) {
    display: ${({ show }) => (show ? "block" : "none")};
  }
`;

export default HideLarge;
