import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import Title from "../../../components/Title";
import Container from "../../../components/Container";
import Panel from "../../../components/Panel";
import ListDetailView from "../../../components/ListDetailView";
import messages from "../messages";

const Properties = ({ worker }) => {
  const { propertyData } = worker;
  return propertyData.length > 0 ? (
    <Container small no>
      <Panel padding="10px 5%">
        <Title align="left">
          <FormattedMessage {...messages.properties} />
        </Title>
      </Panel>
      <ListDetailView
        data={[...[{ address: "address" }]]}
        simple
        masterKey="address"
        exclude={["mortgage"]}
        secondary="mortgage"
      />
    </Container>
  ) : null;
};
Properties.propTypes = {
  worker: PropTypes.object
};

export default observer(Properties);
