const styles = {
  resumeWorkerModalContent: {
    container: {
      textAlign: "center"
    },
    workerName: {
      fontWeight: "bold"
    }
  }
};

export default styles;
