import { Icon, withStyles } from "@material-ui/core";

import { Button } from "../../Button";
import PropTypes from "prop-types";
import React from "react";

const styles = {
  sizeLarge: {
    minWidth: 160,

    "&:last-child": {
      marginLeft: 10
    },

    "& > span:first-child": {
      justifyContent: "center"
    }
  }
};

/**
 * Render an Icon properly styled for use inside `Button`.
 *
 * @param {className} string  The class name to assign the `Icon` component, (eg. `fas fa-check`)
 * @param {style} object (Optional) Custom styles
 */
const NavIcon = ({ className, style, ...props }) => (
  <Icon
    className={className}
    style={{
      fontSize: "initial",
      verticalAlign: "middle",
      ...(style || {}) // in case of need, inject style prop
    }}
    {...props}
  />
);
NavIcon.propTypes = {
  className: PropTypes.string.isRequired
};

/**
 * Renders **Back** and **Next** buttons for Wizard.
 *
 * @param {callback} onNext Called when **Next** button is clicked. If omitted, button will NOT be rendered.
 * @param {boolean} nextDisabled If true, **Next** button will be rendered disabled
 * @param {React.node} nextLabel Label to render in the Next button. Defaults to 'Next'.
 * @param {callback} onBack Called when **Back** button is clicked. If omitted, button will NOT be rendered.
 * @param {boolean} backDisabled If true, **Back** button will be rendered disabled
 * @param {React.node} backLabel Label to render in the Back button. Defaults to 'Back'.
 */
export const FormWizardBackForwardComponent = ({
  classes,
  onNext,
  nextDisabled,
  nextLabel,
  onBack,
  backDisabled,
  backLabel,
  ...props
}) => {
  backLabel = backLabel || (
    <>
      <NavIcon className={"fas fa-chevron-left"} />
      &nbsp;Back
    </>
  );
  nextLabel = nextLabel || (
    <>
      Next&nbsp;
      <NavIcon className={"fas fa-chevron-right"} />
    </>
  );

  return (
    <div {...props}>
      {onBack && (
        <Button
          id="backButton"
          classes={classes}
          color="primary"
          size="large"
          disabled={backDisabled}
          onClick={onBack}
        >
          {backLabel}
        </Button>
      )}
      {onNext && (
        <Button
          id="nextButton"
          classes={classes}
          variant="contained"
          color="secondary"
          size="large"
          disabled={nextDisabled}
          onClick={onNext}
        >
          {nextLabel}
        </Button>
      )}
    </div>
  );
};
FormWizardBackForwardComponent.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func
};

export const FormWizardBackForward = withStyles(styles)(
  FormWizardBackForwardComponent
);
FormWizardBackForward.propTypes = FormWizardBackForwardComponent.propTypes;
