import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import NavBar from "../../components/NavBar";
import AppHeaderContent from "./app-header-content";
import withContext from "../../contextAPI/context-HOC";
import messages from "./messages";
import { getPageFromRoute } from "../../utils/get-page-from-route";
import {
  WORKERS_PAGE,
  READY_OPENINGS,
  SETTINGS_PAGE,
  WORKER_PAGE,
  READY_APPLICANTS,
  READY_LOCATIONS,
  READY_LOCATION_NEW,
  READY_POSITIONS,
  READY_POSITION_NEW,
  READY_JOB_APPLICANTS,
  READY_OPENING_NEW,
  READY_PAGE
} from "../../constants";
import { getSubtabFromRoute } from "../../utils/get-subtab-from-route";
import { getSubtabVariantFromRoute } from "../../utils/get-subtab-variant-from-route";
import { Hidden } from "@material-ui/core";
import MenuUI from "../WorkersPage/MenuUI";
import NewFeaturesUI from "./NewFeaturesUI";
import NavigationUI, { getNavigationProps } from "./NavigationUI";
import DownloadReportUI from "./DownloadReportUI.js";
import Modal from "../WorkerCheckModal";
import Button from "@material-ui/core/Button";
import FeatureDrawer from "../../components/FeatureDrawer";
import newFeaturesModel from "../../models/new-features";
import { observer } from "mobx-react";
import { AppHeaderStyles } from "../../styles";
import * as colors from "../../../src/styles/constants";
import env from "@mars/heroku-js-runtime-env";
import "./index.css";
import ProductButtons from "./ProductButtons";

const styles = AppHeaderStyles;

export class AppHeader extends Component {
  static propTypes = {
    children: PropTypes.element,
    fullwidth: PropTypes.bool,
    cohort: PropTypes.bool,
    disableNavigation: PropTypes.bool,
    context: PropTypes.object,
    history: PropTypes.object,
    model: PropTypes.object,
    auth: PropTypes.object,
    fetch: PropTypes.func,
    title: PropTypes.node
  };
  static contextTypes = {
    router: PropTypes.object
  };
  static defaultProps = {
    history: {},
    context: {}
  };
  constructor(props) {
    super(props);
    this.route = props.history.location
      ? props.history.location.pathname || ""
      : window.location
      ? window.location.pathname || ""
      : "";
  }

  async componentDidMount() {
    await newFeaturesModel.getUserFeatures();
    newFeaturesModel.setFeaturesSelected(false);
  }

  get page() {
    return getPageFromRoute(this.route);
  }

  get subtab() {
    return getSubtabFromRoute(this.route);
  }

  get subtabVariant() {
    return getSubtabVariantFromRoute(this.route);
  }

  renderScreeningSubtab = () => {
    if (this.props.title) {
      return this.props.title;
    }
    if (this.page !== WORKERS_PAGE && this.page !== WORKER_PAGE) return null;
    return (
      <NavigationUI
        {...getNavigationProps({
          titleRoute: "/screen",
          title: <FormattedMessage {...messages.applicants} />,
          variants: {
            title: <FormattedMessage {...messages.worker} />,
            // TODO: this should be a subtabVariant
            inVariant: this.page === WORKER_PAGE
          }
        })}
      />
    );
  };

  renderSettingsSubTab = () => {
    if (this.page !== SETTINGS_PAGE) return null;
    return <NavigationUI title={<FormattedMessage {...messages.settings} />} />;
  };

  // TODO: add tests
  renderOpeningsNavigation = () => {
    if (this.subtab !== READY_OPENINGS) return null;
    if (this.subtabVariant === READY_OPENING_NEW) return null;
    if (!this.props.context.features.REACT_APP_COHORT) return null;
    return (
      <NavigationUI
        {...getNavigationProps({
          titleRoute: "/recruiter/openings",
          title: <FormattedMessage {...messages.openings} />,
          variants: {
            title: <FormattedMessage {...messages.openingsApplicants} />,
            inVariant: this.subtabVariant === READY_JOB_APPLICANTS
          }
        })}
      />
    );
  };

  // TODO: add tests
  renderLocationsNavigation = () => {
    if (this.subtab !== READY_LOCATIONS) return null;
    return (
      <NavigationUI
        {...getNavigationProps({
          titleRoute: "/ready/locations",
          title: <FormattedMessage {...messages.locations} />,
          variants: {
            title: <FormattedMessage {...messages.locationNew} />,
            inVariant: this.subtabVariant === READY_LOCATION_NEW
          }
        })}
      />
    );
  };

  // TODO: add tests
  renderPositionsNavigation = () => {
    if (this.subtab !== READY_POSITIONS) return null;
    return (
      <NavigationUI
        {...getNavigationProps({
          titleRoute: "/ready/positions",
          title: <FormattedMessage {...messages.positions} />,
          variants: {
            title: <FormattedMessage {...messages.positionNew} />,
            inVariant: this.subtabVariant === READY_POSITION_NEW
          }
        })}
      />
    );
  };

  // TODO: add tests
  renderReadyApplicantsNavigation = () => {
    if (this.subtab !== READY_APPLICANTS) return null;
    return (
      <NavigationUI
        title={<FormattedMessage {...messages.readyApplicants} />}
      />
    );
  };

  renderDownloadPartnerReportButton = () => {
    if (
      this.page === WORKERS_PAGE &&
      this.props.context.features
        .REACT_APP_ENABLE_DOWNLOAD_PARTNER_DASHBOARD_REPORT === true
    )
      return (
        <Hidden xsDown>
          <div style={styles.downloadReportBtnLocation}>
            <DownloadReportUI />
          </div>
        </Hidden>
      );
    return null;
  };

  renderAddCandidate() {
    if (this.page === WORKERS_PAGE) {
      return (
        <Hidden mdDown>
          <div style={{ display: "inline-flex" }}>
            <Modal
              auth={this.props.auth}
              workers={this.props.model}
              fetch={this.props.fetch}
            />
          </div>
        </Hidden>
      );
    }
  }

  renderEnvelopeUI = () => {
    if (
      (this.page === WORKERS_PAGE || this.page === READY_PAGE) &&
      env().REACT_APP_ENABLE_ENVELOPE === "true"
    )
      return (
        <Hidden smDown>
          <Button
            id="envelope_button"
            key="1"
            style={styles.envelopeButton}
            disableRipple={true}
            classes={{
              root: "envelope-button"
            }}
          >
            <i className="fas fa-envelope" />
          </Button>
        </Hidden>
      );
    return null;
  };

  renderNewFeaturesUI = () => {
    if (
      (this.page === WORKERS_PAGE || this.page === READY_PAGE) &&
      this.props.context.features.REACT_APP_ENABLE_WHATS_NEW === true
    ) {
      return (
        <Hidden smDown>
          <NewFeaturesUI model={newFeaturesModel} />
        </Hidden>
      );
    }
    return null;
  };

  render() {
    const { fullwidth } = this.props;
    return (
      <>
        <NavBar
          background={colors.white}
          disableNavigation={this.props.disableNavigation}
        >
          {this.renderOpeningsNavigation()}
          {this.renderLocationsNavigation()}
          {this.renderPositionsNavigation()}
          {this.renderReadyApplicantsNavigation()}
          {this.renderScreeningSubtab()}
          {this.renderSettingsSubTab()}
          <AppHeaderContent fullwidth={fullwidth}>
            <div
              style={{
                display: "flex",
                columnGap: "20px",
                alignItems: "center"
              }}
            >
              <ProductButtons asPath={this.route} />
              <MenuUI auth={this.props.auth} />
            </div>
          </AppHeaderContent>
        </NavBar>
        {newFeaturesModel.featuresSelectedComputed ? <FeatureDrawer /> : null}
      </>
    );
  }
}

export default withContext(observer(AppHeader));
