import styled from "styled-components";

const Column = styled.div`
  display: inline-block;
  width: ${props => props.width};
  padding: ${props => props.padding};
  vertical-align: ${props => props.verticalAlign};
  text-align: ${props => props.textAlign || "inherit"};
  background-color: ${props => props.background || "inherit"};
  border-bottom: ${({ borderBottom }) => borderBottom || ""};
  margin: ${({ margin }) => (margin ? margin : "")};
  position: relative;
  font-size: 1rem;
  @media (max-width: 1100px) {
    width: ${props => props.widthLM};
  }
  @media (max-width: 800px) {
    width: ${props => props.widthMD};
  }
  @media (max-width: 500px) {
    text-align: ${props => props.textAlignSM || "inherit"};
    display: ${props => (props.widthSM ? "inline-block" : "block")};
    width: ${props => props.widthSM || "100%"};
  }
  @media print {
    display: ${props => props.printDisplay || "inline-block"};
    width: ${props => props.printWidth};
  }
`;

export default Column;
