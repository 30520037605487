import { Builder } from "../record-helper";

export const parseDriver = data => {
  const builder = new Builder(data);

  builder.assignList(["age", "dob"]);
  builder.assign("total_state_points");
  builder.assignNested(
    "addresses",
    [
      ["street", { out: "address" }],
      "state",
      "city",
      "county",
      ["zip", { out: "zip_code" }]
    ],
    { titles: ["street", "state", "city", "county"] }
  );
  return builder.dump();
};
