import { primary, secondary } from "../constants";

const styles = {
  appHeaderTitle: {
    padding: "0px",
    textDecoration: "none",
    color: "inherit"
  },
  backButton: {
    color: secondary,
    width: "48px",
    height: "39px",
    fontSize: "32px",
    cursor: "pointer"
  },
  appHeaderTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    margin: "0px"
  },
  downloadReportBtnLocation: {
    marginLeft: "33px",
    display: "inline"
  },
  envelopeButton: {
    height: "24px",
    width: "26px",
    fontSize: "24px",
    color: primary
  }
};

export default styles;
