import { Select, withStyles } from "@material-ui/core";
import React from "react";
import DropDownIcon from "./Icon";
import { disabledText } from "../../styles/constants";

const styles = {
  root: {
    verticalAlign: "middle",
    borderRadius: 25,
    marginLeft: -16,
    marginRight: -16
  },
  select: {
    fontSize: "12px",
    fontWeight: "bold",
    paddingRight: "40px",
    textAlign: "center",
    color: "#5f7d95"
  },
  disabledSelect: {
    fontSize: "12px",
    paddingRight: "40px",
    textAlign: "center",
    color: disabledText
  }
};

const FormDropdownComponent = ({
  classes,
  children,
  looksDisabled,
  ...props
}) => {
  return (
    <Select
      disableUnderline
      IconComponent={DropDownIcon}
      classes={{
        root: classes.root,
        select: looksDisabled ? classes.disabledSelect : classes.select
      }}
      {...props}
    >
      {children}
    </Select>
  );
};

export const FormDropdown = withStyles(styles)(FormDropdownComponent);
