/* eslint-disable no-undef */
import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import FooterContainer from "./footer-container";
import Confirm from "./Confirm";
import Form from "./Form";
import ReadOnly from "./ReadOnly";
import { slateGray } from "../../../styles/constants";

const Footer = observer(
  class Footer extends Component {
    static propTypes = {
      model: PropTypes.object,
      onSubmit: PropTypes.func,
      isPDFReport: PropTypes.bool
    };
    constructor(props) {
      super(props);
      this.state = {
        open: false,
        checkCertify: false
      };
      this.handleFooter = this.handleFooter.bind(this);
    }
    componentDidMount() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      window.addEventListener("scroll", this.handleFooter);
      window.addEventListener("resize", this.handleFooter);
    }

    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleFooter);
      window.removeEventListener("resize", this.handleFooter);
    }

    canScroll = () => {
      const height =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      return height !== clientHeight;
    };

    handleFooter() {
      const rootHeight = document.getElementById("root").offsetHeight;
      const windowHeight = window.innerHeight;
      const height = rootHeight - windowHeight;
      const scroll = window.scrollY;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop === 0 && this.state.open) {
        this.setState({ open: false });
      } else if (height <= scroll + 100) {
        this.setState({ open: true });
      } else if (this.state.open) {
        this.setState({ open: false });
      }
    }

    confirm = () => {
      this.props.model.setConfirm(true);
    };

    handleCheckCertify = () => {
      this.setState(prevState => ({ checkCertify: !prevState.checkCertify }));
    };

    dispute = e => {
      e.stopPropagation();
    };
    renderContent = () => {
      if (!this.props.model) return null;
      if (this.props.model.read_only)
        return (
          <FooterContainer
            open={this.state.open}
            isPDFReport={this.props.isPDFReport}
          >
            <ReadOnly model={this.props.model} />
          </FooterContainer>
        );
      return this.props.model.confirm ? (
        <FooterContainer
          open={this.state.open}
          isPDFReport={this.props.isPDFReport}
        >
          <Form model={this.props.model} onSubmit={this.props.onSubmit} />
        </FooterContainer>
      ) : (
        <FooterContainer
          open={this.state.open}
          isPDFReport={this.props.isPDFReport}
          bgColor={slateGray}
          padding="0"
          paddingBottom="0"
        >
          {/**
           * THIS WORKS
           *
           * This is needed to be able to monitor the page scrolling. It stopped working suddenly.
           * We did this to avoid altering the global `body` style, which may or may not break stuff.
           */}
          <style>{"body {height: auto}"}</style>

          <Confirm
            handleOpen={this.confirm}
            handleDispute={this.dispute}
            partnerName={this.props.model.partner}
            handleCheckCertify={this.handleCheckCertify}
            checked={this.state.checkCertify}
          />
        </FooterContainer>
      );
    };
    render() {
      return (
        <>
          {/**
           * THIS WORKS
           *
           * This is needed to be able to monitor the page scrolling. It stopped working suddenly.
           * We did this to avoid altering the global `body` style, which may or may not break stuff.
           */}
          <style>{"body {height: auto}"}</style>

          {this.renderContent()}
        </>
      );
    }
  }
);

export default Footer;
