import React from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import CollapsibleListItem from "../../../components/ListDetailView/collapsible-list-item";
import DetailInline from "../../../components/ListDetailView/detail-inline";
import { Divider } from "material-ui";
import { isEmpty } from "@firebase/util";
import getCustomStyles from "./styles";

const RecordDetail = ({ expand, width, idSuffix, detail }) => {
  const customStyles = getCustomStyles(width === "small");

  return (
    <div
      style={{
        paddingLeft: "65px",
        position: "relative"
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "15px"
        }}
      >
        <FontAwesomeIcon icon={faCircle} style={{ fontSize: "10px" }} />
      </div>
      {idSuffix !== 0 && width === "small" ? <Divider /> : null}
      <div style={{ marginLeft: "25px" }}>
        <CollapsibleListItem
          light
          inline
          verbose
          divider
          expand={expand}
          responsive={width}
          customStyles={customStyles}
          statusID={"FederalRecords.RecordDetail.status." + idSuffix}
          titleID={"FederalRecords.RecordDetail.title" + idSuffix}
          title={detail.case_number + ": " + detail.offense_description1}
          readyStatus={
            isEmpty(detail.crime_type)
              ? "No Crime Type Provided"
              : detail.crime_type
          }
          isFelony={
            !isEmpty(detail.crime_type) &&
            detail.crime_type.toLowerCase().includes("felony") &&
            !detail.crime_type.toLowerCase().includes("misdemeanor")
          }
          key={idSuffix}
        >
          <DetailInline deep data={detail} />
        </CollapsibleListItem>
      </div>
    </div>
  );
};

RecordDetail.propTypes = {
  expand: PropTypes.bool,
  width: PropTypes.string,
  index: PropTypes.number,
  detail: PropTypes.object,
  idSuffix: PropTypes.string
};
export default RecordDetail;
