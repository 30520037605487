import { create } from "apisauce";
import { btoa } from "Base64";
import env from "@mars/heroku-js-runtime-env";

const FRESHDESK_DOMAIN = env().REACT_APP_FRESHDESK_DOMAIN;
const FRESHDESK_API_KEY = env().REACT_APP_FRESHDESK_API_KEY;

const FRESHDESK_AUTHORIZATION = btoa(`${FRESHDESK_API_KEY}:x`);

export class FreshdeskApi {
  constructor() {
    this.api = create({
      baseURL: `https://${FRESHDESK_DOMAIN}.freshdesk.com/api/v2/`,
      headers: {
        Authorization: `Basic ${FRESHDESK_AUTHORIZATION}`,
        Accept: "application/json"
      }
    });
  }

  /**
   * Create a new Ticket on Freshdesk.
   *
   * @example
   *   api = new FreshdeskApi();
   *   api.createTicket({
   *     email: 'partner@example.com',
   *     subject: 'Help!!!',
   *     description: 'Something is wrong, I see UFOs everywhere'
   *   });
   *
   * @param {object} ticketData Freshdesk Ticket data
   * @returns {Promise}
   *
   * @see https://developers.freshdesk.com/api/#create_ticket
   * @see https://github.com/infinitered/apisauce#responses
   */
  createTicket(ticketData) {
    return this.api.post("tickets", ticketData);
  }
}
