import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { toTitleCase } from "turn-shared";

import { CandidateReportStyles as styles } from "../../styles";
import Row from "../Row";
import Column from "../Column";
import Text from "../Text";
import messages from "./messages";

class AddressList extends PureComponent {
  componentDidMount() {}
  render() {
    if (!this.props.addresses || this.props.addresses.length === 0) return null;
    return (
      <Column width="75%" widthMD="100%" printDisplay="block" printWidth="100%">
        <div style={styles.ssnContent} className="heading">
          <Row>
            <Column width="35%" widthMD="17%">
              <Text weight="bold">
                {<FormattedMessage {...messages.subheaderAddressesStreet} />}
              </Text>
            </Column>
            <Column width="25%" widthMD="17%">
              <Text weight="bold">
                {<FormattedMessage {...messages.subheaderAddressesCity} />}
              </Text>
            </Column>
            <Column width="20%" widthMD="17%">
              <Text weight="bold">
                {<FormattedMessage {...messages.subheaderAddressesState} />}
              </Text>
            </Column>
            <Column width="20%" widthMD="17%">
              <Text weight="bold">
                {<FormattedMessage {...messages.subheaderAddressesZipcode} />}
              </Text>
            </Column>
          </Row>
        </div>
        {this.props.addresses.map((address, key) => (
          <div key={key} className="address">
            <Row>
              <Column width="35%" widthMD="100%">
                <Text>{toTitleCase(address.address1)}</Text>
              </Column>
              <Column width="25%" widthMD="100%">
                <Text>{toTitleCase(address.city)}</Text>
              </Column>
              <Column width="20%" widthMD="100%">
                <Text>{address.state}</Text>
              </Column>
              <Column width="20%" widthMD="100%">
                <Text>{address.zip}</Text>
              </Column>
            </Row>
          </div>
        ))}
      </Column>
    );
  }
}

AddressList.propTypes = {
  addresses: PropTypes.array.isRequired
};

export default AddressList;
