import React from "react";
import PropTypes from "prop-types";
import ActionInfoOutline from "material-ui/svg-icons/action/info-outline";
import { FormattedMessage } from "react-intl";

const Info = ({ message, color, styles }) =>
  !message ? null : (
    <div style={styles ? styles.container : {}}>
      <div style={styles ? styles.text : {}}>
        <ActionInfoOutline color={color} style={styles ? styles.icon : {}} />
        <FormattedMessage color={color} {...message} />
      </div>
    </div>
  );

Info.propTypes = {
  message: PropTypes.object.isRequired,
  color: PropTypes.string,
  styles: PropTypes.object
};

export default Info;
