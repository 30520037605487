const styles = {
  textField: {
    marginTop: "7px",
    fontWeight: "normal"
  },
  select: {
    width: "100%",
    marginTop: "7px"
  }
};

export default styles;
