import { observable, action, computed, decorate } from "mobx";
import env from "@mars/heroku-js-runtime-env";
import { maskName, translateStatus } from "turn-shared";
import getOrigin from "../utils/get-origin";
import {
  consent__email_id__report,
  singleParamRegex,
  CONSENT,
  PROCESSING
} from "../constants";

class Worker {
  id;
  uuid = "";
  firstName = "";
  lastName = "";
  displayName = "";
  email = "";
  city = "";
  signUpDate;
  consentDate = "";
  actionDate = "";
  actionBy = "";
  checkStatus = "";
  status = "";
  relativeSignUpDate = "";
  consentEmailId = "";
  package = "";
  turnId = "";
  numberOfTimesOpened = 0;
  numberOfTimesClicked = 0;
  phoneNumber = "";
  checks = [];
  worker_consent = "";
  adverse_action = "";

  get displayFirstName() {
    if (!this.displayName) return "";
    return this.displayName.split(" ")[0];
  }
  get displayLastName() {
    if (!this.displayName) return "";
    const nameArray = this.displayName.split(" ");
    return nameArray[nameArray.length - 1];
  }
  get fullName() {
    if (env().REACT_APP_ENVIRONMENT === "production")
      return this.firstName || this.lastName
        ? `${this.firstName} ${this.lastName}`
        : this.displayName;
    if (this.firstName || this.lastName)
      return `${this.firstName} ${maskName(this.lastName)}`;
    if (this.displayName)
      return `${this.displayFirstName} ${maskName(this.displayLastName)}`;
    return "";
  }

  constructor(worker, adverse_action) {
    this.setWorker(worker, adverse_action);
  }

  translateStatus_extended(status, adverse_action) {
    if (status == "review__so") {
      return "Compliance Review";
    }
    return translateStatus(status, adverse_action);
  }

  setWorker(worker, adverse_action) {
    if (!worker) return;
    this.uuid = worker.uuid || this.uuid;
    this.id = worker.id || this.id;
    this.firstName = worker.first_name || this.firstName;
    this.lastName = worker.last_name || this.lastName;
    this.city = worker.city || this.city;
    this.displayName = worker.display_name || this.displayName;
    this.email = worker.email || this.email;
    this.status = this.translateStatus_extended(
      worker.profile_status,
      adverse_action
    );
    this.adverse_action = adverse_action;
    this.signUpDate = worker.create_timestamp;
    this.consentDate = worker.consent_date || this.consentDate;
    this.actionDate = worker.action_date || this.actionDate;
    this.actionBy = worker.action_by || this.actionBy;
    this.relativeSignUpDate = worker.create_at || this.relativeSignUpDate;
    this.consentEmailId =
      worker.candidate_consent_email_id || this.consentEmailId;
    this.package = worker.package_name || this.package;
    this.turnId = worker.turn_id || this.turnId;
    this.checkStatus = worker.check_status || this.checkStatus;
    this.numberOfTimesOpened =
      worker.number_of_times_opened || this.numberOfTimesOpened;
    this.numberOfTimesClicked =
      worker.number_of_times_clicked || this.numberOfTimesClicked;
    this.phoneNumber = worker.phone_number || this.phoneNumber;
    this.checks = worker.checks || this.checks;
    this.worker_consent = worker.worker_consent || this.worker_consent;
  }

  get hasResultAwaitingConsent() {
    return this.status === CONSENT;
  }

  get hasResultProcessing() {
    return this.status === PROCESSING;
  }

  get candidateAssentURL() {
    const origin = getOrigin();
    const pathname = consent__email_id__report.replace(
      singleParamRegex,
      this.consentEmailId
    );
    const url = `${origin}${pathname}`;
    return url;
  }
}

decorate(Worker, {
  id: observable,
  uuid: observable,
  firstName: observable,
  lastName: observable,
  displayName: observable,
  email: observable,
  city: observable,
  signUpDate: observable,
  consentDate: observable,
  actionDate: observable,
  actionBy: observable,
  status: observable,
  checkStatus: observable,
  relativeSignUpDate: observable,
  consentEmailId: observable,
  turnId: observable,
  numberOfTimesOpened: observable,
  numberOfTimesClicked: observable,
  phoneNumber: observable,
  displayFirstName: computed,
  displayLastName: computed,
  fullName: computed,
  setWorker: action,
  hasResultAwaitingConsent: computed,
  hasResultProcessing: computed,
  candidateAssentURL: computed,
  checks: observable,
  worker_consent: observable,
  adverse_action: observable
});

export default Worker;
