import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "app.components.Dashboard.header",
    defaultMessage: "Turn Partners Dashboard"
  },
  workersLink: {
    id: "app.components.Dashboard.workersLink",
    defaultMessage: "Screening"
  },
  persicopeReportLink: {
    id: "app.components.Dashboard.persicopeReportLink",
    defaultMessage: "Reports"
  },
  settingsLink: {
    id: "app.components.Dashboard.settingsLink",
    defaultMessage: "Settings"
  },
  statusFilter: {
    id: "app.components.Dashboard.statusFilter",
    defaultMessage: "Status"
  },
  searchLabel: {
    id: "app.components.Dashboard.searchLabel",
    defaultMessage: "Search"
  },
  pendingWorkers: {
    id: "app.components.Dashboard.pendingWorkers",
    defaultMessage: "Sourcing"
  },
  whatsNew: {
    id: "app.components.Dashboard.whatsNew",
    defaultMessage: "What's New"
  },
  downloadPartnerDashboardReport: {
    id: "app.components.Dashboard.downloadPartnerDashboardReport",
    defaultMessage: "Download"
  }
});
