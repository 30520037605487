import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { autorun } from "mobx";
import styled from "styled-components";

import member from "../../models/team-member";
import { turnAPI } from "../../services";
import { ErrorDialogStyles as styles } from "../../styles";

import { Button } from "../Button";
import { TurnLoader } from "../Loader";

const LEGAL_TEXT_KEY = "partner_dashboard";

const DialogText = styled.div``;

const DialogActions = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 21px;
`;

/**
 * For the sake of time, this was repurposed to display a Welcome screen.
 * For this to work properly, the `legal_text` table record that matches
 * this Partner MUST have the `expiration_timedelta` column set to something
 * obnoxiously huge so it's only shown on first login.
 *
 * TODO: This is a bit of a hack. Fix this so it works better.
 */
export const PartnerConsentModal = ({ ...props }) => {
  const [memberId, setMemberId] = useState(undefined);
  const [showDialog, setShowDialog] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [legalText, setLegalText] = useState(undefined);

  // Bridge MobX into component state to wait for member to be ready
  useEffect(
    () =>
      autorun(() => {
        if (member && member.id !== memberId) {
          setMemberId(member.id);
        }
      }),
    []
  );

  useEffect(() => {
    if (!memberId) {
      // Non-logged in users shouldn't see any of this
      return;
    }

    // Make sure we're only requesting this once per login
    if (
      localStorage.getItem("id_token") ==
      localStorage.getItem("partner_consent_token")
    ) {
      setShowLoading(false);
      return;
    } else {
      setShowLoading(true);
    }

    // Request Legal Text and make sure we get it
    turnAPI
      .getTeamMemberLegalText(LEGAL_TEXT_KEY)
      .then(response => {
        if (response.status != 200 || !response.data || !response.data.html) {
          setShowLoading(false);
        } else {
          setLegalText(response.data);
          setShowDialog(true);
          setShowLoading(false);
        }
      })
      .catch(() => {
        setShowLoading(false);
      });
  }, [memberId]);

  const onContinue = () => {
    turnAPI
      .postTeamMemberLegalSignature(LEGAL_TEXT_KEY, {
        id: legalText.id,
        timestamps: {
          checkbox: new Date(),
          button: new Date()
        }
      })
      .then(() => {
        // Make sure we're only requesting this once per login
        localStorage.setItem(
          "partner_consent_token",
          localStorage.getItem("id_token")
        );
      })
      .catch(() => {
        // TODO: Log to Bugsnag (silently fail)
      });
    setShowDialog(false);
  };

  const onAddCandidate = () => {
    // FIXME: This is a fucking hack, already spent hours trying to figure it out.
    // I'll get to it after board meeting. Don't judge me.
    window.turn_add_candidate_dispatch &&
      window.turn_add_candidate_dispatch({
        type: "CREATING",
        payload: {
          creating: true
        }
      });
  };

  return (
    <>
      <Dialog
        open={showDialog}
        PaperProps={{ style: { borderRadius: 32 } }}
        {...props}
      >
        <DialogContent style={{ ...styles.dialogContent, paddingBottom: 36 }}>
          <DialogText>
            <h4 id="termsOfServiceTitle" style={{ marginBottom: 0 }}>
              Welcome to your new Screening Dashboard
            </h4>
            <div id="termsOfServiceContent">
              <p>
                Thank you for purchasing a candidate screening from Turn
                Technologies.
              </p>
              <p>This is your screening dashboard.</p>
              <p>
                Here, you:
                <ul style={{ listStyleType: "none", marginTop: 0 }}>
                  <li>✉️ invite candidates for screenings;</li>
                  <li>📈 review results; and</li>
                  <li>
                    🤔 decide whether to move forward with potential workers.
                  </li>
                </ul>
              </p>
              <p>
                As you run background checks, data will display at the top of
                the dashboard to show your screening program’s performance.
              </p>
              <p>
                <strong>Recommended:</strong> We encourage you to make use of
                bulk upload. You can import and invite multiple candidates to a
                background check all at once.
              </p>
            </div>
          </DialogText>
          <DialogActions style={{ justifyContent: "end" }}>
            <Button onClick={() => onContinue()}>Close</Button>
            <Button
              onClick={() => {
                onContinue();
                onAddCandidate();
              }}
              id="partner_consent_button"
              key="1"
              style={{
                ...styles.turquoiseButton,
                width: "auto",
                textTransform: "none"
              }}
            >
              Add Candidate&#40;s&#41;
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <TurnLoader hidden={!showLoading} />
    </>
  );
};
