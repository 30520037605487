/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import { List } from "material-ui/List";
import Done from "material-ui/svg-icons/action/done";
import Warning from "material-ui/svg-icons/alert/error-outline";
import {
  ListDetailStyles as styles,
  CandidateReportStyles as reportStyles
} from "../../../styles";
import {
  noticeSuccess,
  noticeWarning,
  orange,
  green,
  periwinkle,
  red
} from "../../../styles/constants";
import ListContainer from "../../../components/ListDetailView/list-container";
import Title from "../../../components/Title";
import messages from "../messages";
import { autoapproveStyles } from "./styles"; // clearStyles, errorStyles
import MVRDriver from "./driver";
import MVRLicenses from "./licenses";
import MVRAccidents from "./accidents";
import MVRActions from "./actions";
import MVRViolations from "./violations";
import MVRErrorMessage from "./error";
import PendingReview from "../../../components/PendingReview";
import PendingAgeReview from "../../../components/PendingAgeReview";
import {
  PROCESSING,
  REVIEW,
  HIT,
  ERROR,
  MVR as MVRCHECK
} from "../../../constants";
import ProcessingMVR from "../../../components/ProcessingMVR";
import PanelCollapsible from "../../../components/PanelCollapsible";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner,
  faPauseCircle
} from "@fortawesome/pro-solid-svg-icons";
import withContext from "../../../contextAPI/context-HOC";

export const Icon = ({ warn }) =>
  warn ? <Warning color={noticeWarning} /> : <Done color={noticeSuccess} />;
Icon.propTypes = { warn: PropTypes.bool };

export const MVR = observer(
  class MVR extends Component {
    static propTypes = {
      data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      worker: PropTypes.object,
      status: PropTypes.string
    };
    static defaultProps = {
      worker: {},
      data: {}
    };
    constructor(props) {
      super(props);
      this.state = { expand: false };
    }
    setExpand = expand => {
      this.setState({ expand });
    };

    toggleExpandAll = () => this.setState({ expand: !this.state.expand });
    get color() {
      if (this.props.status === PROCESSING || this.props.status === ERROR)
        return periwinkle;
      if (this.props.status === REVIEW) return orange;
      if (this.props.status === HIT) return red;
      return green;
    }
    get icon() {
      if (this.props.status === PROCESSING || this.props.status === ERROR)
        return faSpinner;
      if (this.props.status === HIT) return faExclamationTriangle;
      if (this.props.status === REVIEW) return faPauseCircle;
      return faCheckCircle;
    }
    renderTitle = () => {
      const { data } = this.props;

      return (
        <>
          <div>
            <Title align="left" margin="20px 5%" style={reportStyles.title}>
              <FormattedMessage {...messages.mvr} />
            </Title>
          </div>
          {data.matrix_autoapproved_result ? (
            <div style={autoapproveStyles}>
              <span>
                Autoapprove Matrix Result: {data.matrix_autoapproved_result}
              </span>
            </div>
          ) : null}
        </>
      );
    };
    renderContent = () => {
      const { data, worker } = this.props;
      const { expand } = this.state;
      const {
        driver,
        licenses,
        invalidLicenses,
        violations,
        accidents,
        actions
      } = data; // invalidLicenses, licenses
      if (worker && worker.isProcessingMVR)
        return <ProcessingMVR message={messages.mvr} />;
      if (worker && worker.isPendingReview && worker.shouldShowCheck(MVRCHECK))
        return <PendingReview message={messages.mvr} />;
      if (
        worker &&
        worker.isPendingAgeReview &&
        worker.shouldShowCheck(MVRCHECK)
      )
        return <PendingAgeReview message={messages.mvr} />;
      if (_.isString(data)) return <MVRErrorMessage data={data} icon={null} />;
      return (
        <div>
          <ListContainer simple>
            <List style={styles.list}>
              <MVRDriver data={driver} expand={expand} />
              <MVRLicenses
                data={licenses}
                invalidLicenses={invalidLicenses}
                expand={expand}
              />
              <MVRViolations data={violations} expand={expand} />
              <MVRAccidents data={accidents} expand={expand} />
              <MVRActions data={actions} expand={expand} />
            </List>
          </ListContainer>
        </div>
      );
    };
    render() {
      const { data, worker, context } = this.props;
      const { checks = {} } = context;
      const shouldExpand =
        !data.isProcessingMVR &&
        !data.isPendingReview &&
        !worker.shouldShowCheck(MVRCHECK) &&
        !_.isString(data);

      if (_.isEmpty(data)) return null;
      return (
        <PanelCollapsible
          titleComponent={this.renderTitle()}
          titleStyle={reportStyles.title}
          iconStyle={reportStyles.checkedIcon}
          color={this.color}
          icon={this.icon}
          expandAll={shouldExpand}
          expandAllProps={{
            onClick: this.toggleExpandAll,
            toggled: this.state.expand
          }}
          setExpand={this.setExpand}
          expandAllChecks={checks.expandAllChecks}
        >
          {this.renderContent()}
        </PanelCollapsible>
      );
    }
  }
);

export default withContext(MVR);
