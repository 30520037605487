import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner
} from "@fortawesome/pro-solid-svg-icons";
import { PROCESSING } from "../../../constants";
import {
  ListDetailStyles as styles,
  CandidateReportStyles as reportStyles
} from "../../../styles";
import PanelCollapsible from "../../../components/PanelCollapsible";
import { green, red, periwinkle } from "../../../styles/constants";
import { AppContext } from "../../../contextAPI/context-provider";

import ListContainer from "../../../components/ListDetailView/list-container";
import { List } from "material-ui/List";
import { CanadaCriminalRecordItem } from "../criminalRecords/CanadaCriminalRecordItem";

const parentCategory = new Map();
parentCategory.set("Fraud Watchlists", "FRAUD");
parentCategory.set("Known Affiliations", "AFFILIATIONS");
parentCategory.set("Global Sanctions and Enforcements", "OFAC");
parentCategory.set("Public Safety Check", "PUBLIC_SAFETY");
parentCategory.set("Sex Offender Registry Check", "SEX_OFFENDER");
parentCategory.set("Global Clearance Check", "GLOBAL_CLEARANCE");
parentCategory.set("Other Searches", "OTHER");

export const CanadianCheckListItem = ({ label, data, status }) => {
  const context = useContext(AppContext);
  const { checks = {} } = context;
  const [isProcessing] = useState(status === PROCESSING);

  let isEmpty;
  if (data.length == 0 || typeof data == "string") {
    isEmpty = true;
  } else if (isProcessing) {
    isEmpty = true;
  } else {
    isEmpty = false;
  }

  const icon = !isEmpty
    ? faExclamationTriangle
    : isProcessing
    ? faSpinner
    : faCheckCircle;
  const color = !isEmpty ? red : isProcessing ? periwinkle : green;
  const [itemData] = useState(typeof data == "object" ? data : []);

  return (
    <PanelCollapsible
      // FIXME: This is runtime-generated react-intl message
      title={{
        id: `WorkerContainer.checksList.CanadianCheckListItem.label${_.camelCase(
          label
        )}`,
        defaultMessage: label
      }}
      titleStyle={reportStyles.title}
      iconStyle={reportStyles.checkedIcon}
      color={color}
      icon={icon}
      collapsible={!isEmpty}
      expandAllChecks={checks.expandAllChecks}
      panelProps={{
        style: {
          filter:
            "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
        }
      }}
    >
      {!isEmpty && (
        <ListContainer simple>
          <List style={styles.list}>
            {itemData.map(item => {
              if (item.parent_category == parentCategory.get(label)) {
                return (
                  <CanadaCriminalRecordItem
                    expanded={checks.expandAllChecks}
                    title={item.category}
                    date={item.date}
                    description={item.description}
                    stage={item.stage}
                    type={item.type}
                    source={item.sources || ""}
                  />
                );
              }
            })}
          </List>
        </ListContainer>
      )}
    </PanelCollapsible>
  );
};

CanadianCheckListItem.defaultProps = {
  status: ""
};

CanadianCheckListItem.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
  status: PropTypes.string
};
