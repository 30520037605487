import CallbackStyles from "./components/callback-styles";
import DateStyles from "./components/date-styles";
import DateSelectStyles from "./components/date-select-styles";
import HeaderDropDownStyles from "./components/header-dropdown-styles";
import LandingPageStyles from "./components/landing-page-styles";
import ListDetailStyles from "./components/list-detail-styles";
import ModalStyles from "./components/modal-styles";
import ModalTWStyles from "./components/modalTW-styles";
import ConsentModalStyles from "./components/consent-modal-styles";
import ProcessingModalStyles from "./components/processing-modal-styles";
import ErrorDialogStyles from "./components/error-dialog-styles";
import NavStyles from "./components/nav-styles";
import AppHeaderStyles from "./components/appheader-styles";
import TableStyles from "./components/table-styles";
import WorkerStyles from "./components/worker-styles";
import PermissionStyles from "./components/permission-styles";
import CandidateReportStyles from "./components/candidate-report-styles";
import DatesSeenStyles from "./components/dates-seen-styles";
import CohortCreationStyles from "./components/cohort-creation-styles";
import DotsLoaderStyles from "./components/dots-loader-styles";
import ModalLoaderStyles from "./components/modal-loader-styles";
import CandidatePreviewModalStyles from "./components/candidate-preview-modal-styles";
import ReportAuthorizationStyles from "./components/report-authorization-styles";
import WorkerSettingsStyles from "./containers/worker-settings-styles";
import SliderWithMarksStyles from "./components/slider-with-marks-styles";
import MatchesStyles from "./containers/matches-styles";
import FeaturesUIStyles from "./components/features-ui-styles";
import DownloadFileUIStyles from "./components/download-file-ui-styles";
import ListItemConfirmStyles from "./components/list-item-confirm-styles";
import ActionsSelectStyles from "./components/actions-select-styles";

import { secondary } from "../../src/styles/constants";

const GeneralStyles = {
  inlineIcon: {
    height: 14,
    width: 14
  },
  button: {
    width: "210px",
    borderRadius: 20,
    backgroundColor: secondary,
    boxShadow: "none"
  },
  buttonOverlay: {
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 20
    // display: "inline-flex"
  }
};

export {
  GeneralStyles,
  CallbackStyles,
  DateStyles,
  DateSelectStyles,
  HeaderDropDownStyles,
  LandingPageStyles,
  ListDetailStyles,
  ModalStyles,
  ModalTWStyles,
  ConsentModalStyles,
  ProcessingModalStyles,
  ErrorDialogStyles,
  NavStyles,
  AppHeaderStyles,
  TableStyles,
  WorkerStyles,
  PermissionStyles,
  CandidateReportStyles,
  DatesSeenStyles,
  CohortCreationStyles,
  DotsLoaderStyles,
  ModalLoaderStyles,
  ReportAuthorizationStyles,
  WorkerSettingsStyles,
  SliderWithMarksStyles,
  MatchesStyles,
  FeaturesUIStyles,
  DownloadFileUIStyles,
  CandidatePreviewModalStyles,
  ListItemConfirmStyles,
  ActionsSelectStyles
};
