import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { ListItem } from "material-ui/List";
import Divider from "material-ui/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSpinner,
  faPlusCircle,
  faMinusCircle
} from "@fortawesome/pro-solid-svg-icons";
import { ListDetailStyles as styles } from "../../styles";
import {
  noticeSuccess,
  noticeWarning,
  periwinkle,
  green,
  black
} from "../../styles/constants";
import Highlighted from "../Highlighted";
import SubTitle from "../SubTitle";
import Drawer from "../Drawer";
import ListItemContainer from "./list-item-container";
import ListItemScore from "./list-item-score";
import ListSubtitle from "./list-subtitle";
import ToolTip from "../ToolTip";
import { isEmpty } from "@firebase/util";
import { SearchHighlighted } from "../SearchHighlighted";

class CollapsibleListItem extends PureComponent {
  static propTypes = {
    divider: PropTypes.bool,
    title: PropTypes.string,
    simple: PropTypes.bool,
    children: PropTypes.node,
    score: PropTypes.string,
    count: PropTypes.number,
    readyStatus: PropTypes.string,
    clear: PropTypes.bool,
    icon: PropTypes.node,
    light: PropTypes.bool,
    subtitle: PropTypes.string,
    inline: PropTypes.bool,
    statusID: PropTypes.string,
    isFelony: PropTypes.bool,
    customStyles: PropTypes.object,
    responsive: PropTypes.string,
    titleID: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (typeof nextProps.expand === "boolean")
      this.setState({ open: nextProps.expand });
  }
  getStatusColor = () => (this.props.count > 0 ? noticeWarning : noticeSuccess);
  toggleOpen = () => this.setState({ open: !this.state.open });
  isReady = () => !(this.props.readyStatus === "processing");
  renderCount = () =>
    this.props.count > 0 ? (
      <span style={styles.count}>{this.props.count}</span>
    ) : null;
  renderOpen = open => {
    if (!this.isReady())
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          color={periwinkle}
          style={{ fontSize: "20px" }}
        />
      );
    if (this.props.clear)
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={green}
          style={{ fontSize: "20px" }}
        />
      );
    if (this.props.simple)
      return open ? (
        <FontAwesomeIcon
          icon={faMinusCircle}
          color={black}
          style={{ fontSize: "15px" }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faPlusCircle}
          color={black}
          style={{ fontSize: "15px" }}
        />
      );
    if (this.props.responsive === "small")
      return open ? (
        <FontAwesomeIcon
          icon={faMinusCircle}
          color={black}
          style={{ fontSize: "15px" }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faPlusCircle}
          color={black}
          style={{ fontSize: "15px" }}
        />
      );
    return open ? (
      <FontAwesomeIcon
        icon={faMinusCircle}
        color={black}
        style={{ fontSize: "15px" }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faPlusCircle}
        color={black}
        style={{ fontSize: "15px" }}
      />
    );
  };
  renderIcon = () => {
    const { icon } = this.props;
    if (!icon) return null;
    const iconStyle = { display: "inline-block", margin: "0px 0px 0px 20px" };
    return <span style={iconStyle}>{icon}</span>;
  };
  renderTooltipContent = message => (
    <div style={{ maxWidth: "400px", whiteSpace: "normal" }}>{message}</div>
  );
  renderTitle = () => {
    const {
      title,
      titleID,
      customStyles,
      responsive,
      light,
      inline
    } = this.props;
    const style = {
      display: "inline-block",
      minWidth: "10rem",
      width: undefined,
      fontSize: "14px",
      fontWeight: "bold"
    };
    const highlightStyle = isEmpty(customStyles)
      ? style
      : { ...customStyles.title, ...style };
    const componentTitle = (
      <Highlighted
        light={light}
        style={highlightStyle}
        size="16px"
        inline={inline}
      >
        <SearchHighlighted textToHighlight={title} />
      </Highlighted>
    );

    return titleID && responsive !== "small" ? (
      <ToolTip
        type="description"
        tooltipDescription={componentTitle}
        tooltipID={titleID}
        tooltipPlacement="top"
        tooltipContent={this.renderTooltipContent(title)}
      />
    ) : (
      componentTitle
    );
  };
  renderDivider = () => (this.props.divider ? <Divider /> : null);
  render() {
    const { subtitle, simple, score, children, customStyles } = this.props;
    const { open } = this.state;
    const {
      renderDivider,
      toggleOpen,
      getStatusColor,
      renderCount,
      renderOpen,
      isReady
    } = this;

    return (
      <ListItemContainer
        style={{ whiteSpace: "nowrap" }}
        simple={simple}
        data-test="list-detail-item"
      >
        {renderDivider()}
        <ListItem onClick={toggleOpen} style={simple ? styles.paddedItem : {}}>
          <div style={styles.listItem}>
            {this.renderTitle()}
            {this.renderIcon()}
            <ListSubtitle subtitle={subtitle} />
            <ListItemScore score={score} />
            <SubTitle
              color={getStatusColor()}
              display="inline-block"
              margin="0 0 0 30px"
              style={
                !isEmpty(customStyles) && !isEmpty(customStyles.expand)
                  ? customStyles.expand
                  : {}
              }
            >
              {renderCount()}
              {renderOpen(open)}
            </SubTitle>
          </div>
        </ListItem>
        {isReady() ? <Drawer open={open}>{children}</Drawer> : null}
      </ListItemContainer>
    );
  }
}

export default CollapsibleListItem;
