import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { ReportAuthorizationStyles as styles } from "../../../styles";
import { observer } from "mobx-react";
import Text from "../../Text";
import * as colors from "../../../styles/constants";

export const Footer = observer(
  class Footer extends React.Component {
    static propTypes = {
      label: PropTypes.object,
      hint: PropTypes.object,
      onClick: PropTypes.func,
      handleSubmit: PropTypes.func,
      disabled: PropTypes.bool
    };

    render() {
      const { footer } = styles;
      return (
        <div style={footer.container}>
          <div style={footer.buttonContainer}>
            <Button
              variant="flat"
              color="secondary"
              style={{
                ...footer.button,
                backgroundColor: this.props.disabled
                  ? colors.periwinkle
                  : colors.secondary
              }}
              onClick={this.props.handleSubmit}
              disabled={this.props.disabled}
            >
              Confirm
            </Button>
          </div>
          <Text weight="bold">
            {this.props.label}
            <Text
              color={colors.secondary}
              decoration="underline"
              onClick={this.props.onClick}
              display="block"
              cursor="pointer"
            >
              {this.props.hint}
            </Text>
          </Text>
        </div>
      );
    }
  }
);
