import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import messages from "../SSNTrace/messages";

class Footer extends PureComponent {
  render() {
    if (!this.props.lastSeen && !this.props.firstSeen) return null;
    return (
      <div>
        {this.props.lastSeen ? (
          <FormattedMessage {...messages.SSNLastSeen} />
        ) : null}
        &nbsp;
        {this.props.lastSeen}
        {this.props.lastSeen && this.props.firstSeen ? " | " : null}
        {this.props.firstSeen ? (
          <FormattedMessage {...messages.SSNFirstSeen} />
        ) : null}
        &nbsp;
        {this.props.firstSeen}
      </div>
    );
  }
}

Footer.propTypes = {
  lastSeen: PropTypes.string,
  firstSeen: PropTypes.string
};

export default Footer;
