export default {
  containerCard: {
    body: {
      padding: "20px 16px"
    },
    title: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "27px",
      margin: "0",
      padding: "0"
    },
    paragraphText: {
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "18px",
      margin: "0",
      padding: "0"
    }
  },
  manualFilling: {
    body: {
      paddingLeft: "20px"
    },
    title: {
      textDecorationLine: "underline",
      color: "#2887FF",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "27px"
    },
    paragraphText: {
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "18px",
      margin: "0",
      padding: "0",
      color: "#707073",
      width: "160px"
    }
  }
};
