import {
  aliceBlue,
  primary,
  secondary,
  slateGray,
  white,
  smokeWhite,
  gradientLight
} from "../constants";
export default {
  container: {
    padding: "7.5% 17%",
    textAlign: "center",
    fontWeight: 300,
    fontSize: 18
  },
  logo: {
    height: 90,
    marginBottom: 70
  },
  buttonTab: {
    color: primary,
    fontSize: 22,
    fontWeight: 300,
    textTransform: "none"
  },
  buttonTabDisabled: {
    color: aliceBlue,
    fontSize: 22,
    fontWeight: 300,
    textTransform: "none"
  },
  lnkbar: {
    paddingHorizontal: 20,
    height: 4,
    backgroundColor: aliceBlue,
    maxWidth: "74%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 5
  },
  lnkbar5: {
    paddingHorizontal: 20,
    height: 4,
    backgroundColor: aliceBlue,
    maxWidth: "82%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 5
  },
  lnkbar6: {
    paddingHorizontal: 20,
    height: 4,
    backgroundColor: aliceBlue,
    maxWidth: "88%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 5
  },
  lnkbarPA: {
    paddingHorizontal: 20,
    height: 4,
    backgroundColor: aliceBlue,
    maxWidth: "88%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 5
  },
  lnkbarTab: {
    backgroundColor: primary,
    width: 14,
    height: 14,
    position: "absolute",
    top: -7,
    borderRadius: "50%",
    marginLeft: "calc(12.5% - 7px)"
  },
  lnkbarTab5: {
    backgroundColor: primary,
    width: 14,
    height: 14,
    position: "absolute",
    top: -7,
    borderRadius: "50%",
    marginLeft: "calc(9%)"
  },
  lnkbarTab6: {
    backgroundColor: primary,
    width: 14,
    height: 14,
    position: "absolute",
    top: -7,
    borderRadius: "50%",
    marginLeft: "calc(9%)"
  },
  tabContent: {
    paddingTop: 10
  },
  primaryText: {
    color: primary
  },
  reportsProcessingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    background: smokeWhite
  },
  reportProcessing: {
    width: "576px",
    height: "708px",
    borderRadius: "30px",
    backgroundColor: "#fff",
    margin: "20px 0"
  },
  reportProcessingSm: {
    width: "90%",
    margin: "5% 0"
  },
  text: {
    fontSize: 17,
    textAlign: "left",
    fontWeight: "normal",
    color: "#7E7E8F"
  },
  textBig: {
    fontSize: 18,
    textAlign: "left",
    fontWeight: 700
  },
  textNotification: {
    fontSize: 18,
    textAlign: "center",
    lineHeight: "25px",
    margin: "30px auto"
  },
  trackMessageContainer: {
    height: "89px",
    lineHeight: "89px"
  },
  trackMessageContainerSm: {
    height: "50px",
    lineHeight: "20px"
  },
  textTrack: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold"
  },
  badgesContainer: {
    width: "396px",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto"
  },
  badgesContainerSm: {
    width: "100%"
  },
  appBadgeContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "17px"
  },
  appBadgeContainerSm: {
    marginLeft: "0px"
  },
  playBadgeContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: "7px"
  },
  appBadge: {
    width: "126px",
    height: "auto"
  },
  playBadge: {
    width: "144px",
    height: "auto"
  },
  radioButton: {
    marginBottom: 16
  },
  verifyButton: {
    backgroundColor: gradientLight,
    borderRadius: "12px",
    padding: "10px",
    margin: "10px 10px 16px",
    width: "100%",
    color: "black",
    fontWeight: "bold",
    textDecoration: "none"
  },
  subtitleError: {
    fontWeight: "bold",
    textAlign: "justify",
    paddingLeft: "30px"
  },
  subtitleErrorStep: {
    padding: "10px"
  },
  label: {
    color: slateGray,
    cursor: "pointer"
  },
  select: {
    background: white
  },
  error: {
    display: "block",
    color: "red",
    fontWeight: "bolder",
    fontSize: "13px",
    lineHeight: "20px",
    paddingBottom: "20px",
    transition: "height 200ms"
  },
  warning: {
    display: "inline",
    color: "orange",
    fontWeight: "bolder",
    fontSize: "13px",
    marginTop: "-26px",
    lineHeight: "1"
  },
  checkbox: {
    width: 28,
    height: 28
  },
  checkboxP: {
    fontSize: 14,
    cursor: "pointer"
  },
  fontSize: {
    fontSize: 14,
    fontWeight: "normal"
  },
  headerImage: {
    width: 140
  },
  button: {
    minWidth: 200,
    padding: "0px 10px",
    height: 60,
    lineHeight: "34px"
  },
  buttonLabel: {
    fontSize: 28,
    fontWeight: 300,
    textTransform: "none"
  },
  buttonOverlay: { backgroundColor: "transparent" },
  input: {
    marginTop: 5
  },
  agreeContainer: {
    marginTop: "30px",
    marginBottom: "50px"
  },
  flatButton: {
    verticalAlign: "middle",
    lineHeight: "none",
    minWidth: 200,
    padding: "0px 10px",
    height: 60,
    borderRadius: 5,
    border: `2px solid ${primary}`,
    fontSize: 21
  },
  raisedButton: {
    borderRadius: 5,
    fontSize: 25,
    paddingVertical: 15,
    paddingHorizontal: 40,
    color: "red"
  },
  agreeRights: {
    marginTop: 30,
    marginBottom: 50
  },
  signatureForm: {
    maxWidth: 750,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 100
  },
  heart: {
    height: 34,
    verticalAlign: "baseline",
    marginRight: 15,
    marginBottom: 30
  },
  scrollContent: {
    maxHeight: 500,
    "overflow-y": "scroll",
    paddingRight: 10,
    "-webkit-overflow-scrolling": "touch"
  },
  confirm: {
    detailItem: {
      margin: 0,
      fontSize: 21
    },
    detailContainer: {
      padding: "2.5%"
    },
    detailList: {
      padding: "2%"
    },
    img: {
      maxWidth: "100%",
      height: "auto"
    },
    title: {
      fontWeight: 300,
      fontSize: 42
    },
    p: {
      fontSize: 28
    },
    editIconContainer: {
      position: "absolute",
      right: 0,
      top: "10px"
    },
    editIcon: {
      stroke: "#212121",
      color: "#ffffff"
    },
    appBar: {
      background: "#ffffff"
    },
    flex: {
      flex: 1
    }
  },
  hr: {
    border: `1px solid ${secondary}`,
    marginBottom: "50px"
  },
  floatRight: {
    float: "right"
  },
  closeModal: {
    position: "absolute",
    top: "5px",
    right: "10px"
  },
  modalTitle: {},
  modalContent: {
    maxWidth: "960px"
  },
  imgContainer: {
    width: "100%",
    height: "253px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  imgDimensionsContainer: {
    maxWidth: "200px",
    maxHeight: "190px",
    margin: "0 auto"
  },
  messages: {
    height: "454px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  gralMessageContainer: {
    width: "396px",
    fontSize: "24px",
    fontWeight: "bold",

    flex: 55,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    textAlign: "center",
    lineHeight: "32px"
  },
  gralMessageContainerSm: {
    width: "90%"
  },
  specificMessageContainer: {
    width: "396px",
    marginTop: "16px",
    fontSize: "16px",

    flex: 45,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    lineHeight: "23px",

    bottom: 0
  },
  specificMessageContainerSm: {
    width: "90%",
    marginBottom: "16px"
  },
  processingImage: {
    width: "100%",
    height: "auto"
  },
  addressMessage: {
    marginTop: "1em",
    display: "block"
  }
};
