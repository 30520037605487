import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { TableRowColumn, TableRow, TableHeaderColumn } from "material-ui/Table";
import Highlighted from "../Highlighted";
import DataTable from "../DataTable";
import { TableStyles as styles } from "../../styles";
import FormattedHeader from "../FormattedHeader";
import { SearchHighlighted } from "../SearchHighlighted";

class SimpleTable extends PureComponent {
  shouldRender = () =>
    !!(
      this.props.data &&
      Array.isArray(this.props.data) &&
      this.props.data.length > 0
    );
  renderHeader = keys =>
    keys.map((key, index) => (
      <TableHeaderColumn key={index} style={styles.headerColumn}>
        <Highlighted>
          <FormattedHeader message={key} />
        </Highlighted>
      </TableHeaderColumn>
    ));
  renderBody = keys =>
    this.props.data.map((item, index) => (
      <TableRow key={index} style={styles.row}>
        {keys.map((key, i) => (
          <TableRowColumn key={i}>
            <SearchHighlighted textToHighlight={item[key]} />
          </TableRowColumn>
        ))}
      </TableRow>
    ));
  render() {
    if (!this.shouldRender()) return null;
    const keys = Object.keys(this.props.data[0]);
    return (
      <DataTable
        header={this.renderHeader(keys)}
        body={this.renderBody(keys)}
      />
    );
  }
}

SimpleTable.propTypes = {
  data: PropTypes.object
};

export default SimpleTable;
