import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { toTitleCase } from "turn-shared";

import { CandidateReportStyles as styles } from "../../styles";
import { HIT, CLEAR } from "../../constants";
import Row from "../Row";
import Divider from "../Divider";
import Text from "../Text";
import Title from "../Title";

import DataItem from "./DataItem";
import messages from "./messages";
import Check from "./Check";
import {
  faCheckCircle,
  faExclamationTriangle,
  faInboxOut,
  faSpinner
} from "@fortawesome/pro-solid-svg-icons";
import { PROCESSING, WITHDRAWN, READY } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { green, periwinkle, red } from "../../styles/constants";
import _ from "lodash";

class FederalRecord extends PureComponent {
  static propTypes = {
    federal: PropTypes.array.isRequired,
    reportDate: PropTypes.string,
    pendingMVRReview: PropTypes.bool.isRequired,
    pendingAgeReview: PropTypes.bool.isRequired
  };
  constructor(props) {
    super(props);
    this.federal = props.federal ? props.federal : false;
    this.reportDate = props.reportDate;
    this.renderFederalRecord = props.federal;
  }
  UNSAFE_componentWillMount() {}
  getState = record => {
    if (record.state) return record.state;
    return record.district;
  };
  getIcon = (status, values) => {
    if (status === PROCESSING) {
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          color={periwinkle}
          style={styles.summary.icon}
        />
      );
    }
    if (status === WITHDRAWN) {
      return (
        <FontAwesomeIcon
          icon={faInboxOut}
          color={red}
          style={styles.summary.icon}
        />
      );
    }
    if (_.isEmpty(values) && status === READY) {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={green}
          style={styles.summary.icon}
        />
      );
    }
    if (_.size(values) > 0 && status === READY) {
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          color={red}
          style={styles.summary.icon}
        />
      );
    }
  };

  render() {
    const {
      pendingMVRReview,
      pendingAgeReview,
      isPDFReport,
      ...rest
    } = this.props;

    const defaultCheckNameId = "detailCheckName";
    const defaultCheckStatusId = "detailCheckStatus";

    if (!this.renderFederalRecord) return null;
    if (pendingMVRReview && this.renderFederalRecord) {
      return (
        <div style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.federalRecords} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <div style={styles.sectionContent}>
            <p style={styles.pendingMVR}>
              <FormattedMessage {...messages.pendingMVR} />
            </p>
          </div>
          <Divider height="1px" />
        </div>
      );
    }
    if (pendingAgeReview && this.renderFederalRecord) {
      return (
        <div style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.federalRecords} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <div style={styles.sectionContent}>
            <p id="pendingAgeMessage" style={styles.pendingMVR}>
              <FormattedMessage {...messages.pendingAge} />
            </p>
          </div>
          <Divider height="1px" />
        </div>
      );
    }
    if (!this.federal) {
      return (
        <div style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.federalRecords} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <div style={styles.sectionContent}>
            <Divider height="1px" />
          </div>
        </div>
      );
    }
    return (
      <div style={styles.section}>
        <Check
          check={<FormattedMessage {...messages.federalRecords} />}
          noDivider
          containerStyle={styles.checks.titleContainer}
          nameId={defaultCheckNameId}
          statusId={defaultCheckStatusId}
          {...rest}
        />
        <div style={styles.sectionContent}>
          {this.federal.map((report, key) => (
            <Row key={key} style={styles.checks.federalContainer}>
              <div
                style={{
                  ...styles.summary.container,
                  ...styles.checks.titleContainer
                }}
              >
                <Title
                  color={"black"}
                  style={styles.summary.subtitle}
                  size="14px"
                >
                  {this.getIcon(report.status, report.values)}
                  {report.district}
                </Title>
                <Text
                  size="14px"
                  weight="bold"
                  style={styles.summary.status}
                  color={
                    _.size(report.values) > 0 || report.status === WITHDRAWN
                      ? red
                      : green
                  }
                >
                  {_.size(report.values) > 0
                    ? _.upperFirst(HIT)
                    : report.status === WITHDRAWN
                    ? _.upperFirst(WITHDRAWN)
                    : _.upperFirst(CLEAR)}
                </Text>
                {report.values.map((record, i) => (
                  <>
                    <Row
                      key={i.toString()}
                      style={styles.checks.hitInfoContainer}
                    >
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.caseNumber}
                        text={record.case_number}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.state}
                        text={record.state}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.crimeType}
                        text={toTitleCase(
                          record.crime_type ? record.crime_type : ""
                        )}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.disposition}
                        text={record.disposition}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.disposition_date}
                        text={record.disposition_date}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.districtFederalRecords}
                        text={record.district}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.offenseDescription}
                        text={record.offense_description1}
                      />
                      <DataItem
                        width="25%"
                        widthMD="50%"
                        widthSM={isPDFReport ? "25%" : "100%"}
                        label={messages.offenseDay}
                        text={record.offense_date}
                      />
                    </Row>
                    <Divider checked={`Checked on ${this.reportDate}`} />
                  </>
                ))}
              </div>
            </Row>
          ))}
        </div>
      </div>
    );
  }
}

export default FederalRecord;
