import React from "react";
import "./index.css";

const ChaseDotsLoader = () => (
  <div className="sk-chase-container">
    <div className="sk-chase">
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
      <div className="sk-chase-dot" />
    </div>
  </div>
);

export default ChaseDotsLoader;
