import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CandidateReportStyles as styles } from "../../styles";
import Text from "../Text";
import Title from "../Title";
import Divider from "../Divider";

const Check = ({
  check,
  date,
  color,
  icon,
  status,
  noDivider,
  shouldNotRender,
  containerStyle = {},
  nameId = null,
  statusId = null
}) => {
  const { summary } = styles;
  return shouldNotRender ? null : (
    <div style={{ ...summary.container, ...containerStyle }}>
      <Title id={nameId} color={color} style={summary.check} size="16px">
        <FontAwesomeIcon icon={icon} style={summary.icon} />
        {check}
      </Title>
      <Text size="14px" style={summary.date}>
        {date}
      </Text>
      <Text
        id={statusId}
        size="14px"
        weight="bold"
        style={summary.status}
        color={color}
      >
        {_.upperFirst(status)}
      </Text>
      {noDivider ? null : <Divider height="1px" />}
    </div>
  );
};

Check.propTypes = {
  check: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  status: PropTypes.string,
  noDivider: PropTypes.bool,
  shouldNotRender: PropTypes.bool,
  containerStyle: PropTypes.object,
  nameId: PropTypes.string,
  statusId: PropTypes.string
};

export default observer(Check);
