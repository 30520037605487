import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { isEmpty } from "lodash";
import Header from "./Header";
import Record from "./Record";
import { parseAccident } from "../../../models/mvr";

class MVRAccidents extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    expand: PropTypes.bool.isRequired,
    icon: PropTypes.node
  };
  render() {
    const { data, expand, icon } = this.props;
    if (isEmpty(data)) return null;

    const exclude = [];
    const parsed = data.map(parseAccident);
    const count = parsed.length;

    return (
      <div>
        <Header title="Accidents" count={count} icon={icon} />
        {parsed.map((accident, key) => (
          <Record
            title={accident.details || accident.incident_date}
            data={accident}
            expand={expand}
            key={key}
            exclude={exclude}
          />
        ))}
      </div>
    );
  }
}
export default MVRAccidents;
