import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Row from "../../components/Row";
import Column from "../../components/Column";

import messages from "./messages";
import styles from "./styles.js";

const propTypes = {
  model: PropTypes.object
};

const CheckWorkerForm = observer(
  class CheckWorkerForm extends Component {
    setFullName = e => {
      this.props.model.setFullName(e.target.value);
    };
    setFirstName = e => {
      this.props.model.setFirstName(e.target.value);
    };
    setLastName = e => {
      this.props.model.setLastName(e.target.value);
    };
    setEmail = e => {
      this.props.model.setEmail(e.target.value);
    };
    setPhoneNumber = e => {
      this.props.model.setPhoneNumber(e.target.value);
    };
    handleChange = e => {
      this.props.model.selectPartnerPackage(e.target.value);
    };
    touchLastName = e => {
      e.preventDefault();
      if (this.props.model.lastName.length === 0) return;
      this.props.model.touch("lastName");
    };
    touchFirstName = e => {
      e.preventDefault();
      if (this.props.model.firstName.length === 0) return;
      this.props.model.touch("firstName");
    };
    touchEmail = e => {
      e.preventDefault();
      if (this.props.model.email.length === 0) return;
      this.props.model.touch("email");
    };
    render() {
      if (!this.props.model) return null;
      return (
        <>
          <Row style={{ marginBottom: "1rem" }}>
            <Column width="50%" padding="0 9px 0 9px">
              <InputLabel style={styles.inputLabel}>
                <FormattedMessage {...messages.firstName} />
              </InputLabel>
              <TextField
                id="worker_firstname"
                fullWidth
                autocomplete="nope"
                autoFocus={this.props.model.firstName.length === 0}
                value={this.props.model.firstName}
                onChange={this.setFirstName}
                onBlur={this.touchFirstName}
                error={this.props.model.errorFirstName}
                helperText={this.props.model.errorFirstName}
                style={styles.textField}
              />
            </Column>
            <Column width="50%" padding="0 9px 0 9px">
              <InputLabel style={styles.inputLabel}>
                <FormattedMessage {...messages.lastName} />
              </InputLabel>
              <TextField
                id="worker_lastname"
                fullWidth
                autocomplete="nope"
                value={this.props.model.lastName}
                onChange={this.setLastName}
                onBlur={this.touchLastName}
                error={this.props.model.errorLastName}
                helperText={this.props.model.errorLastName}
                style={styles.textField}
              />
            </Column>
          </Row>
          <Row style={{ marginBottom: "1rem" }}>
            <Column width="50%" padding="0 9px 0 9px">
              <InputLabel style={styles.inputLabel}>
                <FormattedMessage {...messages.labelEmail} />
              </InputLabel>
              <TextField
                id="worker_email"
                placeholder="email@example.com"
                fullWidth
                autocomplete="nope"
                value={this.props.model.email}
                onChange={this.setEmail}
                onBlur={this.touchEmail}
                error={this.props.model.errorEmail}
                helperText={this.props.model.errorEmail}
                style={styles.textField}
              />
            </Column>
            <Column width="50%" padding="0 9px 0 9px">
              <InputLabel style={styles.inputLabel}>
                <FormattedMessage {...messages.labelPhone} />
              </InputLabel>
              <TextField
                id="worker_phonenumber"
                fullWidth
                autocomplete="nope"
                value={this.props.model.phoneNumber}
                onChange={this.setPhoneNumber}
                error={this.props.model.errorPhoneNumber}
                helperText={this.props.model.errorPhoneNumber}
                style={styles.textField}
              />
            </Column>
          </Row>
          <Row style={{ marginBottom: "0.4rem" }}>
            <Column id="partner-package-column" padding="0 9px 0 12px">
              <InputLabel style={styles.inputLabel}>
                <FormattedMessage {...messages.labelPackages} />*
              </InputLabel>
              <Select
                value={this.props.model.selected_partner_package.package_id}
                onChange={this.handleChange}
                style={styles.select}
              >
                {this.props.model.partner_packages.map(
                  (partner_package, key) => (
                    <MenuItem
                      className="menuItemDropdown"
                      value={partner_package.package_id}
                      key={key}
                    >
                      {partner_package.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </Column>
          </Row>
        </>
      );
    }
  }
);

CheckWorkerForm.propTypes = propTypes;

export default CheckWorkerForm;
