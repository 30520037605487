import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer, PropTypes as MobxTypes } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { TableRowColumn, TableHeaderColumn } from "material-ui/Table";
import _ from "lodash";
import DataTable from "../DataTable";
import Highlighted from "../Highlighted";
import { TableStyles as styles } from "../../styles";
import AddressTableContainer from "./container";

import messages from "./messages";
import TableData from "../TableData";
import { Divider } from "@material-ui/core";
import { SearchHighlighted } from "../SearchHighlighted";
import withContext from "../../contextAPI/context-HOC";

export const AddressTable = observer(
  class AddressTable extends Component {
    static propTypes = {
      addresses: PropTypes.oneOfType([
        MobxTypes.observableArray,
        PropTypes.array
      ]),
      setShowAll: PropTypes.func,
      config: PropTypes.array
    };

    static defaultProps = {
      context: {}
    };

    componentDidUpdate(prevProps) {
      const { checks: { expandAllChecks } = {} } = this.props.context;
      if (
        _.isBoolean(expandAllChecks) &&
        prevProps.context.checks.expandAllChecks !== expandAllChecks &&
        _.isFunction(this.props.setShowAll)
      ) {
        this.props.setShowAll(expandAllChecks);
      }
    }

    getHeaderColumnStyle = value => {
      let _styles = { ...styles.headerColumn };

      if (value === "address1") {
        _styles = { ..._styles, ...styles.address.colAddress1 };
      } else if (value === "city") {
        _styles = { ..._styles, ...styles.address.colCity };
      } else if (value === "zipcode") {
        _styles = { ..._styles, ...styles.address.colZip };
      } else if (value === "state") {
        _styles = { ..._styles, ...styles.address.colState };
      }

      return _styles;
    };

    render() {
      const { addresses, config, showAll } = this.props;

      return Array.isArray(addresses) && Array.isArray(config) ? (
        <AddressTableContainer showAll={showAll}>
          <DataTable
            header={
              <div style={styles.address.headerContainerStyle}>
                {config.map((option, key) => (
                  <TableHeaderColumn
                    key={key}
                    style={this.getHeaderColumnStyle(option.value)}
                  >
                    <Highlighted>
                      <FormattedMessage {...messages[option.value]} />
                    </Highlighted>
                  </TableHeaderColumn>
                ))}
              </div>
            }
            body={addresses.map((item, index) => (
              <tr style={styles.row} key={index}>
                <TableData wrapperStyle={styles.address.colAddress1}>
                  <TableRowColumn style={{ float: "none" }}>
                    <SearchHighlighted textToHighlight={item.address1} />
                    {showAll}
                  </TableRowColumn>
                </TableData>
                <TableData wrapperStyle={styles.address.colCity}>
                  <TableRowColumn>
                    <SearchHighlighted textToHighlight={item.city} />
                  </TableRowColumn>
                </TableData>
                <TableData wrapperStyle={styles.address.colZip}>
                  <TableRowColumn>
                    <SearchHighlighted textToHighlight={item.zip} />
                  </TableRowColumn>
                </TableData>
                <TableData wrapperStyle={styles.address.colState}>
                  <TableRowColumn>
                    <SearchHighlighted textToHighlight={item.state} />
                  </TableRowColumn>
                </TableData>
                {showAll && <Divider style={styles.divider} />}
              </tr>
            ))}
          />
        </AddressTableContainer>
      ) : null;
    }
  }
);

export default withContext(AddressTable);
