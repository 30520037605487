import React from "react";
import _ from "lodash";

import { SubscriptionSection } from "./SubscriptionSection";

export const SubscriptionSettings = ({ data = {} }) => {
  // Find all active Subscriptions
  const subscriptions = _(data.sourcing).sortBy([
    "allotted_usage",
    "total_usage"
  ]);

  //Match the current Subscription
  const subscription =
    subscriptions
      .filter(
        ({ allotted_usage, total_usage }) => allotted_usage - total_usage > 0
      )
      .first() || subscriptions.first();

  // Match the Overage Subscription
  const overage = subscriptions
    .filter(({ allotted_usage }) => _.isUndefined(allotted_usage))
    .first();

  // Add the current Overage Usage
  if (overage) {
    subscription.total_usage += overage.total_usage;
  }

  if (subscription) {
    return (
      <SubscriptionSection title="Sourcing Subscription" {...subscription} />
    );
  } else {
    return null;
  }
};
