import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Radio, FormControlLabel } from "@material-ui/core";
import { observer } from "mobx-react";
import { radioButtonStyles as styles } from "./styles";
import { primary } from "../../styles/constants";

const RadioSelect = observer(
  class RadioSelect extends Component {
    static propTypes = {
      setHasCarInsurance: PropTypes.func,
      hasCarInsurance: PropTypes.string
    };
    constructor(props) {
      super(props);
    }
    getRadioButton = value => (
      <Radio
        color="primary"
        colorSecondary="primary"
        onClick={() => {
          this.props.setHasCarInsurance(value);
        }}
        checked={this.props.hasCarInsurance === value}
        style={{ color: primary }}
      />
    );
    render() {
      return (
        <>
          <FormControlLabel
            control={this.getRadioButton("true")}
            label={<span style={styles.labelStyles}>Yes</span>}
            labelPlacement="end"
          />
          <FormControlLabel
            control={this.getRadioButton("false")}
            label={<span style={styles.labelStyles}>No</span>}
            labelPlacement="end"
          />
        </>
      );
    }
  }
);

export default RadioSelect;
