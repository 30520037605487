import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import Img from "../Img";
import Highlighted from "../Highlighted";
import { LandingPageStyles as styles } from "../../styles";
import ContentContainer from "./content-container";
import messages from "./messages";

const propTypes = {
  details: PropTypes.object,
  redirect_url: PropTypes.string
};

class ConsentProvided extends Component {
  constructor(props) {
    super(props);
    this.redirect_url = localStorage.getItem("redirect_url");
  }
  componentDidMount() {
    if (this.redirect_url) {
      const timer = parseInt(process.env.REACT_APP_REDIRECT_URL_TIMER, 10);
      window.setTimeout(
        () => {
          window.location.assign(this.redirect_url);
          localStorage.removeItem("redirect_url");
        },
        !isNaN(timer) ? timer : 5000
      );
    }
  }
  render() {
    const { details } = this.props;
    if (!details) return null;
    return (
      <ContentContainer>
        <Img type="logo" style={styles.headerImage} />
        <h1 id="consentTitle" style={styles.confirm.title}>
          <FormattedMessage {...messages.consentTitle} />
        </h1>
        <p id="consentSubTitle" style={styles.fontSize}>
          <FormattedMessage {...messages.consentSubTitle} />
        </p>
        <p style={styles.fontSize}>
          <Highlighted>
            {this.props.redirect_url ? (
              <FormattedMessage {...messages.redirect} />
            ) : (
              <FormattedMessage {...messages.close} />
            )}
          </Highlighted>
        </p>
      </ContentContainer>
    );
  }
}

ConsentProvided.propTypes = propTypes;

export default ConsentProvided;
