import styled from "styled-components";

export const MessageContainer = styled.div`
  margin: 0 auto;
  max-width: 382px;
  font-size: 14px;
  color: #7e7e8f;
`;

export const ContactLink = styled.a`
  color: #2775ff;

  &:focus-visible {
    outline: none;
  }
`;

export const TextContainer = styled.div`
  margin: 0 auto;
  max-width: 400px;
  font-size: 14px;
  color: #7e7e8f;
`;

export const Description = styled.p`
  margin-bottom: 30px;
`;
