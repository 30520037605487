import styled from "styled-components";

const EmailedContainer = styled.div`
  color: #708392;
  display: ${props => props.display || "block"};
  font-weight: ${props => props.weight || 500};
  width: 100%;
  height: 32px
  text-transform:capitalize;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  margin-top: 0.8rem;

  p {
    display: flex;
    align-items: center;
  }

  span{
    padding-left: 2rem;
    font-size: 0.7rem;
    display: flex;
    align-items: flex-start;
  }

  #email-again-button{
    color: white;
    background-color: #00D9CD;
    border: none;
    border-radius: 5px;
    padding: 6px 10px;
    font-size: 0.7rem;
  }

  #email-again-button:hover {
    background-color: #b7ecec;
    cursor: pointer;
  }
`;

export default EmailedContainer;
