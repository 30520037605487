import { periwinkle, secondary, white } from "../../../styles/constants";

import PropTypes from "prop-types";
import React from "react";
import Logo from "../../Logo";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import styled from "styled-components";

/**
 * Renders the primary background color and sets up flex-box
 */
const StepperWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 120px;
  width: 100%;
  background-color: ${white};

  @media (max-width: 720px) {
    // Add breathing room for Turn logo on top
    height: 160px;
  }
`;

/**
 * Wrapper for each of the layers rendered on the Stepper component.
 *
 * It absolutely positions each layer on top of each other.
 */
const StepperLayer = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 21px;

  @media (max-width: 720px) {
    align-items: flex-start;
    justify-content: center;
  }
`;

/**
 * Composed on top of the `StepperLayer` component styles, this one "shrinks"
 * the width of the layer and horizontally centers it.
 *
 * It's mostly used for the `StepBar` and it's labels.
 */
const StepperLayerNarrow = styled(StepperLayer)`
  width: 600px;
  left: calc(50% - 250px);

  @media (max-width: 720px) {
    width: 100%;
    left: 0;
    padding-top: 70px; // Estimated height of StepperLayer Logo + margins
  }
`;

/**
 * Renders the grey progress bar background
 *
 * @param {number} totalSteps Total steps (used to calculate left and right margins)
 */
const StepBar = styled.div`
  position: relative;
  margin: 20px ${props => 100 / props.totalSteps / 2}%;
  width: calc(100% - ${props => 100 / props.totalSteps}%);
  height: ${props => props.height || "8px"};
  overflow: visible;
  border-radius: 7.5px;
  background-color: ${periwinkle};
`;

/**
 * Renders the turquoise progress bar background
 *
 * @param {number} percentComplete Percent complete of the wizard (0 to 100 float), used to render width
 */
const StepBarComplete = styled.div`
  width: ${props => props.percentComplete}%;
  height: 100%;
  background: ${secondary};
  border-radius: 7.5px;
  transition: width 0.5s;
`;

/**
 * Renders the White dot on top of the progress bar.abs
 *
 * @param {string} size (Optional) CSS size of the dot, defaults to 24px
 * @param {number} percentComplete Percent complete of the wizard (0 to 100 float), used to render left position
 */
const StepDot = styled.span`
  position: absolute;
  top: calc(50% - ${props => props.size || "24px"} / 2);
  left: calc(
    ${props => props.percentComplete}% - ${props => props.size || "24px"} / 2
  );
  height: ${props => props.size || "24px"};
  width: ${props => props.size || "24px"};
  background-color: ${white};
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(95, 125, 149, 0.35);
  transition: left 0.5s;
`;

/**
 * Renders the turquoise dot inside the `StepDot`, absolutely vertically and horizontally centered.
 *
 * @param {string} size (Optional) Size of the dot, defaults to 6px.
 */
const StepInnerDot = styled.span`
  position: absolute;
  left: calc(50% - ${props => props.size || "12px"} / 2);
  top: calc(50% - ${props => props.size || "12px"} / 2);
  height: ${props => props.size || "12px"};
  width: ${props => props.size || "12px"};
  background-color: ${secondary};
  border-radius: 50%;
`;

/**
 * Wrapper to render each Step label.
 */
const StepLabelWrapper = styled.div`
  flex: 1;
  text-align: center;
  padding-top: 44px; // Height of the StepDot + margins
  cursor: pointer;

  @media (max-width: 425px) {
    opacity: ${props => (props.isActive ? 1 : 0)};
    pointer-events: none;
  }
`;

/**
 * Renders a Stepper Bar showing progress.
 *
 * @param {number} currentStep Current step (zero index)
 * @param {number} totalSteps Total steps
 */
export const StepperBar = ({ currentStep, totalSteps }) => {
  if (totalSteps < 2) {
    return null;
  }

  const percentComplete = (currentStep / (totalSteps - 1) || 0) * 100;
  return (
    <StepBar totalSteps={totalSteps}>
      <StepBarComplete percentComplete={percentComplete} />
      <StepDot percentComplete={percentComplete}>
        <StepInnerDot />
      </StepDot>
    </StepBar>
  );
};

StepperBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired
};

/**
 * Renders a Step label
 *
 * @param {bool} isActive (Optional) If true, will render as turquoise
 */
const StepperStep = ({ isActive, disabled, children, ...props }) => {
  return (
    <StepLabelWrapper isActive={isActive} {...props}>
      <Typography
        variant="body2"
        style={{
          opacity: disabled ? 0.6 : 1,
          color: isActive ? secondary : "#9A9AAF",
          fontSize: "12px"
        }}
      >
        {children}
      </Typography>
    </StepLabelWrapper>
  );
};

StepperStep.propTypes = {
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
};

/**
 * Renders the Form Wizard Stepper header component. The `FormWizardStep` components
 * must be passed as children so their labels can be retrieved.
 *
 * This is a controlled component, implementor MUST set `currentStep` and update it as needed.
 * It's automatically passed-down by `react-step-wizard`.
 *
 * @param {number} currentStep The currently rendered step (NOT zero index based)
 * @param {function} goToStep A function that updates the `currentStep`
 */
export const FormWizardStepper = ({
  currentStep,
  goToStep,
  children,
  ...props
}) => {
  const labelComponents = _(React.Children.toArray(children))
    .filter(child => React.isValidElement(child))
    .map((child, i) => {
      const label = _.get(child, "props.label", "FIX ME");
      const disabled = _.get(child, "props.disabled", false);

      return (
        <StepperStep
          id={`${label}Step`}
          key={i}
          isActive={i == currentStep - 1}
          disabled={disabled}
          onClick={() => !disabled && goToStep(i + 1)}
        >
          {label}
        </StepperStep>
      );
    })
    .value();

  return (
    <StepperWrapper style={{ border: "0.5px solid #e5e7eb" }} {...props}>
      <StepperLayer>
        <Logo fill="#2d3047" />
      </StepperLayer>
      <StepperLayerNarrow>
        <StepperBar
          currentStep={currentStep - 1}
          totalSteps={labelComponents.length}
        />
      </StepperLayerNarrow>
      <StepperLayerNarrow>{labelComponents}</StepperLayerNarrow>
    </StepperWrapper>
  );
};

FormWizardStepper.propTypes = {
  currentStep: PropTypes.number,
  goToStep: PropTypes.func,
  children: PropTypes.node.isRequired
};
