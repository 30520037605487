import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { toRelativeFromISO } from "turn-shared";
import { getStatus } from "turn-shared";

import SortableTableHeader from "../DataTable/sortable-table-header";
import DataTable from "../DataTable";
import { TableStyles as styles } from "../../styles";
import WorkerTableFooter from "./footer";
import messages from "./messages";
import DoubleBounceLoader from "../DoubleBounceLoader";
import ReSendEmail from "../ReSendEmail";
import withContext from "../../contextAPI/context-HOC";
import _ from "lodash";
import CandidateStatusUI from "../CandidateStatusUI";
import IconButton from "@material-ui/core/IconButton";
import {
  SearchHighlightedProvider,
  SearchHighlighted
} from "../SearchHighlighted/SearchHighlighted";
import TableData from "../TableData";

const Header = ({ config, model, orderWorkers }) => {
  const { ascending, orderProperty, isOrderable } = model;
  return config.map((option, index) => (
    <SortableTableHeader
      key={index}
      ascending={ascending}
      sortTable={orderWorkers}
      sortBy={orderProperty}
      isSortable={isOrderable(option.value)}
      value={option.value}
      style={styles.sortableTableHeader}
      tableType="worker"
    >
      <FormattedMessage {...messages[option.value]} />
    </SortableTableHeader>
  ));
};
Header.propTypes = {
  config: PropTypes.array,
  model: PropTypes.object,
  orderWorkers: PropTypes.func,
  isOrderable: PropTypes.func
};

export class WorkerTable extends Component {
  static propTypes = {
    workers: PropTypes.array,
    onRowClick: PropTypes.func,
    onPreviewClick: PropTypes.func,
    pageLimit: PropTypes.number,
    pageSize: PropTypes.number,
    page: PropTypes.number,
    onPageSize: PropTypes.func,
    onPageNumber: PropTypes.func,
    config: PropTypes.array,
    model: PropTypes.object,
    orderWorkers: PropTypes.func,
    showTable: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      model,
      onRowClick,
      onPreviewClick,
      onPageSize,
      onPageNumber,
      orderWorkers,
      setLimit,
      showTable,
      adverseAction,
      context
    } = this.props;

    if (!(model && model.totalWorkers && model.config)) return null;

    const {
      config,
      totalWorkers: workers,
      page,
      limit: pageSize,
      pageLimit
    } = model;

    const header = <Header {...{ config, model, orderWorkers }} />;

    const body = showTable ? (
      workers.map(worker => (
        <tr
          id="candidateRow"
          style={styles.new.smokeWhite.row}
          key={worker.id}
          onMouseUp={() => onRowClick(worker)}
        >
          <TableData
            id="candidateStatusColumn"
            wrapperStyle={styles.candidateStatusUICol}
            contentStyle={styles.contentSatus}
          >
            <CandidateStatusUI
              enableEmailStatus={context.features.REACT_APP_ENABLE_EMAIL_STATUS}
              worker={worker}
              status={getStatus(worker.status)}
              adverseAction={adverseAction}
              fullWidth
            />
          </TableData>
          <TableData id="candidateNameColumn" wrapperStyle={styles.colName}>
            <SearchHighlighted textToHighlight={worker.displayName} />
          </TableData>
          <TableData id="candidateLocationColumn" wrapperStyle={styles.colCity}>
            {_.isEmpty(worker.city) ? "-" : worker.city}
          </TableData>
          <TableData
            id="candidateConsentDateColumn"
            wrapperStyle={styles.colInvite}
          >
            {_.isEmpty(worker.relativeSignUpDate)
              ? "-"
              : toRelativeFromISO(worker.relativeSignUpDate)}
          </TableData>
          <TableData
            id="candidatePackageColumn"
            wrapperStyle={styles.colPackage}
          >
            {worker.package}
          </TableData>
          <TableData wrapperStyle={styles.colAction}>
            {worker.status !== "emailed" ? (
              <IconButton
                id="viewReportButton"
                onMouseDown={() => onPreviewClick(worker)}
                style={{ maxWidth: "100%" }}
              >
                <i
                  id="viewReportIcon"
                  className="fas fa-eye"
                  style={styles.icon}
                />
              </IconButton>
            ) : (
              <ReSendEmail worker={worker} />
            )}
          </TableData>
        </tr>
      ))
    ) : (
      <div style={{ ...styles.progressContainer }}>
        <DoubleBounceLoader />
      </div>
    );

    const footer = (
      <WorkerTableFooter
        {...{ page, pageSize, pageLimit, onPageSize, onPageNumber, setLimit }}
      />
    );

    return (
      <SearchHighlightedProvider search={model.search}>
        <DataTable
          header={header}
          body={body}
          footer={footer}
          styles={styles.new.smokeWhite}
        />
      </SearchHighlightedProvider>
    );
  }
}

export default withContext(observer(WorkerTable));
