const preventClick = (router, worker) => {
  if (!router || !worker) return true;
  switch (worker.status) {
    case "emailed":
      if (worker.consentDate === "" || !worker.consentDate) return true;
      return false;
    case "initiated":
      return true;
    default:
      return false;
  }
};

export default preventClick;
