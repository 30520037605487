import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import _ from "lodash";

import SideBar from "../../containers/SideBar";
import AppContainer from "../AppContainer";
import withContext from "../../contextAPI/context-HOC";
import AppHeader from "../../containers/AppHeader";
import Background from "../Background";

import {
  SIDEBAR_COLLAPSED_WIDTH,
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM
} from "../../constants";
import CollapsibleIcon from "./collapsibleIcon";
import FreshChat from "../../containers/FreshChat";

/**
 *
 * When current window size is below MD breakpoint collapsed funcionality is disabled, and text/icons are rendered normally.
 * Collapsed funcionality can be controlled by parent but collapsed, toggleCollapsed and setCollapsed props must be present.
 *
 * @param {object} props
 * @param {bool} props.collapsed When toggleCollapse and setCollapse are also present controls component collapse behaviour
 * @param {func} props.toggleCollapsed When collapsed and setCollapse are also present gets called instead of components method
 * @param {func} props.setCollapsed When toggleCollapse and collapsed are also present gets called instead of components method
 * @param {bool} props.renderHeader Determines if AppHeader should be rendered, defaults to true
 * @param {bool} props.renderSidebar Determines if Sidebar should be rendered, defaults to true
 */

export class Page extends React.Component {
  static propTypes = {
    collapsed: PropTypes.bool,
    toggleCollapsed: PropTypes.func,
    setCollapsed: PropTypes.func,
    renderHeader: PropTypes.bool,
    renderSidebar: PropTypes.bool,

    appContainerProps: PropTypes.object,
    sideBarProps: PropTypes.object,
    backgroundProps: PropTypes.object,
    appHeaderProps: PropTypes.object,
    sideBarCollapsible: PropTypes.bool,
    disableNavigation: PropTypes.bool,

    modal: PropTypes.node,

    children: PropTypes.node
  };

  static defaultProps = {
    appContainerProps: {},
    sideBarProps: {},
    backgroundProps: {},
    appHeaderProps: {},

    renderHeader: true,
    renderSidebar: true,

    modal: null
  };

  constructor(props) {
    super(props);
    window.addEventListener("resize", this.handleResize);
  }

  state = {
    collapsed: true,
    userCollapsed: true,
    mdBreak: false
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    if (window.innerWidth < BREAKPOINT_MD) {
      this.setCollapsed(true);
    }
    this.handleResize();
  }

  componentDidUpdate(prevProps) {
    if (
      _.isBoolean(this.props.collapsed) &&
      _.isFunction(this.props.toggleCollapsed) &&
      _.isFunction(this.props.setCollapsed)
    ) {
      if (
        prevProps.collapsed !== this.props.collapsed &&
        !this.state.userCollapsed
      ) {
        this.setUserCollapsed(true);
      }
    }
  }

  setCollapsed = collapsed => {
    if (!_.isBoolean(collapsed)) return;
    if (
      _.isBoolean(this.props.collapsed) &&
      _.isFunction(this.props.toggleCollapsed) &&
      _.isFunction(this.props.setCollapsed)
    )
      this.props.setCollapsed(collapsed);
    else this.setState({ collapsed });
  };

  setUserCollapsed = userCollapsed =>
    _.isBoolean(userCollapsed) ? this.setState({ userCollapsed }) : null;

  setMdBreak = mdBreak =>
    _.isBoolean(mdBreak) ? this.setState({ mdBreak }) : null;

  toggleCollapsed = () => {
    if (!this.state.userCollapsed) this.setUserCollapsed(true);
    if (
      _.isBoolean(this.props.collapsed) &&
      _.isFunction(this.props.toggleCollapsed) &&
      _.isFunction(this.props.setCollapsed)
    )
      this.props.toggleCollapsed();
    else this.setCollapsed(!this.state.collapsed);
  };

  handleResize = () => {
    this.setMdBreak(window.innerWidth <= BREAKPOINT_MD);
    if (_.isFunction(_.get(this.props, "context.setMdBreak")))
      this.props.context.setMdBreak(window.innerWidth <= BREAKPOINT_MD);
    if (_.isFunction(_.get(this.props, "context.setSmBreak")))
      this.props.context.setSmBreak(window.innerWidth <= BREAKPOINT_SM);
    if (!this.state.userCollapsed) {
      this.setCollapsed(window.innerWidth <= BREAKPOINT_LG);
    }
  };

  get collapsed() {
    if (this.props.disableNavigation) return true;
    if (this.state.mdBreak) return false;
    if (
      _.isBoolean(this.props.collapsed) &&
      _.isFunction(this.props.toggleCollapsed) &&
      _.isFunction(this.props.setCollapsed)
    )
      return this.props.collapsed;
    return this.state.collapsed;
  }

  renderHeader = () => {
    if (!this.props.renderHeader) return null;
    return (
      <AppHeader
        {...this.props.appHeaderProps}
        disableNavigation={this.props.disableNavigation}
      />
    );
  };

  renderSidebar = () => {
    if (!this.props.renderSidebar) return null;
    return (
      <SideBar
        {...this.props.sideBarProps}
        collapsed={this.collapsed}
        mdBreak={this.state.mdBreak}
        disableNavigation={this.props.disableNavigation}
        setCollapsed={this.setCollapsed}
        collapsedIcon={
          this.props.disableNavigation ? null : (
            <CollapsibleIcon
              collapsed={this.collapsed}
              onClick={this.toggleCollapsed}
            />
          )
        }
      />
    );
  };

  render() {
    const { appContainerProps, backgroundProps } = this.props;

    return (
      <AppContainer
        {...appContainerProps}
        padding={`0 0 0 ${SIDEBAR_COLLAPSED_WIDTH}`}
      >
        {this.props.modal}
        {this.renderSidebar()}
        <FreshChat />
        <Background {...backgroundProps} innerRef={this.props.setContentRef}>
          {this.renderHeader()}
          {this.props.children}
        </Background>
      </AppContainer>
    );
  }
}

export default withContext(observer(Page));
