import {
  READY_APPLICANTS,
  READY_LOCATIONS,
  READY_OPENINGS,
  READY_OPENING_NEW,
  READY_LOCATION_NEW,
  READY_POSITION_NEW,
  READY_POSITIONS,
  READY_JOB_APPLICANTS
} from "../constants";

export const getSubtabVariantFromRoute = (route = "") => {
  if (route === "/recruiter/openings") return READY_OPENINGS;
  else if (route === "/recruiter/applicants") return READY_APPLICANTS;
  else if (route === "/ready/locations") return READY_LOCATIONS;
  else if (route === "/ready/positions") return READY_POSITIONS;
  else if (route === "/ready/locations/new") return READY_LOCATION_NEW;
  else if (route === "/ready/positions/new") return READY_POSITION_NEW;
  else if (route === "/ready/openings/new") return READY_OPENING_NEW;
  else if (
    route.includes("/recruiter/openings") &&
    route.includes("/applicants")
  )
    return READY_JOB_APPLICANTS;
};
