import _ from "lodash";
import ArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import ArrowUp from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import { observer } from "mobx-react";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import {
  TABLE_DATA_ACTION,
  TABLE_DATA_CITY,
  TABLE_DATA_INVITE,
  TABLE_DATA_NAME,
  TABLE_DATA_PACKAGE,
  TABLE_DATA_STATUS
} from "../../constants";
import withContext from "../../contextAPI/context-HOC";
import { TableStyles as styles } from "../../styles";
import { periwinkle, primary } from "../../styles/constants";
import Highlighted from "../Highlighted";
import TableData from "../TableData";

const Icon = ({ sortBy, value, ascending, style, tableType, isSortable }) => {
  if (
    !sortBy ||
    value === "empty" ||
    value === "package" ||
    value === "status" ||
    !isSortable
  )
    return null;
  if (tableType === "pendingWorker" && value !== "acceptedDate") return null;
  if ((sortBy !== value && ascending) || !ascending)
    return (
      <ArrowDown
        style={style}
        color={sortBy !== value ? periwinkle : primary}
      />
    );
  return (
    <ArrowUp style={style} color={sortBy !== value ? periwinkle : primary} />
  );
};
Icon.propTypes = {
  tableType: PropTypes.string,
  sortBy: PropTypes.string,
  value: PropTypes.string,
  ascending: PropTypes.bool,
  style: PropTypes.object,
  isSortable: PropTypes.bool
};

export class SortableTableHeader extends Component {
  static propTypes = {
    ascending: PropTypes.bool,
    children: PropTypes.node,
    sortTable: PropTypes.func,
    sortBy: PropTypes.string,
    value: PropTypes.string,
    isSortable: PropTypes.bool,
    containerStyle: PropTypes.object,
    contentStyle: PropTypes.object
  };

  packageStyle = () =>
    Object.assign({}, styles.headerColumn, {
      textAlign: "right",
      cursor: "default"
    });

  onBoardingStyle = () =>
    Object.assign({}, styles.headerColumn, {
      width: this.props.context.features.REACT_APP_ENABLE_EMAIL_STATUS
        ? "23%"
        : ""
    });

  getOldStyle = value =>
    value === "package"
      ? this.packageStyle()
      : value === "status"
      ? this.onBoardingStyle()
      : styles.headerColumn;

  getContainerStyle = (tableType, value = "", containerStyle) => {
    let _styles = { ...styles.headerContainer };
    if (_.isObject(containerStyle)) {
      _styles = { ..._styles, ...containerStyle };
    }
    if (tableType === "worker" && value === "checkStatus") {
      _styles = { ..._styles, ...styles.statusContent };
    } else if (tableType === "worker" && value === "fullName") {
      _styles = { ..._styles, ...styles.nameContainer };
    }
    return _styles;
  };

  getContentStyle = (tableType, value = "", contentStyle) => {
    let _styles = { ...styles.headerContent };
    if (_.isObject(contentStyle)) {
      _styles = { ..._styles, ...contentStyle };
    }
    if (value === "checkStatus" || value === "status") {
      _styles = { ..._styles, ...styles.checkStatus };
    }
    if (tableType === "pendingWorker" && value === "fullName") {
      _styles = { ..._styles, ...styles.checkStatus };
    }
    return _styles;
  };

  getWorkerStyle = value => {
    switch (value) {
      case "status":
        return {
          width: TABLE_DATA_STATUS,
          minWidth: "150px",
          padding: 0
        };
      case "fullName":
        return {
          width: TABLE_DATA_NAME,
          padding: 0,
          cursor: "pointer"
        };
      case "city":
        return {
          width: TABLE_DATA_CITY,
          cursor: "pointer",
          padding: 0
        };
      case "signUpDate":
        return {
          width: TABLE_DATA_INVITE,
          padding: 0,
          cursor: "pointer"
        };
      case "package":
        return {
          width: TABLE_DATA_PACKAGE,
          padding: 0
        };
      case "empty":
        return {
          width: TABLE_DATA_ACTION
        };
      default:
        return styles.headerColumn;
    }
  };

  getPendingWorkerStyle = value => {
    switch (value) {
      case "fullName":
        return {
          width: "40%",
          padding: 0
        };
      case "city":
        return {
          width: "34%",
          padding: 0
        };
      case "signUpDate":
        return {
          width: "20%",
          padding: 0,
          cursor: "pointer"
        };
      case "empty":
        return {
          width: "5%"
        };
      default:
        return styles.headerColumn;
    }
  };
  getApplicantStyle = value => {
    let width = "20%";
    let padding = 0;
    switch (value) {
      case "status":
        width = "25%";
        break;
      case "fullName":
        width = "30%";
        break;
      case "action":
        padding = "0 0 0 7px";
        width = "10%";
        break;
      case "acceptedDate":
        width = "15%";
        break;
    }

    return {
      width,
      padding,
      cursor: "inherit",
      display: "flex",
      alignItems: "center"
    };
  };

  getApplicationStyle = value => {
    let width = "30%";
    if (value === "status") {
      width = "45%";
    } else if (value === "acceptedDate") {
      width = "25%";
    }
    return {
      width,
      padding: 0,
      cursor: "pointer",
      display: "flex",
      alignItems: "center"
    };
  };

  sort = () => {
    const { sortTable, value, isSortable } = this.props;
    if (!isSortable) return;
    if (sortTable) sortTable(value);
  };

  getStyleType = (tableType, value, styles) => {
    if (tableType === "worker") {
      return this.getWorkerStyle(value);
    }
    if (tableType === "pendingWorker") {
      return this.getPendingWorkerStyle(value);
    }
    if (tableType === "applicant") {
      return this.getApplicantStyle(value);
    }
    if (tableType === "application") {
      return this.getApplicationStyle(value);
    }
    return styles;
  };

  render() {
    const {
      value,
      children,
      styles: _styles,
      tableType,
      containerStyle,
      contentStyle,
      ...rest
    } = this.props;
    const tableHeaderStyle = this.getStyleType(tableType, value, _styles);

    return (
      <TableData
        wrapperStyle={tableHeaderStyle}
        containerStyle={this.getContainerStyle(
          tableType,
          value,
          containerStyle
        )}
        contentStyle={this.getContentStyle(tableType, value, contentStyle)}
        onClick={this.sort}
      >
        <Highlighted {...rest}>{children}</Highlighted> <Icon {...this.props} />
      </TableData>
    );
  }
}

export default withContext(observer(SortableTableHeader));
