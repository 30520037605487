import {
  white,
  noticeError,
  primary,
  slateGray,
  aliceBlue,
  secondary
} from "../constants";

const sectionContainer = {
  width: "33%",
  display: "inline-flex",
  overflowX: "scroll",
  flexWrap: "wrap",
  padding: "0px 0px 0px 26px",
  position: "relative",
  paddingTop: "46px"
};

const styles = {
  content: {
    margin: 0
  },
  container: {
    padding: "54px 0px 0px 11px",
    position: "relative"
  },
  sectionContainer,
  teamSectionContainer: {
    ...sectionContainer,
    width: "30%",
    paddingLeft: "26px"
  },
  teamAction: {
    position: "absolute",
    top: 0
  },
  titleContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginBottom: "20px",
    marginTop: "30px"
  },
  icon: {
    fontSize: "20px",
    color: "#1f2937",
    marginRight: "10px"
  },
  text: {
    display: "inline-block"
  },
  url: {
    display: "inline-block",
    color: primary,
    textDecoration: "none"
  },
  button: {
    color: secondary,
    background: "transparent",
    width: "20%",
    padding: "10px 0px",
    marginTop: "20px"
  },
  addButton: {
    color: white,
    background: primary,
    width: "200px",
    padding: "10px 0px",
    marginLeft: "20px",
    textTransform: "none",
    fontSize: "15px"
  },
  outlineButton: {
    color: `${primary} !important`,
    width: "100px",
    textTransform: "none",
    fontSize: "15px"
  },
  paddedButton: {
    margin: "5px 0",
    color: primary,
    boxShadow: 0,
    border: `1px solid ${primary}`
  },
  actionButton: {
    color: primary,
    border: `1px solid ${primary}`,
    width: "50%",
    padding: "5px 0px",
    fontSize: "15px",
    textTransform: "none",
    marginRight: "1em"
  },
  deleteButton: {
    color: "#ec707e",
    border: "1px solid #ec707e",
    width: "35%",
    padding: "5px 0px",
    fontSize: "15px",
    textTransform: "none"
  },
  loader: {
    zIndex: "10",
    opacity: "1",
    position: "absolute",
    width: "100%",
    left: "0",
    top: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#f7f9fc87"
  },
  inlineText: {
    label: {
      display: "flex",
      minWidth: "35%",
      marginRight: "10px",
      fontSize: "14px",
      fontWeight: "normal",
      color: primary,
      flex: 3,
      alignItems: "center"
    },
    url: {
      flex: 1,
      textAlign: "right",
      fontSize: "12px",
      fontWeight: "bold",
      textDecoration: "none",
      color: secondary
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "98%"
    },
    divider: {
      margin: "15px 0",
      height: "1px"
    }
  },
  secondText: {
    label: {
      display: "block",
      fontSize: "12px",
      color: "#808080"
    },
    value: {
      fontSize: "12px",
      fontWeight: "normal",
      color: primary,
      width: "100%"
    },
    container: {
      display: "flex",
      padding: "5px 0",
      flexWrap: "wrap"
    }
  },
  invoiceText: {
    notFound: {
      fontSize: "16px",
      marginBottom: "30px"
    }
  },
  workerSettingsSection: {
    title: {
      color: slateGray,
      margin: 0
    },
    icon: {
      color: slateGray
    },
    panel: {
      width: "100%",
      filter:
        "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
    },
    container: {
      width: "100%",
      padding: "0px",
      position: "relative"
    },
    divider: {
      marginBottom: "25px"
    },
    titleIcon: {
      marginRight: "10px"
    }
  },
  inputField: {
    container: {
      display: "block",
      padding: "5px 0px"
    },
    label: {
      display: "block",
      fontSize: "13px",
      color: primary,
      marginBottom: "10px",
      fontWeight: 500,
      textTransform: "capitalize"
    },
    error: {
      color: "red",
      fontSize: 14
    },
    textField: {
      height: "auto",
      padding: "5px 16px",
      backgroundColor: "#fff",
      borderColor: aliceBlue,
      color: primary,
      fontSize: "12px",
      fontWeight: "normal"
    },
    focused: {
      border: "1px solid #7364DB"
    }
  },
  closeButton: {
    container: {
      display: "flex",
      justifyContent: "flex-end"
    },
    button: {
      color: white,
      stroke: white,
      background: noticeError,
      borderRadius: "50%",
      height: "16px",
      width: "16px",
      padding: "1px"
    }
  },
  creditCard: {
    hidden: {
      display: "none"
    },
    visible: {
      display: "block"
    }
  },
  success: {
    padding: "1em",
    display: "inline-block",
    background: "#45a245",
    color: "#ffffff",
    margin: "1em",
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: 99
  },
  row: {
    display: "flex",
    flexDirection: "row",
    columnGap: "40px",
    marginBottom: "30px"
  },
  form: {
    padding: "0px 24px 10px",
    flex: 1
  },
  input: {
    fontWeight: "normal",
    marginTop: "7px"
  },
  footer: {
    margin: "0px",
    padding: "8px 24px 24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%"
  },
  cancelButton: {
    height: "40px",
    width: "168px",
    color: "#9ca3af",
    border: "1px solid #9ca3af",
    borderRadius: "4px",
    marginRight: "10px"
  },
  nextButton: {
    backgroundColor: secondary,
    width: "168px",
    height: "40px",
    color: "white",
    borderRadius: "4px"
  },
  tableHeader: {
    color: "#808080",
    fontWeight: "bold"
  },
  formLabel: {
    width: "20%",
    paddingTop: "13px"
  }
};

export default styles;
