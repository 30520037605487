import {
  ENROLLED_DARK_COLOR,
  ENROLLED_LIGHT_COLOR,
  UNENROLLED_DARK_COLOR,
  UNENROLLED_LIGHT_COLOR
} from "../constants";

export const STYLES_MAPPING = {
  enrolled: `
    color: ${ENROLLED_DARK_COLOR};
    background-color: ${ENROLLED_LIGHT_COLOR};
  `,
  unenrolled: `
    color: ${UNENROLLED_DARK_COLOR};
    background-color: ${UNENROLLED_LIGHT_COLOR};
  `
};
