import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { isEmpty, isArray } from "lodash";
import { parseViolation } from "../../../models/mvr";
import Header from "./Header";
import Record from "./Record";
import messages from "../messages";

export const getRecordTitle = violation => {
  if (
    !violation.hasOwnProperty("sub_violations") ||
    violation.sub_violations === undefined
  )
    return messages.noDescriptionAvailable.defaultMessage;

  const { descriptions = [] } = violation.sub_violations;
  const d = descriptions[0];

  if (!d) return violation.incident_date;
  return d.short_description || d.state_description || d.long_description;
};

export const exclude = [];

class MVRViolations extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    expand: PropTypes.bool.isRequired,
    icon: PropTypes.node
  };
  render() {
    const { data, expand, icon } = this.props; // readyStatus
    if (!isArray(data) || isEmpty(data)) return null;

    const parsed = data.map(parseViolation);
    const count = parsed.length;

    return (
      <div>
        <Header title="Violations" count={count} icon={icon} />
        {parsed.map((violation, key) => (
          <Record
            title={getRecordTitle(violation)}
            data={violation}
            expand={expand}
            key={key}
            exclude={exclude}
          />
        ))}
      </div>
    );
  }
}
export default MVRViolations;
