import React from "react";
import { PropTypes } from "prop-types";
import { rejectedCandidateStyles as styles } from "./styles";
import _ from "lodash";
import { SearchHighlighted } from "../../../components/SearchHighlighted";

const RejectedReasons = ({ reasons }) => {
  const { reasonsStyles } = styles;
  return _.isEmpty(reasons) ? null : (
    <div style={reasonsStyles.container}>
      <span style={reasonsStyles.title}>Rejection Reasons</span>
      {reasons.map((reason, index) => (
        <p id="rejectionReasonText" key={index} style={reasonsStyles.reasons}>
          <SearchHighlighted textToHighlight={_.startCase(reason)} />
        </p>
      ))}
    </div>
  );
};
RejectedReasons.propTypes = {
  reasons: PropTypes.array.isRequired,
  notes: PropTypes.array.isRequired
};

export default RejectedReasons;
