import React from "react";

export const FormRequired = ({ ...props }) => {
  const children = props.children || "*";
  const style = props.style || {};
  style.color = "red";

  return (
    <span style={style} {...props}>
      {children}
    </span>
  );
};
