import React from "react";
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";

const DropDownIcon = ({ className, ...props }) => {
  return (
    <Icon
      className={classNames(className, "fas fa-chevron-down")}
      style={{ top: "calc(50% - 4px)", fontSize: "12px", right: "20px" }}
      {...props}
    />
  );
};

export default DropDownIcon;
