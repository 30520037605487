/* eslint-disable no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { turnAPI, ga } from "../../services";
import CandidateReport from "../Candidate/Report";
import CandidateReportModel from "../../models/candidate";
import StatusErrors from "./status-errors";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { backgroundReportPDFStyles as styles } from "./styles";
import { PageType, PageEvent } from "../../services/analytics/gtm";

const BackgroundReport = observer(
  class BackgroundReport extends Component {
    static propTypes = {
      params: PropTypes.object,
      location: PropTypes.object
    };
    static contextTypes = { router: PropTypes.object };
    constructor(props) {
      super(props);
      this.state = { errorMessage: null, downloaded: null };
      this.token = null;
      this.model = new CandidateReportModel();
    }
    UNSAFE_componentWillMount() {
      const root = document.getElementById("root");
      root.style.width = "1600px";
      var urlParams = new URLSearchParams(this.props.location.search);
      this.token = urlParams.get("token");
    }
    async componentDidMount() {
      ga.send(PageType.BackgroundReportPDF, PageEvent.pageLoaded);

      if (this.token && this.token.length > 0) {
        await this.getBackgroundReport(this.token);
      }
      setTimeout(() => {
        document.getElementById("loading").remove();
        const input = document.getElementById("root");

        html2canvas(input, { scale: 1, windowWidth: 1200 }).then(canvas => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "px", [input.offsetHeight, 800]);
          pdf.addImage(imgData, "PNG", 0, 0);
          pdf.save("background-report.pdf");
        });
      }, 1000);
    }
    getBackgroundReport = async _token => {
      const token = _token;
      const response = await turnAPI.getBackgroundReport(
        this.props.params.email_id,
        token
      );
      switch (response.status) {
        case 200:
          this.handleResponse(response.data);
          break;
        case 401:
          this.model.handleUnauthorized(response.data);
          break;
        default:
          this.handleStatusErrors(response.status);
          break;
      }
    };
    handleResponse = data => {
      if (typeof data.background_report === "string")
        this.handleStatusErrors(410);
      this.model.setPartner(data.partner_name);
      this.model.setUser(data.user_id);
      this.model.setData(data);
      this.model.setUnauthorized(false);
      this.model.setSsnReview(data.ssn_review);
      this.model.setIsPendingMVRReview(data.mvr_review);
      this.model.setIsPendingAgeReview(data.age_review);
    };
    handleStatusErrors = status => {
      this.setState({ errorMessage: <StatusErrors status={status} /> });
    };
    submitAssent = async () => {
      // NOTE: this is not actually being used in the BGC flow
      // GOTO: app/containers/BGCLandingPage/index.js
      this.model.setLoading(true);
      const response = await turnAPI.assent(
        this.props.params.email_id,
        this.model.assentData
      );
      switch (response.status) {
        case 200:
          this.model.setConsent(true);
          this.gotoSuccessPage();
          break;
        case 203:
          this.model.setConsent(true);
          this.model.setIsPendingMVRReview(true);
          this.gotoSuccessMVRPage();
          break;
        default:
          this.handleStatusErrors(response.status);
          break;
      }
      this.model.setLoading(false);
    };
    gotoSuccessPage() {
      this.context.router.history.push("/candidate/success");
    }
    gotoSuccessMVRPage() {
      this.context.router.history.push("/candidate/successMVR");
    }
    render() {
      if (this.token)
        return (
          <div style={{ width: 1000 }}>
            <div id="loading" style={styles.loader}>
              <h1> We are generating your PDF...</h1>
            </div>
            <div id="myMm" style={{ height: "1mm" }} />
            <CandidateReport
              model={this.model}
              onSubmit={this.submitAssent}
              isPDFReport
            />
          </div>
        );
    }
  }
);

export default BackgroundReport;
