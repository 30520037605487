import React from "react";
import PropTypes from "prop-types";
import { emails } from "../../data/company";

const statusErrors = {
  401: <span>Error 401: This candidate link has expired.</span>,
  403: <span>Error 403: This candidate link has already been used.</span>,
  404: <span>Error 404: This is not a valid candidate link.</span>,
  500: (
    <span>
      Error 500: Internal Server Error.
      <br />
      No worries, service will resume shortly.
    </span>
  )
};

const StatusErrors = ({ status }) => {
  if (typeof status !== "number")
    return (
      <span>
        Error 520: Something went wrong. <br />
        If you continue to see this error please contact{" "}
        <a style={{ color: "white" }} href={`mailto:${emails.support}`}>
          {emails.support}
        </a>
      </span>
    );
  const error = statusErrors[status];
  if (!error)
    return (
      <span>
        Error {status}: Something went wrong. <br />
        If you continue to see this error please contact{" "}
        <a style={{ color: "white" }} href={`mailto:${emails.support}`}>
          {emails.support}
        </a>
      </span>
    );
  return error;
};

StatusErrors.propTypes = {
  status: PropTypes.number
};

export default StatusErrors;
