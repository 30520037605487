import React, { cloneElement } from "react";

import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";

/**
 * Renders the wrapper to center the form content. It should match
 * the width and positioning of StepperLayerNarrow in FormWizardStepper.js.
 */
const StepWrapper = styled.div`
  min-height: 100vh;
  width: 800px;
  margin-left: calc(50% - 400px);
  padding: 21px;

  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0;
  }
`;

/**
 * Wrapper for each Step in the Wizard. Children will be passed down the props
 * from `react-step-wizard`.
 *
 * @see https://github.com/jcmcneal/react-step-wizard#props-accessible-on-each-child-step-component
 */
export const FormWizardStep = ({ children, ...props }) => {
  const stepProps = _.pick(props, [
    "isActive",
    "currentStep",
    "totalSteps",
    "firstStep",
    "lastStep",
    "nextStep",
    "previousStep",
    "goToStep"
  ]);

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return cloneElement(child, stepProps);
    } else {
      return null;
    }
  });

  return <StepWrapper {...props}>{childrenWithProps}</StepWrapper>;
};

FormWizardStep.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  totalSteps: PropTypes.number,
  nextStep: PropTypes.func,
  previousStep: PropTypes,
  goToStep: PropTypes.func,
  firstStep: PropTypes.func,
  lastStep: PropTypes.func
};
