import _ from "lodash";

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

const opacityColor = (hex, opacity) => {
  const rgb = hexToRgb(hex);
  if (_.isEmpty(rgb) || _.isNil(rgb)) return "";
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
};

export default opacityColor;
