import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { List, ListItem } from "material-ui/List";
import _ from "lodash";
import {
  ListDetailStyles as styles,
  CandidateReportStyles as reportStyles
} from "../../../styles";
import {
  primary,
  orange,
  green,
  periwinkle,
  red
} from "../../../styles/constants";
import ListItemContainer from "../../../components/ListDetailView/list-item-container";
import ListContainer from "../../../components/ListDetailView/list-container";
import messages from "../messages";
import Divider from "material-ui/Divider";
import Record from "./Record";
import PendingReview from "../../../components/PendingReview";
import PendingMVRReview from "../../../components/PendingMVRReview";
import CanadaCommentsReview from "../../../components/CanadaCommentsReview";
import { COUNTY_CHECKS, PROCESSING, HIT, REVIEW } from "../../../constants";
import ProcessingMVR from "../../../components/ProcessingMVR";
import PanelCollapsible from "../../../components/PanelCollapsible";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner,
  faPauseCircle
} from "@fortawesome/pro-solid-svg-icons";
import withContext from "../../../contextAPI/context-HOC";
import PendingAgeReview from "../../../components/PendingAgeReview";

export class CriminalRecordsComponent extends Component {
  static propTypes = {
    worker: PropTypes.object,
    status: PropTypes.string,
    context: PropTypes.object,
    expandAll: PropTypes.bool
  };
  static defaultProps = {
    worker: {}
  };
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      width: 0
    };
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  setExpand = expand => {
    this.setState({ expand });
  };

  toggleExpand = () => {
    this.setState(prevProps => ({ expand: !prevProps.expand }));
  };

  getWindowSize = () => {
    let { width } = this.state;

    if (width <= 600) {
      return "small";
    }
    if (width <= 800) {
      return "medium";
    }
    if (width <= 1300) {
      return "large";
    }
  };

  renderListItem(item, index, expand) {
    return (
      <ListItemContainer data-test="list-detail-item" simple key={index}>
        {index !== 0 ? <Divider style={{ marginTop: "0px" }} /> : null}
        <ListItem disabled style={{ padding: 0 }}>
          <Record
            key={index}
            item={item}
            expand={expand}
            width={this.getWindowSize()}
            filteredValue={this.props.worker.filterCountyCriminalData(
              item.value
            )}
          />
        </ListItem>
      </ListItemContainer>
    );
  }

  get color() {
    if (this.props.status === REVIEW) return orange;
    if (this.props.status === PROCESSING) return periwinkle;
    if (this.props.status === HIT) return red;
    return green;
  }

  get icon() {
    if (this.props.status === REVIEW) return faPauseCircle;
    if (this.props.status === PROCESSING) return faSpinner;
    if (this.props.status === HIT) return faExclamationTriangle;
    return faCheckCircle;
  }

  get shouldRender() {
    const { worker } = this.props;
    const { countyCriminalData } = worker;

    if (
      (!countyCriminalData && !worker.isPendingReview) ||
      (!countyCriminalData && !worker)
    ) {
      return false;
    }
    if (worker.isPendingReview && !worker.shouldShowCheck(COUNTY_CHECKS))
      return false;
    if (worker.isPendingMVRReview && !worker.shouldShowCheck(COUNTY_CHECKS))
      return false;
    if (worker.isProcessingMVR && !worker.shouldShowCheck(COUNTY_CHECKS)) {
      return false;
    }

    return true;
  }

  clearStyle = {
    color: primary,
    fontWeight: "bold",
    display: "block",
    margin: "20px 5%"
  };

  renderContent = () => {
    const { expand } = this.state;
    const { worker } = this.props;

    if (worker.isProcessingMVR) {
      return <ProcessingMVR message={messages.criminalRecords} />;
    }
    if (worker.isPendingReview && !worker.canadianReport) {
      return <PendingReview message={messages.criminalRecords} />;
    }
    if (worker.isPendingMVRReview) {
      return <PendingMVRReview message={messages.criminalRecords} />;
    }
    if (worker.isPendingAgeReview) {
      return <PendingAgeReview message={messages.criminalRecords} />;
    }

    const comments =
      _.get(worker.canadianReport, "report_metadata.comments") || "";

    if (this.props.status == REVIEW && comments != "") {
      return <CanadaCommentsReview comments={comments} />;
    }

    const sortedCountyCriminalData = _.orderBy(
      this.props.worker.countyCriminalData,
      "county",
      "asc"
    );

    return (
      <ListContainer simple>
        <List style={styles.list}>
          {(sortedCountyCriminalData || []).map((item, index) =>
            this.renderListItem(item, index, expand)
          )}
        </List>
      </ListContainer>
    );
  };

  isCollapsible(worker) {
    return !(
      (worker.countyCriminalData || []).length === 0 &&
      this.props.status !== REVIEW
    );
  }

  render() {
    const { worker, context } = this.props;
    const { checks = {} } = context || {};

    if (!this.shouldRender) {
      return null;
    }

    return (
      <PanelCollapsible
        padding="10px 0"
        title={messages.criminalRecords}
        titleStyle={reportStyles.title}
        iconStyle={reportStyles.checkedIcon}
        color={this.color}
        icon={this.icon}
        collapsible={this.isCollapsible(worker)}
        expandAll={this.props.expandAll}
        expandAllProps={{
          toggled: this.state.expand,
          onClick: this.toggleExpand
        }}
        setExpand={this.setExpand}
        expandAllChecks={checks.expandAllChecks}
        panelProps={{
          style: {
            filter:
              "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
          }
        }}
      >
        {this.renderContent()}
      </PanelCollapsible>
    );
  }
}

export default withContext(observer(CriminalRecordsComponent));
