import { create } from "apisauce";

export const api = create({
  baseURL: "https://maps.googleapis.com/maps/api/geocode"
});

const key = `key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

export const geocode = {
  get: (lat, long) =>
    api
      .get(`json?${key}&latlng=${lat},${long}&sensor=true&language=EN`)
      .then(response => response),
  getZipcode: zipcode =>
    api
      .get(`json?${key}&language=en-US&region=us&address=${zipcode}`)
      .then(response => response)
};
