import { tartOrange, secondary } from "../constants";

const styles = {
  dialogContent: {
    padding: "0px 36px 0px 36px"
  },
  imgContainer: {
    width: "100%",
    height: "250px",
    margin: "0 auto"
  },
  imgDimensionsContainer: {
    maxWidth: "250px",
    maxHeight: "280px",
    margin: "0 auto"
  },
  headerImage: {
    width: "100%",
    height: "auto"
  },
  messages: {
    height: "227px",

    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  gralMessageContainer: {
    width: "339px",
    fontSize: "24px",
    fontWeight: "bold",

    flex: 33,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center"
  },
  copyConsentURLContainer: {
    width: "339px",
    overflowY: "auto",

    height: "70px",
    fontSize: "16px",

    flex: 34,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    bottom: 0
  },
  specificMessageContainer: {
    width: "339px",
    overflowY: "auto",

    fontSize: "16px",

    flex: 33,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "center",

    bottom: 0
  },
  buttonsContainerFlexEnd: {
    margin: 0,
    height: "90px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  closeWindowButton: {
    width: "96px",
    height: "25px",
    color: tartOrange,
    marginRight: "31px"
    // backgroundColor: "transparent"
  },
  agreeButton: {
    backgroundColor: secondary,
    width: "168px",
    height: "40px",
    color: "white"
  },
  copyCandidateConsentUrlButton: {
    width: "100%",

    fontSize: "16px",
    color: secondary,
    textDecoration: "underline",

    backgroundColor: "transparent"
  },
  copiedCandidateConsentUrlButton: {
    width: "100%",

    fontSize: "16px",
    color: secondary,

    backgroundColor: "transparent"
  }
};
export default styles;
