import styled from "styled-components";

const FooterContainer = styled.div`
  ${props => (props.isPDFReport ? "display: none;" : "")} position: fixed;
  background: ${props => (props.bgColor ? props.bgColor : "white")};
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: ${props => (props.padding ? props.padding : "0.5%")};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom : "3%"};
  box-shadow: 5px -1px 7px #d8d8d8;
  transform: ${props => (props.open ? "translateY(0)" : "translateY(60%)")};
  transition: transform 1s;
  @media print {
    display: none;
  }
`;

export default FooterContainer;
