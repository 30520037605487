import styled from "styled-components";
import { BREAKPOINT_XL, BREAKPOINT_MD } from "../../constants";

const SideBarLogo = styled.div`
  margin-top: 20px;
  height: fit;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${BREAKPOINT_MD}px) {
    justify-content: center;
  }
  @media (max-width: ${BREAKPOINT_XL}px) {
    text-align: center;
    padding-top: 2px;
  }
`;

export default SideBarLogo;
