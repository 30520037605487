import React from "react";
import _ from "lodash";
import { Observer } from "mobx-react/custom";
import env from "@mars/heroku-js-runtime-env";
import { turnAPI } from "../../services";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  FlatWorkerFilterDropdown,
  FlatWorkerFilterMenuItem
} from "../WorkerFilter/FlatWorkerFilterDropdown";
import StatusButton from "../StatusBadge/StatusButton";
import { BREAKPOINT_SM } from "../../constants";
import AddCandidatesModal from "../AddCandidatesModal";
import styled from "styled-components";
import Container from "../Container";
import { ReactComponent as DownloadIcon } from "../../icons/download-icon.svg";
import { primary } from "../../styles/constants";
import useInterval from "../../utils/useInterval";
import Worker from "../../models/worker";
import ModalLoader from "../../components/ModalLoader";
import teamMember from "../../models/team-member";

const HeaderContainer = styled(Container)`
  padding-left: 24px;
  color: ${primary};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${BREAKPOINT_SM}px) {
    flex-flow: column;

    h2 {
      font-size: medium;
      margin-right: 1rem;
    }
  }
`;

export function CandidateTilesHeader(
  isSelectingAll,
  handleSelectAll,
  selectedCandidates,
  dispatch,
  isCreating,
  auth,
  model,
  fetch,
  workers,
  setErrorOpen
) {
  const [delay, setDelay] = React.useState(null);
  const [tries, setTries] = React.useState(0);

  function reset() {
    fetch();
    setDelay(null);
    setTries(0);
  }

  const handleBulkAction = async ({ target: { value } }) => {
    if (value === "action") return;
    workers.setReady(false);
    await turnAPI.bulkDisposition({
      status: value,
      worker_ids: selectedCandidates.map(candidate => candidate.turnId)
    });
    setDelay(env().REACT_APP_INTERVAL_TIME || 2000);
  };

  useInterval(async () => {
    try {
      await workers.retrieveParams();
      const response = await turnAPI.getWorkers(workers.params);
      if (response.status === 200) {
        const { items, adverse_action } = response.data;
        const newCandidates = items.map(
          worker => new Worker(worker, adverse_action)
        );
        // find selectedCandidates that are in newCandidates
        const selectedCandidatesInNewCandidates = selectedCandidates.filter(
          selectedCandidate =>
            newCandidates.find(newCandidate => {
              return (
                newCandidate.id === selectedCandidate.id &&
                newCandidate.status === selectedCandidate.status
              );
            })
        );

        setTries(tries => tries + 1);

        const maxTries = env().REACT_APP_MAX_TRIES || 50;

        if (selectedCandidatesInNewCandidates.length === 0) {
          dispatch({ type: "CLEAR_SELECTED" });
          reset();
        } else if (tries >= maxTries) {
          reset();
          setErrorOpen(true);
        }
      } else if (response.status === 500) {
        setErrorOpen(true);
        reset();
      }
    } catch (e) {
      setErrorOpen(true);
      reset();
    }
  }, delay);

  if (delay) {
    return <ModalLoader isOpen={true} message={"Checking workers..."} />;
  }

  return (
    <HeaderContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2 style={{ color: primary, marginRight: "2rem" }}>
          Applicant Details
        </h2>

        <div style={{ opacity: !workers.shouldSelectallBeOn ? "0" : "100" }}>
          <Observer>
            {() => (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSelectingAll}
                      disabled={!workers.shouldSelectallBeOn}
                      onClick={handleSelectAll}
                    />
                  }
                  label="Select All"
                />
                <FlatWorkerFilterDropdown
                  disabled={_.isEmpty(selectedCandidates)}
                  value="action"
                  onChange={handleBulkAction}
                >
                  <FlatWorkerFilterMenuItem
                    value="action"
                    style={{ display: "none" }}
                  >
                    Action
                  </FlatWorkerFilterMenuItem>
                  <FlatWorkerFilterMenuItem value="approved">
                    Approve
                  </FlatWorkerFilterMenuItem>
                  <FlatWorkerFilterMenuItem value="reject">
                    Reject
                  </FlatWorkerFilterMenuItem>
                  <FlatWorkerFilterMenuItem value="withdraw">
                    Withdraw
                  </FlatWorkerFilterMenuItem>
                </FlatWorkerFilterDropdown>
              </>
            )}
          </Observer>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        {teamMember.canAddCandidates && (
          <StatusButton
            id="add-candidate"
            text="Add Candidate(s)"
            callback={() => {
              dispatch({
                type: "CREATING",
                payload: {
                  creating: true
                }
              });
            }}
          />
        )}

        {isCreating && (
          <AddCandidatesModal
            auth={auth}
            workers={model}
            fetch={fetch}
            autoOpen={true}
            handleClose={() => {
              dispatch({
                type: "CREATING",
                payload: {
                  creating: false
                }
              });
            }}
          />
        )}

        <StatusButton
          text="Download"
          callback={() => {
            dispatch({
              type: "SHOW_CSV_MODAL",
              payload: {
                showCSVModal: true
              }
            });
          }}
          Icon={DownloadIcon}
        />
      </div>
    </HeaderContainer>
  );
}
