import React from "react";
import { PropTypes } from "prop-types";
import Divider from "material-ui/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import CollapsibleListItem from "../../../components/ListDetailView/collapsible-list-item";
import DetailInline from "../../../components/ListDetailView/detail-inline";
import DetailContainer from "../../../components/ListDetailView/detail-container";

const Record = ({ expand, title, subtitle, key, exclude, data }) => (
  <div style={{ padding: "0 0 0 16%", position: "relative" }}>
    <div
      style={{
        position: "absolute",
        top: "15px",
        left: "30px"
      }}
    >
      <FontAwesomeIcon icon={faCircle} style={{ fontSize: "10px" }} />
    </div>
    <CollapsibleListItem
      light
      inline
      verbose
      divider
      expand={expand}
      title={title}
      subtitle={subtitle}
      key={key}
    >
      <Divider />
      <DetailContainer padding="0" marginBottom>
        <DetailInline deep data={data} exclude={exclude} />
      </DetailContainer>
    </CollapsibleListItem>
  </div>
);
Record.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.object.isRequired,
  expand: PropTypes.bool,
  exclude: PropTypes.array,
  key: PropTypes.number
};
Record.defaultProps = { key: 0 };

export default Record;
