import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ListItem } from "material-ui/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import Row from "../Row";
import Drawer from "../Drawer";
import Column from "../Column";
import _ from "lodash";
import IconContainer from "../IconContainer";
import DetailItem from "../DetailItem";
import { WorkerStyles as styles } from "../../styles";
import FormattedHeader from "../FormattedHeader";
import { renderDetailValue } from "turn-shared";
import Text from "../Text";

class ListItemCollapsible extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      expand: false
    };
  }
  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  componentDidUpdate() {
    if (this.props.expand != this.state.expand) {
      this.setState({
        isOpen: this.props.expand,
        expand: !this.state.expand
      });
    }
  }

  render() {
    if (!this.props.data || !this.props.data.details) return null;
    return (
      <Row>
        <Column width="100%" borderBottom={`1px solid #F5F5FA`}>
          <ListItem
            onClick={this.toggleOpen}
            style={{ zIndex: "auto", borderRadius: "10px" }}
            innerDivStyle={{ padding: "16px 10px" }}
            hoverColor="#F5F5FA"
          >
            <Row relative>
              <Column width="40%" widthSM="50%">
                {this.props.tooltip}
                <span data="test-list-item-title" style={styles.ssn.row.title}>
                  {this.props.title}
                </span>
              </Column>
              <IconContainer right="0" top="0">
                <Text size="12px" margin="0px 10px 0px 0px">
                  {_.upperFirst(_.startCase(this.props.data.status))}
                </Text>
                {this.state.isOpen ? (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    color="#000"
                    style={{ fontSize: "15px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color="#000"
                    style={{ fontSize: "15px" }}
                  />
                )}
              </IconContainer>
            </Row>
          </ListItem>

          <Drawer open={this.state.isOpen} style={styles.ssn.drawer}>
            {this.props.data.details.map(detail => (
              <DetailItem
                width="50%"
                key={detail.label}
                detail={{
                  label: (
                    <FormattedHeader
                      message={detail.label}
                      error={!this.props.isMatched}
                    />
                  ),
                  value: renderDetailValue(detail.value)
                }}
                isMatched={this.props.isMatched}
                containerStyle={styles.ssn.detail.container}
                descriptionStyle={styles.ssn.detail.description}
              />
            ))}
            {this.props.legend}
          </Drawer>
        </Column>
      </Row>
    );
  }
}

ListItemCollapsible.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  legend: PropTypes.element,
  isMatched: PropTypes.bool,
  expand: PropTypes.bool,
  tooltip: PropTypes.element
};

export default ListItemCollapsible;
