import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import styles from "./styles.js";
import InputContainer from "./InputContainer";
import { useSearchHighlighted } from "../../../components/SearchHighlighted/index.js";
import { withStyles } from "@material-ui/styles";

const WorkerFinder = () => {
  const { setSearchString } = useSearchHighlighted();

  return (
    <div style={styles.container}>
      <InputContainer>
        <TextField
          id="searchReportKeyword"
          autocomplete="nope"
          onChange={e => setSearchString(e.target.value)}
          InputProps={{
            classes: {
              focused: styles.focused,
              input: styles.input
            }
          }}
          placeholder="Search Report Keyword"
        />
      </InputContainer>
      <div style={styles.iconContainer}>
        <FontAwesomeIcon icon={faSearch} style={styles.icon} />
      </div>
    </div>
  );
};

WorkerFinder.propTypes = {
  classes: PropTypes.object,
  InputProps: PropTypes.object,
  error: PropTypes.bool,
  alt: PropTypes.bool,
  disabled: PropTypes.bool,
  orangeBorder: PropTypes.bool,
  customRootClass: PropTypes.string,
  customInputClass: PropTypes.string
};

export default withStyles(styles)(WorkerFinder);
