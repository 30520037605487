import getMuiTheme from "material-ui/styles/getMuiTheme";
import { createMuiTheme } from "@material-ui/core/styles";
import * as colors from "../styles/constants";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

/* From https://material-ui.com/es/guides/migration-v0x/, 
says can be used v0 and v1 at the same time until components are totally migrated to v1 */

export const materialThemeV0 = getMuiTheme();

export const materialThemeV1 = createMuiTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    error: { main: colors.noticeError },
    warning: { main: colors.noticeWarning },
    info: { main: colors.noticeInfo },
    success: { main: colors.noticeSuccess },
    text: { main: colors.primary },
    action: {
      disabledBackground: "orange"
    }
  },
  typography: {
    fontFamily: "inherit"
  },
  overrides: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiFormLabel: {
      root: {
        color: colors.primary,
        fontSize: "12px",
        fontWeight: "bold"
      }
    },
    MuiInput: {
      root: {
        height: "48px",
        borderRadius: "10px",
        border: `1px solid ${colors.periwinkle}`,
        paddingLeft: "16px",
        paddingRight: "16px",
        color: colors.primary
      },
      input: {
        fontSize: "14px",
        fontWeight: "400",
        padding: "6px 0"
      },
      focused: {
        border: `1px solid ${colors.secondary}`,
        color: colors.primary
      },
      error: {
        border: "1px solid #fa1f4b"
      },
      underline: {
        "&:before": {
          borderBottom: "none"
        },
        "&:after": {
          borderBottom: "none"
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "none"
        },
        "&:hover:not($disabled):not($focused):not($error):after": {
          borderBottom: "none"
        }
      }
    },
    MuiSelect: {
      root: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    },
    MuiButton: {
      text: {
        borderRadius: 20,
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "capitalize"
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(45, 48, 71, 0.61)"
      }
    },
    MuiListItem: {
      root: {
        padding: 0,
        margin: 0
      },
      button: {
        "&:focus": {
          background: "transparent"
        },
        "&:hover": {
          backgroundColor: colors.smokeWhite
        },
        "&:active": {
          backgroundColor: "transparent"
        }
      },
      gutters: {
        paddingLeft: 0,
        [breakpoints.up("sm")]: {
          paddingLeft: 0
        }
      }
    }
  }
});
