import { defineMessages } from "react-intl";

export default defineMessages({
  mainText: {
    id: "app.components.NoFeaturesYet.mainText",
    defaultMessage: "There’s nothing here yet"
  },
  secondaryText: {
    id: "app.components.NoFeaturesYet.secondaryText",
    defaultMessage: "We have some exciting updates coming your way soon"
  }
});
