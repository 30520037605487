import styled from "styled-components";
import { BREAKPOINT_MD } from "../../constants";

const SidebarContent = styled.div`
  @media (min-width: ${BREAKPOINT_MD}px) {
    position: absolute;
  }
  @media print {
    display: none;
  }
`;

export default SidebarContent;
