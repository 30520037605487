import React from "react";
import { PropTypes } from "prop-types";
import Divider from "material-ui/Divider";
import DetailContainer from "../../../components/ListDetailView/detail-container";
import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";
import { rejectedCandidateStyles as styles } from "./styles";

const Header = ({
  title,
  subtitle,
  expand,
  titleId = null,
  subTitleId = null
}) => (
  <>
    <DetailContainer style={styles.headerStyles}>
      <Title
        id={titleId}
        align="left"
        color="#ED7987"
        margin="10px 0px 5px"
        flex="100%"
        weight="500"
        small
      >
        {title}
      </Title>
      <SubTitle
        id={subTitleId}
        display="inline-block"
        textAlign="left"
        maxWidth="95%"
      >
        {subtitle}
      </SubTitle>
    </DetailContainer>
    {expand && <Divider style={styles.dividerStyles} />}
  </>
);
Header.propTypes = {
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  expand: PropTypes.bool,
  titleId: PropTypes.string,
  subTitleId: PropTypes.string
};

export default Header;
