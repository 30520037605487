import { primary } from "../../../styles/constants";

export const styles = {
  clearStyle: {
    color: primary,
    fontWeight: "bold",
    display: "block",
    margin: "20px 5%"
  },
  panelProps: {
    style: {
      filter:
        "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
    }
  }
};
