import styled from "styled-components";

const Form = styled.form`
  text-align: left;
  @media (max-width: 800px) {
    margin: 8% 0;
  }
`;

export default Form;
