import React, { useEffect } from "react";
import styled from "styled-components";
import { Observer } from "mobx-react/custom";
import moment from "moment";

import { RemountOnWindowResize } from "../../utils/useWindowDimensions";

import {
  ScreeningWidget,
  ScreeningWidgetWrapper,
  ScreeningWidgetLoader
} from "../../components/ScreeningWidget";
import {
  FlatWorkerFilterDropdown,
  FlatWorkerFilterMenuItem
} from "../../components/WorkerFilter/FlatWorkerFilterDropdown";
import { ScreeningResponsivePie } from "../../components/ScreeningCharts/DoughnutChart";
import { ScreeningResponsiveLine } from "../../components/ScreeningCharts/LineChart";

import { slateGray } from "../../styles/constants";
import { turnAPI } from "../../services";
import { DEFAULTS_SECTION } from "../../constants";

const ScreeningWidgetsWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
`;

const ScreeningWidgetRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 375px;
`;

const ScreeningTimeframeWidgetWrapper = styled(ScreeningWidgetWrapper)`
  @media (max-width: 1200px) {
    display: none !important;
  }
`;

const ScreeningResponsiveChartTitle = styled.span`
  font-size: 16px;
  color: ${slateGray};
  margin-bottom: 1em;
`;

const ScreeningPieChartWidgetWrapper = styled(ScreeningWidgetWrapper)`
  @media (max-width: 846px) {
    min-width: 100%;
    height: 400px;
  }
`;

function getDays(data) {
  if (data) {
    const daysArray = data.filter(({ title }) =>
      DEFAULTS_SECTION.includes(title)
    );
    if (daysArray.length > 0) {
      return daysArray[0].data;
    }
  }
}

export const ScreeningWidgetsHeader = ({
  widgetsModel,
  workersModel,
  ...props
}) => {
  useEffect(() => {
    const getReady = async () => {
      const response = await turnAPI.getSettings();
      const defaultDays = getDays(response.data);
      if (defaultDays) {
        widgetsModel.setFilterDays(defaultDays);
      }
      widgetsModel.applyFilters();
    };
    getReady();
  }, []);

  const onClickPie = ({ id: status }) => {
    workersModel.setStatus(status);
  };

  return (
    <Observer>
      {() => (
        <ScreeningWidgetsWrapper {...props}>
          <div style={{ flex: 5 }}>
            <ScreeningWidgetRow>
              <ScreeningWidget
                WrapperComponent={ScreeningTimeframeWidgetWrapper}
                title={"Timeframe"}
              >
                <FlatWorkerFilterDropdown
                  value={widgetsModel.filterDays}
                  onChange={({ target: { value } }) =>
                    widgetsModel.setFilterDays(value)
                  }
                >
                  <FlatWorkerFilterMenuItem value={7}>
                    7 days
                  </FlatWorkerFilterMenuItem>
                  <FlatWorkerFilterMenuItem value={15}>
                    15 days
                  </FlatWorkerFilterMenuItem>
                  <FlatWorkerFilterMenuItem value={30}>
                    30 days
                  </FlatWorkerFilterMenuItem>
                  <FlatWorkerFilterMenuItem value={60}>
                    60 days
                  </FlatWorkerFilterMenuItem>
                  <FlatWorkerFilterMenuItem value={90}>
                    90 days
                  </FlatWorkerFilterMenuItem>
                  <FlatWorkerFilterMenuItem value={120}>
                    120 days
                  </FlatWorkerFilterMenuItem>
                </FlatWorkerFilterDropdown>
              </ScreeningWidget>
              <ScreeningWidget
                title={"Average Time"}
                tooltip="Reflects the speed, on average and within the chosen timeframe, from when you approve the start of a background check to when you take an action on the check."
              >
                <ScreeningWidgetLoader isLoading={widgetsModel.loading}>
                  {moment
                    .duration(widgetsModel.averageTime, "seconds")
                    .humanize()}
                </ScreeningWidgetLoader>
              </ScreeningWidget>
              <ScreeningWidget
                title={"Average Cost"}
                tooltip="Displays, within the chosen timeframe, the average cost of a background check in approved status."
              >
                <ScreeningWidgetLoader isLoading={widgetsModel.loading}>
                  $
                  {widgetsModel.averageCost &&
                    widgetsModel.averageCost.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                </ScreeningWidgetLoader>
              </ScreeningWidget>
              <ScreeningWidget
                title={"Disputed"}
                tooltip="This is the number of candidates within the chosen timeframe who have disputed one or more findings on their background check. Turn’s compliance team is actively looking into the claims.
"
              >
                <ScreeningWidgetLoader isLoading={widgetsModel.loading}>
                  {widgetsModel.underInvestigation &&
                    widgetsModel.underInvestigation.toLocaleString()}
                </ScreeningWidgetLoader>
              </ScreeningWidget>
              <ScreeningWidget
                title={"Action Needed"}
                tooltip="These are candidates, within the chosen timeframe, who need a decision from you."
              >
                <ScreeningWidgetLoader isLoading={widgetsModel.loading}>
                  {widgetsModel.awaitingAction &&
                    widgetsModel.awaitingAction.toLocaleString()}
                </ScreeningWidgetLoader>
              </ScreeningWidget>
            </ScreeningWidgetRow>
            <ScreeningWidgetRow>
              <ScreeningWidget tooltip="Refreshed nightly, this tracks, within the chosen timeframe, how much you’ve spent compared to the number of background checks completed. This is an estimate and will not accurately reflect the invoice amount.">
                <ScreeningResponsiveChartTitle style={{ textAlign: "left" }}>
                  Amount Spent &amp; Number of Completed Checks
                </ScreeningResponsiveChartTitle>
                <div style={{ height: 200 }}>
                  <ScreeningWidgetLoader isLoading={widgetsModel.loading}>
                    <RemountOnWindowResize onHeight={false}>
                      <ScreeningResponsiveLine
                        data={widgetsModel.considerCostChart}
                      />
                    </RemountOnWindowResize>
                  </ScreeningWidgetLoader>
                </div>
              </ScreeningWidget>
            </ScreeningWidgetRow>
          </div>
          <ScreeningWidget
            WrapperComponent={ScreeningPieChartWidgetWrapper}
            style={{ flex: 2 }}
            tooltip="These are the number of approved, withdrawn, processing, consider, and total background checks that have completed or you have taken action on within the timeframe selected. Click on the chart or legend to filter the candidates below by a particular status."
            title={
              <ScreeningResponsiveChartTitle>
                Status
              </ScreeningResponsiveChartTitle>
            }
          >
            <ScreeningWidgetLoader isLoading={widgetsModel.loading}>
              {widgetsModel.statusChart && (
                <RemountOnWindowResize>
                  <ScreeningResponsivePie
                    colors={datum => datum.color}
                    data={widgetsModel.statusChart}
                    onClick={onClickPie}
                  />
                </RemountOnWindowResize>
              )}
            </ScreeningWidgetLoader>
          </ScreeningWidget>
        </ScreeningWidgetsWrapper>
      )}
    </Observer>
  );
};
