import styled from "styled-components";
import { TOOLTIP_POSITION } from "./constants";

export const ActionWrapper = styled.div`
  position: relative;

  &:hover {
    #tooltip_mvr,
    #tooltip_crim,
    .tooltip-menu-item {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const Tooltip = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;

  max-width: 350px;
  width: 185px;
  padding: 8px 12px;

  font-size: 12px;
  text-align: start;
  color: white;
  background-color: black;
  line-height: 1.375;
  font-weight: 400;
  white-space: pre-wrap;

  border-radius: 4px;
  z-index: 50;

  transition: all 300ms ease-in-out;

  ${props => TOOLTIP_POSITION[props.position]}
`;
