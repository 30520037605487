import { defineMessages } from "react-intl";

export default defineMessages({
  didntRequest: {
    id: "app.components.ValidationPage.didntRequest",
    defaultMessage: "Didn't request a Background Report?"
  },
  letUsKnow: {
    id: "app.components.ValidationPage.letUsKnow",
    defaultMessage: "Let us know at "
  },
  email: {
    id: "app.components.ValidationPage.email",
    defaultMessage: "support@turn.ai"
  }
});
