const syslog = async messages => {
  messages.map(async message => {
    if (typeof message == "object") {
      message = JSON.stringify(message);
    }
    await fetch(`/log?event=${message}`);
  });
};

export default syslog;
