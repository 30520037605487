import env from "@mars/heroku-js-runtime-env";
import BaseAPI, { apiaryURL, twilioURL } from "./api/base-api";
import { FreshdeskApi } from "./api/freshdesk-api";
import TagManager from "react-gtm-module";
import { GTMPageEvent } from "./analytics/gtm";

export const turnAPI = new BaseAPI();
export const apiaryAPI = new BaseAPI(apiaryURL);
export const phoneAPI = new BaseAPI(twilioURL);

export const freshdeskAPI = new FreshdeskApi();

export const ga = GTMPageEvent.init(
  TagManager,
  env().REACT_APP_GOOGLE_ANALYTICS_ID
);
