import styled from "styled-components";

const Title = styled.h2`
  text-align: ${props => props.align || "center"};
  color: ${props => props.color};
  font-size: ${props => {
    if (props.size) return props.size;
    if (props.huge) return "48px";
    if (props.big) return "32px";
    return props.small ? "20px" : "32px";
  }};
  font-weight: ${props => props.weight || "bold" || "inherit"}
  font-family: ${props => props.family || "inherit"};
  margin: ${props => (props.margin ? props.margin : "20px 0")};
  width: ${props =>
    props.width ? props.width : props.ellipsis ? "100%" : "auto"};
  overflow: ${props => (props.ellipsis ? "hidden" : "visible")};
  text-overflow: ${props => (props.ellipsis ? "ellipsis" : "unset")};
  display: ${props => (props.inline ? "inline-block" : "inherit")};
  flex: ${props => (props.flex ? props.flex : "")};
  @media (max-width: 600px) {
    font-size: ${props => {
      if (props.big) return "24px";
      return props.small ? "20px" : "24px";
    }};
  }
`;

export default Title;
