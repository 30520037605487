import { observable, action, computed, decorate } from "mobx";
import _ from "lodash";

class CustomField {
  name = "";
  value = "";
  touched = false;
  setErrorState = undefined;

  get invalid() {
    return this.touched && this.value.trim().length === 0;
  }

  validate = () => {
    const { value } = this;
    if (!value && value !== "" && !_.isString(value)) return;
    const str = value.trim();
    const error = str.length === 0 ? "required" : undefined;
    if (this.setErrorState) this.setErrorState(this.name, error);
    return error;
  };

  touch = () => {
    this.touched = true;
    this.validate();
  };

  set = value => {
    if (!_.isString(value)) return;
    this.value = value;
  };

  constructor(customField, setErrorState) {
    const { input_name, input_label } = customField;
    this.name = _.camelCase(input_name);
    this.label = input_label;
    this.setErrorState = setErrorState;
  }
}

decorate(CustomField, {
  name: observable,
  value: observable,
  touched: observable,
  invalid: computed,
  validate: action,
  touch: action,
  set: action
});

export default CustomField;
