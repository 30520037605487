/* eslint-disable no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, Provider } from "mobx-react";
import queryString from "query-string";
import { turnAPI } from "../../services";
import { database } from "../../index";
import LandingPageCanadaModel from "../../models/landing-page-canada";
import EditCandidateDetails from "../../components/LandingPage/edit-candidate-details";
import CandidateReportModel from "../../models/candidate";
import { TurnLoader } from "../../components/Loader/TurnLoader";
import { formatEmail } from "turn-shared";

import _ from "lodash";

class BGCCanadaLandingPage extends Component {
  static propTypes = {
    params: PropTypes.object,
    router: PropTypes.object,
    fields: PropTypes.array,
    disclosures: PropTypes.array,
    oneOffChecks: PropTypes.array,
    checkId: PropTypes.string,
    partner: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
      validEmail: true,
      errorMessage: "",
      timeout: false,
      isAsync: false,
      consentEnd: false,
      isOnline: true,
      certnResponse: { hasResponse: false, error: null },
      step: 1
    };
    this.model = new LandingPageCanadaModel(database);
    this.report = new CandidateReportModel();

    if (props.partner) {
      this.model.setPartnerName(props.partner);
    }
  }
  componentDidMount() {
    this.init();
  }
  UNSAFE_componentWillMount() {}
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOnline === true && prevState.isOnline === false) {
      setTimeout(() => {
        //window.location.reload();
      }, 500);
    }
  }

  init = async () => {
    const { model } = this;
    const requiredFields = this.props.fields.map(f => {
      if (f.field == "no_middle_name") return "has_middle_name";
      return _.snakeCase(f.field);
    });
    const query = queryString.parse(this.props.router.location.search);

    model.setRequiredFields(requiredFields);
    model.setOneOffChecks(this.props.oneOffChecks);
    model.setPartnerName(this.props.params.partner_name);
    this.model.setBypass(query.bypass_dupe === "true");
    model.getLegalData();
    model.setFormData(query, false, model.requiredFields);
    model.setEmail(formatEmail(query.email));
  };

  validateForm = async () => {
    const { model } = this;
    model.setLoading(true);
    window.scrollTo(0, 0);

    const response = await turnAPI.checkCanadaDuplicates(this.props.checkId, {
      ...this.model.formData
    });

    if (response.status == 401) {
      this.model.setError(response.data.message.message);
      model.setLoading(false);
      return false;
    }

    await model.setSummaryOfRights();

    model.setLoading(false);
    model.clearError();

    return true;
  };

  handleProvideCandidateConsent = async () => {
    this.model.setLoading(true);
    const response = await turnAPI.applyCanadaSubmit(this.props.checkId, {
      email_id: this.props.params.email_id,
      ...this.model.data
    });
    this.model.setLoading(false);
    if (response.status == 200) {
      this.setState({
        certnResponse: { hasResponse: true, error: false }
      });
    } else {
      this.setState({
        certnResponse: { hasResponse: true, error: true }
      });
    }
  };

  renderLandingPage(loading) {
    return (
      <div>
        <TurnLoader hidden={!loading} />
        <Provider store={this.model}>
          <EditCandidateDetails
            isCanadaCheck={true}
            disclosure={this.model.disclosure}
            rights={this.model.rights}
            terms={this.model.terms}
            stateRights={this.model.stateRights}
            authorization={this.model.authorization}
            details={this.model}
            handleSubmit={this.handleProvideCandidateConsent}
            validateForm={this.validateForm}
            emailId={this.email_id}
            certnResponse={this.state.certnResponse}
            step={this.state.step}
            turn_id={this.model.turn_id}
            checkId={this.props.checkId}
          />
        </Provider>
      </div>
    );
  }

  render() {
    return this.renderLandingPage(this.model.loading);
  }
}

export default observer(BGCCanadaLandingPage);
