import React from "react";
import { ImageContainer, Logo, Wrapper } from "./styled";

const LogoLoader = () => {
  return (
    <Wrapper>
      <ImageContainer>
        <Logo />
      </ImageContainer>
    </Wrapper>
  );
};

export default LogoLoader;
