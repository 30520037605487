export const demoData1 = {
  average_cost: 21.77155683020385,
  average_time: 90637.612382186,
  awaiting_action: 10,
  data: [
    {
      date: "2022-03-02",
      spend: 1436.89,
      total_approved: 86,
      total_checks: 66,
      total_consider: 4
    },
    {
      date: "2022-03-01",
      spend: 1611,
      total_approved: 128,
      total_checks: 74,
      total_consider: 5
    },
    {
      date: "2022-02-28",
      spend: 1632.83,
      total_approved: 85,
      total_checks: 75,
      total_consider: 7
    },
    {
      date: "2022-02-27",
      spend: 1175.64,
      total_approved: 27,
      total_checks: 54,
      total_consider: 0
    },
    {
      date: "2022-02-26",
      spend: 1088.55,
      total_approved: 26,
      total_checks: 50,
      total_consider: 0
    },
    {
      date: "2022-02-25",
      spend: 1349.81,
      total_approved: 71,
      total_checks: 62,
      total_consider: 5
    },
    {
      date: "2022-02-24",
      spend: 1480.43,
      total_approved: 79,
      total_checks: 68,
      total_consider: 8
    },
    {
      date: "2022-02-23",
      spend: 2068.26,
      total_approved: 112,
      total_checks: 95,
      total_consider: 8
    },
    {
      date: "2022-02-22",
      spend: 1915.86,
      total_approved: 176,
      total_checks: 88,
      total_consider: 12
    },
    {
      date: "2022-02-21",
      spend: 1850.54,
      total_approved: 94,
      total_checks: 85,
      total_consider: 12
    },
    {
      date: "2022-02-20",
      spend: 1328,
      total_approved: 36,
      total_checks: 61,
      total_consider: 0
    },
    {
      date: "2022-02-19",
      spend: 1393.35,
      total_approved: 27,
      total_checks: 64,
      total_consider: 1
    },
    {
      date: "2022-02-18",
      spend: 1611.06,
      total_approved: 79,
      total_checks: 74,
      total_consider: 9
    },
    {
      date: "2022-02-17",
      spend: 1894.1,
      total_approved: 78,
      total_checks: 87,
      total_consider: 25
    },
    {
      date: "2022-02-16",
      spend: 2090.03,
      total_approved: 95,
      total_checks: 96,
      total_consider: 9
    },
    {
      date: "2022-02-15",
      spend: 2242.42,
      total_approved: 104,
      total_checks: 103,
      total_consider: 13
    },
    {
      date: "2022-02-14",
      spend: 1785.23,
      total_approved: 107,
      total_checks: 82,
      total_consider: 9
    },
    {
      date: "2022-02-13",
      spend: 1676.37,
      total_approved: 37,
      total_checks: 77,
      total_consider: 0
    },
    {
      date: "2022-02-12",
      spend: 1828.77,
      total_approved: 32,
      total_checks: 84,
      total_consider: 0
    },
    {
      date: "2022-02-11",
      spend: 2242.42,
      total_approved: 103,
      total_checks: 103,
      total_consider: 13
    },
    {
      date: "2022-02-10",
      spend: 2198.88,
      total_approved: 99,
      total_checks: 101,
      total_consider: 10
    },
    {
      date: "2022-02-09",
      spend: 2307.74,
      total_approved: 157,
      total_checks: 106,
      total_consider: 5
    },
    {
      date: "2022-02-08",
      spend: 2612.54,
      total_approved: 112,
      total_checks: 120,
      total_consider: 7
    },
    {
      date: "2022-02-07",
      spend: 2090.03,
      total_approved: 88,
      total_checks: 96,
      total_consider: 5
    },
    {
      date: "2022-02-06",
      spend: 1698.15,
      total_approved: 29,
      total_checks: 78,
      total_consider: 0
    },
    {
      date: "2022-02-05",
      spend: 1632.83,
      total_approved: 34,
      total_checks: 75,
      total_consider: 1
    },
    {
      date: "2022-02-04",
      spend: 2002.94,
      total_approved: 82,
      total_checks: 92,
      total_consider: 1
    },
    {
      date: "2022-02-03",
      spend: 1915.86,
      total_approved: 78,
      total_checks: 88,
      total_consider: 7
    },
    {
      date: "2022-02-02",
      spend: 2133.5732,
      total_approved: 83,
      total_checks: 98,
      total_consider: 13
    },
    {
      date: "2022-02-01",
      spend: 2002.94,
      total_approved: 128,
      total_checks: 92,
      total_consider: 14
    }
  ],
  status: [
    {
      count: 2472,
      profile_status: "approved"
    },
    {
      count: 85,
      profile_status: "pending"
    },
    {
      count: 398,
      profile_status: "processing"
    },
    {
      count: 296,
      profile_status: "rejected"
    },
    {
      count: 5,
      profile_status: "withdrawn"
    }
  ],
  under_investigation: 1
};
