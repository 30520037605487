import { CarInsuranceField, InformationForm } from "./candidate-fields";
import React, { Component } from "react";

import Column from "../Column";
import DisclosureContainer from "./disclosure-container";
import Form from "../Form";
import { FormCheckLine } from "./components/FormCheckLine";
import { FormWizardBackForward } from "./components/FormWizardBackNext";
import { FormWizardStep } from "./components/FormWizardStep";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import messages from "./messages";
import { observer, inject } from "mobx-react";
import { LandingPageStyles as styles } from "../../styles";
import syslog from "../../services/syslog";
import CardFilling from "../CardFilling";
import Row from "../Row";
import CardManualFilling from "../CardFilling/container-manual";
import TabAutomaticFill from "./tab-automaticfill";
import env from "@mars/heroku-js-runtime-env";
import BackButton from "./components/BackButton";
import Captcha from "./components/Captcha";
import { FormRequired } from "./components/FormRequired";
import { autorun } from "mobx";
import WelcomeMessage from "./components/WelcomeMessage";

const FIRST_AND_LAST_NAME_MIN_LENGTH =
  process.env.REACT_APP_FIRST_LAST_NAME_MIN_LENGTH || 2;
const MIDDLE_NAME_MIN_LENGTH =
  process.env.REACT_APP_MIDDLE_NAME_MIN_LENGTH || 1;

class TabInformation extends Component {
  static propTypes = {
    ...FormWizardStep.propTypes,
    details: PropTypes.object,
    validateForm: PropTypes.func,
    isCanadaCheck: PropTypes.bool,
    hasClickedContinue: PropTypes.func,
    turn_id: PropTypes.string,
    checkId: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.scrollingTopRef = React.createRef();
    this.state = {
      disableEmail: props.details.disableEmail,
      showWelcomeMessage: true,
      showFillingButtons:
        !props.store.showManualForm && !props.store.showAutomaticFill
    };
  }

  componentDidMount() {
    const member = localStorage.getItem("member") || null;
    const partnerUUID = member ? JSON.parse(member) : undefined;

    if (
      !this.props.isCanadaCheck &&
      partnerUUID &&
      partnerUUID.partner_id === process.env.REACT_APP_INVESTOR_DEMO_ENV
    ) {
      this.props.details.setInvestorDemoEnv(true);
    }
  }

  componentDidUpdate() {
    const { details: d } = this.props;
    if (this.props.isActive) {
      autorun(() => {
        if (d.scrollCurrentTabTo === "top") {
          this.scrollToElement();
          d.setScrollCurrentTabTo("");
        }
      });
    }
  }

  handleNext = async () => {
    if (this.props.details.isInvestorDemoEnv) {
      this.props.handleSubmit();
    } else {
      const valid = await this.props.validateForm();
      if (valid) {
        this.props.nextStep();
        this.props.hasClickedContinue();
        const detailsData = { ...this.props.details.formData };
        detailsData["has_middle_name"] = !detailsData["has_middle_name"];
        detailsData["tab"] = "step_1_information";
        syslog([detailsData]);
      }
    }
  };

  scrollToElement = () => this.scrollingTopRef.current.scrollIntoView();

  getMiddleName = name => {
    const middle = name.split(" ");
    return middle.length > 1 ? middle[1] : "";
  };

  getFirstName = name => {
    const firstName = name.split(" ");
    return firstName.length > 1 ? firstName[0] : name;
  };

  isAllowedName = name => {
    const DISALLOWED_NAMES = env().REACT_APP_DISALLOWED_NAMES || [];
    if (name && name != null) {
      return !JSON.parse(DISALLOWED_NAMES).includes(name.toLowerCase().trim());
    }
    return true;
  };

  base64ValidFields = ({
    givenName,
    familyName,
    sex,
    dateOfBirth,
    stateCode,
    licenseNumber
  }) => {
    let data = {};
    data.givenName = givenName || "";
    data.familyName = familyName || "";
    data.sex = sex || "";
    data.dateOfBirth = dateOfBirth || "";
    data.stateCode = stateCode || "";
    data.licenseNumber = licenseNumber || "";

    return {
      ...data
    };
  };

  fillModel = data => {
    // strict necesary to validate any attributes coming from Base64. Sometimes they are and sometimes don't
    if (
      Object.keys(data).includes("Front") &&
      Object.keys(data.Front).includes("fields")
    ) {
      const {
        givenName,
        familyName,
        sex,
        dateOfBirth,
        stateCode,
        licenseNumber
      } = this.base64ValidFields(data.Front.fields);

      const cleanString = str => {
        try {
          return str.replace(/[|&;$%@"<>()+,]/g, "");
        } catch (ex) {
          return "";
        }
      };

      try {
        if (givenName && givenName.value) {
          let allowedGivenName = givenName.value;
          let splitName = givenName.value.split(" ");
          for (let index in splitName) {
            if (!this.isAllowedName(splitName[index])) {
              allowedGivenName = "";
            }
          }
          this.props.details.setFirstName(
            this.getFirstName(cleanString(allowedGivenName.trim() || ""))
          );
        }
      } catch (ex) {
        this.props.details.setFirstName("");
      }

      let lastName;
      try {
        lastName =
          familyName !== null &&
          this.isAllowedName(familyName.value) &&
          familyName.value !== ""
            ? familyName.value
            : "";
      } catch (ex) {
        lastName = "";
      }

      try {
        if (lastName !== "") {
          this.props.details.setLastName(cleanString(familyName.value));
        }
      } catch (ex) {
        this.props.details.setLastName("");
      }

      if (lastName === "") {
        try {
          this.props.details.setLastName(
            this.getMiddleName(cleanString(givenName.value || ""))
          );
        } catch (ex) {
          this.props.details.setLastName("");
        }
      } else {
        let middleName;
        try {
          middleName = this.getMiddleName(
            cleanString(givenName.value.trim()) || ""
          );
        } catch {
          middleName = "";
        }

        if (middleName === "") {
          this.props.details.setHasMiddleName(true);
        } else {
          try {
            if (this.isAllowedName(middleName) && middleName) {
              this.props.details.setMiddleName(cleanString(middleName.trim()));
            }
          } catch (ex) {
            this.props.details.setMiddleName("");
          }
        }
      }

      if (sex) {
        this.props.details.setGender(sex.value);
      }

      if (dateOfBirth) {
        const splitDate = dateOfBirth.value.split("-") || [];
        if (splitDate.length > 0) {
          this.props.details.setYearOfBirth(splitDate[0]);
          this.props.details.setMonthOfBirth(splitDate[1]);
          this.props.details.setDayOfBirth(splitDate[2]);
        }
      }

      if (stateCode) {
        this.props.details.setDriversLicenseState(stateCode.value);
      }
      if (licenseNumber) {
        this.props.details.setDriversLicenseNumber(licenseNumber.value);
      }
    }

    if (
      Object.keys(data).includes("Back") &&
      Object.keys(data.Back).includes("fields")
    ) {
      if (Object.keys(data.Back.fields).includes("zipCode")) {
        const { zipCode } = data.Back.fields;
        if (zipCode) {
          this.props.details.setZipcode(zipCode.value);
        }
      }
    }

    this.showManualFill();
  };

  renderForm = () => {
    const { details: d, isCanadaCheck, isActive } = this.props;

    const checkFirstNameOnNext =
      d.firstName.length < FIRST_AND_LAST_NAME_MIN_LENGTH
        ? !!d.firstName
        : false;
    const checkLastNameOnNext =
      d.lastName.length < FIRST_AND_LAST_NAME_MIN_LENGTH ? !!d.lastName : false;
    const checkMiddleNameOnNext =
      d.middleName.length < MIDDLE_NAME_MIN_LENGTH ? !!d.middleName : false;

    const checkCaptchaOnNext =
      process.env.REACT_APP_RECAPTCHA_ACTIVE === "true"
        ? !d.captchaToken
        : null;
    return (
      this.props.store.showManualForm && (
        <>
          <p
            style={{
              ...styles.textBig,
              marginBottom: "28px",
              color: "#7E7E8F"
            }}
          >
            <FormattedMessage {...messages.confirmText} />
          </p>
          <p style={{ ...styles.textBig, color: "#7E7E8F" }}>
            <FormattedMessage {...messages.thankYou} />
          </p>
          <Form>
            <InformationForm model={d} isCanadaCheck={isCanadaCheck} />
          </Form>
          <DisclosureContainer question>
            <CarInsuranceField model={d} />
          </DisclosureContainer>
          <FormCheckLine
            id="informationTabCheckbox"
            label={
              <>
                <FormattedMessage {...messages.certify} />
                <FormRequired />
              </>
            }
            checked={d.certify}
            onChange={() => d.setCertify(!d.certify)}
          />
          <FormCheckLine
            id="informationTabTosCheckbox"
            label={
              <>
                I have read and agree to Turn’s{" "}
                <a
                  href="https://turn.ai/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#7364DB" }}
                >
                  Terms of Use
                </a>
                , including the Arbitration Agreement, and Turn’s{" "}
                <a
                  href="https://turn.ai/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#7364DB" }}
                >
                  Privacy Policy
                </a>
                . <FormRequired />
              </>
            }
            checked={d.certifyTos}
            onChange={() => d.setCertifyTos(!d.certifyTos)}
            style={{ marginTop: 15, marginBottom: 15 }}
          />
          <FormCheckLine
            id="informationTabSmsCheckbox"
            label={<FormattedMessage {...messages.certifySms} />}
            checked={d.certifySms}
            onChange={() => d.setCertifySms(!d.certifySms)}
            style={{ marginTop: 5 }}
          />
          <Column
            textAlign="right"
            textAlignSM="center"
            width="100%"
            margin="20px 0 0 0"
          >
            <FormRequired>* Required</FormRequired>
          </Column>

          <Column
            textAlign="right"
            textAlignSM="center"
            width="100%"
            margin="20px 0 0 0"
          >
            {!!isActive && <Captcha model={d} />}
            <FormWizardBackForward
              id="informationTabBackForwardDiv"
              nextDisabled={
                !d.certify ||
                !d.certifyTos ||
                !d.informationReady ||
                checkCaptchaOnNext ||
                checkFirstNameOnNext ||
                checkLastNameOnNext ||
                checkMiddleNameOnNext
              }
              onNext={this.handleNext}
            />
          </Column>
        </>
      )
    );
  };

  hideButtons = () => {
    this.setState({ showFillingButtons: false });
    this.props.store.setShowManualForm(true);
  };

  showAutomaticForm = () => {
    this.setState({ showWelcomeMessage: true });
    this.setState({ showFillingButtons: false });
    this.props.store.setShowManualForm(false);
    this.props.store.setShowAutomaticFill(true);
  };

  showManualFill = () => {
    this.setState({ showWelcomeMessage: false });
    this.setState({ showFillingButtons: false });
    this.props.store.setShowManualForm(true);
    this.props.store.setShowAutomaticFill(false);
  };

  showDefaultState = () => {
    this.setState({ showWelcomeMessage: true });
    this.setState({ showFillingButtons: true });
    this.props.store.setShowManualForm(false);
    this.props.store.setShowAutomaticFill(false);
  };

  renderBackButton = () => {
    return (
      <BackButton
        show={this.state.showWelcomeMessage && this.state.showFillingButtons}
        linkBack={this.showDefaultState}
      />
    );
  };

  render() {
    const { details: d, isCanadaCheck } = this.props;
    const pickMessages = type => {
      let chosenMessages = {
        chooseHow: messages.chooseHowToText,
        cardFilling: messages.cardAutomaticFilling
      };
      if (d.doMVR) {
        chosenMessages = {
          chooseHow: messages.chooseHowToTextMVR,
          cardFilling: messages.cardMVRFilling
        };
      } else if (isCanadaCheck) {
        chosenMessages = {
          chooseHow: messages.chooseHowToTextCanada,
          cardFilling: messages.cardCanadaFilling
        };
      }

      return chosenMessages[type];
    };

    const cardFillingMessage = pickMessages("cardFilling");
    const chooseHowMessage = pickMessages("chooseHow");

    return (
      <div
        style={{
          ...styles.tabContent
        }}
        ref={this.scrollingTopRef}
      >
        {!d.piiRetryPage ? this.renderBackButton() : null}
        {this.state.showWelcomeMessage && (
          <WelcomeMessage isCanadaCheck={isCanadaCheck} details={d} />
        )}
        {this.props.store.showManualForm && this.renderForm()}
        {this.state.showFillingButtons && !d.piiRetryPage && (
          <>
            <div style={{ marginBottom: "32px", color: "#7E7E8F" }}>
              <strong>
                <FormattedMessage {...messages.moreAboutText} />
              </strong>{" "}
              <strong>
                <FormattedMessage {...chooseHowMessage} />
              </strong>
            </div>
            <div id="howToFillOptions">
              <Row>
                <Column width="100%">
                  <CardFilling
                    id="automatic-fill"
                    {...cardFillingMessage}
                    image="automatic"
                    link={this.showAutomaticForm}
                    doMVR={d.doMVR}
                  ></CardFilling>
                </Column>
              </Row>
              {!d.doMVR && !isCanadaCheck && (
                <Row marginTop="10px">
                  <Column width="100%" marginTop="10px">
                    <CardManualFilling
                      id="manual-fill"
                      link={this.hideButtons}
                    />
                  </Column>
                </Row>
              )}
            </div>
          </>
        )}
        {this.props.store.showAutomaticFill && (
          <TabAutomaticFill
            turn_id={this.props.turn_id}
            callback={this.fillModel}
            details={d}
            isCanadaCheck={isCanadaCheck}
            checkId={this.props.checkId}
          />
        )}
      </div>
    );
  }
}

export default inject("store")(observer(TabInformation));
