import styled from "styled-components";
import {
  noticeWarning,
  noticeError,
  white,
  black,
  periwinkle
} from "../../styles/constants";

const Highlighted = styled.span`
  color: ${props => {
    if (props.color) return props.color;
    if (props.warning) return noticeWarning;
    if (props.inherit) return "inherit";
    if (props.selected) return white;
    if (props.black) return black;
    if (props.disabled) return periwinkle;
    if (props.error) return noticeError;
  }};
  font-size: ${({ size }) => (size ? size : "14px")};
  font-weight: ${({ light }) => (light ? "400" : "bold")};
  font-family: ${({ family }) => family || "inherit"};
  margin-left: ${({ inline }) => (inline ? "-16px" : "inherit")};
  vertical-align: ${({ vmiddle }) => (vmiddle ? "middle" : "inherit")};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Highlighted;
