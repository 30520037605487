const styles = {
  content: {
    backgroundColor: "#FFFFFF",
    margin: "50px",
    borderRadius: 10,
    fontWeight: 300,
    padding: 30,
    boxShadow: "1px 1px 3px #CCC"
  },
  progress: {
    margin: "100px auto",
    width: "200px"
  },
  title: {
    fontWeight: 300,
    paddingBottom: 10
  },
  p: {
    fontSize: 15,
    paddingLeft: 20
  },
  section: {
    borderTopWidth: 1,
    borderTopColor: "#CCC",
    borderTopStyle: "solid",
    padding: "0 20px 5px"
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 500,
    borderBottomWidth: 3,
    borderBottomColor: "#58b2b4",
    borderBottomStyle: "solid",
    paddingBottom: 5,
    marginBottom: 0
  },
  sectionSelect: {
    width: 500,
    marginTop: 10
  },
  sectionMenuItem: {
    whiteSpace: "normal",
    lineHeight: "20px",
    background: "none",
    borderBottom: "1px solid rgb(243, 243, 243)",
    paddingBottom: 10,
    paddingTop: 10
  },
  selectedMenuItemStyle: {
    color: "#58b2b4"
  },
  checkbox: {
    marginBottom: 60,
    marginTop: 60,
    paddingLeft: 80,
    paddingRight: 80,
    fontWeight: "500"
  }
};

export default styles;
