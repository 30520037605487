import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { observer } from "mobx-react";
import _ from "lodash";
import { formatPhone, getStatus } from "turn-shared";
import { CandidatePreviewModalStyles as styles } from "../../styles";
import messages from "./messages";
import workerMessages from "../WorkerPage/messages";
import Badge from "../../components/Badge";
import "./index.css";
import * as colors from "../../../src/styles/constants";
import Button from "@material-ui/core/Button";
import preventClick from "../../utils/prevent-click";
import teamMember from "../../models/team-member";
import CircularProgress from "material-ui/CircularProgress";
import CheckStatus from "../../models/checks-status";
import { HIT, REVIEW, CLEAR } from "../../constants";

import ErrorDialog from "../../components/ErrorDialog";
import RaisedButton from "material-ui/RaisedButton";
import Modal from "../../components/Modal/modal";

class CandidatePreviewModal extends PureComponent {
  static propTypes = {
    processing: PropTypes.bool,
    worker: PropTypes.object,
    clearWorkerPreview: PropTypes.func,
    onPreviewClick: PropTypes.func,
    onPreviewDownloadClick: PropTypes.func,
    onPreviewViewDetailsClick: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.model = new CheckStatus();
  }

  async componentDidMount() {
    if (!this.props.worker) return;
    await this.model.getWorker(this.props.worker.turnId, false);
    this.model.setSSN();
  }

  handleClose = () => {
    this.props.clearWorkerPreview();
    this.model.setReady(true);
  };

  printPage = () => {
    const router = this.context.router;
    const worker = this.props.worker;
    if (preventClick(router, worker, teamMember.email)) return;
    this.context.router.history.push(`/workers/${worker.turnId}?print=true`);
  };

  viewDetails = () => {
    const router = this.context.router;
    const worker = this.props.worker;
    if (preventClick(router, worker, teamMember.email)) return;
    this.context.router.history.push(`/workers/${worker.turnId}`);
  };

  handleAction = (worker, action) => {
    if (
      (worker.status === "consent" || worker.status === "processing") &&
      action === "download"
    ) {
      this.props.onPreviewClick(worker, "download");
    } else if (
      (worker.status === "consent" || worker.status === "processing") &&
      action === "viewDetails"
    ) {
      this.props.onPreviewClick(worker, "viewDetails");
    } else if (
      worker.status !== "consent" &&
      worker.status !== "processing" &&
      action === "viewDetails"
    ) {
      this.viewDetails();
    } else if (
      worker.status !== "consent" &&
      worker.status !== "processing" &&
      action === "download"
    ) {
      this.printPage();
    }
  };

  renderLoading = () => (
    <div style={styles.progressContainer}>
      <CircularProgress style={styles.progress} size={80} thickness={5} />
    </div>
  );

  renderCheck = ({ id, status, shouldRender, iconProps = {}, title }) => {
    if (!shouldRender) return null;
    return (
      <div style={styles.listItemSplited} key={id}>
        <div style={styles.firstElement}>
          <div style={styles.listItemIconSplitedFirstElement}>
            <FontAwesomeIcon {...iconProps} />
          </div>
          <div style={styles.listItemTextSplitedFirstElement}>{title}</div>
        </div>
        <div style={styles.secondElement}>{_.upperFirst(status)}</div>
      </div>
    );
  };

  renderSortedChecks = () => {
    return this.sortedChecks.map((check, i) => {
      return this.renderCheck(check, i);
    });
  };

  get sortedChecks() {
    return [
      {
        id: messages.watchlist.id,
        status: this.model.watchlistStatus,
        shouldRender: this.model.watchlistShouldRender,
        iconProps: {
          icon: this.model.watchlistIcon,
          color: this.model.watchlistColor
        },
        title: <FormattedMessage {...messages.watchlist} />
      },
      {
        id: messages.sexOffender.id,
        status: this.model.sexOffenderStatus,
        shouldRender: this.model.sexOffenderShouldRender,
        iconProps: {
          icon: this.model.sexOffenderIcon,
          color: this.model.sexOffenderColor
        },
        title: <FormattedMessage {...messages.sexOffender} />
      },
      {
        id: messages.ssnTrace.id,
        status: this.model.ssnStatus,
        shouldRender: this.model.ssnShouldRender,
        iconProps: {
          icon: this.model.ssnIcon,
          color: this.model.ssnColor
        },
        title: <FormattedMessage {...messages.ssnTrace} />
      },
      {
        id: messages.addresses.id,
        status: null,
        shouldRender: this.model.addressesShouldRender,
        iconProps: {
          icon: faCheckCircle,
          color: colors.green
        },
        title: <FormattedMessage {...messages.addresses} />
      },
      {
        id: messages.criminal.id,
        status: this.model.criminalStatus,
        shouldRender: this.model.criminalShouldRender,
        iconProps: {
          icon: this.model.criminalIcon,
          color: this.model.criminalColor
        },
        title: <FormattedMessage {...messages.criminal} />
      },
      {
        id: messages.mvr.id,
        status: this.model.mvrStatus,
        shouldRender: this.model.mvrShouldRender,
        iconProps: {
          icon: this.model.mvrIcon,
          color: this.model.mvrColor
        },
        title: <FormattedMessage {...messages.mvr} />
      }
    ]
      .sort(({ status }) => (status === HIT ? 1 : -1))
      .sort(({ status }) => (status === REVIEW ? 1 : -1))
      .sort(({ status }) => (status === CLEAR || status === null ? 1 : -1));
  }

  render() {
    const { show, worker } = this.props;

    if (!show) return null;
    return (
      <Dialog
        open
        classes={{
          paper: "worker-preview-modal"
        }}
        PaperProps={{
          style: { height: "auto", minHeight: "400px", borderRadius: "25px" }
        }}
      >
        <DialogContent>
          <ErrorDialog
            open={this.model.error}
            onPress={() => this.handleClose()}
          />
          <Modal
            open={this.model.notFound}
            title={<FormattedMessage {...workerMessages.workerUnidentified} />}
            actions={[
              <RaisedButton
                primary
                label="OK"
                onClick={() => this.handleClose()}
                key="1"
              />
            ]}
            primaryButtonProps={{
              title: "OK",
              onClick: () => this.handleClose()
            }}
          >
            <FormattedMessage {...workerMessages.workerNotFound} />
          </Modal>

          <div style={styles.ovalContainer}>
            <div style={styles.ovalFlexContainer}>
              <i
                className="fas fa-clipboard-list-check"
                style={styles.ovalIcon}
              />
            </div>
          </div>
          <div style={styles.closeBtnContainer}>
            <Button
              onClick={this.handleClose}
              id="close_button"
              key="1"
              style={styles.closeWindowButton}
            >
              <div style={{ marginRight: "8px" }}>
                <i className="fas fa-times" />
              </div>
              <FormattedMessage {...messages.buttonClose} />
            </Button>
          </div>
          {!this.model.ready || this.model.error || this.model.notFound ? (
            this.renderLoading()
          ) : (
            <>
              <div style={styles.dialogTitle}>{worker.displayName}</div>
              <div style={styles.turnID}>Turn ID: {worker.turnId}</div>
              <div style={styles.badgeStatus}>
                <Badge
                  status={getStatus(worker.status)}
                  size="large"
                  hideIcon
                  justify="center"
                />
              </div>
              <div style={styles.listItem}>
                <div style={styles.listItemIcon}>
                  <i className="fas fa-mobile" />
                </div>
                <div style={styles.listItemText}>
                  {formatPhone(worker.phoneNumber)}
                </div>
              </div>
              <div style={styles.listItem}>
                <div style={styles.listItemIcon}>
                  <i className="fas fa-envelope" />
                </div>
                <div style={styles.listItemText}>{worker.email}</div>
              </div>
              <div style={styles.checkReport}>Check Report</div>
              {this.renderSortedChecks()}
              <div style={styles.buttonsContainer}>
                {/* <div style={styles.buttonDownloadContainer}>
                  <Button
                    onClick={() => this.handleAction(worker, "download")}
                    id="submit_modal"
                    key="1"
                    style={styles.downloadButton}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={styles.downloadIcon}
                    />
                    <FormattedMessage {...messages.download} />
                  </Button>
                </div> */}
                <div style={styles.buttonViewDetailsContainer}>
                  <Button
                    onClick={() => this.handleAction(worker, "viewDetails")}
                    id="submit_modal"
                    key="1"
                    style={styles.detailsButton}
                  >
                    <FormattedMessage {...messages.viewDetails} />
                  </Button>
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default observer(CandidatePreviewModal);
