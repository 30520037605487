import React, { useMemo } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles
} from "@material-ui/core";
import _ from "lodash";

import { slateGray, aliceBlue } from "../../styles/constants";
import { SearchHighlighted } from "../SearchHighlighted";

export const AutoTableCell = withStyles(() => ({
  head: {
    borderBottom: "none",
    fontWeight: 700,
    color: slateGray
  },
  root: {
    borderBottom: `solid 1px ${aliceBlue}`,

    "&:first-child": {
      fontWeight: "bold"
    }
  }
}))(TableCell);

export const AutoTableRow = withStyles(() => ({
  root: {
    "&:last-child > td": {
      borderBottom: "none"
    }
  }
}))(TableRow);

/**
 * Automatically render a Table from an array of objects.
 *
 * @example
 *    <AutoTable
 *      headers={{
 *        name: 'Name',
 *        lastName: 'Last Name'
 *      }}
 *      data={[
 *        { name: 'Bob', lastName: 'Doe' },
 *        { name: 'Alice' ,lastName: 'Doe'}
 *      ]}
 *     />
 *
 * @example
 *      const CustomTableCell = ({ field, item, ...props }) => (
 *        (field == 'icon' ? : (
 *          <AutoTableCell><Icon className={classNames(item[field])}></AutoTableCell>
 *        ) : (
 *          <AutoTableCell {...props} />
 *        ))
 *      );
 *
 * @param {object} props
 * @param {object[]} props.data Array of data items to display on a table
 * @param {object} props.headers  (Optional) Key-value mapping of key-column. If not provided or empty, will auto-generate them from the object-keys
 * @param {Component} props.rowComponent (Optional) Component that will render a TableRow, `item` prop contains the object it'll render, defaults to `TableRow`
 * @param {Component} props.cellComponent (Optional) Component that will render a TableCell, `field` prop contains the object field it'll render and `item` the item being rendered, defaults to AutoTableCell
 * @param {Component} props.headerRowComponent (Optional) Component that will render a TableRow on the header, defaults to rowComponent
 * @param {Component} props.headerCellComponent (Optional) Component that will render a TableCell on the header, defaults to renderCell
 */
export const AutoTable = ({
  headers,
  data,
  rowComponent,
  cellComponent,
  headerRowComponent,
  headerCellComponent,
  ...props
}) => {
  const RenderRow = rowComponent || AutoTableRow;
  const RenderCell = cellComponent || AutoTableCell;
  const RenderHeaderRow = headerRowComponent || RenderRow;
  const RenderHeaderCell = headerCellComponent || RenderCell;

  const _headers = useMemo(() => {
    if (headers) {
      return headers;
    }

    if (_.isEmpty(data)) {
      return [];
    }

    const sample = _(data)
      .values(data)
      .first();
    if (_.isEmpty(sample)) {
      return [];
    }

    return _.mapValues(sample, (v, key) => _.startCase(key));
  }, [headers, data]);

  return (
    <Table {...props}>
      <TableHead>
        <RenderHeaderRow>
          {_.values(_headers).map((column, key) => (
            <RenderHeaderCell key={key} field={key} item={null} isHeader>
              {column}
            </RenderHeaderCell>
          ))}
        </RenderHeaderRow>
      </TableHead>

      <TableBody>
        {_.values(data).map((item, i) => (
          <RenderRow key={i} item={item}>
            {_.keys(_headers).map(key => (
              <RenderCell key={key} field={key} item={item}>
                <SearchHighlighted textToHighlight={_.get(item, key)} />
              </RenderCell>
            ))}
          </RenderRow>
        ))}
      </TableBody>
    </Table>
  );
};
