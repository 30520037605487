import { string } from "prop-types";
import React from "react";

const SupportIcon = ({ height, stroke, width }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke || "#000"}
    >
      <path
        d="M7.79171 17.4167H7.33337C3.66671 17.4167 1.83337 16.5 1.83337 11.9167V7.33333C1.83337 3.66666 3.66671 1.83333 7.33337 1.83333H14.6667C18.3334 1.83333 20.1667 3.66666 20.1667 7.33333V11.9167C20.1667 15.5833 18.3334 17.4167 14.6667 17.4167H14.2084C13.9242 17.4167 13.6492 17.5542 13.475 17.7833L12.1 19.6167C11.495 20.4233 10.505 20.4233 9.90004 19.6167L8.52504 17.7833C8.37837 17.5817 8.03921 17.4167 7.79171 17.4167Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6634 10.0833H14.6716"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9959 10.0833H11.0041"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3283 10.0833H7.33653"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

SupportIcon.propTypes = {
  height: string,
  stroke: string,
  width: string
};

export default SupportIcon;
