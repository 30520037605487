import React, { useMemo } from "react";
import withContext from "../../../contextAPI/context-HOC";
import { PropTypes } from "prop-types";
import PanelCollapsible from "../../../components/PanelCollapsible";
import messages from "../messages";
import {
  ListDetailStyles as listStyles,
  CandidateReportStyles as reportStyles
} from "../../../styles";
import { List, ListItem } from "material-ui/List";
import ListItemContainer from "../../../components/ListDetailView/list-item-container";
import ListContainer from "../../../components/ListDetailView/list-container";
import { styles } from "./styles";
import { getCheckStatus, hideReportUrl } from "./utils";
import {
  CheckStatus,
  CheckStatusContainer,
  DTStatus,
  DrugTestType,
  ListWrapper
} from "./styled";
import _ from "lodash";
import { EXCLUDED_DT_STATUS } from "./constants";

const DrugTestingResults = ({
  context,
  status: checkStatus,
  worker,
  ...otherProps
}) => {
  const { drugTestingResults } = worker || {};

  const { closed_report_url, status, type } = drugTestingResults || {};
  const { checks = {} } = context || {};

  const dt_status = useMemo(
    () => !EXCLUDED_DT_STATUS.includes(status) && status,
    [status]
  );

  const { bgColor, color, icon, testStatus } = useMemo(
    () => getCheckStatus(checkStatus),
    [checkStatus]
  );

  const renderReportURL = useMemo(
    () => closed_report_url && !hideReportUrl(status),
    [drugTestingResults, status]
  );

  const isCollapsible = useMemo(() => !_.isEmpty(drugTestingResults), [
    drugTestingResults
  ]);

  return (
    <PanelCollapsible
      padding="10px 0"
      title={messages.drugTestingResults}
      titleStyle={reportStyles.title}
      iconStyle={reportStyles.checkedIcon}
      color={bgColor}
      icon={icon}
      collapsible={isCollapsible}
      expandAllChecks={checks.expandAllChecks}
      panelProps={styles.panelProps}
      {...otherProps}
    >
      <ListContainer simple>
        <List style={listStyles.list}>
          <ListItemContainer data-test="list-detail-item" simple key={0}>
            <ListItem disabled style={{ padding: 0 }}>
              <ListWrapper>
                <DrugTestType>{type}</DrugTestType>

                <CheckStatusContainer style={{ color }}>
                  <CheckStatus>
                    <strong>{testStatus}</strong>
                  </CheckStatus>
                  {!!dt_status && <DTStatus>{dt_status}</DTStatus>}
                </CheckStatusContainer>

                {renderReportURL && (
                  <a
                    href={closed_report_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Report
                  </a>
                )}
              </ListWrapper>
            </ListItem>
          </ListItemContainer>
        </List>
      </ListContainer>
    </PanelCollapsible>
  );
};

DrugTestingResults.propTypes = {
  context: PropTypes.object,
  expandAll: PropTypes.bool,
  status: PropTypes.string,
  worker: PropTypes.object
};

export default withContext(DrugTestingResults);
