import styled from "styled-components";

const Subhead = styled.div`
  box-sizing: border-box;
  display: inline-block;
  padding: 20px;
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  font-size: 14px;
  cursor: pointer;
  position: relative;
  color: ${({ selected }) => (selected ? "inherit" : "rgb(198, 199, 204)")};
  border-bottom: ${({ selected }) =>
    selected ? "2px solid rgb(95, 171, 252)" : "none"};
  overflow-x: auto;
`;

export default Subhead;
