import styled from "styled-components";
import { STYLES_MAPPING } from "./constants";

export const Container = styled.div`
  display: grid;
  place-items: center;

  width: 40px;
  height: 20px;

  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;

  ${props => STYLES_MAPPING[props.status]}
`;
