import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { isEmpty, isArray } from "lodash";
import Header from "./Header";
import Record from "./Record";
import { parseAction } from "../../../models/mvr";

const getTitle = action => action.code || action.incident_date || "Action";
const getSubtitle = action =>
  isEmpty(action.descriptions)
    ? null
    : action.descriptions[0].short_description;

class MVRActions extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    expand: PropTypes.bool.isRequired,
    icon: PropTypes.node
  };
  render() {
    const { data, expand, icon } = this.props;
    if (!isArray(data) || isEmpty(data)) return null;

    const exclude = [];
    const parsed = data.map(parseAction);
    const count = parsed.length;
    return (
      <div>
        <Header title="Actions" count={count} icon={icon} />
        {parsed.map((action, key) => (
          <Record
            title={getTitle(action)}
            subtitle={getSubtitle(action)}
            data={action}
            expand={expand}
            key={key}
            exclude={exclude}
          />
        ))}
      </div>
    );
  }
}
export default MVRActions;
