import styled from "styled-components";
import { white, secondary } from "../../styles/constants";

const ActionsSelectContainer = styled.div`
  display: ${({ hide }) => (hide ? "none" : "inline-flex")};
  background: ${secondary} !important;
  border-radius: 10px;
  width: 216px;
  font-size: 16px;
  position: relative;
  & > div {
    padding: 0;
    max-height: 47px;
    width: 100%;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.91;
    color: ${white};
    display: inline-flex;
    border: 0 !important;
    &:before {
      content: none;
    }
    & > div {
      font-size: 16px;
      & > div {
        padding: 0 16px;
        z-index: 2;

        &:focus {
          background-color: transparent;
        }
      }
    }
  }
`;

export default ActionsSelectContainer;
