import { Builder } from "../record-helper";

export const parseAction = data => {
  const titles = [];
  const sentences = [];
  const builder = new Builder(data, { titles, sentences });

  builder.assign("action_code", { out: "code" });
  builder.assign("action_type", { out: "type" });

  builder.assignList([
    "mail_date",
    "incident_date",
    "start_date",
    "state_code",
    "state_points",
    "acd_code",
    "acd_code_description",
    "ordered_date"
  ]);
  builder.assignNested(
    "locations",
    [
      ["loc", { out: "location" }],
      ["zip", { out: "zip_code" }],
      ["docket"],
      ["court"],
      ["court_type"],
      ["state"]
    ],
    { titles: ["loc", "court", "court_type"] }
  );
  builder.assignList([
    "thru_date",
    "actual_end_date",
    "additional messages",
    "state_code",
    "acd_code"
  ]);
  builder.assignNested(
    "descriptions",
    [["short_description"], ["state_description"], ["long_description"]],
    {
      sentences: ["short_description", "state_description", "long_description"]
    }
  );
  builder.assign("state_points");
  builder.assignNested("sub_violations", [
    ["acd_code"],
    ["acd_code_description"],
    ["actual_speed"],
    ["state_code"],
    ["key"]
  ]);
  builder.assignNested(
    "sub_violations.descriptions",
    [["short_description"], ["state_description"], ["long_description"]],
    {
      sentences: ["short_description", "state_description", "long_description"]
    }
  );
  builder.assignList(["state_points", "posted_speed", "actual_speed"]);
  return builder.dump();
};
