import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ActionsSelectContainer from "./ActionsSelectContainer";
import { styles } from "./styles";
import "./index.css";
import { formatMenuItemId } from "./adapters";
import { Tooltip } from "../../containers/WorkerContainer/actions/styled";
import { RESUME } from "../../containers/WorkerContainer/actions/constants";

class ActionsSelect extends Component {
  static propTypes = {
    model: PropTypes.object,
    actions: PropTypes.array.isRequired,
    action: PropTypes.string.isRequired,
    setAction: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    modals: PropTypes.node,

    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    open: PropTypes.bool,
    setOpen: PropTypes.func,

    disabled: PropTypes.bool,
    icon: PropTypes.bool,
    type: PropTypes.string,
    secondary: PropTypes.string,
    ref: PropTypes.node,
    divider: PropTypes.bool,
    containerStyle: PropTypes.object,
    modalsStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    iconStyle: PropTypes.object,
    nodeStyle: PropTypes.object
  };

  static defaultProps = {
    containerStyle: {},
    modalsStyle: {},
    titleStyle: {},
    iconStyle: {},
    nodeStyle: {},
    icon: true,
    disabled: false
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.node = null;
  }

  componentDidMount() {
    if (_.isElement(this.props.ref)) {
      this.node = this.props.ref;
    }
  }

  toggleOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  setOpen = open => {
    if (!_.isBoolean(open)) return;
    this.setState({ open });
  };

  handleOpen = open => {
    if (this.shouldUseOpenProps) {
      this.props.setOpen(open);
      return;
    }
    this.setOpen(open);
  };

  renderItems = () => {
    if (!Array.isArray(this.props.menuItems)) return null;
    return this.props.menuItems.map(
      (
        {
          item,
          customDisabled,
          disabled,
          divider,
          hide,
          displayTooltip,
          value,
          customTooltipContent,
          ...rest
        },
        index
      ) => {
        const itemId = formatMenuItemId(this.props.menuItems[index].value);
        return hide ? null : (
          <MenuItem
            id={itemId}
            style={styles.menuItem(customDisabled)}
            className={`${disabled && itemId + "_disabled"} ${customDisabled &&
              "custom_disabled"} mui-menu-item`}
            disabled={disabled}
            value={value}
            {...rest}
            key={value}
          >
            {displayTooltip && (
              <Tooltip
                id={`tooltip_${value}`}
                className="tooltip-menu-item"
                position={value === RESUME ? "bottom_left" : "top_left"}
              >
                {customTooltipContent || "Action not allowed for this role."}
              </Tooltip>
            )}
            <div style={{ opacity: disabled && "0.6" }}>
              {item}
              {!!divider && <div style={styles.divider} />}
            </div>
          </MenuItem>
        );
      }
    );
  };

  get shouldUseOpenProps() {
    if (_.isBoolean(this.props.open) && _.isFunction(this.props.setOpen)) {
      return true;
    }
    return false;
  }

  get open() {
    if (this.shouldUseOpenProps) {
      return this.props.open;
    }
    return this.state.open;
  }

  get containerStyle() {
    let style = styles.container;
    if (this.props.containerStyle) {
      style = { ...style, ...this.props.containerStyle };
    }
    if (this.props.secondary) {
      style = { ...style, height: "36px" };
    }

    return style;
  }

  get modalsStyle() {
    let style = styles.modals;
    if (this.props.modalsStyle) {
      style = { ...style, ...this.props.modalsStyle };
    }

    return style;
  }

  get titleStyle() {
    let style = styles.selectTitle;
    if (this.props.titleStyle) {
      style = { ...style, ...this.props.titleStyle };
    }

    return style;
  }

  get buttonTitleStyle() {
    let style = styles.buttonTitle;
    if (this.props.titleStyle) {
      style = { ...style, ...this.props.titleStyle };
    }

    return style;
  }

  get iconStyle() {
    let style = styles.icon;
    if (this.props.iconStyle) {
      style = { ...style, ...this.props.iconStyle };
    }

    return style;
  }

  get nodeStyle() {
    let style = styles.node;
    if (this.props.nodeStyle) {
      style = { ...style, ...this.props.nodeStyle };
    }

    return style;
  }
  renderIcon = () => {
    if (this.props.icon) {
      return (
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            ...this.iconStyle,
            transform: this.open && "rotate(-180deg)",
            transition: "all 300ms ease-in-out"
          }}
        />
      );
    }
    return;
  };
  render() {
    if (this.props.type === "button" && _.isFunction(this.props.onClick)) {
      return (
        <ActionsSelectContainer style={this.containerStyle}>
          <div onClick={this.props.onClick} style={styles.button}>
            <span style={this.buttonTitleStyle}>{this.props.title}</span>
          </div>
        </ActionsSelectContainer>
      );
    }

    return (
      <div style={this.containerStyle}>
        {!!this.props.modals && (
          <div style={this.modalsStyle}>{this.props.modals}</div>
        )}
        <ActionsSelectContainer
          style={{ ...this.props.styleActionSelectContainer }}
        >
          <Select
            disabled={this.props.disabled}
            value={this.props.action}
            onChange={({ target }) => {
              this.props.setAction(target.value);
            }}
            renderValue={() => (
              <span id={this.props.title.toLowerCase()} style={this.titleStyle}>
                {this.renderIcon()}
                {this.props.title}
              </span>
            )}
            IconComponent={() => null}
            open={this.open}
            onOpen={() => this.handleOpen(true)}
            onClose={() => this.handleOpen(false)}
            MenuProps={{
              anchorEl: this.node
            }}
          >
            {this.renderItems()}
          </Select>
        </ActionsSelectContainer>
        <div ref={node => (this.node = node)} style={this.nodeStyle} />
      </div>
    );
  }
}

export default ActionsSelect;
