import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { WorkerStyles as styles } from "../../../styles";
import Column from "../../../components/Column";
import WorkerDetails from "../../WorkerDetails";
import DatesSeen from "../../../components/DatesSeen";

import Title from "../../../components/Title";
import messages from "../messages";
import PanelCollapsible from "../../../components/PanelCollapsible";
import { primary } from "../../../styles/constants";
import { SearchHighlighted } from "../../../components/SearchHighlighted";

const WorkerContainerInformation = ({ worker }) => {
  const { details, showSSNTrace, detailsFirstSeen, detailsLastSeen } = worker;

  return (
    <PanelCollapsible
      panelProps={{
        style: {
          filter:
            "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
        }
      }}
      padding="0"
      margin="0px 0px 25px 0px"
      collapsedInvert={true}
      contentContentStyle={{ paddingTop: 0, paddingBottom: 0 }}
      titleComponent={
        <Title size="16px" align="left" margin="0" color={primary}>
          <FormattedMessage {...messages.candidateInformation} />
        </Title>
      }
    >
      <Column width="100%" style={styles.detailHeader}></Column>
      <Column width="100%">
        <Title
          size="20px"
          align="left"
          weight="bold"
          margin="0px 0px 10px 0px"
          color={primary}
        >
          <SearchHighlighted textToHighlight={worker.fullName} />
        </Title>
        <WorkerDetails details={details} showSSNTrace={showSSNTrace} />
      </Column>
      <DatesSeen first={detailsFirstSeen} last={detailsLastSeen} />
    </PanelCollapsible>
  );
};

WorkerContainerInformation.propTypes = {
  worker: PropTypes.object.isRequired
};

export default observer(WorkerContainerInformation);
