/* eslint-disable no-fallthrough */
import { decorate, action, observable, computed } from "mobx";
import { Setter } from "turn-shared";
import _ from "lodash";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner,
  faPauseCircle
} from "@fortawesome/pro-solid-svg-icons";

import { turnAPI } from "../services";
import { CurrentWorker } from "../models/current-worker";
import SSNModel from "../models/ssn";
import {
  PROCESSING,
  REVIEW,
  HIT,
  ERROR,
  CLEAR,
  NATIONAL_WATCHLIST,
  COUNTY_CHECKS
} from "../constants";
import { orange, green, periwinkle, red } from "../styles/constants";

class CheckStatus extends Setter {
  worker = {};
  ssn = {};
  ready = false;
  error = false;
  notFound = false;

  constructor() {
    super();
    this.worker = new CurrentWorker();
    this.ssn = new SSNModel();
  }

  setReady = this.setBoolFactory("ready", false);
  setError = this.setBoolFactory("error", false);
  setNotFound = this.setBoolFactory("notFound", false);

  getWorker = async (turnId, withGeo = true) => {
    this.setReady(false);
    const response = await turnAPI.getWorker(turnId);
    switch (response.status) {
      case 200:
        await this.worker.set(response.data);
        if (withGeo) {
          await this.worker.setGeocodeData();
        }
        this.worker.setId(response.data.uuid);
        this.worker.showCounty = true;
        break;
      case 404:
        this.setNotFound(true);
        break;
      case 307:
        this.auth.permission();
        break;
      case 401:
        this.auth.logout();
        break;
      default:
        this.setError(true);
        break;
    }
    this.setReady(true);
  };

  setWorker = worker => {
    if (!_.isObject(worker)) return;
    this.worker = worker;
    this.setSSN();
  };

  setSSN = () => {
    if (_.isEmpty(this.worker)) return null;
    this.ssn.setData(
      {
        ...this.worker.ssnChecks,
        is_deceased: this.worker.is_deceased
      },
      this.worker.manual,
      this.worker.is_random_ssn
    );
  };

  // Checks icons

  get mvrIcon() {
    if (this.mvrStatus === PROCESSING || this.mvrStatus === ERROR)
      return faSpinner;
    if (this.mvrStatus === HIT) return faExclamationTriangle;
    if (this.mvrStatus === REVIEW) return faPauseCircle;
    return faCheckCircle;
  }

  get sexOffenderIcon() {
    return this.sexOffenderStatus === CLEAR
      ? faCheckCircle
      : faExclamationTriangle;
  }

  get watchlistIcon() {
    if (this.watchlistStatus === PROCESSING) return faSpinner;
    if (this.watchlistStatus === HIT) return faExclamationTriangle;
    if (this.watchlistStatus === REVIEW) return faPauseCircle;
    return faCheckCircle;
  }

  get ssnIcon() {
    if (this.ssnStatus === REVIEW) return faPauseCircle;
    return faCheckCircle;
  }

  get criminalIcon() {
    if (this.criminalStatus === PROCESSING) return faSpinner;
    if (this.criminalStatus === HIT) return faExclamationTriangle;
    if (this.criminalStatus === REVIEW) return faPauseCircle;
    return faCheckCircle;
  }

  // Checks colors

  get mvrColor() {
    if (this.mvrStatus === PROCESSING || this.mvrStatus === ERROR)
      return periwinkle;
    if (this.mvrStatus === REVIEW) return orange;
    if (this.mvrStatus === HIT) return red;
    return green;
  }

  get sexOffenderColor() {
    return this.sexOffenderStatus === CLEAR ? green : red;
  }

  get watchlistColor() {
    if (this.watchlistStatus === PROCESSING) return periwinkle;
    if (this.watchlistStatus === REVIEW) return orange;
    if (this.watchlistStatus === HIT) return red;
    return green;
  }

  get ssnColor() {
    if (this.ssnStatus === REVIEW) return orange;
    return green;
  }

  get criminalColor() {
    if (this.criminalStatus === PROCESSING) return periwinkle;
    if (this.criminalStatus === REVIEW) return orange;
    if (this.criminalStatus === HIT) return red;
    return green;
  }

  // Checks status

  get mvrStatus() {
    const data = this.worker.mvr || {};
    if (_.isString(data)) return REVIEW;
    if (!data.isMVRClear) return HIT;
    if (this.worker.isProcessingMVR) return REVIEW;
    if (this.worker.isPendingReview) return REVIEW;
    if (this.worker.isPendingAgeReview) return REVIEW;

    return CLEAR;
  }

  get sexOffenderStatus() {
    if (!this.sexOffender.value) return "";
    return this.sexOffender.value.length === 0 ? CLEAR : HIT;
  }

  get watchlistStatus() {
    const data = this.worker.watchlistData;
    if (!data) return "";
    const clear = data.length === 0;

    if (this.worker.workerIsPendingReview) return REVIEW;
    if (!clear) return HIT;
    return CLEAR;
  }

  get ssnStatus() {
    return this.ssn.isMatched("public_records_name") &&
      this.ssn.isMatched("dob")
      ? CLEAR
      : REVIEW;
  }

  get criminalStatus() {
    const { countyCriminalData: items = [] } = this.worker;
    if (!items) return "";
    if (this.worker.isProcessingMVR) return REVIEW;
    if (this.worker.isPendingReview) return REVIEW;
    if (this.worker.isPendingMVRReview) return REVIEW;
    if (this.worker.isPendingAgeReview) return REVIEW;
    if (items.some(val => val["status"] === "processing")) return PROCESSING;
    if (
      items.some(val => val["status"] === "ready") &&
      items.some(val => !!val["value"])
    )
      return HIT;
    return CLEAR;
  }

  get criminalHasHits() {
    const { countyCriminalData: items = [] } = this.worker;
    if (!items) return false;
    return (
      items.some(val => val["status"] === "ready") &&
      items.some(val => !!val["value"])
    );
  }

  get federalHasHits() {
    const data = this.worker.federalCriminalData || [];
    if (!data) return false;
    return (
      data.some(val => val["status"] === "ready") &&
      data.some(val => val["values"].length > 0)
    );
  }

  // Checks render
  get mvrShouldRender() {
    if (_.isEmpty(this.worker.mvr)) return false;
    return true;
  }

  get sexOffenderShouldRender() {
    // Using same criteria than CheckList
    if (
      !Array.isArray(this.worker.checks) ||
      this.worker.checks.length === 0 ||
      _.isEmpty(this.worker.checks.filter(item => item.value)) ||
      this.worker.workerIsPendingReview
    ) {
      return false;
    }
    return true;
  }

  get watchlistShouldRender() {
    if (!this.worker) return false;
    if (!Array.isArray(this.worker.watchlistData)) return false;
    if (
      this.worker.isPendingReview &&
      !this.worker.shouldShowCheck(NATIONAL_WATCHLIST)
    )
      return false;
    if (
      this.worker.isPendingMVRReview &&
      !this.worker.shouldShowCheck(NATIONAL_WATCHLIST)
    )
      return false;
    if (
      this.worker.isProcessingMVR &&
      !this.worker.shouldShowCheck(NATIONAL_WATCHLIST)
    )
      return false;
    if (
      this.worker.isPendingAgeReview &&
      !this.worker.shouldShowCheck(NATIONAL_WATCHLIST)
    )
      return false;
    return true;
  }

  get ssnShouldRender() {
    return this.worker.showSSNTrace;
  }

  get addressesShouldRender() {
    const sanitizedAddresses = _.filter(this.worker.addresses, address => {
      // Make sure to keep this array in sync with `AddressTable` attributes it uses from address
      for (const key of ["address1", "city", "zip", "state"]) {
        const val = _.get(address, key);
        if (_.isEmpty(val)) {
          return false;
        }
      }

      return true;
    });

    return !_.isEmpty(sanitizedAddresses) && this.worker.showSSNTrace;
  }

  get criminalShouldRender() {
    const { countyCriminalData: items = [] } = this.worker;
    if ((!items && !this.worker.isPendingReview) || (!items && !this.worker)) {
      return false;
    }
    if (
      this.worker.isPendingReview &&
      !this.worker.shouldShowCheck(COUNTY_CHECKS)
    )
      return false;
    if (
      this.worker.isPendingMVRReview &&
      !this.worker.shouldShowCheck(COUNTY_CHECKS)
    )
      return false;
    if (
      this.worker.isProcessingMVR &&
      !this.worker.shouldShowCheck(COUNTY_CHECKS)
    ) {
      return false;
    }
    return true;
  }

  get sexOffender() {
    return this.worker.checks.filter(
      ({ label }) => label === "Sex Offender Check"
    )[0];
  }
}

decorate(CheckStatus, {
  worker: observable,
  ready: observable,
  error: observable,
  notFound: observable,

  getWorker: action,

  mvrStatus: computed,
  mvrIcon: computed,
  mvrColor: computed,
  mvrShouldRender: computed,
  sexOffenderStatus: computed,
  sexOffenderIcon: computed,
  sexOffenderColor: computed,
  sexOffenderShouldRender: computed,
  watchlistStatus: computed,
  watchlistIcon: computed,
  watchlistColor: computed,
  watchlistShouldRender: computed,
  ssnStatus: computed,
  ssnIcon: computed,
  ssnColor: computed,
  ssnShouldRender: computed,
  criminalStatus: computed,
  criminalHasHits: computed,
  criminalIcon: computed,
  criminalColor: computed,
  criminalShouldRender: computed,

  sexOffender: computed
});

export default CheckStatus;
