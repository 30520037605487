import React from "react";
import PropTypes from "prop-types";
import { emails } from "../../data/company";

const statusErrors = {
  401: (
    <span>
      Error 401: This Background Report
      <br /> has not been consented to.
    </span>
  ),
  403: (
    <span>
      Error 403: This Background Report
      <br /> has already been assented to.
    </span>
  ),
  404: <span>Error 404: This Background Report is not available.</span>,
  410: <span>Error 410: This Background Report is no longer available.</span>,
  500: (
    <span>
      Error 500: Internal Server Error.
      <br />
      Worry not, service should resume shortly.
    </span>
  )
};

const StatusErrors = ({ status }) => {
  if (typeof status !== "number")
    return (
      <span>
        Error 520: Something went wrong. <br />
        If you continue to see this error please contact{" "}
        <a style={{ color: "white" }} href={`mailto:${emails.support}`}>
          {emails.support}
        </a>
      </span>
    );
  const success = statusErrors[status];
  if (!success)
    return (
      <span>
        Error {status}: Something went wrong. <br />
        If you continue to see this error please contact{" "}
        <a style={{ color: "white" }} href={`mailto:${emails.support}`}>
          {emails.support}
        </a>
      </span>
    );
  return success;
};

StatusErrors.propTypes = {
  status: PropTypes.number
};

export default StatusErrors;
