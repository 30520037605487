import Column from "../Column";
import { FormCheckLine } from "./components/FormCheckLine";
import { FormWizardBackForward } from "./components/FormWizardBackNext";
import { FormWizardStep } from "./components/FormWizardStep";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import React from "react";
import Title from "../Title";
import TurnScroll from "../TurnScroll";
import messages from "./messages";
import { observer } from "mobx-react";
import { LandingPageStyles as styles } from "../../styles";
import syslog from "../../services/syslog";

const propTypes = {
  ...FormWizardStep.propTypes,
  details: PropTypes.object.isRequired,
  previousStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  rights: PropTypes.string.isRequired
};

const TabRights = props => {
  const handleNext = () => {
    props.nextStep();
    const detailsData = { ...props.details.formData };
    detailsData["has_middle_name"] = !detailsData["has_middle_name"];
    detailsData["tab"] = "step_3_rights";
    syslog(["Tab Rights", detailsData]);
  };

  const handleBack = () => {
    props.previousStep();
    props.details.setCaptchaReset("reset");
  };

  return (
    <div style={styles.tabContent}>
      <Title id="rightsStepTitle" align="left" big>
        A Summary of Your Rights
      </Title>
      <TurnScroll
        id="rightsLegalDisclosure"
        dangerouslySetInnerHTML={{ __html: props.rights }}
      />
      <div style={styles.agreeContainer}>
        <FormCheckLine
          id="rightsTabCheckbox"
          label={<FormattedMessage {...messages.rights} />}
          checked={props.details.agreeRights}
          onChange={() =>
            props.details.setAgreeRights(!props.details.agreeRights)
          }
        />
      </div>
      <Column
        textAlign="right"
        textAlignSM="center"
        width="100%"
        margin="20px 0 0 0"
      >
        <FormWizardBackForward
          id="rightsTabBackForwardDiv"
          nextDisabled={!props.details.agreeRights}
          onBack={handleBack}
          onNext={handleNext}
        />
      </Column>
    </div>
  );
};

TabRights.propTypes = propTypes;
export default observer(TabRights);
