import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { CandidateReportStyles as styles } from "../../styles";
import Row from "../Row";
import Column from "../Column";

import Check from "./Check";
import AddressList from "./AddressList";
import messages from "./messages";
import { green } from "../../styles/constants";
import Divider from "../Divider";

class AddressesFullList extends Component {
  static propTypes = {
    model: PropTypes.object,
    isPDFReport: PropTypes.bool,
    checkIcon: PropTypes.any
  };
  static defaultProps = {
    model: {}
  };
  constructor(props) {
    super(props);
    this.model = props.model;
  }
  render() {
    if (!this.model) return null;
    if (!Array.isArray(this.model.addresses) || this.model.addresses.length < 1)
      return null;
    const { isPDFReport, checkIcon } = this.props;

    const defaultCheckNameId = "detailCheckName";
    const defaultCheckStatusId = "detailCheckStatus";
    const defaultCheckSectionId = "detailCheckSection";
    const columnWidth = isPDFReport ? "25%" : "100%";

    return (
      <div id={defaultCheckSectionId} style={styles.section}>
        <Check
          check={<FormattedMessage {...messages.addressesFullActiveList} />}
          icon={checkIcon}
          color={green}
          noDivider
          containerStyle={styles.checks.titleContainer}
          nameId={defaultCheckNameId}
          statusId={defaultCheckStatusId}
        />
        <div>
          <Row style={styles.sectionContent}>
            {/* SSN */}
            <Column
              width="25%"
              widthMD={columnWidth}
              widthSM={columnWidth}
              style={styles.ssnStatus}
            ></Column>
            <AddressList isSSN={false} addresses={this.model.addresses} />
          </Row>
          <Divider height="1px" />
        </div>
      </div>
    );
  }
}

export default observer(AddressesFullList);
