const styles = {
  container: {
    fontSize: 0
    // backgroundColor: "rgb(248, 248, 248)"
  },
  count: {
    fontSize: 16,
    fontWeight: "bold",
    display: "inline-block",
    marginRight: 5,
    verticalAlign: "middle"
  },
  detailContainer: {
    margin: 0
  },
  list: {
    padding: 0
  },
  paddedItem: {
    paddingLeft: "5%",
    paddingRight: "5%"
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between"
  }
};

export default styles;
