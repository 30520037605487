import React from "react";
import { PropTypes } from "prop-types";
import { prettifyDate, toTitleCase } from "turn-shared";
import CollapsibleListItem from "../../../components/ListDetailView/collapsible-list-item";
import DetailInline from "../../../components/ListDetailView/detail-inline";
import DetailContainer from "../../../components/ListDetailView/detail-container";
import { parseDriver } from "../../../models/mvr";

const MVRDriver = ({ data, expand }) => {
  const name = data.full_name
    ? toTitleCase(data.full_name)
    : "Name Unavailable";
  const dob = data.dob ? `(${prettifyDate(data.dob)})` : "(DOB Unavailable)";
  const parsed = parseDriver(data);
  return (
    <div style={{ padding: "0 0 0 6%" }}>
      <CollapsibleListItem title={name} subtitle={dob} expand={expand} inline>
        <DetailContainer>
          <DetailInline deep data={parsed} />
        </DetailContainer>
      </CollapsibleListItem>
    </div>
  );
};
MVRDriver.propTypes = {
  data: PropTypes.object.isRequired,
  expand: PropTypes.bool.isRequired
};
export default MVRDriver;
