import styled from "styled-components";
import { primary, secondary } from "../../styles/constants";

export const ModalContainer = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
`;

export const panelStyles = {
  position: "relative",
  maxWidth: "500px",
  overflow: "hidden",
  borderRadius: "16px",
  padding: "30px",
  textAlign: "left",
  verticalAlign: "middle",
  backgroundColor: "#fff",
  filter:
    "drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))",
  transitionProperty: "all",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: "150ms"
};

export const Content = styled.div``;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Children = styled.div`
  margin: 25px 0 35px;
`;

export const PrimaryButton = styled.button`
  min-width: 130px;
  height: 44px;
  background-color: ${secondary};
  color: #fff;
  font-weight: 600;
  margin-left: 10px;

  border: 0;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;

  &:focus-visible {
    outline: 0;
  }

  &:hover {
    background-color: ${secondary}96;
  }

  &:disabled {
    background-color: #8f949a !important;
    cursor: not-allowed;

    &:hover {
      background-color: #8f949a !important;
    }
  }

  ${props => props.customStyles}
`;

export const SecondaryButton = styled.button`
  min-width: 130px;
  height: 44px;
  color: ${primary};
  font-weight: 600;

  border: 0;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;

  &:focus-visible {
    outline: 0;
  }

  &:hover {
    background-color: ${primary}40 !important;
  }

  ${props => props.customStyles}
`;
