import { defineMessages } from "react-intl";

export default defineMessages({
  applicants: {
    id: "app.containers.AppHeader.applicants",
    defaultMessage: "Screen"
  },
  openings: {
    id: "app.containers.AppHeader.openings",
    defaultMessage: "Jobs"
  },
  openingsApplicants: {
    id: "app.containers.AppHeader.openingsApplicants",
    defaultMessage: "Applicants"
  },
  locations: {
    id: "app.containers.AppHeader.locations",
    defaultMessage: "Hiring Locations"
  },
  locationNew: {
    id: "app.containers.AppHeader.locationNew",
    defaultMessage: "New Hiring Location"
  },
  positions: {
    id: "app.containers.AppHeader.positions",
    defaultMessage: "Job Descriptions"
  },
  positionNew: {
    id: "app.containers.AppHeader.positionNew",
    defaultMessage: "New Job Description"
  },
  readyApplicants: {
    id: "app.containers.AppHeader.readyApplicants",
    defaultMessage: "Applicants"
  },
  worker: {
    id: "app.containers.AppHeader.worker",
    defaultMessage: "Report"
  },
  settings: {
    id: "app.containers.AppHeader.settings",
    defaultMessage: "Settings"
  },
  logout: {
    id: "app.containers.AppHeader.logout",
    defaultMessage: "Logout"
  }
});
