import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { CandidateReportStyles as styles } from "../../styles";

import DoubleBounceLoader from "../DoubleBounceLoader";
import Title from "../Title";

const TurnLoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, ${props => props.opacity || 0.9});
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  pointer-events: ${props => (props.hidden ? "none" : "auto")};
  opacity: ${props => (props.hidden ? 0 : 1)};
  transition: opacity 200ms;

  z-index: 9999;
`;

/**
 * Render a full-screen, fixed, top-most, semi-transparent, doubled bounce dots
 * message for conveying "loading".
 *
 * @example
 *  <TurnLoader />
 *
 * @example
 *  <TurnLoader opacity={0.5} />
 *
 * @example
 *  <TurnLoader>Downloading data...</TurnLoader>
 *
 * @param {number} opacity (Optional) sets the background's opacity (0 to 1), defaults to 0.9
 * @param {bool} hidden (Optional) makes the loader hidden with animations
 */
export const TurnLoader = ({ children, ...props }) => (
  <TurnLoaderWrapper {...props}>
    <DoubleBounceLoader />
    <Title weight="bolder" big margin="20px 0 5px 0">
      Please Wait
    </Title>
    <p style={styles.progressContent}>
      {React.isValidElement(children) ? (
        children
      ) : (
        <>
          Validating your information <br />
          it will be ready shortly.
        </>
      )}
    </p>
  </TurnLoaderWrapper>
);
TurnLoader.propTypes = {
  children: PropTypes.node,
  opacity: PropTypes.number
};
