const demoWorkerResponse = {
  adverse_action: true,
  count: 117500,
  counts: {
    Withdrawn: 1,
    adverse: 0,
    approved: 93771,
    consent: 91,
    emailed: 55204,
    "first notice": 999,
    initiated: 37148,
    pending: 39,
    processing: 2073,
    rejected: 13820,
    review: 4510,
    "second notice": 1460,
    withdrawn: 451
  },
  items: [],
  limit: 10,
  offset: 0
};

export default demoWorkerResponse;
