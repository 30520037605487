import { string } from "prop-types";
import React from "react";

const UnidentifiedUserIcon = ({ height, stroke, width }) => {
  return (
    <svg
      width={width || "172"}
      height={height || "172"}
      viewBox="0 0 172 172"
      fill="none"
      stroke={stroke || "#000"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={74.334} cy={48.899} r={28.388} />
      <path
        d="M74.913 80.762c17.331 0 31.381-14.05 31.381-31.38C106.294 32.05 92.244 18 74.913 18S43.532 32.05 43.532 49.381c0 17.332 14.05 31.381 31.38 31.381ZM21 143.525c0-24.289 24.163-43.934 53.913-43.934 6.025 0 11.862.816 17.322 2.323"
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.149 106.254a8 8 0 0 0-8 8v.222a8 8 0 0 0 8 8h-9.269a8 8 0 0 0-8 8v10.649a8 8 0 0 0 8 8h30.347a8 8 0 0 0 8-8v-10.649a8 8 0 0 0-8-8h-10.428a8 8 0 0 0 8-8v-.222a8 8 0 0 0-8-8h-10.65Z"
      />
      <path
        d="M112.57 122.089v-4.828c0-7.99 2.414-14.483 14.484-14.483 12.069 0 14.483 6.493 14.483 14.483v4.828M138.93 151.249h-24.139c-9.656 0-12.07-2.414-12.07-12.069v-4.828c0-9.656 2.414-12.07 12.07-12.07h24.139c9.656 0 12.07 2.414 12.07 12.07v4.828c0 9.655-2.414 12.069-12.07 12.069Z"
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

UnidentifiedUserIcon.propTypes = {
  height: string,
  stroke: string,
  width: string
};

export default UnidentifiedUserIcon;
