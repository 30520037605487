import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner
} from "@fortawesome/pro-solid-svg-icons";
import { Divider } from "material-ui";

import Highlighted from "../../../components/Highlighted";
import { periwinkle, green, red } from "../../../styles/constants";
import IconContainer from "../../../components/IconContainer";
import RecordEta from "./Eta";
import RecordDetail from "./RecordDetail";
import { isEmpty, isString } from "lodash";
import getCustomStyles from "./styles";
import { ICON_MIN_WIDTH, ON_DEMAND_CRIM } from "../../../constants";
import { SearchHighlighted } from "../../../components/SearchHighlighted";
import { OnDemandCrimStatus, OnDemandCrimWrapper } from "./styled";

const Record = props => {
  if (isEmpty(props.item)) {
    return null;
  }
  const {
    clear_eta,
    county,
    hit_eta,
    name,
    has_hits,
    report_url,
    resolution,
    status
  } = props.item;
  const { width, expand, filteredValue } = props;
  const subTitle = status ? status.toUpperCase() : status;
  const customStyles = getCustomStyles(width === "small");

  const validateString = string => isString(string) && !isEmpty(string);

  const formatCounty = county => {
    if (!_.isString(county)) return "";
    const split = county.split("-");
    // Are all counties 2 length
    const _county = split.filter(string => string.length === 2)[0];
    const _state = _.upperFirst(
      _.lowerCase(split.filter(string => string.length !== 2)[0])
    );
    return `${_state}, ${_county}`;
  };

  const renderTitle = () => {
    formatCounty(county);
    return validateString(county) ? (
      <Highlighted
        black
        style={{
          minWidth: "8rem",
          display: "inline-block",
          marginLeft: "25px"
        }}
      >
        <SearchHighlighted textToHighlight={formatCounty(county)} />
      </Highlighted>
    ) : null;
  };

  const renderSubtitle = () => {
    const _subTitle = _.upperCase(subTitle);
    const isProcessing = _subTitle === "PROCESSING";
    const isReady = isEmpty(filteredValue) && _subTitle === "READY";
    const hasHits =
      (!isEmpty(filteredValue) && _subTitle === "READY") || has_hits;

    if (isProcessing) {
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          color={periwinkle}
          style={{ fontSize: "20px", minWidth: ICON_MIN_WIDTH }}
        />
      );
    }
    if (isReady) {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={green}
          style={{ fontSize: "20px", minWidth: ICON_MIN_WIDTH }}
        />
      );
    }
    if (hasHits) {
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          color={red}
          style={{ fontSize: "20px", minWidth: ICON_MIN_WIDTH }}
        />
      );
    }
    return null;
  };

  const renderDetail = (label, detail) =>
    validateString(detail) && subTitle !== "READY" ? (
      <RecordEta label={label} detail={detail} />
    ) : null;

  return (
    <div>
      <div
        style={{
          minHeight: "4rem",
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap"
        }}
      >
        <div>
          {renderSubtitle()}
          {renderTitle()}
        </div>
        <IconContainer right="34px">
          {renderDetail("ETA Clear:", clear_eta)}
          {renderDetail("ETA Hit:", hit_eta)}
        </IconContainer>

        {name === ON_DEMAND_CRIM && (
          <OnDemandCrimWrapper>
            {resolution && (
              <OnDemandCrimStatus status={resolution}>
                {resolution}
              </OnDemandCrimStatus>
            )}
            {report_url && (
              <a href={report_url} target="_blank" rel="noopener noreferrer">
                View Report
              </a>
            )}
          </OnDemandCrimWrapper>
        )}
      </div>
      {!isEmpty(filteredValue)
        ? filteredValue.map((detail, index) => (
            <>
              <RecordDetail
                expand={expand}
                width={width}
                customStyles={customStyles}
                idSuffix={county + index}
                detail={detail}
                key={index}
              />
              {filteredValue.length !== 1 &&
                index + 1 !== filteredValue.length && <Divider />}
            </>
          ))
        : null}
    </div>
  );
};
Record.propTypes = {
  item: PropTypes.object,
  width: PropTypes.string,
  expand: PropTypes.bool,
  filteredValue: PropTypes.array
};

export default Record;
