const headerContainer = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "15px 5%",
  borderRadius: "10px",
  position: "relative"
};

const styles = {
  title: {
    display: "inline-flex",
    flexWrap: "wrap"
  },
  subIcon: {
    minWidth: "10px",
    width: "15px"
  },
  collapsibleIcon: {
    fontSize: "14px"
  },
  headerContainer,
  headerContainerOpen: {
    ...headerContainer,
    borderRadius: "10px 10px 0px 0px"
  },
  contentContainer: {
    padding: "10px 5%",
    position: "relative"
  },
  dividerStyles: {
    display: "block",
    height: "1px",
    width: "100%",
    margin: "0 auto",
    marginBottom: "10px"
  }
};

export default styles;
