import React from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import Title from "../../../components/Title";
import Container from "../../../components/Container";
import Panel from "../../../components/Panel";
import ListDetailView from "../../../components/ListDetailView";
import messages from "../messages";

const WorkerVehicles = ({ vehicleData }) => {
  return vehicleData.length > 0 ? (
    <Container small>
      <Panel padding="10px 5%">
        <Title align="left">
          <FormattedMessage {...messages.vehicles} />
        </Title>
      </Panel>
      <ListDetailView
        data={[...vehicleData]}
        simple
        masterKey={["make", "model", "year"]}
        exclude={["id", "title_holders"]}
        secondary="title_holders"
      />
    </Container>
  ) : null;
};
WorkerVehicles.propTypes = { vehicleData: PropTypes.object.isRequired };

export default observer(WorkerVehicles);
