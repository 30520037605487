import styled from "styled-components";

const DetailResponsiveLabel = styled.dt`
  @media (max-width: 600px) {
    display: ${props => props.displaySM || "inline-block"};
    min-width: ${props => props.marginRightSM || "4em"};
  }
`;

export default DetailResponsiveLabel;
