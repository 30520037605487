import styled from "styled-components";

const DateContainer = styled.div`
  .form-date {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
  .date-border {
    border: 1px solid #bfcddc;
    border-radius: 7px;
    background: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .form-date__label {
    font-family: "Helvetica", arial, sans-serif;
    font-size: 0.875rem;
    line-height: 24px;
    font-weight: normal;
    padding-bottom: 8px;
  }

  .form-date__input[type="date"] {
    appearance: none;
    color: #1d1d1d;
    font-family: "Helvetica", arial, sans-serif;
    font-size: 0.875rem;
    line-height: 24px;
    background: #ffffff;
    display: inline-block;
    visibility: visible;
    width: 140px;
    padding: 4px;
    position: relative;
  }
  .form-date__input[type="date"]:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .form-date__input[type="date"]::-webkit-calendar-picker-indicator {
    pointer-events: auto;
  }

  .form-date__input[type="date"]::-webkit-date-and-time-value {
    margin: 0;
  }

  .form-date__input[type="date"] {
    border: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    -webkit-appearance: none;
  }

  .form-date__input[type="date"]::-webkit-inner-spin-button,
  .form-date__input[type="date"]::-webkit-outer-spin-button,
  .form-date__input[type="date"]::-webkit-clear-button {
    -webkit-appearance: none;
    display: none;
  }

  .form-date__input[type="date"]::-webkit-datetime-edit-month-field {
    padding: 0;
  }
  .form-date__input[type="date"]::-webkit-datetime-edit-day-field {
    padding: 0;
  }
  .form-date__input[type="date"]::-webkit-datetime-edit-year-field {
    padding: 0;
  }

  .form-date__input[type="date"]::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }
`;

export default DateContainer;
