import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ButtonPhotosRequest } from "../Button";
import Img from "../Img";

const propTypes = {
  imageType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  paragraphText: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  handler: PropTypes.func.isRequired,
  photosResponse: PropTypes.bool,
  lastStep: PropTypes.func,
  isLastStep: PropTypes.bool
};

const TabCanadaPhotosFlow = ({
  imageType,
  title,
  paragraphText,
  buttonText,
  handler,
  photosResponse,
  lastStep,
  isLastStep
}) => {
  useEffect(() => {
    if (photosResponse && photosResponse.error != null) {
      lastStep();
    }
  }, [photosResponse]);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: 30,
        padding: "10% 12%",
        margin: "5% auto 0 auto",
        maxWidth: 576
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Img type={imageType} style={{ width: 214 }} />

        <p
          id="verificationStepTitle"
          style={{
            fontSize: 24,
            textAlign: "center",
            fontWeight: "bold",
            margin: -10
          }}
        >
          {title}
        </p>
        <p
          id="verificationStepSubTitle"
          style={{ textAlign: "center", fontSize: 16 }}
        >
          {paragraphText}
        </p>

        {!isLastStep && (
          <ButtonPhotosRequest
            id="letsDoItButton"
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => handler()}
          >
            {buttonText}
          </ButtonPhotosRequest>
        )}
      </div>
    </div>
  );
};

TabCanadaPhotosFlow.defaultProps = {
  isLastStep: false
};

TabCanadaPhotosFlow.propTypes = propTypes;

export default TabCanadaPhotosFlow;
