import { defineMessages } from "react-intl";

export default defineMessages({
  copyright: {
    id: "app.containers.SideBar.copyright",
    defaultMessage: "Copyright Turn Technologies Inc. "
  },
  workerLink: {
    id: "app.containers.WorkerPage.workersLink",
    defaultMessage: "Worker"
  },
  workersLink: {
    id: "app.components.Dashboard.workersLink",
    defaultMessage: "Screen"
  },
  pendingWorkers: {
    id: "app.components.Dashboard.pendingWorkers",
    defaultMessage: "Instaready"
  },
  sourcing: {
    id: "app.components.Dashboard.sourcing",
    defaultMessage: "Ready"
  },
  candidates: {
    id: "app.components.Dashboard.candidates",
    defaultMessage: "Create Job Invite"
  },
  reportLink: {
    id: "app.components.Dashboard.reportLink",
    defaultMessage: "Reports"
  },
  settings: {
    id: "app.containers.Dashboard.settings",
    defaultMessage: "Settings"
  },
  support: {
    id: "app.containers.Dashboard.support",
    defaultMessage: "Support"
  },
  logout: {
    id: "app.containers.Dashboard.logout",
    defaultMessage: "Logout"
  },
  positions: {
    id: "app.components.Dashboard.candidates",
    defaultMessage: "Job Descriptions"
  },
  openings: {
    id: "app.components.Dashboard.candidates",
    defaultMessage: "Jobs"
  },
  locations: {
    id: "app.components.Dashboard.candidates",
    defaultMessage: "Hiring Locations"
  },
  applicants: {
    id: "app.components.Dashboard.workersLink",
    defaultMessage: "Applications"
  },
  advise: {
    id: "app.components.Dashboard.advise",
    defaultMessage: "Advise"
  }
});
