import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Row from "../Row";
import Column from "../Column";
import Img from "../Img";
import Text from "../Text";
import { CandidateReportStyles as styles } from "../../styles";

import HeaderStyle from "./HeaderStyle";
import messages from "./messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone
} from "@fortawesome/pro-solid-svg-icons";

class ReportHeader extends Component {
  static propTypes = {
    isPDFReport: PropTypes.bool
  };

  render() {
    const { isPDFReport } = this.props;

    return (
      <Row style={styles.headerContainer}>
        <Column
          width="75%"
          widthMD="65%"
          widthSM={isPDFReport ? "75%" : "100%"}
          verticalAlign="top"
          background="transparent"
        >
          <Img type="logo" style={styles.headerImage} alt="" />
        </Column>
        <Column
          width="25%"
          widthMD="35%"
          widthSM={isPDFReport ? "25%" : "100%"}
          verticalAlign="top"
          background="transparent"
        >
          <HeaderStyle>
            <Text
              size="10px"
              lineHeight="1.57"
              display="block"
              align="right"
              weight="bold"
            >
              <FormattedMessage {...messages.headerCompanyName} />
            </Text>
            <Text size="11px" display="block" align="right">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={styles.headerText}
              />
              <FormattedMessage {...messages.headerCompanyAddress} />
              ,&nbsp;
              <FormattedMessage {...messages.headerCompanyState} />
            </Text>
            <Text size="11px" display="block" align="right">
              <span style={styles.headerText}>
                <FontAwesomeIcon icon={faEnvelope} style={styles.headerText} />
                <FormattedMessage {...messages.headerCompanyEmail} />
              </span>
              <span>
                <FontAwesomeIcon icon={faPhone} style={styles.headerText} />
                <FormattedMessage {...messages.headerCompanyNumber} />
              </span>
            </Text>
          </HeaderStyle>
        </Column>
      </Row>
    );
  }
}

export default ReportHeader;
