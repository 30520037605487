const elipsisText = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "inline-block"
};

const getCustomStyles = isSmall => ({
  status: { ...elipsisText, width: "80%", marginLeft: "-16px" },
  title: {
    ...elipsisText,
    width: isSmall ? "80%" : "45%",
    display: isSmall ? "block" : "inline-block"
  },
  tooltipsStyle: { ...elipsisText, margin: "auto", maxWidth: "100%" },
  tooltipContainerStyle: { ...elipsisText, padding: "0 1rem", width: "45%" },
  expand: {
    margin: 0,
    width: isSmall ? "20%" : "10%",
    display: "inline-block",
    textAlign: "right"
  }
});

export default getCustomStyles;
