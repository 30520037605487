import React from "react";
import { Icon, withStyles } from "@material-ui/core";
import classNames from "classnames";

const styles = {
  icon: {
    width: "auto",
    height: "auto",
    overflow: "visible",
    verticalAlign: "middle"
  }
};

const siteIcons = {
  "facebook.com": "fab fa-facebook-square",
  "linkedin.com": "fab fa-linkedin",
  "twitter.com": "fab fa-twitter-square",
  "instagram.com": "fab fa-instagram-square"
};

/**
 * Render a FontAwesome icon for the site_name provided. It'll render nothing if
 * it's not in the internal mapping list of site names.
 *
 * @param {object} props
 * @param {string} props.site Site Name as provided by CERTN
 */
export const SiteIconComponent = ({ classes, site, ...props }) => {
  const iconClass = siteIcons[site];

  if (iconClass) {
    return <Icon className={classNames(classes.icon, iconClass)} {...props} />;
  } else {
    return null;
  }
};

export const SiteIcon = withStyles(styles)(SiteIconComponent);
