import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import Warning from "material-ui/svg-icons/alert/error-outline";
import Highlighted from "../Highlighted";
import { noticeWarning } from "../../styles/constants";
import DetailDescription from "./detail-description";
import DetailContainer from "./detail-container";
import messages from "./messages";
import { SearchHighlighted } from "../SearchHighlighted";
import { styles } from "./styles";
import { DescriptionFooter } from "./styled";

class DetailItem extends PureComponent {
  static defaultProps = {
    containerStyle: {},
    labelIcon: null,
    labelStyle: {},
    disableRow: false,
    descriptionStyle: {},
    enableValueAction: false
  };
  renderDescription() {
    if (!this.props.detail.value)
      return <FormattedMessage {...messages.notProvided} />;
    if (this.props.detail.renderer)
      return this.props.detail.renderer(this.props.detail.value);
    return (
      <div
        onClick={() => this.props.onValueAction && this.props.onValueAction()}
      >
        <SearchHighlighted
          style={this.props.enableValueAction ? styles.actionableValue : null}
          textToHighlight={this.props.detail.value}
        />
      </div>
    );
  }
  renderDescriptionFooter() {
    if (this.props.detail.valueDetail)
      return (
        <DescriptionFooter>{this.props.detail.valueDetail}</DescriptionFooter>
      );
  }
  renderLabel() {
    if (this.props.detail.value) return this.props.detail.label;
    return (
      <div>
        {this.props.detail.label} <Warning color={noticeWarning} />
      </div>
    );
  }
  renderLabelIcon() {
    if (this.props.labelIcon) return this.props.labelIcon;
  }
  render() {
    if (!this.props.detail) return null;
    return this.props.detail.label ? (
      <DetailContainer
        width={this.props.width}
        display="block"
        style={this.props.containerStyle}
        disableDetail={this.props.disableRow}
      >
        <dt style={this.props.labelStyle}>
          <Highlighted warning={!this.props.detail.value}>
            {this.renderLabel()}
          </Highlighted>
          {this.renderLabelIcon()}
        </dt>
        <DetailDescription
          id={`detailItem${this.props.detail.label}`.replace(" ", "")}
          style={this.props.descriptionStyle}
        >
          {this.renderDescription()}
          {this.renderDescriptionFooter()}
        </DetailDescription>
      </DetailContainer>
    ) : null;
  }
}

DetailItem.propTypes = {
  detail: PropTypes.object,
  containerStyle: PropTypes.object,
  labelIcon: PropTypes.object,
  labelStyle: PropTypes.object,
  disableRow: PropTypes.bool,
  descriptionStyle: PropTypes.object,
  width: PropTypes.string,
  enableValueAction: PropTypes.bool,
  onValueAction: PropTypes.func
};

export default DetailItem;
