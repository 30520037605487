import styled from "styled-components";

const Drawer = styled.div`
  display: ${props => (props.open ? "block" : "none")};
  @media print {
    display: block;
  }
`;

export default Drawer;
