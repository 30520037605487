import styled from "styled-components";

const Panel = styled.div`
  display: block;
  background: ${({ background }) => (background ? background : "white")};
  padding: ${props => props.padding || "10px 5% 50px 5%"};
  margin: ${props => props.margin || 0};
  page-break-inside: ${props => (props.allowPagebreak ? "auto" : "avoid")};
  position: relative;
  border-radius: ${props => props.borderRadius || "10px"};
  overflow: ${props => (props.overflow ? props.overflow : "")} @media
    (max-width: 1100px) {
    margin: ${props => props.marginLM};
  }
  box-shadow: ${props =>
    props.shadow ? "1px 1px 3px rgba(0, 0, 0, 0.13);" : "none"};
`;

export default Panel;
