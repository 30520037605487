import React, { Component } from "react";
import PropTypes from "prop-types";
import { getStatus } from "turn-shared";

import { slateGray } from "../../../styles/constants";
import Text from "../../../components/Text";
import Badge from "../../../components/Badge";
import { observer } from "mobx-react";

import { WorkerStyles } from "../../../styles";
import ReactTooltip from "react-tooltip";

const styles = WorkerStyles.checks.rechecksTabs.tab;

class Tab extends Component {
  static propTypes = {
    key: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    recheck: PropTypes.object.isRequired,
    adverse_action: PropTypes.bool
  };

  getStatusWithForRecheck(status, adverse_action) {
    if (status == "pending__first_notice" && Boolean(adverse_action)) {
      return "First Notice";
    }

    if (status == "pending__first_notice" && Boolean(adverse_action) == false) {
      return "Rejected";
    }

    if (
      status == "review__so" ||
      status == "review__identity" ||
      status == "review__cm" ||
      status == "review__qa"
    ) {
      return "Compliance Review";
    }

    return status;
  }

  render() {
    const {
      onClick,
      selected,
      key,
      title,
      status,
      adverse_action
    } = this.props;

    return (
      <div
        key={key}
        style={selected ? styles.selected.container : styles.container}
        onClick={onClick}
      >
        <Text color={slateGray} size="14px" weight="bold" display="block">
          {title}
          <Badge
            status={getStatus(
              this.getStatusWithForRecheck(status, adverse_action)
            )}
            style={styles.badge}
            height="auto"
            display="inline-flex"
            fontSize="12px"
            hideIcon
            disableElipses
            data-tip
            data-for={status === "emailed" && `emailed-${key}`}
            id={status === "emailed" && `emailed-${key}`}
          />
          {status === "emailed" && (
            <ReactTooltip
              target={`emailed-${key}`}
              id={`emailed-${key}`}
              place={"top"}
              effect="solid"
              className="emailed-tooltip"
            >
              This state requires consent
            </ReactTooltip>
          )}
        </Text>
      </div>
    );
  }
}

export default observer(Tab);
