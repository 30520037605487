import React, { Component } from "react";
import { reaction } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { turnAPI, ga } from "../../services";
import ErrorDialog from "../../components/ErrorDialog";
import WorkersContainer from "../WorkersContainer";
import workers from "../../models/workers";
import member from "../../models/team-member";
import Page from "../../components/Page";
import { PageType, PageEvent } from "../../services/analytics/gtm";
import teamMember from "../../models/team-member";

const WorkersPage = observer(
  class WorkersPage extends Component {
    static propTypes = {
      auth: PropTypes.object,
      router: PropTypes.object,
      history: PropTypes.object
    };

    static contextTypes = {
      router: PropTypes.object
    };

    constructor(props) {
      super(props);
      this.state = {
        errorOpen: false,
        timeout: null
      };
    }

    UNSAFE_componentWillMount() {
      this.auth = this.props.auth;
      if (!member.id) member.retrieveMember();
    }

    async componentDidMount() {
      if (!teamMember.showWorkerList) return window.location.replace("/home");
      this.getWorkers();
      ga.send(PageType.WorkersPage, PageEvent.pageLoaded);
    }

    closeError = () => this.setState({ errorOpen: false });

    debounceSearch = () => {
      clearTimeout(this.state.timeout);
      this.setState({
        timeout: this.generateSearchTimeout()
      });
    };

    generateSearchTimeout = () => setTimeout(this.getWorkers, 300);

    applyFilter = reaction(
      () => workers.status,
      () => this.debounceSearch()
    );
    applySearch = reaction(
      () => workers.search,
      () => this.debounceSearch()
    );

    setLimit = limit => {
      workers.setLimit(limit);
      this.getWorkers();
    };

    getWorkers = async () => {
      workers.setReady(false);
      await workers.retrieveParams();
      const response = await turnAPI.getWorkers(workers.params);
      this.handleResponse(response);
    };

    handleResponse = response => {
      switch (response.status) {
        case 200:
          workers.setPayload(response.data);
          break;
        case 307:
          this.auth.permission();
          break;
        case 401:
          this.auth.logout();
          break;
        default:
          this.setState({
            errorOpen: true
          });
          break;
      }
    };

    render() {
      const { auth, getWorkers } = this;
      const { searchOpen, errorOpen } = this.state;

      return (
        <Page
          sideBarProps={{
            auth: auth,
            model: workers,
            member: member,
            fetch: getWorkers,
            history: this.props.history
          }}
          appHeaderProps={{
            auth: auth,
            fullwidth: searchOpen,
            model: workers,
            fetch: getWorkers,
            history: this.props.history
          }}
          modal={<ErrorDialog open={errorOpen} onPress={this.closeError} />}
        >
          <WorkersContainer
            fetch={getWorkers}
            workers={workers}
            setLimit={this.setLimit}
            adverseAction={workers.adverseAction}
            model={workers}
            member={member}
            history={this.props.history}
          />
        </Page>
      );
    }
  }
);

export default WorkersPage;
