import styled from "styled-components";

const UploadZone = styled.div`
  width: 376px;
  height: 252px;
  border: 2px dashed #ebebed;
  box-sizing: border-box;
  border-radius: 4.2413px;
  font-family: "Lato";
`;

export default UploadZone;
