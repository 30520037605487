import { backgroundGray, primary } from "../../../styles/constants";

export const rejectedCandidateStyles = {
  buttonStyles: {
    display: "inline-block",
    margin: "0 0 0 30px",
    textDecoration: "none",
    fontWeight: 300,
    cursor: "pointer",
    userSelect: "none"
  },
  dividerStyles: {
    height: "1px",
    width: "100%",
    margin: "0 auto",
    marginBottom: "10px"
  },
  reasonsStyles: {
    title: {
      margin: "5px 0px 10px",
      fontSize: "14px",
      color: "#808080",
      display: "block"
    },
    container: {
      padding: "20px 0px"
    },
    reasons: {
      background: backgroundGray,
      margin: 0,
      padding: "10px 15px"
    }
  },
  notesStyles: {
    noteContainer: {
      position: "relative",
      padding: "10px",
      backgroundColor: "#F5F5FA",
      borderRadius: "8px"
    },
    divider: {
      position: "absolute",
      margin: 0,
      bottom: 0,
      width: "100%"
    },
    titleDivider: {
      position: "relative",
      width: "100%",
      left: "0px",
      backgroundColor: "#BDBDBD",
      height: "1px"
    },
    notes: {
      margin: "5px 0px 10px",
      fontSize: "16px",
      color: "#BDBDBD",
      display: "block",
      marginTop: "2em"
    },
    addNote: {
      fontSize: "14px",
      color: primary,
      display: "block",
      margin: "2em 0 1em"
    },
    input: {
      borderRadius: "10px",
      "&::placeholder": {
        fontSize: "10px !important"
      }
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end"
    },
    button: {
      marginTop: "1em",
      borderRadius: "10px",
      fontSize: "14px",
      textTransform: "none"
    },
    note: {
      fontSize: "14px",
      fontWeight: "normal",
      position: "relative",
      padding: "5px 0px 10px",
      margin: 0
    },
    user: {
      display: "flex",
      justifyContent: "end",
      fontSize: "12px",
      color: "#A4A4A4"
    }
  },
  headerStyles: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    cursor: "pointer",
    paddingRight: 0,
    paddingLeft: 0
  }
};
