import { Builder } from "../record-helper";

export const parseLicense = data => {
  const titles = ["type", "statuses", "code"];
  const builder = new Builder(data, { titles });

  builder.assignList(["license_number", "type", "state"]);
  builder.assignNested("statuses", "code", { out: "status" });
  builder.assign("exp_date");
  builder.assign("issue_date");
  builder.assign("original_issue");
  builder.assignNested(
    "classes",
    [
      ["code", { out: "class_code" }],
      ["description", { out: "code_description" }]
    ],
    { titles: ["description"] }
  );
  builder.assignNested(
    "endorsements",
    [
      ["code", { out: "endorsement_code" }],
      ["description", { out: "endorsement_description" }],
      ["type", { out: "endorsement_type" }]
    ],
    { titles: ["description"] }
  );
  return builder.dump();
};
