import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import Badge from "material-ui/Badge";
import { FeaturesUIStyles as style } from "../../styles/index";

const NewFeaturesUI = observer(
  class NewFeaturesUI extends Component {
    static propTypes = {
      model: PropTypes.object.isRequired
    };

    constructor(props) {
      super(props);
    }

    onFeaturesToggle = () => {
      let { model } = this.props;
      model.setFeaturesSelected(true);
      model.setAreThereNewFeatures(false);
      model.setDisableButton(true);
    };

    render() {
      return (
        <div style={style.wrapper}>
          <Badge
            primary={true}
            badgeStyle={
              this.props.model.areThereNewFeatures
                ? style.badgeStyle
                : style.withoutBadgeStyle
            }
            style={style.rootBadgeStyle}
          >
            <Button
              onClick={this.onFeaturesToggle}
              id="whats_new_button"
              key="0"
              style={style.flatButton}
              disableRipple={true}
              disabled={this.props.model.disableButton}
            >
              <i className="fas fa-bell" style={style.icon} />
            </Button>
          </Badge>
        </div>
      );
    }
  }
);

export default NewFeaturesUI;
