export const config = [
  { label: "Street", value: "address1" },
  { label: "City", value: "city" },
  { label: "Zip Code", value: "zipcode" },
  { label: "State", value: "state" }
];

export const data = [
  {
    street: "816 N Dearborn St Apt C",
    address1: "816 N Dearborn St Apt C",
    city: "Chicago",
    state: "IL",
    zip: "60609",
    date_first_seen: "03/17/1992",
    date_last_seen: "03/17/1992"
  },
  {
    street: "395 S Wabash Ave Apt 34",
    address1: "816 N Dearborn St Apt C",
    city: "Chicago",
    state: "IL",
    zip: "60203",
    date_first_seen: "03/17/1992",
    date_last_seen: "03/17/1992"
  },
  {
    street: "816 N Deaborn  St Apt C",
    address1: "816 N Dearborn St Apt C",
    city: "Chicago",
    state: "IL",
    zip: "60204",
    date_first_seen: "03/17/1992",
    date_last_seen: "03/17/1992"
  }
];
