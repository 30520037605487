import { defineMessages } from "react-intl";

export default defineMessages({
  page: {
    id: "app.components.CandidateTiles.page",
    defaultMessage: "Page"
  },
  of: {
    id: "app.components.CandidateTiles.of",
    defaultMessage: "of"
  },
  rows: {
    id: "app.components.CandidateTiles.rows",
    defaultMessage: "Rows"
  },
  showRows: {
    id: "app.components.CandidateTiles.showRows",
    defaultMessage: "Show"
  }
});
