import styled from "styled-components";

const DetailContainer = styled.div`
  display: ${({ display }) => (display ? display : "inline-block")};
  float: ${({ inline }) => (inline ? "left" : "none")};
  clear: ${({ inline }) => (inline ? "left" : "none")};
  vertical-align: top;
  width: ${({ width }) => width || "33%"};
  padding: ${({ padding, inline }) => {
    if (padding) return padding;
    if (inline) return "8px 6%";
    return "8px 15px";
  }};

  ${({ disableDetail }) =>
    disableDetail &&
    `
    opacity: 0.45;
  `}

  @media not print {
    @media (max-width: 800px) {
      display: ${props => props.displayMD || "block"};
      width: ${props => props.widthMD || "100%"};
    }
  }
  @media (max-width: 600px) {
    display: ${props => props.displaySM || "block"};
    width: ${props => props.widthSM || "100%"};
  }
`;

export default DetailContainer;
