import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import _ from "lodash";
import { WorkerStyles } from "../../../styles";

import Tab from "./Tab";

const styles = WorkerStyles.checks.rechecksTabs;

class RechecksTabs extends Component {
  static propTypes = {
    worker: PropTypes.object.isRequired
  };

  state = {
    selected: false
  };

  setSelected = selected => this.setState({ selected });
  isSelected = id => this.state.selected === id;

  handleTabClick = ({
    data: _data = {},
    ssn,
    notes,
    id,
    shouldReset = false,
    current_address,
    address_records
  }) => {
    const data = { ..._data, ssn, notes, current_address, address_records };
    const {
      worker: {
        setRecheck,
        resetChecks,
        setLoadingRecheck,
        setReadOnly,
        setSelectedTabId
      }
    } = this.props;

    setLoadingRecheck(true);
    setSelectedTabId(id);
    // Delay used to have loadingRecheck as true,
    // renders a loader in WorkerContainer
    _.delay(() => {
      if (shouldReset) {
        setReadOnly(false);
        resetChecks();
      } else {
        setReadOnly(true);
        setRecheck(data);
      }
      this.setSelected(id);
      setLoadingRecheck(false);
    }, 300);
  };

  // Set the selected tab once the component is mounted
  componentDidMount() {
    if (!this.props.worker) return;
    const {
      worker: { rechecks, onBoardingStatus }
    } = this.props;

    if (rechecks && rechecks.length > 0 && onBoardingStatus === "emailed") {
      this.handleTabClick(rechecks[0]);
    }
  }

  renderTabs = () => {
    const {
      worker: { onBoardingStatus, rechecks, checkDate }
    } = this.props;
    const isEmailed = onBoardingStatus === "emailed";
    const hasPreviousChecks = rechecks.length > 0;

    return (
      <>
        {hasPreviousChecks && (
          <Tab
            title={checkDate}
            status={onBoardingStatus}
            onClick={() =>
              isEmailed
                ? null
                : this.handleTabClick({ id: false, shouldReset: true })
            }
            selected={isEmailed ? false : this.isSelected(false)}
          />
        )}
        {rechecks.map(recheck =>
          !recheck ? null : (
            <Tab
              title={`${recheck.date}`}
              key={recheck.id}
              status={recheck.status}
              onClick={() => this.handleTabClick(recheck)}
              selected={this.isSelected(recheck.id)}
              adverse_action={this.props.worker.worker.adverse_action}
            />
          )
        )}
      </>
    );
  };

  render() {
    let hasPreviousChecks = false;

    if (this.props.worker) {
      const {
        worker: { checkStatus, onBoardingStatus }
      } = this.props;

      const restrictedStatuses = ["approved", "second_notice", "rejected"];

      hasPreviousChecks =
        (checkStatus === "clear" &&
          !restrictedStatuses.find(item => item === onBoardingStatus)) ||
        onBoardingStatus === "emailed" ||
        (this.props.worker.rechecks && this.props.worker.rechecks.length > 0);
    }

    if (
      !this.props.worker ||
      !hasPreviousChecks ||
      this.props.worker.canadianReport
    )
      return null;

    return <div style={styles.container}>{this.renderTabs()}</div>;
  }
}

export default observer(RechecksTabs);
