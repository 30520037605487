import React from "react";
import env from "@mars/heroku-js-runtime-env";

import { pdLocalstorageItemName } from "../../constants";

/**
 * When REACT_APP_ACTIVE_MIGRATION_REDIRECT isn't set to true, the component will
 * render the passed on container.
 *
 * When REACT_APP_ACTIVE_MIGRATION_REDIRECT is set to true, the component will reload
 * once, on a second attempt it will redirect to home. This is required because of the
 * current nginx configuration.
 *
 * pdLocalstorageItemName is cleared in the recuiter dashboard on load
 * rdLocalstorageItemName is cleared in the partner dashboard on load
 *
 * @param {Node} container existing container used when migration isn't active
 */
const MigratedRoute = ({ container: Container, ...rest }) => {
  const props = rest || {};

  if (env().REACT_APP_ACTIVE_MIGRATION_REDIRECT !== "true")
    return <Container {...props} />;

  React.useEffect(() => {
    const tried = localStorage.getItem(pdLocalstorageItemName) === "true";

    if (!tried) {
      localStorage.setItem(pdLocalstorageItemName, "true");
      window.location.reload();
      return;
    }

    localStorage.setItem(pdLocalstorageItemName, "false");
    window.location.replace("/");
  }, []);

  return null;
};

export default MigratedRoute;
