import React from "react";
import { Select, withStyles } from "@material-ui/core";
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";

const styles = {
  root: {
    verticalAlign: "middle",
    borderRadius: 25,
    marginLeft: -16,
    marginRight: -16
  },
  select: {
    fontSize: "12px",
    fontWeight: "bold",
    padding: "10px 20px",
    paddingRight: "40px",
    textAlign: "center",
    color: "#5f7d95",
    "&:focus": {
      background: "transparent"
    }
  }
};

const DropDownIcon = ({ className, ...props }) => {
  return (
    <Icon
      className={classNames(className, "fas fa-chevron-down")}
      style={{ top: "calc(50% - 4px)", fontSize: "12px", right: "20px" }}
      {...props}
    />
  );
};

const WorkerFilterDropdownComponent = _props => {
  const { classes, children, ...props } = _props;

  return (
    <Select
      disableUnderline
      IconComponent={DropDownIcon}
      classes={{
        root: classes.root,
        select: classes.select
      }}
      {...props}
    >
      {children}
    </Select>
  );
};

export const WorkerFilterDropdown = withStyles(styles)(
  WorkerFilterDropdownComponent
);
