import React, { useState, useEffect } from "react";
import {
  Paper,
  FormLabel,
  TextField,
  Icon,
  Typography,
  withStyles
} from "@material-ui/core";
import { Observer } from "mobx-react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _ from "lodash";

import LandingPageCanadaModel from "../../../models/landing-page-canada";

import { AutocompleteSelect } from "../../Autocomplete";
import { Button } from "../../Button";
import { DateInput } from "../../DateSelect";
import Column from "../../Column";
import { observer } from "mobx-react";
import { tartOrange } from "../../../styles/constants";

/**
 * @augments Paper
 */
const ConvictionPaperComponent = ({ classes = {}, ...props }) => {
  // Set a state to force rendering twice, first without `rootMounted` class and then with it
  // to allow for CSS animation transition to kick in
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setImmediate(() => {
      setMounted(true);
    });
  }, []);

  const paperClass = classNames(classes.root, mounted && classes.rootMounted);
  return <Paper className={paperClass} {...props} />;
};
ConvictionPaperComponent.propTypes = Paper.propTypes;

/**
 * `ConvictionPaper` contains the form inputs to render each Conviction entry. This wrapper around MUI's
 * `Paper` component allows for on-mount animation for a smoother experience.
 *
 * @augments ConvictionPaperComponent
 */
export const ConvictionPaper = withStyles({
  root: {
    padding: 20,
    transition: "max-height 0.4s, opacity 0.4s",
    maxHeight: 0,
    opacity: 0
  },
  rootMounted: {
    maxHeight: 500,
    opacity: 1
  }
})(ConvictionPaperComponent);
ConvictionPaper.propTypes = ConvictionPaperComponent.propTypes;

export const ConvictionCancelButton = ({ onClick, title }) =>
  onClick ? (
    <div style={{ textAlign: "center" }}>
      <Button style={{ color: tartOrange }} onClick={onClick}>
        {title}
      </Button>
    </div>
  ) : null;
ConvictionCancelButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string
};

/**
 * Renders a Conviction input box to let the user enter a conviction's information. This component
 * **MUST be controlled** by it's implementor, values are not stored/updated on their own.
 *
 * @param options Array of {label, value} objects of Convictions to display on the list
 * @param onChangeCharge Callback called when the `charge` is changed
 * @param charge Selected `charge`, must be reference equal to one of `options[]`
 * @param onChangeDate Callback called when the `date` is changed
 * @param date Selected `date`
 * @param onChangeCourt Callback called when the `court` is changed
 * @param court Written `court` string
 * @param onRemove (Optional) Callback called when Remove button is clicked, will not render the button if not provided
 *
 * @augments ConvictionPaper
 */
export const ConvictionInput = ({
  errors = {},
  options,
  onChangeCharge,
  charge,
  onChangeDate,
  date,
  onChangeCourt,
  court,
  onRemove,
  ...props
}) => {
  const [touched, setTouched] = useState({});
  const _touch = fieldName => {
    if (!touched[fieldName]) {
      setTouched({
        ...touched,
        [fieldName]: true
      });
    }
  };
  const _onChangeCharge = e => {
    _touch("charge");
    onChangeCharge && onChangeCharge(e && e.value);
  };
  const _onChangeDate = date => {
    const { year, month, day } = date;
    if (year || month || day) {
      _touch("date_of_sentence");
    }

    onChangeDate && onChangeDate(date);
  };
  const _onChangeCourt = ({ target }) => {
    _touch("court_location");
    onChangeCourt && onChangeCourt(target && target.value);
  };

  return (
    <ConvictionPaper elevation={1} {...props}>
      <Column width={"100%"} padding={"10px"} verticalAlign="top">
        <FormLabel>Conviction Offense</FormLabel>
        <AutocompleteSelect
          options={options}
          onChange={_onChangeCharge}
          value={charge}
          placeholder="Start typing to search..."
        />
        {_.get(errors, "offense") && touched.charge && (
          <Typography style={{ color: tartOrange }}>
            {errors.offense}
          </Typography>
        )}
      </Column>

      <div>
        <Column
          width={"50%"}
          widthSM="100%"
          padding={"10px"}
          verticalAlign="top"
        >
          <FormLabel>Date of Sentence</FormLabel>
          <DateInput onChange={_onChangeDate} value={date} />
          {_.get(errors, "date_of_sentence") && touched.date_of_sentence && (
            <Typography style={{ color: tartOrange }}>
              {errors.date_of_sentence}
            </Typography>
          )}
        </Column>
        <Column
          width={"50%"}
          widthSM="100%"
          padding={"10px"}
          verticalAlign="top"
        >
          <FormLabel>Court Location</FormLabel>
          <TextField
            autoComplete="nope"
            style={{ width: "100%" }}
            onChange={_onChangeCourt}
            value={court}
          />
          {_.get(errors, "court_location") && touched.court_location && (
            <Typography style={{ color: tartOrange }}>
              {errors.court_location}
            </Typography>
          )}
        </Column>
      </div>

      <ConvictionCancelButton onClick={onRemove} title="Remove" />
    </ConvictionPaper>
  );
};
ConvictionInput.propTypes = {
  options: AutocompleteSelect.propTypes.options,
  charge: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  onChangeCharge: PropTypes.func,
  date: DateInput.propTypes.value,
  onChangeDate: PropTypes.func,
  court: PropTypes.string,
  onChangeCourt: PropTypes.func
};

/**
 * Render the Convictions Input boxes, controlled by `LandingPageCanadaModel`.
 *
 * @param {LandingPageCanadaModel} model
 */
export class ConvictionsFormComponent extends React.Component {
  static propTypes = {
    model: PropTypes.instanceOf(LandingPageCanadaModel)
  };

  state = {
    renders: 0
  };

  get options() {
    const { model } = this.props;

    return _(model.charges || {})
      .flatMap()
      .map(({ code, description }) => ({
        value: code,
        label: description
      }))
      .sortBy(charge => charge.label)
      .value();
  }

  updateConviction(index, data = {}) {
    const { model } = this.props;

    const convictions = model.convictions || [];

    // "Upsert" the index field value
    convictions[index] = {
      ...(convictions[index] || {}),
      ...data
    };

    // Store into model
    model.setConvictions([...convictions]);
    model.validateConvictions();
  }

  removeConviction(index) {
    const { model } = this.props;

    const currentConvictions = model.convictions || [];

    // We need to re-construct the Array in this funny manner
    // as MobX doesn't seem to like using vanilla js manipulations of the array
    const newConvictions = [
      ..._.without(currentConvictions, currentConvictions[index])
    ];

    const convictions = _.isEmpty(newConvictions) ? [{}] : newConvictions;
    model.setConvictions(convictions);

    model.clearConvictionsErrors();
    model.validateConvictions();
  }

  addConviction() {
    const { model } = this.props;

    const convictions = model.convictions || [];
    model.setConvictions([...convictions, {}]);
  }

  renderConvictionInput(conviction, index) {
    // Re-map `model.convictions[].offense` to `options` item
    // so it properly renders the selected item in <ConvictionInput />
    const selectedConviction = _.find(
      this.options,
      option => option.value === conviction.offense
    );

    return (
      <ConvictionInput
        key={index}
        style={{ marginBottom: 10 }}
        errors={this.props.model.convictionErrors[index]}
        options={this.options}
        charge={selectedConviction || null}
        onChangeCharge={value =>
          this.updateConviction(index, { offense: value })
        }
        date={conviction.date_of_sentence}
        onChangeDate={value =>
          this.updateConviction(index, { date_of_sentence: value })
        }
        court={conviction.court_location || ""}
        onChangeCourt={value =>
          this.updateConviction(index, { court_location: value })
        }
        onRemove={() => this.removeConviction(index)}
      />
    );
  }

  render() {
    const { props } = this;
    const { model } = props;

    if (!model.convictions || model.convictions.length < 1) {
      model.setConvictions([{}]);
    }

    return (
      <Observer>
        {() => (
          <div {...props}>
            {_.map(model.convictions, this.renderConvictionInput.bind(this))}

            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                name="addConviction"
                color="secondary"
                onClick={() => this.addConviction()}
              >
                <Icon
                  className={"fas fa-plus"}
                  style={{ fontSize: "initial" }}
                />
                &nbsp; Add conviction
              </Button>
            </div>
          </div>
        )}
      </Observer>
    );
  }
}

export const ConvictionsForm = observer(ConvictionsFormComponent);
ConvictionsForm.propTypes = ConvictionsFormComponent.propTypes;
