import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "./messages";
import { Container, Header, Footer } from "../";
import { Option } from "./Option";
import { observer } from "mobx-react";

export const CardForm = observer(
  class CardForm extends React.Component {
    static propTypes = {
      value: PropTypes.string,
      handleChange: PropTypes.func,
      handleKeyDown: PropTypes.func,
      handleSubmit: PropTypes.func,
      toggleOptions: PropTypes.func,
      error: PropTypes.bool,
      errorMessage: PropTypes.string
    };

    render() {
      return (
        <Container align="center">
          <Header
            title={<FormattedMessage {...messages.title} />}
            subtitle={<FormattedMessage {...messages.subtitle} />}
          />
          <Option
            label={<FormattedMessage {...messages[`${this.props.value}`]} />}
            hint={
              <FormattedMessage {...messages[`${this.props.value}_hint`]} />
            }
            handleChange={this.props.handleChange}
            handleKeyDown={this.props.handleKeyDown}
            error={this.props.error}
            errorMessage={this.props.errorMessage}
          />
          <Footer
            label={<FormattedMessage {...messages.dontRemember} />}
            hint={<FormattedMessage {...messages.changeCredentials} />}
            onClick={this.props.toggleOptions}
            handleSubmit={this.props.handleSubmit}
            disabled={this.props.error}
          />
        </Container>
      );
    }
  }
);
