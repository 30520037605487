import React, { Component } from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";
import Title from "../Title";
import styles from "./styles.js";

import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import Panel from "../Panel";
import opacityColor from "../../utils/opacity-color";
import HidePrint from "../Responsive/hide-print";
import ShowPrint from "../Responsive/show-print";
import ExpandAll from "../ExpandAll";
import { SearchHighlighted } from "../SearchHighlighted";
import withContext from "../../contextAPI/context-HOC";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Divider from "material-ui/Divider";

/**
 * Renders a <Panel /> which expands showing its content.
 *
 * @param {object} props
 * @param {string} props.color Color to use in panel title, subtitle and background color 0.6 of opacity
 * @param {object} props.panelProps (Optional) Properties passed to <Panel /> component
 * @param {object} props.contentContainerStyle (Optional) Style passed to <div /> which wraps <AnimateHeight /> content
 * @param {object} props.contentContentStyle (Optional) Style passed to <div /> which wraps <Panel /> content
 * @param {bool} props.collapsible (Optional) Disables collapsible functionality when false
 * @param {bool} props.collapsedInvert (Optional) Is expanded showing content on render
 * @param {bool} props.collapsibleIcon (Optional) Icon passed to <FontAwesomeIcon />
 * @param {bool} props.noPrint (Optional) Renders component without <HidePrint /> and <ShowPrint />
 * @param {ReactNode} props.children Content to be rendered when panel is expanded
 * @param {bool} props.collapsed (Optional) controlls collapsed state of component
 * @param {number} props.collapsedContentHeight (Optional) height of the collapsed content
 * @param {object} props.setCollapsed when props > collapsed is passed this function is called instead of component
 *
 * @param {(object|ReactNode)} props.title Object passed to <FormattedMessage />, or title to display
 * @param {object} props.titleStyle (Optional) Style passed to <Title /> component in renderTitle
 * @param {object} props.icon (Optional) Icon passed to <FontAwesomeIcon /> rendered next to title
 * @param {object} props.iconStyle (Optional) Style passed to <FontAwesomeIcon />
 *
 * @param {object} props.subtitle (Optional) Object passed to <FormattedMessage />
 * @param {object} props.subtitleStyle (Optional) Style passed to <Title /> component in renderSubtitle
 * @param {object} props.subIcon (Optional) Icon passed to <FontAwesomeIcon /> rendered next to subtitle
 * @param {object} props.subIconStyle (Optional) Style passed to <FontAwesomeIcon />
 *
 * @param {bool} props.expandAll (Optional) Renders <ExpandAll />
 * @param {object} props.expandAllProps Properties passed to <ExpandAll />
 * @param {object} props.collapsibleIconStyle (Optional) Style passed to <FontAwesomeIcon />
 *
 * @param {func} props.onCollapseStart (Optional) Called when handleCollapseStart is called
 * @param {func} props.onCollapseEnd (Optional) Called when handleCollapseEnd is called
 * @param {func} props.onToggleCollapse (Optional) Called when toggleCollapse is called
 * @param {func} props.setExpand (Optional) Called when props.expandAll is updated
 */

// TODO: Remove props.titleComponent as it does the same as props.title
// TODO: Change props.collapsedInvert for props.expandedOnRender

export class PanelCollapsible extends Component {
  static propTypes = {
    color: PropTypes.string,
    panelProps: PropTypes.object,
    contentContainerStyle: PropTypes.object,
    contentContentStyle: PropTypes.object,
    collapsible: PropTypes.bool,
    collapsedInvert: PropTypes.bool,
    noPrint: PropTypes.bool,
    collapsed: PropTypes.bool,
    headerDivider: PropTypes.bool,
    setCollapsed: PropTypes.func,

    title: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
    titleStyle: PropTypes.object,
    icon: PropTypes.object,
    iconStyle: PropTypes.object,

    subtitle: PropTypes.object,
    subtitleStyle: PropTypes.object,
    subIcon: PropTypes.object,
    subIconStyle: PropTypes.object,

    expandAll: PropTypes.bool,
    expandAllProps: PropTypes.object,
    collapsibleIconStyle: PropTypes.object,

    onCollapseStart: PropTypes.func,
    onCollapseEnd: PropTypes.func,
    onToggleCollapse: PropTypes.func,
    setExpand: PropTypes.func,
    collapsedContentHeight: PropTypes.number
  };

  static defaultProps = {
    titleStyle: {},
    iconStyle: {},
    subtitleStyle: {},
    subIconStyle: {},
    panelProps: {},
    contentContainerStyle: {},
    contentContentStyle: {},
    collapsibleIconStyle: {},
    collapsible: true,
    headerDivider: false
  };

  state = {
    collapsed: false
  };

  get collapsed() {
    if (_.isBoolean(this.props.collapsed)) {
      return this.props.collapsed;
    }
    return this.state.collapsed;
  }

  componentDidUpdate(prevProps) {
    if (
      _.isBoolean(this.props.expandAllChecks) &&
      prevProps.expandAllChecks !== this.props.expandAllChecks
    ) {
      this.setCollapsed(this.props.expandAllChecks);
      if (this.props.setExpand) {
        this.props.setExpand(this.props.expandAllChecks);
      }
    }
  }

  toggleCollapsed = () => {
    if (!this.props.collapsible) return;

    if (_.isFunction(this.props.onToggleCollapse))
      this.props.onToggleCollapse();
    if (
      _.isBoolean(this.props.collapsed) &&
      _.isFunction(this.props.setCollapsed)
    )
      this.props.setCollapsed(!this.props.collapsed);
    else this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  };

  setCollapsed = _collapsed => {
    if (!_.isBoolean(_collapsed)) return;
    if (
      _.isBoolean(this.props.collapsed) &&
      _.isFunction(this.props.setCollapsed)
    )
      this.props.setCollapsed(_collapsed);
    else this.setState({ collapsed: _collapsed });
  };

  renderTitle = () => {
    if (this.props.titleComponent) return this.props.titleComponent;
    if (!this.props.title) return "";
    if (_.isObject(this.props))
      return <FormattedMessage {...this.props.title} />;
    return this.props.title;
  };

  renderSubtitle = () => {
    if (!this.props.subtitle) return null;
    return (
      <Title
        color={this.props.color}
        style={{ ...this.props.subtitleStyle, ...styles.title }}
      >
        <FontAwesomeIcon
          icon={this.props.subIcon}
          style={this.props.subIconStyle}
        />
        <FormattedMessage {...this.props.subtitle} />;
      </Title>
    );
  };

  renderCollapsibleIcon = () => {
    if (this.props.collapsible === false) return null;
    if (this.props.collapsedInvert === true) {
      return (
        <FontAwesomeIcon
          icon={faChevronUp}
          style={{
            ...styles.collapsibleIcon,
            ...this.props.collapsibleIconStyle,
            transform: this.collapsed && "rotate(180deg)",
            transition: "all 300ms ease-in-out"
          }}
        />
      );
    }
    return (
      <FontAwesomeIcon
        icon={faChevronDown}
        style={{
          ...styles.collapsibleIcon,
          ...this.props.collapsibleIconStyle,
          transform: this.collapsed && "rotate(-180deg)",
          transition: "all 300ms ease-in-out"
        }}
      />
    );
  };

  contentHeight = (shouldReturn = false) => {
    if (this.props.collapsedInvert === true && shouldReturn === false) {
      if (this.collapsed) return this.props.collapsedContentHeight || 0;
    } else {
      if (
        (!this.collapsed ||
          (this.props.collapsible === false &&
            this.props.collapsed !== true)) &&
        shouldReturn === false
      )
        return this.props.collapsedContentHeight || 0;
    }
    return "auto";
  };

  renderExpandAll = () => {
    const { expandAll, expandAllProps } = this.props;

    return !expandAll ||
      _.isEmpty(expandAllProps) ||
      !_.isObject(expandAllProps) ? null : (
      <HidePrint>
        <ExpandAll {...expandAllProps} />
      </HidePrint>
    );
  };

  renderContent = (shouldReturn = false) => {
    return (
      <AnimateHeight
        height={this.contentHeight(shouldReturn)}
        onAnimationEnd={this.handleCollapseEnd}
        onAnimationStart={this.handleCollapseStart}
      >
        <div style={this.contentStyle}>
          {this.renderExpandAll()}
          {this.props.children}
        </div>
      </AnimateHeight>
    );
  };

  handleCollapseStart = () => {
    if (_.isFunction(this.props.onCollapseStart)) this.props.onCollapseStart();
    this.setState({ collapsing: true });
  };

  handleCollapseEnd = () => {
    if (_.isFunction(this.props.onCollapseEnd)) this.props.onCollapseEnd();
    this.setState({ collapsing: false });
  };

  get contentStyle() {
    let style = styles.contentContainer;
    if (this.props.contentContainerStyle) {
      style = { ...style, ...this.props.contentContainerStyle };
    }
    if (this.props.expandAll) {
      style = { ...style, paddingTop: "50px" };
    }

    return style;
  }

  render() {
    const style =
      this.props.collapsible === false
        ? styles.headerContainer
        : this.collapsed || this.props.collapsedInvert === true
        ? styles.headerContainerOpen
        : styles.headerContainer;

    if (this.props.noPrint) {
      return (
        <Panel
          margin="0px 0px 24px 0px"
          padding="0px" //"10px 5%"
          overflow="hidden"
          {...this.props.panelProps}
        >
          <div
            style={{
              ...style,
              backgroundColor: opacityColor(this.props.color, "0.15"),
              ...this.props.contentContentStyle
            }}
            onClick={this.toggleCollapsed}
          >
            <Title
              color={this.props.color}
              style={{ ...styles.title, ...this.props.titleStyle }}
            >
              <FontAwesomeIcon
                icon={this.props.icon}
                style={this.props.iconStyle}
              />
              <SearchHighlighted textToHighlight={this.renderTitle()} />
            </Title>
            {this.renderSubtitle()}
            {this.renderCollapsibleIcon()}
          </div>
          {this.renderContent()}
        </Panel>
      );
    }
    return (
      <>
        <HidePrint>
          <Panel
            margin="0px 0px 24px 0px"
            padding="0px" //"10px 5%"
            overflow="hidden"
            {...this.props.panelProps}
          >
            <div
              style={{
                ...style,
                backgroundColor: opacityColor(this.props.color, "0.15"),
                ...this.props.contentContentStyle
              }}
              onClick={this.toggleCollapsed}
            >
              <Title
                color={this.props.color}
                style={{ ...this.props.titleStyle, ...styles.title }}
              >
                <FontAwesomeIcon
                  icon={this.props.icon}
                  style={{ ...this.props.iconStyle, ...styles.subIcon }}
                />
                {this.renderTitle()}
              </Title>
              {this.renderSubtitle()}
              {this.renderCollapsibleIcon()}
            </div>
            {this.props.headerDivider && !this.state.collapsed && (
              <div style={{ padding: "0 5%" }}>
                <Divider style={styles.dividerStyles} />
              </div>
            )}
            {this.renderContent()}
          </Panel>
        </HidePrint>
        <ShowPrint>
          <Panel
            margin="0px 0px 24px 0px"
            padding="0px" //"10px 5%"
            overflow="hidden"
            {...this.props.panelProps}
          >
            <div
              style={{
                ...style,
                backgroundColor: opacityColor(this.props.color, "0.15"),
                ...this.props.contentContentStyle
              }}
              onClick={this.toggleCollapsed}
            >
              <Title
                color={this.props.color}
                style={{ ...this.props.titleStyle, ...styles.title }}
              >
                <FontAwesomeIcon
                  icon={this.props.icon}
                  style={{ ...this.props.iconStyle, ...styles.subIcon }}
                />
                {this.renderTitle()}
              </Title>
              {this.renderSubtitle()}
              {this.renderCollapsibleIcon()}
            </div>
            {this.renderContent(true)}
          </Panel>
        </ShowPrint>
      </>
    );
  }
}

export default withContext(PanelCollapsible);
