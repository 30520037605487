import React from "react";
import _ from "lodash";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Label,
  Text
} from "recharts";

import { slateGray } from "../../styles/constants";

export const ScreeningResponsivePie = ({ data, onClick, ...props }) => {
  const _onClick = ({ payload }) => {
    if (onClick) {
      onClick(payload);
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          innerRadius={80}
          outerRadius={110}
          cornerRadius={50}
          startAngle={20}
          endAngle={360}
          minAngle={30}
          paddingAngle={-15}
          onClick={_onClick}
          {...props}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} stroke={null} />
          ))}
          <Label
            content={props => {
              const {
                viewBox: { cx, cy }
              } = props;
              const textProps = {
                x: cx,
                y: cy,
                textAnchor: "middle",
                verticalAnchor: "middle",
                fill: slateGray
              };

              return (
                <>
                  <Text {...textProps} fontSize={16} y={cy - 10}>
                    Total
                  </Text>
                  <Text {...textProps} fontSize={14} y={cy + 10}>
                    {_(data)
                      .map("value")
                      .sum()
                      .toLocaleString()}
                  </Text>
                </>
              );
            }}
          />
        </Pie>
        <Tooltip formatter={value => value.toLocaleString()} />
        <Legend
          iconType="circle"
          verticalAlign="bottom"
          onClick={_onClick}
          iconSize={12}
          wrapperStyle={{ fontSize: 12 }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
