import { createMuiTheme } from "@material-ui/core/styles";

import { materialThemeV1 as baseTheme } from "./turn";

export const materialThemeV1 = createMuiTheme({
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    MuiInput: {
      ...baseTheme.overrides.MuiInput,
      root: {
        ...baseTheme.overrides.MuiInput.root,
        borderRadius: "5px"
      }
    }
  }
});
