import Column from "../Column";
import { FormCheckLine } from "./components/FormCheckLine";
import { FormWizardBackForward } from "./components/FormWizardBackNext";
import { FormWizardStep } from "./components/FormWizardStep";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import React from "react";
import Title from "../Title";
import TurnScroll from "../TurnScroll";
import messages from "./messages";
import { observer } from "mobx-react";
import { LandingPageStyles as styles } from "../../styles";
import syslog from "../../services/syslog";

const propTypes = {
  ...FormWizardStep.propTypes,
  details: PropTypes.object,
  currentStep: PropTypes.number,
  rights: PropTypes.string
};

const TabStateRights = props => {
  const handleNext = () => {
    props.nextStep();
    const detailsData = { ...props.details.formData };
    detailsData["has_middle_name"] = !detailsData["has_middle_name"];
    detailsData["tab"] = "step_5_state_rights";
    syslog([detailsData]);
  };
  return (
    <div style={styles.tabContent}>
      <Title id="stateRightsStepTitle" align="left" big>
        A Summary of Your State Rights
      </Title>
      <TurnScroll
        id="stateRightsLegalDisclosure"
        dangerouslySetInnerHTML={{ __html: props.rights }}
      />
      {props.details.shouldReceiveBGCCopy ? (
        <div style={{ ...styles.agreeContainer, marginBottom: 30 }}>
          <FormCheckLine
            id="getBGCCopy"
            label={<FormattedMessage {...messages.bgccopy} />}
            checked={props.details.getBGCCopy}
            onChange={() =>
              props.details.setGetBGCCopy(!props.details.getBGCCopy)
            }
          />
        </div>
      ) : null}
      <div style={styles.agreeContainer}>
        <FormCheckLine
          id="agreeStateRights"
          label={<FormattedMessage {...messages.rights} />}
          checked={props.details.agreeStateRights}
          onChange={() =>
            props.details.setAgreeStateRights(!props.details.agreeStateRights)
          }
        />
      </div>
      <Column
        textAlign="right"
        textAlignSM="center"
        width="100%"
        margin="20px 0 0 0"
      >
        <FormWizardBackForward
          id="stateRightsTabBackForwardDiv"
          nextDisabled={!props.details.agreeStateRights}
          onBack={() => props.previousStep()}
          onNext={() => handleNext()}
        />
      </Column>
    </div>
  );
};

TabStateRights.propTypes = propTypes;
export default observer(TabStateRights);
