import React, { useState } from "react";
// { Component } import styled from "styled-components";
import Column from "../Column";
import Row from "../Row";
import messages from "./messages";
// import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { FormWizardBackForward } from "./components/FormWizardBackNext";
import UploadIdZone from "../UploadIdZone";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { ReactComponent as CloseIcon } from "../../icons/close-icon-black.svg";
import Img from "../Img";
import { ExampleImageContainer } from "./components/Base64ModalContainers";
import { Button } from "../Button";
import style from "./tab-automaticfill-styles";

function TabAutomaticFill({ callback, details, isCanadaCheck, checkId }) {
  const [frontData, setFrontData] = useState("");
  const [backData, setBackData] = useState("");
  const [faceData, setFaceData] = useState("");
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  const setResponseData = (response, side) => {
    switch (side) {
      case "Front":
        setFrontData(response.data);
        break;
      case "Back":
        setBackData(response.data);
        break;
      default:
        setFaceData(response.data);
    }
  };

  return (
    <>
      {!details.piiRetryPage && (
        <p style={{ color: "#7E7E8F", fontWeight: "normal" }}>
          Upload a picture of your valid (not expired){" "}
          <strong>
            government-issued{" "}
            {details.doMVR ? "driver's license." : "photo ID."}
          </strong>
          {isCanadaCheck
            ? " If you are uploading a photo of your passport, please upload the same image for front and back."
            : ""}
        </p>
      )}
      <Row>
        <Column
          textAlign="center"
          textAlignSM="center"
          width="45%"
          margin="20px 40px 0 0"
        >
          <UploadIdZone
            id="uploadzone"
            upload={setResponseData}
            checkId={checkId}
            details={details}
            title="Front"
            doc_type="photo_id-front"
          ></UploadIdZone>
        </Column>
        <Column
          textAlign="center"
          textAlignSM="center"
          width="45%"
          margin="20px 0 0 0"
        >
          <UploadIdZone
            upload={setResponseData}
            checkId={checkId}
            details={details}
            doc_type="photo_id-back"
            title="Back"
          ></UploadIdZone>
        </Column>
      </Row>
      {isCanadaCheck && (
        <p>
          Upload a selfie holding your photo id and clearly showing your face
          and shoulders. (Profile pictures, formal portraits, and social media
          images are not acceptable)
        </p>
      )}
      {isCanadaCheck && (
        <Row>
          <Column
            textAlign="center"
            textAlignSM="center"
            width="45%"
            margin="20px 0 0 0"
          >
            <UploadIdZone
              upload={setResponseData}
              checkId={checkId}
              details={details}
              doc_type="photo_id-face"
              title="Selfie"
            ></UploadIdZone>
          </Column>
        </Row>
      )}

      {toggleModal && (
        <Dialog
          fullWidth
          open={toggleModal}
          onClose={handleToggleModal}
          autoScrollBodyContent
          disableBackdropClick
          disableEscapeKeyDown
          style={{ scrollBehavior: "smooth" }}
        >
          <CloseIcon
            onClick={() => handleToggleModal()}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "1rem",
              top: "1rem",
              width: 10,
              height: 10
            }}
          />
          <DialogContent>
            <h3>Successfully Uploaded ID Example</h3>
            <ExampleImageContainer>
              <Img type="exampleBase64Front" />
              <Img type="exampleBase64Back" />
            </ExampleImageContainer>
          </DialogContent>
          <DialogActions style={style.buttonContainer}>
            <Button
              onClick={() => handleToggleModal()}
              style={style.turquoiseButton}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Row>
        <Column textAlign="left" textAlignSM="left" width="100%">
          <h2>
            <FormattedMessage {...messages.beforeUpload} />
          </h2>
          <p style={{ fontWeight: "bold", color: "#7E7E8F" }}>
            <FormattedMessage {...messages.followingSteps} />
          </p>
          <p style={{ color: "#7E7E8F" }}>
            <FormattedMessage {...messages.checkImage} />
          </p>
          <p style={{ marginBottom: "0px", color: "#7E7E8F" }}>
            <FormattedMessage {...messages.pictureMobile} />
          </p>

          <ul style={{ marginTop: "0px" }}>
            <li style={{ color: "#7E7E8F" }}>
              <FormattedMessage {...messages.tipsDarkBG} />
            </li>
            <li style={{ color: "#7E7E8F" }}>
              <FormattedMessage {...messages.tipsFlash} />
            </li>
            <li style={{ color: "#7E7E8F" }}>
              <FormattedMessage {...messages.tipsHorizontal} />
            </li>
            <li style={{ color: "#7E7E8F" }}>
              <FormattedMessage {...messages.tipsTilt} />
            </li>
            <li onClick={() => handleToggleModal()}>
              <a href="#" style={style.linkText}>
                <FormattedMessage {...messages.tipsExample} />
              </a>
            </li>
          </ul>
          <p style={{ color: "#7E7E8F" }}>
            <FormattedMessage {...messages.reviewName} />
          </p>
        </Column>
      </Row>

      <Row>
        <Column
          textAlign="right"
          textAlignSM="center"
          width="100%"
          margin="20px 0 0 0"
        >
          <FormWizardBackForward
            id="informationTabBackForwardDiv"
            nextDisabled={
              isCanadaCheck
                ? frontData === "" || backData === "" || faceData === ""
                : frontData === ""
            }
            nextLabel="Save and Continue"
            onNext={() => {
              const data = {
                Front: frontData,
                Back: backData
              };
              callback(data);
            }}
          />
        </Column>
      </Row>
    </>
  );
}

export default TabAutomaticFill;
