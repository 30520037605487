import React from "react";
import { PropTypes } from "prop-types";
import sources from "./sources";

function Img(props) {
  const source = props.type !== null ? sources[props.type] : props.src;

  return (
    <img
      className={props.className}
      style={props.style}
      src={source}
      alt={props.alt}
    />
  );
}

// We require the use of src and alt, only enforced by react in dev mode
Img.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.string
};

export default Img;
