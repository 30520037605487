import { primary } from "../../styles/constants";

export const workerEmailToolbarStyles = {
  container: {
    display: "flex",
    marginTop: "5px",
    justifyContent: "space-between"
  },
  detailContainerStyle: {
    padding: 0
  },
  icon: {
    fontSize: "12px",
    fill: primary
  },
  label: {
    fontSize: "12px",
    color: primary,
    fontWeight: 400,
    paddingLeft: "3px"
  }
};
