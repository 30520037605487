import { defineMessages } from "react-intl";

export default defineMessages({
  addressTitle: {
    id: "app.containers.WorkerContainer.addressTitle",
    defaultMessage: "Addresses"
  },
  educationTitle: {
    id: "app.containers.WorkerContainer.educationTitle",
    defaultMessage: "Education"
  },
  employersTitle: {
    id: "app.containers.WorkerContainer.employersTitle",
    defaultMessage: "Employers"
  },
  urlsTitle: {
    id: "app.containers.WorkerContainer.urlsTitle",
    defaultMessage: "Social Networks"
  },
  enhancedIdentity: {
    id: "app.containers.WorkerContainer.enhancedIdentity",
    defaultMessage: "Enhanced ID Verification"
  },
  buttonApprove: {
    id: "app.containers.WorkerContainer.buttonApprove",
    defaultMessage: "Approve"
  },
  buttonWithdraw: {
    id: "app.containers.WorkerContainer.buttonWithdraw",
    defaultMessage: "Withdraw"
  },
  buttonReject: {
    id: "app.containers.WorkerContainer.buttonReject",
    defaultMessage: "Reject"
  },
  confirmApproval: {
    id: "app.containers.WorkerContainer.confirmApproval",
    defaultMessage: "Please confirm that you wish to approve "
  },
  confirmWithdrawal: {
    id: "app.containers.WorkerContainer.confirmWithdrawal",
    defaultMessage: "Please confirm that you wish to withdraw "
  },
  rejectHelper: {
    id: "app.containers.WorkerContainer.rejectHelper",
    defaultMessage:
      "To continue, please specify which of the listed records you consider as Rejection Reason(s) for " +
      "this candidate. You can pick more than one."
  },
  rejectHintCharges: {
    id: "app.containers.WorkerContainer.rejectHintCharges",
    defaultMessage: "e.g. Driving Under the Influence of Alcohol"
  },
  rejectHint: {
    id: "app.containers.WorkerContainer.rejectHint",
    defaultMessage: "Please provide a reason for your decision"
  },
  hiddenData: {
    id: "app.containers.WorkerContainer.hiddenData",
    defaultMessage: "Show Hidden Data"
  },
  ssnTrace: {
    id: "app.containers.WorkerContainer.confirmApproval",
    defaultMessage: "SSN Trace"
  },
  emailTitle: {
    id: "app.containers.WorkerContainer.rejectHint",
    defaultMessage: "Email"
  },
  phoneTitle: {
    id: "app.containers.WorkerContainer.hiddenData",
    defaultMessage: "Phone"
  },
  showAll: {
    id: "app.containers.WorkerContainer.showAll",
    defaultMessage: "Show all"
  },
  notProvided: {
    id: "app.containers.WorkerContainer.notProvided",
    defaultMessage: "Not provided"
  },
  watchlists: {
    id: "app.containers.WorkerContainer.watchlists",
    defaultMessage: "Watchlists"
  },
  canadianReport: {
    id: "app.containers.WorkerContainer.canadianReport",
    defaultMessage: "Canadian Criminal Record"
  },
  canadianStatus: {
    id: "app.containers.WorkerContainer.canadianStatus",
    defaultMessage: "Check Status"
  },
  canadianBasicCheck: {
    id: "app.containers.WorkerContainer.canadianBasicCheck",
    defaultMessage: "Canadian Basic Check"
  },
  socialNetworks: {
    id: "app.containers.WorkerContainer.socialNetworks",
    defaultMessage: "Social Networks"
  },
  employers: {
    id: "app.containers.WorkerContainer.employers",
    defaultMessage: "Employers"
  },
  addresses: {
    id: "app.containers.WorkerContainer.addresses",
    defaultMessage: "Addresses"
  },
  educations: {
    id: "app.containers.WorkerContainer.educations",
    defaultMessage: "Education"
  },
  site_category: {
    id: "app.containers.WorkerContainer.site_category",
    defaultMessage: "Site Category"
  },
  site_name: {
    id: "app.containers.WorkerContainer.site_name",
    defaultMessage: "Site Name"
  },
  site_url: {
    id: "app.containers.WorkerContainer.site_url",
    defaultMessage: "Site URL"
  },
  company_name: {
    id: "app.containers.WorkerContainer.company_name",
    defaultMessage: "Company Name"
  },
  position: {
    id: "app.containers.WorkerContainer.position",
    defaultMessage: "Position"
  },
  start_date: {
    id: "app.containers.WorkerContainer.start_date",
    defaultMessage: "Start Date"
  },
  end_date: {
    id: "app.containers.WorkerContainer.end_date",
    defaultMessage: "End Date"
  },
  address: {
    id: "app.containers.WorkerContainer.address",
    defaultMessage: "Address"
  },
  city: {
    id: "app.containers.WorkerContainer.city",
    defaultMessage: "City"
  },
  province_state: {
    id: "app.containers.WorkerContainer.province_state",
    defaultMessage: "Province State"
  },
  country: {
    id: "app.containers.WorkerContainer.country",
    defaultMessage: "Country"
  },
  postal_code: {
    id: "app.containers.WorkerContainer.postal_code",
    defaultMessage: "Postal Code"
  },
  institution: {
    id: "app.containers.WorkerContainer.institution",
    defaultMessage: "Institution"
  },
  degree: {
    id: "app.containers.WorkerContainer.degree",
    defaultMessage: "Degree"
  },
  canadianEnhancedIdentity: {
    id: "app.containers.WorkerContainer.canadianEnhancedIdentity",
    defaultMessage: "Enhanced Identity Verification"
  },
  match: {
    id: "app.containers.WorkerContainer.match",
    defaultMessage: "Match"
  },
  fullname: {
    id: "app.containers.WorkerContainer.fullname",
    defaultMessage: "Full Name"
  },
  dob: {
    id: "app.containers.WorkerContainer.dob",
    defaultMessage: "DOB"
  },
  originalId: {
    id: "app.containers.WorkerContainer.originalId",
    defaultMessage: "Original ID"
  },
  placeOfBirth: {
    id: "app.containers.WorkerContainer.placeOfBirth",
    defaultMessage: "Place of Birth"
  },
  score: {
    id: "app.containers.WorkerContainer.score",
    defaultMessage: "Score"
  },
  type: {
    id: "app.containers.WorkerContainer.type",
    defaultMessage: "Type"
  },
  url: {
    id: "app.containers.WorkerContainer.url",
    defaultMessage: "URL"
  },
  source: {
    id: "app.containers.WorkerContainer.source",
    defaultMessage: "Source"
  },
  location: {
    id: "app.containers.WorkerContainer.location",
    defaultMessage: "Location"
  },
  locationType: {
    id: "app.containers.WorkerContainer.locationType",
    defaultMessage: "Location Type"
  },
  governmentID: {
    id: "app.containers.WorkerContainer.governmentID",
    defaultMessage: "Government ID"
  },
  vehicles: {
    id: "app.containers.WorkerContainer.vehicles",
    defaultMessage: "Registered Vehicles"
  },
  properties: {
    id: "app.containers.WorkerContainer.properties",
    defaultMessage: "Properties"
  },
  criminalRecords: {
    id: "app.containers.WorkerContainer.criminalRecords",
    defaultMessage: "Criminal Records"
  },
  sexOffender: {
    id: "app.containers.WorkerContainer.sexOffender",
    defaultMessage: "Sex Offender Checks"
  },
  federalCheck: {
    id: "app.containers.WorkerContainer.federalCheck",
    defaultMessage: "Federal Check"
  },
  drugTestingResults: {
    id: "app.containers.WorkerContainer.drugTestingResults",
    defaultMessage: "Drug Test Results"
  },
  mvr: {
    id: "app.containers.WorkerContainer.mvr",
    defaultMessage: "Motor Vehicle Record"
  },
  rejected: {
    id: "app.containers.WorkerContainer.rejected",
    defaultMessage: "Rejected Candidate"
  },
  notes: {
    id: "app.containers.WorkerContainer.notes",
    defaultMessage: "Notes"
  },
  oneOffChecks: {
    id: "app.containers.WorkerContainer.oneOffChecks",
    defaultMessage: "Other Checks"
  },
  titleConsent: {
    id: "app.containers.WorkerContainer.titleConsent",
    defaultMessage: "This candidate has not yet consented to this report."
  },
  bodyConsent: {
    id: "app.containers.WorkerContainer.bodyConsent",
    defaultMessage:
      "Viewing report details or taking action on this candidate may open you to legal liability."
  },
  pdfURL: {
    id: "app.containers.WorkerContainer.pdfURL",
    defaultMessage: "Background Check Results."
  },
  clearError: {
    id: "app.containers.WorkerContainer.clearError",
    defaultMessage: "An error has occurred with this MVR. Please contact "
  },
  resumeChecks: {
    id: "app.containers.WorkerContainer.resumeChecks",
    defaultMessage:
      "As a result, the accuracy and integrity of the report is in question. We are working with the candidate to verify identity. Once verified, any pending checks will be performed. If you want to proceed anyway click the Resume button."
  },
  resumeMVRChecks: {
    id: "app.containers.WorkerContainer.resumeMVRChecks",
    defaultMessage:
      "This candidate's MVR report isn't clear. As a result, all other checks were not initiated." +
      " If you still wish to continue with all the pending checks, press the Resume Checks button."
  },
  resumeAgeChecks: {
    id: "app.containers.WorkerContainer.resumeAgeChecks",
    defaultMessage:
      "The information provided by the applicant does not meet the age requirement.As a result, all other checks were not initiated." +
      " If you still wish to continue with all the pending checks, press the Resume Checks button."
  },
  resumeConfirmation: {
    id: "app.containers.WorkerContainer.resumeConfirmation",
    defaultMessage:
      'Confirm you wish to proceed with this candidate "as is" as stated in our Terms of Service and relieve Turn of any and all liability resulting from authorizing the pending background check.'
  },
  resumeMVRConfirmation: {
    id: "app.containers.WorkerContainer.resumeMVRConfirmation",
    defaultMessage:
      "Please confirm that you wish to proceed with this candidate’s pending checks, despite not having a clear MVR."
  },
  resumeAgeConfirmation: {
    id: "app.containers.WorkerContainer.resumeAgeConfirmation",
    defaultMessage:
      "Please confirm that you wish to proceed with this candidate’s pending checks, despite not having the required age."
  },
  recheckTitle: {
    id: "app.containers.WorkerContainer.recheckTitle",
    defaultMessage: "Recheck worker"
  },
  recheckConfirmation: {
    id: "app.containers.WorkerContainer.recheckConfirmation",
    defaultMessage:
      "Please confirm that you wish to run a recheck on this candidate:"
  },
  noDescriptionAvailable: {
    id: "app.containers.WorkerContainer.noDescriptionAvailable",
    defaultMessage: "No description available"
  },
  candidateInformation: {
    id: "app.containers.WorkerContainer.candidateInformation",
    defaultMessage: "Candidate Information"
  },
  enrollmentInformation: {
    id: "app.containers.WorkerContainer.enrollmentInformation",
    defaultMessage: "Enrollment"
  },
  unauthorizedActionTitle: {
    id: "app.containers.WorkerContainer.unauthorizedAction",
    defaultMessage: "Unauthorized Action"
  },
  unauthorizedAction: {
    description: "Per FCRA, a recheck in California requires a fresh consent.",
    footer: "Please contact our Support team if you need assistance at "
  }
});
