import React, { useMemo, useContext, useState } from "react";
import _ from "lodash";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

import { CandidateReportStyles as reportStyles } from "../../../styles";
import PanelCollapsible from "../../../components/PanelCollapsible";
import { green } from "../../../styles/constants";
import {
  AutoTable,
  AutoTableCell
} from "../../../components/AutoTable/AutoTable";
import { AppContext } from "../../../contextAPI/context-provider";
import PropTypes from "prop-types";

import messages from "../messages";

export const CanadaEmployersCell = ({ field, ...props }) => {
  if (_.includes(["start_date", "end_date"], field)) {
    return <AutoTableCell {...props} style={{ whiteSpace: "nowrap" }} />;
  } else {
    return <AutoTableCell {...props} />;
  }
};

export const CanadaEmployers = ({ worker }) => {
  const context = useContext(AppContext);
  const { checks = {} } = context;
  const [isProcessing] = useState(worker.status === "processing");
  const employers = useMemo(() => {
    const _employers = _.get(
      worker,
      "canadianReport.information_search.employers"
    );

    return _.values(_employers);
  }, [worker]);

  if (_.isEmpty(employers)) {
    return null;
  }

  return (
    <PanelCollapsible
      title={messages.employersTitle}
      titleStyle={reportStyles.title}
      iconStyle={reportStyles.checkedIcon}
      color={green}
      icon={faCheckCircle}
      isCollapsbile
      collapsible={!isProcessing}
      expandAllChecks={!isProcessing && checks.expandAllChecks}
    >
      <AutoTable
        data={employers}
        cellComponent={CanadaEmployersCell}
        headers={{
          company_name: "Company",
          position: "Position",
          start_date: "Start Date",
          end_date: "End Date"
        }}
      />
    </PanelCollapsible>
  );
};

CanadaEmployers.propTypes = {
  worker: PropTypes.object
};
