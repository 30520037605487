import React from "react";
import Text from "../../../components/Text";
import { ReportAuthorizationStyles as styles } from "../../../styles";

import messages from "./messages";
import { FormattedMessage } from "react-intl";

export class Support extends React.PureComponent {
  render() {
    const { support } = styles;
    return (
      <div style={support.container}>
        <div>
          <Text margin="0" weight={700} display="block">
            <FormattedMessage {...messages.didntRequest} />
          </Text>
          <Text display="block">
            <span>
              <FormattedMessage {...messages.letUsKnow} />
            </span>
            <a
              style={support.email}
              href={`mailto: ${messages.email.defaultMessage}`}
            >
              <FormattedMessage {...messages.email} />
            </a>
          </Text>
        </div>
      </div>
    );
  }
}
