import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { FlatButton } from "material-ui";
import Exit from "material-ui/svg-icons/action/exit-to-app";
import { primary } from "../../styles/constants";

class LogoutButton extends PureComponent {
  static propTypes = {
    logout: PropTypes.func.isRequired
  };
  static style = {
    display: "inline-block",
    verticalAlign: "middle",
    minWidth: 50
  };
  render() {
    const { logout } = this.props;
    return logout ? (
      <FlatButton primary onClick={logout}>
        <Exit color={primary} />
        Logout
      </FlatButton>
    ) : null;
  }
}

export default LogoutButton;
