import React from "react";
import PropTypes from "prop-types";
import style from "./snackbar-styles";

import Snackbar from "material-ui/Snackbar";

const propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired
};

const defaultProps = {
  message: "Warning",
  type: "warning",
  open: false,
  onRequestClose: null
};

const SnackbarToast = ({ message, type, open, onRequestClose }) => {
  let snackbarConfig = {
    open,
    message,
    autoHideDuration: 4000,
    bodyStyle: style.bodyStyle,
    onRequestClose
  };
  snackbarConfig.contentStyle =
    type === "success" ? style.contentStyleSuccess : style.contentStyleWarning;
  return <Snackbar {...snackbarConfig} />;
};

Snackbar.propTypes = propTypes;
Snackbar.defaultProps = defaultProps;
export default SnackbarToast;
