import React from "react";
import CircularProgress from "material-ui/CircularProgress";
import Divider from "material-ui/Divider";
import Title from "../Title";
import { CandidateReportStyles as styles } from "../../styles";

import { TurnLoader as _TurnLoader } from "./TurnLoader";

export const TurnLoader = _TurnLoader;

const ReportLoader = () => (
  <div style={styles.progress}>
    <CircularProgress size={200} thickness={10} color="#7364DB" />
    <Title weight="bolder" big margin="20px 0 5px 0">
      Please Wait
    </Title>
    <Divider style={styles.progressDivider} />
    <p style={styles.progressContent}>
      Validating your information <br />
      it will be ready shortly.
    </p>
  </div>
);

export default ReportLoader;
