import _ from "lodash";

export const getIsNotEmptyRecords = state => {
  const {
    mvr,
    sexOffenderRecords,
    criminalRecords,
    watchListRecords,
    nationalRecords,
    federalRecords
  } = state;

  const records = [
    mvr,
    sexOffenderRecords,
    criminalRecords,
    watchListRecords,
    nationalRecords,
    federalRecords
  ];

  return records.some(record => !_.isEmpty(record));
};
