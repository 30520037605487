import React, { Component } from "react";
import { observer } from "mobx-react";
import { turnAPI } from "../../services";
import _ from "lodash";
import ErrorDialog from "../../components/ErrorDialog";
import ModalLoader from "../../components/ModalLoader";
import DownloadFileButton from "../../components/DownloadFileButton";

const DownloadReportUI = observer(
  class DownloadReportUI extends Component {
    constructor(props) {
      super(props);
      this.state = {
        errorOpen: false,
        loading: false
      };
    }

    setLoading = loading => {
      if (typeof loading !== "boolean") return;
      this.setState({ loading });
    };

    getReport = async () => {
      this.setLoading(true);
      const response = await turnAPI.getReport();

      switch (response.status) {
        case 200:
          if (_.isNil(response.data.report_hyperlink)) {
            this.setState({ errorOpen: true });
            this.setLoading(false);
            break;
          } else {
            window.open(response.data.report_hyperlink);
          }
          break;
        case 500:
        default:
          this.setState({ errorOpen: true });
          break;
      }
      this.setLoading(false);
    };

    closeError = () => this.setState({ errorOpen: false });

    render() {
      const { loading, errorOpen } = this.state;
      return (
        <>
          <ErrorDialog open={errorOpen} onPress={this.closeError} />
          {loading ? <ModalLoader /> : null}
          <DownloadFileButton onPress={this.getReport} />
        </>
      );
    }
  }
);

export default DownloadReportUI;
