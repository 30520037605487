import React from "react";
import PropTypes from "prop-types";

import SubTitle from "../SubTitle";
import PanelCollapsible from "../PanelCollapsible";
import WorkerSettingsContent from "./content";
import { WorkerSettingsStyles as styles } from "../../styles";
import withContext from "../../contextAPI/context-HOC";
import { slateGray } from "../../styles/constants";
import {
  WEBHOOK_URL,
  PACKAGES,
  FOUNTAIN_INTEGRATION,
  PAYMENT_METHOD,
  DEFAULTS_SECTION,
  LEVER_INTEGRATION,
  CURRENT_INVOICE
} from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faPuzzlePiece,
  faCreditCard,
  faPollPeople,
  faCalendarDay,
  faFileInvoiceDollar
} from "@fortawesome/pro-solid-svg-icons";

export const SettingsSection = ({ title, context, ...rest }) => {
  if (
    rest.type === "stripe_form" &&
    !context.features.REACT_APP_ENABLE_PAYMENT_SETTINGS
  )
    return null;
  const renderTitle = title => {
    if (title === WEBHOOK_URL)
      return (
        <FontAwesomeIcon
          style={styles.workerSettingsSection.titleIcon}
          icon={faLink}
        />
      );
    if (title === FOUNTAIN_INTEGRATION || title === LEVER_INTEGRATION)
      return (
        <FontAwesomeIcon
          style={styles.workerSettingsSection.titleIcon}
          icon={faPuzzlePiece}
        />
      );
    if (title === PAYMENT_METHOD)
      return (
        <FontAwesomeIcon
          style={styles.workerSettingsSection.titleIcon}
          icon={faCreditCard}
        />
      );
    if (title === PACKAGES)
      return (
        <FontAwesomeIcon
          style={styles.workerSettingsSection.titleIcon}
          icon={faPollPeople}
        />
      );

    if (title === DEFAULTS_SECTION)
      return (
        <FontAwesomeIcon
          style={styles.workerSettingsSection.titleIcon}
          icon={faCalendarDay}
        />
      );
    if (title === CURRENT_INVOICE)
      return (
        <FontAwesomeIcon
          style={styles.workerSettingsSection.titleIcon}
          icon={faFileInvoiceDollar}
        />
      );
  };
  return (
    <PanelCollapsible
      noPrint
      collapsedInvert={
        title === WEBHOOK_URL ||
        title === PACKAGES ||
        title === DEFAULTS_SECTION
      }
      panelProps={{ style: styles.workerSettingsSection.panel }}
      collapsibleIconStyle={styles.workerSettingsSection.icon}
      titleStyle={styles.workerSettingsSection.title}
      titleComponent={
        <SubTitle size="16px" color={slateGray} margin="0" weight="bold">
          {renderTitle(title)}
          <span style={{ textTransform: "capitalize" }}>
            {title && title.replace(/_/gi, " ")}
          </span>
        </SubTitle>
      }
    >
      <WorkerSettingsContent title={title} {...rest} />
    </PanelCollapsible>
  );
};

export const WorkerSettingsSection = withContext(SettingsSection);

WorkerSettingsSection.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  reference: PropTypes.string,
  data: PropTypes.array,
  key: PropTypes.number,
  length: PropTypes.number
};

WorkerSettingsSection.defaultProps = {
  title: ""
};
