import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { CandidateReportStyles as styles } from "../../styles";
import { FormattedMessage } from "react-intl";

import Text from "../Text";
import messages from "./messages";
import Check from "./Check";
import Divider from "../Divider";

const propTypes = {
  offenses: PropTypes.array.isRequired,
  pendingMVRReview: PropTypes.bool.isRequired,
  pendingAgeReview: PropTypes.bool.isRequired
};

class SexOffender extends PureComponent {
  constructor(props) {
    super(props);
    this.offenses =
      props.offenses && props.offenses.length > 0 ? props.offenses : false;
    this.renderOffenses = props.offenses;
  }
  componentDidMount() {}
  render() {
    const {
      pendingMVRReview,
      pendingAgeReview,
      offenses,
      ...rest
    } = this.props;

    const defaultCheckNameId = "detailCheckName";
    const defaultCheckStatusId = "detailCheckStatus";
    const defaultCheckSectionId = "detailCheckSection";

    if (!this.renderOffenses) return null;
    if (pendingMVRReview && this.renderOffenses) {
      return (
        <div id={defaultCheckSectionId} style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.sexOffender} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <p style={styles.pendingMVR}>
            <FormattedMessage {...messages.pendingMVR} />
          </p>
          <Divider height="1px" />
        </div>
      );
    }
    if (pendingAgeReview && this.renderOffenses) {
      return (
        <div id={defaultCheckSectionId} style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.sexOffender} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <p id="pendingAgeMessage" style={styles.pendingMVR}>
            <FormattedMessage {...messages.pendingAge} />
          </p>
          <Divider height="1px" />
        </div>
      );
    }
    if (!this.offenses) {
      return (
        <div id={defaultCheckSectionId} style={styles.section}>
          <Check
            check={<FormattedMessage {...messages.sexOffender} />}
            noDivider
            containerStyle={styles.checks.titleContainer}
            nameId={defaultCheckNameId}
            statusId={defaultCheckStatusId}
            {...rest}
          />
          <Divider height="1px" />
        </div>
      );
    }
    return (
      <div id={defaultCheckSectionId} style={styles.section}>
        <Check
          check={<FormattedMessage {...messages.sexOffender} />}
          noDivider
          containerStyle={styles.checks.titleContainer}
          nameId={defaultCheckNameId}
          statusId={defaultCheckStatusId}
          {...rest}
        />
        <div id="sex-offender-content" style={styles.sectionContent}>
          <Text style={styles.reportDateLabel}>
            <FormattedMessage {...messages.registeredSex} />
          </Text>
          {offenses.map((offense, key) => (
            <Text
              className="sex-offender-source-state"
              key={key}
              style={styles.reportDateTxt}
            >
              {offense.source_state}
            </Text>
          ))}
        </div>
        <Divider height="1px" />
      </div>
    );
  }
}

SexOffender.propTypes = propTypes;

export default SexOffender;
