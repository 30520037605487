import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import _ from "lodash";
import { turnAPI } from "../../services";
import {
  InlineText,
  InlineUrl,
  InputField,
  Button,
  SecondText,
  CreditCard,
  ConsentButton,
  CurrentInvoice
} from "./content-items";
import Dialog from "material-ui/Dialog";
import { ModalStyles } from "../../styles";
import { FormattedMessage } from "react-intl";
import RaisedButton from "material-ui/RaisedButton";
import * as messages from "./messages";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";

class WorkerSettingsContent extends Component {
  static propTypes = {
    model: PropTypes.object,
    source: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      form: {},
      creditCardExpanded: false,
      errorMessage: null,
      successFountainOpen: false,
      errorFountainOpen: false,
      default: {
        days: 90
      }
    };
    this.actionCloseErrorFountain = [
      <div style={ModalStyles.confirmBtnContainer} key={1}>
        <RaisedButton
          primary
          label="OK"
          onClick={() => this.closeErrorFountain()}
        />
      </div>
    ];
    this.actionCloseSuccessFountain = [
      <div style={ModalStyles.confirmBtnContainer} key={1}>
        <RaisedButton
          primary
          label="OK"
          onClick={() => this.closeSuccessFountain()}
        />
      </div>
    ];
  }

  componentDidMount() {
    this.resetForm();
    if (
      this.props.data &&
      this.props.data[0] &&
      this.props.data[0].defaultValue
    ) {
      const form = {
        email: this.props.data[0].defaultValue
      };
      this.setState({ form });
    }

    if (this.isNumeric(this.props.data)) {
      this.setState({
        default: {
          days: this.props.data
        }
      });
    }
  }

  isNumeric(value) {
    return typeof value === "number";
  }

  setAlert() {
    toastr.options = {
      closeButton: true,
      positionClass: "toast-bottom-right",
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "10000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      preventDuplicates: true,
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut"
    };

    if (this.state.success) {
      toastr.success("We have successfully updated your payment information");
      setTimeout(() => this.setState({ success: null }), 10000);
    }

    if (this.state.error) {
      toastr.error(
        "There was an error, please try again or contact support on support@turn.ai"
      );
      setTimeout(() => this.setState({ error: null }), 5000);
    }
  }

  setForm = form => {
    this.setState(prev => ({ form: { ...prev.form, ...form } }));
  };

  setValue = (source, value) => {
    if (!_.isString(source)) return null;
    this.setForm({ [source]: value });
  };

  isUrlValid = url => {
    // constructor of class URL requires a valid URL
    let _url;
    try {
      _url = new URL(url);
    } catch (error) {
      _url = false;
    }
    return !!_url;
  };

  handleSave = async () => {
    const { data } = this.props;
    let reference = this.props.reference;
    let value = this.state.form;
    if (this.isNumeric(data)) {
      reference = "/partners/default_days";
      value = { default_days: this.state.default.days };
    }

    if (!reference) return;
    this.props.model.setReady(false);

    // because the purpose of settings is to render from backend
    // validating form > webhook_url when props > title is Webhook URL
    if (
      this.props.title === "Webhook URL" &&
      !this.isUrlValid(this.state.form.webhook_url)
    ) {
      this.props.model.setReady(true);
      this.setState({
        successFountainOpen: true,
        message: <FormattedMessage {...messages.webhook.invalidWebhook} />
      });
      return;
    }

    const response = await turnAPI.postSettings(reference, value);
    if (!response) return;
    let statusMessages;
    if (this.props.title === "Webhook URL") {
      statusMessages = messages.webhook;
    } else if (this.props.type === "form_password") {
      statusMessages = messages.password;
    } else if (this.isNumeric(data)) {
      statusMessages = messages.defaults;
    } else if (this.props.title === "Lever Integration") {
      statusMessages = messages.lever;
    } else {
      statusMessages = messages.fountain;
    }

    switch (response.status) {
      case 200:
        if (this.props.model) await this.props.model.getSettings();
        this.resetForm();
        this.props.model.setReady(true);
        this.setState({
          successFountainOpen: true,
          message: <FormattedMessage {...statusMessages.positiveResponse} />
        });
        break;
      case 202:
        this.props.model.setReady(true);
        this.setState({
          errorFountainOpen: true,
          message: (
            <FormattedMessage {...statusMessages.noNewValuesProvidedResponse} />
          )
        });
        break;
      case 400:
        this.props.model.setReady(true);
        this.setState({
          errorFountainOpen: true,
          message: (
            <FormattedMessage
              {...(statusMessages.badRequestResponse || {
                id: "app.components.WorkerSettings.leverCredentialsSaveError",
                defaultMessage: response.data.error
              })}
            />
          )
        });
        break;
      case 401:
        this.props.model.setReady(true);
        this.setState({
          errorFountainOpen: true,
          message: (
            <FormattedMessage {...statusMessages.authenticationRequired} />
          )
        });
        break;
      default:
        this.props.model.setReady(true);
        this.setState({
          errorFountainOpen: true,
          message: <FormattedMessage {...statusMessages.errorResponse} />
        });
        break;
    }
  };

  getChildProps = (props, model) => {
    switch (props.type) {
      case "input":
      case "password": {
        let onChange = ({ target }) =>
          this.setValue(props.source, target.value);
        let value = this.state.form[props.source];
        return {
          ...props,
          onChange,
          value,
          placeholder: props.placeholder,
          error: model.error
        };
      }
      case "number": {
        let onChange = ({ target }) =>
          this.setState({ default: { days: parseInt(target.value) } });
        let value = this.state.default.days;
        return {
          ...props,
          onChange,
          value,
          placeholder: props.placeholder,
          error: model.error
        };
      }
      default:
        return props;
    }
  };

  renderChild = (props, key, formType, model) => {
    if (formType && formType === "stripe_form" && props.type === "text") {
      return (
        <CreditCard
          key={key}
          handlePayment={this.submitStripeToken}
          email={this.state.form.email}
          creditCardExpanded={this.state.creditCardExpanded}
          handleExpand={this.toggleCardInfo}
          model={model}
          {...props}
        />
      );
    }
    if (formType && formType === "invoice")
      return <CurrentInvoice key={key} {...props} />;
    if (props.type === "inlineText") return <InlineText key={key} {...props} />;
    if (props.type === "url") return <InlineUrl key={key} {...props} />;
    if (props.type === "text") return <SecondText key={key} {...props} />;
    if (props.type === "number")
      return <InputField key={key} {...this.getChildProps(props, model)} />;
    if (props.type === "input" || props.type === "password")
      return <InputField key={key} {...this.getChildProps(props, model)} />;

    if (props.type === "link-button") {
      return <ConsentButton key={key} {...props} />;
    }
    return null;
  };

  resetForm = () => {
    if (
      (this.props.type !== "form" && this.props.type !== "form_password") ||
      !this.props.data
    )
      return;
    this.props.data.map(({ source, defaultValue }) =>
      this.setValue(source, defaultValue || "")
    );
  };

  toggleCardInfo = () => {
    this.setState(prevProps => ({
      creditCardExpanded: !prevProps.creditCardExpanded
    }));
  };

  get children() {
    const { data, type, model } = this.props;
    if (this.isNumeric(data)) {
      return this.renderChild({ data: data, type }, 0, type, model);
    } else {
      return this.props.data.map((props, key) =>
        this.renderChild(props, key, type, model)
      );
    }
  }

  get submitButton() {
    if (
      this.props.type === "form" ||
      this.props.type === "form_password" ||
      this.props.type === "number"
    ) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end"
          }}
        >
          <Button
            onClick={this.handleSave}
            value={
              <>
                <FontAwesomeIcon icon={faSave} style={{ marginRight: "5px" }} />
                Save
              </>
            }
          />
        </div>
      );
    } else {
      return null;
    }
  }

  submitStripeToken = async token => {
    const { reference, model: Settings, data } = this.props;
    const { form } = this.state;
    const email = form.email ? form.email : data[0].defaultValue;
    if (!reference || !email) return;
    const res = await Settings.submitStripeToken(reference, token, { email });
    if (res === 200) {
      this.setState({ success: true });
      this.toggleCardInfo();
    } else {
      this.setState({ error: true });
    }
  };

  closeErrorFountain = () => this.setState({ errorFountainOpen: false });

  closeSuccessFountain = () => this.setState({ successFountainOpen: false });

  render() {
    if (
      !this.props.data ||
      (!_.isArray(this.props.data) && !this.isNumeric(this.props.data))
    )
      return null;
    if (!_.isString(this.props.type)) return null;
    return (
      <>
        {this.children}
        {this.submitButton}
        <Dialog
          actions={this.actionCloseErrorFountain}
          modal={false}
          contentStyle={ModalStyles.modal}
          bodyStyle={ModalStyles.altBodyStyle}
          open={this.state.errorFountainOpen}
        >
          <h3 style={ModalStyles.altMessage}>{this.state.message}</h3>
        </Dialog>
        <Dialog
          actions={this.actionCloseSuccessFountain}
          modal={false}
          contentStyle={ModalStyles.modal}
          bodyStyle={ModalStyles.altBodyStyle}
          open={this.state.successFountainOpen}
        >
          <h3 style={ModalStyles.altMessage}>{this.state.message}</h3>
        </Dialog>
        {this.setAlert()}
      </>
    );
  }
}

export default observer(WorkerSettingsContent);
