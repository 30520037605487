import styled from "styled-components";

const Panel = styled.div`
  display: block;
  cursor: pointer;
  position: fixed;
  bottom: 10%;
  right: 2.5%;
  z-index: 1;
  background: #627dff;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #627dff;
  border-radius: 20px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.75);
`;

export default Panel;
