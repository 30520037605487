/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  workerLink: {
    id: "app.containers.WorkerPage.workersLink",
    defaultMessage: "Worker"
  },
  workerVerified: {
    id: "app.containers.WorkerPage.workerVerified",
    defaultMessage: "Verified Worker"
  },
  workerNotVerified: {
    id: "app.containers.WorkerPage.workerNotVerified",
    defaultMessage: "Not Verified"
  },
  onBoardingApproved: {
    id: "app.containers.WorkerPage.onBoardingApproved",
    defaultMessage: "On Boarding Approved"
  },
  onBoardingPending: {
    id: "app.containers.WorkerPage.onBoardingPending",
    defaultMessage: "On Boarding Pending"
  },
  onBoardingRejected: {
    id: "app.containers.WorkerPage.onBoardingRejected",
    defaultMessage: "On Boarding Rejected"
  },
  applicationsLink: {
    id: "app.containers.WorkerPage.settingsLink",
    defaultMessage: "Applications"
  },
  historyLink: {
    id: "app.containers.WorkerPage.settingsLink",
    defaultMessage: "History"
  },
  roleAccessDeniedTitle: {
    id: "app.containers.WorkerPage.roleAccessDeniedTitle",
    defaultMessage: "Access Denied"
  },
  workerNotFound: {
    description: {
      line1:
        "This worker has not given consent, thus we have not fully verified their identity.",
      line2: "Please contact our Support team if you need assistance at "
    }
  },
  workerUnidentified: {
    id: "app.containers.WorkerPage.workerUnidentified",
    defaultMessage: "Unidentified Worker"
  },
  workerUnidentifiedDetails: {
    id: "app.containers.WorkerPage.workerUnidentifiedDetails",
    defaultMessage:
      "Please contact our Support team if you need assistance at support@turn.ai"
  },
  workerInitated: {
    title: {
      id: "app.containers.WorkerPage.workerInitiatedTitle",
      defaultMessage: "This report has not been started"
    },
    description:
      "This candidate has consented to do a background check, to start it, please take action on your ATS/API integration."
  },
  error: {
    title: {
      id: "app.component.error.title",
      defaultMessage: "Oops! Something went wrong"
    },
    description: {
      id: "app.component.error.description",
      defaultMessage:
        "If you continue to see this message, please contact support@turn.ai"
    },
    ok: {
      id: "app.component.error.ok",
      defaultMessage: "OK"
    }
  }
});
