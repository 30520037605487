// export const primary = "#31B4B5";
// export const secondary = "#A0D9D4";
// export const black = "#383A38";
// export const white = "#fff";
// export const gray = "#A1A4A2";
// export const lightGray = "#BDBDBD";
export const fadedGreen = "#98D9DA";
// export const red = "#ff6c69";
// export const noticeError = "#EC707E";
// export const noticeWarning = "#F6BB28";
// export const noticeSuccess = primary;
export const gradientLight = "#4adfe0";
export const gradientDark = "#0f9394";
export const gradientVariant = "#008077";
export const pink = "#ee818e";
export const backgroundGray = "#F8F8F8";
export const backgroundBlack = "#010202";
export const altGreen = "#31B579";
export const fadedGray = "#FCFCFC";
export const lightGray = "#F4F4FB";
export const fadedBlue = "#E3ECF4";
export const warningOrange = "#F4B565";

//Turn Primary Palette
export const primary = "#1f2937"; //CoolGray or SpaceCadet
export const secondary = "#7364DB"; //Turquoise
export const emerald = "#0ed067";
export const tartOrange = "#fb4d49";
export const yellowOrange = "#faa73c";
export const yellowOrangeLight = "#FFE27D";
export const red = "#ff6c69";
export const green = "#0ED067";
export const orange = "#faa03c";
export const blue = "#7364DB";

//Turn Grey Palette
export const lightBlack = "#8F8F8F";
export const slateGray = "#5f7d95";
export const slateGray2 = "#6B7280";
export const cadetBlue = "#9bb1c8";
export const periwinkle = "#bfcddd";
export const aliceBlue = "#e1e8f3";
export const lightBlue = "#D0E1FF70";
export const darkBlue = "#313C94";
export const smokeWhite = "#f7f9fc";
export const disabledGray = "#969B9D";
export const unselectedGray = "#C7C5C5";
//For disabled fields
export const disabledText = "#C6C7C7";
export const disabledBackground = "#EFEFEF";
//For disabled buttons
export const disabledButtonText = "#A7ABAD";
export const disabledButtonBackground = "#F2F2F2";

//Turn Alerts Palette
export const alert0 = slateGray;
export const alert1 = emerald;
export const alert2 = tartOrange;
export const alert3 = yellowOrange;
export const ceruleanBlue = "#4357ad";
export const alertOrange = "#d97716";
export const borderOrange = "#F28800";

//Turn Secondary Palette 1
export const peach = "#f9cb9c";
export const babyPink = "#f4cccc";
export const spanishPink = "#e6b8af";
export const beauBlue = "#cfe2f3";
export const cultured = "#e3e9ed";

//Turn Secondary Palette 2
export const blueBell = "#b4a7d6";
export const babyBlueEyes = "#9fc5e8";
export const powderBlue = "#b7ecec";
export const honeyDew = "#d9ead3";
export const buff = "#ffe599";

//Others
export const black = "#000";
export const white = "#fff";
export const mischka = "#ced0da";

//Notice colors
export const noticeMuted = slateGray;
export const noticeError = tartOrange;
export const noticeWarning = ceruleanBlue;
export const noticeSuccess = emerald;
export const noticeInfo = ceruleanBlue;

//Menu link
export const selectedLink = "#2E3148";

//Turn ready palette
export const readyLightBlue = "#2191FB";
export const readyInactive = "#2d304761";
export const readyBlue = "#637DFF";
export const readyDarkPurple = "#141825";

//Base64 link
export const blueLink = "#0047FF";

// TW inspired palette
export const TWPrimary = "#008077";
export const TWSuccess = "#70BF13";
export const TWSuccessLight = "##F3FFE5";
export const TWError = "#B21D12";
export const TWErrorLight = "#FEF2F1";
export const TWGrey = "#666666";
