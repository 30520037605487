import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "../../../containers/ReportAuthorizationPage/messages";
import { Container, Header } from "../";
import { Option } from "./Option";

export class CardList extends React.PureComponent {
  static propTypes = {
    options: PropTypes.array,
    onClick: PropTypes.func
  };

  render() {
    return !this.props.options ? null : (
      <Container>
        <Header title={<FormattedMessage {...messages.choose} />} />
        {this.props.options.map((option, key) => (
          <Option
            label={<FormattedMessage {...messages[option]} />}
            hint={<FormattedMessage {...messages.chooseHint} />}
            token={option}
            onClick={this.props.onClick}
            key={key}
          />
        ))}
      </Container>
    );
  }
}
