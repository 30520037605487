import styled from "styled-components";

const Row = styled.div`
  display: block;
  width: 100%;
  // font-size: 0;
  position: ${props => (props.relative ? "relative" : "static")}
  padding: ${props => props.padding || 0}
  margin: ${props => props.margin || 0}
  border-left: ${props => props.borderLeft || "none"}
`;

export default Row;
