const styles = {
  dragDropText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#2A2A2B"
  },
  maxUpload: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#2A2A2B"
  },
  browseLabel: {
    textDecoration: "underline",
    color: "#01D8CE"
  },
  img2Upload: {
    width: "232px",
    height: "150px"
  },
  labelChangePhoto: {
    color: "#5372E7",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "10px"
  },
  labelError: {
    color: "#FF1D25",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px"
  }
};

export default styles;
