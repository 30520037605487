import React from "react";
import { PropTypes } from "prop-types";

const Icon = ({ icon }) => {
  if (!icon) return null;
  const style = {
    display: "inline-block",
    margin: "0px 0px 0px 20px"
  };
  return <span style={style}>{icon}</span>;
};
Icon.propTypes = { icon: PropTypes.node };

export default Icon;
