import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Background from "../../components/Background";
import Logo from "../../components/Logo";
import {
  Content,
  Container as CardContainer
} from "../../components/ReportAuthorization";
import { ReportAuthorizationStyles as styles } from "../../styles";
import Img from "../../components/Img";
import Text from "../../components/Text";

import messages from "./messages";

class Error extends Component {
  static propTypes = {
    message: PropTypes.node
  };

  render() {
    const { error } = styles;
    return (
      <Background>
        <Content>
          <div style={styles.logoContainer}>
            <Logo svg height="100%" />
          </div>
          <CardContainer>
            <div style={error.content}>
              <Img
                type={this.props.message ? "error" : "errorNotFound"}
                style={error.image}
              />
              <Text display="block" size="20px" weight="bold" margin="40px 0">
                {this.props.message ? (
                  this.props.message
                ) : (
                  <FormattedMessage {...messages.default} />
                )}
              </Text>
            </div>
          </CardContainer>
        </Content>
      </Background>
    );
  }
}

export default Error;
