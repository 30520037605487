import React from "react";
import PropTypes from "prop-types";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles";

import getNavigationProps from "./getNavigationProps";

const NavigationUI = ({ title, titleRoute, subtitle }) => (
  <div id="heading" style={styles.container}>
    {titleRoute ? (
      <a href={titleRoute} style={styles.titleInactive}>
        {title}
      </a>
    ) : (
      <span style={styles.title}>{title}</span>
    )}
    {subtitle && (
      <>
        <FontAwesomeIcon
          icon={faChevronRight}
          color={"#8E8E8E"}
          style={styles.icon}
        />
        <span style={styles.subtitle}>{subtitle}</span>
      </>
    )}
  </div>
);

NavigationUI.propTypes = {
  title: PropTypes.string,
  titleRoute: PropTypes.string,
  subtitle: PropTypes.string
};

export default NavigationUI;
export { getNavigationProps };
