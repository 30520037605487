import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  ImageContainer,
  Message,
  Modal,
  Title
} from "./styled";
const error = require("../../../src/components/Img/images/error.png");

const ErrorModal = ({ buttonLabel, isOpen, message, title, onClick }) => {
  return (
    <Modal isOpen={isOpen}>
      <Container>
        {error && (
          <ImageContainer>
            <img
              style={{ width: "100%", height: "100%" }}
              src={error}
              alt="error image"
            />
          </ImageContainer>
        )}
        <Title>{title}</Title>
        <Message>{message}</Message>
        <Button onClick={onClick}>{buttonLabel}</Button>
      </Container>
    </Modal>
  );
};

ErrorModal.propTypes = {
  buttonLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func
};

export default ErrorModal;
