import styled from "styled-components";
import { aliceBlue } from "../../styles/constants";

const DetailContainerInline = styled.div`
  display: inline-block;
  float: left;
  clear: ${({ clear }) => (clear ? "left" : "none")};
  color: ${({ label, subtitle, color }) => {
    if (color) return color;
    if (subtitle) return "inherit";
    if (label) return aliceBlue;
    return "inherit";
  }};
  font-size: ${({ size }) => size || "14px"};
  font-weight: ${({ label }) => (label ? "bold" : "normal")};
  vertical-align: top;
  width: ${({ width }) => width || "33%"};
  padding: ${({ padding }) => {
    if (padding) return padding;
    return "2px 0";
  }};
`;

export default DetailContainerInline;
