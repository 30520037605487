import styled from "styled-components";

const ShowPrint = styled.div`
  display: none;
  @media print {
    display: ${props => props.display || "block"};
  }
`;

export default ShowPrint;
