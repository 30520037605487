import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import bugsnag from "bugsnag-js";
import HttpsRedirect from "react-https-redirect";
import createPlugin from "bugsnag-react";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import ReactGA from "react-ga";
import { setPusherClient } from "react-pusher";
import Pusher from "pusher-js";

export const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: true
});

setPusherClient(pusherClient);

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  notifyReleaseStages: ["production", "staging"]
});
export const ErrorBoundary = bugsnagClient.use(createPlugin(React));

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
if (process.env.GA_OPTIMIZE_ID)
  ReactGA.ga("require", process.env.REACT_APP_GA_OPTIMIZE_ID);

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
};
firebase.initializeApp(config);

export const database = firebase.database();
export const storage = firebase.storage();

ReactDOM.render(
  <ErrorBoundary>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
