import { white } from "../../../src/styles/constants";

const style = {
  drawerContainer: {
    width: "358px",
    height: "657px",
    position: "absolute",
    right: "351px",
    top: "65px",
    transform: "none",
    overflowX: "hidden",
    borderRadius: "14px",
    boxShadow: "3px 3px 1px 0 rgba(0, 0, 0, 0.03)",
    border: "solid 0.4px #9bb1c8"
  },
  whatsNewTitle: {
    backgroundColor: white,
    padding: "17px 27px 0px 20px",
    fontWeight: "bold",
    fontSize: "18px",
    color: "#202224",
    zIndex: "1",
    borderBottom: `1px solid rgba(155, 177, 200, 0.5)`,
    width: "100%",
    margin: "auto",
    position: "sticky",
    top: "0"
  }
};

export default style;
