import React from "react";
import { Select, withStyles } from "@material-ui/core";
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";

import { primary } from "../../styles/constants";
import { MenuItem } from "material-ui";

const filterDropdownStyles = {
  root: {
    verticalAlign: "middle",
    borderRadius: 0,
    border: "none",
    marginLeft: -16,
    marginRight: -16
  },
  select: {
    fontSize: "12px",
    fontWeight: "bold",
    padding: "5px 10px",
    paddingRight: "21px",
    textAlign: "center",
    "&:focus": {
      background: primary
    },
    border: "none"
  },
  selectMenu: {
    background: primary,
    color: "white",
    border: "none"
  },
  disabled: {
    backgroundColor: "#8080804d"
  }
};

const DropDownIcon = ({ className, ...props }) => {
  return (
    <Icon
      className={classNames(className, "fas fa-chevron-down")}
      style={{
        top: "calc(50% - 4px)",
        fontSize: "10px",
        right: "7px",
        color: "white"
      }}
      {...props}
    />
  );
};

const FlatWorkerFilterDropdownComponent = ({ children, ...props }) => (
  <Select
    disableUnderline
    IconComponent={DropDownIcon}
    style={{ borderRadius: 0, height: 32, border: "none" }}
    {...props}
  >
    {children}
  </Select>
);
export const FlatWorkerFilterDropdown = withStyles(filterDropdownStyles)(
  FlatWorkerFilterDropdownComponent
);

const filterMenuItemStyles = {
  root: {
    lineHeight: 21
  }
};
const FlatWorkerFilterMenuItemComponent = ({ ...props }) => (
  <MenuItem {...props} />
);
export const FlatWorkerFilterMenuItem = withStyles(filterMenuItemStyles)(
  FlatWorkerFilterMenuItemComponent
);
