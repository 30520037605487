import React, { useMemo } from "react";
import messages from "../messages";
import { CandidateReportStyles as styles } from "../../../styles";
import Check from "../Check";
import Divider from "../../Divider";
import {
  DEFAULT_CHECK_NAME_ID,
  DEFAULT_CHECK_SECTION_ID,
  DEFAULT_CHECK_STATUS_ID
} from "./constants";
import { FormattedMessage } from "react-intl";
import { hideReportUrl } from "./utils";
import { ContentWrapper, TestType } from "./styled";

const DrugTestingResults = ({ drugTest, status, ...otherProps }) => {
  const { closed_report_url, type } = drugTest || {};

  const renderReportURL = useMemo(
    () => closed_report_url && !hideReportUrl(status),
    [drugTest, status]
  );

  return (
    <div id={DEFAULT_CHECK_SECTION_ID} style={styles.section}>
      <Check
        check={<FormattedMessage {...messages.drugTestingResults} />}
        noDivider
        containerStyle={styles.checks.titleContainer}
        nameId={DEFAULT_CHECK_NAME_ID}
        statusId={DEFAULT_CHECK_STATUS_ID}
        status={status}
        {...otherProps}
      />
      <ContentWrapper id="drug-test-content">
        <TestType>{type}</TestType>
        {renderReportURL && (
          <a href={closed_report_url} target="_blank" rel="noopener noreferrer">
            View Report
          </a>
        )}
      </ContentWrapper>
      <Divider height="1px" />
    </div>
  );
};

export default DrugTestingResults;
