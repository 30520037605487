import { smokeWhite, periwinkle } from "../../../styles/constants";

export const ResumeChecksStyles = {
  modal: {
    position: "absolute",
    zIndex: "5",
    width: "100%",
    left: "0",
    top: "0",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#ffffffb3"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    flex: 3
  },
  textContent: {
    width: "85%"
  },
  buttonContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  message: {
    color: smokeWhite,
    fontWeight: "normal",
    lineHeight: 1.2,
    fontSize: "12px"
  },
  icon: {
    fontSize: "36px",
    color: smokeWhite
  },
  button: {
    border: `solid 1px ${periwinkle}`,
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: 0,
    textTransform: "none"
  },
  buttonLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    padding: 0,
    width: "100%"
  }
};
