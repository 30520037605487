import styled from "styled-components";
import { primary } from "../../styles/constants";

const AbsoluteCenter = styled.a`
  color: ${props => props.color || primary};
  text-decoration: ${props => props.textDecoration || "underline"}
  &:hover {
    color: ${props => props.hoverColor || primary};
    text-decoration: ${props => props.hoverTextDecoration || "underline"}
  }
`;

export default AbsoluteCenter;
