import { action, observable, computed, decorate } from "mobx";
import moment from "moment";
import _ from "lodash";
import { Setter, toTitleCase, validators, translateStatus } from "turn-shared";

import env from "@mars/heroku-js-runtime-env";
import {
  REVIEW,
  HIT,
  CLEAR,
  PROCESSING,
  WITHDRAWN,
  PENDING,
  DRUG_TEST_PENDING_SCHEDULING_STATUS,
  PROFILE_STATUS,
  READY,
  FULFILLED,
  ON_DEMAND_CRIM,
  ATTENTION,
  ON_DEMAND_MVR
} from "../constants";
import {
  faCheckCircle,
  faPauseCircle,
  faExclamationTriangle,
  faSpinner
} from "@fortawesome/pro-solid-svg-icons";
import { orange, red, green, periwinkle } from "../styles/constants";
import { turnAPI } from "../services";
import { PROCESSING_ON_DEMAND_CRIM_STATUS } from "./constants";

const { validateCheck, validateMVRCheck, disable_check } = validators;

class CandidateReportModel extends Setter {
  loading = true; // model is loading until setData runs
  confirm = false;
  confirmTimestamp = undefined;
  certify = false;
  certifyTimestamp = undefined;
  consent = false;
  disableAssentButton = false;
  review_modal_checked = false;
  signed = false;
  signedTimestamp = undefined;
  name = "";
  partner = "";
  address_for_fcra = "";
  status = "";
  worker_status = "";
  user = null;
  ssn = {};
  doSSNTrace = true;

  // Drug Testing
  doDrugTesting = false;
  shouldScheduleDrugTest = false;
  drugTestingResults = null;

  data;
  processing_checks;
  read_only;
  fileNumber;
  email;
  fullName;
  mvr = null;
  phoneNumber;
  turnID = "";
  geocodeData = null;
  candidate_provided_zipcode = "";
  candidate_provided_dob = "";
  candidateConsentEmailId = "";
  hasCarInsurance = undefined;
  ssnReview = false;
  isPendingMVRReview = false;
  isPendingAgeReview = false;
  report;
  person;
  signatureDataUrl;
  redirect_url = "";
  _unauthorized = false;
  _error = false;
  _errorMessage = "";
  _tokens = [];

  checkReviewModal = () => {
    this.review_modal_checked = true;
  };
  setLoading = loading => {
    if (typeof loading !== "boolean") return;
    this.loading = loading;
  };
  setReadOnly = read_only => {
    if (typeof read_only !== "boolean") return;
    this.read_only = read_only;
  };
  setConsent = consent => {
    if (typeof consent !== "boolean") return;
    this.consent = consent;
  };

  setDisableAssentButton = disable => {
    if (typeof disable !== "boolean") return;
    this.disableAssentButton = disable;
  };

  setConfirm = confirm => {
    if (typeof confirm !== "boolean") return;
    this.confirm = confirm;
    this.confirmTimestamp = new Date().toISOString();
  };

  setCertify = certify => {
    if (typeof certify !== "boolean") return;
    this.certify = certify;
    this.certifyTimestamp = new Date().toISOString();
  };

  setSigned = signed => {
    if (typeof signed !== "boolean") return;
    this.signed = signed;
    this.signedTimestamp = new Date().toISOString();
  };

  setName = name => {
    if (typeof name !== "string") return;
    this.name = name;
  };

  setPartner = partner => {
    if (typeof partner !== "string") return;
    this.partner = partner;
  };

  setAddressForFcra = address_for_fcra => {
    if (typeof address_for_fcra !== "string") return;
    this.address_for_fcra = address_for_fcra;
  };
  setUser = user => {
    if (typeof user !== "number") return;
    this.user = user;
  };

  setRedirectUrl = url => {
    if (typeof url !== "string") return;
    this.redirect_url = url;
  };

  setShouldScheduleDrugTest = (drugTestData = {}) => {
    const { status } = drugTestData || {};
    const isBGCInitiated = this.status === PROFILE_STATUS.initiated;
    this.shouldScheduleDrugTest =
      !this.ssnReview &&
      !isBGCInitiated &&
      DRUG_TEST_PENDING_SCHEDULING_STATUS.includes(status);
  };

  /* must be replaced by turn shared library */
  translateStatus_extended(status, adverse_action) {
    if (status == "review__so") {
      return "Compliance Review";
    }
    return translateStatus(status, adverse_action);
  }

  setData = (data = {}) => {
    const report = data.background_report;
    this.data = data;
    this.processing_checks = data.processing_checks;
    this.fileNumber = data.report_id;
    this.email = data.email;
    this.fullName = toTitleCase(data.display_name);
    this.phoneNumber = data.phone_number;
    this.turnID = data.turn_id;
    this.worker_status = data.worker_status;
    this.status = this.translateStatus_extended(data.worker_status);

    this.report = report;
    this.person = report.person_report;
    this.setChecks(report.checks);
    this.setAddresses(report);
    this.ssn = report.ssn;
    if (this.report.candidate_provided_data)
      this.candidate_provided_zipcode = report.candidate_provided_data.zipcode;
    if (this.report.candidate_provided_data)
      this.candidate_provided_dob =
        report.candidate_provided_data.date_of_birth;
    if (this.report.candidate_provided_data)
      this.doSSNTrace = report.ssn.number !== "*****";
    if (report.candidate_provided_data)
      this.hasCarInsurance = report.candidate_provided_data.has_car_insurance;
    this.setReadOnly(data.read_only);
    this.review_modal_checked = data.read_only;
    this.setGeocodeData();
    if (data.do_drug_test) {
      this.doDrugTesting = true;
      this.drugTestingResults = data.drug_testing;
      this.setShouldScheduleDrugTest(data.drug_testing);
    }

    this.setLoading(false); // once all the data is loaded release the UI
  };

  setChecks = (checks = {}) => {
    this.criminal = disable_check(env().REACT_APP_DISABLE_REPORT_CRIMINAL)
      ? null
      : validateCheck(checks.criminal);
    this.federal = disable_check(env().REACT_APP_DISABLE_FEDERAL_CHECK)
      ? null
      : checks.federal;
    this.sexOffender = disable_check(env().REACT_APP_DISABLE_SEX_OFFENDER)
      ? null
      : validateCheck(checks.sex_offender);
    this.watchlist = disable_check(env().REACT_APP_DISABLE_WATCHLIST)
      ? null
      : validateCheck(checks.watchlist);
    if (checks.mvr !== ON_DEMAND_MVR)
      this.mvr = checks.mvr ? validateMVRCheck(checks.mvr) : this.mvr;
    this.setOneOffChecks(checks.oneoff);
  };

  setOneOffChecks = (reports = []) => {
    if (_.isEmpty(reports)) return;

    const oneOffChecks = [];

    reports.forEach(report => {
      report.name === ON_DEMAND_CRIM
        ? this.setOnDemandCountyCriminalRecords(report)
        : oneOffChecks.push(report);
    });

    if (!_.isEmpty(oneOffChecks))
      this.oneOffChecks = validateCheck(oneOffChecks);
  };

  setOnDemandCountyCriminalRecords = async (report = {}) => {
    if (_.isEmpty(report)) return;

    const { custom_inputs, name, report_url, resolution, state } = report;
    const _resolution = _.upperCase(resolution);
    const criminalRecord = validateCheck([
      {
        county: this.getOnDemandCrimCounty(custom_inputs),
        has_hits: this.getOnDemandCrimHasHits(state, _resolution),
        name,
        report_url: report_url,
        resolution: _resolution,
        status:
          state === FULFILLED && _resolution === _.upperCase(CLEAR)
            ? READY
            : PROCESSING_ON_DEMAND_CRIM_STATUS.includes(state)
            ? PROCESSING
            : state,
        value: this.getOnDemandCrimHasHits(state, _resolution)
      }
    ]);
    this.criminal = _.isEmpty(this.criminal)
      ? [...criminalRecord]
      : [...this.criminal, ...criminalRecord];
  };

  getOnDemandCrimCounty = (custom_inputs = {}) => {
    if (_.isEmpty(custom_inputs)) return;

    const {
      ON_DEMAND_CRIM_COUNTY: county = "",
      ON_DEMAND_CRIM_STATE: state = ""
    } = custom_inputs;
    return `${_.upperCase(state)}-${_.upperCase(county)}`;
  };

  getOnDemandCrimHasHits = (state = "", resolution = "") => {
    // This aberration is due to the validations for icon and color expecting an array and checking its length
    if (
      state === ATTENTION ||
      state === PENDING ||
      state === WITHDRAWN ||
      resolution === _.upperCase(PENDING) ||
      resolution === "UNSOLVED"
    ) {
      return ["1"];
    }
    return [];
  };

  setAddresses = (report = {}) => {
    if (report.checks && report.checks.addresses) {
      this.addresses = report.checks.addresses;
    } else if (report.current_address) {
      this.addresses = [report.current_address];
    } else {
      this.addresses = null;
    }
  };

  storeImage = dataUrl => {
    this.signatureDataUrl = dataUrl;
  };

  setGeocodeData = async () => {
    if (
      !this.candidate_provided_zipcode ||
      this.candidate_provided_zipcode.length !== 5
    ) {
      this.geocodeData = null;
      return;
    }
    const resp = await turnAPI.getZipCode(this.candidate_provided_zipcode);
    if (!resp.ok || resp.status !== 200) return;
    this.geocodeData = resp.data;
  };

  handleUnauthorized = data => {
    if (!data) return;
    if (data.expected) this.setTokens(data.expected);
    if (data.message) this.setErrorMessage(data.message);
    this.setUnauthorized(true);
    this.setError(true);
  };

  setUnauthorized = bool => {
    if (_.isBoolean(bool)) this._unauthorized = bool;
  };

  setError = bool => {
    if (_.isBoolean(bool)) this._error = bool;
  };

  setErrorMessage = message => {
    if (_.isString(message)) this._errorMessage = message;
  };

  setTokens = options => {
    if (_.isArray(options)) this._tokens = options;
  };

  setSsnReview = this.setBoolFactory("ssnReview", false);
  setIsPendingMVRReview = this.setBoolFactory("isPendingMVRReview", false);
  setIsPendingAgeReview = this.setBoolFactory("isPendingAgeReview", false);

  get stateIsPendingMVRReview() {
    if (!this.isPendingMVRReview) return null;
    return this.isPendingMVRReview;
  }

  get stateIsPendingAgeReview() {
    if (!this.isPendingAgeReview) return null;
    return this.isPendingAgeReview;
  }

  get stateAbbr() {
    if (!this.geocodeData) return null;
    return _.get(this.geocodeData, "state");
  }

  get consentDate() {
    if (!this.data.consent_date) return null;
    const date = new Date(1000 * this.data.consent_date);
    return `${date.getUTCMonth() +
      1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
  }
  get completedDate() {
    if (!this.data.completed_date) {
      if (this.status === "pending" || this.status === "consider")
        return `Possible Adverse information is being reviewed by ${this.partner}`;
      if (this.status === "consent")
        return "This report is waiting for your consent.";
      return "";
    }
    const date = new Date(1000 * this.data.completed_date);
    return `Completed on: ${date.getUTCMonth() +
      1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
  }
  get reportDate() {
    const date = new Date(1000 * this.data.report_date);
    return `${date.getUTCMonth() +
      1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
  }
  get orderDate() {
    const date = new Date(1000 * this.data.order_date);
    return `${date.getUTCMonth() +
      1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
  }
  get currentAddress() {
    const current = this.report.current_address;
    if (!current) return "";
    const street = toTitleCase(current.address1);
    const city = toTitleCase(current.city);
    return `${street} ${city} ${current.state} ${current.zip}`;
  }

  get currentAddressFull() {
    const current = this.report.current_address;
    return current ? this.report.current_address : null;
  }

  get currentState() {
    if (_.isEmpty(this.report) || _.isEmpty(this.report.current_address))
      return "";
    return this.report.current_address.state;
  }

  get ssnStatus() {
    if (!this.ssn) return "";
    return toTitleCase(this.ssn.status);
  }
  get ssnNameStatus() {
    if (!this.ssn) return "";
    return toTitleCase(this.ssn.name_status);
  }
  get ssnVerified() {
    return this.ssnStatus === "Verified" && this.ssnNameStatus === "Matched";
  }
  get dobStatus() {
    if (!this.ssn) return "";
    return toTitleCase(this.ssn.dob_status);
  }
  get ssnMortality() {
    if (!this.ssn) return "";
    return !this.ssn.deceased_date
      ? "Not Deceased"
      : toTitleCase(this.ssn.deceased_date);
  }
  get driversLicense() {
    if (!this.report || !this.report.candidate_provided_data)
      return "Not Provided";
    return !this.report.candidate_provided_data.drivers_license_number
      ? "Not Provided"
      : this.report.candidate_provided_data.drivers_license_number;
  }
  get driversLicenseState() {
    if (
      !this.report ||
      !_.get(this.report, "candidate_provided_data.drivers_license_state")
    )
      return null;
    return _.get(this.report, "candidate_provided_data.drivers_license_state");
  }
  get expiredLicenses() {
    if (typeof this.mvr === "string") return [];
    if (!_.isObject(this.mvr) || !this.mvr.licenses) return [];
    return this.mvr.licenses.filter(license =>
      moment(license.expiration_date).isBefore(moment())
    );
  }

  get footerReady() {
    if (this.disableAssentButton) return false;
    return this.certify && this.name && this.signed;
  }

  get assentData() {
    const data = {
      signature: this.name,
      signature_image: this.signatureDataUrl,
      timestamps: {
        authorization: [this.confirmTimestamp],
        signature: [this.signedTimestamp]
      }
    };

    if (env().REACT_APP_FCRA_PARTNER_COMPLIANCE_ENFORCE_METADATA) {
      data.timestamps = {
        authorization: [this.confirmTimestamp],
        signature: [this.signedTimestamp]
      };
    }

    return data;
  }

  get unauthorized() {
    return this._unauthorized;
  }

  get error() {
    return this._error;
  }

  get errorMessage() {
    return this._errorMessage;
  }

  get tokens() {
    return this._tokens;
  }

  get sexOffenderStatus() {
    if (this.stateIsPendingMVRReview || this.stateIsPendingAgeReview)
      return REVIEW;
    if (!_.isEmpty(this.sexOffender)) return HIT;
    return CLEAR;
  }
  get criminalStatus() {
    if (this.stateIsPendingMVRReview || this.stateIsPendingAgeReview)
      return REVIEW;
    if (!this.criminal) return null;
    // When hits are not grouped by county validate with old way
    if (
      this.criminal.every(val => val.name !== ON_DEMAND_CRIM) &&
      !this.criminal.some(val => val.value) &&
      !_.isEmpty(this.criminal)
    )
      return HIT;
    // When it is a ON_DEMAND_CRIM, we should check if it has hits
    if (
      this.criminal.some(
        val => val.name === ON_DEMAND_CRIM && val.has_hits.length > 0
      )
    )
      return HIT;
    if (this.criminal.some(val => val.value && val.value.length > 0))
      return HIT;
    return CLEAR;
  }
  get drugTestingResultsStatus() {
    if (!this.doDrugTesting) return null;
    const { resolution } = this.drugTestingResults || {};

    if (resolution === CLEAR) return CLEAR;
    if (resolution === PENDING) return PENDING;
    if (resolution === WITHDRAWN) return WITHDRAWN;
    return PROCESSING;
  }
  get federalStatus() {
    if (this.stateIsPendingMVRReview || this.stateIsPendingAgeReview)
      return REVIEW;
    if (!this.federal) return null;
    if (
      this.federal.some(val => val["status"] === "ready") &&
      this.federal.some(val => val["values"].length > 0)
    )
      return HIT;
    return CLEAR;
  }
  get mvrStatus() {
    const data = this.mvr;
    if (this.stateIsPendingMVRReview || this.stateIsPendingAgeReview)
      return REVIEW;
    if (_.isEmpty(data) || _.isString(data) || !_.isObject(data)) return CLEAR;
    if (data.status != "clear") return HIT;
    return CLEAR;
  }
  get watchlistStatus() {
    if (this.stateIsPendingMVRReview || this.stateIsPendingAgeReview)
      return REVIEW;
    if (!_.isEmpty(this.watchlist)) return HIT;
    return CLEAR;
  }

  get oneOffChecksStatus() {
    if (this.stateIsPendingMVRReview || this.stateIsPendingAgeReview)
      return REVIEW;
    if (!this.oneOffChecks) return null;
    if (
      !this.oneOffChecks.every(
        ({ resolution }) => resolution.toLowerCase() === CLEAR
      )
    )
      return HIT;
    return CLEAR;
  }

  get sexOffenderIcon() {
    if (this.sexOffenderStatus === REVIEW) return faPauseCircle;
    if (this.sexOffenderStatus === HIT) return faExclamationTriangle;
    return faCheckCircle;
  }
  get criminalIcon() {
    if (this.criminalStatus === REVIEW) return faPauseCircle;
    if (this.criminalStatus === HIT) return faExclamationTriangle;
    return faCheckCircle;
  }
  get drugTestingResultsIcon() {
    if (this.drugTestingResultsStatus === PROCESSING) return faSpinner;
    if (this.drugTestingResultsStatus === PENDING) return faExclamationTriangle;
    if (this.drugTestingResultsStatus === WITHDRAWN)
      return faExclamationTriangle;
    return faCheckCircle;
  }
  get federalIcon() {
    if (this.federalStatus === REVIEW) return faPauseCircle;
    if (this.federalStatus === HIT) return faExclamationTriangle;
    return faCheckCircle;
  }
  get mvrIcon() {
    if (this.mvrStatus === REVIEW) return faPauseCircle;
    if (this.mvrStatus === HIT) return faExclamationTriangle;
    return faCheckCircle;
  }
  get watchlistIcon() {
    if (this.watchlistStatus === REVIEW) return faPauseCircle;
    if (this.watchlistStatus === HIT) return faExclamationTriangle;
    return faCheckCircle;
  }

  get oneOffChecksIcon() {
    if (this.oneOffChecksStatus === REVIEW) return faPauseCircle;
    if (this.oneOffChecksStatus === HIT) return faExclamationTriangle;
    return faCheckCircle;
  }

  get sexOffenderColor() {
    if (this.sexOffenderStatus === REVIEW) return orange;
    if (this.sexOffenderStatus === HIT) return red;
    return green;
  }
  get criminalColor() {
    if (this.criminalStatus === REVIEW) return orange;
    if (this.criminalStatus === HIT) return red;
    return green;
  }
  get drugTestingResultsColor() {
    if (this.drugTestingResultsStatus === PROCESSING) return periwinkle;
    if (this.drugTestingResultsStatus === PENDING) return orange;
    if (this.drugTestingResultsStatus === WITHDRAWN) return red;
    return green;
  }
  get federalColor() {
    if (this.federalStatus === REVIEW) return orange;
    if (this.federalStatus === HIT) return red;
    return green;
  }
  get mvrColor() {
    if (this.mvrStatus === REVIEW) return orange;
    if (this.mvrStatus === HIT) return red;
    return green;
  }
  get watchlistColor() {
    if (this.watchlistStatus === REVIEW) return orange;
    if (this.watchlistStatus === HIT) return red;
    return green;
  }

  get oneOffChecksColor() {
    if (this.oneOffChecksStatus === REVIEW) return orange;
    if (this.oneOffChecksStatus === HIT) return red;
    return green;
  }

  get checksSummaryData() {
    return [
      {
        check: "SSN Trace",
        shouldNotRender: !this.doSSNTrace,
        status: "",
        icon: faCheckCircle,
        color: green
      },
      {
        check: "Addresses",
        shouldNotRender: !(this.doSSNTrace && !_.isEmpty(this.addresses)),
        status: "",
        icon: faCheckCircle,
        color: green
      },
      {
        check: "Sex Offender Check",
        shouldNotRender: !this.sexOffender,
        status: this.sexOffenderStatus,
        icon: this.sexOffenderIcon,
        color: this.sexOffenderColor
      },
      {
        check: "Criminal Records",
        shouldNotRender: !this.criminal,
        status: this.criminalStatus,
        icon: this.criminalIcon,
        color: this.criminalColor
      },
      {
        check: "Drug Test Results",
        shouldNotRender: !this.doDrugTesting,
        status: this.drugTestingResultsStatus,
        icon: this.drugTestingResultsIcon,
        color: this.drugTestingResultsColor
      },
      {
        check: "Federal Check",
        shouldNotRender: !this.federal,
        status: this.federalStatus,
        icon: this.federalIcon,
        color: this.federalColor
      },
      {
        check: "MVR",
        shouldNotRender: _.isEmpty(this.mvr),
        status: this.mvrStatus,
        icon: this.mvrIcon,
        color: this.mvrColor
      },
      {
        check: "Watchlist",
        shouldNotRender: !this.watchlist,
        status: this.watchlistStatus,
        icon: this.watchlistIcon,
        color: this.watchlistColor
      },
      {
        check: "Other Checks",
        shouldNotRender: !this.oneOffChecks,
        status: this.oneOffChecks && this.oneOffChecksStatus,
        icon: this.oneOffChecksIcon,
        color: this.oneOffChecksColor
      }
    ];
  }
  get profileStatus() {
    if (this.status === "consent") return "Confirm";
    if (
      this.status === "approved" ||
      this.status === "pending" ||
      this.status === "rejected" ||
      this.status === "pending__first_notice" ||
      this.status === "pending__second_notice" ||
      this.status === "pending__resolved" ||
      this.status === "pending__dispute"
    )
      return "Ready";
    if (this.status === "processing" || this.status === "review")
      return "Processing";
    if (
      this.status === "initiated" ||
      this.status === "emailed" ||
      this.status === "withdrawn"
    )
      return "Initiated";
    return _.upperFirst(this.status);
  }
}

decorate(CandidateReportModel, {
  loading: observable,
  confirm: observable,
  certify: observable,
  consent: observable,
  disableAssentButton: observable,
  processing_checks: observable,
  review_modal_checked: observable,
  signed: observable,
  name: observable,
  partner: observable,
  address_for_fcra: observable,
  user: observable,
  ssn: observable,
  doSSNTrace: observable,
  data: observable,
  read_only: observable,
  fileNumber: observable,
  email: observable,
  fullName: observable,
  mvr: observable,
  phoneNumber: observable,
  turnID: observable,
  geocodeData: observable,
  candidate_provided_zipcode: observable,
  candidate_provided_dob: observable,
  candidateConsentEmailId: observable,
  report: observable,
  person: observable,
  signatureDataUrl: observable,
  redirect_url: observable,
  ssnReview: observable,
  isPendingMVRReview: observable,
  isPendingAgeReview: observable,
  checkReviewModal: action,
  setLoading: action,
  _unauthorized: observable,
  _error: observable,
  _errorMessage: observable,
  _tokens: observable,
  status: observable,
  setReadOnly: action,
  setConsent: action,
  setDisableAssentButton: action,
  setConfirm: action,
  setCertify: action,
  setSigned: action,
  setName: action,
  setPartner: action,
  setAddressForFcra: action,
  setUser: action,
  setRedirectUrl: action,
  setData: action,
  setChecks: action,
  setAddresses: action,
  storeImage: action,
  setGeocodeData: action,
  handleUnauthorized: action,
  setError: action,
  setErrorMessage: action,
  setSsnReview: action,
  setIsPendingMVRReview: action,
  tokens: computed,
  unauthorized: computed,
  error: computed,
  errorMessage: computed,
  stateIsPendingMVRReview: computed,
  stateAbbr: computed,
  reportDate: computed,
  consentDate: computed,
  completedDate: computed,
  orderDate: computed,
  currentAddress: computed,
  currentAddressFull: computed,
  currentState: computed,
  ssnStatus: computed,
  ssnNameStatus: computed,
  ssnVerified: computed,
  dobStatus: computed,
  ssnMortality: computed,
  driversLicense: computed,
  driversLicenseState: computed,
  footerReady: computed,
  assentData: computed,
  sexOffenderStatus: computed,
  criminalStatus: computed,
  drugTestingResultsStatus: computed,
  federalStatus: computed,
  mvrStatus: computed,
  watchlistStatus: computed,
  sexOffenderIcon: computed,
  criminalIcon: computed,
  drugTestingResultsIcon: computed,
  federalIcon: computed,
  mvrIcon: computed,
  watchlistIcon: computed,
  sexOffenderColor: computed,
  criminalColor: computed,
  drugTestingResultsColor: computed,
  federalColor: computed,
  mvrColor: computed,
  watchlistColor: computed,
  profileStatus: computed
});

export default CandidateReportModel;
