import styled from "styled-components";

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 64px;
  padding-right: 64px;
  height: 70px;
  background-color: ${props =>
    props.background ? props.background : "inherit"};
  z-index: 1;
  align-items: center;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 5px;

  pointer-events: ${props =>
    props.disableNavigation ? "none" : "auto"} !important;
`;

export default NavBar;
