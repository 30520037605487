import React from "react";
import { PropTypes } from "prop-types";
import DetailItem from "../../components/DetailItem/";
import { WorkerStyles as styles } from "../../styles";

const WorkerDetails = ({ details, showSSNTrace }) => {
  let worker_details = details.filter(detail => !!detail.value);
  if (!showSSNTrace)
    worker_details = worker_details.filter(detail => detail.label !== "SSN");
  return (
    <div>
      {worker_details.map((detail, index) => (
        <div
          style={index + 1 === worker_details.length ? {} : styles.divider}
          key={index}
        >
          <DetailItem
            detail={detail}
            containerStyle={styles.detailContainer}
            labelStyle={styles.detailLabel}
            descriptionStyle={styles.detailDescription}
          />
        </div>
      ))}
    </div>
  );
};
WorkerDetails.propTypes = {
  details: PropTypes.array,
  showSSNTrace: PropTypes.bool
};
WorkerDetails.defaultProps = {
  details: [],
  showSSNTrace: true
};

export default WorkerDetails;
