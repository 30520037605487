import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class CanadaCommentsReview extends PureComponent {
  render() {
    return (
      <>
        <span data-qa="comment_review">{this.props.comments}</span>
      </>
    );
  }
}

CanadaCommentsReview.propTypes = {
  comments: PropTypes.string
};

export default CanadaCommentsReview;
