import React from "react";
import PropTypes from "prop-types";
import FormattedHeader from "../FormattedHeader";
import DetailItem from "../DetailItem";
import { renderDetailValue } from "turn-shared";

const ListItemHighlight = ({ highlight, title, deep = false }) =>
  highlight ? (
    <div style={{ padding: "10px 5% 5% 5%" }}>
      <DetailItem
        detail={{
          label: <FormattedHeader message={title} />,
          value: renderDetailValue(highlight, deep)
        }}
        width="100%"
      />
    </div>
  ) : null;

ListItemHighlight.propTypes = {
  highlight: PropTypes.string,
  title: PropTypes.string,
  deep: PropTypes.bool
};

export default ListItemHighlight;
