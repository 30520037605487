import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { ErrorDialogStyles as styles } from "../../styles";
import messages from "./messages";
import { observer } from "mobx-react/custom";
import "./index.css";
import Img from "../Img";
import Button from "@material-ui/core/Button";

/**
 * Composable ErrorDialog, inherits all props from `Dialog`. It's `react-intl` agnostic. It allows
 * rendering a Dialog like the generic "Oops!".
 *
 * @example
 *    <ErrorDialogComponent
 *      autoScrollBodyContent
 *      modal
 *      open={this.cohort.invalidCity}
 *      onOkClicked={() => this.cohort.setInvalidCity(false)}
 *      title="This City is not yet available through Worker Sourcing"
 *      message="If you have any questions please contact support@turn.ai
 *    />
 *
 * @param {object} props
 * @param {ReactNode} props.img (Optional) Rendered above the texts
 * @param {string} props.string Title to display
 * @param {string} props.message Message to display
 * @param {string} props.dismissLabel (Optional) String to render inside the dismiss button, defaults to "OK"
 * @param {string} props.onOkClicked Callback run when the dismiss button is clicked
 * @param {ReactNode} props.buttons Buttons to render instead of a single "OK" button
 */
export const ErrorDialogComponent = ({
  img,
  title,
  message,
  dismissLabel,
  onOkClicked,
  buttons,
  ...props
}) => {
  const buttonContainerStyles = img
    ? styles.buttonContainer
    : {
        ...styles.buttonContainer,
        height: "auto"
      };

  // TODO: Improve further by rendering `children` inside `errorMessages` div
  return (
    <Dialog onClose={onOkClicked} {...props}>
      <DialogContent style={styles.dialogContent}>
        {img && (
          <div style={styles.imgContainer}>
            <div style={styles.imgDimensionsContainer}>{img}</div>
          </div>
        )}
        <div style={styles.errorMessages}>
          <div style={styles.gralErrorMessageContainer}>{title}</div>
          <div style={styles.specificErrorMessageContainer}>{message}</div>
        </div>
        <div style={buttonContainerStyles}>
          {buttons || (
            <Button
              onClick={onOkClicked}
              id="ok_button"
              key="1"
              style={styles.turquoiseButton}
            >
              {dismissLabel || "OK"}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

/**
 * REVIEW for DEPRECATION: it's currently used all over the codebase, and it's also
 * not very composable (you can't edit the ErrorDialog behavior) and understandable.
 *
 * @param {object} props
 * @param {bool} open
 * @param {function} onPress
 * @param {message} message
 */
const ErrorDialog = ({ open, onPress, message }) => (
  <ErrorDialogComponent
    fullWidth
    modal
    autoScrollBodyContent
    classes={{
      paper: "error-dialog-modal"
    }}
    open={open}
    onOkClicked={onPress}
    img={<Img type="error" style={styles.headerImage} />}
    title={<FormattedMessage {...messages.title} />}
    message={message ? message : <FormattedMessage {...messages.error} />}
    dismissLabel={<FormattedMessage {...messages.ok} />}
  />
);

ErrorDialog.propTypes = {
  open: PropTypes.bool,
  onPress: PropTypes.func,
  message: PropTypes.string
};

export default observer(ErrorDialog);
