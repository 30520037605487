import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { observer } from "mobx-react";
import { List, ListItem } from "material-ui/List";
import Done from "material-ui/svg-icons/action/done";
import ArrowRight from "material-ui/svg-icons/hardware/keyboard-arrow-right";
import ArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import { primary, white, noticeWarning } from "../../styles/constants";
import { WorkerStyles as styles } from "../../styles";
import Container from "../Container";
import ListDetailView from "../ListDetailView";
import CollapsibleListItem from "../ListDetailView/collapsible-list-item";
import Drawer from "../Drawer";
import { valueExists, validators } from "turn-shared";
import { STATUSES, getStatus } from "../../utils/render-worker-approval";
import IconContainer from "../IconContainer";

const { isArray } = validators;

const CheckList = observer(
  class CheckList extends Component {
    static propTypes = {
      worker: PropTypes.object.isRequired,
      items: PropTypes.array,
      renderCheck: PropTypes.func,
      uuid: PropTypes.string,
      noCollapse: PropTypes.bool,
      expand: PropTypes.bool
    };
    static defaultProps = {
      worker: {}
    };
    constructor(props) {
      super(props);
      this.state = {
        open: -1
      };
    }
    getHits = item => {
      if (Array.isArray(item.countItems)) return item.countItems.length;
      if (Array.isArray(item.value)) return item.value.length;
      return 0;
    };
    getItemStatus = val => {
      if (val.status === "ready" && !val.value) return "clear";
      if (val.status === "ready" && val.value) return "consider";
      return val.status;
    };
    getNullItems = () => this.props.items.filter(item => item.value);
    pickColor = item => {
      const status = getStatus(item.value);
      switch (status) {
        case STATUSES.DONE:
        case STATUSES.PROCESSING:
          return primary;
        case STATUSES.WARNING:
        default:
          return noticeWarning;
      }
    };
    toggleOpen(item, key) {
      if (!valueExists(item.value)) return;
      if (this.state.open === key) {
        this.setState({ open: -1 });
      } else {
        this.setState({ open: key });
      }
    }
    renderToggle = (item, key) => {
      if (!valueExists(item.value)) return null;
      const color = this.pickColor(item);
      return this.state.open === key ? (
        <ArrowDown color={color} />
      ) : (
        <ArrowRight color={color} />
      );
    };
    renderIcon = item => {
      if (!this.props.renderCheck) return <Done color={primary} />;
      if (Array.isArray(item.countItems))
        return this.props.renderCheck(item.countItems);
      return this.props.renderCheck(item.value);
    };
    renderNumberOfChecks = item => {
      if (!this.getHits(item)) return null;
      const color = this.pickColor(item);
      const style = styles.getCheckNumber(color);
      return <div style={style}>{this.getHits(item)}</div>;
    };
    renderListItem = (item, key) => {
      if (this.props.noCollapse) {
        return (
          <div key={key} data-test="list-item">
            {this.renderListDetailView(item)}
          </div>
        );
      }
      return (
        <div key={key} data-test="list-item">
          <ListItem
            style={styles.listItem}
            leftIcon={item.icon}
            rightIcon={this.renderToggle(item, key)}
            onClick={() => this.toggleOpen(item, key)}
          >
            {item.label}
            <IconContainer>
              {this.renderNumberOfChecks(item)}
              {this.renderIcon(item)}
            </IconContainer>
          </ListItem>
          <Drawer open={this.state.open === key}>
            {this.renderListDetailView(item)}
          </Drawer>
        </div>
      );
    };
    renderListDetailView = item => {
      if (item.groupByProperty) {
        return (
          <div
            style={{
              backgroundColor: "rgb(248, 248, 248)",
              border: "2px solid rgb(224, 224, 224)"
            }}
          >
            {item.value.map((val, key) => (
              <CollapsibleListItem
                key={key}
                title={val[item.groupByProperty]}
                divider={key !== 0}
                readyStatus={this.getItemStatus(val)}
                simple
                clear={!val.value}
                count={isArray(val.value) ? val.value.length : 0}
              >
                <div style={{ paddingLeft: 15, paddingRight: 5 }}>
                  <ListDetailView
                    data={val.value ? [...val.value] : val.value}
                    masterKey={item.masterKey}
                    exclude={item.exclude}
                    secondary={item.secondary}
                    expand={this.props.expand}
                  />
                </div>
              </CollapsibleListItem>
            ))}
          </div>
        );
      }
      return (
        <ListDetailView
          data={item.value}
          masterKey={item.masterKey}
          exclude={item.exclude}
          secondary={item.secondary}
          containerStyle={{ padding: 0 }}
          expand={this.props.expand}
        />
      );
    };
    render() {
      if (
        !Array.isArray(this.props.items) ||
        this.props.items.length === 0 ||
        _.isEmpty(this.getNullItems()) ||
        this.props.worker.workerIsPendingReview
      )
        return null;
      return (
        <Container background={white} small margin="0">
          <List>
            {this.props.items.map((item, key) =>
              item.value ? this.renderListItem(item, key) : null
            )}
          </List>
        </Container>
      );
    }
  }
);

export default CheckList;
