import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as CSVIcon } from "../../icons/csv-icon.svg";
import { ReactComponent as CheckIcon } from "../../icons/green-check-icon.svg";
import { ReactComponent as CloseIcon } from "../../icons/close-icon.svg";
import styles from "./styles.js";

function sizeValidator(file) {
  // convert bytes to megabytes
  const fileSize = file.size / 1000000;
  const maxLength = 15;
  if (fileSize > maxLength) {
    return {
      code: "file-too-large",
      message: `File is larger than ${maxLength} MB`
    };
  }

  return null;
}

function Dropzone({ setAcceptedFiles }) {
  const [files, setFiles] = React.useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections
  } = useDropzone({
    onDrop,
    accept: ["text/csv", "application/vnd.ms-excel"],
    validator: sizeValidator
  });

  useEffect(() => {
    setAcceptedFiles(files);
  }, [files]);

  const renderActive = () => {
    return (
      <div style={styles.dropzone.containerActive}>
        <CSVIcon style={styles.dropzone.icon} />
        <h3 style={styles.dropzone.title}>Drop Files Here</h3>
        <p style={styles.dropzone.subtitle}>
          Drag and drop or <span style={styles.modal.downloadLink}>browse</span>{" "}
          to upload a CSV file.
        </p>
        <p style={styles.dropzone.text}>Maximum upload file size: 15MB.</p>
      </div>
    );
  };

  const renderNormal = () => {
    return (
      <div style={styles.dropzone.container}>
        <CSVIcon style={styles.dropzone.icon} />
        <h3 style={styles.dropzone.title}>Bulk Upload Candidates</h3>
        <p style={styles.dropzone.subtitle}>
          Drag and drop or <span style={styles.modal.downloadLink}>browse</span>{" "}
          to upload a CSV file.
        </p>
        <p style={styles.dropzone.text}>Maximum upload file size: 15MB.</p>
      </div>
    );
  };

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? renderActive() : renderNormal()}
      </div>
      {files.length > 0 && (
        <div style={styles.dropzone.files.container}>
          <div style={styles.dropzone.files.header}>
            <h4 style={styles.dropzone.files.titleSuccess}>
              Success! Please upload or cancel.
            </h4>
            <div style={styles.dropzone.files.check}>
              <CheckIcon style={{ margin: 5 }} />
            </div>
          </div>
          <div>
            {
              <ul style={{ listStyle: "none", padding: 0 }}>
                {files.map((file, key) => (
                  <li
                    key={`file-${key}`}
                    style={styles.dropzone.files.file}
                  >{`${file.name} - ${Math.round(file.size / 1000) +
                    1} kb`}</li>
                ))}
              </ul>
            }
          </div>
        </div>
      )}
      {fileRejections.length > 0 && (
        <div style={styles.dropzone.files.container}>
          <div style={styles.dropzone.files.header}>
            <h4 style={styles.dropzone.files.titleFailed}>Upload failed!</h4>
            <div style={styles.dropzone.files.close}>
              <CloseIcon style={{ margin: 5 }} />
            </div>
          </div>
          <div>
            {
              <ul style={{ listStyle: "none", padding: 0 }}>
                {fileRejections.map(({ file, errors }, key) => (
                  <li
                    key={`file-${key}`}
                    style={styles.dropzone.files.file}
                  >{`${file.name} - ${Math.round(
                    file.size / 1000
                  )} kb / ${errors[0].message.replace(
                    "one of text/csv, application/vnd.ms-excel",
                    "CSV"
                  )}`}</li>
                ))}
              </ul>
            }
          </div>
        </div>
      )}
    </>
  );
}

export default Dropzone;
