import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { WorkerSettingsStyles as styles } from "../../../styles";
import MembersTable from "./_MembersTable";
import MemberModal from "./_MemberModal";

export class TeamMembers extends Component {
  static defaultProps = {
    model: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalReady: true,
      member: {
        first_name: "",
        last_name: "",
        email: "",
        password: ""
      },
      teamMembers: null,
      isNewMember: true
    };
  }

  setModalReady = modalReady => {
    if (!_.isBoolean(modalReady)) return;
    this.setState({ modalReady });
  };

  componentDidMount() {
    const data =
      this.props.data &&
      this.props.data.map(dataItem => ({
        id: dataItem.value[4],
        name: dataItem.value[0],
        email: dataItem.value[1],
        first_name: dataItem.value[2],
        last_name: dataItem.value[3],
        is_primary_contact: dataItem.isPrimaryContact || false
      }));
    this.setState({ teamMembers: data });
  }

  reloadPage = () => {
    this.props.history.go("/settings");
  };

  handleChange = (field, data) => {
    const member = this.state.member;
    member[field] = data;
    this.setState({ member });
  };

  handleOpen = () => {
    this.setState({
      isModalOpen: true,
      member: { first_name: "", email: "" },
      isNewMember: true
    });
  };

  handleClose = () => {
    const { toggleOpenAddTeam } = this.props.model;
    if (_.isFunction(toggleOpenAddTeam)) toggleOpenAddTeam();
    this.setState({ isModalOpen: false });
  };

  handleSubmit = async isNew => {
    this.setModalReady(false);
    if (isNew) {
      await this.props.model.createTeamMember(this.state.member);
    } else {
      await this.props.model.updateTeamMember(
        this.state.member.id,
        this.state.member
      );
    }
    if (!this.props.model.exception) {
      this.setState({
        isModalOpen: false,
        member: { first_name: "", email: "" }
      });
      setTimeout(() => {
        this.setModalReady(true);
        this.reloadPage();
      }, 1000);
    } else {
      this.setModalReady(true);
    }
  };

  handleEdit = index => {
    const member = this.state.teamMembers[index];
    this.setState({
      isModalOpen: true,
      isNewMember: false,
      member
    });
  };

  handleDelete = async index => {
    const member = this.state.teamMembers[index];
    this.setReady(false);
    await this.props.model.deleteTeamMember(member.id);
    this.setReady(true);
    const teamMembers = this.state.teamMembers;
    teamMembers.splice(index, 1);
    this.setState({ teamMembers });
  };

  makePrimary = async index => {
    const member = this.state.teamMembers[index];
    this.setReady(false);
    await this.props.model.makePrimaryContact(member.id);
    this.setReady(true);

    setTimeout(() => this.reloadPage(), 1000);
  };

  setReady = ready => {
    const { model } = this.props;
    if (!model.setReady) return;
    model.setReady(ready);
  };

  render() {
    return (
      <div
        id="existingMembersList"
        style={styles.workerSettingsSection.container}
      >
        <MemberModal
          open={this.state.isModalOpen || this.props.model.openAddTeam}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          member={this.state.member}
          isNew={this.state.isNewMember}
          ready={this.state.modalReady}
        />
        <MembersTable
          actions={{
            handleEdit: this.handleEdit,
            handleDelete: this.handleDelete,
            makePrimary: this.makePrimary
          }}
          members={this.state.teamMembers}
          {...this.props}
        />
      </div>
    );
  }
}

export default withRouter(observer(TeamMembers));
