import styled from "styled-components";

const InputContainer = styled.div`
  width: 100%;

  & > div {
    width: 100%;
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
    & > div {
      & > input {
        padding: 16px 24px 16px 18px;
        font-size: 14px;
        color: #4b5563cc;
        font-weight: normal;

        &::placeholder {
          font-size: 14px;
          color: #4b5563cc;
          font-weight: normal;
          opacity: 1;
        }
      }
      &:before {
        content: none;
      }
      &:after {
        content: none;
      }
    }
  }
`;

export default InputContainer;
