import { defineMessages } from "react-intl";

export default defineMessages({
  triggerLabel: {
    id: "app.components.WorkerCheckModal.triggerLabel",
    defaultMessage: "Add Candidate"
  },
  confirmFullName: {
    id: "app.components.WorkerCheckModal.confirmFullName",
    defaultMessage: "Full Legal Name"
  },
  confirmName: {
    id: "app.components.WorkerCheckModal.confirmName",
    defaultMessage: "Name"
  },
  confirmLastName: {
    id: "app.components.WorkerCheckModal.confirmLastName",
    defaultMessage: "Last Name"
  },
  confirmPhone: {
    id: "app.components.WorkerCheckModal.confirmPhone",
    defaultMessage: "Mobile Number"
  },
  confirmEmail: {
    id: "app.components.WorkerCheckModal.confirmEmail",
    defaultMessage: "Email"
  },
  confirmSSN: {
    id: "app.components.WorkerCheckModal.confirmSSN",
    defaultMessage: "Social Security Number"
  },
  confirmPackage: {
    id: "app.components.WorkerCheckModal.confirmPackage",
    defaultMessage: "Partner Package"
  },
  emailSent: {
    id: "app.components.WorkerCheckModal.emailSent",
    defaultMessage:
      "We will perform the background check as soon as they provide consent."
  },
  trialExpired: {
    id: "app.components.WorkerCheckModal.trialExpired",
    defaultMessage:
      "We’re sorry. {reason}. Please contact {email} to sign up with Turn."
  },
  trialExpiredEcomm: {
    id: "app.components.WorkerCheckModal.trialExpiredEcomm",
    defaultMessage:
      "We’re sorry. {reason}. We'll redirect you to the checkout page."
  },
  trialExpiredChecks: {
    id: "app.components.WorkerCheckModal.trialExpiredChecks",
    defaultMessage: "You’ve reached the limit of checks for your trial"
  }
});
