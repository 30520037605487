/* eslint-disable no-alert */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import CircularProgress from "material-ui/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "material-ui/Checkbox";
import RaisedButton from "material-ui/RaisedButton";
import { PermissionStyles as styles } from "../../styles";
import { background } from "../../components/Img/sources";
import PermissionModel from "../../models/permission";
import { PageType, PageEvent } from "../../services/analytics/gtm";
import { ga } from "../../services";

import messages from "./messages";

const Permissions = observer(
  class Permissions extends Component {
    constructor(props) {
      super(props);
      this.model = new PermissionModel();
    }

    UNSAFE_componentWillMount() {
      this.auth = this.props.auth;
      this.model.getTerms(this.auth);
    }

    sendTerms = () => {
      if (
        this.model.selectedGLBA !== 0 &&
        this.model.selectedDPPA !== 0 &&
        this.model.agree
      ) {
        this.model.send(this.auth);
      } else {
        alert("Please complete all the fields");
      }
    };

    componentDidMount() {
      ga.send(PageType.Permission, PageEvent.pageLoaded);
    }

    render() {
      if (this.model.loading) {
        return (
          <div style={styles.progress}>
            <CircularProgress size={200} thickness={10} />
          </div>
        );
      }
      return (
        <div
          style={{
            backgroundImage: `url(${background})`,
            display: "flex",
            backgroundSize: "contain"
          }}
        >
          <div style={styles.content}>
            <h2 style={styles.title}>
              <FormattedMessage {...messages.title} />
            </h2>
            <p style={styles.p}>
              Federal law, along with our{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.turn.ai/terms"
              >
                Terms of Use
              </a>
              , requires you to have a permissible use to view certain personal
              information. The laws that generally govern these uses are the
              Drivers&apos; Privacy Protection Act (DPPA) and related state
              laws, and the Gramm-Leach-Bliley Act (GLBA). You will not be given
              access to the personal information on our platforms if you do not
              have a permissible use.
            </p>
            <p style={styles.p}>
              In addition to complying with these privacy acts, you should
              understand that, per the Fair Credit Reporting Act (FCRA), the
              data you access using our platforms may not be used as a factor in
              establishing a consumer&apos;s eligibility for credit, insurance,
              employment or other purposes.
            </p>
            <p style={styles.p}>
              There are criminal fines and civil liabilities for knowingly
              violating the provisions and restrictions of these acts.
            </p>
            <p style={styles.p}>
              With this in mind,{" "}
              <b>please specify your permissible use below.</b> Once you&apos;ve
              selected a permissible use, your choice will persist throughout
              your session, and you can set a default permissible use for later
              sessions.
            </p>
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>
                <FormattedMessage {...messages.titleGLBA} />
              </h3>
              <Select
                selectedMenuItemStyle={styles.selectedMenuItemStyle}
                style={styles.sectionSelect}
                value={this.model.selectedGLBA}
                onChange={e => {
                  this.model.selectGLBA(e.target.value);
                }}
                floatingLabelText="Please select the GLBA"
                menuItemStyle={styles.sectionMenuItem}
              >
                {this.model.GLBA.map(term => (
                  <MenuItem key={term.id} value={term.id}>
                    {term.description}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={styles.section}>
              <h3 style={styles.sectionTitle}>
                <FormattedMessage {...messages.titleDPPA} />
              </h3>
              <Select
                selectedMenuItemStyle={styles.selectedMenuItemStyle}
                style={styles.sectionSelect}
                value={this.model.selectedDPPA}
                onChange={e => {
                  this.model.selectDPPA(e.target.value);
                }}
                floatingLabelText="Please select the DPPA"
                menuItemStyle={styles.sectionMenuItem}
              >
                {this.model.DPPA.map(term => (
                  <MenuItem key={term.id} value={term.id}>
                    {term.description}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <p style={styles.p}>
              With regard to information subject to the DPPA, some state law
              permissible uses may vary from the uses identified above. In these
              instances, certain state information may not be available under
              each permissible use listed above or you may be asked to certify
              to a permissible use permitted by applicable state law to obtain
              information from a specific state.
            </p>
            <Checkbox
              checked={this.model.agree}
              onCheck={this.model.checkAgreement}
              style={styles.checkbox}
              label="I agree and certify that I will only use the information I view on
          Turn’s platforms in accordance with (i) the permissible uses
          selected above, (ii) those selected subsequently in connection with
          a specific information request, and (iii) Turn’s Terms and
          Conditions."
            />
            <RaisedButton
              onClick={this.sendTerms}
              label="Continue"
              fullWidth
              primary
            />
          </div>
        </div>
      );
    }
  }
);

Permissions.propTypes = {
  auth: PropTypes.object
};

export default Permissions;
