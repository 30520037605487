import { defineMessages } from "react-intl";

export default defineMessages({
  submitTo: {
    id: "app.containers.Report.submitTo",
    defaultMessage: "Your report has been submitted to "
  },
  review: {
    id: "app.containers.Report.review",
    defaultMessage: " for their review."
  },
  convenience: {
    id: "app.containers.Report.convenience",
    defaultMessage:
      "This is a convenience copy of your Background Report. Please print a copy of this report for your records."
  },
  mistake: {
    id: "app.containers.Report.mistake",
    defaultMessage: "If this is a mistake please email"
  },
  inTouch: {
    id: "app.containers.Report.inTouch",
    defaultMessage: " and we'll be in touch with you shortly."
  },
  app: {
    id: "app.containers.Report.app",
    defaultMessage:
      "You can also view your completed background check in our Turn Worker App."
  }
});
