const errorMessages = {
  fullName: {
    required: "Full Name is required",
    invalid:
      "We were unable to find this worker. Please try again or contact customer support",
    unknown:
      "Well, this is unexpected, an unknown error ocurred! Please try again."
  },
  email: {
    format: "This email address is not formatted correctly",
    refuted: "This email is undeliverable",
    verified: "",
    unknown:
      "Well, this is unexpected, an unknown error ocurred! Please try again."
  },
  phone: {
    format: "Must be a valid US Number",
    invalid:
      "The number you entered is invalid. Please enter a valid mobile number.",
    voip:
      "Oops! You entered a voip number. Please enter a valid mobile number.",
    landline:
      "Oops! You entered a landline number. Please enter a valid mobile number.",
    mobile: "",
    unknown:
      "Well, this is unexpected, an unknown error ocurred! Please try again."
  },
  ssn: {
    required: "The SSN number must contain 9 digits.",
    service:
      "We were not able to identify the user based on the information provided. Please add the SSN to your request."
  },
  general: {
    notReady: "Not Ready",
    required: "This field is required"
  }
};

export default errorMessages;
