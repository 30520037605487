const style = {
  bodyStyle: {
    height: "auto",
    lineHeight: "28px",
    padding: 24,
    whiteSpace: "pre-line",
    textAlign: "center"
  },
  contentStyleWarning: {
    color: "#EC707E",
    fontWeight: "bold"
  },
  contentStyleSuccess: {
    color: "#31b4b5",
    fontWeight: "bold"
  }
};

export default style;
