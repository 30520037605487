import { observable, action, computed, decorate } from "mobx";
import _ from "lodash";
import { formatName, validators } from "turn-shared";
import errorMessages from "../data/error-messages";

const { validateEmail } = validators;

class FormModel {
  firstName = "";
  lastName = "";
  email = "";
  phoneNumber = "";
  partner_packages = [];
  selected_partner_package = {};
  touchFirstName = false;
  touchLastName = false;
  touchEmail = false;
  touchSubmit = false;
  loading = false;
  emailServiceError = "";
  phoneNumberInvalidError = "";

  constructor(data = {}) {
    this.setNewCandidateEndpointData(data);
  }

  get params() {
    const params = {
      email: this.email,
      full_name: this.fullName,
      phone_number: this.phoneNumber !== "" ? this.phoneNumber : null,
      package_id: this.selected_partner_package.package_id
    };
    return params;
  }

  get package_id() {
    return this.params ? this.params.package_id : "";
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
  get errorFirstName() {
    if (!this.touchFirstName) return "";
    return this.firstName.length > 0 ? "" : errorMessages.general.required;
  }
  get errorLastName() {
    if (!this.touchLastName) return "";
    return this.lastName.length > 0 ? "" : errorMessages.general.required;
  }
  get errorEmail() {
    if (!this.touchEmail) return "";
    if (this.emailServiceError) return this.emailServiceError;
    return validateEmail(this.email) ? "" : errorMessages.email.format;
  }
  get errorPhoneNumber() {
    if (this.phoneNumberInvalidError) return this.phoneNumberInvalidError;
    return "";
  }
  get errors() {
    if (!this.touchFirstName) return errorMessages.general.notReady;
    if (!this.touchLastName) return errorMessages.general.notReady;
    if (!this.touchEmail) return errorMessages.general.notReady;
    return this.errorFirstName + this.errorLastName + this.errorEmail;
  }
  setEmail = email => {
    this.clearEmailServiceError();
    this.email = email.replace(/\s/g, "");
  };
  setPhoneNumber = phoneNumber => {
    this.phoneNumber = phoneNumber;
  };
  setFirstName = name => {
    this.firstName = formatName(name);
  };
  setLastName = name => {
    this.lastName = formatName(name);
  };
  setPartnerPackages = packages => {
    if (!Array.isArray(packages)) return;
    this.partner_packages = [...packages].sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, { sensitivity: "base" });
    });
  };
  setSelectedPackage = partner_package => {
    if (!_.isObject(partner_package)) return;
    this.selected_partner_package = partner_package;
  };
  selectPartnerPackage = package_id => {
    if (this.partner_packages.length === 0) return;
    const selected_packages = this.partner_packages.filter(
      partner_package => partner_package.package_id === package_id
    );
    if (selected_packages.length !== 1) return;
    this.setSelectedPackage(selected_packages[0]);
  };
  setNewCandidateEndpointData = data => {
    this.setPartnerPackages(data.packages);
    if (this.partner_packages.length > 0) {
      this.selectPartnerPackage(data.default_package);
    }
  };
  touch = field => {
    switch (field) {
      case "email":
        this.touchEmail = true;
        break;
      case "firstName":
        this.touchFirstName = true;
        break;
      case "lastName":
        this.touchLastName = true;
        break;
      case "submit":
        this.touchSubmit = true;
        break;
      default:
        break;
    }
  };
  resetTouches = () => {
    this.touchFirstName = false;
    this.touchLastName = false;
    this.touchEmail = false;
    this.touchSubmit = false;
  };
  setEmailServiceError = verification => {
    if (typeof errorMessages.email[verification] === "string") {
      this.emailServiceError = errorMessages.email[verification]; //HERE THE ERROR HAPPENS ABOUT REFUTED ERROR MESSAGE
    } else {
      this.emailServiceError = errorMessages.email.unknown;
    }
  };
  setPhoneNumberInvalidError = error => {
    this.phoneNumberInvalidError = error;
  };
  clearEmailServiceError = () => {
    this.emailServiceError = "";
  };
  clearEmailServiceError = () => {
    this.emailServiceError = "";
  };
  // clearPhoneNumberError = () => {
  //   this.errorPhoneNumber = "";
  // };
  setLoading = loading => {
    if (typeof loading !== "boolean") return;
    this.loading = loading;
  };
}

decorate(FormModel, {
  firstName: observable,
  lastName: observable,
  email: observable,
  phoneNumber: observable,
  partner_packages: observable,
  selected_partner_package: observable,
  touchFirstName: observable,
  touchLastName: observable,
  touchEmail: observable,
  touchSubmit: observable,
  emailServiceError: observable,
  loading: observable,
  params: computed,
  fullName: computed,
  errorFirstName: computed,
  errorLastName: computed,
  errorEmail: computed,
  errors: computed,
  errorPhoneNumber: computed,
  setEmail: action,
  setFirstName: action,
  setLastName: action,
  setPartnerPackages: action,
  setSelectedPackage: action,
  setNewCandidateEndpointData: action,
  touch: action,
  resetTouches: action,
  setEmailServiceError: action,
  clearEmailServiceError: action,
  setPhoneNumber: action,
  setLoading: action
});

export default FormModel;
