import React from "react";
import PropTypes from "prop-types";
import Badge from "../Badge";

const ListItemScore = ({ score }) =>
  score ? <Badge inverted score={score} margin="0 8% 0 3%" /> : null;

ListItemScore.propTypes = {
  score: PropTypes.string
};

export default ListItemScore;
