import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faClipboardUser,
  faMobileAlt
} from "@fortawesome/pro-solid-svg-icons";

import { ReportAuthorizationStyles as styles } from "../../../../styles";
import Text from "../../../Text";
import { Divider } from "material-ui";
import styled from "styled-components";
import { BREAKPOINT_SM } from "../../../../constants";

const Container = styled.div`
  text-align: left;
  width: 60%;
  margin: 1em auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${BREAKPOINT_SM}px) {
    width: 100%;
  }
`;

export class Option extends React.PureComponent {
  static propTypes = {
    label: PropTypes.object,
    hint: PropTypes.object,
    token: PropTypes.string,
    onClick: PropTypes.func
  };

  renderIcon = token => {
    switch (token) {
      case "ssn":
        return <FontAwesomeIcon icon={faAddressCard} size="2x" color="#fff" />;
      case "drivers_license_number":
        return (
          <FontAwesomeIcon icon={faClipboardUser} size="2x" color="#fff" />
        );
      case "phone_number":
        return <FontAwesomeIcon icon={faMobileAlt} size="2x" color="#fff" />;
      default:
        break;
    }
  };

  render() {
    const { option } = styles.cardList;
    const { option: formOption } = styles.cardForm;
    return (
      <Container onClick={() => this.props.onClick(this.props.token)}>
        <Divider style={option.divider} />
        <div style={option.left}>
          <div style={formOption.iconContainer}>
            <div style={formOption.icon}>
              {this.renderIcon(this.props.token)}
            </div>
          </div>
        </div>
        <div style={option.right}>
          <Text align="left" size="24px" weight="bold" display="block">
            {this.props.label}
          </Text>
          <Text size="16px">{this.props.hint}</Text>
        </div>
      </Container>
    );
  }
}
