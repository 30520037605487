import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { List } from "material-ui/List";
import { renderDetailValue, valueExists, selectMasterKey } from "turn-shared";

import { ListDetailStyles as styles } from "../../styles";
import Warning from "material-ui/svg-icons/alert/error-outline";
import { noticeWarning } from "../../styles/constants";
import CollapsibleListItem from "./collapsible-list-item";
import Detail from "./detail";
import DetailContainer from "./detail-container";
import ListContainer from "./list-container";
import SecondaryListItem from "./secondary-item";
import ListSubSections from "./list-subsections";
import ListItemHighlight from "./list-item-highlight";

class ListDetailView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      masterKey: null
    };
  }
  componentDidMount() {
    this.setMasterKey();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setMasterKey();
    }
  }
  setMasterKey = () => {
    this.setState({
      masterKey: this.selectMasterKey(this.props.data, this.props.masterKey)
    });
  };
  getSubsections = (item, keys) => {
    let subsections = [];
    if (keys && Array.isArray(keys) && keys.length > 0) {
      subsections = keys.map(key => ({
        title: key,
        data: item[key]
      }));
    }
    return subsections;
  };
  selectMasterKey = (data, masterKey) => {
    if (Array.isArray(masterKey) && masterKey.length > 0) return masterKey;
    return selectMasterKey(data, masterKey);
  };
  renderDivider = index => {
    if (!this.props.simple) return false;
    return index !== 0;
  };
  renderTitle = item => {
    if (typeof this.state.masterKey === "string")
      return renderDetailValue(item[this.state.masterKey]);
    if (Array.isArray(this.state.masterKey)) {
      const title = this.state.masterKey
        .map(key => (valueExists(item[key]) ? item[key] : ""))
        .filter(value => value)
        .join(" ");
      return title || renderDetailValue(item[this.state.masterKey]);
    }
    return null;
  };
  renderWarning = item => {
    if (typeof this.props.renderWarning !== "function") return null;
    return this.props.renderWarning(item) ? (
      <Warning color={noticeWarning} />
    ) : null;
  };
  render() {
    if (!Array.isArray(this.props.data) || this.props.data.length === 0)
      return null;
    return (
      <div style={styles.container}>
        <ListContainer
          simple={this.props.simple}
          indent={this.props.indent}
          style={this.props.containerStyle}
        >
          <List style={styles.list}>
            {this.props.data.map((item, index) => (
              <CollapsibleListItem
                key={index}
                title={this.renderTitle(item)}
                simple={this.props.simple}
                score={item.score}
                divider={this.renderDivider(index)}
                icon={this.renderWarning(item)}
                expand={this.props.expand}
              >
                <DetailContainer simple={this.props.simple}>
                  <Detail
                    data={item}
                    exclude={this.props.exclude}
                    deep={this.props.deep}
                  />
                </DetailContainer>
                <SecondaryListItem
                  data={item[this.props.secondary]}
                  simple={this.props.simple}
                  exclude={this.props.exclude}
                  title={this.props.secondary}
                  displayTable={this.props.secondaryDisplayTable}
                  deep={this.props.deep}
                  expand={this.props.expand}
                />
                <ListSubSections
                  items={this.getSubsections(item, this.props.subsections)}
                  simple={this.props.simple}
                  exclude={this.props.exclude}
                  displayTable={this.props.subsectionsDisplayTable}
                  deep={this.props.deep}
                  expand={this.props.expand}
                />
                <ListItemHighlight
                  highlight={item[this.props.highlight]}
                  title={this.props.highlight}
                  deep={this.props.deep}
                />
              </CollapsibleListItem>
            ))}
          </List>
        </ListContainer>
      </div>
    );
  }
}

ListDetailView.propTypes = {
  data: PropTypes.array,
  masterKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  indent: PropTypes.bool,
  simple: PropTypes.bool,
  exclude: PropTypes.array,
  secondary: PropTypes.string,
  secondaryDisplayTable: PropTypes.bool,
  subsections: PropTypes.array,
  subsectionsDisplayTable: PropTypes.bool,
  highlight: PropTypes.string,
  deep: PropTypes.bool,
  renderWarning: PropTypes.func,
  expand: PropTypes.bool,
  containerStyle: PropTypes.object
};

ListDetailView.defaultProps = {
  containerStyle: {}
};

export default ListDetailView;
