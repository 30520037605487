import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import StatusLabelContainer from "./status-label-container";

class StatusLabel extends PureComponent {
  static propTypes = {
    status: PropTypes.string
  };

  render() {
    const { status } = this.props;
    return status ? (
      <StatusLabelContainer {...this.props}>
        {`${_.upperCase(status)}${status === "processing" ? "..." : ""}`}
      </StatusLabelContainer>
    ) : null;
  }
}

export default StatusLabel;
