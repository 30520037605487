import styled from "styled-components";

export const WorkerFilterTitle = styled("h4")`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3047;
`;
