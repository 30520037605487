/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
  root: {
    height: 48
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center"
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 16,
    fontSize: 16
  },
  paper: {
    padding: 0,
    zIndex: 99999
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
  Menu
};

/**
 * @augments {React.Component<Props, State>}
 *
 * @see https://v1.material-ui.com/demos/autocomplete/#react-select
 */
export const AutocompleteSelectComponent = ({
  classes,
  theme,
  options,
  style,
  ...props
}) => {
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary
    })
  };

  return (
    <div className={classes.root} style={style}>
      <Select
        classes={classes}
        styles={selectStyles}
        options={options}
        components={components}
        isClearable={true}
        menuPosition="fixed"
        {...props}
      />
    </div>
  );
};
AutocompleteSelectComponent.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  style: PropTypes.object
};

/**
 * Composed from `react-select` and Material UI components, renders a drop-down select box with autocomplete-like functionality.
 *
 * @example
 *    const options = [{
 *      { label: 'Hello', value: 1 },
 *      { label: 'Goodbye', value: 0 },
 *    }];
 *    const [value, setValue] = useState(0);
 *    const onChange = (value) => {
 *      setValue(value);
 *
 *      if (value == 1) {
 *        alert('Hi there!');
 *      } else {
 *        alert('Bye, bye!');
 *      }
 *    };
 *
 *    <AutocompleteSelect options={options} onChange={onChange} value={value} />
 *
 * @param {object} options Options to show, must be an array of {label, value} objects
 * @param {function} onChange Callback to be called with the new selected `value`
 * @param {any} value Selected value
 *
 * @augments AutocompleteSelectComponent
 */
export const AutocompleteSelect = withStyles(styles, { withTheme: true })(
  AutocompleteSelectComponent
);
AutocompleteSelect.propTypes = AutocompleteSelectComponent.propTypes;
