import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ConsentProvided from "../../components/LandingPage/consent-provided";
import { PageType, PageEvent } from "../../services/analytics/gtm";
import { ga } from "../../services";

const propTypes = { details: PropTypes.object };

class BackgroundReportSuccess extends PureComponent {
  componentDidMount() {
    ga.send(PageType.BackgroundReportSucess, PageEvent.pageLoaded);
  }

  render() {
    return <ConsentProvided details={this.props.details || {}} />;
  }
}

BackgroundReportSuccess.propTypes = propTypes;

export default BackgroundReportSuccess;
