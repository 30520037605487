import { observable, action, decorate } from "mobx";
import { getStatus, pickColor } from "turn-shared";
import moment from "moment";
import _ from "lodash";

import { RemoteModel } from "../helpers/RemoteModel";

import { turnAPI } from "../../services";
import {
  emerald,
  orange,
  ceruleanBlue,
  slateGray
} from "../../styles/constants";

import { getDemoData } from "../../data/demo/getDemoData";

const statusColors = {
  approved: emerald,
  pending: orange,
  rejected: "#EE0000",
  processing: ceruleanBlue,
  withdrawn: "#9BB1C8",
  verifying: slateGray
};

/**
 * The env var REACT_APP_SOURCING_DEMO_PARTNERS contains a comma-separated
 * list of demo partner accounts.
 *
 * This function checks the currently-logged-in partner against this list.
 *
 * If the UUID appears in the list, dummy data will be provided to populate the
 * graphs in the Partner Dashboard
 *
 * @param {string} uuid
 */
const partnerIsDemoAccount = uuid => {
  if (!uuid) return false;
  const demoPartnerUUIDs = process.env.REACT_APP_SOURCING_DEMO_PARTNERS;
  if (!demoPartnerUUIDs) return false;
  return demoPartnerUUIDs.includes(uuid.toLowerCase());
};

export class PartnerMetricsModel extends RemoteModel {
  isDemoPartner = undefined;
  storedData = undefined;

  currentlyLoading = false;
  filterDays = 30;
  filterStartDate = moment()
    .subtract(30, "days")
    .format("YYYY-MM-DD");
  filterEndDate = moment().format("YYYY-MM-DD");

  averageTime = undefined;
  averageCost = undefined;
  underInvestigation = undefined;
  awaitingAction = undefined;

  statusChart = undefined;
  considerCostChart = undefined;

  checkIfDemoPartner(member) {
    this.isDemoPartner = partnerIsDemoAccount(member.partner_id);
  }

  // Actions
  setFilterDays(days) {
    this.filterDays = days;
    this.filterStartDate = moment()
      .subtract(days, "days")
      .format("YYYY-MM-DD");
    this.filterEndDate = moment().format("YYYY-MM-DD");

    return this.applyFilters(true);
  }
  setStartDate(startDate) {
    this.filterStartDate = startDate;
    return this.applyFilters(true);
  }
  setEndDate(endDate) {
    this.filterEndDate = endDate;
    return this.applyFilters(true);
  }

  async getApiData() {
    this.currentlyLoading = true;
    const response = await this.awaitRequest(
      turnAPI.getPartnerMetrics({
        start: this.filterStartDate,
        end: this.filterEndDate
      })
    );
    this.currentlyLoading = false;
    return (response && response.data) || {};
  }

  async getData() {
    if (this.isDemoPartner) {
      this.storedData = getDemoData();
    } else {
      this.storedData = this.getApiData();
    }
    return this.storedData;
  }

  // Force is a boolean which tells this method to call the API and not return cached data
  // To be used when data parameters change
  async applyFilters(force) {
    // We don't want this to do anything until we've established
    // If this is a demo or not
    if (this.isDemoPartner === undefined) {
      return this.storedData;
    }

    // If we are still waiting for a response from the API, don't call again
    if (this.currentlyLoading) return;
    // We can't do a weird un-bounded request
    if (!this.filterStartDate || !this.filterEndDate) {
      return;
    }

    const data =
      this.storedData && !force ? this.storedData : await this.getData();
    this.averageTime = data.average_time;
    this.underInvestigation = data.under_investigation;
    this.awaitingAction = data.awaiting_action;

    // updates the average cost to total spent / total checks, instead of using data.average_cost
    if (data.data) {
      const totalSpent = data.data.reduce((accumulator, dataNode) => {
        return accumulator + dataNode.spend;
      }, 0);

      const totalChecks = data.data.reduce((accumulator, dataNode) => {
        return accumulator + dataNode.total_checks;
      }, 0);

      this.averageCost = totalChecks !== 0 ? totalSpent / totalChecks : "N/A";
    }

    this.statusChart = _.map(
      data.status || [],
      ({ profile_status, count }) => ({
        id: profile_status,
        name: _.capitalize(getStatus(profile_status)),
        value: count,
        color: statusColors[profile_status] || pickColor(profile_status)
      })
    );
    this.considerCostChart = _.sortBy(data.data, "date");
  }
}

decorate(PartnerMetricsModel, {
  // Observables
  isDemoPartner: observable,
  filterDays: observable,
  averageTime: observable,
  averageCost: observable,
  underInvestigation: observable,
  awaitingAction: observable,

  statusChart: observable,
  considerCostChart: observable,

  //Actions
  setFilterDays: action,
  setStartDate: action,
  setEndDate: action,
  checkIfDemoPartner: action
});
