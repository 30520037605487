import * as colors from "../styles/constants";

import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { createMuiTheme } from "@material-ui/core/styles";

const breakpoints = createBreakpoints({});

export const materialThemeV1 = createMuiTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    error: { main: colors.noticeError },
    warning: { main: colors.noticeWarning },
    info: { main: colors.noticeInfo },
    success: { main: colors.noticeSuccess },
    text: { main: colors.primary },
    action: {
      disabledBackground: "orange"
    }
  },
  typography: {
    fontFamily: "inherit"
  },
  overrides: {
    MuiTypography: {
      display1: {
        fontFamily: "inherit",
        fontWeight: 700,
        color: colors.primary
      },
      display2: {
        fontFamily: "inherit",
        fontWeight: 700,
        color: colors.primary
      },
      display3: {
        fontFamily: "inherit",
        fontWeight: 700,
        color: colors.primary
      },
      display4: {
        fontFamily: "inherit",
        fontWeight: 700,
        color: colors.primary
      },
      headline: {
        fontFamily: "inherit",
        fontWeight: 700,
        color: colors.primary
      },
      title: {
        fontFamily: "inherit",
        fontWeight: 700,
        color: colors.primary
      },
      subheading: {
        fontFamily: "inherit",
        fontWeight: 700,
        color: colors.primary
      },
      body1: {
        fontFamily: "inherit",
        fontSize: 16,
        color: "#7E7E8F"
      },
      body2: {
        fontFamily: "inherit",
        fontWeight: "bold",
        fontSize: 16,
        color: colors.primary
      }
    },
    MuiButtonBase: {
      disableRipple: true
    },
    MuiFormLabel: {
      root: {
        color: colors.primary,
        fontFamily: "inherit",
        fontSize: "12px",
        fontWeight: "bold"
      }
    },
    MuiInputLabel: {
      root: {
        fontFamily: "inherit",
        color: "#7E7E8F"
      }
    },
    MuiInput: {
      root: {
        height: "48px",
        borderRadius: "10px",
        border: `1px solid ${colors.periwinkle}`,
        paddingLeft: "16px",
        paddingRight: "16px",
        fontFamily: "inherit",
        fontSize: "14px",
        color: "#7E7E8F",
        overflow: "hidden"
      },
      input: {
        fontFamily: "inherit",
        fontWeight: "normal",

        "&[type=tel]": {
          height: "initial"
        },
        "&[type=email]": {
          height: "initial"
        }
      },
      formControl: {
        backgroundColor: colors.white
      },
      focused: {
        border: `1px solid ${colors.primary}`,
        color: colors.primary
      },
      error: {
        border: "1px solid #fa1f4b"
      },
      underline: {
        "&:before": {
          borderBottom: "none"
        },
        "&:after": {
          borderBottom: "none"
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "none"
        },
        "&:hover:not($disabled):not($focused):not($error):after": {
          borderBottom: "none"
        }
      }
    },
    MuiSelect: {
      root: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      select: {
        padding: "16px 20px"
      }
    },
    MuiButton: {
      text: {
        borderRadius: 20,
        fontFamily: "inherit",
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "capitalize"
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 10
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(45, 48, 71, 0.61)"
      }
    },
    MuiListItem: {
      root: {
        padding: 0,
        margin: 0
      },
      button: {
        "&:focus": {
          background: "transparent"
        },
        "&:hover": {
          backgroundColor: colors.smokeWhite
        },
        "&:active": {
          backgroundColor: "transparent"
        }
      },
      gutters: {
        paddingLeft: 0,
        [breakpoints.up("sm")]: {
          paddingLeft: 0
        }
      }
    }
  }
});
