const styles = {
  container: {
    display: "inline-block",
    position: "relative",
    width: "64px",
    height: "64px"
  },
  dot: {
    position: "absolute",
    top: "27px",
    width: "11px",
    height: "11px",
    borderRadius: "50%",
    background: "#31b4b5",
    animationTimingFunction: "cubic-bezier(0, 1, 1, 0)"
  }
};
export default styles;
