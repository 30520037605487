import React from "react";
import { observer } from "mobx-react/custom";
import FlatButton from "material-ui/FlatButton";
import { DownloadFileUIStyles as style } from "../../styles/index";
// import { ReactComponent as FileDownloadIcon } from "../../icons/file-download.svg";
import { FormattedMessage } from "react-intl";
import messages from "../../containers/WorkersPage/messages";

const DownloadFileButton = ({ onPress }) => (
  <div style={style.wrapper}>
    <FlatButton
      label={<FormattedMessage {...messages.downloadPartnerDashboardReport} />}
      icon={<i className="fas fa-file-spreadsheet" style={style.icon} />}
      style={style.flatButton}
      labelStyle={style.flatButtonLabel}
      onClick={onPress}
      className="btn-custom-flat-button"
    />
  </div>
);

export default observer(DownloadFileButton);
