import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { getStatus } from "turn-shared";

import { CandidateReportStyles as styles } from "../../styles";
import { slateGray } from "../../styles/constants";
import withContext from "../../contextAPI/context-HOC";

import CandidateStatusUI from "../CandidateStatusUI";
import Row from "../Row";
import Text from "../Text";
import Divider from "../Divider";

import DataItem from "./DataItem";

const propTypes = {
  model: PropTypes.object.isRequired,
  context: PropTypes.shape({
    features: PropTypes.shape({
      REACT_APP_ENABLE_EMAIL_STATUS: PropTypes.bool
    })
  })
};

export class ReportDate extends PureComponent {
  componentDidMount() {}
  render() {
    const { model } = this.props;
    if (!model) return null;
    return (
      <div style={styles.reportDateContainer}>
        <Row style={styles.reportDateContent}>
          <DataItem
            noMargin
            width="30%"
            widthMD="30%"
            widthSM="100%"
            title={
              <Text size="20px" weight="bold" height="30px">
                {model.fullName}
              </Text>
            }
            text={" "}
          />
          <DataItem
            noMargin
            width="30%"
            widthMD="30%"
            widthSM="100%"
            title={
              <CandidateStatusUI
                enableEmailStatus={
                  this.props.context.features.REACT_APP_ENABLE_EMAIL_STATUS
                }
                worker={model}
                status={getStatus(model.profileStatus)}
              />
            }
            text={" "}
          />
          <DataItem
            noMargin
            width="30%"
            widthMD="30%"
            widthSM="100%"
            title={
              <Text id="partnerName" size="20px" weight="bold" height="30px">
                {model.partner}
              </Text>
            }
            text={
              <Text id="statusDescription" color={slateGray}>
                {model.address_for_fcra ? model.address_for_fcra : " "}
              </Text>
            }
          />
        </Row>
        <Row style={styles.reportDateContent}>
          <DataItem
            noMargin
            width="30%"
            widthMD="30%"
            widthSM="100%"
            title={""}
            text={
              <Text
                id="requestedOn"
                color={slateGray}
              >{`Requested on: ${model.reportDate}`}</Text>
            }
          />
          <DataItem
            noMargin
            width="30%"
            widthMD="30%"
            widthSM="100%"
            title={""}
            text={
              <Text id="signedUpOn" color={slateGray}>
                {model.consentDate ? `Signed up on: ${model.consentDate}` : ""}
              </Text>
            }
          />
          <DataItem
            noMargin
            width="30%"
            widthMD="30%"
            widthSM="100%"
            title={""}
            text={
              <Text id="statusDescription" color={slateGray}>
                {model.completedDate}
              </Text>
            }
          />
        </Row>
        <Divider height="1px" />
      </div>
    );
  }
}

ReportDate.propTypes = propTypes;

export default withContext(ReportDate);
