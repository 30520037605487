/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/href-no-hash */
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { CandidateReportStyles as styles } from "../../styles";
import { pickColor } from "turn-shared";
import Row from "../Row";
import Column from "../Column";

import Check from "./Check";
import AddressList from "./AddressList";
import messages from "./messages";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { green } from "../../styles/constants";
import Divider from "../Divider";
import Text from "../Text";

class SSN extends Component {
  static propTypes = {
    model: PropTypes.object,
    isPDFReport: PropTypes.bool
  };
  static defaultProps = {
    model: {}
  };
  constructor(props) {
    super(props);
    this.model = props.model;
  }
  render() {
    if (!this.model || !this.model.doSSNTrace) return null;
    const { isPDFReport } = this.props;

    const defaultCheckNameId = "detailCheckName";
    const defaultCheckStatusId = "detailCheckStatus";
    const defaultCheckSectionId = "detailCheckSection";

    return (
      <div id={defaultCheckSectionId} style={styles.section}>
        <Check
          check={<FormattedMessage {...messages.ssnTrace} />}
          icon={faCheckCircle}
          color={green}
          noDivider
          containerStyle={styles.checks.titleContainer}
          nameId={defaultCheckNameId}
          statusId={defaultCheckStatusId}
        />
        <div>
          <Row style={styles.sectionContent}>
            {/* SSN */}
            <Column
              width="25%"
              widthMD={isPDFReport ? "25%" : "100%"}
              widthSM={isPDFReport ? "25%" : "100%"}
              style={styles.ssnStatus}
            >
              <div style={styles.ssnContent}>
                <Row>
                  <Column width="100%">
                    <Text weight="bold">DOB</Text>
                    <Text
                      style={{
                        ...styles.tableStatusTxt,
                        color: pickColor(this.model.dobStatus)
                      }}
                    >
                      {this.model.dobStatus}
                    </Text>
                  </Column>
                </Row>
              </div>
              <div>
                <Row>
                  <Column width="100%">
                    <Text weight="bold">Mortality</Text>
                    <Text style={styles.tableStatusTxt}>
                      {this.model.ssnMortality}
                    </Text>
                  </Column>
                </Row>
              </div>
            </Column>

            <AddressList
              isSSN={true}
              addresses={[this.model.currentAddressFull]}
            />
          </Row>
          <Divider height="1px" />
        </div>
      </div>
    );
  }
}

export default observer(SSN);
