import { OPPOSITE_STATUS_MAPPING, STATUS_MAPPING } from "./Modal/constants";

export const getEnrollmentStatus = (isEnabled, status, updatedAt) => {
  if (!isEnabled) return STATUS_MAPPING.not_enrolled;

  const isPending = updatedAt === "PENDING";
  return status === null
    ? STATUS_MAPPING.unenrolled
    : isPending
    ? OPPOSITE_STATUS_MAPPING[status]
    : status;
};
