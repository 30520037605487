import React, { PureComponent, isValidElement } from "react";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import { isPlainObject } from "lodash";
import Warning from "material-ui/svg-icons/alert/error-outline";
import { noticeWarning } from "../../styles/constants";
import DetailContainerInline from "./detail-container-inline";
import messages from "./messages";
import { SearchHighlighted } from "../SearchHighlighted";

const Label = ({ detail }) => {
  const isSubtitle = isPlainObject(detail.value);
  return (
    <DetailContainerInline
      label
      subtitle={isSubtitle}
      width="10rem"
      size="12px"
      color="#000"
    >
      <SearchHighlighted textToHighlight={detail.label} />
      {!detail.value ? <Warning color={noticeWarning} /> : null}
    </DetailContainerInline>
  );
};
Label.propTypes = {
  detail: PropTypes.object.isRequired,
  width: PropTypes.string
};

const getDescriptionProps = detail => {
  if (!isPlainObject(detail.value)) return { width: "auto" };
  return { width: "100%", clear: true };
};
const Description = ({ detail, isImage }) => {
  const { value, renderer, label } = detail;
  const props = getDescriptionProps(detail);
  let content = <SearchHighlighted textToHighlight={value} />;
  if (!value) content = <FormattedMessage {...messages.notProvided} />;
  if (renderer) content = renderer(value);
  return isImage ? (
    <DetailContainerInline {...props} size="12px" color="#000">
      <img width="100%" src={content} alt={label} />
    </DetailContainerInline>
  ) : (
    <DetailContainerInline {...props} size="12px" color="#000">
      {content}
    </DetailContainerInline>
  );
};
Description.propTypes = {
  detail: PropTypes.object.isRequired,
  isImage: PropTypes.bool
};

class DetailItemInline extends PureComponent {
  static propTypes = {
    detail: PropTypes.object,
    width: PropTypes.string,
    disabled: PropTypes.bool,
    isImage: PropTypes.bool
  };
  render() {
    const { detail, width, isImage } = this.props;
    if (!detail) return null;
    if (isValidElement(detail)) return detail;
    if (!detail.label) return null;
    return (
      <DetailContainerInline width={width} style={{ whiteSpace: "normal" }}>
        <Label detail={detail} />
        <Description isImage={isImage} detail={detail} />
      </DetailContainerInline>
    );
  }
}
export default DetailItemInline;
