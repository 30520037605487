import { primary, backgroundGray, black } from "../constants";
import * as colors from "../constants";

const window = {
  padding: "25px",
  borderRadius: "10px"
};

export default {
  checkbox: {
    fill: primary,
    padding: "8px 0"
  },
  checkboxChecked: {
    fill: primary,
    padding: "8px 0",
    backgroundColor: backgroundGray
  },
  radioButton: {
    marginBottom: 16
  },
  footer: {
    height: 40,
    width: "100%",
    display: "block",
    position: "relative",
    marginTop: 50
  },
  errorMessage: {
    color: "red",
    fontWeight: "bolder"
  },
  backButton: {
    border: "2px solid",
    borderColor: primary,
    borderRadius: 2,
    height: 40,
    color: primary,
    position: "absolute",
    left: 0,
    bottom: 0
  },
  nextButton: {
    position: "absolute",
    right: 0,
    bottom: 0
  },
  label: {
    color: primary,
    cursor: "pointer",
    display: "block",
    marginBottom: -10
  },
  inputGroup: {
    marginBottom: 34
  },
  radioButtonHolder: {
    position: "relative"
  },
  radioButtonTextField: {
    position: "absolute",
    bottom: -8,
    left: 40
  },
  filters: {
    body: {
      padding: "1em",
      width: "300px"
    },
    bodyLarge: {
      padding: "1em",
      width: "450px"
    },
    bodyxLarge: {
      width: "600px",
      height: "250px",
      padding: "1em"
    },
    footer: {
      padding: "1em",
      textAlign: "right"
    },
    row: {
      display: "flex"
    },
    map: {
      width: "300px",
      height: "200px"
    }
  },
  selectedChip: {
    backgroundColor: "#0097a7",
    color: "#ffffff",
    borderColor: "transparent",
    margin: "5px",
    width: "120px"
  },
  clearChip: {
    backgroundColor: "#ffffff",
    color: "#0097a7",
    border: "1px solid",
    borderColor: "#0097a7",
    margin: "5px",
    width: "120px"
  },
  select: {
    width: "122px",
    marginRight: "1em"
  },
  checkboxContainer: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  filterCheckbox: {
    fill: primary,
    width: "30px"
  },
  inputNumber: {
    borderRadius: "5px",
    maxWidth: "80px",
    maxHeight: "25px",
    paddingLeft: "10px",
    boxShadow: "0px 0px 0px 2px #BDBDBD inset"
  },
  sliderWithMarks: {
    userSelect: "none",
    cursor: "default",
    height: "18px",
    width: "91%",
    position: "relative",
    marginTop: "10px",
    marginBottom: "20px",
    marginLeft: "5px"
  },
  checkboxWrapper: {
    display: "flex",
    width: "55%"
  },
  check: {
    marginBottom: "1em",
    width: "200px"
  },
  preview: {
    width: 120,
    cursor: "pointer"
  },
  previewText: {
    fontSize: 12,
    lineHeight: "25px",
    paddingLeft: 5,
    color: colors.primary
  },
  previewIcon: {
    color: colors.primary
  },
  closeModal: {
    position: "absolute",
    top: "5px",
    right: "10px"
  },
  iframe: {
    width: "100%",
    // height: "500px",
    border: 0
  },
  emailPreview: {
    maxWidth: 1200,
    padding: 0,
    backgroundColor: primary
  },
  templateTabs: {
    marginBottom: "1em",
    background: "transparent"
  },
  templateTabLabel: {
    fontSize: 14,
    color: black,
    fontWeight: "bold",
    textTransform: "inherit"
  },
  windowsContainer: {
    display: "flex",
    position: "relative"
  },
  previewWindow: {
    ...window,
    flex: 3,
    backgroundColor: colors.white,
    marginRight: "25px",
    display: "flex",
    position: "relative"
  },
  editorWindow: {
    ...window,
    flex: 2,
    minHeight: "500px",
    backgroundColor: colors.white
  },
  editorWrapper: {
    minHeight: "450px",
    display: "block"
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    margin: "auto"
  },
  closeButton: {
    backgroundColor: "transparent",
    width: "40%",
    position: "relative",
    padding: 0
  },
  closeButtonText: {
    color: colors.red,
    position: "absolute",
    left: 12
  },
  cancelIcon: {
    maxWidth: "20px",
    stroke: "white",
    marginRight: "10px"
  },
  saveButton: {
    borderRadius: "20px",
    backgroundColor: colors.secondary,
    width: "50%",
    color: colors.white
  },
  filterBtn: {
    marginRight: "32px"
  },
  previewImage: {
    maxWidth: "100px",
    maxHeight: "100px"
  },
  footerWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  footerSave: {
    width: "168px"
  },
  landingEditor: {
    windowsContainer: {
      display: "flex",
      position: "relative",
      backgroundColor: colors.white,
      borderRadius: "10px",
      height: "100%"
    },
    iframe: {
      flexBasis: "100%",
      border: "none",
      borderRadius: "10px"
    },
    editorWrapper: {
      display: "flex",
      flexWrap: "wrap",
      width: "40%",
      height: "100%",
      position: "absolute",
      backgroundColor: colors.white,
      right: 0,
      borderRadius: "10px",
      transition: "width 0.2s ease-in-out, opacity 0.2s ease-in-out",
      opacity: 1,
      zIndex: 1,
      overflow: "scroll"
    },
    editors: {
      width: "100%",
      minWidth: "300px"
    },
    footerWrapper: {
      minWidth: "300px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignSelf: "flex-end",
      paddingTop: 30
    },
    footerSave: {
      width: "50%"
    },
    toggleContainer: {
      display: "block",
      height: "45px",
      width: "100%",
      position: "sticky",
      top: "-25px",
      left: "0",
      backgroundColor: "#fff",
      zIndex: "1"
    }
  },
  smsEditor: {
    editorContainer: {
      position: "relative"
    },
    errorContainer: {
      position: "absolute",
      right: 0,
      bottom: -3,
      display: "flex",
      alignItems: "center",
      height: 20
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: 20
    }
  },
  controlsButtonView: {
    textTransform: "none",
    color: colors.primary,
    background: colors.aliceBlue,
    marginRight: 20,
    minWidth: 100
  },
  controlsButtonEdit: {
    textTransform: "none",
    marginRight: 20,
    minWidth: 100
  },
  controlsIcon: {
    fontSize: 20,
    marginRight: 5
  },
  toggleIcon: {
    fontSize: 30,
    cursor: "pointer"
  },
  controlsWrapper: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 15,
    zIndex: 2,
    right: 25
  },
  resetButton: {
    backgroundColor: "transparent",
    color: colors.primary,
    width: "50%",
    border: `1px solid ${colors.primary}`
  }
};
