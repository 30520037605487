import React from "react";
import PropTypes from "prop-types";
import { ReportAuthorizationStyles as styles } from "../../../../styles";
import TextField from "@material-ui/core/TextField";
import Text from "../../../Text";

import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import { slateGray, smokeWhite } from "../../../../styles/constants";

const TextFieldContainer = styled.div`
  & > div {
    & > div {
      width: 84px;
      height: 36px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.31;
      color: ${slateGray};
      background-color: ${smokeWhite};
    }
  }
`;

const Option = observer(
  class Option extends React.Component {
    static propTypes = {
      label: PropTypes.object,
      hint: PropTypes.object,
      handleChange: PropTypes.func,
      handleKeyDown: PropTypes.func,
      error: PropTypes.string,
      errorMessage: PropTypes.string,
      classes: PropTypes.object
    };

    render() {
      const { option } = styles.cardForm;
      return (
        <div style={option.container}>
          <div style={option.iconContainer}>
            <div style={option.icon}>
              <FontAwesomeIcon icon={faAddressCard} size="2x" color="#fff" />
            </div>
          </div>
          <div style={option.textContainer}>
            <Text weight="bold" size="20px" align="left" display="block">
              {this.props.label}
              <Text display="block">Last four digits</Text>
            </Text>
            <div style={option.inputContainer}>
              <Text size="16px" weight="bold" style={option.hint}>
                {this.props.hint}
              </Text>
              <TextFieldContainer>
                <TextField
                  onChange={this.props.handleChange}
                  onKeyDown={this.props.handleKeyDown}
                  error={this.props.error}
                  inputProps={{
                    style: option.input
                  }}
                />
              </TextFieldContainer>
              {this.props.error && (
                <p style={option.error}>{this.props.errorMessage}</p>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
);

export { Option };
