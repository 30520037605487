export const oneOffChecksStyle = {
  one_third: {
    width: "35%",
    display: "inline-block"
  },
  link: {
    width: "26%",
    display: "inline-block",
    textAlign: "right",
    textDecoration: "none"
  }
};
