import React, { PureComponent } from "react";
import _ from "lodash";
import { PropTypes } from "prop-types";
import { isObservableArray } from "mobx";
import FormattedHeader from "../FormattedHeader";
import { ListItem } from "material-ui/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/pro-solid-svg-icons";

import { ListDetailStyles as styles } from "../../styles";
import { primary, black } from "../../styles/constants";
import Drawer from "../Drawer";
import Highlighted from "../Highlighted";
import SubTitle from "../SubTitle";
import SimpleTable from "../SimpleTable";
import Detail from "./detail";
import DetailContainer from "./detail-container";

class SecondaryListItem extends PureComponent {
  static propTypes = {
    data: PropTypes.PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    displayTable: PropTypes.bool,
    exclude: PropTypes.array,
    title: PropTypes.string,
    simple: PropTypes.bool,
    deep: PropTypes.bool
  };
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (typeof nextProps.expand === "boolean")
      this.setState({ open: nextProps.expand });
  }
  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };
  shouldRender = () => {
    if (!this.props.data) return false;
    if (Array.isArray(this.props.data) || isObservableArray(this.props.data)) {
      return this.props.data.length > 0;
    } else if (_.isObject(this.props.data)) {
      const truthy_values = _.filter(this.props.data, item => !!item);
      return truthy_values.length > 0;
    }
    return null;
  };
  renderOpen = open =>
    open ? (
      <FontAwesomeIcon
        icon={faMinusCircle}
        color={black}
        style={{ fontSize: "15px" }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faPlusCircle}
        color={black}
        style={{ fontSize: "15px" }}
      />
    );

  renderDataArray = () =>
    this.props.displayTable ? (
      <DetailContainer simple={this.props.simple}>
        <SimpleTable data={[...this.props.data]} />
      </DetailContainer>
    ) : (
      this.props.data.map((data, index) => (
        <DetailContainer
          simple={this.props.simple}
          borderTop={index > 0}
          key={index}
        >
          <Detail
            data={data}
            exclude={this.props.exclude}
            deep={this.props.deep}
          />
        </DetailContainer>
      ))
    );
  render() {
    if (!this.shouldRender()) return null;
    return (
      <div>
        <ListItem
          onClick={this.toggleOpen}
          style={this.props.simple ? styles.paddedItem : {}}
        >
          <Highlighted black>
            <FormattedHeader message={this.props.title} inherit />
          </Highlighted>
          <SubTitle color={primary} display="inline-block" margin="0 0 0 30px">
            {this.renderOpen(this.state.open)}
          </SubTitle>
        </ListItem>
        <Drawer open={this.state.open}>
          {Array.isArray(this.props.data) ||
          isObservableArray(this.props.data) ? (
            this.renderDataArray()
          ) : (
            <DetailContainer simple={this.props.simple} secondary>
              <Detail
                data={this.props.data}
                exclude={this.props.exclude}
                deep={this.props.deep}
                width="100%"
              />
            </DetailContainer>
          )}
        </Drawer>
      </div>
    );
  }
}

export default SecondaryListItem;
