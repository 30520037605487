import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import Img from "../Img";
import { LandingPageStyles as styles } from "../../styles";
import messages from "./messages";
import { FormWizardStepper } from "../LandingPage/components/FormWizardStepper";
import env from "@mars/heroku-js-runtime-env";
import { CONSENT_FLOW_URL } from "../../services/api/base-api";

const propTypes = {
  candidateConsentEmailId: PropTypes.string,
  isReview: PropTypes.boolean,
  logRedirect: PropTypes.func,
  processing: PropTypes.boolean,
  redirect_url: PropTypes.string,
  worker_status: PropTypes.string
};

class ReportsProcessing extends Component {
  constructor(props) {
    super(props);
    this.redirect_url = localStorage.getItem("redirect_url");
    this.state = { width: 0 };
  }

  componentDidMount() {
    if (this.redirect_url) {
      if (typeof this.props.logRedirect === "function") {
        this.props.logRedirect();
      }
      const timer = parseInt(env().REACT_APP_REDIRECT_URL_TIMER, 10);
      window.setTimeout(
        () => {
          window.location.assign(this.redirect_url);
          localStorage.removeItem("redirect_url");
        },
        !isNaN(timer) ? timer : 5000
      );
    }

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  getReportProcessingStyle = () => {
    return this.state.width > 768
      ? styles.reportProcessing
      : { ...styles.reportProcessing, ...styles.reportProcessingSm };
  };

  getGralMessageContainerStyle = () => {
    return this.state.width > 768
      ? styles.gralMessageContainer
      : { ...styles.gralMessageContainer, ...styles.gralMessageContainerSm };
  };

  getSpecificMessageContainerStyle = () => {
    return this.state.width > 768
      ? styles.specificMessageContainer
      : {
          ...styles.specificMessageContainer,
          ...styles.specificMessageContainerSm
        };
  };

  getTrackMessageContainerStyle = () => {
    return this.state.width > 768
      ? styles.trackMessageContainer
      : styles.trackMessageContainerSm;
  };

  getBadgesContainerStyle = () => {
    return this.state.width > 768
      ? styles.badgesContainer
      : { ...styles.badgesContainer, ...styles.badgesContainerSm };
  };

  getPIIUrl = () => {
    const emailId = this.props.candidateConsentEmailId;
    if (!emailId) return "#";
    return `${CONSENT_FLOW_URL}/pii/${emailId}/ssn`;
  };

  getAppBadgeContainerStyle = () => {
    return this.state.width > 768
      ? styles.appBadgeContainer
      : { ...styles.appBadgeContainer, ...styles.appBadgeContainerSm };
  };

  getVerifyButtonStyle = () => {
    return { ...styles.verifyButton, ...styles.radioButton };
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  isReview = () => {
    return (
      this.props.isReview || this.props.worker_status === "review__identity"
    );
  };

  render() {
    const { processing } = this.props;
    if (!processing) return null;

    return (
      <div style={styles.reportsProcessingContainer}>
        <FormWizardStepper />
        <div style={this.getReportProcessingStyle()}>
          <>
            <div style={styles.imgContainer}>
              <div style={styles.imgDimensionsContainer}>
                {this.props.isReview ? (
                  <Img type="error" style={styles.processingImage} />
                ) : (
                  <Img type="processing" style={styles.processingImage} />
                )}
              </div>
            </div>
            <div style={styles.messages}>
              <div
                id="bgcStatusMessage"
                style={this.getGralMessageContainerStyle()}
              >
                {this.isReview() ? (
                  <FormattedMessage
                    {...messages.processingTitleError}
                    id="processingTitleErrorMessage"
                  />
                ) : (
                  <FormattedMessage
                    {...messages.processingTitle}
                    id="processingTitleMessage"
                  />
                )}
              </div>
              <div style={this.getSpecificMessageContainerStyle()}>
                {this.isReview() ? (
                  <div>
                    <FormattedMessage
                      {...messages.processingSubTitleError}
                      values={{
                        SupportLink: (
                          <a href="mailto:support@turn.ai">support@turn.ai</a>
                        )
                      }}
                    />
                    <ol style={styles.subtitleError}>
                      <li style={styles.subtitleErrorStep}>
                        <FormattedMessage
                          {...messages.processingSubTitleErrorStepOne}
                          values={{
                            SupportLink: (
                              <a href="mailto:support@turn.ai">
                                support@turn.ai
                              </a>
                            )
                          }}
                        />
                      </li>
                      <li style={styles.subtitleErrorStep}>
                        <FormattedMessage
                          {...messages.processingSubTitleErrorStepTwo}
                          values={{
                            SupportLink: (
                              <a href="mailto:support@turn.ai">
                                support@turn.ai
                              </a>
                            )
                          }}
                        />
                      </li>
                    </ol>
                    <div style={[]}>
                      <a
                        href={this.getPIIUrl()}
                        style={this.getVerifyButtonStyle()}
                      >
                        <FormattedMessage
                          {...messages.processingSubTitleErrorButton}
                        />
                      </a>
                    </div>
                    <p>
                      <FormattedMessage
                        {...messages.processingSubTitleErrorFooterOne}
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        {...messages.processingSubTitleErrorFooterTwo}
                        values={{
                          SupportLink: (
                            <a href="mailto:support@turn.ai">support@turn.ai</a>
                          )
                        }}
                      />
                    </p>
                  </div>
                ) : (
                  <FormattedMessage {...messages.processingSubTitle} />
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    );
  }
}

ReportsProcessing.propTypes = propTypes;

export default ReportsProcessing;
