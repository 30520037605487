import React from "react";
import { PropTypes } from "prop-types";
import { Button as MaterialButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { WorkerSettingsStyles as styles } from "../../styles";
import Divider from "../Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-solid-svg-icons";

import { CopyUrlButton } from "../CopyUrlButton";

export const Url = ({ value, style }) =>
  value ? <CopyUrlButton url={value} style={style} /> : null;
Url.defaultProps = { style: styles.url };
Url.propTypes = {
  style: PropTypes.object,
  value: PropTypes.string
};

export const Button = ({ onClick, value }) =>
  onClick ? (
    <MaterialButton onClick={onClick} style={styles.button} size="small">
      {value}
    </MaterialButton>
  ) : null;
Button.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool
};
Button.defaultProps = { value: "Save" };

export const Text = ({ content, style }) =>
  content ? <span style={style}> {content} </span> : null;
Text.defaultProps = { style: styles.text };
Text.propTypes = {
  content: PropTypes.string,
  style: PropTypes.object
};

export const InputField = ({ label, onChange, key, value, error, ...rest }) =>
  onChange ? (
    <div key={key} style={styles.inputField.container}>
      <span style={styles.inputField.label}>{label}</span>
      <TextField
        {...rest}
        onChange={onChange}
        fullWidth
        value={value}
        InputProps={{
          style: styles.inputField.textField
        }}
      />
      {error && error[label] && (
        <span style={styles.inputField.error}>{error[label]}</span>
      )}
    </div>
  ) : null;
InputField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  source: PropTypes.string,
  value: PropTypes.any
};

export const InlineUrl = ({ label, value, key }) => {
  return (
    <div key={key} style={styles.inlineText.container}>
      <Text content={label} style={styles.inlineText.label} />
      <Url value={value} style={styles.inlineText.url} />
      <Divider style={styles.inlineText.divider} />
    </div>
  );
};
InlineUrl.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  key: PropTypes.any
};

export const InlineText = ({ value, key }) => {
  return _.isArray(value) ? (
    <div key={key} style={styles.inlineText.container}>
      <Text content={value[0]} style={styles.inlineText.label} />
      <Text content={value[1]} />
    </div>
  ) : null;
};
InlineText.propTypes = {
  value: PropTypes.string,
  content: PropTypes.string.isRequired,
  key: PropTypes.any
};

export const SecondText = ({ label, value, key }) => (
  <div key={key} style={styles.secondText.container}>
    <Text content={label} style={styles.secondText.label} />
    <Text content={value} style={styles.secondText.value} />
  </div>
);

SecondText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  key: PropTypes.any
};

export const CurrentInvoice = ({ value, key }) => (
  <>
    {value === "None" ? (
      <Text
        content={"No invoice found."}
        style={styles.invoiceText.notFound}
        key={key}
      />
    ) : (
      <a
        href={value}
        key={key}
        style={{ marginBottom: "30px", color: "#008EFF" }}
      >
        View and download current invoice
      </a>
    )}
  </>
);
CurrentInvoice.propTypes = {
  value: PropTypes.string,
  key: PropTypes.any
};

export const CreditCard = ({
  value,
  key,
  handleExpand,
  creditCardExpanded,
  ...rest
}) => {
  return (
    <>
      <SecondText key={key} value={value} {...rest} />
      {!creditCardExpanded && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end"
          }}
        >
          <Button
            onClick={handleExpand}
            style={styles.button}
            value={
              <>
                <FontAwesomeIcon icon={faSave} style={{ marginRight: "5px" }} />
                Save
              </>
            }
          />
        </div>
      )}
    </>
  );
};

CreditCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  key: PropTypes.any
};

export const ConsentButton = ({ label, href }) => {
  const clickHandler = () => {
    window.location.href = href;
  };

  return (
    <div>
      <MaterialButton onClick={clickHandler} fullWidth={true} color="primary">
        {label}
      </MaterialButton>
    </div>
  );
};
ConsentButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};
