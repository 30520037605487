const styles = {
  appBadgeContainer: {
    width: "150px",
    display: "inline-block"
  },
  appBadge: {
    margin: "6%",
    width: "80%"
  },
  playBadge: {
    width: "100%"
  },
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "20px 0px 0px"
  },
  textContainer: {
    display: "inline-block",
    textAlign: "center"
  },
  badgesContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  },
  badgesTitle: {
    alignSelf: "center"
  }
};

export default styles;
