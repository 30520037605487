import React from "react";
import { useEffect, useState } from "react";

const loadScript = () => {
  const id = "freshchat-lib";
  if (document.getElementById(id) || window.fcWidget) return;

  const script = document.createElement("script");
  script.async = true;
  script.type = "text/javascript";
  script.src = "https://wchat.freshchat.com/js/widget.js";
  script.id = id;
  document.head.appendChild(script);
};

const FreshChat = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    loadScript();

    const initInterval = setInterval(() => {
      if (!window.fcWidget) return;
      try {
        clearInterval(initInterval);
        window.fcWidget.init({
          host: process.env.REACT_APP_FRESHCHAT_HOST,
          token: process.env.REACT_APP_FRESHCHAT_TOKEN,
          config: {
            headerProperty: {
              hideChatButton: true
            }
          }
        });

        window.fcWidget.on("widget:closed");
        setIsLoaded(true);
      } catch (error) {
        return null;
      }
    }, 1000);
  }, []);

  if (!isLoaded) return null;

  return <div style={{ display: "hidden" }} id="btn-widget"></div>;
};

export default FreshChat;
