import { secondary, white } from "../constants";

const style = {
  dialogContent: {
    padding: "36px 72px 60px 72px"
  },
  imgContainer: {
    width: "100%",
    maxHeight: "240px",
    margin: "0 auto"
  },
  imgDimensionsContainer: {
    maxWidth: "240px",
    maxHeight: "240px",
    margin: "0 auto"
  },
  headerImage: {
    width: "100%",
    height: "auto"
  },
  errorMessages: {
    height: "144px",

    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "normal"
  },
  gralErrorMessageContainer: {
    fontSize: "24px",
    fontWeight: "bold",

    flex: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  specificErrorMessageContainer: {
    overflowY: "auto",

    height: "32px",
    fontSize: "16px",

    flex: 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",

    bottom: 0
  },
  onlyGralErrorMessageContainer: {
    fontSize: "24px",
    fontWeight: "bold",

    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonContainer: {
    height: "74px",

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  turquoiseButton: {
    width: "131px",
    height: "36px",
    backgroundColor: secondary,
    borderRadius: "4px",
    color: white
  }
};
export default style;
