import styled from "styled-components";

export const RegisterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

export const WhiteButton = styled.a`
  background-color: #fff;
  border: 1px solid #7E7E8F;
  color: #7E7E8F;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  padding: 11px 24px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;

  transition: all 300ms ease-in-out;

  &:hover {
    transform: scale(0.95);
  }
  
  hover:shadow-none
  hover:scale-95
  hover:bg-white
  hover:active:bg-transparent
`;
