const styles = {
  container: {
    fontSize: "14px",
    color: "#ccc",
    position: "absolute",
    left: 15,
    bottom: 5,
    fontWeight: 300
  },
  separator: {
    display: "inline-block",
    margin: "0 10px"
  }
};
export default styles;
