import styled from "styled-components";

const Container = styled.div`
  position: relative;
  padding: ${props => props.padding || "0"};
  background-color: ${props => props.background || "inherit"};
  box-shadow: ${props =>
    props.hasBoxShadow ? "0 2px 2px 0 rgba(0, 0, 0, 0.12);" : "none"};
  margin: ${props => {
    if (props.margin) return props.margin;
    if (props.large) return "24px 30px";
    if (props.slim) return "24px 0";
    return props.small ? "16px" : "24px 26px";
  }};
  display: ${props => (props.printOnly ? "none" : "block")};
  // display: ${props => (props.printOnly ? "none" : "inline-flex")};
  page-break-inside: ${props => (props.allowPagebreak ? "auto" : "avoid")};
  border-radius: 25px;
  @media (max-width: 600px) {
    ${props => {
      if (props.large) return "24px 16px";
      return props.small ? "8px" : "12px 8px";
    }};
    margin: ${props =>
      props.responsiveMargin
        ? props.responsiveMargin
        : props.large
        ? ""
        : "12px 8px"};
    padding: ${props =>
      props.responsivePadding ? props.responsivePadding : ""};
  }
  @media print {
    display: block;
  }
`;

export const FixedContainer = styled(Container)`
  width: ${props => props.width || 300}px;
  position: fixed;

  & + div {
    margin-left: calc(${props => props.width || 300}px + 48px);
  }
`;

export default Container;
