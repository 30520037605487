import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { Observer } from "mobx-react/custom";
import CheckStatus from "../../models/checks-status";
import { CandidateTileContainer } from "./CandidateTileContainer";
import messages from "./messages";
import StatusBadge from "../StatusBadge";
import { reformatDateUTC } from "../../utils/momentParse";
import CandidateCheck from "./CandidateCheck";
import CandidateOptionalField from "./CandidateOptionalField";
import { CandidatePreviewModalStyles as styles } from "../../styles";
import { HIT, REVIEW, CLEAR } from "../../constants";
import CircularProgress from "material-ui/CircularProgress";
import { SearchHighlightedProvider } from "../SearchHighlighted";
import { formatPhone } from "turn-shared";

function CandidateTile({
  candidate,
  fetch,
  handleTileClick,
  selected,
  dispatch,
  workers
}) {
  const [model] = useState(new CheckStatus());

  const statusWithOutChecks = ["emailed", "consent", "initiated"];

  useEffect(() => {
    async function getChecks() {
      await model.getWorker(candidate.turnId, false);
      model.setSSN();
    }

    if (!statusWithOutChecks.includes(candidate.status)) {
      getChecks();
    }
  }, [model]);

  const renderCheck = ({ status, shouldRender, title }, key) => {
    if (!shouldRender) return null;

    return <CandidateCheck key={key} name={title} status={status} />;
  };

  renderCheck.propTypes = {
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    shouldRender: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
  };

  const renderSortedChecks = () => {
    return sortedChecks().map((check, i) => {
      return renderCheck(check, i);
    });
  };

  const sortedChecks = () => {
    return [
      {
        id: messages.watchlist.id,
        status: model.watchlistStatus,
        shouldRender: model.watchlistShouldRender,
        title: <FormattedMessage {...messages.watchlist} />
      },
      {
        id: messages.sexOffender.id,
        status: model.sexOffenderStatus,
        shouldRender: model.sexOffenderShouldRender,
        title: <FormattedMessage {...messages.sexOffender} />
      },
      {
        id: messages.ssnTrace.id,
        status: model.ssnStatus,
        shouldRender: model.ssnShouldRender,
        title: <FormattedMessage {...messages.ssnTrace} />
      },
      {
        id: messages.addresses.id,
        status: null,
        shouldRender: model.addressesShouldRender,
        title: <FormattedMessage {...messages.addresses} />
      },
      {
        id: messages.criminal.id,
        status: model.criminalStatus,
        shouldRender: model.criminalShouldRender,
        title: <FormattedMessage {...messages.criminal} />
      },
      {
        id: messages.mvr.id,
        status: model.mvrStatus,
        shouldRender: model.mvrShouldRender,
        title: <FormattedMessage {...messages.mvr} />
      }
    ]
      .sort(({ status }) => (status === HIT ? 1 : -1))
      .sort(({ status }) => (status === REVIEW ? 1 : -1))
      .sort(({ status }) => (status === CLEAR || status === null ? 1 : -1));
  };

  const renderLoading = () => (
    <div
      style={{
        width: "100%",
        height: "100%",
        opacity: 0.5,
        zIndex: 2
      }}
    >
      <CircularProgress style={styles.progress} size={80} thickness={5} />
    </div>
  );

  return (
    <Observer>
      {() => (
        <SearchHighlightedProvider search={workers.search}>
          <CandidateTileContainer
            id="applicantDetailsContainer"
            onClick={() => handleTileClick(candidate)}
          >
            <StatusBadge
              candidate={candidate}
              fetch={fetch}
              selected={selected}
              dispatch={dispatch}
            />
            <div>
              <ul className="candidate-info">
                <CandidateOptionalField
                  type="Name"
                  value={candidate.displayName}
                  id="applicant-name"
                />
                <CandidateOptionalField
                  type="Email"
                  value={candidate.email}
                  id="applicant-email"
                />
                <CandidateOptionalField
                  type="Phone Number"
                  value={formatPhone(candidate.phoneNumber)}
                  id="applicant-phone"
                />
                <CandidateOptionalField
                  type="Location"
                  value={candidate.city}
                  id="applicant-location"
                />
                <CandidateOptionalField
                  type="Consent Date"
                  value={reformatDateUTC(
                    new Date(candidate.consentDate),
                    "MM/DD/YYYY"
                  )}
                  id="applicant-consent-date"
                />
                <CandidateOptionalField
                  type="Package"
                  value={candidate.package}
                  id="applicant-package"
                />
                {candidate.status === "initiated" ? (
                  <CandidateOptionalField
                    type="Consent Given"
                    value={candidate.worker_consent}
                    id="applicant-consent"
                  />
                ) : null}
                {candidate.actionBy.toLowerCase() === "turn app" ? null : (
                  <>
                    <CandidateOptionalField
                      type={
                        candidate.status === "rejected"
                          ? "Rejected Date"
                          : "Action Date"
                      }
                      value={reformatDateUTC(
                        new Date(candidate.actionDate),
                        "MM/DD/YYYY"
                      )}
                      id="applicant-rejected-action-date"
                    />
                    <CandidateOptionalField
                      type={
                        candidate.status === "rejected"
                          ? "Rejected By"
                          : "Action By"
                      }
                      value={candidate.actionBy}
                      id="applicant-rejected-action-by"
                    />
                  </>
                )}
              </ul>
            </div>
            {statusWithOutChecks.includes(candidate.status) ? null : (
              <div style={{ position: "relative", minHeight: "38%" }}>
                <ul className="candidate-checks">
                  {!model.ready || model.error || model.notFound
                    ? renderLoading()
                    : renderSortedChecks()}
                </ul>
              </div>
            )}
          </CandidateTileContainer>
        </SearchHighlightedProvider>
      )}
    </Observer>
  );
}

CandidateTile.propTypes = {
  candidate: PropTypes.object,
  history: PropTypes.object,
  fetch: PropTypes.func.isRequired,
  handleTileClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  dispatch: PropTypes.func,
  workers: PropTypes.object
};

export default CandidateTile;
