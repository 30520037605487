import { white, secondary } from "../../styles/constants";

const style = {
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "37px"
  },
  turquoiseButton: {
    width: "228px",
    height: "42px",
    borderRadius: "6px",
    backgroundColor: secondary,
    color: white,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  linkText: {
    color: secondary
  }
};
export default style;
