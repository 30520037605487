import styled from "styled-components";

const FormLockContainer = styled.div`
  position: relative;
  background-color: transparent;
  ${({ formLock }) =>
    formLock &&
    `
    &::before {
        content:'';
    position:absolute;
    z-index: 999;
    inset: 0;
    }
  `}
`;

export default FormLockContainer;
