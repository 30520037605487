import React from "react";
import PropTypes from "prop-types";
import {
  Content,
  Support,
  CardForm,
  CardList
} from "../../components/ReportAuthorization";
import Background from "../../components/Background";
import { observer } from "mobx-react";
import Logo from "../../components/Logo";
import { ReportAuthorizationStyles as styles } from "../../styles";

const ReportAuthorizationPage = observer(
  class ReportAuthorizationPage extends React.Component {
    static propTypes = {
      handleSubmit: PropTypes.func,
      model: PropTypes.object
    };

    constructor(props) {
      super(props);
      this.model = this.props.model;
      this.state = {
        value: this.model.tokens[0] || "ssn",
        token: "",
        options: false
      };
    }

    componentDidMount() {
      this.setModelError(false, "");
    }

    toggleOptions = () => this.setState({ options: !this.state.options });

    handleSubmit = () => this.props.handleSubmit(this.state.token);

    handleChange = e => {
      if (!e || !e.target) return;
      this.validateToken(e.target.value);
    };

    handleKeyDown = ({ keyCode }) => {
      if (keyCode === 13) {
        this.handleSubmit();
      }
    };

    setModelError = (error, message) => {
      this.model.setError(error);
      this.model.setErrorMessage(message);
    };

    validateToken = token => {
      this.setState({ token });
      if (token.length !== 4)
        this.setModelError(true, "Only four characters allowed");
      else this.setModelError(false, "");
    };

    renderCard = () =>
      this.state.options ? (
        <CardList options={this.model.tokens} onClick={this.handleSelect} />
      ) : (
        <CardForm
          value={this.state.value}
          handleChange={this.handleChange}
          handleKeyDown={this.handleKeyDown}
          toggleOptions={this.toggleOptions}
          handleSubmit={this.handleSubmit}
          error={this.model.error}
          errorMessage={this.model.errorMessage}
        />
      );

    handleSelect = value => {
      if (!value) return;
      this.setState({ value });
      this.toggleOptions();
    };

    render() {
      return (
        <Background>
          <Content>
            <div style={styles.logoContainer}>
              <Logo svg height="100%" fill="#2d3047" />
            </div>
            <div>
              {this.renderCard()}
              <Support />
            </div>
          </Content>
        </Background>
      );
    }
  }
);

export default ReportAuthorizationPage;
