import { defineMessages } from "react-intl";

export default defineMessages({
  toggle: {
    id: "app.components.WorkersContainer.toggle",
    defaultMessage: "Only identified"
  },
  consentModalTitle: {
    id: "app.containers.WorkersContainer.consentModalTitle",
    defaultMessage: "{displayName} has not yet consented to this report."
  },
  processingModalTitle: {
    id: "app.containers.WorkersContainer.processingModalTitle",
    defaultMessage: "{displayName}'s background report is not complete yet."
  },
  consentModalBody: {
    id: "app.containers.WorkersContainer.consentModalBody",
    defaultMessage:
      "Viewing report details or taking action on this candidate may open you to legal liability."
  },
  download: {
    id: "app.containers.WorkersContainer.download",
    defaultMessage: "Download"
  },
  viewDetails: {
    id: "app.containers.WorkersContainer.viewDetails",
    defaultMessage: "View Report"
  },
  gralMessage: {
    id: "app.containers.WorkersContainer.candidateNotConsented",
    defaultMessage: "The candidate has not yet consented to this report"
  },
  buttonCancel: {
    id: "app.containers.WorkersContainer.buttonCancel",
    defaultMessage: "Cancel"
  },
  buttonClose: {
    id: "app.containers.WorkersContainer.buttonClose",
    defaultMessage: "Close"
  },
  buttonAgree: {
    id: "app.containers.WorkersContainer.buttonAgree",
    defaultMessage: "Agree"
  },
  copyCandidateConsentUrl: {
    id: "app.containers.WorkersContainer.copyCandidateConsentUrl",
    defaultMessage: "Copy Candidate Consent URL"
  },
  copied: {
    id: "app.containers.WorkersContainer.copied",
    defaultMessage: "Copied"
  },
  watchlist: {
    id: "app.containers.WorkersContaienr.watchlist",
    defaultMessage: "Watchlists"
  },
  sexOffender: {
    id: "app.containers.WorkersContaienr.sexOffender",
    defaultMessage: "Sex Offender Check"
  },
  ssnTrace: {
    id: "app.containers.WorkersContaienr.ssnTrace",
    defaultMessage: "SSN Trace"
  },
  addresses: {
    id: "app.containers.WorkersContaienr.addresses",
    defaultMessage: "Addresses"
  },
  criminal: {
    id: "app.containers.WorkersContaienr.criminal",
    defaultMessage: "Criminal Records"
  },
  mvr: {
    id: "app.containers.WorkersContaienr.mvr",
    defaultMessage: "Motor Vehicle Record"
  },
  csvModalBody: {
    id: "app.containers.WorkersContaienr.csvModalBody",
    defaultMessage: "Please select the date range for your report:"
  },
  csvModalTitleFrom: {
    id: "app.containers.WorkersContaienr.csvModalBody",
    defaultMessage: "From"
  },
  csvModalTitleTo: {
    id: "app.containers.WorkersContaienr.csvModalBody",
    defaultMessage: "To"
  },
  csvModalError: {
    id: "app.containers.WorkersContaienr.csvModalError",
    defaultMessage: "Something went wrong, please contact support@turn.ai"
  }
});
