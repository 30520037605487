import React from "react";
import ScrollContainer from "./scroll-container";
import styled from "styled-components";
import { white } from "../../styles/constants";

const ScrollPaper = styled.div`
  position: relative;
  background: ${white};
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  padding 20px;
  font-size: 16px;
  color: #7E7E8F;
`;

export const TurnScroll = props => (
  <ScrollPaper>
    <ScrollContainer className="turn-scroll" {...props} />
  </ScrollPaper>
);

export default TurnScroll;
