import React from "react";
import PropTypes from "prop-types";
import { ModalStyles } from "../../styles";
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import Modal from "../../components/Modal/modal";
import { turnAPI } from "../../services";
import DotsLoader from "../../components/DotsLoader";
import styles from "./styles";

class ResumeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: true
    };
  }

  setReady(bool) {
    if (typeof bool === "boolean") {
      this.setState({ ready: bool });
    }
  }

  handleResponse = response => {
    this.setReady(true);
    this.props.handleClose();
    switch (response.status) {
      case 200:
        window.location.reload();
        break;
      case 500:
        if (response.data && response.data.request_id)
          this.props.openErrorDialog(`Request ID: ${response.data.request_id}`);
        else this.props.openErrorDialog();
        break;
      default:
        this.props.openErrorDialog();
        break;
    }
  };

  handleConfirm = async () => {
    this.setReady(false);
    let response;
    const { worker } = this.props;
    if (worker.isPendingReview === true) {
      response = await turnAPI.resumeChecks(worker.turnId);
    } else if (worker.isPendingMVRReview === true) {
      response = await turnAPI.resumeChecksMVR(worker.turnId);
    } else if (worker.isPendingAgeReview === true) {
      response = await turnAPI.resumeChecksAge(worker.turnId);
    }
    if (!response) {
      this.props.openErrorDialog(
        "Worker is not pending any reviews. No checks resumed."
      );
      this.setReady(true);
      this.props.handleClose();
      return;
    }
    this.handleResponse(response);
  };

  getMessage = () => {
    let model = this.props.worker;
    if (model.isPendingReview)
      return <FormattedMessage {...messages.resumeConfirmation} />;
    if (model.isPendingMVRReview)
      return <FormattedMessage {...messages.resumeMVRConfirmation} />;
    if (model.isPendingAgeReview)
      return <FormattedMessage {...messages.resumeAgeConfirmation} />;
  };

  displayModalContents = () => {
    const { worker } = this.props;
    return (
      <div style={styles.resumeWorkerModalContent.container}>
        Are you sure you want to resume checks on{" "}
        <span style={styles.resumeWorkerModalContent.workerName}>
          {worker.firstName} {worker.lastName}
        </span>
        ?
      </div>
    );
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <Modal
        Modal
        open={open}
        title={this.getMessage()}
        primaryButtonProps={{
          title: "Ok",
          onClick: this.handleConfirm
        }}
        secondaryButtonProps={{
          title: "Cancel",
          onClick: handleClose
        }}
        flexPaper
        contentStyle={{
          paddingLeft: "0px"
        }}
      >
        {this.state.ready ? (
          this.displayModalContents()
        ) : (
          <div style={ModalStyles.resumeLoader}>
            <DotsLoader />
          </div>
        )}
      </Modal>
    );
  }
}

ResumeModal.propTypes = {
  open: PropTypes.bool,
  worker: PropTypes.object,
  openErrorDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ResumeModal;
