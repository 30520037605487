import {
  AddressField,
  CityField,
  FirstNameField,
  LastNameField,
  ZipcodeField,
  SSNField
} from "./candidate-fields";
import React, { Component } from "react";

import Column from "../Column";
import Form from "../Form";
import { FormWizardBackForward } from "./components/FormWizardBackNext";
import { FormWizardStep } from "./components/FormWizardStep";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import Title from "../Title";
import messages from "./messages";
import { observer } from "mobx-react";
import { LandingPageStyles as styles } from "../../styles";
import { FormCheckLine } from "./components/FormCheckLine";
import syslog from "../../services/syslog";

class TabPAForm extends Component {
  static propTypes = {
    ...FormWizardStep.propTypes,
    details: PropTypes.object,
    emailId: PropTypes.string,
    setStep: PropTypes.func,
    currentStep: PropTypes.number,
    handleSubmit: PropTypes.func.isRequired
  };

  render() {
    const { details: d } = this.props;
    return (
      <>
        <Title id="paStepTitle" align="left" big>
          Just one more step...
        </Title>
        <p style={styles.text}>
          {d.partnerName}
          <FormattedMessage {...messages.paReleaseFormHeader} />
        </p>

        <Form>
          <p style={{ ...styles.textBig, marginBottom: 5, marginTop: 50 }}>
            <FormattedMessage {...messages.driverInformation} />
          </p>

          <hr style={styles.hr} />
          {d.error ? <p style={styles.error}>{d.error}</p> : null}
          <LastNameField model={d} width="50%" />
          <FirstNameField model={d} width="50%" />
          <SSNField name="ssn" model={d} width="50%" shouldRender />
          <AddressField model={d} width="100%" />
          <CityField model={d} width="50%" />
          <ZipcodeField model={d} width="50%" shouldRender />
        </Form>

        <FormCheckLine
          checked={d.releaseCertify}
          onChange={() => d.setReleaseCertify(!d.releaseCertify)}
          label={
            <>
              I, {d.firstName} {d.initial}. {d.lastName} hereby request the
              Department of Transportation to furnish a copy of my PA Driver’s
              Record to {d.partnerName}
            </>
          }
        />

        <Column
          textAlign="right"
          textAlignSM="center"
          width="100%"
          margin="20px 0 0 0"
        >
          <FormWizardBackForward
            nextDisabled={!d.informationReleaseReady}
            onBack={() => this.props.previousStep()}
            onNext={() => {
              const detailsData = { ...d.formData };
              detailsData["has_middle_name"] = !detailsData["has_middle_name"];
              detailsData["tab"] = "step_7_PARealese_form";
              syslog([detailsData]);
              this.props.nextStep();
            }}
          />
        </Column>
      </>
    );
  }
}

export default observer(TabPAForm);
