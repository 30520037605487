import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { List, ListItem } from "material-ui/List";
import {
  ListDetailStyles as styles,
  CandidateReportStyles as reportStyles
} from "../../../styles";
import {
  primary,
  orange,
  green,
  periwinkle,
  red
} from "../../../styles/constants";
import ListItemContainer from "../../../components/ListDetailView/list-item-container";
import ListContainer from "../../../components/ListDetailView/list-container";
import messages from "../messages";
import Divider from "material-ui/Divider";
import PendingReview from "../../../components/PendingReview";
import PendingMVRReview from "../../../components/PendingMVRReview";
import PendingAgeReview from "../../../components/PendingAgeReview";
import { PROCESSING, HIT, REVIEW, FEDERAL, READY } from "../../../constants";
import ProcessingMVR from "../../../components/ProcessingMVR";
import PanelCollapsible from "../../../components/PanelCollapsible";
import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner,
  faPauseCircle,
  faInboxOut
} from "@fortawesome/pro-solid-svg-icons";
import withContext from "../../../contextAPI/context-HOC";
import _ from "lodash";
import Highlighted from "../../../components/Highlighted";
import { SearchHighlighted } from "../../../components/SearchHighlighted";
import IconContainer from "../../../components/IconContainer";
import RecordEta from "./Eta";
import { isEmpty, isString } from "lodash";
import RecordDetail from "./RecordDetail";
import { pickColor } from "turn-shared";
import { WITHDRAWN } from "../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_MIN_WIDTH } from "../../../constants";

export class FederalCheckComponent extends Component {
  static propTypes = {
    worker: PropTypes.object,
    status: PropTypes.string,
    context: PropTypes.object,
    expandAll: PropTypes.bool
  };
  static defaultProps = {
    worker: {}
  };
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      width: 0
    };
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  setExpand = expand => {
    this.setState({ expand });
  };

  toggleExpand = () => {
    this.setState(prevProps => ({ expand: !prevProps.expand }));
  };

  getWindowSize = () => {
    let { width } = this.state;

    if (width <= 600) {
      return "small";
    }
    if (width <= 800) {
      return "medium";
    }
    if (width <= 1300) {
      return "large";
    }
  };

  get color() {
    if (this.props.status === REVIEW) return orange;
    if (this.props.status === PROCESSING) return periwinkle;
    if (this.props.status === HIT) return red;
    return green;
  }

  get icon() {
    if (this.props.status === REVIEW) return faPauseCircle;
    if (this.props.status === PROCESSING) return faSpinner;
    if (this.props.status === HIT) return faExclamationTriangle;
    return faCheckCircle;
  }

  clearStyle = {
    color: primary,
    fontWeight: "bold",
    display: "block",
    margin: "20px 5%"
  };

  renderContent = () => {
    const { expand } = this.state;
    const { worker } = this.props;
    const { federalCriminalData: federalCriminalHits } = worker;
    if (worker.isProcessingMVR) {
      return <ProcessingMVR message={messages.federalCheck} />;
    }
    if (worker.isPendingReview) {
      return <PendingReview message={messages.federalCheck} />;
    }
    if (worker.isPendingMVRReview) {
      return <PendingMVRReview message={messages.federalCheck} />;
    }
    if (worker.isPendingAgeReview) {
      return <PendingAgeReview message={messages.federalCheck} />;
    }

    return (
      <ListContainer simple>
        <List style={styles.list}>
          <ListItemContainer data-test="list-detail-item" simple key={0}>
            {federalCriminalHits.map((federalCriminalData, i) => {
              return (
                <ListItem key={i.toString()} disabled style={{ padding: 0 }}>
                  <div>
                    <div
                      style={{
                        minHeight: "4rem",
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap"
                      }}
                    >
                      <div>
                        {this.renderSubtitle(
                          federalCriminalData.status,
                          federalCriminalData.values
                        )}
                      </div>
                      <div>
                        {this.renderTitle(
                          federalCriminalData.district,
                          federalCriminalData.status
                        )}
                      </div>
                      <IconContainer right="34px">
                        <>
                          {this.renderDetail(
                            "ETA Clear:",
                            federalCriminalData.clear_eta,
                            federalCriminalData.status
                          )}
                          {this.renderDetail(
                            "ETA Hit:",
                            federalCriminalData.hit_eta,
                            federalCriminalData.status
                          )}
                        </>
                      </IconContainer>
                    </div>
                    {!isEmpty(federalCriminalData.values)
                      ? federalCriminalData.values.map((item, index) => (
                          <>
                            <RecordDetail
                              expand={expand}
                              width={this.getWindowSize()}
                              idSuffix={index}
                              detail={item}
                              key={index}
                            />
                            {federalCriminalData.values.length !== 1 &&
                              index + 1 !==
                                federalCriminalData.values.length && (
                                <Divider />
                              )}
                          </>
                        ))
                      : null}
                  </div>
                </ListItem>
              );
            })}
          </ListItemContainer>
        </List>
      </ListContainer>
    );
  };

  renderDetail = (label, detail, status) =>
    this.validateString(detail) && status === "processing" ? (
      <RecordEta label={label} detail={detail} />
    ) : null;

  validateString = string => isString(string) && !isEmpty(string);

  renderSubtitle = (status, values) => {
    if (status === PROCESSING) {
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          color={periwinkle}
          style={{ fontSize: "20px", minWidth: ICON_MIN_WIDTH }}
        />
      );
    }
    if (status === WITHDRAWN) {
      return (
        <FontAwesomeIcon
          icon={faInboxOut}
          color={red}
          style={{ fontSize: "20px", minWidth: ICON_MIN_WIDTH }}
        />
      );
    }
    if (isEmpty(values) && status === READY) {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={green}
          style={{ fontSize: "20px", minWidth: ICON_MIN_WIDTH }}
        />
      );
    }
    if (values && values.length > 0 && status === READY) {
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          color={red}
          style={{ fontSize: "20px", minWidth: ICON_MIN_WIDTH }}
        />
      );
    }
  };

  renderTitle = (district, status) => {
    return (
      <Highlighted
        black
        style={{
          minWidth: "8rem",
          display: "inline-block",
          marginLeft: "25px"
        }}
      >
        {status === WITHDRAWN ? (
          <>
            <SearchHighlighted textToHighlight={district} />
            <Highlighted
              style={{
                color: pickColor(status),
                position: "absolute",
                right: 120
              }}
            >
              {_.upperCase(status)}
            </Highlighted>
          </>
        ) : status === PROCESSING ? (
          <SearchHighlighted textToHighlight={district} />
        ) : (
          <SearchHighlighted textToHighlight={`${district}`} />
        )}
      </Highlighted>
    );
  };

  render() {
    const { worker, context } = this.props;
    const { checks = {} } = context || {};
    const { federalCriminalData } = worker;

    if (
      (!federalCriminalData && !worker.isPendingReview) ||
      (!federalCriminalData && !worker) ||
      _.isEmpty(federalCriminalData)
    ) {
      return null;
    }
    if (worker.isPendingReview && !worker.shouldShowCheck(FEDERAL)) return null;
    if (worker.isPendingMVRReview && !worker.shouldShowCheck(FEDERAL))
      return null;
    if (worker.isProcessingMVR && !worker.shouldShowCheck(FEDERAL)) return null;

    return (
      <PanelCollapsible
        padding="10px 0"
        title={messages.federalCheck}
        titleStyle={reportStyles.title}
        iconStyle={reportStyles.checkedIcon}
        color={this.color}
        icon={this.icon}
        collapsible={
          !(federalCriminalData.length === 0 && this.props.status !== REVIEW)
        }
        expandAll={this.props.expandAll}
        expandAllProps={{
          toggled: this.state.expand,
          onClick: this.toggleExpand
        }}
        setExpand={this.setExpand}
        expandAllChecks={checks.expandAllChecks}
        panelProps={{
          style: {
            filter:
              "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
          }
        }}
      >
        {this.renderContent()}
      </PanelCollapsible>
    );
  }
}

export default withContext(observer(FederalCheckComponent));
