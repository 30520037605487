import { defineMessages } from "react-intl";

export default defineMessages({
  fullName: {
    id: "app.components.WorkerTable.headerName",
    defaultMessage: "Name"
  },
  city: {
    id: "app.components.WorkerTable.headerCity",
    defaultMessage: "Location"
  },
  email: {
    id: "app.components.WorkerTable.headerEmail",
    defaultMessage: "Email"
  },
  status: {
    id: "app.components.WorkerTable.headerStatus",
    defaultMessage: "Status"
  },
  checkStatus: {
    id: "app.components.WorkerTable.checkStatus",
    defaultMessage: "Check status"
  },
  emailStatus: {
    id: "app.components.WorkerTable.emailStatus",
    defaultMessage: " "
  },
  empty: {
    id: "app.components.WorkerTable.empty",
    defaultMessage: " "
  },
  signUpDate: {
    id: "app.components.WorkerTable.headerDate",
    defaultMessage: "Consent Date"
  },
  package: {
    id: "app.components.WorkerTable.headerPackage",
    defaultMessage: "Package"
  },
  page: {
    id: "app.components.WorkerTable.page",
    defaultMessage: "Page"
  },
  of: {
    id: "app.components.WorkerTable.of",
    defaultMessage: "of"
  },
  rows: {
    id: "app.components.WorkerTable.rows",
    defaultMessage: "Rows"
  },
  showRows: {
    id: "app.components.WorkerTable.showRows",
    defaultMessage: "Show"
  }
});
