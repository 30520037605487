import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const DoubleBounceLoader = ({ modifier, style }) => (
  <div id="spinnerIcon" className="spinner" style={style}>
    <div
      className={`double-bounce1${
        modifier ? ` double-bounce1--${modifier}` : ""
      }`}
    />
    <div
      className={`double-bounce2${
        modifier ? ` double-bounce2--${modifier}` : ""
      }`}
    />
  </div>
);

DoubleBounceLoader.propTypes = {
  modifier: PropTypes.string,
  style: PropTypes.object
};

export default DoubleBounceLoader;
