import React, { useEffect, useState } from "react";
import propTypes from "prop-types";

import { useDebounce } from "./useDebounce";

/**
 * Hook that listens to window resize and updates the state with the new dimensions.
 *
 * @returns {{width: number, height: number}}
 */
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

/**
 * Forces the component to re-render (by re-mounting it) when the browser window is resized.
 *
 * @param {object} props
 * @param {bool} props.onHeight Defaults to `true`. If true, the component will re-render when the height changes.
 * @param {bool} props.onWidth Defaults to `true`. If true, the component will re-render when the width changes.
 * @returns React.Node
 */
export const RemountOnWindowResize = ({ onWidth, onHeight, children }) => {
  const [uniqueKey] = useState((Math.random() + 1).toString(36).substring(7));
  const { width, height } = useWindowDimensions();
  const widthKey = onWidth === undefined || onWidth === true ? width : "X";
  const heightKey = onHeight === undefined || onHeight === true ? height : "X";
  const key = `RemountOnWindowResize-${uniqueKey}-${widthKey}-${heightKey}`;
  const debouncedKey = useDebounce(key, 300);

  return <React.Fragment key={debouncedKey}>{children}</React.Fragment>;
};
RemountOnWindowResize.propTypes = {
  children: propTypes.node.isRequired,
  onWidth: propTypes.bool,
  onHeight: propTypes.bool
};
