import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableRow
} from "material-ui/Table";
import { observer } from "mobx-react";
import { PropTypes } from "prop-types";
import React from "react";
import { TableStyles as _styles } from "../../styles";

const DataTable = observer(
  ({
    header,
    body,
    footer,
    styles: {
      tableWrapper = {},
      container = {},
      tableBody = {},
      headerRow = {}
    } = {}
  }) =>
    header && body ? (
      <div style={{ ..._styles.new.container, ...container }}>
        <Table style={{ width: "90%", ...tableWrapper }}>
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            style={_styles.head}
          >
            <TableRow style={headerRow}>{header}</TableRow>
          </TableHeader>
          <TableBody showRowHover displayRowCheckbox={false} style={tableBody}>
            {body}
          </TableBody>
          {footer && (
            <TableFooter style={_styles.footer} className="table-footer-custom">
              {footer}
            </TableFooter>
          )}
        </Table>
      </div>
    ) : null
);

DataTable.propTypes = {
  body: PropTypes.node,
  header: PropTypes.node,
  headerContainerStyle: PropTypes.object
};

export default DataTable;
