import React from "react";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import PropTypes from "prop-types";

const BackButton = ({ linkBack, labelText, show }) => {
  return (
    <h1
      onClick={linkBack}
      style={{
        visibility: show ? "hidden" : "visible",
        fontWeight: "normal",
        fontSize: "1.2rem",
        cursor: "pointer"
      }}
    >
      <ArrowBack
        style={{
          visibility: show ? "hidden" : "visible",
          fill: "#000000",
          marginRight: "20px "
        }}
      />
      {labelText}
    </h1>
  );
};

BackButton.propTypes = {
  linkBack: PropTypes.func.isRequired,
  labelText: PropTypes.string.isRequired,
  show: PropTypes.bool
};

export default BackButton;
