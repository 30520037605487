/* eslint-disable no-case-declarations */

import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import Checkbox from "material-ui/Checkbox";
import FieldSet from "../../../components/Form/field-set";
import { turnAPI } from "../../../services";
import { styles } from "./styles";
import Modal from "../../../components/Modal/modal";
import messages from "../messages";
import _ from "lodash";
import SubTitle from "../../../components/SubTitle";
import WarningConceptIcon from "../../../icons/svgr/WarningConceptIcon";
import { getIsNotEmptyRecords } from "./utils";
import { MODAL_HEADING } from "./constants";
import {
  CriminalRecordsContainer,
  MVRMonitoringWarningContainer,
  ModalHeading,
  ModalWrapper,
  NoteField,
  NoteFieldLabel
} from "./styled";

class RejectModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      criminalRecords: this.getCriminalRecords(),
      federalRecords: this.getFederalRecords(),
      hasMVRMonitoringOneOffChecks: this.props.worker
        .hasMVRMonitoringOneOffChecks,
      isNoteFieldFocused: false,
      loading: false,
      mvr: this.getMVR(),
      nationalRecords: this.props.worker.criminalRecords,
      note: "",
      reason: "",
      selectedRecords: [],
      sexOffenderRecords: this.props.worker.sexOffenders,
      isScrolledTop: false,
      watchListRecords: this.props.worker.watchLists
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.state.isScrolledTop) {
      const hitsContainer = document.getElementById("hits-container");

      if (hitsContainer) {
        hitsContainer.scrollTop = 0;
        this.setState({ isScrolledTop: true });
      }
    }
  }

  setNote = e => this.setState({ note: e.target.value });
  setReason = e => this.setState({ reason: e.target.value });
  rejectWorker = async () => {
    this.setState({ loading: true });
    const response = await turnAPI.rejectWorker(this.props.worker.id, {
      note: this.state.note,
      reports: this.state.selectedRecords
    });
    if (!response) return;
    switch (response.status) {
      case 200:
        window.location.reload();
        break;
      case 307:
        this.props.auth.permission();
        break;
      case 401:
        this.props.auth.logout();
        break;
      default:
        break;
    }
    this.setState({ loading: false });
  };
  setRecord = (report_id, kind, value) => event => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const record = { report_id, kind, value };
      this.setState({
        selectedRecords: [record, ...this.state.selectedRecords]
      });
    } else {
      const records = this.state.selectedRecords;
      const filteredRecords = records.filter(
        record => record.report_id !== report_id
      );
      this.setState({
        selectedRecords: filteredRecords
      });
    }
  };

  getFederalRecords = () =>
    this.props.worker && _.isEmpty(this.props.worker.federalCriminalData)
      ? null
      : this.props.worker.federalCriminalData[0].values;
  getCriminalRecords = () => {
    const records =
      this.props.worker && this.props.worker.countyCriminalData
        ? this.props.worker.countyCriminalData.map(item =>
            this.props.worker.filterCountyCriminalData(item.value)
          )
        : null;
    const filteredRecords = [].concat.apply([], records);
    return filteredRecords;
  };
  getMVR = () => {
    const keys = ["accidents", "actions", "violations"];
    const records =
      this.props.worker && this.props.worker.mvr
        ? Object.keys(this.props.worker.mvr)
            .filter(
              key => keys.includes(key) && this.props.worker.mvr[key].length > 0
            )
            .map(key => {
              let records;
              switch (key) {
                case "violations":
                  records = this.props.worker.mvr[key].map(record => {
                    const newRecord = Object.assign({}, record);
                    newRecord.description =
                      newRecord.descriptions && record.descriptions.lenght > 0
                        ? record.descriptions[0].short_description
                        : record.sub_violations &&
                          record.sub_violations.length > 0 &&
                          record.sub_violations[0].descriptions
                        ? record.sub_violations[0].descriptions[0]
                            .short_description
                        : `Violation: ${record.state_code}`;
                    newRecord.type = "mvr_violation";
                    return newRecord;
                  });
                  return records;
                case "actions":
                  records = this.props.worker.mvr[key].map(record => {
                    const newRecord = Object.assign({}, record);
                    newRecord.description =
                      newRecord.descriptions && record.descriptions.lenght > 0
                        ? record.descriptions[0].short_description
                        : record.sub_violations &&
                          record.sub_violations.length > 0 &&
                          record.sub_violations[0].descriptions
                        ? record.sub_violations[0].descriptions[0]
                            .short_description
                        : `Action: ${record.action_type} - ${record.action_code}`;
                    newRecord.type = "mvr_action";
                    return newRecord;
                  });
                  return records;
                case "accidents":
                  records = this.props.worker.mvr[key].map(record => {
                    const newRecord = Object.assign({}, record);
                    newRecord.description =
                      newRecord.descriptions && record.descriptions.lenght > 0
                        ? record.descriptions[0].short_description
                        : record.sub_violations &&
                          record.sub_violations.length > 0 &&
                          record.sub_violations[0].descriptions
                        ? record.sub_violations[0].descriptions[0]
                            .short_description
                        : `Accident: ${record.accident_details}`;
                    newRecord.type = "mvr_accident";
                    return newRecord;
                  });
                  return records;
                default:
                  return this.props.worker.mvr[key];
              }
            })
        : [];
    const filteredRecords = [].concat.apply([], records);
    return filteredRecords;
  };

  renderRecords = (records, type, label) => {
    return (
      <>
        {records &&
          records.length > 0 &&
          !records.every(record => _.isNull(record)) && (
            <SubTitle style={{ ...styles.recordsLabel, fontWeight: "400" }}>
              {label}
            </SubTitle>
          )}
        {records &&
          records.length > 0 &&
          records.map((record, index) => {
            if (_.isNull(record) || !_.isObject(record)) return;
            let description, recordType, recordId;
            switch (type) {
              case "mvr":
                description = record.description;
                recordType = record.type;
                recordId = record.id;
                break;
              case "county_criminal":
                description = record.offense_description_1;
                recordType = type;
                recordId = record.id;
                break;
              case "sex_offender":
                description = `${record.state} - ${record.offense_description1}`;
                recordType = type;
                recordId = record.id;
                break;
              case "watchlist":
                description = record.source;
                recordType = type;
                recordId = record.id;
                break;
              case "national":
                description = `${record.source_state} -  ${record.offense_description_1}`;
                recordType = type;
                recordId = record.id;
                break;
              case "federal":
                description = `${record.state} -  ${record.offense_description1}`;
                recordType = type;
                recordId = record.id;
                break;
              case "other":
                description = label;
                recordType = type;
                recordId = null;
                break;
              default:
                description = "";
                break;
            }

            return (
              <div key={`${record.id}-${index}`} className="record-item">
                <FieldSet
                  width="100%"
                  padding="0 12px 15px 0"
                  shouldRender={true}
                >
                  <Checkbox
                    label={description}
                    onClick={this.setRecord(recordId, recordType, description)}
                    iconStyle={styles.checkbox}
                  />
                </FieldSet>
              </div>
            );
          })}
      </>
    );
  };

  renderMVRMonitoringWarningText = () => (
    <MVRMonitoringWarningContainer>
      <div style={{ flexShrink: "0" }}>
        <WarningConceptIcon width={26} height={26} />
      </div>
      <p style={{ margin: "0", fontSize: "14px" }}>
        {" "}
        <strong style={{ color: "#EC8C56" }}>Important:</strong> Remember to
        only adjudicate based on the records found in the MVR Monitoring report.
      </p>
    </MVRMonitoringWarningContainer>
  );

  renderOtherNonCriminalReasons = () => (
    <div>
      <span style={styles.recordsLabel}>Other non-criminal reasons</span>
      <div style={{ position: "relative" }}>
        <NoteField
          onBlur={() => this.setState({ isNoteFieldFocused: false })}
          onChange={this.setNote}
          onFocus={() => this.setState({ isNoteFieldFocused: true })}
          placeholder={
            this.props.worker.setRequestCharges
              ? messages.rejectHintCharges.defaultMessage
              : messages.rejectHint.defaultMessage
          }
          rows={5}
          value={this.state.note}
        />
        <NoteFieldLabel
          isFocused={this.state.isNoteFieldFocused}
          isNotEmpty={!!this.state.note}
        >
          Note
        </NoteFieldLabel>
      </div>
    </div>
  );

  recordsContainer = () => {
    const isPending =
      this.props.worker.onBoardingStatus === "pending" ||
      this.props.worker.onBoardingStatus === "Compliance Review";

    const {
      mvr,
      sexOffenderRecords,
      criminalRecords,
      watchListRecords,
      nationalRecords,
      federalRecords,
      hasMVRMonitoringOneOffChecks
    } = this.state;

    const isNotEmptyRecords = getIsNotEmptyRecords(this.state);
    const shouldRenderRecords =
      isPending && isNotEmptyRecords && !hasMVRMonitoringOneOffChecks;

    return (
      <ModalWrapper>
        <ModalHeading id="modal-heading">
          {shouldRenderRecords
            ? MODAL_HEADING.withHits
            : MODAL_HEADING.withNoHits}
        </ModalHeading>

        {hasMVRMonitoringOneOffChecks && this.renderMVRMonitoringWarningText()}

        {shouldRenderRecords && (
          <div>
            <span style={styles.recordsLabel}>Display Charges</span>
            <CriminalRecordsContainer id="hits-container">
              {this.renderRecords(
                criminalRecords,
                "county_criminal",
                "Criminal Records"
              )}
              {this.renderRecords(mvr, "mvr", "Motor Vehicle Records")}
              {this.renderRecords(
                sexOffenderRecords,
                "sex_offender",
                "Sex Offender Records"
              )}
              {this.renderRecords(watchListRecords, "watchlist", "Watchlists")}
              {this.renderRecords(
                nationalRecords,
                "national",
                "National Records"
              )}
              {this.renderRecords(federalRecords, "federal", "Federal Records")}
            </CriminalRecordsContainer>
          </div>
        )}

        {this.renderOtherNonCriminalReasons()}
      </ModalWrapper>
    );
  };
  renderAlert = () => {
    const isPending = this.props.worker.onBoardingStatus === "pending";
    const isJurisdiction = this.props.worker.preAdverseJurisdiction
      ? this.props.worker.preAdverseJurisdiction.value
      : false;
    const jurisdiction = this.props.worker.preAdverseJurisdiction
      ? this.props.worker.preAdverseJurisdiction.jurisdiction
      : "";

    return (
      isJurisdiction &&
      isPending && (
        <div style={styles.floatingAlert}>
          Action Needed Regarding {jurisdiction} Law
        </div>
      )
    );
  };
  isSubmitDisabled = () => {
    if (this.state.selectedRecords.length >= 1 || this.state.note.length >= 10)
      return false;
    return true;
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        primaryButtonProps={{
          title: "Submit",
          onClick: this.rejectWorker,
          disabled: this.isSubmitDisabled()
        }}
        secondaryButtonProps={{
          title: "Cancel",
          onClick: this.props.handleClose
        }}
        containerStyle={{
          overflow: "visible"
        }}
        titleStyle={{ margin: "0" }}
      >
        {this.recordsContainer()}
      </Modal>
    );
  }
}

RejectModal.propTypes = {
  worker: PropTypes.object,
  auth: PropTypes.object,
  router: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default RejectModal;
