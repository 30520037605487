import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SecondaryListItem from "./secondary-item";

class ListSubSections extends PureComponent {
  shouldRender = () =>
    !!(
      this.props.items &&
      Array.isArray(this.props.items) &&
      this.props.items.length > 0
    );
  render() {
    if (!this.shouldRender()) return null;
    return (
      <div>
        {this.props.items.map((item, index) => (
          <SecondaryListItem
            key={index}
            data={item.data}
            simple={this.props.simple}
            exclude={this.props.exclude}
            title={item.title}
            displayTable={this.props.displayTable}
            deep={this.props.deep}
            expand={this.props.expand}
          />
        ))}
      </div>
    );
  }
}

ListSubSections.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      title: PropTypes.string
    })
  ),
  displayTable: PropTypes.bool,
  simple: PropTypes.bool,
  exclude: PropTypes.array,
  deep: PropTypes.bool,
  expand: PropTypes.bool
};

export default ListSubSections;
