export const backgroundReportPDFStyles = {
  loader: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100vh",
    background: "#fff"
  }
};
