import React from "react";
import PropTypes from "prop-types";
import { TableStyles as styles } from "../../styles";

const TableData = ({
  children,
  id = null,
  wrapperStyle = styles.data.wrapper,
  containerStyle = styles.data.container,
  contentStyle = styles.data.content,
  ...rest
}) => {
  return (
    <td style={wrapperStyle} {...rest}>
      <div style={containerStyle}>
        <span id={id} style={contentStyle}>
          {children}
        </span>
      </div>
    </td>
  );
};

TableData.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  wrapperStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  contentStyle: PropTypes.object
};

export default TableData;
