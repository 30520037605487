import styled from "styled-components";

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${props => (props.justifyCenter ? "center" : "start")};
  align-items: ${props => (props.itemsCenter ? "center" : "start")};
  position: ${props => (props.relative ? "relative" : "static")};
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};
  border-left: ${props => props.borderLeft || "none"};
`;

export default FlexRow;
