import styled, { css } from "styled-components";
import { smokeWhite } from "../../styles/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SidebarContainer = styled.div`
  background: white;
  z-index: 100; // need to fix other components z-index
  transition: all 0.4s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  height: auto;
  border-right: 1px solid #e8edf2;
  padding: 0 8px;
  overflow-x: auto;

  pointer-events: ${props => (props.disableNavigation ? "none" : "auto")};

  a {
    opacity: ${props => (props.disableNavigation ? "0.2" : "1")};
  }

  @media screen and (min-width: 960px) {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 80px;

    &:hover {
      width: 192px;
    }
  }

  @media print {
    display: none;
  }
`;

const fadeInAnimation = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  animation: fadeIn 1s;
`;

const showOnHover = css`
  ${fadeInAnimation}
  @media screen and (min-width: 960px) {
    display: none;

    ${SidebarContainer}:hover & {
      display: block;
    }
  }
`;

const logoutStyles = css`
  @media screen and (min-width: 960px) {
    cursor: pointer;
  }
`;

const getTabLinkContainerStyles = props => {
  if (props.logout || props.support) {
    return logoutStyles;
  }
};

const tabContainerselectedStyles = css`
  background-color: #7364db !important;
`;

const collapsibleStyles = css`
  width: fit-content;
`;

const withSubTabsStyles = css`
  margin-bottom: 0;
`;

const subTabsOpenStyles = css`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const selectedStyles = css`
  color: white !important;
  stroke: #fff;
`;

const getTabContainerSelectedStyles = props => {
  if (props.selected) {
    return tabContainerselectedStyles;
  }
};

const getCollapsibleStyles = props => {
  if (props.collapsible) {
    return collapsibleStyles;
  }
};

const getWithSubTabsStyles = props => {
  if (props.withSubTabs) {
    return withSubTabsStyles;
  }
};

const getSubTabsOpenStyles = props => {
  if (props.subTabsOpen) {
    return `${subTabsOpenStyles} ${selectedStyles} ${tabContainerselectedStyles}`;
  }
};

export const TabLinkContainer = styled.div`
  display: flex;
  flex-grow: 1
  position: relative;
  height: 2.9rem;
  width: 100%;
  min-height: 3rem;
  margin: 12px 0;
  font-size: 14px;
  border-radius: 10px;
  
  transition: all 0.4s ease;
  cursor: pointer;

  ${getSubTabsOpenStyles}
  ${getTabLinkContainerStyles}
  ${getTabContainerSelectedStyles}
  ${getWithSubTabsStyles}

  &:hover {
    background-color: #7364DB;
  }
`;

const changeOnTabLinkContainerHover = css`
  ${TabLinkContainer}:hover & {
    color: white !important;
    background-color: none;
    stroke: #fff;
  }
`;

export const TabLink = styled.a`
  width: 100%;
  text-decoration: none;
  z-index: 1;
  cursor: pointer;
  padding-left: 19px;

  ${getCollapsibleStyles}
`;

const getSelectedStyles = props => {
  if (props.selected) {
    return selectedStyles;
  }
};

const subTabSelectedStyles = css`
  color: #7364db;

  svg {
    color: #7364db;
  }
`;

const getSubTabSelectedStyles = props => {
  if (props.selected) {
    return subTabSelectedStyles;
  }
};

export const SourcingTabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 200;
  padding: 14px;
  color: #999999;

  ${getSubTabSelectedStyles}

  svg {
    color: #999999;
    transition: all 0.4s ease;
    ${changeOnTabLinkContainerHover}
    ${getSubTabSelectedStyles}
  }

  & > *:last-child {
    margin-left: 12px;
  }

  &:hover {
    color: #7364db;

    svg {
      color: #7364db;
    }
  }

  @media screen and (max-width: 960px) {
    display: block;
    margin-left: 75px;

    & > i {
      flex: 0 !important;
      float: none;
    }
  }
`;

export const TabLinkListItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 200;
  font-family: "Inter";
  font-size: 16px;
  width: 100%;
  height: 100%;

  svg {
    stroke: #7E7E8F;
    width: 22px;
    height: 22px;
    transition: all 0.4s ease;
    ${changeOnTabLinkContainerHover}
    ${getSelectedStyles}
    ${getSubTabsOpenStyles}
  }
`;

export const TabLinkIcon = styled.i`
  font-size: 21px;
  color: ${smokeWhite};
  ${getSelectedStyles}
`;

export const TabLinkText = styled.span`
  ${showOnHover}
  ${changeOnTabLinkContainerHover}
  color: #7E7E8F;
  transition: all 0.4s ease;
  font-weight: 100;
  margin-left: 8px;
  font-size: 14px;

  ${getSelectedStyles}
  ${getSubTabsOpenStyles}
`;

export const SubTabLinkContainer = styled.div`
  ${showOnHover}
  background-color: #F4F4FC;
  border-radius: 0 0 8px 8px;
`;

// using min-width to avoid line break when width transitions
export const SubTabLinkText = styled.span`
  min-width: ${({ minWidth }) => minWidth || "70px"};
  margin-left: 10px;
  margin-bottom: 3px;
`;

const drawerOpenStyles = css`
  transform: rotate(-180deg);
`;

const getOpen = props => {
  if (!props.open) {
    return drawerOpenStyles;
  }
};

export const CircleIcon = styled(FontAwesomeIcon)`
  font-size: 5px;
  margin-left: 15px;
`;

export const ChevronIcon = styled(FontAwesomeIcon)`
  ${showOnHover}
  width: fit;
  font-size: 12px;
  color: #7E7E8F;
  transition: all 300ms ease-out;
  cursor: pointer;

  ${getSelectedStyles}
  ${changeOnTabLinkContainerHover}
  ${getSubTabsOpenStyles}
  ${getOpen}
`;

export const LogoContainer = styled.a`
  color: #2d3047;
  overflow: hidden;
  width: 32px;
  margin-left: -5px;
  ${SidebarContainer}:hover & {
    width: unset;
  }

  svg {
    display: block;
    width: 95px;
    height: 40px;
  }
`;

export const MenuList = styled.div`
  width: 100%;
  display: block;
`;

export const ActionsContainer = styled.div`
  padding-top: 48px;
`;

export const ActionsLine = styled.div`
  width: 85%;
  height: 1px;
  margin: 0 auto 32px auto;

  background-color: #d9d9d966;
`;

export const ActionIconWrapper = styled.div`
  padding-left: 19px;
`;
