import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react/custom";
import Button from "@material-ui/core/Button";

const stylesEnabled = {
  minWidth: "36px",
  height: "36px",
  borderRadius: "4px",
  backgroundColor: "transparent",
  boxShadow: "none"
};

const stylesDisabled = {
  minWidth: "36px",
  height: "36px",
  borderRadius: "4px",
  backgroundColor: "transparent",
  boxShadow: "none"
};

function GoToPageButton({ disabled, onClick, children }) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      color="primary"
      style={!disabled ? stylesEnabled : stylesDisabled}
    >
      {children}
    </Button>
  );
}

GoToPageButton.propTypes = {
  shouldPrev: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node
};

export default observer(GoToPageButton);
