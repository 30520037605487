/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/href-no-hash */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { CandidateReportStyles as styles } from "../../styles";

import SectionTitle from "./SectionTitle";
import SSN from "./SSN";
import CriminalRecord from "./CriminalRecord";
import FederalRecord from "./FederalRecord";
import MVR from "./MVR";
import SexOffender from "./SexOffender";
import { Watchlist } from "./Watchlist";
import OneOffChecks from "./OneOffChecks";
import messages from "./messages";
import {
  faClipboardListCheck,
  faCheckCircle
} from "@fortawesome/pro-solid-svg-icons";
import AddressesFullActiveList from "./AddressesFullList";
import DrugTestingResults from "./DrugTestingResults";
import _ from "lodash";

const propTypes = {
  model: PropTypes.object,
  isPDFReport: PropTypes.bool
};

const ReportChecks = observer(
  class ReportChecks extends Component {
    constructor(props) {
      super(props);
      this.model = props.model;
    }

    renderSSN = () => (
      <SSN model={this.model} isPDFReport={this.props.isPDFReport} />
    );

    renderAddresses = () => (
      <AddressesFullActiveList
        model={this.model}
        isPDFReport={this.props.isPDFReport}
        checkIcon={faCheckCircle}
      />
    );
    renderSexOffender = () => (
      <SexOffender
        offenses={this.model.sexOffender}
        reportDate={this.model.reportDate}
        pendingMVRReview={this.model.stateIsPendingMVRReview}
        pendingAgeReview={this.model.stateIsPendingAgeReview}
        isPDFReport={this.props.isPDFReport}
        icon={this.model.sexOffenderIcon}
        color={this.model.sexOffenderColor}
        status={this.model.sexOffenderStatus}
      />
    );
    renderCriminal = () => (
      <CriminalRecord
        criminal={this.model.criminal}
        reportDate={this.model.reportDate}
        pendingMVRReview={this.model.stateIsPendingMVRReview}
        pendingAgeReview={this.model.stateIsPendingAgeReview}
        isPDFReport={this.props.isPDFReport}
        icon={this.model.criminalIcon}
        color={this.model.criminalColor}
        status={this.model.criminalStatus}
      />
    );
    renderFederal = () => (
      <FederalRecord
        federal={this.model.federal}
        reportDate={this.model.reportDate}
        pendingMVRReview={this.model.stateIsPendingMVRReview}
        pendingAgeReview={this.model.stateIsPendingAgeReview}
        isPDFReport={this.props.isPDFReport}
        icon={this.model.federalIcon}
        color={this.model.federalColor}
        status={this.model.federalStatus}
      />
    );
    renderMVR = () => (
      <MVR
        mvr={this.model.mvr}
        reportDate={this.model.reportDate}
        isPDFReport={this.props.isPDFReport}
        icon={this.model.mvrIcon}
        color={this.model.mvrColor}
        status={this.model.mvrStatus}
      />
    );
    renderWatchlist = () => (
      <Watchlist
        watchlist={this.model.watchlist}
        reportDate={this.model.reportDate}
        pendingMVRReview={this.model.stateIsPendingMVRReview}
        pendingAgeReview={this.model.stateIsPendingAgeReview}
        isPDFReport={this.props.isPDFReport}
        icon={this.model.watchlistIcon}
        color={this.model.watchlistColor}
        status={this.model.watchlistStatus}
      />
    );

    renderOneOffChecks = () => (
      <OneOffChecks
        oneOffChecks={this.model.oneOffChecks}
        reportDate={this.model.reportDate}
        pendingMVRReview={this.model.stateIsPendingMVRReview}
        pendingAgeReview={this.model.stateIsPendingAgeReview}
        isPDFReport={this.props.isPDFReport}
        icon={this.model.oneOffChecksIcon}
        color={this.model.oneOffChecksColor}
        status={this.model.oneOffChecksStatus}
      />
    );

    renderDrugTestingResults = () => (
      <DrugTestingResults
        color={this.model.drugTestingResultsColor}
        drugTest={this.model.drugTestingResults}
        icon={this.model.drugTestingResultsIcon}
        isPDFReport={this.props.isPDFReport}
        reportDate={this.model.reportDate}
        status={this.model.drugTestingResultsStatus}
      />
    );

    render() {
      return (
        <div>
          <SectionTitle
            title={<FormattedMessage {...messages.reportDetail} />}
            icon={faClipboardListCheck}
          />
          <div style={styles.checks.container}>
            {this.renderSSN()}
            {this.renderAddresses()}
            {this.renderSexOffender()}
            {this.renderCriminal()}
            {this.model.doDrugTesting && this.renderDrugTestingResults()}
            {this.renderFederal()}
            {!_.isEmpty(this.model.mvr) && this.renderMVR()}
            {this.renderWatchlist()}
            {this.renderOneOffChecks()}
          </div>
        </div>
      );
    }
  }
);

ReportChecks.propTypes = propTypes;

export default ReportChecks;
