import {
  HOME_PAGE,
  WORKERS_PAGE,
  WORKER_PAGE,
  READY_PAGE,
  REPORT_PAGE,
  SETTINGS_PAGE
} from "../constants";

export const getPageFromRoute = (route = "") => {
  const homeLinks = ["/home", "/demo", "/upgrade"];
  if (homeLinks.includes(route)) return HOME_PAGE;
  else if (route === "/") return WORKERS_PAGE;
  else if (route === "/report") return REPORT_PAGE;
  else if (route.includes("/workers")) return WORKER_PAGE;
  else if (route.includes("/settings")) return SETTINGS_PAGE;
  else if (route.includes("/ready") || route.includes("/recruiter"))
    return READY_PAGE;
};
