import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { ModalStyles } from "../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faRadar
} from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as CloseButton } from "../../icons/close.svg";
import withContext from "../../contextAPI/context-HOC";
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_WIDTH } from "../../constants";
import * as colors from "../../styles/constants";
import "./index.css";
import {
  Children,
  Content,
  Heading,
  ModalContainer,
  panelStyles,
  PrimaryButton,
  SecondaryButton
} from "./styled";

const styles = ModalStyles.new;

const getIcon = (key, customColor) => {
  let icon;
  let color;

  switch (key) {
    case "error":
      icon = faExclamationCircle;
      color = colors.red;
      break;
    case "warn":
      icon = faExclamationCircle;
      color = colors.yellowOrange;
      break;
    case "createNew":
      icon = faExclamationCircle;
      color = colors.altGreen;
      break;
    case "created":
      icon = faCheckCircle;
      color = colors.emerald;
      break;
    case "radar":
      icon = faRadar;
      color = colors.secondary;
      break;
    default:
  }

  return (
    <FontAwesomeIcon
      icon={icon}
      color={customColor || color}
      className="icon"
    />
  );
};

class Modal extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    titleStyle: PropTypes.object,
    subtitle: PropTypes.string,
    containerStyle: PropTypes.object,
    panelStyle: PropTypes.object,
    headingStyle: PropTypes.object,
    contentStyle: PropTypes.object,
    buttonContainerStyle: PropTypes.object,
    closeContainerStyle: PropTypes.object,
    flexPaper: PropTypes.bool,

    primaryButtonProps: PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      style: PropTypes.object
    }),
    secondaryButtonProps: PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      style: PropTypes.object
    }),
    centerButtons: PropTypes.bool,
    onClose: PropTypes.func,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    iconColor: PropTypes.string
  };

  static defaultProps = {
    containerStyle: {},
    contentStyle: {},
    buttonContainerStyle: {},
    centerButtons: false,
    titleStyle: {}
  };

  renderImage = () => {
    if (!this.props.img) return null;

    return (
      <div style={styles.imageContainer}>
        <div style={styles.image}>{this.props.img}</div>
      </div>
    );
  };

  renderCloseButton = () => {
    if (!this.props.onClose) return null;
    let style = styles.closeContainer;
    if (this.props.closeContainerStyle) {
      style = {
        ...style,
        ...this.props.closeContainerStyle
      };
    }
    return (
      <div style={style} onClick={this.props.onClose}>
        <CloseButton />
      </div>
    );
  };

  renderTitle = () => {
    let icon = this.props.icon;
    if (typeof icon === "string") {
      icon = getIcon(icon, this.props.iconColor);
    }

    const titleContent = icon ? (
      <div className="grid-title">
        <div className="icon-container">{icon}</div>
        <div className="title-container">{this.props.title}</div>
      </div>
    ) : (
      this.props.title
    );

    return (
      <div
        style={{
          ...styles.title,
          marginTop: !this.props.img && "20px",
          ...this.props.titleStyle
        }}
      >
        {titleContent}
      </div>
    );
  };

  getPaperStyle = buttonsVisible => {
    const { containerStyle, context, flexPaper } = this.props;

    let marginLeft;
    if (context.mdBreak) {
      marginLeft = "";
    } else if (context.sidebarCollapsed) {
      marginLeft = `${parseInt(SIDEBAR_COLLAPSED_WIDTH) + 48}px`;
    } else {
      marginLeft = `${parseInt(SIDEBAR_WIDTH) + 48}px`;
    }

    return {
      ...styles.container,
      display: flexPaper ? "flex" : "grid",
      gridTemplateRows: buttonsVisible ? "60px auto 50px" : "60px auto",
      marginLeft,
      ...containerStyle
    };
  };

  render() {
    const { contentStyle, buttonContainerStyle } = this.props;
    const buttonsVisible =
      this.props.secondaryButtonProps || this.props.primaryButtonProps;

    return (
      <Transition appear show={this.props.open} as={Fragment}>
        <Dialog
          as="div"
          onClose={() => this.props.onClose}
          style={{
            position: "relative",
            zIndex: 1000,
            width: "100%",
            height: "100%"
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              style={{
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "rgb(0 0 0 / 0.25)"
              }}
            />
          </Transition.Child>

          <div
            style={{
              position: "fixed",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              overflowY: "auto"
            }}
          >
            <ModalContainer>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  style={{ ...panelStyles, ...this.props.panelStyle }}
                >
                  <Content>
                    <Heading
                      style={{
                        ...styles.textContainer,
                        ...this.props.headingStyle
                      }}
                    >
                      {this.renderImage()}
                      {this.renderTitle()}
                      {this.renderCloseButton()}
                    </Heading>
                    <Children
                      style={{ ...styles.contentContainer, ...contentStyle }}
                    >
                      {this.props.children}
                    </Children>
                    {buttonsVisible ? (
                      <div
                        style={
                          _.isObject(this.props.secondaryButtonProps) &&
                          !this.props.centerButtons
                            ? {
                                ...styles.buttonContainerEnd,
                                ...buttonContainerStyle
                              }
                            : {
                                ...styles.buttonContainerCentered,
                                ...buttonContainerStyle
                              }
                        }
                      >
                        {_.isObject(this.props.secondaryButtonProps) && (
                          <SecondaryButton
                            autoFocus={false}
                            id={this.props.secondaryButtonProps.title}
                            {...this.props.secondaryButtonProps}
                          >
                            {this.props.secondaryButtonProps.title}
                          </SecondaryButton>
                        )}
                        {_.isObject(this.props.primaryButtonProps) && (
                          <PrimaryButton
                            autoFocus={false}
                            id={this.props.primaryButtonProps.title.toLowerCase()}
                            {...this.props.primaryButtonProps}
                          >
                            {this.props.primaryButtonProps.title}
                          </PrimaryButton>
                        )}
                      </div>
                    ) : null}
                  </Content>
                </Dialog.Panel>
              </Transition.Child>
            </ModalContainer>
          </div>
        </Dialog>
      </Transition>
    );
  }
}

export default withContext(Modal);
