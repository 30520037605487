import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 40px;
  padding-left: 26px;
`;

export const TestType = styled.p`
  margin: 0;
  font-weight: 500;
`;
