import React from "react";
import { PropTypes } from "prop-types";
import { isEmpty, isArray } from "lodash";
import Header from "./Header";
import Record from "./Record";
import { parseLicense } from "../../../models/mvr";

export const exclude = ["license_number", "type"];
const MVRLicenses = ({ data, expand }) => {
  // invalidLicenses
  if (!isArray(data) || isEmpty(data)) return null;
  const parsed = data.map(parseLicense);
  const count = parsed.length;

  return (
    <div>
      <Header title="Licenses" count={count} success />
      {parsed.map((license, key) => (
        <Record
          title={license.license_number || "N/A"}
          subtitle={license.type}
          data={license}
          key={key}
          expand={expand}
          exclude={exclude}
        />
      ))}
    </div>
  );
};
MVRLicenses.propTypes = {
  data: PropTypes.array.isRequired,
  // invalidLicenses: PropTypes.array.isRequired,
  expand: PropTypes.bool.isRequired
};
export default MVRLicenses;
