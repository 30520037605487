import styled from "styled-components";
import PropTypes from "prop-types";

import { ReactComponent as TurnLogo } from "../../icons/logo_turn_teal.svg";
import * as colors from "../../styles/constants";

const Logo = styled(TurnLogo)`
  fill: ${({ fill }) => fill || colors.secondary};
  height: ${({ height }) => height || ""};
  margin-left: 4px;
  width: ${({ width }) => width || "100px"};

  @media (max-width: 1024px) {
    margin-left: 4%;
  }
  @media (max-width: 720px) {
    margin: 0;
  }
`;

Logo.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string
};

export default Logo;
