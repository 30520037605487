import React from "react";
import { observer } from "mobx-react";
import { detailDisplayStyles as styles } from "./styles";

export const DetailDisplay = observer(
  ({ icon, secondIcon, detail, containerStyle = {} }) => (
    <div style={{ ...styles.container, ...containerStyle }}>
      {icon && icon}
      {secondIcon && secondIcon}
      {detail && detail}
    </div>
  )
);
