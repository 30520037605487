import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.containers.Permission.title",
    defaultMessage: "Permissible Use Certification"
  },
  titleGLBA: {
    id: "app.containers.Permission.titleGLBA",
    defaultMessage: "GLBA PERMISSIBLE USES"
  },
  titleDPPA: {
    id: "app.containers.Permission.titleDPPA",
    defaultMessage: "DPPA PERMISSIBLE USES"
  }
});
