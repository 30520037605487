import {
  READY_OPENINGS,
  WORKERS_SUBTAB,
  SETTINGS_SUBTAB,
  WORKER_PAGE,
  READY_LOCATIONS,
  READY_APPLICANTS,
  READY_POSITIONS
} from "../constants";

export const getSubtabFromRoute = (route = "") => {
  if (
    route.includes("/recruiter/openings") ||
    route.includes("/ready/openings")
  )
    return READY_OPENINGS;
  else if (route.includes("/ready/locations")) return READY_LOCATIONS;
  else if (route.includes("/ready/positions")) return READY_POSITIONS;
  else if (route.includes("/recruiter/applicants")) return READY_APPLICANTS;
  else if (route === "/") return WORKERS_SUBTAB;
  else if (route === "/settings") return SETTINGS_SUBTAB;
  else if (route.includes("/workers/")) return WORKER_PAGE;
};
