import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerPlus, faLayerMinus } from "@fortawesome/pro-solid-svg-icons";
import { primary } from "../../styles/constants";

const styles = {
  container: {
    display: "inline-block",
    margin: "0 5% 0 30px",
    textDecoration: "none",
    cursor: "pointer",
    position: "absolute",
    top: 17,
    right: 0,
    color: primary,
    fontWeight: "bold",
    fontSize: "14px"
  },
  icon: {
    marginRight: "10px"
  }
};

const ExpandAll = ({ style = {}, onClick, toggled }) => {
  return (
    <a style={{ ...styles.container, ...style }} onClick={onClick}>
      <FontAwesomeIcon
        icon={!toggled ? faLayerPlus : faLayerMinus}
        style={styles.icon}
      />
      {!toggled ? "Expand All" : "Close All"}
    </a>
  );
};

ExpandAll.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired
};

ExpandAll.defaultProps = {
  style: {}
};

export default ExpandAll;
