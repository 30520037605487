import styled from "styled-components";

export const CriminalRecordsContainer = styled.div`
  height: 200px;
  padding: 1em;

  background: #fafafa;
  border-radius: 10px;

  overflow: auto;
`;

export const ModalHeading = styled.p`
  margin: 0;

  font-size: 16px;
  text-align: center;
  font-weight: 600;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;

  text-align: left;
`;

export const MVRMonitoringWarningContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;
  padding: 14px 20px;

  background-color: #f1f1fb;
  border-radius: 10px;
`;

export const NoteField = styled.textarea`
  width: 100%;
  padding: 30px 20px;

  background-color: #fafafa;
  border: 2px solid #fafafa;
  border-radius: 10px;

  resize: none;

  transition: all 300ms ease-in-out;

  &::placeholder {
    opacity: 0;
  }

  &:focus-visible {
    outline: none;
  }

  &:focus {
    outline: none;
    border: 2px solid #7364db;

    &::placeholder {
      opacity: 1;
      transition-delay: 250ms;
    }
  }
`;

export const NoteFieldLabel = styled.label`
  position: absolute;

  transition: all 300ms ease-in-out;

  ${props =>
    props.isFocused || props.isNotEmpty
      ? `
        top: 10px;
        left: 12px;
        font-size: 10px;
      `
      : `
        top: 30px;
        left: 22px;
        font-size: 14px;
      `};
`;
