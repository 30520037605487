import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import DateContainer from "./container";
import { useDebounce } from "../../utils/useDebounce";
import moment from "moment";
import { FormattedMessage } from "react-intl";

const DatePickerCSV = ({ value, onChange, ...props }) => {
  const [innerValue, setInnerValue] = useState(value || "");
  const debouncedInnerValue = useDebounce(innerValue, 200);

  useEffect(() => {
    // Keep internal controlled value in sync
    if (moment(value).toDate() != innerValue) {
      setInnerValue(value);
    }
  }, [value]);

  useEffect(() => {
    // Call onChange with new value (if callback exists)
    onChange && onChange(debouncedInnerValue);
  }, [debouncedInnerValue]);

  return (
    <DateContainer>
      <div className="form-date">
        <label className="form-date__label" htmlFor="input-date">
          <FormattedMessage {...props.label} />
        </label>
        <div className="date-border">
          <input
            {...props.inputProps}
            className="form-date__input"
            type="date"
            onChange={({ target }) => setInnerValue(target.value)}
            value={innerValue}
          />
        </div>
      </div>
    </DateContainer>
  );
};

DatePickerCSV.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};
export default DatePickerCSV;
