import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import _ from "lodash";
import { rejectedCandidateStyles as styles } from "./styles";
import { Input } from "@material-ui/core";
import { turnAPI } from "../../../services";
import { Button } from "../../../components/Button";

const AddNote = observer(
  class AddNote extends Component {
    static propTypes = {
      worker: PropTypes.object,
      getNotes: PropTypes.func
    };
    constructor(props) {
      super(props);
      this.state = {
        note: "",
        error: false,
        expanded: false
      };
    }
    setNote = event => {
      const note = event.target.value;
      if (!_.isString(note)) return;
      this.setState({ note });
    };
    saveNote = () => {
      const { note } = this.state;
      if (_.isEmpty(note)) this.setState({ error: true });
      else this.handleSave();
    };
    handleSave = async () => {
      const { note } = this.state;
      const response = await turnAPI.saveNote(this.props.worker.turnId, {
        note
      });
      if (!response) return;
      switch (response.status) {
        case 200:
          this.props.getNotes();
          this.setState({ note: "" });
          this.toggleExpand();
          break;
        default:
          break;
      }
    };
    disableSubmit = () => _.isEmpty(this.state.note);
    toggleExpand = () => this.setState({ expanded: !this.state.expanded });

    render() {
      const { notesStyles } = styles;
      const { error, expanded } = this.state;

      return (
        <div>
          {expanded ? (
            <>
              <span style={notesStyles.addNote}>Add note</span>
              <Input
                fullWidth
                placeholder="e.g. Candidate updated by Hiring Team"
                onChange={this.setNote}
                value={this.state.note}
                required
                error={error}
                style={notesStyles.input}
              />
              <div style={notesStyles.buttonContainer}>
                <Button
                  color="secondary"
                  variant="contained"
                  style={notesStyles.button}
                  onClick={this.saveNote}
                  disabled={this.disableSubmit()}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <div style={notesStyles.buttonContainer}>
              <Button
                color="secondary"
                variant="contained"
                onClick={this.toggleExpand}
                style={notesStyles.button}
                disabled={this.props.worker.readOnly}
              >
                + Add Note
              </Button>
            </div>
          )}
        </div>
      );
    }
  }
);

export default AddNote;
