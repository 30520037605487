import { primary, white, disabledText } from "../constants";

const styles = {
  select: {
    backgroundColor: white,
    width: "calc(30% - 12px)",
    verticalAlign: "middle",
    textTransform: "capitalize"
  },
  container: {
    // marginBottom: 30
  },
  slash: {
    color: primary,
    fontWeight: "bold",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "0 5px",
    width: 7,
    overflow: "visible"
  },
  disabledSlash: {
    color: disabledText,
    fontWeight: "bold",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "0 5px",
    width: 7,
    overflow: "visible"
  }
};

export default styles;
