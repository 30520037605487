export default {
  container: {
    alignItems: "center",
    display: "flex",
    color: "#8E8E8E",
    fontSize: "16px",
    backgroundColor: "white"
  },
  titleInactive: {
    appearance: "none",
    fontSize: "16px",
    textDecoration: "none",
    color: "black",
    fontWeight: 600
  },
  title: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 600,
    fontSize: "16px"
  },
  icon: {
    margin: "0 8px 0 12px",
    fontSize: "0.7em",
    cursor: "not-allowed"
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 400
  }
};
