import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import NotFoundBackground from "./background";
import Notice from "./notice";
import NotFoundLink from "./link";

import messages from "./messages";

class ErrorPage extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <NotFoundBackground>
        <Notice>
          {this.props.message ? (
            this.props.message
          ) : (
            <FormattedMessage {...messages.default} />
          )}
          <div style={{ marginTop: "32px" }} />
          {this.props.noLink ? null : (
            <NotFoundLink href="/">Return to Homepage</NotFoundLink>
          )}
        </Notice>
      </NotFoundBackground>
    );
  }
}

ErrorPage.propTypes = {
  noLink: PropTypes.bool,
  message: PropTypes.node
};

export default ErrorPage;
