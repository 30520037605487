import styled from "styled-components";
import * as colors from "../../styles/constants";

// Adds custom styles to TextField from @material-ui/core/TextField
const TextArea = styled.textarea`
  background: ${({ disabled, background }) =>
    disabled
      ? colors.disabledBackground
      : background
      ? background
      : colors.smokeWhite};
  border-color: ${({ border, disabled }) =>
    disabled ? "" : border ? border : colors.aliceBlue};
  color: ${({ disabled, color }) =>
    disabled ? colors.disabledText : color ? color : colors.primary};
  width: ${({ width }) => (width ? width : "100%")};
  resize: ${({ resize }) => (resize ? resize : "none")};
  border-radius: ${({ border }) => (border ? border : "10px")};
  font-family: ${({ family }) => (family ? family : "Inter")};
  font-size: ${({ size }) => (size ? size : "11px")};
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  line-height: 1.56;
  padding: ${({ padding }) => (padding ? padding : "12px")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "")};

  ::placeholder {
    color: ${colors.disabledText};
  }
`;

export default TextArea;
