import styled from "styled-components";
import { primary } from "../../styles/constants";

const Text = styled.span`
  color: ${({ color }) => color || primary};
  font-size: ${({ size }) => size || "14px"};
  font-style: ${({ fontStyle }) => fontStyle || ""};
  font-weight: ${({ weight }) => weight || "normal"};
  margin: ${({ margin }) => margin || "0 0"};
  display: ${({ display }) => display || "inline-block"};
  font-family: ${({ family }) => family || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || ""};
  text-align: ${({ align }) => align || ""};
  cursor: ${({ cursor }) => cursor || ""};
  text-decoration: ${({ decoration }) => decoration || ""};
  height: ${({ height }) => height || ""};
  width: ${({ width }) => width || ""};
  padding: ${({ padding }) => padding || ""};
`;

export default Text;
