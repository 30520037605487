import React from "react";
import PropTypes from "prop-types";
import DetailContainer from "../../../components/DetailItem/detail-container";
import DetailResponsiveLabel from "../../../components/DetailItem/detail-responsive-label";
import Highlighted from "../../../components/Highlighted";

const RecordEta = props => {
  const { label, detail } = props;
  if (!detail || typeof detail != "string" || detail.length === 0) {
    return null;
  }

  return (
    <DetailContainer
      padding="0 1rem"
      width=" "
      widthMD=" "
      displayMD="inline-block"
      displaySM="block"
    >
      <DetailResponsiveLabel>
        <Highlighted light="light" size="12px" disabled>
          {label}
        </Highlighted>
      </DetailResponsiveLabel>
      <Highlighted light="light" size="12px" disabled>
        {detail}
      </Highlighted>
    </DetailContainer>
  );
};
RecordEta.propTypes = {
  label: PropTypes.string,
  detail: PropTypes.string
};

export default RecordEta;
