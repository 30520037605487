/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { includes, get } from "lodash";
import { turnAPI } from "../services";
import auth from "./service";
import { isRouteDisabledWithError } from "../models/is-route-disabled-helper";
import ErrorPage from "../containers/ErrorPage";
import { primary, white } from "../../src/styles/constants";
import { PRE_OAUTH_PARAM_PREFIX } from "../constants";
import teamMember from "../models/team-member";

const saveParamsToLocalStorage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams || typeof urlParams !== "object") return;
  urlParams.forEach((value, key) => {
    localStorage.setItem(PRE_OAUTH_PARAM_PREFIX + key, value);
  });
};

export const jobCreationEcomFlowLSKey = "job_creation_ecom_flow";

const ProtectedRoute = ({ component: Component, history, ...rest }) => {
  const [isLogged, setIsLogged] = useState(true);

  useEffect(() => {
    auth.loggedIn().then(logged => setIsLogged(logged));
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        saveParamsToLocalStorage();
        if (process.env.NODE_ENV === "test")
          return <Component auth={auth} {...props} />;
        if (isRouteDisabledWithError(history.location.pathname))
          // for ready only partners redirect to home instead of showing error
          return teamMember.isAuthorizedForScreen ? (
            <ErrorPage />
          ) : (
            <Redirect to="/recruiter/openings" />
          );
        if (typeof FreshWidget !== "undefined")
          FreshWidget.init("", {
            queryString: "&widgetType=popup",
            utf8: "✓",
            widgetType: "popup",
            buttonType: "text",
            buttonText: "Support",
            buttonColor: { white },
            buttonBg: primary,
            alignment: "2",
            offset: "235px",
            formHeight: "500px",
            url: "https://turnhq.freshdesk.com"
          });
        if (includes(history.location.search, "ecom_flow")) {
          const lastPath = get(history.location.search.split("="), "[1]");
          localStorage.setItem("last_path", lastPath);
          if (includes(lastPath, "/ready/openings/new")) {
            localStorage.setItem(jobCreationEcomFlowLSKey, "true");
          }
        } else {
          localStorage.setItem("last_path", history.location.pathname);
        }
        if (!isLogged) return <Redirect to="/login" />;
        turnAPI.setToken(auth.getToken());
        return <Component auth={auth} {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
