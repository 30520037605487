import React from "react";
// import { blue500, red500, greenA200 } from 'material-ui/styles/colors';
import SvgIcon from "material-ui/SvgIcon";

const ClockIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M9.8390347,0 C4.41456127,0 0,4.41317783 0,9.83765126 C0,15.2621247 4.41317783,19.676686 9.8390347,19.676686 C15.2648916,19.676686 19.676686,15.2635081 19.676686,9.83765126 C19.676686,4.41179438 15.2648916,0 9.8390347,0 Z M9.83909664,17.7600887 C5.47090337,17.7600887 1.91665916,14.2058445 1.91665916,9.83765126 C1.91665916,5.46945799 5.47090337,1.91521378 9.83909664,1.91521378 C14.2072899,1.91521378 17.7600268,5.46945799 17.7600268,9.83765126 C17.7600268,14.2058445 14.2072899,17.7600887 9.83909664,17.7600887 Z"
      id="Shape"
    />
    <path
      d="M14.6376832,9.79594667 C15.0518968,9.79594667 15.3876832,10.1317331 15.3876832,10.5459467 C15.3876832,10.9601602 15.0518968,11.2959467 14.6376832,11.2959467 L9.82286057,11.2959467 C9.40864701,11.2959467 9.07286057,10.9601602 9.07286057,10.5459467 L9.07286057,5.8034166 C9.07286057,5.38920303 9.40864701,5.0534166 9.82286057,5.0534166 C10.2370741,5.0534166 10.5728606,5.38920303 10.5728606,5.8034166 L10.5728606,9.79594667 L14.6376832,9.79594667 Z"
      id="Path-19"
    />
  </SvgIcon>
);

export default ClockIcon;
