import React, { useContext, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";

export const SearchHighligtedContext = React.createContext();
export const useSearchHighlighted = () => useContext(SearchHighligtedContext);

/**
 * Provider to easily highlight text according to  a given search string. This should
 * wrap an are where <SearchHighlighted> components are expected to be rendered.
 *
 * @example
 *    // Will highlight "something" and "amazing"
 *    <SearchHighligtedProvider search="something amazing">
 *      <SearchHighlighted textToHighlight={"This is something kind of amazing"}/>
 *    </SearchHighligtedProvider>
 *
 * @param search Search string
 */
export const SearchHighlightedProvider = ({ search, children }) => {
  // Allow for controlled and un-controlled use of this component
  const [searchString, setSearchString] = useState(search);
  useEffect(() => {
    setSearchString(search);
  }, [search]);

  // Break-down the searchString into usable keywords array
  const searchWords = useMemo(
    () => (searchString && searchString.split(/\s/)) || [],
    [searchString]
  );

  return (
    <SearchHighligtedContext.Provider value={{ searchWords, setSearchString }}>
      {children}
    </SearchHighligtedContext.Provider>
  );
};

SearchHighlightedProvider.propTypes = {
  search: PropTypes.string,
  children: PropTypes.node
};

/**
 * Component to render a string highlighted according to the wrapping
 * <SearchHighligtedProvider /> component's search string.
 *
 * @param props.textToHighlight The string to highlight matching keywords
 */
export const SearchHighlighted = ({ textToHighlight, ...props }) => {
  const { searchWords = [] } = useSearchHighlighted() || {};

  // Sanity check
  if (typeof textToHighlight == "string") {
    return (
      <Highlighter
        autoEscape
        searchWords={searchWords}
        textToHighlight={textToHighlight}
        {...props}
      />
    );
  } else {
    return <>{textToHighlight}</>;
  }
};
