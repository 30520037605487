import { string } from "prop-types";
import React from "react";

const SettingsIcon = ({ height, stroke, width }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke || "#000"}
    >
      <path
        d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.83337 11.8067V10.1933C1.83337 9.24 2.61254 8.45166 3.57504 8.45166C5.23421 8.45166 5.91254 7.27833 5.07837 5.83916C4.60171 5.01416 4.88587 3.94166 5.72004 3.465L7.30587 2.5575C8.03004 2.12666 8.96504 2.38333 9.39587 3.1075L9.49671 3.28166C10.3217 4.72083 11.6784 4.72083 12.5125 3.28166L12.6134 3.1075C13.0442 2.38333 13.9792 2.12666 14.7034 2.5575L16.2892 3.465C17.1234 3.94166 17.4075 5.01416 16.9309 5.83916C16.0967 7.27833 16.775 8.45166 18.4342 8.45166C19.3875 8.45166 20.1759 9.23083 20.1759 10.1933V11.8067C20.1759 12.76 19.3967 13.5483 18.4342 13.5483C16.775 13.5483 16.0967 14.7217 16.9309 16.1608C17.4075 16.995 17.1234 18.0583 16.2892 18.535L14.7034 19.4425C13.9792 19.8733 13.0442 19.6167 12.6134 18.8925L12.5125 18.7183C11.6875 17.2792 10.3309 17.2792 9.49671 18.7183L9.39587 18.8925C8.96504 19.6167 8.03004 19.8733 7.30587 19.4425L5.72004 18.535C4.88587 18.0583 4.60171 16.9858 5.07837 16.1608C5.91254 14.7217 5.23421 13.5483 3.57504 13.5483C2.61254 13.5483 1.83337 12.76 1.83337 11.8067Z"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

SettingsIcon.propTypes = {
  height: string,
  stroke: string,
  width: string
};

export default SettingsIcon;
