import React from "react";
import { PropTypes } from "prop-types";
import Divider from "material-ui/Divider";
import DetailContainer from "../../../components/ListDetailView/detail-container";
import Highlighted from "../../../components/Highlighted";
import HeaderIcon from "./HeaderIcon";
import Count from "./count";

const Header = ({ title, count, success, icon }) => (
  <div>
    <Divider />
    <DetailContainer inline>
      <Highlighted size="16px" vmiddle>
        {title}
      </Highlighted>
      <Count count={count} success={success} />
      <HeaderIcon icon={icon} />
    </DetailContainer>
  </div>
);
Header.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  success: PropTypes.bool,
  icon: PropTypes.node
};

export default Header;
