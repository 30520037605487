import React, { PureComponent } from "react";
import { FormattedMessage } from "react-intl";
import Container from "../../components/Container";
import Img from "../../components/Img";
import Title from "../../components/Title";
import { database } from "../../index";

import messages from "./messages";

import { PageType, PageEvent } from "../../services/analytics/gtm";
import { ga } from "../../services";

const logoStyles = { margin: "0 auto", display: "block", width: "200px" };

class Disclosure extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      html: ""
    };
  }
  UNSAFE_componentWillMount() {
    this.get_data();
  }
  async get_data() {
    database
      .ref("report_auth")
      .once("value")
      .then(snap => {
        this.setState({ html: snap.val() });
      });
  }

  componentDidMount() {
    ga.send(PageType.Disclosure, PageEvent.pageLoaded);
  }

  render() {
    const html = this.state.html.split("<p>&nbsp;</p>").join("");
    return html ? (
      <Container large padding="5%">
        <Img type="logo" style={logoStyles} alt="Turn Technologies" />
        <Title big weight={300} margin="1em 0">
          <FormattedMessage {...messages.title} />
        </Title>
        <h3>
          <FormattedMessage {...messages.disclosure_title} />
        </h3>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    ) : null;
  }
}

export default Disclosure;
