import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import messages from "../messages";
import Header from "./Header";
import NotesList from "./NotesList";
import AddNote from "./AddNote";
import Panel from "../../../components/Panel";
import { turnAPI } from "../../../services";
import _ from "lodash";
import teamMember from "../../../models/team-member";

const Notes = observer(
  class Notes extends Component {
    static propTypes = {
      worker: PropTypes.object
    };
    constructor(props) {
      super(props);
      this.state = { expand: this.props.expand || false };
    }

    componentDidMount() {
      if (this.props.worker.turnId && _.isEmpty(this.props.worker.notes))
        this.getNotes();
    }

    getNotes = async () => {
      this.toggleExpand();
      const response = await turnAPI.getNotes(this.props.worker.turnId);
      switch (response.status) {
        case 200:
          await this.props.worker.setNotes(response.data);
          this.toggleExpand();
          break;
        default:
          break;
      }
    };

    toggleExpand = () => this.setState({ expand: !this.state.expand });

    render() {
      const { worker } = this.props;
      const { expand } = this.state;

      return (
        <Panel
          style={{
            filter:
              "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
          }}
          padding="20px 15px"
          margin="0px 0px 25px 0px"
        >
          <Header
            title={<FormattedMessage {...messages.notes} />}
            expand={expand}
            toggleExpand={this.toggleExpand}
          />
          {expand && (
            <>
              <NotesList notes={worker.notes} />
              {teamMember.canAddNote && (
                <AddNote worker={this.props.worker} getNotes={this.getNotes} />
              )}
            </>
          )}
        </Panel>
      );
    }
  }
);

export default Notes;
