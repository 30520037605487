import styled from "styled-components";
import { primary } from "../../../styles/constants";

const ButtonStyles = styled.button`
  color: white;
  background-color: ${primary};
  border: none;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 0.7rem;
  min-width: 60px;
  min-height: 29px;
  margin: 0 0.2rem;

  &:hover {
    background-color: #b7ecec;
    cursor: pointer;
  }
`;

export default ButtonStyles;
