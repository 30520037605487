import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import messages from "../messages";
import { months } from "../../../data/dates";
import Header from "./Header";
import RejectedReasons from "./RejectedReasons";
import PanelCollapsible from "../../../components/PanelCollapsible";
import NotesList from "../notes/NotesList";

const RejectedCandidate = observer(
  class RejectedCandidate extends Component {
    static propTypes = {
      worker: PropTypes.object,
      rejected: PropTypes.object
    };
    constructor(props) {
      super(props);
    }

    getSubtitle = () => {
      const { fullName } = this.props.worker;
      const { rejected_by, rejected_at } = this.props.rejected;

      const date = new Date(rejected_at * 1000);
      const year = date.getFullYear();
      const month = months[date.getMonth() + 1];
      const day = date.getDate();
      const hour = date.getHours();
      const minutes = date.getMinutes();
      return `${fullName} was rejected by ${rejected_by} ${month} ${day} ${year}, at ${hour}:${minutes}`;
    };

    render() {
      const { isRejected } = this.props.worker;
      const { rejection_reasons, notes } = this.props.rejected;

      return isRejected ? (
        <PanelCollapsible
          titleStyle={{ margin: 0 }}
          titleComponent={
            <Header
              titleId="rejectedCandidateTitle"
              title={<FormattedMessage {...messages.rejected} />}
              subtitle={this.getSubtitle()}
              subTitleId="rejectedCandidateSubTitle"
            />
          }
        >
          <RejectedReasons reasons={rejection_reasons} />
          <NotesList notes={notes} />
        </PanelCollapsible>
      ) : null;
    }
  }
);

export default RejectedCandidate;
