import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import { WorkerStyles as styles } from "../../styles";
import { primary, secondary } from "../../styles/constants";
import Title from "../../components/Title";
import Column from "../../components/Column";
import Row from "../../components/Row";
import WorkerManual from "./manual";
import Notes from "./notes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

import {
  APPROVED,
  REJECTED,
  CONSENT,
  PROCESSING,
  WITHDRAWN,
  PENDING,
  REVIEW,
  FIRST_NOTICE,
  SECOND_NOTICE,
  COMPLIANCEREVIEW,
  CAN_WITHDRAW_STATUS
} from "../../constants";
import RejectedCandidate from "./rejectedCandidate";
import WorkerContainerInformation from "./Information";
import WorkerHeader from "./header";
import WorkerContainerChecks from "./checks";
import { SearchHighlightedProvider } from "../../components/SearchHighlighted";
import DebounceLoader from "../../components/DoubleBounceLoader";
import { TurnLoader } from "../../components/Loader";
import { turnAPI } from "../../services";
import EnrollmentInformation from "./Information/EnrollmentInformation";
import teamMember from "../../models/team-member";

const WorkerContainer = observer(
  class WorkerContainer extends Component {
    static propTypes = {
      worker: PropTypes.object,
      auth: PropTypes.object,
      location: PropTypes.object,
      history: PropTypes.object,
      openErrorDialog: PropTypes.func
    };
    static contextTypes = {
      router: PropTypes.object.isRequired
    };
    state = {};
    getIsWithdrawable = () =>
      CAN_WITHDRAW_STATUS.includes(this.props.worker.plainOnBoardingStatus);
    getIsReadOnly = () => this.props.worker.readOnly;
    getIsCalifornia = () => this.props.worker.providedState === "CA";
    getMVRData = () => this.props.worker.mvr;
    getCriminalRecords = () => this.props.worker.countyCriminalRecords;
    workerApproved = () => this.props.worker.onBoardingStatus === APPROVED;
    workerRejected = () => this.props.worker.onBoardingStatus === REJECTED;
    workerInConsent = () => this.props.worker.onBoardingStatus === CONSENT;
    workerWithdrawn = () => this.props.worker.onBoardingStatus === WITHDRAWN;
    workerPending = () =>
      this.props.worker.onBoardingStatus === PENDING ||
      this.props.worker.onBoardingStatus === "pending"; // TODO: Deal with the "pending" situation
    workerReview = () =>
      this.props.worker.onBoardingStatus === REVIEW ||
      this.props.worker.onBoardingStatus === COMPLIANCEREVIEW;
    workerFirstNotice = () =>
      this.props.worker.onBoardingStatus === FIRST_NOTICE;
    workerSecondNotice = () =>
      this.props.worker.onBoardingStatus === SECOND_NOTICE;
    workerManualNoReport = () =>
      this.props.worker.manual && !this.props.worker.pdfURL;
    workerInProcessing = () =>
      this.props.worker.onBoardingStatus === PROCESSING;
    workerInProcessingMVR = () => this.props.worker.isProcessingMVR;
    workerInPendingReview = () => !!this.props.worker.isPendingReview;
    workerInPendingMVRReview = () => !!this.props.worker.isPendingMVRReview;
    workerInPendingAgeReview = () => !!this.props.worker.isPendingAgeReview;
    workerInEmailed = () =>
      this.props.worker.plainOnBoardingStatus === "emailed";
    workerInEmailedRecheck = () =>
      this.props.worker.onBoardingStatus === "emailed" &&
      this.props.worker.rechecks.length > 0;
    workerCannotApprove = () =>
      this.workerApproved() ||
      this.workerInConsent() ||
      this.workerInProcessing() ||
      this.workerManualNoReport() ||
      this.props.worker.readOnly;
    workerCannotReject = () =>
      this.workerApproved() ||
      this.workerRejected() ||
      this.workerFirstNotice() ||
      this.workerSecondNotice() ||
      this.workerInConsent() ||
      this.workerInProcessing() ||
      this.workerManualNoReport() ||
      this.workerWithdrawn() ||
      this.props.worker.readOnly;

    workerCanWithdraw = () =>
      !this.workerWithdrawn() &&
      ((this.getIsWithdrawable() && !this.getIsReadOnly()) ||
        (this.getIsCalifornia() && this.workerInEmailed()));

    workerCannotResume = () =>
      !this.workerInPendingAgeReview() &&
      !this.workerInPendingMVRReview() &&
      !this.workerInPendingReview();
    workerCannotPrint = () =>
      this.workerInConsent() ||
      this.workerInProcessing() ||
      this.workerReview() ||
      this.workerInPendingAgeReview() ||
      this.workerInEmailedRecheck();

    workerCanRecheck = () =>
      this.workerApproved() || this.workerRejected() || this.workerPending();

    printPage = async () => {
      if (this.props.worker.canadianReport) {
        window.print();
      } else {
        this.setState({ loading: true });
        const response = await turnAPI.getPartnerWorkerReportPdf(
          this.props.worker.id
        );
        if (response.status === 200) {
          const url = `https://${response.data.url.split("://")[1]}`;
          const w = window.open(url, "_blank");
          if (w) {
            w.focus();
          }
        }
        this.setState({ loading: false });
      }
    };
    renderTitle = () => (
      <Link to="/">
        <FontAwesomeIcon
          icon={faChevronLeft}
          style={{ fontSize: "32px", color: secondary, marginRight: "20px" }}
        />
        <Title margin="0px" inline color={primary}>
          Report
        </Title>
      </Link>
    );
    renderLoader = () => {
      if (!this.props.worker.loadingRecheck) return null;
      return (
        <div style={styles.checks.loader}>
          <DebounceLoader />
        </div>
      );
    };
    render() {
      const { worker } = this.props;
      const { router } = this.context;
      const {
        workerCannotApprove,
        workerCannotReject,
        workerCannotResume,
        workerCannotPrint,
        workerCanWithdraw,
        workerCanRecheck
      } = this;

      if (!worker) return null;
      return (
        <div style={styles.content}>
          <SearchHighlightedProvider>
            <Row style={styles.headerContainer}>
              <WorkerHeader
                worker={worker}
                cannotApprove={workerCannotApprove()}
                cannotReject={workerCannotReject()}
                canWithdraw={workerCanWithdraw()}
                cannotResume={workerCannotResume()}
                cannotPrint={workerCannotPrint()}
                openErrorDialog={this.props.openErrorDialog}
                printPage={this.printPage}
                canRecheck={workerCanRecheck()}
                {...this.props}
              />
            </Row>
            <Row style={styles.contentContainer}>
              <Column
                width="30%"
                widthMD="100%"
                widthSM="100%"
                printWidth="100%"
              >
                <WorkerContainerInformation
                  router={router}
                  cannotApprove={workerCannotApprove()}
                  cannotReject={workerCannotReject()}
                  canWithdraw={workerCanWithdraw()}
                  cannotResume={workerCannotResume()}
                  openErrorDialog={this.props.openErrorDialog}
                  printPage={this.printPage}
                  {...this.props}
                />

                {teamMember.showContinuousMonitoring && (
                  <EnrollmentInformation worker={worker} expand={true} />
                )}

                {teamMember.showNotes && (
                  <Notes worker={worker} expand={true} />
                )}
                <RejectedCandidate
                  worker={worker}
                  rejected={worker.rejectionDetails}
                />
                <WorkerManual worker={worker} />
              </Column>
              <Column
                width="65%"
                widthMD="100%"
                widthSM="100%"
                printWidth="100%"
              >
                {this.renderLoader()}

                <WorkerContainerChecks
                  worker={worker}
                  openErrorDialog={this.props.openErrorDialog}
                />
              </Column>
            </Row>
          </SearchHighlightedProvider>
          <TurnLoader hidden={!this.state.loading} />
        </div>
      );
    }
  }
);

export default WorkerContainer;
