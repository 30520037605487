import env from "@mars/heroku-js-runtime-env";
import teamMember from "./team-member";
import { readyRoutes, reportRoute, screenRoutes } from "../constants";

export const isProjectRoute = (projectRoutes = [], route) =>
  projectRoutes.some(
    projectRoute => route.includes(`${projectRoute}/`) || route === projectRoute
  );

export const isRouteDisabledWithError = route => {
  // need this while settings lives in the recruiter dashboard
  const isSettings = route === "/recruiter/settings";
  if (isProjectRoute(readyRoutes, route) && !isSettings) {
    if (env().REACT_APP_COHORT === "false") return true;
    if (!teamMember.id) teamMember.retrieveMember();
    if (!teamMember.isAuthorizedForReady) return true;

    return false;
  }
  if (reportRoute === route && env().REACT_APP_ENABLE_REPORT_TAB === "false")
    return true;
  if (
    isProjectRoute(screenRoutes, route) &&
    !teamMember.isAuthorizedForScreen &&
    !isSettings
  )
    return true;

  return false;
};
