import { Builder } from "../record-helper";

export const parseViolation = data => {
  const titles = ["type", "statuses", "code"];
  const builder = new Builder(data, { titles });

  builder.assignList(["type", "incident_date", "conviction_date"]);
  builder.assignNested(
    "locations",
    [
      ["loc", { out: "location" }],
      ["zip", { out: "zip_code" }],
      ["docket"],
      ["court"],
      ["court_type"],
      ["state"]
    ],
    { titles: ["loc", "court", "court_type"] }
  );
  builder.assignList([
    "accident",
    "suspension",
    "fine_date",
    "suspension_date",
    "plate_number"
  ]);
  builder.assignNested("sub_violations", [
    ["acd_code"],
    ["acd_code_description"],
    ["state_points"]
  ]);
  builder.assignNested(
    "sub_violations.descriptions",
    [["short_description"], ["state_description"]],
    { sentences: ["short_description", "state_description"] }
  );
  return builder.dump();
};
