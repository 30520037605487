/* eslint-disable no-fallthrough */
import React, { Component } from "react";
import PropTypes from "prop-types";
import IconButton from "material-ui/IconButton";
import MenuIcon from "material-ui/svg-icons/navigation/menu";
import SideBarOpenButton from "../../components/SideBarPieces/open-button";
import SideBarHeader from "../../components/SideBarPieces/header";
import SideBarLogo from "../../components/SideBarPieces/logo";
import {
  LogoContainer,
  MenuList,
  SidebarContainer,
  TabLink,
  TabLinkContainer,
  TabLinkText,
  TabLinkListItem,
  ActionsLine,
  ActionsContainer,
  ActionIconWrapper
} from "../../components/SideBarPieces/styled";
import SidebarContent from "../../components/SideBarPieces/content";
import { secondary } from "../../styles/constants";
import _ from "lodash";

import messages from "./messages";
import withContext from "../../contextAPI/context-HOC";
import teamMember from "../../models/team-member";
import workers from "../../models/workers";
import Settings from "../../models/settings";
import { WORKER_PAGE, READY_PAGE, READY_APPLICANTS } from "../../constants";
import { getPageFromRoute } from "../../utils/get-page-from-route";
import { getSubtabFromRoute } from "../../utils/get-subtab-from-route";
import { ReactComponent as Logo } from "../../icons/turn_logo.svg";
import HideLarge from "../../components/Responsive/hide-large";
import ShowLarge from "../../components/Responsive/show-large";
import { getSubtabVariantFromRoute } from "../../utils/get-subtab-variant-from-route";
import SettingsIcon from "../../icons/svgr/SettingsIcon";
import SearchZoomInIcon from "../../icons/svgr/SearchZoomInIcon";
import LogoutIcon from "../../icons/svgr/LogoutIcon";
import HomeIcon from "../../icons/svgr/HomeIcon";
import SupportIcon from "../../icons/svgr/SupportIcon";

export class SideBar extends Component {
  static propTypes = {
    children: PropTypes.node,
    model: PropTypes.object.isRequired,
    auth: PropTypes.object,
    fetch: PropTypes.func,
    history: PropTypes.object.isRequired,
    openSettings: PropTypes.bool,
    openSupport: PropTypes.func,
    mdBreak: PropTypes.bool,
    disableNavigation: PropTypes.bool
  };
  static defaultProps = {
    model: workers,
    history: {}
  };
  constructor(props) {
    super(props);
    this.settings = new Settings(props.context.settings);
    this.state = {
      open: false,
      sourcingCollapsed: true,
      readyPage: false
    };
    this.route = this.props.history.location
      ? this.props.history.location.pathname || ""
      : window.location
      ? window.location.pathname || ""
      : "";
    this.profile = this.props.auth ? this.props.auth.getProfile() : {};
  }

  componentDidMount() {
    if (this.page === READY_PAGE && this.subtab !== READY_APPLICANTS) {
      setTimeout(() => {
        this.setState({ readyPage: true });
      }, 200);
    }
  }

  openMenu = () => {
    if (this.props.disableNavigation) return;
    this.setState({ open: !this.state.open });
  };

  get page() {
    return getPageFromRoute(this.route);
  }

  get subtab() {
    return getSubtabFromRoute(this.route);
  }

  get subtabVariant() {
    return getSubtabVariantFromRoute(this.route);
  }

  toggleSourcingCollapsed = () => {
    this.setState(prevState => ({
      sourcingCollapsed: !prevState.sourcingCollapsed
    }));
  };

  isSelected = tab => {
    if (tab == READY_APPLICANTS && this.route === "/recruiter/applicants") {
      return true;
    }

    return this.page === tab ? true : false;
  };

  isSubtabSelected = subTab => {
    if (_.isArray(subTab)) {
      return subTab.includes(this.subtab) ? true : false;
    }
    return this.subtab === subTab ? true : false;
  };

  openFreshDesk = () => {
    if (!window.fcWidget) return;
    window.fcWidget.open();
  };

  renderHomeTab = () => {
    if (!teamMember.showHome) return null;
    return (
      <TabLinkContainer selected={this.isSelected("HOME")}>
        <TabLink href="/home" target="">
          <TabLinkListItem
            id="homeMenuSideBar"
            selected={this.isSelected("HOME")}
          >
            <HomeIcon />
            <TabLinkText selected={this.isSelected("HOME")}>Home</TabLinkText>
          </TabLinkListItem>
        </TabLink>
      </TabLinkContainer>
    );
  };

  renderWorkersTab = () => {
    if (!teamMember.isAuthorizedForScreen) return null;
    return (
      <TabLinkContainer
        selected={this.isSelected("WORKERS") || this.isSelected("WORKER")}
      >
        <TabLink href="/screen" target="">
          <TabLinkListItem
            id="screeningMenuSideBar"
            selected={this.isSelected("WORKERS") || this.isSelected("WORKER")}
          >
            <SearchZoomInIcon />
            <TabLinkText
              selected={this.isSelected("WORKERS") || this.isSelected("WORKER")}
            >
              {messages.workersLink.defaultMessage}
            </TabLinkText>
          </TabLinkListItem>
        </TabLink>
      </TabLinkContainer>
    );
  };

  renderSettingsSubTab = () => {
    return (
      <TabLinkContainer selected={this.isSelected("SETTINGS")}>
        <TabLink href="/settings" target="">
          <TabLinkListItem
            id="settingsMenuSideBar"
            selected={this.isSelected("SETTINGS")}
            {...(this.isSelected("SETTINGS") ? "selected" : "")}
          >
            <SettingsIcon />
            <TabLinkText selected={this.isSelected("SETTINGS")}>
              {messages.settings.defaultMessage}
            </TabLinkText>
          </TabLinkListItem>
        </TabLink>
      </TabLinkContainer>
    );
  };

  renderActionsTabs = () => {
    return (
      <ActionsContainer>
        <ActionsLine></ActionsLine>
        {teamMember.canTriggerSupport && (
          <TabLinkContainer support onClick={this.openFreshDesk}>
            <TabLinkListItem
              {...(this.isSelected("SUPPORT") ? "selected" : "")}
            >
              <ActionIconWrapper>
                <SupportIcon />
              </ActionIconWrapper>
              <TabLinkText>{"Support"}</TabLinkText>
            </TabLinkListItem>
          </TabLinkContainer>
        )}
        <TabLinkContainer logout onClick={this.props.auth.logout}>
          <TabLinkListItem {...(this.isSelected("LOGOUT") ? "selected" : "")}>
            <ActionIconWrapper>
              <LogoutIcon />
            </ActionIconWrapper>
            <TabLinkText>{messages.logout.defaultMessage}</TabLinkText>
          </TabLinkListItem>
        </TabLinkContainer>
      </ActionsContainer>
    );
  };

  getTitle = () => {
    if (this.page === WORKER_PAGE)
      return this.props.model ? this.props.model.fullName || "" : "";
    return this.profile ? this.profile.name || "" : "";
  };

  renderLogo = () => (
    <LogoContainer href="/">
      <Logo />
    </LogoContainer>
  );

  render() {
    return (
      <SidebarContent>
        <SidebarContainer
          onMouseLeave={() => this.setState({ sourcingCollapsed: true })}
          disableNavigation={this.props.disableNavigation}
        >
          <SideBarHeader>
            <ShowLarge>
              <SideBarOpenButton>
                <IconButton tooltip="Show Menu" onClick={this.openMenu}>
                  <MenuIcon color={secondary} />
                </IconButton>
              </SideBarOpenButton>
            </ShowLarge>
            <SideBarLogo>{this.renderLogo()}</SideBarLogo>
          </SideBarHeader>
          {this.props.hideContent ? null : (
            <HideLarge show={this.state.open}>
              <div>
                <MenuList>
                  {this.renderHomeTab()}
                  {this.renderWorkersTab()}
                  {!teamMember.canShowProfile &&
                  !teamMember.canShowCompanySettings &&
                  !teamMember.canShowUserManagement &&
                  !teamMember.canShowIntegrations &&
                  !teamMember.canShowSettingsPayments
                    ? null
                    : this.renderSettingsSubTab()}
                  {this.renderActionsTabs()}
                </MenuList>
              </div>
            </HideLarge>
          )}
        </SidebarContainer>
      </SidebarContent>
    );
  }
}

export default withContext(SideBar);
