import { string } from "prop-types";
import React from "react";

const LogoutIcon = ({ height, stroke, width }) => {
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke || "#000"}
    >
      <path
        d="M8.15833 6.93C8.44249 3.63 10.1383 2.2825 13.8508 2.2825H13.97C18.0675 2.2825 19.7083 3.92333 19.7083 8.02083V13.9975C19.7083 18.095 18.0675 19.7358 13.97 19.7358H13.8508C10.1658 19.7358 8.46999 18.4067 8.16749 15.1617"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.83337 11H13.64"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5958 7.92917L14.6667 11L11.5958 14.0708"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LogoutIcon.propTypes = {
  height: string,
  stroke: string,
  width: string
};

export default LogoutIcon;
