import React from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import CollapsibleListItem from "../../../components/ListDetailView/collapsible-list-item";
import DetailInline from "../../../components/ListDetailView/detail-inline";
import { Divider } from "material-ui";
import { isEmpty } from "@firebase/util";

const RecordDetail = ({ expand, width, customStyles, idSuffix, detail }) => (
  <div
    style={{
      paddingLeft: "9%",
      position: "relative"
    }}
  >
    <div
      style={{
        position: "absolute",
        top: "15px"
      }}
    >
      <FontAwesomeIcon icon={faCircle} style={{ fontSize: "10px" }} />
    </div>
    {idSuffix !== 0 && width === "small" ? <Divider /> : null}
    <div style={{ marginLeft: "25px" }}>
      <CollapsibleListItem
        light
        inline
        verbose
        divider
        expand={expand}
        responsive={width}
        customStyles={customStyles}
        statusID={"CrimnalRecords.RecordDetail.status." + idSuffix}
        titleID={"CrimnalRecords.RecordDetail.title" + idSuffix}
        title={detail.offense_description_1}
        readyStatus={
          isEmpty(detail.crime_type)
            ? "No Crime Type Provided"
            : detail.crime_type
        }
        isFelony={
          !isEmpty(detail.crime_type) &&
          detail.crime_type.toLowerCase().includes("felony") &&
          !detail.crime_type.toLowerCase().includes("misdemeanor")
        }
        key={idSuffix}
      >
        <DetailInline deep data={detail} exclude={["active", "id", "county"]} />
      </CollapsibleListItem>
    </div>
  </div>
);

RecordDetail.propTypes = {
  expand: PropTypes.bool,
  width: PropTypes.string,
  customStyles: PropTypes.object,
  index: PropTypes.number,
  detail: PropTypes.object,
  idSuffix: PropTypes.string
};
export default RecordDetail;
