import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, Icon, withStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes } from "@fortawesome/pro-solid-svg-icons";
import styled from "styled-components";
import pluralize from "pluralize";
import _ from "lodash";

import SubTitle from "../../SubTitle";
import PanelCollapsible from "../../PanelCollapsible";
import { WorkerSettingsStyles as styles } from "../../../styles";
import {
  primary,
  slateGray,
  aliceBlue,
  emerald,
  tartOrange,
  orange
} from "../../../styles/constants";

import { StatusLabel } from "./StatusLabel";
import { SubscriptionEdit } from "./SubscriptionEdit";

const muiStyles = {
  icon: {
    width: "auto",
    height: "auto",
    overflow: "visible",
    verticalAlign: "middle",
    fontSize: 14,
    marginRight: 7
  }
};

const SubscriptionNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SubscriptionName = styled.div`
  flex: 1;
  color: ${primary};
  font-weight: bold;
`;

const Subdivision = styled.hr`
  border-top: 1px solid ${aliceBlue};
`;

const UnitTitle = styled.div`
  color: ${slateGray};
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
`;

const CountBoxesWrapper = styled.div`
  display: flex;
  font-weight: bold;
  margin: 10px 0;
`;

const CountBoxItem = styled.div`
  flex: 1;
`;

const CountBoxCount = styled.div`
  font-size: 21px;
  color: ${props => props.color || "inherit"};
`;

const CountBoxLabel = styled.div`
  color: ${slateGray};
  font-size: 12px;
`;

const CountBox = ({ count, label, color, ...props }) =>
  _.isNil(count) ? null : (
    <CountBoxItem {...props}>
      <CountBoxCount color={color}>{count}</CountBoxCount>
      <CountBoxLabel>{label}</CountBoxLabel>
    </CountBoxItem>
  );

export const SubscriptionSectionComponent = ({
  classes,
  title,
  name,
  status,
  is_instant_charge,
  allotted_usage,
  total_usage,
  unit_label
}) => {
  const [showEdit, setShowEdit] = useState(false);

  unit_label = unit_label ? pluralize(unit_label) : "Usage";
  const unit_title = _.upperFirst(unit_label);
  const available_usage =
    allotted_usage > total_usage ? allotted_usage - total_usage : 0;

  return (
    <PanelCollapsible
      noPrint
      panelProps={{ style: styles.workerSettingsSection.panel }}
      collapsibleIconStyle={styles.workerSettingsSection.icon}
      titleStyle={styles.workerSettingsSection.title}
      titleComponent={
        <SubTitle size="16px" color={slateGray} margin="0" weight="bold">
          <FontAwesomeIcon
            style={styles.workerSettingsSection.titleIcon}
            icon={faBoxes}
          />
          {title}
          <StatusLabel color={status === "active" ? emerald : orange}>
            {_.startCase(status)}
          </StatusLabel>
        </SubTitle>
      }
    >
      <SubscriptionNameWrapper>
        <SubscriptionName>{name}</SubscriptionName>
        <Button
          color="secondary"
          size="small"
          onClick={() => setShowEdit(true)}
        >
          <Icon className={classNames(classes.icon, "far fa-edit")} />
          Edit Plan
        </Button>
        <SubscriptionEdit open={showEdit} onClose={() => setShowEdit(false)} />
      </SubscriptionNameWrapper>

      <Subdivision />

      <UnitTitle>{unit_title}</UnitTitle>
      <CountBoxesWrapper>
        {is_instant_charge ? (
          <>
            <CountBox label="Included" count={"∞"} />
            <CountBox label="Available" color={emerald} count={"∞"} />
          </>
        ) : (
          <>
            <CountBox label="Included" count={allotted_usage} />
            <CountBox
              label="Available"
              color={emerald}
              count={available_usage}
            />
          </>
        )}
        <CountBox label="Sent" color={tartOrange} count={total_usage} />
      </CountBoxesWrapper>
    </PanelCollapsible>
  );
};
SubscriptionSectionComponent.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
  is_instant_charge: PropTypes.bool,
  allotted_usage: PropTypes.number,
  total_usage: PropTypes.number
};

export const SubscriptionSection = withStyles(muiStyles)(
  SubscriptionSectionComponent
);
SubscriptionSection.propTypes = SubscriptionSectionComponent.propTypes;
