import Column from "../Column";
import { FormCheckLine } from "./components/FormCheckLine";
import { FormWizardBackForward } from "./components/FormWizardBackNext";
import { FormWizardStep } from "./components/FormWizardStep";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import React from "react";
import Title from "../Title";
import TurnScroll from "../TurnScroll";
import messages from "./messages";
import { observer } from "mobx-react";
import { LandingPageStyles as styles } from "../../styles";
import syslog from "../../services/syslog";

const propTypes = {
  ...FormWizardStep.propTypes,
  details: PropTypes.object,
  disclosure: PropTypes.string
};

const TabDisclosure = props => {
  const handleNext = () => {
    const detailsData = { ...props.details.formData };
    detailsData["has_middle_name"] = !detailsData["has_middle_name"];
    detailsData["tab"] = "step_4_disclosure";
    syslog([detailsData]);
    props.nextStep();
  };
  return (
    <div style={styles.tabContent}>
      <Title id="disclosureStepTitle" align="left" big>
        Disclosure
      </Title>
      <TurnScroll
        id="disclosureLegalDisclosure"
        dangerouslySetInnerHTML={{ __html: props.disclosure }}
      />
      <div style={styles.agreeContainer}>
        <FormCheckLine
          id="disclosureTabCheckbox"
          label={<FormattedMessage {...messages.disclosure} />}
          checked={props.details.agreeDisclosure}
          onChange={() =>
            props.details.setAgreeDisclosure(!props.details.agreeDisclosure)
          }
        />
      </div>
      <Column
        textAlign="right"
        textAlignSM="center"
        width="100%"
        margin="20px 0 0 0"
      >
        <FormWizardBackForward
          id="disclosureTabBackForwardDiv"
          nextDisabled={!props.details.agreeDisclosure}
          onBack={() => props.previousStep()}
          onNext={() => handleNext()}
        />
      </Column>
    </div>
  );
};

TabDisclosure.propTypes = propTypes;
export default observer(TabDisclosure);
