import React from "react";
import { PropTypes } from "prop-types";

const style = {
  backgroundColor: "#40404b",
  position: "fixed",
  width: "100%",
  height: "100%"
};
class Login extends React.PureComponent {
  static propTypes = {
    auth: PropTypes.node
  };
  componentDidMount() {
    this.props.auth.login();
  }
  render() {
    return <div style={style} />;
  }
}

export default Login;
