import styled from "styled-components";

const FooterContent = styled.div`
  width: 80%;
  padding: 10px;
  display: block;
  margin: 0 auto;
  text-align: ${props => (props.left ? "left" : "center")};
`;

export default FooterContent;
