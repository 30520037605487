import { observable, decorate, action, computed } from "mobx";
import { turnAPI } from "../services";
import { Setter } from "turn-shared";
import {
  TEAMS_SECTION,
  INTEGRATIONS_SECTION,
  PACKAGES_AND_PAYMENTS,
  DEFAULTS_SECTION,
  CURRENT_INVOICE
} from "../constants";

class Settings extends Setter {
  data = [];
  open = false;
  openAddTeam = false;
  ready = true;
  context = {};
  _error = {};
  selectedMember = {};
  exception = null;
  successMessage = null;

  constructor(props) {
    super(props);
    if (props) this.context = props;
    this.getSettings();
  }

  get error() {
    return this._error;
  }

  get teamSection() {
    return this.data.filter(({ title }) => TEAMS_SECTION.includes(title));
  }

  get integrationsSection() {
    return this.data.filter(({ title }) =>
      INTEGRATIONS_SECTION.includes(title)
    );
  }

  get defaultsSection() {
    return this.data.filter(({ title }) => DEFAULTS_SECTION.includes(title));
  }

  get defaultSettings() {
    return this.data.filter(({ title }) =>
      INTEGRATIONS_SECTION.includes(title)
    );
  }

  get packagesAndPaymentsSection() {
    return this.data.filter(
      ({ title, type }) =>
        PACKAGES_AND_PAYMENTS.includes(title) || type == "subscription"
    );
  }

  get invoiceSection() {
    return this.data.filter(({ title }) => title === CURRENT_INVOICE);
  }

  setMember = (prop, value) => {
    this.selectedMember[prop] = value;
  };

  setSelectedMember = member => {
    this.selectedMember = member;
  };

  openSettings = () => {
    if (this.context.setOpenSettings) this.context.setOpenSettings(true);
    this.open = this.context.openSettings || true;
  };
  closeSettings = () => {
    if (this.context.setOpenSettings) this.context.setOpenSettings(false);
    this.open = this.context.openSettings || false;
  };
  setReady = this.setBoolFactory("ready", false);

  setOpenAddTeam = this.setBoolFactory("openAddTeam");

  toggleOpenAddTeam = () => this.setOpenAddTeam(!this.openAddTeam);

  cleanData = data => {
    const primaryContactSection = data.find(
      section => section.title === "Primary Contact"
    );
    const updatedData = data
      .map(section => {
        if (section.title === "Team Members") {
          const hasPrimaryContact = !!section.data.find(
            data => data.value[1] === primaryContactSection.data[0].value[1]
          );
          if (hasPrimaryContact) {
            section.data.map(data => {
              const newData = data;
              if (data.value[1] === primaryContactSection.data[0].value[1]) {
                newData.isPrimaryContact = true;
              }
              return newData;
            });
          } else {
            const primaryContact = primaryContactSection.data[0];
            primaryContact.isPrimaryContact = true;
            section.data.push(primaryContact);
          }
        }
        return section;
      })
      .filter(section => section.title !== "Primary Contact");
    return updatedData;
  };

  getSettings = async () => {
    this.setReady(false);
    const response = await turnAPI.getSettings();
    switch (response.status) {
      case 200:
        this.data = await this.cleanData(response.data);
        this.setReady(true);
        return this.data;
      default:
        break;
    }
    this.setReady(true);
  };

  submitStripeToken = async (reference, token, formData) => {
    this.setReady(false);
    const response = await turnAPI.postSettings(reference, {
      token,
      ...formData
    });
    if (!response) return;
    switch (response.status) {
      case 200:
        this.getSettings();
        this.setReady(true);
        return response.status;

      default:
        this.setReady(true);
        return response.status;
    }
  };

  createTeamMember = async data => {
    this.setReady(false);
    const response = await turnAPI.createTeamMember(data);
    switch (response.status) {
      case 200:
        await this.getSettings();
        this.setReady(true);
        this.setSuccessMessage(
          `Team member with email ${data.email} was created successfully`
        );
        return response.data;

      case 422:
        this.setException(response.data);
        break;

      default:
        this.setReady(true);
        break;
    }
  };

  updateTeamMember = async (id, data) => {
    this.setReady(false);
    const response = await turnAPI.updateTeamMember(id, data);
    switch (response.status) {
      case 200:
        await this.getSettings();
        this.setReady(true);
        this.setSuccessMessage(`Team member was successfully updated`);
        break;

      default:
        this.setReady(true);
        break;
    }
  };

  makePrimaryContact = async id => {
    this.setReady(false);
    const response = await turnAPI.makePrimaryContact(id);
    if (!response) return;
    switch (response.status) {
      case 200:
        await this.getSettings();
        this.setReady(true);
        this.setSuccessMessage(
          `Team member was successfully made primary contact`
        );
        break;

      default:
        this.setReady(true);
        break;
    }
  };

  deleteTeamMember = async id => {
    this.setReady(false);
    const response = await turnAPI.deleteTeamMember(id);
    if (!response) return;
    switch (response.status) {
      case 200:
        await this.getSettings();
        this.setReady(true);
        this.setSuccessMessage(`Team member was deleted successfully`);
        break;

      default:
        this.setReady(true);
        break;
    }
  };

  setError = (label, error) => {
    this._error = {
      [label]: error
    };
  };

  resetErrors = () => {
    this._error = {};
  };

  setException = exception => {
    this.exception = exception;
  };

  setSuccessMessage = successMessage => {
    this.successMessage = successMessage;
  };
}
decorate(Settings, {
  error: computed,
  teamSection: computed,
  integrationsSection: computed,
  defaultsSection: computed,
  packagesAndPaymentsSection: computed,
  invoiceSection: computed,
  payload: observable,
  open: observable,
  data: observable,
  ready: observable,
  _error: observable,
  exception: observable,
  selectedMember: observable,
  successMessage: observable,
  openAddTeam: observable,
  closeSettings: action,
  openSettings: action,
  setReady: action,
  setError: action,
  resetErrors: action,
  createTeamMember: action,
  updateTeamMember: action,
  makePrimaryContact: action,
  deleteTeamMember: action,
  setException: action,
  setSuccessMessage: action,
  setOpenAddTeam: action,
  toggleOpenAddTeam: action
});

export default Settings;
