import styled from "styled-components";

const Head = styled.div`
  display: block;
  width: 100%;
  background-color: #fff;
  margin: 25px 0 15px 0;
  max-width: 100%;
`;

export default Head;
