import React from "react";
import { MenuItem } from "@material-ui/core";
import { getStatus } from "turn-shared";
import { observer } from "mobx-react";
import { capitalize } from "lodash";

import { WorkerFilterContainer } from "./WorkerFilterContainer";
import { WorkerFilterTitle } from "./WorkerFilterTitle";
import { WorkerFilterLabel } from "./WorkerFilterLabel";
import { WorkerFilterSearchField } from "./WorkerFilterSearchField";
import { WorkerFilterDropdown } from "./WorkerFilterDropdown";
import { WorkerFilterSection } from "./WorkerFilterSection";
import { WorkerFilterReset } from "./WorkerFilterReset";

import { smokeWhite, primary } from "../../styles/constants";

const styles = {
  menuItem: {
    color: primary,
    fontSize: "14px",
    fontWeight: "bold"
  }
};

export class WorkerFilterComponent extends React.Component {
  renderFilterMenuItems() {
    const { workers } = this.props;
    return workers.statuses.map(
      option =>
        option !== "adverse" &&
        option !== "created" && (
          <MenuItem key={option} value={option} style={styles.menuItem}>
            {capitalize(getStatus(option))}
          </MenuItem>
        )
    );
  }

  renderDateFilterMenuItems() {
    const ranges = [
      {
        days: 0,
        label: "All"
      },
      {
        days: 7,
        label: "Last 7 days"
      },
      {
        days: 15,
        label: "Last 15 days"
      },
      {
        days: 30,
        label: "Last 30 days"
      },
      {
        days: 60,
        label: "Last 60 days"
      },
      {
        days: 90,
        label: "Last 90 days"
      }
    ];

    return ranges.map(({ days, label }) => (
      <MenuItem key={days} value={days} style={styles.menuItem}>
        {label}
      </MenuItem>
    ));
  }

  reset() {
    const { workers, fetch } = this.props;

    workers.setSearch("");
    workers.setStatus("all");
    workers.setDaysFilter(0);
    workers.setOffset(0);

    // FIXME: This should happen automatically
    fetch && fetch();
  }

  renderSearch() {
    const { workers } = this.props;
    return (
      <WorkerFilterSection style={{ display: "block" }}>
        <WorkerFilterLabel style={{ display: "block", marginBottom: 10 }}>
          Search
        </WorkerFilterLabel>
        <WorkerFilterSearchField
          fullWidth
          name="search"
          placeholder="Name, keyword, email, Turn ID"
          onChangeText={text => workers.setSearch(text)}
          value={workers.search}
          inputProps={{ autocomplete: "new-password" }}
        />
      </WorkerFilterSection>
    );
  }

  renderStatus() {
    const { workers } = this.props;
    return (
      <WorkerFilterSection>
        <WorkerFilterLabel style={{ verticalAlign: "middle", width: 70 }}>
          Status:
        </WorkerFilterLabel>
        <WorkerFilterDropdown
          style={{ flex: 1, background: smokeWhite }}
          value={workers.status}
          onChange={({ target }) => workers.setStatus(target.value)}
        >
          {this.renderFilterMenuItems()}
        </WorkerFilterDropdown>
      </WorkerFilterSection>
    );
  }

  renderDate() {
    const { workers, fetch } = this.props;
    return (
      <WorkerFilterSection>
        <WorkerFilterLabel style={{ verticalAlign: "middle", width: 70 }}>
          Date:
        </WorkerFilterLabel>
        <WorkerFilterDropdown
          style={{ flex: 1, background: smokeWhite }}
          value={workers.daysFilter}
          onChange={({ target }) => {
            workers.setDaysFilter(target.value);

            // FIXME: This should happen automatically
            fetch && fetch();
          }}
        >
          {this.renderDateFilterMenuItems()}
        </WorkerFilterDropdown>
      </WorkerFilterSection>
    );
  }

  renderReset() {
    return (
      <div style={{ textAlign: "right" }}>
        <WorkerFilterReset
          label={"Reset Filters"}
          style={{ marginBottom: 10, marginTop: 30 }}
          onClick={() => this.reset()}
        />
      </div>
    );
  }

  render() {
    const { workers, ...props } = this.props;

    return (
      <WorkerFilterContainer hasBoxShadow background="white" {...props}>
        <WorkerFilterTitle>Filter Results ({workers.count})</WorkerFilterTitle>
        {this.renderSearch()}
        {this.renderStatus()}
        {this.renderDate()}
        {this.renderReset()}
      </WorkerFilterContainer>
    );
  }
}

export class PendingWorkerFilterComponent extends WorkerFilterComponent {
  renderStatus() {
    return null;
  }
}

export const PendingWorkerFilter = observer(PendingWorkerFilterComponent);
export const WorkerFilter = observer(WorkerFilterComponent);
