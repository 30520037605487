import styled from "styled-components";
import { fadedGray } from "../../../styles/constants";

export const Background = styled.div`
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  background: ${({ background }) => background || fadedGray};
  display: flex;
  justify-content: center;
`;
