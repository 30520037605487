import styled from "styled-components";

const Body = styled.div`
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
  max-width: 100%;
  overflow-x: auto;
`;

export default Body;
