import React from "react";
import _ from "lodash";
import Done from "material-ui/svg-icons/action/done";
import Warning from "material-ui/svg-icons/alert/error-outline";
import { ClockIcon as Processing } from "../themes/icons";
import { noticeWarning, primary } from "../styles/constants";

const WARNING = "warning";
const DONE = "done";
const PROCESSING = "processing";
const STATUSES = { WARNING, DONE, PROCESSING };
const styles = {
  color: primary,
  fontWeight: "bold",
  display: "inline-block",
  verticalAlign: "middle",
  marginRight: 20
};

const getStatus = check => {
  if (!Array.isArray(check)) return WARNING;
  if (check.length === 0) return DONE;
  if (_.some(check, { status: "processing" })) return PROCESSING;
  if (_.every(check, { status: "ready", value: null })) return DONE;
  return WARNING;
};

const renderWorkerApproval = check => {
  switch (getStatus(check)) {
    default:
    case WARNING:
      return <Warning color={noticeWarning} />;
    case DONE:
      return (
        <span>
          <span style={styles}>CLEAR</span> <Done color={primary} />
        </span>
      );
    case PROCESSING:
      return <Processing color={primary} />;
  }
};

export default renderWorkerApproval;
export { STATUSES, getStatus };
