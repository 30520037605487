import React, { useMemo } from "react";
import _ from "lodash";

import { CanadianCheckListItem } from "./CanadianCheckListItem";

const checkListKeys = {
  public_court_records: "Public Court Records",
  fraud_watchlists: "Fraud Watchlists",
  known_affiliations: "Known Affiliations",
  global_sanctions_and_enforcements: "Global Sanctions and Enforcements",
  public_safety_check: "Public Safety Check",
  sex_offender_registry_check: "Sex Offender Registry Check",
  global_clearance_check: "Global Clearance Check",
  other_searches: "Other Searches",
  public_profile_scan: "Public Profile Scan"
};

export const CanadianCheckList = ({ worker }) => {
  /**
   * `items` is an Array of:
   *    {
   *      label: "Label of the Check",
   *      data: {} // data as returned by worker.canadianReport.report_data.{section}
   *    }
   */
  const items = useMemo(() => {
    return _.map(checkListKeys, (label, key) => {
      return {
        label,
        data: _.get(worker, `canadianReport.report_data.${key}`)
      };
    });
  }, [worker]);

  if (!_.get(worker, "report_parameters.do_canadian_softcheck")) {
    return null;
  }

  return _.map(items, (item, i) => (
    <CanadianCheckListItem
      key={i}
      label={item.label}
      data={item.data || []}
      status={worker.onBoardingStatus}
    />
  ));
};
