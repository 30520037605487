import React from "react";
import { TextField, withStyles } from "@material-ui/core";

export const styles = {
  root: {
    borderRadius: 25,
    border: "solid 1px #bfcddd",
    backgroundColor: "#f7f9fc",
    padding: "7px 17px",
    fontSize: "12px",
    fontWeight: "bold"
  },
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#5f7d95"
    }
  },
  hintStyle: {
    padding: "0 17px",
    zIndex: 1,
    pointerEvents: "none"
  }
};

export const WorkerFilterTextFieldComponent = _props => {
  const { classes, ...props } = _props;

  return (
    <TextField
      disableUnderline={true}
      autocomplete="nope"
      InputProps={{
        disableUnderline: true,
        classes: { root: classes.root, input: classes.input }
      }}
      {...props}
    />
  );
};

export const WorkerFilterTextField = withStyles(styles)(
  WorkerFilterTextFieldComponent
);
