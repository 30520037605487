import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { ReactComponent as CloseIcon } from "../../icons/close-icon-black.svg";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { styles } from "./styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { turnAPI } from "../../services";
import { CircularProgress } from "material-ui";
import moment from "moment";
import { withStyles } from "@material-ui/styles";
import { observer } from "mobx-react";
import teamMember from "../../models/team-member";
import Column from "../../components/Column";
import Row from "../../components/Row";
import DatePickerCSV from "../../components/DatePickerCSV";
import env from "@mars/heroku-js-runtime-env";

const propTypes = {
  dispatch: PropTypes.func,
  showCSVModal: PropTypes.bool,
  model: PropTypes.object
};

const styling = () => ({
  cancelButton: {
    "&:hover": {
      backgroundColor: "white"
    }
  }
});

const DownloadCSVModal = observer(
  class CsvModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: "",
        loading: false,
        months: [],
        memberModel: teamMember,
        monthsRequired: teamMember.hasBeenPartnerFor
      };
    }

    handleClose = () => {
      this.props.dispatch({
        type: "SHOW_CSV_MODAL",
        payload: {
          showCSVModal: false
        }
      });
      this.setState({ error: "" });
    };

    handleDateFromChange = event => {
      // It would make more sense to use state for this, but using the model prevents a Firefox bug

      this.props.model.setCSVSelectedFromDate(event);

      this.setState({ error: "" });
    };

    handleDateToChange = event => {
      this.props.model.setCSVSelectedToDate(event);

      this.setState({ error: "" });
    };

    handleSubmit = async () => {
      if (
        !this.props.model.csvSelectedToDate ||
        !this.props.model.csvSelectedFromDate
      ) {
        this.setState({ error: "Please select a date range." });
        return;
      }

      const startDate = this.props.model.csvSelectedFromDate.trim();
      const endDate = this.props.model.csvSelectedToDate.trim();

      // in case env var doesn't exists we use the default hardcoded value (90)
      const ALLOWED_DAYS = env().REACT_APP_MAX_ALLOWED_DAYS_REPORT || 90;

      if (moment(endDate).diff(moment(startDate), "days") > ALLOWED_DAYS) {
        this.setState({
          error: `Date out of range. Only ${ALLOWED_DAYS} days maximum is allowed per request.`
        });
        return;
      }

      const member = localStorage.getItem("member") || null;
      const memberEmail = member ? JSON.parse(member) : undefined;

      this.setState({ loading: true });
      const response = await turnAPI.postCSVReport({
        start: moment(startDate).format("YYYY-MM-DD"),
        end: moment(endDate).format("YYYY-MM-DD")
      });

      switch (response.status) {
        case 200:
          this.props.model.setWorkerToastr({
            success: true,
            error: null,
            message:
              memberEmail && memberEmail.email
                ? `Your report has been sent to ${memberEmail.email}`
                : "Your report has been sent to the email on file."
          });
          this.handleClose();
          break;
        default:
          this.props.model.setWorkerToastr({
            success: null,
            error: true,
            message: ""
          });
          this.handleClose();
          break;
      }

      this.setState({ loading: false });
    };

    componentDidMount() {
      this.handleDateFromChange(moment().format("YYYY-MM-DD"));
      this.handleDateToChange(moment().format("YYYY-MM-DD"));
    }
    render() {
      const { showCSVModal, classes } = this.props;

      return (
        <Dialog
          open={showCSVModal}
          onClose={this.handleClose}
          autoScrollBodyContent
          disableBackdropClick
          disableEscapeKeyDown
        >
          <CloseIcon
            onClick={this.handleClose}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "1rem",
              top: "1rem",
              width: 10,
              height: 10,
              zIndex: 99
            }}
          />
          <DialogContent>
            <div style={styles.modal.messageContainer}>
              <div style={styles.modal.checkIconContainer}>
                {this.state.loading && (
                  <CircularProgress
                    style={styles.modal.progress}
                    size={80}
                    thickness={5}
                    color="#7364DB"
                  />
                )}
              </div>

              <div style={styles.modal.bodyContainer}>
                <FormattedMessage {...messages.csvModalBody} />
                <Row>
                  <Column
                    textAlign="left"
                    textAlignSM="center"
                    width="43%"
                    margin="5px 0 0 20px"
                  >
                    <DatePickerCSV
                      onChange={e => this.handleDateFromChange(e)}
                      value={
                        this.props.model.csvSelectedFromDate ||
                        moment().format("YYYY-MM-DD")
                      }
                      label={messages.csvModalTitleFrom}
                    />

                    <div></div>
                  </Column>

                  <Column
                    textAlign="left"
                    textAlignSM="center"
                    width="43%"
                    margin="5px 0 0 20px"
                  >
                    <div>
                      <DatePickerCSV
                        onChange={e => this.handleDateToChange(e)}
                        value={
                          this.props.model.csvSelectedToDate ||
                          moment().format("YYYY-MM-DD")
                        }
                        label={messages.csvModalTitleTo}
                      />
                    </div>
                  </Column>
                </Row>
                <Row>
                  <Column padding="10px" width="100%">
                    {this.state.error && (
                      <div style={styles.modal.csvModalError}>
                        {this.state.error}
                      </div>
                    )}
                  </Column>
                </Row>
              </div>
            </div>
          </DialogContent>
          <div className="" style={styles.modal.actionContainer}>
            <DialogActions>
              <Button
                onMouseDown={this.handleClose}
                id="close_modal"
                className={classes.cancelButton}
                style={styles.modal.cancelButton}
              >
                Cancel
              </Button>
            </DialogActions>
            <DialogActions style={{ marginBottom: "10px" }}>
              <Button
                onClick={this.handleSubmit}
                id="submit_modal"
                style={styles.modal.nextButton}
                disabled={this.state.loading}
                className="submit_csv"
              >
                Submit
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      );
    }
  }
);

DownloadCSVModal.propTypes = propTypes;

export default withStyles(styling)(DownloadCSVModal);
