import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { observer } from "mobx-react";
import _ from "lodash";
import { DetailDisplay } from "../DetailDisplay";
import { EmailLabel } from "./label";
import { workerEmailToolbarStyles as styles } from "./styles";
import { availableStatuses } from "turn-shared";

const { EMAILED } = availableStatuses;

const WorkerEmailToolbar = observer(
  class WorkerEmailToolbar extends Component {
    static propTypes = {
      worker: PropTypes.object.isRequired
    };

    render() {
      const { worker } = this.props;
      if (_.isEmpty(worker) || worker.status !== EMAILED) return null;

      const { numberOfTimesOpened, numberOfTimesClicked } = worker;
      return (
        <div style={styles.container}>
          <DetailDisplay
            icon={<i className="fas fa-envelope" style={styles.icon} />}
            detail={
              <EmailLabel text={numberOfTimesOpened} style={styles.label} />
            }
            containerStyle={styles.detailContainerStyle}
          />
          <DetailDisplay
            icon={<i className="fas fa-hand-pointer" style={styles.icon} />}
            detail={
              <EmailLabel
                text={`${numberOfTimesClicked}`}
                style={styles.label}
              />
            }
          />
        </div>
      );
    }
  }
);

export default WorkerEmailToolbar;
