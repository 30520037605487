import styled from "styled-components";

// prettier-ignore
const AddressTableContainer = styled.div`
  overflow: hidden;
  overflow-x: auto;

  tbody tr:not(:first-child) {
    display: ${props => (props.showAll ? "table-row" : "none !important")};
    @media print {
      display: table-row;
    }
  }
  td {
    @media print {
      overflow: visible !important;
      white-space: normal !important;
      text-overflow: inherit !important;
      vertical-align: top !important;
    }
  }
`;

export default AddressTableContainer;
