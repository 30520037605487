import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import lodash from "lodash";
import { CandidateReportStyles as reportStyles } from "../../../styles";
import AddressTable from "../../../components/AddressTable";
import { config } from "../../../data/addresses-mock";

import messages from "../messages";
import PanelCollapsible from "../../../components/PanelCollapsible";
import { green } from "../../../styles/constants";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import withContext from "../../../contextAPI/context-HOC";

export * from "./CanadaWorkerAddresses";

const WorkerAddresses = observer(
  ({
    addresses,
    showSSNTrace,
    showAllAddresses,
    setShowAllAddresses,
    context: { checks } = {}
  }) => {
    const sanitizedAddresses = lodash.filter(addresses, address => {
      // Make sure to keep this array in sync with `AddressTable` attributes it uses from address
      for (const key of ["address1", "city", "zip", "state"]) {
        const val = lodash.get(address, key);
        if (lodash.isEmpty(val)) {
          return false;
        }
      }

      return true;
    });

    const showExpandAll =
      lodash.isArray(addresses) && addresses.length > 1 ? true : false;

    return !lodash.isEmpty(sanitizedAddresses) && showSSNTrace ? (
      <PanelCollapsible
        title={messages.addressTitle}
        titleStyle={reportStyles.title}
        iconStyle={reportStyles.checkedIcon}
        color={green}
        icon={faCheckCircle}
        collapsible
        expandAll={showExpandAll}
        expandAllProps={{
          onClick: () => {
            setShowAllAddresses(!showAllAddresses);
          },
          toggled: showAllAddresses
        }}
        expandAllChecks={checks.expandAllChecks}
        panelProps={{
          style: {
            filter:
              "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
          }
        }}
      >
        <AddressTable
          showAll={showAllAddresses}
          setShowAll={setShowAllAddresses}
          addresses={sanitizedAddresses}
          config={config}
        />
      </PanelCollapsible>
    ) : null;
  }
);
WorkerAddresses.propTypes = {
  addresses: PropTypes.array.isRequired,
  showSSNTrace: PropTypes.bool.isRequired,
  showAllAddresses: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
};

export default withContext(WorkerAddresses);
