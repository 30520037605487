import React from "react";
import styles from "./no-features-yet-styles";
import { ReactComponent as UpdateComingIcon } from "../../icons/update-coming.svg";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const NoFeaturesYet = () => {
  return (
    <div style={styles.wrapper}>
      <article style={styles.box}>
        <div style={styles.iconContainer}>
          <UpdateComingIcon style={styles.icon} />
        </div>
        <div style={styles.mainTextContainer}>
          <div style={styles.mainText}>
            <FormattedMessage {...messages.mainText} />
          </div>
        </div>
        <div style={styles.secondaryTextContainer}>
          <p style={styles.secondaryText}>
            <FormattedMessage {...messages.secondaryText} />
          </p>
        </div>
      </article>
    </div>
  );
};

export default NoFeaturesYet;
