import { string } from "prop-types";
import React from "react";
import { RegisterButtonContainer, WhiteButton } from "./styled";

const ProductButtons = ({ asPath }) => {
  return (
    <RegisterButtonContainer>
      <WhiteButton href={`/upgrade?redirect_url=${asPath}`}>
        Upgrade now
      </WhiteButton>
    </RegisterButtonContainer>
  );
};

ProductButtons.propTypes = {
  asPath: string
};

export default ProductButtons;
