import React from "react";
import { PropTypes } from "prop-types";
import { ListDetailStyles as styles } from "../../../styles";
import { red, primary } from "../../../styles/constants";
import SubTitle from "../../../components/SubTitle";

const props = {
  display: "inline-block",
  margin: "0 0 0 30px"
};

const Count = ({ count, success }) => {
  if (count === 0) return null;
  const color = count > 0 && !success ? red : primary;
  return (
    <SubTitle color={color} {...props}>
      <span style={styles.count}>{count}</span>
    </SubTitle>
  );
};
Count.propTypes = {
  count: PropTypes.number.isRequired,
  success: PropTypes.bool
};

export default Count;
