import { white, primary, aliceBlue, slateGray } from "../constants";

const styles = {
  logoContainer: {
    height: "100px",
    background: white,
    border: "0.5px solid #e5e7eb",
    paddingLeft: "21px"
  },
  image: {
    width: 100,
    margin: "25px 30px"
  },
  button: {
    color: white
  },
  divider: {
    position: "absolute",
    width: "100%",
    left: 0
  },
  header: {
    paddingTop: "2em"
  },
  footer: {
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "left",
      position: "relative",
      padding: "0em 0em 2em"
    },
    left: {
      display: "inline-block"
    },
    right: {
      display: "inline-block"
    },
    label: {
      fontSize: "14px",
      margin: 0
    },
    hint: {
      fontSize: "14px",
      fontWeight: "bold",
      color: primary,
      margin: 0,
      cursor: "pointer"
    },
    button: {
      color: white,
      borderRadius: 20,
      minWidth: 140,
      marginBottom: 25
    },
    buttonContainer: {
      flexBasis: "100%",
      display: "flex",
      justifyContent: "center"
    }
  },
  support: {
    container: {
      width: "100%",
      margin: "auto",
      display: "flex",
      justifyContent: "center"
    },
    email: {
      color: primary,
      textDecoration: "none"
    }
  },
  cardList: {
    option: {
      divider: {
        flex: "100%"
      },
      hint: {
        color: aliceBlue
      },
      icon: {
        width: "3em",
        height: "3em",
        color: primary
      },
      left: {
        display: "inline-block",
        padding: "40px 0"
      },
      right: {
        display: "inline-block"
      }
    }
  },
  cardForm: {
    option: {
      container: {
        padding: "45px 0",
        display: "flex"
      },
      hint: {
        color: slateGray,
        marginRight: "1em"
      },
      error: {
        display: "flex",
        color: "red",
        fontWeight: "bolder",
        fontSize: "12px",
        position: "absolute",
        bottom: "-22px",
        margin: "0",
        width: "200px"
      },
      inputContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: 25,
        position: "relative"
      },
      input: {
        textAlign: "center"
      },
      iconContainer: {
        flex: 2,
        textAlign: "right"
      },
      textContainer: {
        flex: 3
      },
      icon: {
        height: "60px",
        width: "60px",
        display: "inline-flex",
        borderRadius: "50%",
        background: primary,
        alignItems: "center",
        justifyContent: "center",
        marginRight: "25px"
      }
    }
  },
  error: {
    content: {
      textAlign: "center",
      padding: "5em 0",
      width: "65%",
      margin: "auto"
    },
    image: {
      maxWidth: "200px"
    }
  }
};
export default styles;
