import styled from "styled-components";

const DetailContainer = styled.div`
  background-color: ${({ secondary }) =>
    secondary ? "rgb(248, 248, 248)" : "inherit"};
  display: block;
  overflow: auto;
  width: 100%;
  font-size: 1rem;
  padding: ${({ padding, simple, inline, inner }) => {
    if (padding) return padding;
    if (simple) return "10px 6%";
    if (inline) return inner ? "0 6%" : "16px 6%";
    return "10px";
  }};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "1em" : "none")};
  border-top: ${({ borderTop }) =>
    borderTop ? "1px solid rgb(224, 224, 224)" : "none"};
`;

export default DetailContainer;
