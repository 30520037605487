import {
  primary,
  aliceBlue,
  black,
  white,
  smokeWhite,
  secondary
} from "../constants";

const text = {
  fontWeight: "normal"
};

const style = {
  dialogTitle: {
    marginTop: "30px",
    marginBottom: "10px",
    fontWeight: "bold",
    fontSize: "20px",
    color: primary,
    textAlign: "center"
  },
  turnID: {
    fontSize: "14px",
    fontWeight: "bold",

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    color: primary
  },
  badgeStatus: {
    height: "85px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    borderBottom: `1px solid ${aliceBlue}`
  },
  phoneNumber: {
    height: "49px",

    flexDirection: "row",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  phoneNumberIcon: {
    fontSize: "14px",
    color: primary,

    flex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  phoneNumberText: {
    color: primary,
    fontSize: "12px",

    flex: 90,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  listItem: {
    height: "49px",

    flexDirection: "row",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderBottom: `1px solid ${aliceBlue}`
  },
  listItemIcon: {
    fontSize: "14px",
    color: primary,

    flex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  listItemText: {
    color: primary,
    fontSize: "12px",

    flex: 90,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    ...text
  },
  checkReport: {
    height: "59px",
    color: primary,

    fontSize: "14px",
    fontWeight: "bold",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",

    borderBottom: `1px solid ${aliceBlue}`
  },
  listItemSplited: {
    height: "49px",
    fontSize: "12px",

    flexDirection: "row",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderBottom: `1px solid ${aliceBlue}`
  },
  firstElement: {
    flex: 5,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  secondElement: {
    color: primary,

    flex: 5,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    ...text
  },
  listItemIconSplitedFirstElement: {
    fontSize: "14px",
    color: "#34de82",

    flex: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  listItemTextSplitedFirstElement: {
    color: primary,

    flex: 80,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    ...text
  },
  buttonsContainer: {
    height: "76px",

    display: "flex",
    flexDirection: "row"
  },
  buttonDownloadContainer: {
    paddingRight: "10px",

    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  buttonViewDetailsContainer: {
    paddingLeft: "10px",

    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  blackButton: {
    width: "139px",
    height: "40px",
    backgroundColor: primary,
    color: white
  },
  downloadButton: {
    width: "139px",
    height: "40px",
    color: primary
  },
  detailsButton: {
    width: "139px",
    height: "40px",
    backgroundColor: secondary,
    color: white
  },
  downloadIcon: {
    marginRight: "5px"
  },
  ovalContainer: {
    backgroundColor: black,
    width: "60px",
    height: "60px",
    borderRadius: "50%",

    position: "absolute",
    top: "-30px",
    left: "180px"
  },
  ovalFlexContainer: {
    height: "60px",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  ovalIcon: {
    color: smokeWhite,
    fontSize: "24px"
  },

  closeBtnContainer: {
    position: "absolute",
    top: "13px",
    right: "18px",
    zIndex: 3,
    backgroundColor: white
  },
  closeBtnFlexContainer: {
    height: "60px",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  closeWindowButton: {
    color: black,
    fontSize: "14px",
    fontWeight: "bold",
    width: "65px",
    height: "10px",
    backgroundColor: "transparent"
  },
  progressContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    textAlign: "center",
    padding: 20,
    opacity: 0.5,
    top: 0,
    left: 0,
    zIndex: 2
    // background: "white"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  }
};

export default style;
