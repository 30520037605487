import React from "react";
import { PropTypes } from "prop-types";
import { rejectedCandidateStyles as styles } from "./styles";
import _ from "lodash";
import { SearchHighlighted } from "../../../components/SearchHighlighted";
import { reformatDate } from "../../../utils/momentParse";

const RejectedNotes = ({ notes }) => {
  const { notesStyles } = styles;
  const getSubtitle = note => {
    const { created_at, user } = note;
    return `by ${user} ${created_at ? `on ${reformatDate(created_at)}` : ""}`;
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: "6px" }}>
      {!_.isEmpty(notes) &&
        notes.map((note, index) => (
          <div style={notesStyles.noteContainer} key={index}>
            <p id="rejectionNote" style={notesStyles.note}>
              <SearchHighlighted textToHighlight={note.note} />
            </p>
            <span id="rejectionNoteDate" style={notesStyles.user}>
              {getSubtitle(note)}
            </span>
          </div>
        ))}
    </div>
  );
};
RejectedNotes.propTypes = {
  notes: PropTypes.array
};

export default RejectedNotes;
