import styled from "styled-components";
import { BREAKPOINT_SM } from "../../constants";
import Container from "../Container";

export const CandidateTileContainer = styled(Container)`
  background-color: white;
  padding: 0.5rem 2rem;
  text-align: "left";
  width: 380px;
  min-height: 360px;
  max-height: 440px;

  @media (max-width: ${BREAKPOINT_SM}px) {
    width: 100%;
  }

  ul {
    list-style: none;
    margin-top: 0;
  }

  .candidate-info {
    color: #708392;
    font-weight: 500;
    padding: 0;
  }

  .candidate-info > li > span {
    font-weight: 300;
  }

  .candidate-checks {
    font-size: 0.9rem;
    color: #708392;
    padding-left: 0;
    text-transform: capitalize;
  }
`;
