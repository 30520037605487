import styled from "styled-components";

const FilterAndTableContainer = styled.div`
  display: inline-flex;
  margin-left: 11px;
  margin-top: 30px;
  width: 100%;
  min-height: 89vh;
  @media (max-width: 1500px) {
    overflow-x: scroll;
  }
`;

export default FilterAndTableContainer;
