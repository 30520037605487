import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  faCheckCircle,
  faExclamationTriangle
} from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";

import PanelCollapsible from "../../../components/PanelCollapsible";
import renderWorkerApproval from "../../../utils/render-worker-approval";
import { CandidateReportStyles as reportStyles } from "../../../styles";
import { red, green } from "../../../styles/constants";
import CheckList from "../../../components/CheckList";
import withContext from "../../../contextAPI/context-HOC";

export * from "./CanadianCheckListItem";

export class ChecksList extends Component {
  static propTypes = {
    worker: PropTypes.object,
    context: PropTypes.shape({
      checks: PropTypes.object
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      expand: false
    };
  }

  setExpand = expand => {
    this.setState({ expand });
  };

  toggleExpand = () => {
    this.setState(prevProps => ({ expand: !prevProps.expand }));
  };

  render() {
    const { checks = {} } = this.props.context;
    const allItems = this.props.worker.checks || [];

    // Using same criteria than CheckList
    if (
      !Array.isArray(allItems) ||
      allItems.length === 0 ||
      _.isEmpty(allItems.filter(item => item.value)) ||
      this.props.worker.workerIsPendingReview
    ) {
      return null;
    }
    return allItems.map((item, key) => {
      const value = item.value;
      if (!value) return null;
      const clear = value.length === 0;

      return (
        <PanelCollapsible
          title={{
            id: `app.containers.WorkerContainer.${_.upperFirst(
              _.camelCase(item.label)
            )}.title`,
            defaultMessage:
              _.lowerCase(item.label) === "criminal records"
                ? "National Criminal"
                : item.label
          }}
          titleStyle={reportStyles.title}
          iconStyle={reportStyles.checkedIcon}
          color={clear ? green : red}
          icon={clear ? faCheckCircle : faExclamationTriangle}
          collapsible={!clear}
          key={key}
          expandAll
          expandAllProps={{
            onClick: this.toggleExpand,
            toggled: this.state.expand
          }}
          setExpand={this.setExpand}
          expandAllChecks={checks.expandAllChecks}
          panelProps={{
            style: {
              filter:
                "drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))"
            }
          }}
        >
          <CheckList
            worker={this.props.worker}
            items={[item]}
            renderCheck={renderWorkerApproval}
            uuid={this.props.worker.id}
            expand={this.state.expand}
            noCollapse
          />
        </PanelCollapsible>
      );
    });
  }
}

export default withContext(ChecksList);
