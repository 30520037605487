import React, { useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { PropTypes } from "prop-types";
import { CaptchaContainer } from "./CaptchaContainer";
import { autorun } from "mobx";

export default function Captcha({ model }) {
  const captchaRef = useRef(null);

  const handleOnChange = () => {
    const token = captchaRef.current.getValue();
    model.setCaptchaToken(token);
  };

  const handleExpired = () => {
    model.setCaptchaToken("");
  };

  useEffect(() => {
    autorun(() => {
      if (model.captchaError) {
        captchaRef.current.reset();
        model.setCaptchaToken("");
      } else if (model.captchaReset === "reset") {
        captchaRef.current.reset();
        model.setCaptchaToken("");
        model.setCaptchaReset("");
      }
    });
  }, []);

  return (
    <CaptchaContainer>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        ref={captchaRef}
        onChange={() => handleOnChange()}
        onExpired={() => handleExpired()}
      />
    </CaptchaContainer>
  );
}

Captcha.propTypes = {
  model: PropTypes.object
};
