import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { CandidateReportStyles as styles } from "../../../styles";
import { FormSignature } from "../../../components/LandingPage/components/FormSignature";
import { FormCheckLine } from "../../../components/LandingPage/components/FormCheckLine";
import messages from "../../../components/BackgroundReport/messages";
import { Button } from "../../../components/Button";
import Text from "../../../components/Text";

import FooterContent from "./footer-content";

const Form = observer(
  class Form extends Component {
    setName = e => {
      this.props.model.setName(e.target.value);
    };
    setSignaturePad = pad => {
      this.signaturePad = pad;
    };
    clear = () => {
      this.props.model.setConfirm(false);
      this.props.model.setCertify(false);
      this.props.model.setSigned(false);
      this.props.model.setName("");
      this.props.model.storeImage("");
    };
    storeImage = () => {
      const dataURL = this.signaturePad.toDataURL();
      this.props.model.storeImage(dataURL);
    };
    render() {
      if (!this.props.model) return null;
      return (
        <FooterContent left>
          <div style={{ position: "relative" }}>
            <FormSignature details={this.props.model} />
          </div>
          <FormCheckLine
            label={
              <Text>
                <FormattedMessage {...messages.certify} />
              </Text>
            }
            checked={this.props.model.certify}
            onChange={() =>
              this.props.model.setCertify(!this.props.model.certify)
            }
          />
          <div style={styles.inputContainer}>
            <Button
              id="submitButton"
              variant="contained"
              color="secondary"
              disabled={!this.props.model.footerReady}
              onClick={this.props.onSubmit}
              style={styles.input}
            >
              Submit
            </Button>
          </div>
        </FooterContent>
      );
    }
  }
);

Form.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func
};

export default Form;
