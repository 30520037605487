import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FormattedHeader from "../FormattedHeader";
import BadgeContainer from "./badge-container";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

class Badge extends PureComponent {
  render() {
    const { fontSize = "14px" } = this.props;
    return this.props.status || this.props.score ? (
      <BadgeContainer
        display="flex"
        justify="flex-start"
        alignItems="center"
        {...this.props}
      >
        {this.props.score ? (
          `Score: ${this.props.score}`
        ) : (
          <>
            {!this.props.hideIcon && (
              <FontAwesomeIcon
                icon={faCircle}
                style={{ fontSize: fontSize, marginRight: "17px" }}
              />
            )}
            <div>
              <FormattedHeader
                style={{ fontSize: fontSize }}
                message={this.props.status}
                inherit
              />
              {!!this.props.toolbar && this.props.toolbar}
            </div>
          </>
        )}
        {(this.props.status === "processing" ||
          this.props.status === "review") &&
        !this.props.disableElipses
          ? "..."
          : ""}
      </BadgeContainer>
    ) : null;
  }
}

Badge.propTypes = {
  fontSize: PropTypes.string,
  status: PropTypes.string,
  score: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  hideIcon: PropTypes.bool,
  disableElipses: PropTypes.bool,
  toolbar: PropTypes.node
};

export default Badge;
