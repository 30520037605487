/*
    In a demo environment, only a couple of real partner workers will exist.

    To make the page look a bit busier, we will multiply the existing workers
    so that each tile is clickable.

    Each duplicated worker will be given a random name.
*/
import demoWorkerResponse from "./demoWorkerResponse";
import getRandomFirstName from "./getRandomLastName";
import getRandomLastName from "./lastNameArray";
import { v4 as uuidv4 } from "uuid";

export default function multiplyDemoWorkers(data) {
  const realWorkers = data.items;
  const multipliedWorkers = [...realWorkers];

  for (let i = 0; i < 10; i++) {
    realWorkers.forEach(worker => {
      const first_name = getRandomFirstName();
      const last_name = getRandomLastName();
      const display_name = `${first_name} ${last_name}`;
      multipliedWorkers.push({
        ...worker,
        first_name,
        last_name,
        display_name,
        id: uuidv4() // id is used as the key for the tiles so we need a new one here
      });
    });
  }

  return {
    ...demoWorkerResponse,
    items: multipliedWorkers
  };
}
