import React from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import moment from "moment";

import { secondary, slateGray } from "../../styles/constants";

export const ScreeningResponsiveLine = ({ data }) => {
  const tooltipFormatter = (value, name) => {
    switch (name) {
      case "Daily Spend":
        return `$${value.toLocaleString()}`;
      case "Date":
        return moment(value)
          .toDate()
          .toLocaleDateString();
      default:
        return value;
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <CartesianGrid horizontal={true} vertical={true} />
        <XAxis
          xAxisId="date"
          dataKey="date"
          name="Date"
          tickFormatter={tick =>
            moment(tick)
              .toDate()
              .toLocaleDateString()
          }
        />
        <YAxis
          yAxisId="left"
          tickFormatter={tick => `$${tick.toLocaleString()}`}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          allowDecimals={false}
          tickFormatter={tick => tick.toLocaleString()}
        />
        <Line
          name="Daily Spend"
          type="basis"
          dot={false}
          yAxisId="left"
          xAxisId="date"
          dataKey="spend"
          strokeWidth={3}
          stroke={slateGray}
        />
        <Line
          name="Completed Checks"
          type="basis"
          dot={false}
          yAxisId="right"
          xAxisId="date"
          dataKey="total_checks"
          strokeWidth={3}
          stroke={secondary}
        />
        <Tooltip
          labelFormatter={value =>
            moment(value)
              .toDate()
              .toLocaleDateString()
          }
          formatter={tooltipFormatter}
        />
        <Legend
          iconType="circle"
          align="center"
          verticalAlign="bottom"
          layout="vertical"
          iconSize={12}
          wrapperStyle={{ fontSize: 12 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
ScreeningResponsiveLine.propTypes = {
  data: PropTypes.array
};
