import React from "react";
import { PropTypes } from "prop-types";
import { TableRow, TableRowColumn } from "material-ui/Table";
import TextField from "@material-ui/core/TextField";
import { FormattedMessage } from "react-intl";
import Highlighted from "../Highlighted";
import { TableStyles as styles } from "../../styles";
import GoToPageButton from "../GoToPageButton";

import messages from "./messages";
import { MenuItem } from "material-ui";
import { Select } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-solid-svg-icons";

import { aliceBlue, primary, secondary } from "../../styles/constants";

class WorkerTableFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputTimeout: null,
      prevTimeout: null,
      nextTimeout: null,
      clicks: 0
    };
  }
  onPageSize = async event => {
    const value = event.target.value || event.currentTarget.value;
    await this.props.setLimit(value);
    this.props.onPageNumber(Math.round(this.props.page));
  };

  onPageSizeClick = async e => {
    await this.props.setLimit(e.currentTarget.value);
    this.props.onPageNumber(Math.round(this.props.page));
  };

  onPageNumber = (_, page) => {
    this.props.onPageNumber(page);
  };

  onNext = () => {
    const { nextTimeout, clicks } = this.state;

    if (this.shouldNext()) {
      clearTimeout(nextTimeout);
      this.setState({
        clicks: clicks + 1,
        nextTimeout: this.getButtonTimeout()
      });
    }
  };

  onPrev = () => {
    const { prevTimeout, clicks } = this.state;

    if (this.shouldPrev()) {
      clearTimeout(prevTimeout);
      this.setState({
        clicks: clicks - 1,
        prevTimeout: this.getButtonTimeout()
      });
    }
  };

  onInput = e => {
    let page = e.target.value;
    if (page <= 0) {
      page = 1;
    }

    const { inputTimeout } = this.state;

    clearTimeout(inputTimeout);
    this.setState({
      inputTimeout: this.getInputTimeout(e.target, page)
    });
  };

  getButtonTimeout = () =>
    setTimeout(() => {
      const { clicks } = this.state;
      const { page } = this.props;
      const toPage = Math.round(page + clicks);

      this.onPageNumber(null, toPage < 1 ? 1 : toPage);
      this.setState({
        clicks: 0
      });
    }, 500);

  getInputTimeout = (input, placeholder) =>
    setTimeout(() => {
      input.value = "";
      input.placeholder = placeholder;
      this.onPageNumber(null, placeholder);
    }, 500);

  getRowValue = value => <Highlighted>{value}</Highlighted>;

  shouldPrev = () => this.props.page > 1;

  shouldNext = () => this.props.page < this.props.pageLimit;

  shouldInput = () => this.props.pageLimit === 1;

  render() {
    const { clicks } = this.state;

    // No results is `pageLimit` set to zero
    const hintText = this.props.pageLimit > 0 ? this.props.page + clicks : 0;

    return (
      <TableRow style={styles.footerRow}>
        <TableRowColumn style={styles.footerColumn}>
          <div
            style={{
              flex: 1,
              display: "inline-flex",
              justifyContent: "flex-start",
              alignItems: "center"
            }}
          >
            <Highlighted size="14px">
              {messages.showRows.defaultMessage}
            </Highlighted>
            <Select
              renderValue={() => this.getRowValue(this.props.pageSize)}
              value={this.props.pageSize}
              onChange={this.onPageSize}
              style={{
                margin: "0 1rem",
                width: "90px",
                height: "36px",
                borderRadius: "4px",
                border: 0,
                backgroundColor: "#e1e8f3",
                textAlign: "center"
              }}
              className={"MuiInput-multiline-25"}
            >
              {[20, 50, 100].map(value => (
                <MenuItem
                  value={value}
                  key={value}
                  primaryText={this.getRowValue(value)}
                />
              ))}
            </Select>
            {/* <Button
              key={50}
              value={50}
              // value={this.props.pageSize}
              onClick={this.onPageSize}
              style={styles.paginationBtnEnabled}
            >
              50
            </Button>
            <Button
              key={100}
              value={100}
              // value={this.props.pageSize}
              onClick={this.onPageSize}
              style={styles.paginationBtnDisabled}
            >
              100
            </Button> */}
          </div>
          <div
            style={{
              flex: 1,
              display: "inline-flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <GoToPageButton onClick={this.onPrev} disabled={!this.shouldPrev()}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ fontSize: "14px", color: secondary }}
              />
            </GoToPageButton>

            <div>
              <Highlighted
                style={{
                  marginRight: "12px"
                }}
              >
                <FormattedMessage {...messages.page} />
              </Highlighted>
              <TextField
                size="small"
                placeholder={`${hintText < 0 ? 0 : hintText}`}
                onChange={e => this.onInput(e)}
                disabled={this.shouldInput()}
                style={styles.footerInput}
                inputProps={{ style: { textAlign: "center" } }}
                InputProps={{
                  style: {
                    border: 0,
                    borderRadius: "4px",
                    paddingRight: 0,
                    paddingLeft: 0,
                    backgroundColor: aliceBlue,
                    color: primary
                  }
                }}
              />
              <Highlighted
                style={{
                  marginLeft: "12px"
                }}
              >
                <FormattedMessage {...messages.of} /> {this.props.pageLimit}
              </Highlighted>
            </div>

            <GoToPageButton onClick={this.onNext} disabled={!this.shouldNext()}>
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ fontSize: "14px", color: secondary }}
              />
            </GoToPageButton>
          </div>
        </TableRowColumn>
      </TableRow>
    );
  }
}

WorkerTableFooter.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  pageLimit: PropTypes.number,
  onPageSize: PropTypes.func,
  onPageNumber: PropTypes.func,
  setLimit: PropTypes.func
};

export default WorkerTableFooter;
