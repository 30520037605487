import { create } from "apisauce";
import { CancelToken } from "axios";
import env from "@mars/heroku-js-runtime-env";
import _ from "lodash";

export const apiaryURL =
  "https://private-c5ec3-turnworkersapi.apiary-mock.com/";
export const turnURL = env().REACT_APP_API_URL;
export const twilioURL = "https://lookups.twilio.com/v1";

const OAUTH_BASE_URL = process.env.REACT_APP_OAUTH_BASE_URL;
// Should be the concent url for each environment like: https://consent.turn.ai/
export const CONSENT_FLOW_URL = process.env.REACT_APP_CONSENT_FLOW_URL;

const cancelableRequests = [];

class BaseAPI {
  loading = {};

  constructor(baseURL = turnURL) {
    this.api = create({
      baseURL,
      headers: {
        Accept: "application/json"
      }
    });
  }

  cancelRequests() {
    cancelableRequests.forEach(source => source.cancel());
    cancelableRequests.splice(0, cancelableRequests.length);
  }

  /**
   * Setter function for loading state, in order to prevent
   * a scenario where two request would compete against one
   * another we're using a key-value for each request
   *
   * @param {boolean} loading determines if another request shouldn't be made
   * @param {string} key determines the request name
   */
  setLoading = (loading, key) => {
    if (!_.isBoolean(loading) || !_.isString(key)) return;
    this.loading[key] = loading;
  };
  /**
   * Handler function for each request, while the request is being made
   * it is set to true in the loading state to prevent further request from being made
   *
   * @param {function} request method to be called
   * @param {string} endpoint endpoint to be called
   * @param {any} params params for request
   * @param {string} key determines
   */
  handleRequest = (request, endpoint, params, key) => {
    if (!_.isFunction(request) || !_.isString(key) || this.loading[key])
      return false;
    this.setLoading(true, key);
    return request(endpoint, params).then(response => {
      this.setLoading(false, key);
      return response;
    });
  };
  setToken(token) {
    if (!token) return this;
    this.api.setHeaders({ Authorization: `Bearer ${token}` });
    return this;
  }
  setStatusPreference(status) {
    if (!status) return this;
    this.api.setHeaders({ Prefer: `status=${status}` });
    return this;
  }
  approveWorker = (id, params) =>
    this.handleRequest(
      this.api.put,
      `/partners/me/workers/${id}/approve`,
      params,
      "approveWorker"
    );
  withdrawWorker = (id, params) =>
    this.handleRequest(
      this.api.post,
      `/partners/me/workers/${id}/withdraw`,
      params,
      "withdrawWorker"
    );
  rejectWorker = (id, params) =>
    this.handleRequest(
      this.api.put,
      `/partners/me/workers/${id}/reject`,
      params,
      "rejectWorker"
    );
  getMember = () => this.api.get("/partners/me");
  getSettings = () => this.api.get("/partners/settings");
  getReport = () => this.api.get("/partners/report");
  postCSVReport = params => this.api.post("/partners/report", params);
  getWorkerSourcingReport = () =>
    this.api.get("/partners/worker_sourcing_report");
  getWorkers = params => {
    this.cancelRequests();

    const source = CancelToken.source();
    cancelableRequests.push(source);
    return this.api.get(
      `/partners/me/workers?query=${JSON.stringify(params)}`,
      {},
      { cancelToken: source.token }
    );
  };
  getPendingWorkers = params =>
    this.api.get(`/cohort/candidates?query=${JSON.stringify(params)}`);

  getWorker = id => {
    const source = CancelToken.source();
    cancelableRequests.push(source);
    return this.api.get(
      `/partners/me/workers/${id}?full=false`,
      {},
      { cancelToken: source.token }
    );
  };

  getPartnerWorkerReportPdf = id =>
    this.api.get(`/partners/me/workers/${id}/pdf`);
  getApplicantsCount = cohort_id =>
    this.api.get(
      `/invite/partner_counts${cohort_id ? `?cohort_id=${cohort_id}` : ""}`
    );
  getAllApplicants = query => this.api.get(`/invite${query || ""}`);
  getApplicants = (id, query) =>
    this.api.get(`/cohort/${id}/invites${query || ""}`);
  getApplicantHistory = id => this.api.get(`/invite/${id}/candidate_history`);
  getApplicantQuestions = id => this.api.get(`/invite/${id}/questionnaire`);
  checkWorker = params => this.api.post("/partners/workers", params);
  validateEmail = email =>
    this.api.get(`/verify_email?email=${encodeURIComponent(email)}`);
  validateSSN = (
    first_name,
    middle_name,
    last_name,
    date_of_birth,
    email_id,
    ssn,
    drivers_license_number,
    fountain_id,
    phone_number,
    email,
    bypass_dupe,
    g_recaptcha_response
  ) =>
    this.api.post(`/candidate_consent_email/candidate/${email_id}/validate`, {
      first_name,
      middle_name,
      last_name,
      date_of_birth,
      ssn,
      drivers_license_number,
      fountain_id,
      phone_number,
      email,
      bypass_dupe,
      g_recaptcha_response
    });
  validateCandidateEmail = (email_id, email) =>
    this.api.get(
      `/candidate_verify_email/${email_id}?email=${encodeURIComponent(email)}`
    );
  getCandidateData = email_id =>
    this.api.get(`/candidate_consent_email/candidate/${email_id}`);
  getInterests = () => this.api.get("/interests");
  getTransports = () => this.api.get("/transports");
  getPaymetMethod = () => this.api.get("/payment_settings/partner_methods");
  getBackgroundReport = (email_id, token) =>
    this.api.get(
      `/candidate_consent_email/candidate/${email_id}/report?token=${token}`
    );
  provideCandidateConsent = (email_id, params) =>
    this.api.post(`/candidate_consent_email/candidate/${email_id}`, params);
  provideCandidateRecheckConsent = (email_id, params) =>
    this.api.post(
      `/candidate_consent_email/recheck/candidate/${email_id}`,
      params
    );
  provideCandidateConsentAsync = (email_id, params) =>
    this.api.post(
      `/candidate_consent_email/candidate/${email_id}/async`,
      params
    );
  getTrialStatus = () => this.api.get("/organizations/trial_status");
  newCandidate = () => this.api.get("/organizations/new_candidate");
  getTerms = () => this.api.get("/partners/terms");
  consent = params => this.api.post("/partners/me/consent", params);
  getTeamMemberLegalText = key =>
    this.api.get(`/partners/me/legal_text/${key}`);
  postTeamMemberLegalSignature = (key, data) =>
    this.api.post(`/partners/me/legal_text/${key}`, data);
  apply = ({ partner_name, partner_id }) =>
    this.api.post(`/candidate_consent_email/${partner_name}/${partner_id}`, {});
  applyCanada = params => this.api.post(`/fcra_check/form`, params);
  applyCanadaSubmit = (check_id, params) =>
    this.api.post(`/fcra_check/form/${check_id}`, params);
  checkCanadaDuplicates = (check_id, params) =>
    this.api.post(`/fcra_check/form/${check_id}/validate`, params);
  checkCanadaEmailId = email_id =>
    this.api.get(`/fcra_check/${email_id}/validate`);
  assent = (email_id, data) =>
    this.api.post(
      `/candidate_consent_email/candidate/${email_id}/assent`,
      data
    );
  getCohortCount = params =>
    this.api.get(`/cohort/count?query=${JSON.stringify(params)}`);
  createCohort = params => this.api.post("/cohort", params);
  updateCohort = (id, params) => this.api.patch(`/cohort/${id}`, params);
  updateCohortStatus = params => this.api.post("/cohort/update_status", params);
  requestCohortWorkers = (cohort_id, params) =>
    this.api.post(`/cohort/${cohort_id}/request`, params);
  getCohort = cohort_id => this.api.get(`/cohort/${cohort_id}`);
  getDefaultCohort = () => this.api.get(`/cohort/default_values`);
  saveCohortJobLocation = params =>
    this.api.post(`/cohort/job_locations`, params);
  saveCohortJobPosition = params =>
    this.api.post(`/cohort/job_positions`, params);
  getNotes = id => this.api.get(`partners/me/workers/${id}/notes`);
  saveNote = (id, params) =>
    this.handleRequest(
      this.api.put,
      `partners/me/workers/${id}/notes`,
      params,
      "saveNote"
    );
  resendEmail = id =>
    this.handleRequest(
      this.api.post,
      `partners/me/workers/${id}/email/consent/resend`,
      null,
      "resendEmail"
    );
  postSettings = (url, params) =>
    this.handleRequest(this.api.post, url, params, "postSettings");

  // Team Members
  createTeamMember = params =>
    this.api.post(`organizations/team_members`, params);
  updateTeamMember = (id, params) =>
    this.api.put(`organizations/team_members/${id}`, params);
  deleteTeamMember = id =>
    this.handleRequest(
      this.api.delete,
      `organizations/team_members/${id}`,
      null,
      "deleteTeamMember"
    );
  makePrimaryContact = id =>
    this.handleRequest(
      this.api.post,
      `organizations/team_members/${id}/make_primary`,
      null,
      "makePrimaryContact"
    );
  resumeChecks = id =>
    this.handleRequest(
      this.api.put,
      `partners/me/workers/${id}/resume_checks?ignore_mismatches=true&ignore_mvr_hits=false`,
      null,
      "resumeChecks"
    );
  resumeChecksMVR = id =>
    this.handleRequest(
      this.api.put,
      `partners/me/workers/${id}/resume_checks?ignore_mismatches=true&ignore_mvr_hits=true`,
      null,
      "resumeChecksMVR"
    );
  resumeChecksAge = id =>
    this.handleRequest(
      this.api.put,
      `partners/me/workers/${id}/resume_checks?ignore_mismatches=false&ignore_mvr_hits=false&ignore_age=true`,
      null,
      "resumeChecksAge"
    );

  getConvictionCharges = () => this.api.get("/certn/charges");

  recheckWorker = (id, params) =>
    this.handleRequest(
      this.api.post,
      `/partners/me/workers/${id}/recheck`,
      params,
      "recheckWorker"
    );

  getPaymentInfo = () => this.api.get(`/payment_settings/partner_methods`);
  updatePaymentInfo = data =>
    this.api.post("/payment_settings/partner_methods", data);

  getSourcingSubscriptions = () =>
    this.api.get(`/payment_settings/subscriptions/sourcing`);

  enrollPartnerSubscription = data =>
    this.api.post(`/payment_settings/subscriptions/sourcing`, data);

  enrollCandidateMVR = id =>
    this.api.put(`/organization/monitoring/mvr/${id}/enroll`);

  unenrollCandidateMVR = id =>
    this.api.put(`/organization/monitoring/mvr/${id}/unenroll`);

  validateCity = data => this.api.post(`/cohort/campaigns`, data);

  getZipCode = zip_code => this.api.get(`/location/get-zip-code/${zip_code}`);

  getPartnerMetrics = params =>
    this.api.get(`/partners/me/metrics?query=${JSON.stringify(params)}`);

  bulkDisposition = params => {
    this.cancelRequests();
    this.handleRequest(
      this.api.put,
      `/partners/me/workers/bulkdisposition`,
      params,
      "bulkDisposition"
    );
  };

  bulkUpload = params => {
    return this.handleRequest(
      this.api.post,
      `/partners/workers/bulk`,
      params,
      "bulkUpload"
    );
  };

  getJobList = params => {
    if (!params || params === "") {
      return this.api.get("/cohort");
    }

    return this.api.get(`/cohort?${params}`);
  };

  changeJobStatus = (jobId, status) => {
    return this.api.patch(`/cohort/${jobId}`, {
      status
    });
  };
  changeJobsStatuses = (job_ids, action) => {
    return this.api.post(`/cohort/update_status`, {
      job_ids,
      action
    });
  };

  putInviteStatus = data => this.api.put(`/invite/complete_review`, data);

  getScreeners = () => this.api.get(`/screeners`);
  getScreenerDetails = id => this.api.get(`/screeners/${id}`);

  createScreeningQuestions = data =>
    this.api.post("/screeners/create-questions", data);

  updateScreeningQuestions = data => this.api.put("/screeners", data);

  getLocationOrPositionQuery = (params = "") => {
    // TODO we need a way to get all locations/positions items from the backend;
    // for the /ready/locations and /ready/positions pages we can implement pagination,
    // but for create new item page we need a way to get all items
    // (need that for validation AND to show item in the select input after it was created and we reload the page);
    // as optimization - move validation (check if exists) to the backend when creating a new position/location;
    // ticket - https://turnhq.atlassian.net/browse/READY-2351
    const defaultPerPage = "per_page=100";

    const queries = `${
      params.includes("per_page") ? "" : `${defaultPerPage}${params ? "&" : ""}`
    }${params}`;

    return `${queries ? "?" : ""}${queries}`;
  };

  getLocationList = params => {
    return this.api.get(
      `/cohort/job_locations${this.getLocationOrPositionQuery(params)}`
    );
  };

  getPositionList = (params = "") => {
    return this.api.get(
      `/cohort/job_positions${this.getLocationOrPositionQuery(params)}`
    );
  };

  getLocationDetails = id => this.api.get(`/cohort/job_locations/${id}`);

  getPositionDetails = id => this.api.get(`/cohort/job_positions/${id}`);

  updateLocation = (id, data) =>
    this.api.patch(`/cohort/job_locations/${id}`, data);

  updatePosition = (id, data) =>
    this.api.patch(`/cohort/job_positions/${id}`, data);

  forwardAtsOauthCallback = (serviceName, code) => {
    return this.api.get(`${OAUTH_BASE_URL}/${serviceName}/oauth?code=${code}`);
  };

  uploadBase64Id = params =>
    this.handleRequest(
      this.api.post,
      `/upload_doc_img`,
      params,
      "uploadDocBase64"
    );

  transformDefaultWorkflow = data => data.find(i => i.is_default);

  getWorkflows = async () => {
    const response = await this.handleRequest(
      this.api.get,
      "/workflows",
      undefined,
      "getWorkflows"
    );
    return response;
  };

  getWorkflowById = async id => {
    const response = await this.handleRequest(
      this.api.get,
      `/workflows/${id}`,
      undefined,
      "getWorkflowById"
    );
    return response;
  };
}

export default BaseAPI;
