import React from "react";
import Modal from "../../../../../components/Modal/modal";
import { PropTypes } from "prop-types";
import CMIcon from "../../../../../icons/svgr/CMIcon";
import EnrollmentBadge from "../Badge";
import {
  Details,
  Email,
  ImageContainer,
  Status,
  TitleWrapper,
  getCustomPrimaryButtonStyles,
  getCustomSecondaryButtonStyles
} from "./styled";
import { PRIMARY_BUTTON_LABEL_MAPPING } from "./constants";
import { styles } from "./styles";
import usecontroller from "./usecontroller";

const EnrollmentModal = ({
  enrollment,
  isOpen,
  onCloseModal,
  worker,
  disableEnrollmentButton
}) => {
  const { label, status, type: enrollmentType } = enrollment || {};
  const { firstName, lastName, turnId } = worker || {};

  const { isLoading, setAlert, triggerEnrollmentAction } = usecontroller();

  const isNotEnrolled = status === "not_enrolled";

  const title = isNotEnrolled
    ? "Just one more step!"
    : `${label || ""} Monitoring`;

  return (
    <>
      <Modal
        open={isOpen}
        title={<TitleWrapper>{title}</TitleWrapper>}
        {...(!isNotEnrolled && {
          primaryButtonProps: {
            title: PRIMARY_BUTTON_LABEL_MAPPING[status] || "",
            onClick: () =>
              triggerEnrollmentAction(enrollmentType, status, turnId),
            disabled: isLoading || disableEnrollmentButton,
            customStyles: getCustomPrimaryButtonStyles(status)
          }
        })}
        secondaryButtonProps={{
          title: isNotEnrolled ? "Close" : "Cancel",
          onClick: onCloseModal,
          customStyles: getCustomSecondaryButtonStyles(isNotEnrolled)
        }}
        buttonContainerStyle={styles.buttonContainer}
        headingStyle={{ gap: "10px" }}
        panelStyle={{ width: isNotEnrolled ? "456px" : "360px" }}
        img={
          <ImageContainer>
            <CMIcon isDefault={!isNotEnrolled} />
            {!isNotEnrolled && <EnrollmentBadge enrollment={enrollment} />}
          </ImageContainer>
        }
      >
        <Details>
          {isNotEnrolled ? (
            <span>
              Your account still needs to be set up for{" "}
              <TitleWrapper>{label}</TitleWrapper> Monitoring. Please contact
              our Customer Support team at{" "}
              <Email href="mailto:support@turn.ai">support@turn.ai</Email> -
              Thank you!
            </span>
          ) : (
            <span>
              {firstName} {lastName} is{" "}
              <Status status={status}>{status}</Status>
            </span>
          )}
        </Details>
      </Modal>
      {setAlert()}
    </>
  );
};

EnrollmentModal.propTypes = {
  enrollment: PropTypes.object,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.function,
  worker: PropTypes.object
};

export default EnrollmentModal;
