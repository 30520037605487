import { white, primary } from "../../../styles/constants";

export const styles = {
  container: {
    position: "relative"
  },
  content: {
    display: "none"
  },
  icon: {
    position: "absolute",
    right: "16px",
    alignSelf: "center",
    color: white
  },
  node: {
    position: "absolute",
    bottom: "-30px"
  },
  item: {
    fontSize: "14px",
    fontWeight: "bold",
    color: primary
  },
  itemIcon: {
    fontSize: "20px",
    width: "40px"
  },
  divider: {
    width: "90%",
    height: "1px",
    margin: "5px auto",
    backgroundColor: "rgb(0,0,0,0.1)"
  },
  selectTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // To make up for icon > right
    paddingRight: "16px"
  }
};
