import React from "react";
import { PropTypes } from "prop-types";
import { Container } from "./styled";

const EnrollmentBadge = ({ enrollment }) => {
  const { status, type } = enrollment || {};

  return <Container status={status}>{type.toUpperCase()}</Container>;
};

EnrollmentBadge.propTypes = {
  enrollment: PropTypes.obj
};

export default EnrollmentBadge;
