/* eslint-disable no-undef */
/* eslint-disable no-fallthrough */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import CircularProgress from "material-ui/CircularProgress";
import env from "@mars/heroku-js-runtime-env";
import { observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { saveAs } from "file-saver";
import styles from "./styles.js";
import FormModel from "../../models/form";
import { turnAPI } from "../../services";
import ModalTrigger from "../WorkerCheckModal/trigger";
import Form from "./form";
import Confirm from "../WorkerCheckModal/confirm";
import TrialExpired from "../WorkerCheckModal/trial-expired";
import ErrorDialog from "../ErrorDialog";
import Button from "@material-ui/core/Button";
import Img from "../Img";
import Dropzone from "./Dropzone";
import { ReactComponent as CloseIcon } from "../../icons/close-icon-black.svg";
import Tabs from "../Tabs";
import Tab from "../Tab";
import teamMember from "../../models/team-member";

import messages from "./messages";
import _ from "lodash";

const SINGLE_CANDIDATE = "single_candidate";
const MULTIPLE_CANDIDATES = "multiple_candidates";

const propTypes = {
  auth: PropTypes.object,
  workers: PropTypes.object,
  autoOpen: PropTypes.bool,
  handleClose: PropTypes.func
};

const AddCandidatesModal = observer(
  class Modal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        open: false,
        form: { errors: "" },
        status: "not-ready",
        errorOpen: false,
        files: [],
        bulkPackage: null,
        openMultiple: false
      };
      let msg =
        this.state.openMultiple == true
          ? messages.buttonUpload
          : messages.buttonSubmit;
      this.actions = {
        "not-ready": [
          <Button
            onMouseDown={this.handleClose}
            id="close_modal"
            key="0"
            style={styles.modal.cancelButton}
          >
            <FormattedMessage {...messages.buttonCancel} />
          </Button>,
          <Button
            onClick={this.confirmData}
            id="submit_modal"
            key="1"
            style={styles.modal.nextButton}
          >
            <FormattedMessage {...msg} />
          </Button>
        ],
        ready: [
          <div key="0">
            <Button
              onMouseDown={this.handleClose}
              id="close_modal"
              key="3"
              style={styles.modal.cancelButton}
            >
              <div style={{ marginRight: "8px" }}>
                <i className="fas fa-times-circle" />
              </div>
              <FormattedMessage {...messages.buttonCancel} />
            </Button>
            <Button
              onClick={this.handleBack}
              id="back_modal"
              key="4"
              style={styles.modal.editButton}
            >
              <div style={{ marginRight: "8px" }}>
                <i className="fas fa-edit" />
              </div>
              <FormattedMessage {...messages.buttonEdit} />
            </Button>
          </div>,

          <Button
            onClick={this.checkWorker}
            id="submit_modal"
            key="5"
            style={styles.modal.nextButton}
          >
            <FormattedMessage {...messages.buttonSubmit} />
          </Button>
        ],
        "trial-expired": [
          <Button onClick={this.handleClose} id="close_modal" key="6">
            <FormattedMessage {...messages.buttonClose} />
          </Button>
        ]
      };
    }

    componentDidMount() {
      if (this.props.autoOpen) {
        this.handleOpen();
      }
    }

    setFiles = files => {
      this.setState({ files });
    };

    configTabs = () => {
      return [
        {
          value: SINGLE_CANDIDATE,
          title: "Add Single Candidate",
          show: true,
          onClick: this.setActiveTab
        },
        {
          value: MULTIPLE_CANDIDATES,
          title: "Add Multiple Candidates",
          show: true,
          onClick: this.setActiveTab
        }
      ];
    };

    reset = () => {
      const newForm = new FormModel({
        packages: this.state.form.partner_packages,
        default_package: this.state.form.package_id
      });

      this.setState({
        open: true,
        form: newForm,
        bulkPackage: newForm.selected_partner_package.package_id,
        status: "not-ready"
      });
    };

    handleBack = () => {
      this.setState({ status: "not-ready" });
    };

    handleOpen = async () => {
      await this.reset();
      this.state.form.setLoading(true);
      const response = await turnAPI.newCandidate();
      switch (response.status) {
        case 200:
          this.state.form.setNewCandidateEndpointData(response.data);
          this.setState({ status: "not-ready" });
          break;
        case 401:
          this.props.auth.logout();
          break;
        case 402:
          this.setState({ status: "trial-expired" });
          break;
        default:
          this.setState({
            errorOpen: true
          });
          break;
      }
      this.state.form.setLoading(false);
    };

    handleClose = () => {
      this.setState({ open: false });
      if (_.isFunction(this.props.handleClose)) this.props.handleClose();
    };

    confirmData = async () => {
      const { form, files } = this.state;

      if (!_.isEmpty(files)) {
        await this.uploadFiles();
      }

      form.touch("submit");
      form.touch("firstName");
      form.touch("lastName");
      form.touch("email");
      if (form.errors === "") await this.validate();
    };

    uploadFiles = async () => {
      this.state.form.setLoading(true);
      const { files, bulkPackage, form } = this.state;

      const filesUploaded = files.map(async file => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
          "package_id",
          bulkPackage || form.selected_partner_package.package_id
        );
        const response = await turnAPI.bulkUpload(formData);
        return response;
      });

      Promise.all(filesUploaded).then(filesResponses => {
        this.state.form.setLoading(false);
        const status = _(filesResponses)
          .map("status")
          .max();
        switch (status) {
          case 200:
          case 201:
          case 202:
            this.setState({ status: "upload-ready" });
            break;
          case 401:
            this.props.auth.logout();
            break;
          case 402:
            if (teamMember.account_source === "trial_landing") {
              window.location.href = `${
                env().REACT_APP_CHECKOUT_BGC
              }?email=${encodeURIComponent(teamMember.email)}`;
            }
            this.setState({ status: "trial-expired" });
            this.forceUpdate();
            break;
          default:
            this.setState({
              errorOpen: true
            });
            break;
        }
      });
    };

    validate = async () => {
      this.state.form.setLoading(true);
      await this.validateEmail();
      this.state.form.setLoading(false);
      if (this.state.form.errors === "") this.setState({ status: "ready" });
    };

    checkWorker = async () => {
      this.state.form.setLoading(true);
      const response = await turnAPI.checkWorker(this.state.form.params);
      switch (response.status) {
        case 200:
          this.handleCheckResponse(response.data);
          break;
        case 401:
          this.props.auth.logout();
          break;
        case 402:
          if (teamMember.account_source === "trial_landing") {
            window.location.href = `${
              env().REACT_APP_CHECKOUT_BGC
            }?email=${encodeURIComponent(teamMember.email)}`;
          }
          this.setState({ status: "trial-expired" });
          this.forceUpdate();
          break;
        case 422:
          if (response.data.phone_number) {
            this.state.form.setPhoneNumberInvalidError(
              response.data.phone_number
            );
            this.setState({ status: "not-ready" });
            break;
          }
        default:
          this.setState({
            errorOpen: true
          });
          break;
      }
      this.state.form.setLoading(false);
    };

    validateEmail = async () => {
      const response = await turnAPI.validateEmail(this.state.form.email);
      switch (response.status) {
        case 200:
          this.state.form.setEmailServiceError(
            response.data.is_verified ? "verified" : "refuted"
          );
          break;
        case 500:
          this.setState({
            errorOpen: true
          });
          break;
        default:
          this.state.form.setEmailServiceError("unknown");
          break;
      }
    };

    getWorkerEmail = data => {
      if (!data.email && data.worker) {
        return data.worker;
      }
      if (!data.worker) {
        return data;
      }
      data.worker["email"] = data.email;

      return data;
    };

    handleCheckResponse = data => {
      if (data.is_fcra) {
        this.handleEmailResponse(data);
      } else {
        this.handleWorkerResponse(data);
      }
    };

    handleEmailResponse = data => {
      let worker = this.getWorkerEmail(data);

      this.props.workers.addWorker(worker);
      this.props.fetch();
      this.setState({ status: "email-sent" });
    };

    handleWorkerResponse = data => {
      const ssnRequired = data.need.includes("ssn");
      if (ssnRequired && this.state.form.requireSSN) {
        this.state.form.setNameServiceError("invalid");
        this.setState({ status: "not-ready" });
      } else if (ssnRequired) {
        this.state.form.setRequireSSN(true);
        this.state.form.touch("ssn");
        this.state.form.setSSNServiceError();
        this.setState({ status: "not-ready" });
      } else {
        this.props.workers.addWorker(data);
        this.handleClose();
      }
    };

    renderModalContent = renderNotReady => {
      switch (this.state.status) {
        // case "email-sent":
        //   return <EmailSent />;
        case "ready":
          return <Confirm model={this.state.form} />;
        case "trial-expired":
          return (
            <TrialExpired
              ecomm={teamMember.account_source === "trial_landing"}
            />
          );
        case "not-ready":
        default:
          return !renderNotReady && <Form model={this.state.form} />;
      }
    };

    handleBulkUploadPackage = e => {
      this.setState({ bulkPackage: e.target.value });
    };

    renderBulkUpload = () => {
      const { form } = this.state;
      if (!form.selected_partner_package) return null;

      return (
        <>
          <div style={{ width: "256px" }}>
            <InputLabel style={styles.inputLabel}>
              <FormattedMessage {...messages.labelPackages} />*
            </InputLabel>
            <Select
              value={this.state.bulkPackage}
              onChange={this.handleBulkUploadPackage}
              style={styles.select}
            >
              {this.state.form.partner_packages.map((partner_package, i) => (
                <MenuItem
                  className="menuItemDropdown"
                  value={partner_package.package_id}
                  key={i}
                >
                  {partner_package.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <p style={styles.modal.downloadText}>
            <span
              onClick={async () => {
                try {
                  const response = await fetch(
                    env().REACT_APP_BULK_TEMPLATE_URL ||
                      "https://pw-bulk-upload.s3.amazonaws.com/template/CSV_Template.csv"
                  );
                  const blob = await response.blob();
                  saveAs(blob, "CSV_Template.csv");
                } catch (e) {
                  this.setState({
                    errorOpen: false
                  });
                }
              }}
              style={styles.modal.downloadLink}
            >
              Download
            </span>{" "}
            the bulk upload template file.
          </p>
          <Dropzone setAcceptedFiles={this.setFiles} />
        </>
      );
    };

    renderTitle = () => {
      switch (this.state.status) {
        case "email-sent":
          return "The candidate has been notified";
        case "upload-ready":
          return "The candidates have been notified";
        case "ready":
          return "Confirm Information";
        case "trial-expired":
          return "Your trial has expired";
        case "not-ready":
        default:
          return "";
      }
    };

    setActiveTab = lblValue => {
      const btnLbl =
        lblValue == SINGLE_CANDIDATE
          ? messages.buttonSubmit
          : messages.buttonUpload;

      this.actions["not-ready"] = [
        <Button
          onMouseDown={this.handleClose}
          id="close_modal"
          key="0"
          style={styles.modal.cancelButton}
        >
          <FormattedMessage {...messages.buttonCancel} />
        </Button>,
        <Button
          onClick={this.confirmData}
          id="submit_modal"
          key="1"
          style={styles.modal.nextButton}
        >
          <FormattedMessage {...btnLbl} />
        </Button>
      ];
      this.forceUpdate();
    };

    renderLoading = () => {
      const shouldRenderLoading =
        this.state.form.loading && this.state.status !== "upload-ready";
      if (shouldRenderLoading) {
        return (
          <div style={styles.progressContainer}>
            <CircularProgress style={styles.progress} size={80} thickness={5} />
          </div>
        );
      }
    };

    render() {
      const { status, open, errorOpen } = this.state;

      return (
        <>
          {!this.props.autoOpen ? (
            <ModalTrigger handleOpen={this.handleOpen} />
          ) : null}

          {status === "email-sent" || status === "upload-ready" ? (
            <Dialog
              fullWidth
              open={open}
              onClose={this.handleClose}
              autoScrollBodyContent
              disableBackdropClick
              disableEscapeKeyDown
              style={{ scrollBehavior: "smooth" }}
            >
              <CloseIcon
                onClick={this.handleClose}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "1rem",
                  top: "1rem",
                  width: 10,
                  height: 10
                }}
              />
              <DialogContent style={{ padding: "47px" }}>
                {this.renderLoading()}
                <div style={styles.imgContainer}>
                  <div style={styles.imgDimensionsContainer}>
                    <Img type="discoverySuccess" style={styles.headerImage} />
                  </div>
                </div>
                <div style={styles.messages}>
                  <div
                    id="candidate-notified-message"
                    style={styles.gralMessageContainer}
                  >
                    {this.renderTitle()}
                  </div>
                  <div style={styles.specificMessageContainer}>
                    <FormattedMessage {...messages.emailSent} />
                  </div>
                </div>
                <div style={styles.blackButtonContainer}>
                  <Button
                    onClick={this.reset}
                    id="ok_button"
                    key="1"
                    style={styles.blackButtonEmailSent}
                  >
                    <FormattedMessage {...messages.buttonAgainAddCandidate} />
                  </Button>
                  <div style={styles.closeButtonContainer}>
                    <Button
                      onClick={this.handleClose}
                      id="close_button"
                      key="1"
                      style={styles.closeWindowButton}
                    >
                      <div style={{ marginRight: "8px" }}>
                        <i className="fas fa-times-circle" />
                      </div>
                      <FormattedMessage {...messages.buttonClose} />
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          ) : (
            <Dialog
              fullWidth
              open={open}
              onClose={this.handleClose}
              autoScrollBodyContent
              disableBackdropClick
              disableEscapeKeyDown
            >
              <CloseIcon
                onClick={this.handleClose}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "1rem",
                  top: "1rem",
                  width: 10,
                  height: 10
                }}
              />
              <DialogContent>
                <Tabs headerConfig={this.configTabs()}>
                  <Tab value={"single_candidate"}>
                    {this.renderLoading()}
                    {this.renderModalContent()}
                  </Tab>
                  <Tab value={"multiple_candidates"}>
                    {this.renderModalContent(true)}
                    {this.state.status !== "trial-expired" &&
                      this.renderBulkUpload()}
                  </Tab>
                </Tabs>
              </DialogContent>

              {status === "ready" ? (
                <DialogActions style={styles.buttonsContainerSpaceBetween}>
                  {this.actions[status]}
                </DialogActions>
              ) : (
                <DialogActions style={styles.buttonsContainerFlexEnd}>
                  {this.actions[status]}
                </DialogActions>
              )}
            </Dialog>
          )}

          <ErrorDialog
            open={errorOpen}
            onPress={() => {
              this.setState({
                errorOpen: false
              });
            }}
          />
        </>
      );
    }
  }
);

AddCandidatesModal.propTypes = propTypes;

export default AddCandidatesModal;
