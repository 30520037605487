import styled from "styled-components";
import { pickColor } from "turn-shared";
import {
  ceruleanBlue,
  emerald,
  orange,
  secondary,
  alert3,
  slateGray
} from "../../styles/constants";

const statusColor = {
  approved: emerald,
  consent: "white",
  consider: alert3,
  first_notice: orange,
  second_notice: orange,
  processing: ceruleanBlue,
  rejected: "#EE0000",
  withdrawn: "#9BB1C8",
  verifying: slateGray,
  reviewing: slateGray,
  "Compliance Review": slateGray,
  initiated: slateGray,
  emailed: slateGray
};

const StatusBadgeContainer = styled.p`
  color: ${props => (props.status === "consent" ? secondary : "white")};
  background-color: ${props =>
    statusColor[props.status]
      ? statusColor[props.status]
      : pickColor(props.status)};
  border-radius: 50px;
  border: ${props =>
    props.status === "consent" ? `1px solid ${secondary}` : "none"}
  display: ${props => props.display || "block"};
  font-size: ${props => props.size || "0.7rem"};
  font-weight: ${props => props.weight || 500};
  text-align: ${props => props.textAlign || "left"};
  min-width: 100px;
  max-width: 130px;
  height: 32px
  text-transform:capitalize;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  padding-right: 1rem;
`;

export default StatusBadgeContainer;
