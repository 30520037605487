import React, { useEffect, useRef } from "react";
import StepWizard from "react-step-wizard";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import _ from "lodash";

import { smokeWhite } from "../../../styles/constants";

import { FormWizardStepper } from "./FormWizardStepper";

/**
 * Render the background and make sure we're at least 100% of the parent height.
 */
const FormWizardWrapper = styled.div`
  min-height: 100%;
  background: ${smokeWhite};
`;

/**
 * Multi-step wizard, composed from `react-step-wizard`.
 *
 * Children must be `FormWizardStep` (or composed/inherited from it) and should have a `label` prop
 * which will be used to render the text under each step on the header. Children have access to the
 * wizard steps and navigation through props passed-down by `react-step-wizard`.
 *
 * @example
 *    <FormWizard>
 *      <FormWizardStep label="Start">
 *        <!-- whatever -->
 *      </FormWizardStep>
 *      <FormWizardStep label="End">
 *        <!-- whatever -->
 *      </FormWizardStep>
 *    </FormWizard>
 *
 * @see https://www.npmjs.com/package/react-step-wizard
 */
export const FormWizard = ({ activeStep, children, ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current || activeStep === undefined) {
      return;
    }

    if (ref.current.state.activeStep !== activeStep) {
      const childCount = _.compact(React.Children.toArray(children)).length;
      if (activeStep < childCount) {
        ref.current.setActiveStep(activeStep);
      } else {
        ref.current.setActiveStep(childCount - 1);
      }
    }
  }, [activeStep]);

  return (
    <FormWizardWrapper {...props}>
      <StepWizard
        ref={ref}
        nav={<FormWizardStepper>{children}</FormWizardStepper>}
        {...props}
      >
        {children}
      </StepWizard>
    </FormWizardWrapper>
  );
};

FormWizard.propTypes = {
  activeStep: PropTypes.number,
  children: PropTypes.node.isRequired
};
