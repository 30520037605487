import React, { PureComponent } from "react";
import Title from "../Title";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { primary } from "../../styles/constants";

class PendingAgeReview extends PureComponent {
  render() {
    return (
      <Title size="14px" color={primary} align="left" weight="500" margin="0">
        <FormattedMessage {...messages.title} />
      </Title>
    );
  }
}

export default PendingAgeReview;
