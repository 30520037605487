import { demoData1 } from "./demoData";

/*
    This function returns the data necessary to populate the charts in the Partner Dashboard landing page.
    Since this is for demo purposes, we're just going to add some dummy data, which doesn't actually relate to anything.

    I've pulled a real response to use here. The problem is that the data need to look like they are from the past 28 days, otherwise
    the graphs will look empty.

    The purpose of this function is to update all of the dates in the JSON and make them look like they are recent. This is done by calculating the 
    number of days which have passed since the original JSON was extracted and add this to the dates in the JSON.
*/

class DatePlus extends Date {
  getTimeSince(date) {
    return this.getTime() - date.getTime();
  }

  getDaysSince(date) {
    return Math.floor(this.getTimeSince(date) / (24 * 60 * 60 * 1000));
  }

  addDays(n) {
    this.setTime(this.getTime() + n * 24 * 60 * 60 * 1000);
  }
}

const EXTRACTED_DATE = new DatePlus("2022-03-03");
const now = new DatePlus();
const daysSince = now.getDaysSince(EXTRACTED_DATE);

const updateDateInData = dataItem => {
  const dateString = dataItem.date;
  if (!dateString) return null;
  const date = new DatePlus(dateString);

  // If date is invalid
  if (isNaN(date.getTime())) return null;

  date.addDays(daysSince);
  const newDateString = date.toISOString().slice(0, 10);
  return {
    ...dataItem,
    date: newDateString
  };
};

export const getDemoData = () => {
  const newData = demoData1.data.map(updateDateInData);
  return {
    ...demoData1,
    data: newData
  };
};
