// This is a copy of the WorkerSettings container but with additional logic to handle Oauth callbacks on redirect
// Oauth redirects should be sent to this page
import React from "react";
import SettingsPage from "../SettingsPage";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { turnAPI } from "../../services";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { PRE_OAUTH_PARAM_PREFIX } from "../../constants";
import withContext from "../../contextAPI/context-HOC";

class OauthCallbackSettings extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    router: PropTypes.object,
    location: PropTypes.object,
    auth: PropTypes.object,
    context: PropTypes.object,
    history: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      service:
        this.props.match.params.service ||
        this.props.match.url.replace("/settings/oauth/", ""),
      code: this.getCode(),
      modal: {
        display: false,
        error: false,
        message: ""
      }
    };
  }
  static contextTypes = { router: PropTypes.object };

  getCode() {
    const query = new URLSearchParams(this.props.location.search);
    const code = query.get("code");

    if (code) return code;

    const localStorageCode = localStorage.getItem(
      PRE_OAUTH_PARAM_PREFIX + "code"
    );

    if (localStorageCode) return localStorageCode;
  }

  componentDidMount() {
    // code rerurned in url from oauth provider
    this.handleServiceCallback(this.state.service, this.state.code);
  }

  handleServiceCallback = (serviceName, code) => {
    switch (serviceName) {
      case "lever":
        return turnAPI
          .forwardAtsOauthCallback("lever", code)
          .then(res => {
            if (res.status === 200) {
              this.onSuccessfulAuth(res.body);
            } else {
              this.onUnsuccessfulAuth(res.body);
            }
          })
          .catch(err => {
            this.onUnsuccessfulAuth(err);
          });

      case "rippling":
        return turnAPI
          .forwardAtsOauthCallback("rippling", code)
          .then(res => {
            if (res.status === 200) {
              const localStorageRedirectUri = localStorage.getItem(
                PRE_OAUTH_PARAM_PREFIX + "redirect_uri"
              );
              this.onSuccessfulAuth({});
              // send user back to rippling to complete auth flow
              const rippling_redirect =
                localStorageRedirectUri ||
                process.env.REACT_APP_RIPPLING_REDIRECT_URI;
              if (!rippling_redirect) {
                throw new Error("No Rippling URL found for redirect");
              }
              window.location.href = rippling_redirect;
            } else {
              this.onUnsuccessfulAuth(res.body);
            }
          })
          .catch(err => {
            this.onUnsuccessfulAuth(err);
          });

      default:
        this.setToast({
          message: "Couldn't find a service called: " + this.state.service,
          error: true
        });
    }
  };

  onSuccessfulAuth(body) {
    if (body && body.redirect_uri) {
      this.props.history.push(body.redirect_uri);
    }
    this.setToast({
      message: this.state.service + " authorization successful",
      error: false
    });
  }

  onUnsuccessfulAuth() {
    this.setToast({
      message: this.state.service + " authentication failed",
      error: true
    });
  }

  resetToast() {
    this.setState({ modal: { show: false, error: false, message: "" } });
    this.props.history.push("/settings");
  }

  setToast({ message, error }) {
    this.setState({ modal: { show: true, error: error || false, message } });
  }

  showToast() {
    toastr.options = {
      closeButton: true,
      positionClass: "toast-bottom-right",
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "10000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut"
    };

    if (this.state.modal.show) {
      if (this.state.modal.error) {
        toastr.error(this.state.modal.message);
      } else {
        toastr.success(this.state.modal.message);
      }

      setTimeout(() => this.resetToast(), 5000);
    }
  }
  render() {
    return (
      <>
        <SettingsPage {...this.props} />
        {this.showToast()}
      </>
    );
  }
}

export default withContext(observer(OauthCallbackSettings));
